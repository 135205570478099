import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BackButtonInter from '../../../../atoms/Buttons/BackButtonInter';
import { Typography, Box, CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux';
import { BenefitConfigDetails, BenefitDetailsData, BenefitTypeButton } from './benefit.config';
import ButtonInter from '../../../../atoms/Buttons/ButtonInter';
import { useNavigate } from 'react-router-dom';
import { MembershipColor } from '../../../Membership/MembershipMainValues';
import { IUserData } from '../../interfaces/user';
import { MembershipPlanItem } from '../../interfaces/membership';
import { ConvertPlanToMembershipItem } from '../../utils/convertPlantoMembership';
import { BenefitValues, BenefitValuesType } from '../benefitEngine/benefit.values';
import useFetch from "../../../../../hooks/useFetch";
import { apiError as apiCheckUpMedicError, apiLoading as apiCheckUpMedicLoading, apiSuccess as apiCheckUpMedicSuccess } from "../../../../../api/checkUpMedicSlice";
import LoadingComponent from "../../../../../components/organisms/loading/loading";
import loading from "../../../../../components/organisms/loading/loading";
import { IStorageMembershipData } from '../../../../../utils/localStorageKeys';

const BenefitDetailsConteiner = styled.div`
    box-sizing: border-box;
    padding: 10px;
    margin-top: 10px;
`

const BenefitCardDetails = styled.div``
const BenefitWelcome = styled.div``
const BenefitRequirements = styled.div``
const BenefitButton = styled.div`
    display: flex;
    gap:24px;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`

interface BenefitDetailsComponent {
    benefitKey: BenefitValuesType;
}

type ButtonType = {
    [key in BenefitTypeButton]: 'contained' | 'outlined' | 'text';
}

const BenefitDetailsComponent: React.FC<BenefitDetailsComponent> = ({
    benefitKey,
}) => {
    const navigate = useNavigate();
    const [eventYears, setEventYears] = useState<number>(1);
    const userResponse: IUserData = useSelector((state: any) => state.user.response.data);
    const currentMembership: number = useSelector((state: any) => state.user.currentMembership);
    const [MembershipsPlans, setMembershipPlans] = useState<MembershipPlanItem[]>([]);
    const BenefitData: BenefitDetailsData | undefined = BenefitConfigDetails[benefitKey];
    const Valid = BenefitData ? true : false;
    //const currentValue = useSelector((state: any) => state.productsCards.currentValue);

    const [image, setImage] = useState(BenefitData?.image)
    const [colorPrimary, setColorPrimary] = useState(MembershipColor?.freeSecondary)
    const [colorSecondary, setColorSecondary] = useState(MembershipColor?.freeOpacity)
    const [colorText, setColorText] = useState('black')

    // let image: any = BenefitData?.image;
    // let colorPrimary: string = MembershipColor.freeSecondary;
    // let colorSecondary: string = MembershipColor.freeOpacity;
    // let colorText: string = 'black'
    let buttonType: ButtonType = {
        'primary': 'contained',
        'secondary': 'outlined',
        'text': 'text',
    }

    useEffect(() => {
        const plans = ConvertPlanToMembershipItem(userResponse.plans);
        setMembershipPlans(plans);
        const membership = plans[currentMembership];
        setImage(!BenefitData?.onlyPremium && membership.type === "premium" ? BenefitData?.imageWhite : BenefitData?.image)
        setColorPrimary(BenefitData?.onlyPremium || membership.type === "premium" ? MembershipColor.premium : MembershipColor.freeSecondary)
        setColorSecondary(BenefitData?.onlyPremium || membership.type === "premium" ? MembershipColor.premiumOpacity : MembershipColor.freeOpacity)
        setColorText(BenefitData?.onlyPremium || membership.type === "premium" ? 'white' : 'black')
    }, [userResponse]);

    useEffect(() => {
        if (MembershipsPlans && MembershipsPlans.length > 0 && BenefitData?.onlyPremium === false) {
            setColorPrimary(MembershipsPlans[currentMembership]?.type === "premium" ? MembershipColor.premiumSecondary : MembershipColor.freeSecondary)
            setColorSecondary(MembershipsPlans[currentMembership]?.type === "premium" ? MembershipColor.premiumOpacity : MembershipColor.freeOpacity)
        }
    }, [MembershipsPlans])


    const { fetchData } = useFetch();
    const checkUpMedicLoading = useSelector((state: any) => state.checkUpMedic.loading) as any | null;
    const checkUpMedicResponse = useSelector((state: any) => state.checkUpMedic.response) as any | null;
    const checkUpMedicError = useSelector((state: any) => state.checkUpMedic.error?.response?.data) as any | null;


    const CheckUpAction = () => {
        return fetchData('/plansalud/aba', 'POST', {
            event: "health-assistances",
            content: {
                item_id: "checkup"
            }
        }, {}, apiCheckUpMedicLoading, apiCheckUpMedicSuccess, apiCheckUpMedicError);
    }

    // useEffect(() => {
    //     if (BenefitData && BenefitData.label===BenefitValues.CheckUpMedico){
    //         console.log({checkUpMedicResponse});

    //         if(checkUpMedicResponse && checkUpMedicResponse?.code && checkUpMedicResponse?.code===200){
    //             navigate("/portal-del-cliente")
    //         }
    //     }

    // }, [ checkUpMedicResponse]);


    useEffect(() => {
        if (BenefitData && BenefitData.label === BenefitValues.CheckUpMedico) {
            if (checkUpMedicError && checkUpMedicError?.code && checkUpMedicError?.code === 400) {
                navigate("/portal-del-cliente")
            }
        }
    }, [checkUpMedicError]);

    let storage: IStorageMembershipData = {
        ...useSelector((state: any) => state.productsCards.currentValue),
    };


    return Valid ? <BenefitDetailsConteiner >
        <BackButtonInter />
        <div
            style={{
                display: "flex",
            }}
        >
            <div style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "500px",
                margin: "0 auto"
            }}>
                <BenefitCardDetails style={{
                    background: colorPrimary,
                    display: 'flex',
                    flexDirection: 'column',
                    boxSizing: 'border-box',
                    borderRadius: '10px 10px 0px 0px',
                    margin: '10px'
                }}>
                    <Box sx={{
                        display: 'flex',
                        padding: '10px',

                    }}>
                        <Box sx={{
                            width: '25%',
                            height: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center'
                        }}>
                            <img src={image} alt="" style={{ width: '80%', margin: 'auto' }} />
                        </Box>
                        <Typography style={{
                            fontSize: '25px',
                            fontWeight: 900,
                            display: 'flex',
                            alignItems: "center",
                            width: '70%',
                            color: colorText
                        }}>
                            {BenefitData ? BenefitData.label : 'Este beneficio no esta configurado'}
                        </Typography>
                    </Box>
                    <Box sx={{
                        background: colorSecondary,
                        padding: '10px',
                        borderRadius: '10px 10px 0px 0px',
                        fontSize: '20px'
                    }}>
                        <Typography>
                            {BenefitData?.description}
                        </Typography>
                    </Box>
                    <Box sx={{
                        padding: '10px',
                    }}>
                        <Typography style={{
                            fontWeight: 900,
                            textAlign: 'center',
                            fontSize: '18px',
                            color: colorText
                        }}>
                            Eventos disponibles al año: {(BenefitData?.label === "Pago de medicamentos" || BenefitData?.label === "Check-up médico") && MembershipsPlans[currentMembership]?.type === "premium" ? 3 : eventYears}
                        </Typography>
                    </Box>
                </BenefitCardDetails>
                <div style={{
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    paddingLeft: '10px',
                    paddingRight: '10px'
                }}>
                    <hr color={MembershipsPlans[currentMembership] && MembershipsPlans[currentMembership].type === 'free' && BenefitData?.onlyPremium === false ? colorPrimary : MembershipColor.premiumOpacity} />
                </div>

                <BenefitWelcome style={{
                    fontSize: '20px',
                    padding: '10px',
                }}>

                    {BenefitData?.label === BenefitValues.CheckUpMedico ? BenefitData?.welcome({ fullname: userResponse.user.name + ' ' + userResponse.user.last_name }) : BenefitData?.welcome() || 'Bienvenida no encontrada'}
                </BenefitWelcome>
                <BenefitRequirements style={{
                    width: '95%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    fontSize: '20px',
                    padding: '10px',
                    boxSizing: 'border-box',
                    margin: 'auto',
                    background: colorSecondary
                }}>
                    {
                        BenefitData?.content(colorPrimary) || 'No se encontro contenido'
                    }
                </BenefitRequirements>
                <BenefitButton style={{ marginTop: '15px' }}>
                    {BenefitData?.buttons.map(button => {
                        return <ButtonInter variant={buttonType[button.type]} onClick={() => {
                            if (button.action === 'go' && typeof button.value === 'string') {
                                navigate(button.value)
                            } else if (button.action === 'function' && typeof button.value === 'function') {
                                button.value();
                            }
                            else if (button.action === 'inside_function' && typeof button.value === 'function') {
                                button.value(CheckUpAction);
                                navigate('/portal-del-cliente')
                            }
                            else if (button.action === 'back') {
                                navigate(-1);
                            } else if (button.action === 'outsite' && typeof button.value === 'string') {
                                window.location.href = button.value;
                            } else {
                                console.error("Evento mal configurado", {
                                    button
                                });
                            }
                            window.dataLayer.push({
                                event: BenefitData?.label == "Pago de medicamentos"
                                    ? "TRUS_pago_medicamentos"
                                    : BenefitData?.label == "CheckUpMedico"
                                        ? "TRUS_check_medico"
                                        : BenefitData?.label == "Reembolso de gastos médicos"
                                            ? "TRUS_reembolso"
                                            : BenefitData?.label == "Pérdidas Orgánicas"
                                                ? "TRUS_perdidas_organicas"
                                                : BenefitData?.label == "Invalidez total y permanente por accidente"
                                                    ? "TRUS_invalidez_total"
                                                    : BenefitData?.label == "Apoyo de incapacidad por accidente"
                                                        ? "TRUS_apoyo_incapacidad"
                                                        : ""
                                ,
                                tipo_seguro: userResponse?.user?.benefit_id === "C01" ? "De regalo" : "Premium",
                                correo: userResponse?.user?.email,
                                seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
                                telefono: userResponse?.user?.phone,
                                boton_contacto: button.action == "back" ? "Entendido" : button.action == "go" && button.value == "" ? "Formato de reclamacion" : button.value == "/video-conferencia" ? "Agendar videoconsulta" : "",
                            })
                        }}>
                            {button.label}
                        </ButtonInter>
                    })}
                </BenefitButton>
            </div>
        </div>
    </BenefitDetailsConteiner >
        :
        <Box sx={{
            padding: '25px'
        }}>
            <BackButtonInter />
            <code style={{
                fontSize: '25px',
                marginTop: '20px'
            }}>
                Beneficio no encontrado
            </code>
        </Box>
}

export default BenefitDetailsComponent
