

import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar, Toolbar
} from '@mui/material';
import BasicModal from '../../molecules/BasicModal/BasicModal';
import MenuContent from './MenuContent/MenuContent';
import CustomContainer from '../Container/Container';
import { useLocation } from 'react-router-dom';
import { ContextAuth0 } from '../../../auth/contextAPIProvider';
import { isAuth, logout } from '../../../auth/auth0';
import { useDispatch, useSelector } from 'react-redux';
import { restartValue as restartUserValue } from '../../../api/userSlice';
import { restartValue as restartBeneficiaryValue } from '../../../api/gadgetsBrandSlice';
import { restartValue } from '../../../api/loginSlice';
import { useTheme, useMediaQuery } from '@mui/material';
import { restartValue as restartAllianceSetting } from "../../../api/allianceSettingSlice";
import { restartValue as restartStatus } from "../../../api/statusSlice";

interface NavbarProps {
  isAlliance?: boolean
  // Define las propiedades del componente aquí (si es necesario)
}

const blackListPaths: string[] = ['/elegir-cobertura', '/resumen-membresia', '/crear-cuenta', '/redirect', '/payment'];

const useQuery = () => {
  let location = window.location.pathname
  const getID = (text: string = "") => {
    const id = text.split('/').pop();
    return id
  }
  return getID(location);
}

const Navbar: React.FC<NavbarProps> = ({ isAlliance = false }) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation();
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const locate = useLocation();
  const [isAuthState, setIsAuth] = React.useState<boolean>(false);
  const webAuth: any = useContext(ContextAuth0);
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('md'));


  let path = window.location.pathname.includes("landing-alliance")
  let searchParamID = useQuery();
  const allianceSettingResponse = useSelector((state: any) => state.allianceSetting.response);
  const userResponse = useSelector((state: any) => state?.user?.response);
  
  const MENUBODY: any = [
    {
      willBeRendered: (!locate.pathname.includes('/finsus')) ? true : false,
      item: 'Coche Seguro',
      url: 'https://cocheseguro.inter.mx/'
      // subItems: [
      //   { label: 'Medico Seguro', onClick: () => window.open('https://medicoseguro.inter.mx/inicio', '_blank') },
      //   { label: 'Mascota Segura', onClick: () => window.open('https://mascotasegura.inter.mx/seguro-mascota/landing', '_blank') }
      // ]
    },
    {
      willBeRendered: (!locate.pathname.includes('/finsus')) ? true : false,
      item: 'Médico Seguro',
      url: 'https://medicoseguro.inter.mx/inicio'
    },
    {
      willBeRendered: (!locate.pathname.includes('/finsus')) ? true : false,
      item: 'Mascota Segura',
      url: 'https://mascotasegura.inter.mx/seguro-mascota/landing'
    },
    {
      item: 'Conocer más',
      subItems: [
        {
          label: 'Promociones', onClick: () => {
            window.dataLayer.push({
              event: 'TRUS_menu',
              option: 'Promociones',
            })
            window.open('https://inter.mx/promotions', '_blank')
          }
        },
        {
          label: 'ABC de los seguros', onClick: () => {
            window.dataLayer.push({
              event: 'TRUS_menu',
              option: 'ABC de los seguros',
            })
            window.open('https://inter.mx/blog', '_blank')
          }
        },
        {
          label: 'Preguntas frecuentes', onClick: () => {
            window.dataLayer.push({
              event: 'TRUS_menu',
              option: 'Preguntas frecuentes',
            })
            navigate('/preguntas-frecuentes')
          }
        }
      ]
    },
    {
      willBeRendered: !isDesktop ,
      item: 'Iniciar sesión',
      onClick: () => {
        navigate('/iniciar-sesion')
      }
    },
    {
      item: 'Ayuda',
      url: 'https://intermx-digital.atlassian.net/servicedesk/customer/portal/8'
    }
    // {
    //   item: 'Promociones',
    //   url: 'https://inter.mx/promotions'
    // },
    // {
    //   item: 'ABC de los seguros',
    //   url: 'https://inter.mx/blog'
    // },
    // {
    //   item: 'Preguntas frecuentes',
    //   onClick:() => navigate('/preguntas-frecuentes')
    //   // url: 'https://inter.mx/preguntas-frecuentes'
    // },
  ]

  const Ayuda = async () => {
    var anchor = document.createElement('a');
    anchor.href = 'https://intermx-digital.atlassian.net/servicedesk/customer/portal/8';
    anchor.target = "_blank";
    anchor.click();
  }

  const MENUBODYAUTH = [
    {
      item: 'Mi Perfil',
      active: true,
      onClick: () => {
        window.dataLayer.push({
          event: 'TRUS_menu',
          option: "Mi perfil",
        })
        navigate('/portal-del-cliente')
      }
    },
    {
      item: 'Mis datos',
      onClick: () => {
        window.dataLayer.push({
          event: 'TRUS_menu',
          option: "Mi perfil",
        })
        navigate('/datos-del-cliente')
      }
    },
    {
      item: 'Ayuda',
      onClick: () => {
        window.dataLayer.push({
          event: 'TRUS_menu',
          option: "Ayuda",
        })
        Ayuda()
      }
    },
    {
      item: 'Documentos adicionales',
      onClick: () => {
        window.dataLayer.push({
          event: 'TRUS_menu',
          option: "Documentos adicionales",
        })
        navigate('/documentos-adicionales')
      }
    },
    {
      item: 'Preguntas frecuentes',
      onClick: () => {
        window.dataLayer.push({
          event: 'TRUS_menu',
          option: "Preguntas frecuentes",
        })
        navigate((allianceSettingResponse?.data?.name === 'Finsus'
          || userResponse?.data?.business?.name === 'Finsus') ? '/finsus/preguntas-frecuentes' : '/preguntas-frecuentes')
      }
    },
    {
      item: 'Cerrar sesión',
      onClick: () => {
        window.dataLayer.push({
          event: 'TRUS_menu',
          option: "Cerrar sesion",
        })
        sessionStorage.setItem('userError', '2');
        dispatch(restartValue());
        dispatch(restartUserValue());
        dispatch(restartBeneficiaryValue());
        dispatch(restartAllianceSetting())
        dispatch(restartStatus());
        localStorage.clear();
        logout(webAuth);
      }
    }
  ]

  const MENUBODYAUTHMOVIL = [
    {
      item: 'Mi Perfil',
      active: true,
      onClick: () => {
        window.dataLayer.push({
          event: 'TRUS_menu',
          option: "Mi perfil",
        })
        navigate('/portal-del-cliente')
      }
    },
    {
      item: 'Mis datos',
      onClick: () => {
        window.dataLayer.push({
          event: 'TRUS_menu',
          option: "Mi perfil",
        })
        navigate('/datos-del-cliente')
      }
    },
    {
      item: 'Ayuda',
      onClick: () => {
        window.dataLayer.push({
          event: 'TRUS_menu',
          option: "Ayuda",
        })
        Ayuda()
      }
    },
    {
      item: 'Documentos adicionales',
      onClick: () => {
        window.dataLayer.push({
          event: 'TRUS_menu',
          option: "Documentos adicionales",
        })
        navigate('/documentos-adicionales')
      }
    },
    {
      item: 'Preguntas frecuentes',
      onClick: () => {
        window.dataLayer.push({
          event: 'TRUS_menu',
          option: "Preguntas frecuentes",
        })
        navigate((allianceSettingResponse?.data?.name === 'Finsus'
          || userResponse?.data?.business?.name === 'Finsus') ? '/finsus/preguntas-frecuentes' : '/preguntas-frecuentes')
      }
    },
    {
      item: 'Cerrar sesión',
      onClick: () => {
        window.dataLayer.push({
          event: 'TRUS_menu',
          option: "Cerrar sesion",
        })
        sessionStorage.setItem('userError', '2');
        dispatch(restartValue());
        dispatch(restartUserValue());
        dispatch(restartBeneficiaryValue());
        dispatch(restartAllianceSetting());
        dispatch(restartStatus());
        localStorage.clear();
        logout(webAuth);
      }
    }
  ]

  React.useEffect(() => {
    window.dataLayer.push({
      event: 'TRUS_page_v',
      page_title: window.document.title,
      page_location: window.location.href,
    })
    //Habilitar url para mantenimiento
    //navigate("/mantenimiento")
  }, [window.location.pathname])

  React.useEffect(() => {
    if (sessionStorage?.access_token) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStorage?.access_token]);

  React.useEffect(() => {
    if (!isAuthState) {
      // dispatch(restartUserValue())
    }
  }, []);

  return (
    <React.Fragment>
      <AppBar component="nav" position="fixed" style={{ background: '#FFFDFE', zIndex: 2000 }}>
        <CustomContainer childComponent={<MenuContent desktopStylish={
          (allianceSettingResponse?.data?.name === 'Finsus'
            || userResponse?.data?.business?.name === 'Finsus') ?
            { justifyContent: 'flex-end', gap: '1rem' } :
            {}} 
          isAlliance={allianceSettingResponse || userResponse?.data?.business}
          isAuthState={isAuthState}
          setOpenModal={setOpenModal}
          body={isAuthState ? isDesktop ? MENUBODYAUTH : MENUBODYAUTHMOVIL : MENUBODY}
          visibleMenu={!blackListPaths.includes(pathname)} />} />
        <BasicModal open={openModal} setOpenModal={setOpenModal} disableBackdropClick={false} />
      </AppBar>
      <Toolbar style={{
        width: 60
      }} />
    </React.Fragment>
  );
};

export default Navbar;
