import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom'
import { useMediaQuery, useTheme } from "@mui/material";
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from "../../../store/apiSlice";
import BasicModal from "../../molecules/BasicModal/BasicModal";
import { MembershipColor } from "../Membership/MembershipMainValues";
import Avatar from '../../organisms/Modal/assets/maniPose.png'
import CardAction from "../Card";


interface IDialogProps {
    exit: () => void;
}

interface IRenderModalProps {
    child: React.ReactNode;
    closeModal?: boolean;
}

function Modal(props: IDialogProps) {
    const { exit } = props
    const [open, setOpen] = useState(true)
    const { breakpoints } = useTheme();

    const isMobile = useMediaQuery(breakpoints.down('sm'));
  
    const onClick = () => {
      setOpen(false)
      exit();
    };
  
    return (
        // <Dialog
        //     sx={{
        //         "& .MuiDialog-paper": {
        //             backgroundColor: "transparent",
        //             gap: "1rem",
        //             maxWidth: 728,
        //             boxShadow: "none",
        //             borderRadius: 0,
        //         },
        //     }}
        //     open={open}
        //     onClose={handleClose}
        // >
        //     <div
        //         style={{
        //         display: "flex",
        //         alignItems: "center",
        //         justifyContent: "flex-end",
        //         }}
        //     >
        //         <IconButton
        //             aria-label="close"
        //             onClick={handleClose}
        //             sx={{
        //                 right: 0,
        //                 color: (theme) => theme.palette.grey[500],
        //                 backgroundColor: "#FFF",
        //                 margin: ".5rem 0",
        //                 width: 32,
        //                 height: 32,
        //                 "&:hover": {
        //                 backgroundColor: "#FFF",
        //                 },
        //             }}
        //         >
        //     <CloseOutlined />
        //     </IconButton>
        // </div>
        //     <DialogContent>
        //         {child}
        //     </DialogContent>
        // </Dialog>
        <BasicModal
            styleRoot={{
            maxWidth: 728,
            boxShadow: "none",
            gap: "2px",
            marginTop: "62px",
            }}
            styleContent={{
            margin: 0,
            padding: 0,
            }}
            disableBackdropClick={true}
            closeButton
            open={open}
            setOpenModal={setOpen}
            onClick={onClick}
            bodyDialog={
                <CardAction
                    buttonText={"Entendido"}
                    onClick={onClick}
                    imgSrc={Avatar}
                    cardContent={
                        <div style={{paddingTop: isMobile ? "0px" : "40px"}}>
                            <span style={{
                                fontSize: 18,
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: 'normal',
                            }}>Para proteger tu información, hemos cerrado tu sesión.
                                <br></br>
                            </span>
                        </div>
                    }
                    style={{
                    backgroundColor: MembershipColor.free,
                    border: "1px solid " + MembershipColor.free,
                    }}
                    styleTextContent={{
                    fontSize: "17px",
                    }}
                    styleImg={{
                    backgroundColor: `var(--azul-25, ${MembershipColor.freeOpacity})`,
                    padding: 0,
                    display: "flex",
                    flexWrap: "wrap",
                    alignContent: "flex-end",
                    }}
                    styleImgCard={{
                    padding: 0,
                    }}
                    styleButton={{
                    color: MembershipColor.free,
                    backgroundColor:  "#fff",
                    border: `1px solid #fff`
                    }}
                    styleButtonSecondary={{
                    color: MembershipColor.free,
                    }}
                />
            }
        />
      );
  }
  
  const RenderModal = () => {
    const div = document.createElement('div')
    document.body.appendChild(div)
  
    const exit = () => {
      ReactDOM.unmountComponentAtNode(div)
    }
  
    ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter>
          <Modal exit={exit} />
        </BrowserRouter>
      </Provider>
      , div
    )
  }
  
  export default RenderModal