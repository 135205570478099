import React from 'react';

interface CircleStepsProps {
    width?: number;
    height?: number;
    fill?: string;
    stroke?: string;
}

const CircleSteps: React.FC<CircleStepsProps> = ({
                                                     width = 57,
                                                     height = 57,
                                                     fill = '#595959',
                                                     stroke = "#c7c7c7"
                                                 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 64 65" fill="none">
            <circle cx="32" cy="32.8811" r="30.5" fill={fill} stroke={stroke}/>
        </svg>
    );
};

export default CircleSteps;
