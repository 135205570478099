import * as React from 'react';
import AccordionDetails from '@mui/material/AccordionDetails';
import QuestionsPlus from '../../../assets/Questions/QuestionsPlus';
// import Link from '@mui/material/Link';
import { Link } from 'react-router-dom';
import '../QuestionsAlliance/question_alliance.css';
import { Accordion, AccordionSummary, Typography } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import { pageData } from '../../templates/AllianceFAQs/FAQs'
import {useSelector} from "react-redux";

export default function BasicAccordionAlliance() {

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [expandedSecond, setExpanded2] = React.useState<string | false>(false);
  const [expandedThird, setExpanded3] = React.useState<string | false>(false);
  const [expandedFourth, setExpanded4] = React.useState<string | false>(false);
  const [expandedFived, setExpanded5] = React.useState<string | false>(false);
  const [expandedSix, setExpanded6] = React.useState<string | false>(false);

  const validateOption = (id: number) => {
    if (id === 0) {
      return expanded
    } else if (id === 1) {
      return  expandedSecond
    } else if (id === 2) {
      return  expandedThird
    } else if (id === 3) {
      return  expandedFourth
    } else if (id ===4) {
      return  expandedFived
    } else if (id ===5) {
      return  expandedSix
    }
  }

  const validateOptionSet = (id: number, isExpanded: boolean , panel:string) => {
    if (id === 0) {
      setExpanded(isExpanded ? panel : false)
    } else if (id === 1) {
      setExpanded2(isExpanded ? panel : false)
    } else if (id === 2) {
      setExpanded3(isExpanded ? panel : false)
    } else if (id === 3) {
      setExpanded4(isExpanded ? panel : false)
    } else if (id === 4) {
      setExpanded5(isExpanded ? panel : false)
    }else if (id === 5) {
      setExpanded6(isExpanded ? panel : false)
    }
  }

  const handleChange =
      (panel: string,id:number,title:string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        validateOptionSet(id,isExpanded, panel)
        if (isExpanded) {
          window.dataLayer.push({
            event: 'TRUS_faq',
            faq: title,
          })
        }

      };


  const AcordionCustom: React.CSSProperties = {
    boxShadow: 'unset',
    borderRadius: '0px',
    textAlign:'left',
  }
  const AcordionContainer: React.CSSProperties = {
    borderBottom: '2px solid gray'
  }

  const allianceSettingResponse = useSelector((state: any) => state.allianceSetting.response);

  return (
    <div>
      <Typography
        color={'var(--black, #212121)'}
        textAlign={'center'}
        fontSize={'20px'}
        fontStyle={'normal'}
        fontWeight={700}
      >
        Preguntas Frecuentes
      </Typography>

      {
        allianceSettingResponse && allianceSettingResponse?.data?.faqs?.slice(0,6).map((item:any, index:number) =>
            (
                <div style={AcordionContainer} key={index}>
                  <Accordion style={AcordionCustom} expanded={validateOption(index)   === 'panel'+index}
                             onChange={handleChange('panel' + index, index, item.question)}>
                    <AccordionSummary
                        expandIcon={validateOption(index) === 'panel' +index ? < RemoveIcon/> : < QuestionsPlus/>}
                    >
                      <Typography
                          fontFamily={'Montserrat'}
                          color={'var(--black-75, #595959)'}
                          fontSize={'16px'}
                          fontWeight={700}

                      >{item.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                          fontFamily={'Montserrat'}
                          color={'var(--black-75, #212121)'}
                          fontSize={'16px'}
                          fontWeight={400}
                      >
                        {item.answer}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
            )
        )
      }


      <Typography
        color={'var(--black, #212121)'}
        fontSize={'16px'}
        fontWeight={400}
        style={{
          cursor: 'pointer',
          marginTop: '40px'
        }}
        textAlign={"center"}

      >
        Para más info consulta nuestra sección de
      </Typography>
      <Link to={'/preguntas-frecuentes-alianza'}>
        <Typography

          color={'var(--black, #009DD0)'}
          fontSize={'16px'}
          fontWeight={400}
          style={{ cursor: 'pointer' }}
          textAlign={"center"}
        >
          Preguntas Frecuentes
        </Typography>
      </Link>
    </div>
  );
}
