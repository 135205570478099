import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, AccordionSummary, Typography, AccordionDetails, Button, IconButton, Box } from '@mui/material';
import styled from 'styled-components';

import { scrollToTop } from '../../../utils/scrollToTop';
import ExpandMore from '@mui/icons-material/ExpandMore';
import EditIcon from '../../../assets/EditIcon';
import RemoveIcon from '../../../assets/RemoveIcon';
import BasicModal from '../../molecules/BasicModal/BasicModal';
import DeleteBeneficiaryModal from '../../../pages/AccidentalDeath/Beneficiary/Modals/DeleteBeneficiaryModal';
import { restartValue } from '../../../api/multipleBeneficiarySlice';

type AccordeonActionProps = {
  data?: Array<any>;
  editIconHandler: any;
  getUser?: any;
}

const AccordeonAction: FC<AccordeonActionProps> = ({ data, editIconHandler, getUser }) => {
  const dispatch = useDispatch()
  const response = useSelector((state: any) => state.multipleBeneficiaries?.response) as any | null;
  const [expanded, setExpanded] = useState<any>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [beneficiary, setBeneficiary] = useState<any>(null)

  const ContainerButtons = styled.div`
        display: flex;
        gap: '8px';
    `;

  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    if (isExpanded) {
      setExpanded([...expanded, panel]); // Agrega el panel abierto al arreglo
    } else {
      const reduce = expanded.filter(((item: any) => item !== panel));
      setExpanded(reduce); // Elimina el panel cerrado del arreglo
    }
  }

  useEffect(() => {
    if (data && data?.length > 0) {
      setExpanded([0]);
      scrollToTop();
    }
  }, [data]);

  useEffect(() => {
    if (response?.ok) {
      setOpenModal(false)
      getUser()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const deleteIconHandler = (beneficiary: any, index: number) => {
    setOpenModal(true)
    setBeneficiary({ ...beneficiary, index })
  }

  const closeModal = () => {
    setOpenModal(false)
    dispatch(restartValue())
  }

  return (
    <div>
      <BasicModal
        styleRoot={{
          maxWidth: 728,
          boxShadow: "none",
          borderRadius: 0,
        }}
        styleContent={{
          margin: 0,
          padding: 0,
        }}
        closeButton
        open={openModal}
        setOpenModal={closeModal}
        bodyDialog={<DeleteBeneficiaryModal beneficiary={beneficiary} setOpenModal={closeModal} />}
      />
      {data && data?.length > 0 && data.map((items, index) => {
        return (
          <Accordion
            sx={{
              padding: '0px',
              margin: 0
            }}
            style={{
              boxShadow: 'none',
            }}
            key={index} expanded={expanded.includes(index)} onChange={handleChange(index)} >
            <AccordionSummary sx={{
              margin: 0,
              borderBottom: '1px solid #000'
            }} expandIcon={<ExpandMore style={{ color: '#000' }} />}
            >
              <Box style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}>
                <Typography
                  color={'var(--black, #212121)'}
                  fontSize={'16px'}
                  fontWeight={700}
                  sx={{
                    textWrap: 'balance',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    marginRight: '12px',
                    width: '10rem',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textAlign: 'initial'
                  }}
                >{items.title}</Typography>
                <ContainerButtons>
                  <IconButton onClick={() => editIconHandler(items.json, index + 1)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => deleteIconHandler(items, index)}>
                    <RemoveIcon />
                  </IconButton>
                </ContainerButtons>
              </Box>
            </AccordionSummary>
            <AccordionDetails>{items.description}</AccordionDetails>
          </Accordion>);
      })}
      <div style={{ height: '20vh' }}></div>
    </div>
  )
};

export default AccordeonAction;