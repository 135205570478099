/* eslint-disable no-restricted-globals */
import React, {
  useState,
  // useRef, 
  useContext
} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomTypography } from "../../atoms/Label/Label";
import maniposes from "../../../assets/CreateAccountAssets/Maniposes.svg";
import { styled } from "styled-components";
import LoginForm from "../LogInForm";
import { ContextAuth0 } from "../../../auth/contextAPIProvider";
import { FacebookIcon } from "../../../assets/SocialMediaIcons/FacebookIcon";
import { GoogleAuth2Icon } from "../../../assets/SocialMediaIcons/Google-auth2";
import './ReferredComponent.css';

import guy from '../../../assets/ManiPosesGuyStadistics/guy.png';
import stadistic from '../../../assets/ManiPosesGuyStadistics/stadistics.png';
import BasicModal from "../../molecules/BasicModal/BasicModal";
import ManiPosesBody from "../../../assets/ManiErrorHandler/ManiPosesErrorHandler.jpg";
import CardAction from "../Card";
import { CustomButton } from "../../atoms/Buttons/Buttons";
import { ArrowBack } from "@mui/icons-material";
import BASE_CONFIG from "../../../env/env";

const LayOutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 558px;
  margin: auto;
`;

const SocialButton = styled.button`
  display: flex;
  padding: 14px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #efefef;
  font-weight: 600;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  cursor: pointer;
  text-transform: uppercase;
  `;

type LoginProps = {};

const Login: React.FC<LoginProps> = () => {
  const [login, setLogin] = useState("landingLogin");
  const navigate = useNavigate();
  const location = useLocation();
  const path = location?.state?.path

  const webAuth: any = useContext(ContextAuth0);
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const [open, setOpenModal] = useState<boolean>(false);
  const [isBack, setBack] = useState<boolean>(false);

  const onClick = () => {
    setOpenModal(false);
    sessionStorage.setItem('userError', '0');
  };
  const onClick2 = () => {
    setOpenModal(false);
    sessionStorage.setItem('userError', '0');
    navigate("/");
  };

  React.useEffect(() => {
    if (sessionStorage?.access_token) {
      //navigate('/portal-del-cliente', { replace: true });
    }
    if (sessionStorage?.userError == '1') {
      setOpenModal(true);
      setBack(true);
    }
    if (sessionStorage?.userError == '2') {
      setBack(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignUpLoginRedirect = (connectTo: string) => {
    window.dataLayer.push({
      event: 'TRUS_iniciar_sesion',
      option: 'Google',
      boton_contacto: 'Inicia con Google'
    })
    webAuth && webAuth.authorize({
      connection: connectTo,
      prompt: 'login',
      redirect_uri: `${BASE_CONFIG.base}/redirect`,
      scope: 'openid profile email',
      responseType: 'code token id_token',
      state: path === "/documentos-adicionales" ? '/documentos-adicionales' : "login"
    })
  }

  React.useEffect(() => {
    window.dataLayer.push({
      'event': 'TRUS_iniciar_sesion_v',
      tipo_seguro: undefined,
      'correo': undefined,
      'telefono': undefined,
      'page_location': window.location.href,
    });
  }, [])

  return (
    <div style={{ marginTop: 40, marginBottom: 40 }}>
      <CustomButton
        text={"Regresar"}
        onClick={() => isBack == true ? navigate("/") : navigate(-1)}
        size={"medium"}
        startIcon={<ArrowBack />}
        variant={"contained"}
        style={{
          color: "#039ECC",
          backgroundColor: "transparent",
          fontSize: "1rem",
          textTransform: "none",
          fontWeight: "600",
          fontStyle: "normal",
          padding: "0px",
          minWidth: "64px",
          border: "none",
          justifyContent: "flex-start",
          width: "fit-content",
        }}
      />
      <LayOutContainer>
        <BasicModal
          styleRoot={{
            maxWidth: 728,
            boxShadow: "none",
            gap: "2px",
            marginTop: "62px",
          }}
          styleContent={{
            margin: 0,
            padding: 0,
          }}
          disableBackdropClick={true}
          closeButton
          open={open}
          setOpenModal={setOpenModal}
          onClick={onClick}
          bodyDialog={
            <CardAction
              onClick={onClick}
              onClickSecondary={onClick2}
              buttonText={"Entendido"}
              buttonTextSecondary="Registrarme"
              cardContent={
                "¡Oops! Esta cuenta no esta registrada, asegurate de que hayas escrito correctamente tu correo y si aun no estas registrado te invitamos a que te sumes a #TeRegalamosUnSeguro."
              }
              imgSrc={ManiPosesBody}
              secundaryButton={true}
              style={{
                backgroundColor: "#039ECC",
                border: "1px solid #039ECC",
              }}
              styleTextContent={{
                fontSize: "17px",
              }}
              styleImg={{
                backgroundColor: "#C0E6F2",
                padding: 0,
                display: "flex",
                flexWrap: "wrap",
                alignContent: "flex-end",
              }}
              styleImgCard={{
                padding: 0,
              }}
              styleButton={{
                color: "#039ECC",
              }}
              styleButtonSecondary={{
                color: "#039ECC",
              }}
            />
          }
        />
        <CustomTypography
          style={{
            fontSize: 24,
            fontWeight: 700,
            letterSpacing: "0.5px",
            textAlign: "center",
            Padding: "0 1rem",
            textWrap: "balance",
          }}
        >
          #TeRegalamosUnSeguro
        </CustomTypography>
        <CustomTypography
          style={{
            color: 'var(--Black, #212121)',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal',
            letterSpacing: '0.5px',
          }}
        >
          Iniciar sesión
        </CustomTypography>

        <div className='imageBanner'>
          <div className="guy">
            <img src={guy} alt="" width={250} />
          </div>
          <div className="stadistics">
            <img src={stadistic} alt="" width={250} />
          </div>
        </div>
        <LoginForm></LoginForm>

        {login === "formLogin" ? (
          <LoginForm redirectTo={path} />
        ) : (
          <React.Fragment>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: 16,
                alignItems: "center",
              }}
            >
              <CustomTypography
                style={{
                  fontWeight: 400,
                  fontSize: 18,
                  fontStyle: "normal",
                }}
              >
                o
              </CustomTypography>
              <SocialButton
                onClick={() => handleSignUpLoginRedirect('google-oauth2')}
                style={{ border: "none", backgroundColor: "#EFEFEF" }}
              >
                <GoogleAuth2Icon />
                Inicia con Gmail
              </SocialButton>
              {/* <SocialButton
                onClick={() => handleSignUpLoginRedirect('facebook')}
                style={{
                  backgroundColor: "#3B5998",
                  color: "#F2F2F2",
                  border: "none",
                }}
              >
                <FacebookIcon />
                Inicia con Facebook
              </SocialButton> */}

              {
                /*
                <SocialButton
                                style={{
                                  color: "#039ECC",
                                  backgroundColor: "#FFFFFF",
                                  textAlign: "center",
                                  fontSize: 16,
                                  fontStyle: "normal",
                                  fontWeight: 600,
                                  lineHeight: "normal",
                                  textTransform: "uppercase",
                                  borderRadius: 8,
                                  border: "1.5px solid #039ECC",
                                }}
                                onClick={() => {
                                  setLogin("formLogin")
                                  window.dataLayer.push({
                                    event: 'TRUS_iniciar_sesion',
                                    option: 'Telefono',
                                    boton_contacto: 'Inicia con tu telefono'
                                  })
                                }}
                              >
                                Inicia con tu télefono
                              </SocialButton>
                */
              }
            </div>
          </React.Fragment>
        )}


      </LayOutContainer>
    </div>
  );
};

export default Login;
