import React from 'react';
// import styled from 'styled-components';
import { BenefitsDetails } from '../../CustomerPortalContent';
import BenefitItemComponent from './benefit.item';
import { IUserData } from '../../interfaces/user';
import { useSelector } from 'react-redux';
import { benefitGetKeyByName } from './benefit.tools';
// import { MembershipCategoryType } from '../../../Membership/MembershipMainValues';
import { MembershipPlanItem } from '../../interfaces/membership';
import { BenefitValues, BenefitValuesType } from './benefit.values';
// import BenefitModalComponent from './benefit.modal';
// import { CapitalizeText } from '../../../../../utils/utils';



interface BenefitEngineComponentProps {
    benefits: BenefitsDetails[];
    created_at: string;
    membership: MembershipPlanItem;
}
const BenefitEnigineComponent: React.FC<BenefitEngineComponentProps> = ({
    benefits,
    created_at,
    membership
}) => {
    const userResponse: IUserData = useSelector((state: any) => state?.user?.response?.data);
    const defaultWaitDays: number = 30;

    return (
        userResponse && <div>
            {benefits.map((benefit, index) => {
                const benefitKey: BenefitValuesType | undefined = benefitGetKeyByName(benefit.title);
                const renderSalud: boolean = (membership.benefitId === benefit.benefit_id || benefit.title === BenefitValues.Muerte)
                return (benefitKey && ((membership.category === "salud" && renderSalud) || membership.category === 'celular')) &&
                    <BenefitItemComponent
                        membership={membership}
                        key={benefitKey}
                        benefitKey={benefitKey}
                        defautWaitDays={defaultWaitDays}
                        created_at={created_at}
                        benefit={benefit}
                        user={{
                            imei: (userResponse?.user?.imei || userResponse?.gadgets?.imei) ? true : false,
                            beneficiary: userResponse?.beneficiaries && userResponse.beneficiaries.length > 0 ? true : false,
                            fullname: `${userResponse.user.name} ${userResponse.user.middle_name} ${userResponse.user.last_name}`
                        }}
                        index={index}
                    />
            })}
        </div>
    )
}
export default BenefitEnigineComponent
