import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ReactComponent as CelularProtectActivo } from '../../organisms/MembershipCard/assets/CelularProtectActive.svg';
import { ReactComponent as CelularProtectInactivo } from '../../organisms/MembershipCard/assets/CelularProtectInactivo.svg';
import { ReactComponent as SaludProtectActivo } from '../../organisms/MembershipCard/assets/SaludActivo.svg';
import { ReactComponent as SaludProtectFinsusActivo } from '../../organisms/MembershipCard/assets/SaludActivoFinsus.svg';
import { ReactComponent as SaludProtectInactivo } from '../../organisms/MembershipCard/assets/SaludInactivo.svg';
import ActiveFinsus from '../../organisms/MembershipCard/assets/ActiveFinsus';
import { themeValues, myColorPalette } from '../../../theme/themeGenerated';




interface TabMembershipI {
    typeCategory: string
    setTypeCategory: (typeSelect: string) => void,

}
const TabMembership: React.FC<TabMembershipI> = ({ typeCategory, setTypeCategory }) => {
    const getType = (val: string) => {
        switch (val) {
            case "celular":
                return 0
            case "salud":
                return 1
            default:
                return 0
        }
    }

    const [value, setValue] = React.useState(getType(typeCategory));

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const validateType = (val: number) => {
        switch (val) {
            case 0:
                setTypeCategory("celular")
                break;
            case 1:
                setTypeCategory("salud")
                break;
            default:
                setTypeCategory("celular")
        }
    }

    useEffect(() => {
        validateType(value)
    }, [value]);



    return (<Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        // background: 'red'

    }}>
        <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            TabIndicatorProps={
                {
                    style: {
                        backgroundColor: window.location.pathname === "/finsus" ? myColorPalette.getFinsusSecondary() :
                            ''
                    }
                }}
        >

            <Tab label="" icon={value === 0 ?
                window.location.pathname === "/finsus" ? <ActiveFinsus /> : <CelularProtectActivo />




                : <CelularProtectInactivo />} />


            <Tab label="" icon={value === 1 ? window.location.pathname === "/finsus" ? <SaludProtectFinsusActivo /> : <SaludProtectActivo style={{ fill: 'red' }} /> : <SaludProtectInactivo />} />
        </Tabs>

    </Box>)
}
export default TabMembership
