import React, { useEffect } from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { AppleButton } from './AppleButton';
import { GoogleButton } from './GoogleButton';
import LogoLight from './assets/logo-light.png';
import TikTokIcon from './assets/TiktokIcon';

import XforelonMusk from './assets/XForElonMusk';
import { useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import SocialMediaComponent from '../../pages/Home/SocialMedia';
import { themeValues, myColorPalette } from '../../theme/themeGenerated';



interface WelcomeProps {
    // Define las propiedades del componente aquí
}

const FooterInter: React.FC<WelcomeProps> = () => {
    const [isAuthState, setIsAuth] = React.useState<boolean>(false);

    const allianceSettingResponse = useSelector((state: any) => state.allianceSetting.response);
    const userResponse = useSelector((state: any) => state?.user?.response);

    const year = new Date().getFullYear();

    useEffect(() => {
        // myColorPalette.setFinsusSecondary('#23223F')

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    React.useEffect(() => {
        if (sessionStorage?.access_token) {
            setIsAuth(true);
        } else {
            setIsAuth(false);
        }
    }, [sessionStorage?.access_token]);
    // Implementa el componente aquí
    return (
        <Box
            component={'footer'}
            sx={{
                bgcolor:
                    (window.location.pathname === "/finsus" ||
                        userResponse?.data?.business?.name === 'Finsus') ? myColorPalette.getFinsusFooter() :
                        'var(--Black, #212121)'
            }}
            bottom="0px"
        >
            <Container
                maxWidth={'xl'}
                style={{
                    width: '100%',
                    marginTop: '20px'
                }}>
                <Grid container spacing={5}
                    marginTop="40px"
                    marginLeft="0px"
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-evenly'
                    }}>
                    <Grid item xs={12} md={!isAuthState && allianceSettingResponse?.data?.imagenes?.logo_white ? 4 : 2}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={2}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: (allianceSettingResponse?.data?.imagenes?.logo_white || userResponse?.data?.imagenes?.logo_white) ? 'space-around' : ''
                                }}

                            >
                                {
                                    (allianceSettingResponse?.data?.imagenes?.logo_white || userResponse?.data?.imagenes?.logo_white) &&
                                    <Box
                                        component={'img'}
                                        src={allianceSettingResponse?.data?.imagenes?.logo_white || userResponse?.data?.business?.imagenes?.logo_white}
                                        sx={{ width: 'auto', height: 36 }}
                                        alt={'LogoAlianza'}
                                    />
                                }

                                <Box
                                    component={'img'}
                                    src={LogoLight}
                                    sx={{ width: 'auto', height: 36 }}
                                    alt={'Inter, seguro, seguro'}
                                />
                            </div>

                        </Stack>
                    </Grid>


                    <Grid item xs={12} md={2}>
                        {!isAuthState && allianceSettingResponse ?
                            null
                            : <>
                                <Typography
                                    variant={'subtitle1'}
                                    color={'white'}
                                    fontWeight={'700'}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {'Productos'}
                                </Typography>
                                <br></br>
                                <Link href="https://cocheseguro.inter.mx/"
                                    target={'_blank'}>
                                    <Typography
                                        variant={'subtitle2'}
                                        onClick={
                                            () => {
                                                window.dataLayer.push({
                                                    event: 'ev_btn_footer', //Static data
                                                    option: 'Coche Seguro', //Dynamic data
                                                })
                                            }
                                        }
                                        color={'white'}
                                        fontSize={"16px"}
                                        fontWeight={'400'}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {'Coche Seguro'}
                                    </Typography>
                                </Link>
                                <Link href="https://medicoseguro.inter.mx/inicio" target={'_blank'}>
                                    <Typography
                                        variant={'subtitle2'}
                                        color={'white'}
                                        onClick={
                                            () => {
                                                window.dataLayer.push({
                                                    event: 'ev_btn_footer', //Static data
                                                    option: 'Medico Seguro', //Dynamic data
                                                })
                                            }
                                        }
                                        fontWeight={'400'}

                                        fontSize={"16px"}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {'Médico Seguro'}
                                    </Typography>
                                </Link>
                                <Link href="https://mascotasegura.inter.mx/" target={'_blank'}>
                                    <Typography
                                        variant={'subtitle2'}
                                        onClick={
                                            () => {
                                                window.dataLayer.push({
                                                    event: 'ev_btn_footer', //Static data
                                                    option: 'Mascota Segura', //Dynamic data
                                                })
                                            }
                                        }
                                        color={'white'}

                                        fontWeight={'400'}
                                        style={{ cursor: 'pointer' }}
                                        fontSize={"16px"}
                                    >
                                        {'Mascota Segura'}
                                    </Typography>
                                </Link>
                                <br>
                                </br>

                                <Box marginBottom={"0px"} />
                            </>}
                        <Typography variant={'subtitle1'} color={'white'} fontWeight={'bold'}>
                            {'Clientes'}
                        </Typography>
                        <br></br>
                        <Link href=" https://inter.mx/aviso-de-privacidad"
                            target={'_blank'}>
                            <Typography
                                variant={'subtitle2'}
                                color={'white'}
                                fontWeight={'400'}
                                style={{ cursor: 'pointer' }}
                                fontSize={"16px"}
                            >
                                {'Aviso de Privacidad'}
                            </Typography>
                        </Link>

                        {
                            (window.location.pathname === "/finsus" || userResponse?.data?.business?.name === 'Finsus') ?
                                <Link href="/finsus/terminos-y-condiciones" target={'_blank'}>

                                    <Typography
                                        variant={'subtitle2'}
                                        color={'white'}
                                        fontWeight={'400'}
                                        style={{ cursor: 'pointer' }}
                                        fontSize={"16px"}
                                    >
                                        {'Terminos y condiciones'}
                                    </Typography>
                                </Link>
                                :
                                <Link href="/terminos-y-condiciones" target={'_blank'}>

                                    <Typography
                                        variant={'subtitle2'}
                                        color={'white'}
                                        fontWeight={'400'}
                                        style={{ cursor: 'pointer' }}
                                        fontSize={"16px"}
                                    >
                                        {'Terminos y condiciones'}
                                    </Typography>
                                </Link>

                        }

                    </Grid>

                    <Grid item xs={12} md={2}>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography
                                variant={'subtitle1'}
                                color={'white'}
                                fontWeight={'bold'}
                                style={{ cursor: 'pointer' }}
                            >
                                {'Contáctanos'}
                            </Typography>
                            <br></br>
                            <Box marginBottom={"0px"} />
                            <Typography
                                variant={'subtitle2'}
                                color={'white'}
                                title={'hola@inter.mx'}
                                fontWeight={'400'}
                                style={{
                                    cursor: 'pointer',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                fontSize={"16px"}
                            >
                                {'55 442 46837'}
                            </Typography>
                            <br></br>
                            <Typography
                                variant={'subtitle2'}

                                color={'white'}
                                title={'hola@inter.mx'}
                                fontWeight={'400'}
                                style={{
                                    cursor: 'pointer',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                fontSize={"16px"}
                            >
                                {'hola@inter.mx'}
                            </Typography>
                            <br></br>
                            {!isAuthState && allianceSettingResponse ?
                                null
                                :
                                <>
                                    <Typography
                                        color={'white'}
                                        title={'hola@inter.mx'}
                                        fontWeight={'400'}
                                        style={{
                                            cursor: 'pointer',
                                            whiteSpace: 'normal',
                                            overflow: 'auto',
                                        }}
                                        fontSize={"16px"}
                                    >
                                        {'Miguel de Cervantes Saavedra No. 301, Torre Sur Piso 17, Colonia Granada, Delegación Miguel Hidalgo, C.P. 11520, Ciudad de México, México'}
                                    </Typography>
                                    <br></br>
                                </>}

                        </Box>
                    </Grid>
                    {
                        window.location.pathname === "/finsus" ?
                            <SocialMediaComponent /> :
                            null

                    }


                    {!isAuthState && allianceSettingResponse ?
                        null
                        :
                        <SocialMediaComponent />

                    }

                </Grid>

                <Box
                    paddingY={1}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    marginTop={'40px'}
                >
                    <Typography
                        textAlign={'center'}
                        variant={'caption'}
                        color={'white'}
                        style={{ fontWeight: 300 }}
                    >
                        {`Copyright ${year} ® Interprotección`}

                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default FooterInter;
