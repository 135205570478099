import React from 'react';

import giffy from '../../../assets/payment/gifgif.gif';
import BasicModal from '../../../components/molecules/BasicModal/BasicModal';
import CardAction from '../../../components/organisms/Card';
import { useLocation } from 'react-router-dom';

type ModalSuccessBeneficiaryAddedProps = {}

const ModalSuccessBeneficiaryAdded: React.FC<ModalSuccessBeneficiaryAddedProps> = () => {
    const [open, setOpen ] = React.useState<boolean>(false);
    const location = useLocation();

    React.useEffect(() => {
        if(sessionStorage?.beneficiaryAdded){
            sessionStorage.removeItem('beneficiaryAdded');
            setOpen(true);
        }
    }, []);

    return (
        <BasicModal
            styleRoot={{
            maxWidth: 728,
            boxShadow: "none",
            gap: "2px",
            marginTop: "62px",
            }}
            styleContent={{
            margin: 0,
            padding: 0,
            }}
            disableBackdropClick={true}
            closeButton={false}
            open={open}
            setOpenModal={setOpen}
            onClick={() => setOpen(false)}
            bodyDialog={
            <CardAction
                onClick={() => setOpen(false)}
                buttonText={"Entendido"}
                cardContent={`Has ${location.state?.edit ? 'editado' : 'agregado'} con éxito a tu beneficiario`}
                imgSrc={giffy}
                style={{
                    backgroundColor: "#FFF",
                    border: "1px solid #FFF",
                }}
                styleTextContent={{ 
                    color: '#424242',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '28px',
                    letterSpacing: '0.25px'
                }}
                styleImg={{
                    backgroundColor: "#FFFFFF",
                    padding: 0,
                    display: "flex",
                    flexWrap: "wrap",
                    alignContent: "flex-end",
                }}
                styleImgCard={{padding: 0 }}
                styleButton={{ 
                    border: 'none', 
                    backgroundColor: "#039ECC", 
                    color: '#FFF' 
                }}
            />
        }
      />
    );
}

export default ModalSuccessBeneficiaryAdded;