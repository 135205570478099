import React from 'react';
import styled from 'styled-components';
import BackButtonInter from '../../../atoms/Buttons/BackButtonInter';
import { Box, Typography } from '@mui/material';
import Check from '../assets/check.png';
import ButtonInter from '../../../atoms/Buttons/ButtonInter';
import { useNavigate } from 'react-router-dom';
import { BucketStorageFiles } from '../../../../utils/bucket.storage';
import { useSelector } from 'react-redux';
import { InterInstance } from '../../../../api/config';

const ContainerNotes = styled.div`
    padding:15px;
    max-width: 850px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin:auto;
`
const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const NoteDetails: React.FC = () => {
    const navigate = useNavigate();

    return (<ContainerNotes>
        <BackButtonInter />
        <MessageContainer>
            <Typography style={{
                alignContent: 'center'
            }} >
                <strong>
                    Antes de continuar, te dejamos estas recomendaciones para que todo salga perfecto
                </strong>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <img src={Check}></img>
                </Box>
            </Typography>
            <Box >
                <ul style={{
                    display: 'flex',
                    gap: '24px',
                    flexDirection: 'column'
                }}>
                    <li>
                        <strong>Respalda tu celular</strong>, esto lo pedimos para proteger tus datos personales, para repararlo tus datos serán <strong>borrados por seguridad</strong>.
                    </li>
                    <li>
                        Una vez que recibas la guía de paquetería, <strong>sigue las indicaciones de la misma</strong>, para entregar tu celular.
                    </li>
                    <li>
                        Cuando recibamos tu dispositivo, iniciaremos el análisis y reparación para <strong>regresártelo en un lapso de 96 horas.</strong>
                    </li>
                    <li>
                        <strong>En tu portal de cliente podrás rastrear el avance de tu reparación </strong> para que estes seguro seguro de que estamos trabajando en ella.
                    </li>
                    <li>
                        Si tu equipo ha sido manipulado o tiene elementos externos como agua, piezas no originales o bien no es modelo señalado <strong>se cancelara la reparación.</strong>
                    </li>
                </ul>
            </Box>
            <Box>
                <ButtonInter variant='contained' onClick={(() => {
                    navigate('/pago-pendiente')
                })}>
                    Continuar
                </ButtonInter>
            </Box>
        </MessageContainer>
    </ContainerNotes>)
}

export default NoteDetails