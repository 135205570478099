/* eslint-disable no-restricted-globals */
import React, {
  useState,
  // useRef, 
  useContext
} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomTypography } from "../../atoms/Label/Label";
import { styled } from "styled-components";
import { ContextAuth0 } from "../../../auth/contextAPIProvider";
import '../Login/ReferredComponent.css';

import guy from '../../../assets/ManiPosesGuyStadistics/mani-error.png';
import { CustomButton } from "../../atoms/Buttons/Buttons";
import { ArrowBack } from "@mui/icons-material";
import BASE_CONFIG from "../../../env/env";
import { PrimaryButton } from "../../molecules/CellPhoneRepairButtons";
import { Box } from "@mui/material";

const LayOutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 558px;
  margin: auto;
`;

type ErrorProps = {};

const ErrorPagina: React.FC<ErrorProps> = () => {
  const [login, setLogin] = useState("landingLogin");
  const navigate = useNavigate();

  const webAuth: any = useContext(ContextAuth0);
  const location = useLocation();
  const [open, setOpenModal] = useState<boolean>(false);
  const [isBack, setBack] = useState<boolean>(false);

  const onClick = () => {
    setOpenModal(false);
    sessionStorage.setItem('userError', '0');
  };
  const onClick2 = () => {
    setOpenModal(false);
    sessionStorage.setItem('userError', '0');
    navigate("/");
  };

  React.useEffect(() => {
    if (sessionStorage?.access_token) {
      //navigate('/portal-del-cliente', { replace: true });
    }
    if (sessionStorage?.userError == '1') {
      setOpenModal(true);
      setBack(true);
    }
    if (sessionStorage?.userError == '2') {
      setBack(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignUpLoginRedirect = (connectTo: string) => {
    window.dataLayer.push({
      event: 'TRUS_iniciar_sesion',
      option: 'Google',
      boton_contacto: 'Inicia con Google'
    })
    webAuth && webAuth.authorize({
      connection: connectTo,
      prompt: 'login',
      redirect_uri: `${BASE_CONFIG.base}/redirect`,
      scope: 'openid profile email',
      responseType: 'code token id_token',
      state: 'login'
    })
  }

  React.useEffect(() => {
    window.dataLayer.push({
      'event': 'TRUS_iniciar_sesion',
      'tipo_seguro': undefined,
      'correo': undefined,
      'telefono': undefined,
      'page_location': window.location.href,
    });
  }, [])

  return (
    <div style={{ marginTop: 40, marginBottom: 40 }}>
      <CustomButton
        text={"Regresar"}
        onClick={() => isBack == true ? navigate("/") : navigate(-1)}
        size={"medium"}
        startIcon={<ArrowBack />}
        variant={"contained"}
        style={{
          color: "#039ECC",
          backgroundColor: "transparent",
          fontSize: "1rem",
          textTransform: "none",
          fontWeight: "600",
          fontStyle: "normal",
          padding: "0px",
          minWidth: "64px",
          border: "none",
          justifyContent: "flex-start",
          width: "fit-content",
        }}
      />
      <LayOutContainer>
        <CustomTypography
          style={{
            fontSize: 24,
            fontWeight: 700,
            letterSpacing: "0.5px",
            textAlign: "center",
            Padding: "0 1rem",
            textWrap: "balance",
          }}
        >
          ¡Oops! Estamos presentando unos problemas con nuestra plataforma
        </CustomTypography>
        <br />
        <div className='imageBanner'>
          <div className="guy">
            <img src={guy} alt="" width={250} />
          </div>
        </div>
        <CustomTypography
          style={{
            fontSize: 20,
            fontWeight: 400,
            letterSpacing: "0.5px",
            textAlign: "center",
            Padding: "0 1rem",
            textWrap: "balance",
          }}
        >
          No te preocupes ya estamos trabajando para resolverlo. Regresa más tarde.
        </CustomTypography>
        <Box sx={{ mt: 5 }} />
        <PrimaryButton text={"Entendido"} onClick={() => {
          navigate("/")
        }} />
      </LayOutContainer>
    </div>
  );
};

export default ErrorPagina;
