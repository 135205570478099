import React from 'react';
import CrearCuentaTemplate from '../../components/templates/CrearCuenta';

type CrearCuentaProps = {};

const CrearCuenta: React.FC<CrearCuentaProps> = () => {
  return <CrearCuentaTemplate />;
};

export default CrearCuenta;
