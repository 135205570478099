import React from 'react'
import styled from 'styled-components';

export default function CheckUp() {
    const BlackText = styled.span`
        color: #212121;
        font-family: 'Montserrat';
        font-size: '16px';
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
    `;

    const Paragraph = styled.div`
        margin-top: 16px;
   `

    return (
        <>
            <Paragraph>
                <BlackText>Check up Médico (Química sanguínea de 24 elementos ego+bh):</BlackText>
            </Paragraph>
            A partir de una valoración médica por telemedicina y presentando la receta médica expedida, el asegurado puede solicitar una cita para realizarse un Check Up que consta de Química Sanguínea de 24 elementos, biometría hemática y examen general de orina, con laboratorio afiliados a la red de INTERPROTECCIÓN ASISTENCIAS

            <Paragraph>INTERPROTECCIÓN ASISTENCIAS realizará él envió del documento al Asegurado para que pueda acudir al laboratorio, dicho documento cuenta con una vigencia de 07 (siete) días naturales a partir de la fecha de envío. En caso de requerir un segundo envío del documento o reprogramar la cita, solo será por única ocasión adicional y tendrá solo 3 días para acudir al laboratorio y redimir el beneficio. INTERPROTECCIÓN ASISTENCIAS tiene la obligación de comunicarse con el Asegurado en un lapso de 48 horas posterior a la llamada inicial para notificar al Asegurado si se continua en búsqueda de proveedor, en caso de que no se cuente con proveedor en la localidad solicitada por el Asegurado final, INTERPROTECCIÓN ASISTENCIAS realizará la restitución del gasto erogado (reembolso) con un monto máximo de $600.00 (Seiscientos pesos 00/100 MXN) siempre y cuando el Asegurado haga el envío de la documentación que acredite la realización del estudio (factura del laboratorio, identificación oficial con fotografía y cuenta bancaria a nombre del Asegurado) a la liga de reembolso que indique INTERPROTECCIÓN ASISTENCIAS, conforme los proceso establecidos por este último. Una vez que INTERPROTECCIÓN ASISTENCIAS haya confirmado que la documentación está completa y correcta, se realizará el pago al Asegurado final. En caso de no ser recibida dicha documentación en este periodo de tiempo, el servicio quedará como cancelado y quedará disponible para el Asegurado.</Paragraph>

            <Paragraph>
                Así mismo, el Asegurado será responsable de acudir directamente ante al laboratorio para la entrega de los resultados; por la naturaleza del servicio INTERPROTECCIÓN ASISTENCIAS, no será responsable de los resultados, marcadores y estudios médicos realizados por los laboratorios mencionados, ya que no es el prestador directo, en caso de cualquier queja o reclamación, el Asegurado deberá acudir directamente con el proveedor del servicio final (el laboratorio) a fin de hacer valer sus derechos o solución. INTERPROTECCIÓN ASISTENCIAS coadyuvará en todo momento con el asegurado a efecto de hacer valer sus derechos ante el proveedor de laboratorio.
            </Paragraph>

            <Paragraph>
                El servicio deberá ser coordinado desde un inicio por INTERPROTECCIÓN ASISTENCIAS para hacer válido el beneficio.
            </Paragraph>

            <Paragraph>
                <BlackText>Monto cubierto:</BlackText> 1 evento durante la vigencia del plan.
            </Paragraph>

            <Paragraph>
                <BlackText>Edad de aceptación:</BlackText> de 18 a 69 años
            </Paragraph>

            <Paragraph>Aplica únicamente para el Asegurado.</Paragraph>
        </>
    )
}
