import React, { useState, useEffect } from 'react';
import { useTheme, useMediaQuery, Typography } from '@mui/material';
import './MembershipCoverage.css';
import seguro from './assets/seguro.png';
import celular from './assets/celular.png';
import avatarSalud from './assets/guy.png';
import avatarPhone from './assets/guy1.png'
import heart from './assets/heart.png';
import phone from './assets/phone.png';
import MembershipDetailCard from './MembershipDetailsCards/MembershipDetailCard';
import { useNavigate } from 'react-router-dom'
import { MembershipCategoryType, MembershipInfo, MembershipSubType, MembershipSubTypes, MembershipType } from '../MembershipMainValues';
import { IStorageMembershipData } from '../../../../utils/localStorageKeys';
import MembershipButton from './MembershipDetailsCards/MembershipButtons';
import { BenefitsDetails } from '../MembershipCard/MemberShipItem/MembershipItem';
import { useSelector, useDispatch } from 'react-redux';
import LoadingComponent from '../../loading/loading';
import { setCurrentValue } from '../../../../api/productsSlice';
import SmarthPhoneIcon from '../../MembershipCard/assets/SmarthPhoneIcon';
import VectorBackgroundIcon from '../../MembershipCard/assets/VectorBackgroundIcon';
import HeartIcon from '../../MembershipCard/assets/HeartIcon';
import { scrollToTop } from '../../../../utils/scrollToTop';

interface ReponseMembershipData {
    [MembershipSubTypes.elite]: MembershipInfo;
    [MembershipSubTypes.esencial]: MembershipInfo;
    [MembershipSubTypes.freemium]: MembershipInfo;
    [MembershipSubTypes.superior]: MembershipInfo;
    [MembershipSubTypes.premium]: MembershipInfo;
}


const MembershipCoverageComponent: React.FC = () => {
    const { breakpoints } = useTheme();
    const [isError, setIsError] = useState(false);
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const [isButtonFixed, setIsButtonFixed] = useState(false);
    const dispatch = useDispatch();
    const storage: IStorageMembershipData = useSelector((state: any) => state.productsCards.currentValue);
    const category: MembershipCategoryType = storage && storage.category ? storage.category : 'salud';

    let [type, setType] = useState<MembershipType>(storage && storage.type ? storage.type : "premium");

    let [subType, setSubType] = useState<MembershipSubType>(type === 'free' ? 'freemium' : storage && storage.subtype ? storage.subtype : storage && storage.category === 'celular' ? 'premium' : 'esencial');

    if (!storage.subtype) {
        let newStorage = { ...storage };
        newStorage.subtype = subType;
        dispatch(setCurrentValue(newStorage));
    }


    const ResponseHealth = useSelector((state: any) => state.health.response.data) as any | null;
    const LoadingHealth = useSelector((state: any) => state.health.loading);
    const ErrorHealth = useSelector((state: any) => state.health.error);

    const ResponseGadgets = useSelector((state: any) => state.gadgets.response.data) as any | null;
    const LoadingGadgets = useSelector((state: any) => state.gadgets.loading);
    const ErrorGadgets = useSelector((state: any) => state.gadgets.error);


    let response = category === 'salud' ? ResponseHealth : ResponseGadgets;
    let loading = category === 'salud' ? LoadingHealth : LoadingGadgets;
    let error = category === 'salud' ? ErrorHealth : ErrorGadgets;

    let [typeData, setTypeData] = useState<MembershipInfo>(response[subType]);
    let [benefits, setBenefit] = useState<BenefitsDetails[]>(typeData.benefits);
    let [extraBenefits, setExtraBenefit] = useState<BenefitsDetails[]>(typeData.extra);
    const [buttonLabel, setButtonLabel] = useState<string>(type === "free" ? 'REGISTRARME GRATIS' : 'QUIERO SER PREMIUM');


    useEffect(() => {
        if (storage) {
            let newStorage = { ...storage }
            newStorage.subtype = subType;
            dispatch(setCurrentValue(newStorage));
            //StorageManager.setItem<IStorageMembershipData>(STORAGE.memershipData, storage)
        }
        const handleScroll = () => {
            const cardConteiner = document.querySelector(".membershipCardsSingle");
            const button = document.querySelector('.buttonRegisterMobile');
            const containerButtonUp = document.querySelector('.containerButtonUp');
            if (cardConteiner && button && containerButtonUp) {
                const cardReact = cardConteiner.getBoundingClientRect();
                if (cardReact.bottom >= window.innerHeight) {
                    setIsButtonFixed(true);
                } else {
                    setIsButtonFixed(false);
                }
            }
        }
        window.addEventListener('scroll', handleScroll);

        response = category === 'salud' ? ResponseHealth : ResponseGadgets;
        loading = category === 'salud' ? LoadingHealth : LoadingGadgets;
    }, [category, type]);

    const changeType = (value: MembershipType) => {
        setType(value);
        setButtonLabel(value === "free" ? 'REGISTRARME GRATIS' : 'QUIERO SER PREMIUM')
        let subTypeChange: MembershipSubType = 'freemium';

        if (value === 'free') {
            subTypeChange = 'freemium';
            setSubType(subTypeChange);
        } else if (value === 'premium') {
            if (!subTypeChange || subTypeChange == 'freemium') {
                subTypeChange = category === 'celular' ? 'premium' : 'esencial';
            }
            setSubType(subTypeChange);

        }
        if (storage) {
            let newStorage = Object.assign({}, storage);
            newStorage.type = value;
            newStorage.subtype = subTypeChange;
            dispatch(setCurrentValue(newStorage))
            //StorageManager.setItem(STORAGE.memershipData, storage);
        }

        setTypeData(response[subTypeChange]);
        //setBenefit(MembershipData[subTypeChange].benefits);
        //setExtraBenefit(MembershipData[subTypeChange].extra);
        if (response) {
            setBenefit(response[subTypeChange].benefits);
            setExtraBenefit(response[subTypeChange].extra);
        }

    }

    const changeSubType = (value: MembershipSubType) => {
        setSubType(value);
        setTypeData(response[value]);
        //setBenefit(MembershipData[value].benefits);
        //setExtraBenefit(MembershipData[value].extra);
        if (storage) {
            storage.subtype = value;
            dispatch(setCurrentValue(storage));
            //StorageManager.setItem(STORAGE.memershipData, storage);
        }
        if (response) {
            setBenefit(response[value].benefits);
            setExtraBenefit(response[value].extra);
        }
    }


    const navigate = useNavigate();

    const userResponse = useSelector((state: any) => state.user.response);

    const onClickHandleRegister = () => {
        window.dataLayer.push({
            event: 'TRUS_registrarme',
            tipo_seguro: "De regalo",
            seguro: storage.cardTitle,
            boton_contacto: storage.type ? "Registrarme gratis" : "Quiero ser premium",
        })
        navigate("/registrate/1");
    }

    const renderGuyBanner = () => {
        if (category === "salud") {
            return <div className="avatarWithHeart">
                <img src={avatarSalud} alt="" className="avatarImage" />
                <img src={heart} alt="" className="heartImage" />
            </div>
        } else {
            return <div className='avatarWithPhone'>
                <img src={phone} alt="" className="phoneImage" />
                <img src={avatarPhone} alt="" className="avatarImage" />
            </div>
        }
    }
    // Etiqueta deshabilitada, ya no se usara
    // React.useEffect(() => {
    //     scrollToTop()
    //     window.dataLayer.push({
    //         event: 'TRUS_cobertura',
    //         tipo_seguros: storage.type == "free" ? "De regalo" : "Premium",
    //         items:
    //             benefits.map((benefit, index) => (
    //                 {
    //                     index: index,
    //                     cobertura: benefit.title,
    //                 }))
    //     })
    // }, [benefits])

    return (
        <>
            <div className="PrincipalBox">
                <div className='MainConteiner'>
                    <div className="bannerConteiner">
                        <div className="bannerText">
                            <div className="seguroTitle">
                                <VectorBackgroundIcon />
                                <div className="icons-content" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', paddingLeft: '20px' }}>
                                    {category === "salud" ?
                                        <HeartIcon /> : <SmarthPhoneIcon />
                                    }
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '10px',
                                    marginLeft: '25px'
                                }}>
                                    <Typography style={{
                                        fontWeight: '900',
                                        fontSize: '20px'
                                    }}>
                                        {category === "salud" ? 'Salud Protect' : 'Celular Protect'}
                                    </Typography>
                                </div>
                                {/* <img src={category === "celular" ? celular : seguro} alt="" className='LogoSeguro' style={{ maxWidth: "250px" }} /> */}
                            </div>
                            <div className="labelBanner">
                                <p>
                                    Conoce los beneficios para que tu cel esté seguro y ¡Adiós problemas!
                                </p>
                            </div>

                            <div className="containerButtonUp">
                                <div className="buttonRegister" style={isMobile ? { display: 'none' } : { display: 'flex' }} >
                                    <button className='btnBlue' onClick={onClickHandleRegister}>
                                        {buttonLabel}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="image">
                            <div className="imageBannerCoverage">
                                {renderGuyBanner()}
                            </div>
                        </div>

                    </div>
                    <LoadingComponent isLoaded={response && loading === false ? true : false} isError={isError} ErrorMessage='Hubo un error al cargar las coberturas' ></LoadingComponent>
                    <div className="cardConteiner" style={response && !loading ? { display: 'flex', flexDirection: 'column' } : { display: 'none' }}>
                        <MembershipButton type={type} onClickSubType={changeType} />

                        <div className='membershipCardsSingle' >
                            <div className={"membershipCard"}>
                                <MembershipDetailCard key="single" type={type} subType={subType} category={category} benefits={benefits} extraBenefits={extraBenefits} price={typeData.price} changeSubType={changeSubType}></MembershipDetailCard>
                                <div className={"buttonRegisterMobile " + (isMobile === true ? 'buttonShow' : '')} style={isButtonFixed ? { position: 'fixed', bottom: '0', justifyContent: 'center', width: '100%' } : { width: '100%', justifyContent: 'center' }} >
                                    <button className='btnBlue' onClick={onClickHandleRegister} style={isButtonFixed ? { width: '80%' } : { width: '90%' }}>
                                        {buttonLabel}
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>)
}

export default MembershipCoverageComponent