import axios from 'axios';
import BASE_CONFIG from '../env/env';
// 
const baseURL = BASE_CONFIG.backend;

export const InterInstance = axios.create({
  baseURL,
  headers: {
    AccessControlAllowOrigin: '*',
    AccessControlAllowMethods: 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

export const addAccessToken = async (access_token = null) => {
  InterInstance.interceptors.request.use(
    async config => {
      config.headers.Authorization = `Bearer ${access_token || sessionStorage.access_token}`

      return config
    },
    error => {
      return Promise.reject(error)
    }
  )
}