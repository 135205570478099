import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from 'styled-components';
import { CustomTypography } from '../../atoms/Label/Label';
import CustomContainer from '../../organisms/Container/Container';

const EmptyContainer = styled.div` 
    border-radius: 8px;
    border: 1px solid var(--azul-5, #F2FAFC);
    background: var(--azul-5, #F2FAFC);
    padding: 16px;
    align-items: center;
    display: flex;
    justify-content: center;
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface customTab {
  data: Array<any>
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 0, py: 2, textAlign: 'center' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CustomTab(props: customTab) {

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }} style={{
      display: 'flex',
      flexDirection: 'column',
      gap: 40
    }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingTop: '38px' }}>
        <Tabs 
          centered 
          style={{ 
            width: '100%', 
            position: 'relative', 
            bottom: '0px', 
            maxWidth: '753px', 
            margin: 'auto' 
          }} 
          value={value} 
          onChange={handleChange} 
          aria-label="tabs"
        >
          {props.data.map(({label}, index) => {
            return (
              <Tab sx={{ flex: 1, padding: 0, height: '24px' }} style={{
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 700,
                textAlign: 'center',
                alignSelf: 'stretch',
                textTransform: 'inherit'
              }} label={label} {...a11yProps(index)} />
            )
          })}
        </Tabs>
      </Box>
      {props.data.map(({ content }, index) => {
        return(
        <CustomTabPanel value={value} index={index}>
          <div>
            {content || <EmptyContainer>
              <CustomTypography
                item={"La informacion no pudo cargarse en este momento"}
                style={{
                  fontSize: 16,
                  textAlign: 'center',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  textTransform: 'inherit',
                  letterSpacing: '0.032px'
                }}
              />
            </EmptyContainer>}
          </div>
        </CustomTabPanel>)
      })}
    </Box>
  );
}
