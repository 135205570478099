import { themeValues } from "../../../theme/theme";
import { BenefitsDetails } from "./MembershipCard/MemberShipItem/MembershipItem";
import IconComponent from "./MembershipCoverage/MembershipDetailsCards/Icon/IconComponent";
import { createTheme } from '@mui/material';

//icons
import botellaPastillas from './MembershipCoverage/MembershipDetailsCards/Icon/botella-de-pastillas.svg'
import etiquetaPrecio from './MembershipCoverage/MembershipDetailsCards/Icon/etiqueta-de-precio.svg';
import medicamento from './MembershipCoverage/MembershipDetailsCards/Icon/medicamento.svg';
import moneyManagement from './MembershipCoverage/MembershipDetailsCards/Icon/money-management.svg';
import telemedicina from './MembershipCoverage/MembershipDetailsCards/Icon/telemedicina.svg'
import cementerio from './MembershipCoverage/MembershipDetailsCards/Icon/cementerio.svg';
import guirnalda from './MembershipCoverage/MembershipDetailsCards/Icon/guirnalda.svg';
import huesoRoto from './MembershipCoverage/MembershipDetailsCards/Icon/hueso-roto.svg';
import infarto from './MembershipCoverage/MembershipDetailsCards/Icon/infarto-de-miocardio 1.svg';
import microscopio from './MembershipCoverage/MembershipDetailsCards/Icon/microscopio.svg';
import moneda from './MembershipCoverage/MembershipDetailsCards/Icon/moneda.svg';
import plan from './MembershipCoverage/MembershipDetailsCards/Icon/plan.svg'
import robo from './MembershipCoverage/MembershipDetailsCards/Icon/robo 1.svg';
import saludMental from './MembershipCoverage/MembershipDetailsCards/Icon/salud-mental.svg';
import telefonoInteligente from './MembershipCoverage/MembershipDetailsCards/Icon/pantalla-rota.svg';

const theme = createTheme(themeValues);

export const MembershipColor = {
    free: theme.palette.primary.main,
    freeSecondary: '#C0E6F2',
    freeOpacity: '#F1FCFF',
    premium: '#E6B331',
    premiumSecondary: '#FAD882',
    premiumOpacity: '#FFF7E3',
    alianzaOpacity:'#F4F4F4',
    alianza:'#8F8F8F'
}


export enum MembershipCategory {
    salud = 'salud',
    celular = 'celular',
    //hogar = 'hogar'
}

export enum MembershipTypes {
    free = 'free',
    premium = 'premium'
}

export enum MembershipSubTypes {
    freemium = 'freemium',
    premium = 'premium',
    esencial = 'esencial',
    superior = 'superior',
    elite = 'elite'
}



export type MembershipType = keyof typeof MembershipTypes;
export type MembershipSubType = keyof typeof MembershipSubTypes;
export type MembershipCategoryType = keyof typeof MembershipCategory;

export interface MembershipInfo {
    type: MembershipType,
    subtype: MembershipSubType,
    benefits: BenefitsDetails[],
    extra: BenefitsDetails[],
    price: number;
    category: MembershipCategoryType
}


///MOCK DATA
export const MembershipDataCelular: { [key in MembershipSubType]: MembershipInfo } = {
    premium: {
        category: 'celular',
        price: 99,
        subtype: 'premium',
        type: 'premium',
        benefits: [{
            title: 'Reparación de celular',
            description: '¿Se daño tu celular por accidente? Te cubrimos con hasta $10,000 para que la repares.',
            icon: ''
        }, {
            title: 'Robo',
            description: 'Te protegemos con hasta $10,000 para reponer tu celular, en caso de que seas víctima de robo con violencia.',
            icon: ''
        }],
        extra: [{
            title: 'Club de descuentos oro',
            description: 'ofertas en miles de comercios participantes, con hasta un 70% de descuento.',
            icon: ''
        }, {
            title: 'Telemedicina general ilimitada',
            description: 'Consulta en línea con receta digital valida para comprar tus medicamentos.',
            icon: ''
        }, {
            title: 'Telemedicina psicológica ilimitada',
            description: 'Consulta psicológica a distancia para cuidar tu salud mental',
            icon: ''
        }],
    },
    elite: {
        benefits: [],
        extra: [],
        category: 'celular',
        price: 0,
        subtype: 'elite',
        type: 'premium'
    },
    superior: {
        benefits: [],
        extra: [],
        category: 'celular',
        price: 0,
        subtype: 'superior',
        type: 'premium'
    },
    freemium: {
        category: 'celular',
        price: 0,
        type: 'free',
        subtype: 'freemium',
        benefits: [{
            title: 'Reparación de pantalla',
            description: '¿Se te cayo tu celular y se rompió la pantalla? Te cubrimos con hasta $4,000 para que la repares.',
            icon: ''
        }],
        extra: [{
            title: 'Club de descuentos bronce',
            description: 'Obtendrás ofertas con miles de comercios participantes.',
            icon: ''
        }, {
            title: 'Telemedicina general ilimitada',
            description: 'Consulta en línea con receta digital valida para comprar tus medicamentos.',
            icon: ''
        }]
    },
    esencial: {
        category: 'celular',
        price: 0,
        subtype: 'esencial',
        type: 'premium',
        benefits: [],
        extra: [],
    }
}
export const MembershipDataSalud: { [key in MembershipSubType]: MembershipInfo } = {
    premium: {
        benefits: [],
        extra: [],
        category: 'salud',
        price: 0,
        subtype: 'premium',
        type: 'premium'
    },
    freemium: {
        category: 'salud',
        type: 'free',
        subtype: 'freemium',
        price: 0,
        benefits: [{
            title: 'Pago de medicamentos',
            description: 'Por si te enfermas, te ayudamos hasta con 1,000 pesos para que los compres.',
            icon: ''
        }, {
            title: 'Reembolso de gastos médicos',
            description: 'En caso de que tengas una accidente te rembolsamos hasta $10,000 de lo que hayas pagado.',
            icon: ''
        }, {
            title: 'Check-up médico',
            description: 'Por si necesitas estudios (Química sanguínea de 24 elementos, biometría hemática y examen de orina)',
            icon: ''
        }],
        extra: [{
            title: 'Club de descuentos bronce',
            description: 'Obtendrás ofertas con miles de comercios participantes.',
            icon: ''
        }, {
            title: 'Telemedicina general ilimitada',
            description: 'Consulta en línea con receta digital valida para comprar tus medicamentos.',
            icon: ''
        }]
    },
    esencial: {
        category: 'salud',
        type: 'premium',
        subtype: 'esencial',
        price: 90,
        benefits: [{
            title: 'Pago de medicamentos',
            description: 'Por si te enfermas, te ayudamos hasta con 1,000 pesos para que los compres.',
            icon: ''
        }, {
            title: 'Reembolso de gastos médicos',
            description: 'En caso de que tengas una accidente te rembolsamos hasta $10,000 de lo que hayas pagado.',
            icon: ''
        }, {
            title: 'Check-up médico',
            description: 'Por si necesitas estudios (Química sanguínea de 24 elementos, biometría hemática y examen de orina)',
            icon: ''
        }],
        extra: [{
            title: 'Club de descuentos oro',
            description: 'ofertas en miles de comercios participantes, con hasta un 70% de descuento.',
            icon: ''
        }, {
            title: 'Telemedicina general ilimitada',
            description: 'Consulta en línea con receta digital valida para comprar tus medicamentos.',
            icon: ''
        }]
    },
    superior: {
        category: 'salud',
        type: 'premium',
        subtype: 'superior',
        price: 120,
        benefits: [{
            title: 'Cáncer',
            description: 'En caso de que seas diagnosticado con cáncer, te apoyamos con $15,000 pesos',
            icon: ''
        }, {
            title: 'Infarto',
            description: 'En caso de que te hayan diagnosticado un infarto, te apoyamos con $10,000 pesos',
            icon: ''
        }, {
            title: 'Fractura de huesos',
            description: '¿Tuviste un accidente y te fracturaste un hueso? No te preocupes, te cubrimos con $10,000',
            icon: ''
        }],
        extra: [{
            title: 'Club de descuentos oro',
            description: 'ofertas en miles de comercios participantes, con hasta un 70% de descuento.',
            icon: ''
        }, {
            title: 'Telemedicina general ilimitada',
            description: 'Consulta en línea con receta digital valida para comprar tus medicamentos.',
            icon: ''
        }, {
            title: 'Telemedicina psicológica ilimitada',
            description: 'Consulta psicológica a distancia para cuidar tu salud mental',
            icon: ''
        }]
    },
    elite: {
        category: 'salud',
        type: 'premium',
        subtype: 'elite',
        price: 250,
        benefits: [{
            title: 'Fallecimiento del titular',
            description: 'Protege a tus seres queridos con $10,000 en caso de muerte accidental.',
            icon: ''
        }, {
            title: 'Gastos funerarios',
            description: 'Te ayudamos con $10,000 para cubrir los gastos en un momento tan difícil. ',
            icon: ''
        }, {
            title: 'Servicio funerario',
            description: '2 eventos de servicio funerario familiar hasta para 7 personas.',
            icon: ''
        }],
        extra: [{
            title: 'Club de descuentos oro',
            description: 'ofertas en miles de comercios participantes, con hasta un 70% de descuento.',
            icon: ''
        }, {
            title: 'Telemedicina general ilimitada',
            description: 'Consulta en línea con receta digital valida para comprar tus medicamentos.',
            icon: ''
        }, {
            title: 'Telemedicina psicológica ilimitada',
            description: 'Consulta psicológica a distancia para cuidar tu salud mental',
            icon: ''
        }, {
            title: 'Telemedicina nutricional ilimitada',
            description: 'Consulta de nutrición en línea para ayudar a mejorar tu alimentación',
            icon: ''
        }]
    }
}
