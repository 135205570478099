import { useDispatch, useSelector } from 'react-redux';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { InterInstance } from '../api/config';
import RenderAlert from '../components/organisms/Modal';
import RenderAccountExist from '../components/organisms/Modal/RenderAccountExist';
import { useNavigate } from "react-router-dom";
interface UseFetchResult {
  fetchData: (url: string, method: AxiosRequestConfig['method'], data?: any, headers?: AxiosRequestConfig['headers'], apiLoading?: any, apiSuccess?: any, apiError?: any) => void;
}




const useFetch = <T>(): UseFetchResult => {
  const dispatch = useDispatch();
  const response_membership = useSelector((state: any) => state.productsCards) as | any | null;
  const navigate = useNavigate();


  const fetchData = async (url: string, method: AxiosRequestConfig['method'], data?: any, headers?: AxiosRequestConfig['headers'], apiLoading?: any, apiSuccess?: any, apiError?: any) => {
    await dispatch(apiLoading(true));

    try {
      let apiResponse: AxiosResponse<any>;
      const messageError = JSON.stringify({ error: true, message: 'Error al obtener los datos' });

      switch (method) {
        case 'GET':
          apiResponse = await InterInstance.get(url, { headers });
          if (apiResponse?.data) {
            dispatch(apiSuccess(apiResponse.data));
          } else {
            throw Error(messageError);
          }
          break;
        case 'POST':
          apiResponse = await InterInstance.post(url, data, { headers });
          if (apiResponse?.data) {
            dispatch(apiSuccess(apiResponse.data));
          } else {
            throw Error(messageError);
          }
          break;
        case 'PUT':
          apiResponse = await InterInstance.put(url, data, { headers });
          if (apiResponse?.data) {
            dispatch(apiSuccess(null));
          } else {
            throw Error(messageError);
          }
          break;
        case 'PATCH':
          apiResponse = await InterInstance.patch(url, data, { headers });
          if (apiResponse?.data) {
            dispatch(apiSuccess(null));
          } else {
            throw Error(messageError);
          }
          break;
        case 'DELETE':
          apiResponse = await InterInstance.delete(url, { headers });
          if (apiResponse?.data) {
            dispatch(apiSuccess(null));
          } else {
            throw Error(messageError);
          }
          break;
        default:
          throw new Error('Método HTTP no válido');
      }

    } catch (error: any) {
      const status = error?.response?.status || 400;
      const message = error?.response?.data?.data?.message || 'Error al realizar la petición';
      dispatch(apiError(error || 'Error al realizar la petición'));

      if (message === 'ya tienes este plan') {
        //alert('example' + message)
        RenderAccountExist({ severity: 'warning', message, membership: response_membership, navigate });
        return false
      } else if (message === 'no puedes tener 2 planes freemium') {
        RenderAccountExist({ severity: 'warning', message, membership: response_membership, navigate });
        return false
      } else if (message === "El correo ingresado ya existe" || message === "El telefono ingresado ya existe") {
        RenderAccountExist({ severity: 'warning', message, membership: response_membership, navigate });
        return false
      } else if (message === "Homonimo encontrado o persona ya registrada") {
        return false;
      } else if (message === "Internal server error") {
        return false
      } else if (message === "El usuario no tiene eventos") {
        return false;
      }
      RenderAlert({ severity: "info", message });
    }
  };

  return { fetchData };
};

export default useFetch;
