import { BenefitValuesType } from "./benefit.values";

export interface BenefitData {
    path: string;
    waitDays?: number;
}
type BenefitConfig = {
    [key in BenefitValuesType]?: BenefitData
}

export const BenefitConfiguration: BenefitConfig = {
    Reparacion: {
        path: '/reparacion-celular',
        waitDays: 30
    },
    Muerte: {
        path: '/muerte-accidental',
        waitDays: 0
    },
    Descuento: {
        path: '/ofertas'
    },
    Robo: {
        path: '/robo-celular',
    }

}