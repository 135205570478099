import  {useSelector}  from 'react-redux'
import React, {useEffect, useState} from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CelularMani from "../../assets/RevolutionAssets/CelularMani.svg";
import SaludMani from "../../assets/RevolutionAssets/SaludMani.svg";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {CustomButton} from "../atoms/Buttons/Buttons";
import { setCurrentValue } from '../../api/productsSlice';
import { MembershipPlanItem } from '../organisms/CustomerPortalContent/interfaces/membership';
import {
  apiLoading as LoadingGadgets,
  apiSuccess as SuccessGadgets,
  apiError as ErrorGadgets
} from '../../api/gadgetsSlice';
import {
  apiLoading as LoadingHealth,
  apiSuccess as SuccessHealth,
  apiError as ErrorHealth
} from '../../api/healthSlice';
import useFetch from '../../hooks/useFetch';

interface IMemberShipType {
  plan?: MembershipPlanItem;
}

const CardMembershipType = ({plan} : IMemberShipType) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  // @ts-ignore
  const userResponse = useSelector((state) => state?.user?.response);
 const [membershipType, setMembershipType] = useState('celular');
 const { fetchData } = useFetch();

 const currentValue = useSelector(
  (state: any) => state.productsCards.currentValue
) as any | null;

  const getPlanToBuy = (benefit_id: any) => {

    const type = {
      "C01": "premium",
      "C02": "free",
      "S01": "premium",
      "S02": "premium",
      "S03": "premium",
      "S04": "free",
    }

    const subtype = {
      "C01": "premium",
      "C02": "freemium",
      "S01": "premium",
      "S02": "premium",
      "S03": "premium",
      "S04": "freemium",
    }

    const category = {
      "C01": "salud",
      "C02": "salud",
      "S01": "celular",
      "S02": "celular",
      "S03": "celular",
      "S04": "celular",
    }

    const data = {
  // @ts-ignore
      type: type[benefit_id], // premium -free
  // @ts-ignore
      subtype: subtype[benefit_id], // premium - freemium
  // @ts-ignore
      category: category[benefit_id] // celular-salud
    }
    return data
  }

  const fetchingDataGadgets = () => {
    const newCurrentValue = getPlanToBuy(plan?.benefitId)

    const url: string = newCurrentValue.category === 'salud' ? '/catalogs/health' : '/catalogs/gadgets';

    return fetchData(url, 'GET', {}, {}
        , newCurrentValue.category === 'salud' ? LoadingHealth : LoadingGadgets, newCurrentValue.category === 'salud' ? SuccessHealth : SuccessGadgets, newCurrentValue.category === 'salud' ? ErrorHealth : ErrorGadgets);
  }

  const buyNewProduct = () => {
    const newCurrentValue = getPlanToBuy(plan?.benefitId)
    window.dataLayer.push({
      event: 'TRUS_nuevo_seguro',
      seguro: membershipType == "celular" ? "Celular Protect" : "Salud Protect",
      boton_contacto: 'Lo quiero',
      tipo_seguro: currentValue?.type == "premium" ? "Premium" : "De regalo",
      correo: userResponse?.data?.user?.email,
      telefono: userResponse?.data?.user?.phone,
  })

    dispatch(setCurrentValue(newCurrentValue))
    navigate("/portal-del-cliente/registro-nuevo-producto")
  }

  useEffect(() => {
    fetchingDataGadgets();
    const typeCategory = plan?.category === "celular" ? "salud" : "celular";
    setMembershipType(typeCategory)
  }, [plan]);



  return (
<Box sx={{display:'flex',flexDirection:'column',justifyContent:'center', alignItems:'center',gap:'40px'}}>
  {isMobile ? membershipType === 'celular' ?
    <div style={{ height: '447px', width: '328px' }}>
      <img style={{ height: '447px', width: '328px' }} src={CelularMani} alt={'ImgItem1'} loading='lazy' />
    </div>
    :
    <div style={{ height: '447px', width: '328px' }}>
      <img style={{ height: '447px', width: '328px' }} src={SaludMani} alt={'ManiSalud'} loading='lazy' />
    </div> : ''}

  {!isMobile ? membershipType === 'celular' ?
    <div style={{ height: '447px', width: '328px' }}>
      <img style={{ height: '447px', width: '328px' }} src={CelularMani} alt={'ImgItem1'} loading='lazy' />
    </div>
    :
    <div style={{ height: '447px', width: '328px' }}>
      <img style={{ height: '447px', width: '328px' }} src={SaludMani} alt={'ManiSalud'} loading='lazy' />
    </div> : ''}
  <Box sx={{paddingRight: 2, paddingLeft:2}}>
  <Typography
    sx={{
      color: "var(--Black, #212121)",
      fontFamily: "Montserrat",
      fontSize: "22px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      textAlign:"left"
    }}
    dangerouslySetInnerHTML={{__html:
        `Con <b>${membershipType === 'celular' ? 'Celular Protect' : 'Salud Protect'}</b> obtendrás la protección que necesitas ante accidentes o enfermedades y puedas estar tranquilo de contar con nuestro respaldo.`}}
  />
</Box>
  <Box sx={{mb:2,  width:'100%', alignItems:'center',justifyContent:'center',display:'flex'}}>
  <CustomButton text={'¡Lo Quiero!'} onClick={buyNewProduct}
                style={{
                  fontWeight: 'bold',
                  borderRadius: 8,
                  boxShadow: 'none',
                  color: "var(--White, #FFF)",
                  backgroundColor: "var(--Azul-Inter, #039ECC)",
                  fontSize: "1rem",
                  // textTransform: "none",
                  width: "70%",
                  border: "none",
                  padding: "14px 16px",
                  justifyContent: "center",
                 }}
  />
</Box>

</Box>
  )
}
export default CardMembershipType;
