import React from "react"

type DotsProps = {
    color: string
}

const Dots: React.FC<DotsProps> = ({color = '#C2C2C2' }) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
    <circle cx="5" cy="5.50003" r="5" fill={color} />
  </svg>
}

export default Dots;
