import React from "react";

interface TikTokIconProps {
  style?: React.CSSProperties;
}

const TikTokIcon: React.FC<TikTokIconProps> = (props) => {
  return (

    <svg
      style={props.style}

      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="21"
      fill="none"
      viewBox="0 0 18 21"
    >
      <path
        fill="#FFFFFF"
        d="M7.145 10.911c-.423.073-.847.073-1.2.218-1.447.437-2.365 1.964-2.083 3.492.283 1.636 1.624 2.69 3.248 2.545 1.553-.109 2.824-1.418 2.824-2.982.035-2.873.035-5.746.035-8.583V.183h2.612c.07 0 .177.145.177.218.53 2.655 2.118 4.11 4.66 4.51 0 0 .035 0 .07.036V8.22c-1.977.109-3.53-.655-4.8-2.364v8.183c0 3.164-2.26 5.746-5.26 6.11-3 .327-5.754-1.71-6.425-4.801-.776-3.71 2.012-7.346 5.683-7.346.142 0 .283.036.424.036.035.982.035 1.964.035 2.873z"
      ></path>
    </svg>

  );
}

export default TikTokIcon;
