import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import FormItemGenerate from '../../../atoms/FormItems/FormItemGenerate';
import { styled } from 'styled-components';
import { OutlinedButton, PrimaryButton } from '../../../molecules/CellPhoneRepairButtons';
import { useNavigate } from 'react-router-dom';
import { IStorageMembershipData } from '../../../../utils/localStorageKeys';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import useFetch from '../../../../hooks/useFetch';
import {
    apiSuccess as addressSuccess,
    apiLoading as addressLoading,
    apiError as addressError
} from '../../../../api/addressSlice';

type SendAddressProps = {
    errors?: any;
    handleChange?: any;
    handleBlur?: any;
    setActiveStep: any;
    activeStep: number;
}

const ContentStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`;

const SendAddress: React.FC<SendAddressProps> = ({ handleChange, handleBlur, activeStep, setActiveStep }) => {
    const matches = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();
    const [zipCode, zipCodeMeta] = useField("zipCode");
    const [stateField, stateMeta] = useField("state");
    const [state, setState] = useState('');
    const [stateOption, setStateOption] = useState(['CDMX']);

    const [city, setCity] = useState('');
    const [cityOption, setCityOption] = useState([])
    const [cityField, cityMeta] = useField("city");

    const [municipalityField, municipalityMeta] = useField("municipality");
    const [municipality, setMunicipality] = useState('');
    const [municipalityOption, setMunicipalityOption] = useState(['Cuauhtémoc']);

    const [colonyField, colonyMeta] = useField("colony");
    const [colony, setColony] = useState('');
    const [colonyOption, setColonyOption] = useState(['Ciudad de México']);

    const [street, streetMeta] = useField("street");
    const [number, numberMeta] = useField("number");
    const [reference, referenceMeta] = useField("reference");
    const { fetchData } = useFetch();

    const userData = JSON.parse(sessionStorage.getItem('user-storage')!);
    let storage: IStorageMembershipData = {
        ...useSelector((state: any) => state.productsCards.currentValue),
    };

    const userResponse = useSelector((state: any) => state.user.response);
    const addressResponse = useSelector((state: any) => state.address.response);

    const getAddress = async (zipcode: string) => {
        return await fetchData('/users/auth', 'POST', {
            event: 'get-addess',
            content: {
                zipcode
            }
        }, {}, addressLoading, addressSuccess, addressError);
    }

    useEffect(() => {
        if (addressResponse && addressResponse.data) {
            const data = addressResponse.data;
            setMunicipalityOption(data.municipality);
            setStateOption(data.state);
            setCityOption(data.city)
            setColonyOption(data.col)
        }
    }, [addressResponse])



    useEffect(() => {
        window.dataLayer.push({
            'event': 'TRUS_reparacion_direccion_v',
            tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium",//premium | gratuito 
            'correo': userResponse?.data?.user?.email,
            'telefono': userResponse?.data?.user?.phone,
            'page_location': window.location.href,
        });
    }, []);
    const handleBlurLocal = () => {
        if (zipCode.value.length === 5) {
            getAddress(zipCode.value);
        }
    }
    const items = [{
        id: 1,
        label: 'Código Postal',
        name: 'zipCode',
        autofocus: true,
        as: 'textField',
        error: zipCode,
        meta: zipCodeMeta
    },
    {
        id: 2,
        label: 'Estado*',
        name: 'state',
        type: 'select',
        event: setState,
        value: state,
        option: stateOption,
        error: stateField,
        meta: stateMeta,
        xs: matches ? 6 : 12,
        loading: false
    },
    {
        id: 3,
        label: 'Alcaldía o Municipio*',
        name: 'municipality',
        type: 'select',
        event: setState,
        value: municipality,
        option: municipalityOption,
        error: municipalityField,
        meta: municipalityMeta,
        xs: matches ? 6 : 12,
        loading: false
    },
    {
        id: 4,
        label: 'Ciudad*',
        name: 'city',
        type: 'select',
        event: setCity,
        value: city,
        option: cityOption,
        error: cityField,
        meta: cityMeta,
        loading: false
    },
    {
        id: 5,
        label: 'Colonia*',
        name: 'colony',
        type: 'select',
        event: setColony,
        value: colony,
        option: colonyOption,
        error: colonyField,
        meta: colonyMeta,
        loading: false
    },
    {
        id: 6,
        label: 'Calle',
        name: 'street',
        type: 'text',
        as: 'textField',
        error: street,
        xs: matches ? 6 : 12,
        meta: streetMeta
    },
    {
        id: 7,
        label: 'Número',
        name: 'number',
        type: 'text',
        as: 'textField',
        error: number,
        xs: matches ? 6 : 12,
        meta: numberMeta
    },
    {
        id: 8,
        label: 'Referencias',
        name: 'reference',
        type: 'text_area',
        as: 'textField',
        error: reference,
        meta: referenceMeta
    }
    ]
    return (
        <React.Fragment>
            <ContentStyled>
                <FormItemGenerate
                    items={items}
                    handleChange={handleChange}
                    handleBlur={handleBlurLocal}
                />

            </ContentStyled>
            {/* <PrimaryButton type='submit' text={"Continuar"} onClick={() =>{
                // navigate("/recomendacion")
                setActiveStep(activeStep + 1);
                window.dataLayer.push({
                    event: "TRUS_reparacion_direccion",
                    cp: zipCode.value,
                    boton_contacto: 'Continuar',
                    tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium",//premium | gratuito 
                    correo: userResponse?.data?.user?.email,
                    telefono: userResponse?.data?.user?.phone,
                });
            }} /> */}
            {/* <OutlinedButton text={"No, ya cambió"} onClick={() => { navigate("/editar-informacion") }} /> */}
        </React.Fragment>
    )
};

export default SendAddress;