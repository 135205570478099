import React, { useState } from "react";
import BasicModal from "../../molecules/BasicModal/BasicModal";
import CardAction from "../../organisms/Card";
import { MembershipCategoryType } from "../../organisms/Membership/MembershipMainValues";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { setCurrentValue } from '../../../api/productsSlice';

const CustomerPortalTemplate: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpenModal] = useState<boolean>(true);
  // const { category } = JSON.parse(localStorage.getItem("myObjectKey") || "{}");
  const storage  = useSelector((state: any) => state.productsCards.currentValue) as any | null;

  const closeModal = (newState: boolean) => {
    navigate('/portal-del-cliente', { replace: true, state: { showModalUpsell: false } })
    setOpenModal(newState)
  }
  
  let membershipType: MembershipCategoryType = storage?.category?.toLowerCase();
  return (
    <BasicModal
      styleRoot={{
        maxWidth: 728,
        boxShadow: "none",
      }}
      styleContent={{
        margin: 0,
        padding: 0,
      }}
      disableBackdropClick={true}
      closeButton
      open={open}
      setOpenModal={closeModal}
      bodyDialog={<CardAction 
        onClick={() => {
          const sendDataLocalStorage = {
              type: "premium",
              subtype: "premium",
              category: storage?.category,
              cardTitle: storage?.category === "celular" ? "Seguro Inter Celular" : "Seguro Inter Salud",
              benefit_id: storage?.benefit_id,
              plan_id: storage?.plan_id
          };
          // console.log(userResponse, productPremium, planFree, item)
          
          dispatch(setCurrentValue(sendDataLocalStorage));
          navigate('/resumen-membresia', {
            state: {
                path: 'portal-del-cliente',
                tabHidden: "freemium"
            }
          })
        }}
        cardType={"premium"}
        subType={"premium"}
        cardCategory={membershipType}
        cardTitle={storage?.cardTitle} 
      />}
    />
  );
};

export default CustomerPortalTemplate;
