import React, { useEffect, useState } from 'react';
import '../../MeetOurMemberships/styles.css';
import celular from './assets/SmarthPhoneFull.svg';
import saludProtect from './assets/CelularProtect.svg';
import { CustomButton } from '../../atoms/Buttons/Buttons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, useMediaQuery } from '@mui/material';
import { setCurrentValue } from '../../../api/productsSlice';
import { IMemberShipData } from '../../MeetOurMemberships/MembershipSlider';
import RadioGroup from '@mui/material/RadioGroup';
import { themeValues, myColorPalette } from '../../../theme/themeGenerated';


import { styled } from 'styled-components';
import {
    MembershipCategoryType,
    MembershipColor,
    MembershipInfo,
    MembershipSubType,
    MembershipType,
    MembershipTypes
} from '../Membership/MembershipMainValues';
import MembershipItem, {BenefitsDetails} from '../Membership/MembershipCard/MemberShipItem/MembershipItem';
import LoadingComponent from '../loading/loading';

//Restart tracking_id with all the register proccess / cleaning the form fields
import { restartValue as restartBrand } from "../../../api/gadgetsBrandSlice";
import { restartValue as restartModels } from "../../../api/gadgetsModelsSlice";
import { restartValue as restartDescription } from "../../../api/gadgetsDescriptionSlice";
import { restartValue as restartLeads } from "../../../api/leadsSlice";
import { restartValue as restartUser } from "../../../api/userSlice";
import { restartValue as restartSignUp } from "../../../api/signUpSlice";
import { restartValue as restartRegister } from "../../../api/formRegisterSlice";
import { fetchTrackingStore } from '../../../api/fetchingTrackingStore';
import { getBrowserInfo } from '../../../utils/navigatorDetails';
import {
    apiSuccess as successTracking,
    apiError as errorTracking,
    apiLoading as loadingTracking,
} from "../../../api/trackingSlice";
import useFetch from '../../../hooks/useFetch';
import MembershipItemCheck from '../Membership/MembershipCard/MemberShipItem/MemberShipItemCheck';
import ReactDOM from "react-dom";
import RenderAlertWarning from "../../organisms/Modal/warningClose";
import { useFormikContext } from 'formik';

import  pagoMedicamentos from '../Membership/MembershipCoverage/MembershipDetailsCards/Icon/botella-de-pastillas.svg';
import  reembolsoGastos from '../Membership/MembershipCoverage/MembershipDetailsCards/Icon/moneda.svg';
import  checkupMedico from '../Membership/MembershipCoverage/MembershipDetailsCards/Icon/money-management.svg';

const TitleElement = styled.span`
  color: var(--black, #212121);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const SubTitleElement = styled.span`
  color: var(--black, #212121);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

type CustomContainerProps = {
    idCard: number;
    cardTitle: string;
    cardDescription: string;
    cardImage: string;
    cardImageTitle: string;
    cardType: string;
    cardCategory: string;
    childComponent?: React.ReactNode;
    cardData: Array<IMemberShipData>;
    cardSubtype?: any;
    newProductRegistration?: boolean;
    isAlliance?: boolean;
};


const MemberShipCardNewDesign: React.FC<CustomContainerProps> = ({
    idCard,
    cardTitle,
    cardDescription,
    cardImage,
    cardType,
    cardData,
    cardSubtype,
    cardCategory,
    newProductRegistration = false, isAlliance = false
}) => {
    const navigatePage = useNavigate()

    const dispatch = useDispatch();

    const isIphone5 = useMediaQuery('(min-device-width: 0px) and (max-device-width: 320px) and (min-device-height: 0px) and (max-device-height: 568px) ');

    const ResponseHealth = useSelector((state: any) => state.health.response) as any | null;
    const LoadingHealth = useSelector((state: any) => state.health.loading);
    const ErrorHealth = useSelector((state: any) => state.health.error);

    const ResponseGadgets = useSelector((state: any) => state.gadgets.response) as any | null;
    const LoadingGadgets = useSelector((state: any) => state.gadgets.loading);
    const ErrorGadgets = useSelector((state: any) => state.gadgets.error);
    const [benefitData, setBenefitData] = useState<any>(null);
    const [Gadgets, setGagets] = useState<MembershipInfo>();
    const [Health, setHealth] = useState<MembershipInfo>();
    const[allianceBenefit, setAllianceBenefit] = useState<BenefitsDetails>()

    const productsCards = useSelector((state: any) => state.productsCards.response) as any | null;
    const responseUserAlliance = useSelector((state: any) => state.userAliance.response) as
        | any
        | null;
    const [dataAll, setData] = useState<MembershipInfo>();

    const [DataSalud, setDataSalud] = useState<MembershipInfo>();
    const formik = useFormikContext();

    const { fetchData } = useFetch();

    const sendDataredux: any = {
        type: cardType,
        subtype: cardType === 'free' ? "freemium" : "premium",
        category: cardCategory,
        cardTitle: cardTitle,
        cardDescription: cardDescription,
    };

    const cleanBenefitIdsThatAreNotInTheProccess = (subType: string) => {
        /**
         * El siguiente codigo genera un nuevo objeto para los datos de health
         * para filtrar el tipo benefit_id === S00 ya que es un dato que nos devuelve
         * BE y no debe de ir en el proceso, esto se hizo para no modificar el componente
         * que genera los beneficios.
         */

        const newHealth = ResponseHealth?.data[subType].benefits;
        const benefits = newHealth.filter((b: any) => b.benefit_id !== "S00");
        const hash = { health: { ...ResponseHealth?.data[subType] } };

        hash.health['benefits'] = [...benefits];

        return hash.health;
    }

    useEffect(() => {
        const subType: MembershipSubType = cardType === "premium" ? "premium" : "freemium";

        setGagets(ResponseGadgets?.data[subType]);
        //setHealth(ResponseHealth?.data[subType]);
        const getHealthData = cleanBenefitIdsThatAreNotInTheProccess(subType);
        setHealth(getHealthData);

        setDataSalud(ResponseHealth?.data["esencial"]);
        setData(cardCategory === "salud" ? ResponseHealth?.data : ResponseGadgets?.data);
    }, [ResponseHealth, ResponseGadgets]);

    // useEffect(() => {
    // }, [Gadgets, Health])

    useEffect(() => {
        const subType: MembershipSubType = cardType === "premium" ? "premium" : "freemium";
        setGagets(ResponseGadgets?.data[subType]);
        const getHealthData = cleanBenefitIdsThatAreNotInTheProccess(subType);
        setHealth(getHealthData)
    }, [cardType])
    const [selectedValue, setSelectedValue] = React.useState('');

    useEffect(() => {

        Health?.benefits.map((benefit, index) => {
            if (selectedValue === benefit.title) {
                setSelectedButton(false)
            }
        })


    }, [selectedValue, Health?.benefits])


    const restartRegisterForm = () => {
        dispatch(restartBrand());
        dispatch(restartModels());
        dispatch(restartLeads());
        dispatch(restartDescription());
        dispatch(restartUser());
        dispatch(restartSignUp());
        dispatch(restartRegister());
        sessionStorage.removeItem("exitoPremium");
        fetchTrackingStore(
            "users/tracking",
            "create-tracking",
            {
                content: {
                    data: {},
                    ...getBrowserInfo(),
                },
            },
            fetchData,
            loadingTracking,
            successTracking,
            errorTracking
        );
    };


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value);
    };
    const [selectedButton, setSelectedButton] = React.useState(true);

    const getBenefitsByCategory = () => {
        let getBenefits = null;

        if (cardCategory === "celular") {
            getBenefits = Gadgets?.benefits;
        } else if (cardCategory === "salud") {
            if (DataSalud) {
                getBenefits = DataSalud?.benefits;
            } else if (Health) {
                getBenefits = Health?.benefits;
            }
        }

        const hash: any = [];

        const getBenefitId: any = getBenefits?.filter((item: any) => {
            const duplicated = !hash[item.benefit_id];
            hash[item.benefit_id] = true;

            return duplicated;
        });

        const getProduct = productsCards.data.products.find((product: any) => product.category === cardCategory && product.type === cardType)

        if (getBenefitId?.length === 1 && getProduct?.plan_id) {
            sendDataredux['benefit_id'] = getBenefitId[0]?.benefit_id;
            sendDataredux['plan_id'] = getProduct?.plan_id;
        } else {
            throw Error("Error al elegir beneficio y/o plan");
        }
    }

    const getBenefitId = () => {
        let getBenefits = null;

        if (cardCategory === "celular") {
            getBenefits = Gadgets?.benefits;
        } else if (cardCategory === "salud") {
            if (DataSalud) {
                getBenefits = DataSalud?.benefits;
            } else if (Health) {
                getBenefits = Health?.benefits;
            }
        }

        const hash: any = [];

        const benefitId: any = getBenefits?.filter((item: any) => {
            const duplicated = !hash[item.benefit_id];
            hash[item.benefit_id] = true;

            return duplicated;
        });
        const benefit = selectedValue ? benefitId.find((b: any) => b.title === selectedValue) : benefitId ? benefitId[0] : null

        return benefit;
    }

    const allianceSettingResponse = useSelector((state: any) => state.allianceSetting.response);


    const eventFiunction = () => {

        if (cardCategory === 'salud' && cardType === 'free') {
            if (!selectedValue) {
                RenderAlertWarning({
                    severity: "warning",
                    message: "Para poder continuar con tu registro, selecciona una cobertura"
                })
                return false;
            } else {
                setSelectedButton(false)
            }
        }
        if (benefitData?.benefit?.benefit_id && benefitData?.plan?.plan_id) {
            sendDataredux['benefit_id'] = benefitData?.benefit?.benefit_id;
            sendDataredux['plan_id'] = benefitData?.plan?.plan_id;
        } else {
            getBenefitsByCategory();
        }

        restartRegisterForm();
        dispatch(setCurrentValue(sendDataredux));
        window.dataLayer.push({
            event: 'TRUS_nuestros_seguros_loquiero',
            tipo_seguro: sendDataredux.type == "free" ? "De regalo" : "Premium",
            seguro: sendDataredux.category == "celular" ? "Celular Protect" : "Salud Protect",
            boton_contacto: "Lo quiero"

        })
        if (window.location.pathname === "/finsus") {
            navigatePage("/finsus/registrate/1")

        } else {
            navigatePage("/registrate/1")

        }

    }

    const eventFunctionAlianza = () => {
        if (allianceSettingResponse && isAlliance && cardCategory === 'salud' && cardType === 'free') {
            sendDataredux['benefit_id'] = allianceSettingResponse?.data?.benefit_id;
            sendDataredux['plan_id'] = allianceSettingResponse?.data?.plan_id;
            sendDataredux['business'] = allianceSettingResponse?.data?.id_bussiness;
            restartRegisterForm();
            dispatch(setCurrentValue(sendDataredux));
            let alliance_data = allianceSettingResponse?.data?.alliance_data
            window.dataLayer.push({
                event: 'TRUS_alianza_loquiero',
                tipo_seguro: sendDataredux.type == "free" ? "De regalo" : "Premium",
                seguro: sendDataredux.category == "salud" ? "Salud Protect" : "Celular Protect",
                boton_contacto: "Lo quiero",
                alianza: sendDataredux.business,
            })
            if (alliance_data) {
                navigatePage("/alianza/empleado")
            } else {
                navigatePage("/alianza/registrate/1")
            }
        }
    }

    const matches = useMediaQuery('(max-width:320px)');

    const userResponse = useSelector((state: any) => state?.user?.response?.data?.user);

    const [checkedItems, setCheckedItems] = React.useState<Record<number, boolean>>({});


    const handleCheckedChange = (index: any, isChecked: any) => {
        setSelectedButton(!selectedButton)
        setCheckedItems(prevState => ({ ...prevState, [index]: isChecked }));
    };

    useEffect(() => {
        if (newProductRegistration) {
            setSelectedValue('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardType])

    useEffect(() => {
        if (newProductRegistration) {
            const getBeneficiaryId = getBenefitId();
            const getProduct = productsCards.data.products.find((product: any) => product.category === cardCategory && product.type === cardType)
            formik.setFieldValue('category', cardCategory)
            formik.setFieldValue('cardType', cardType)
            formik.setFieldValue('benefitId', getBeneficiaryId?.benefit_id);
            formik.setFieldValue('planId', getProduct.plan_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardType, selectedValue, Health, Gadgets, DataSalud])

    useEffect(() => {
        myColorPalette.setSecondaryColor('white')

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(()=>{
        if(!allianceSettingResponse?.data?.benefit_id || !Health?.benefits?.length)
            return

        const { benefit_id } = allianceSettingResponse.data
        const tmpBenefit = Health?.benefits.find(benefit => benefit.benefit_id === benefit_id)
        if(tmpBenefit){
            const copy = {...tmpBenefit}
            if(benefit_id === "S01"){
                copy.icon = pagoMedicamentos
            }else if(benefit_id === "S02"){
                copy.icon = reembolsoGastos
            }else{
                copy.icon = checkupMedico
            }
            setAllianceBenefit(copy)
        }

    },[allianceSettingResponse?.data, Health?.benefits])

    // const benefitIdHasCoberturas = allianceSettingResponse.data.benefit_id

    // const healt123 = Health?.benefits?.map((benefit) => {
    //     if (benefitIdHasCoberturas === benefit.benefit_id) {
    //         return benefit.description
    //     }
    // })

    return (

        <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
        }}>
            <LoadingComponent isLoaded={Gadgets != undefined ? true : false} />
            {
                !isAlliance &&
                    Gadgets != undefined ?
                    <div className={`slider`}
                        id='identifier-123'
                        style={{
                            display: Gadgets != undefined ? 'flex' : 'none',
                            width: '100%',
                            margin: isIphone5 ? 'auto 10px' : 'auto',
                            height: 'fit-content',
                        }}
                    >
                        <div
                            key={idCard}
                        >
                            <div>
                                <div style={{
                                    //background: 'red',

                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: 'fit-content',
                                    height: 'fit-content',
                                    alignItems: 'center',
                                    borderRadius: '10px',

                                }}>

                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        margin: '24px 12px 0px 12px',
                                        borderRadius: '8px 8px 0 0',
                                        width: '-webkit-fill-available',
                                        padding: '8px',
                                    }}>
                                        {
                                            cardImage === "Seguro Inter Salud " ?
                                                <Box sx={{
                                                    color: cardType === "free" ?
                                                        window.location.pathname === "/finsus" ? myColorPalette.getFinsusSecondary() :

                                                            MembershipColor.free : MembershipColor.premium,
                                                    textAlign: "center",
                                                    fontFamily: "Montserrat",
                                                    fontSize: "28px",
                                                    fontStyle: "normal",



                                                    fontWeight: 700,
                                                    lineHeight: "normal",
                                                }}>{'$' + Gadgets?.price + " / mes"}</Box>
                                                :
                                                <Box sx={{
                                                    color: cardType === "free" ?
                                                        window.location.pathname === "/finsus" ? myColorPalette.getFinsusSecondary() :
                                                            MembershipColor.free : MembershipColor.premium,
                                                    textAlign: "center",
                                                    fontFamily: "Montserrat",
                                                    fontSize: "28px",
                                                    fontStyle: "normal",
                                                    fontWeight: 700,
                                                    lineHeight: "normal",
                                                }}>{'$' + Health?.price + " / mes"}</Box>
                                        }
                                        <Box sx={{
                                            textAlign: "center",
                                            fontFeatureSettings: "'clig' off, 'liga' off",
                                            fontFamily: "Montserrat",
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "normal",
                                        }}>
                                            {cardType === "free" ? "Sin dejar datos bancarios" : "Primer mes de regalo"}

                                        </Box>
                                    </Box>
                                    {/*<div*/}
                                    {/*    id='promo-celular'*/}
                                    {/*    style={{*/}
                                    {/*        fontSize: '22px',*/}
                                    {/*        textAlign: 'center',*/}
                                    {/*        display: cardType === "free" ? 'none' : 'flex',*/}
                                    {/*    }}>*/}
                                    {/*    <p>*/}
                                    {/*        Por menos de <strong>$4 pesos al día</strong> llévate{*/}
                                    {/*        cardImage === "Seguro Inter Salud " ? ' Salud Protect Premium' : ' Celular Protect Premium'*/}
                                    {/*    },<strong>¡Y si contratas hoy el primer mes es de regalo!</strong>*/}
                                    {/*    </p>*/}
                                    {/*</div>*/}
                                    <div className="card-content" style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: '24px 12px 0px 12px',
                                        borderRadius: '8px 8px 0 0',
                                        width: '-webkit-fill-available',
                                        padding: '8px',
                                        background: cardType === "free" ? window.location.pathname === "/finsus" ? myColorPalette.getFinsusRgb() : MembershipColor.freeOpacity : MembershipColor.premiumOpacity
                                    }} />

                                    <div id='info-healt-premium'
                                        className="labelBenefit" style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            margin: '0px 12px 0px 12px',
                                            width: '-webkit-fill-available',
                                            background: cardType === "free" ? window.location.pathname === "/finsus" ? myColorPalette.getFinsusSecondary() : MembershipColor.freeSecondary : MembershipColor.premiumSecondary
                                        }}
                                    >
                                        <p style={{
                                            padding: '5px',
                                            margin: '0px',
                                            //  background: 'blue'
                                        }}>
                                            <strong style={{
                                                fontSize: 20,
                                                fontWeight: 600,
                                                color: window.location.pathname === "/finsus" ? myColorPalette.getSecondaryColor() : 'none'
                                                //color: window.location.pathname === "/finsus" ? myColorPalette.getSecondaryColor() : 'none'
                                            }}>
                                                {
                                                    isAlliance && cardType === "free" &&
                                                        cardImage === "Seguro Inter Salud " ?
                                                        "Mis coberturas"
                                                        :
                                                        cardType === "free" &&
                                                            cardImage === "Seguro Inter Salud " ?
                                                            'Selecciona una cobertura para crear tu plan ideal'
                                                            :
                                                            'Coberturas Incluidas'
                                                }
                                            </strong>
                                        </p>
                                    </div>
                                    {

                                        cardImage === "Seguro Inter Salud " && cardType === "premium" ?

                                            <>
                                                {
                                                    <>

                                                        <div>
                                                            {
                                                                DataSalud?.benefits?.map(benefit => {

                                                                    return <MembershipItem cardType={cardType}
                                                                        title={benefit.title}
                                                                        description={benefit.description} />
                                                                })
                                                            }
                                                        </div>
                                                    </>
                                                }


                                            </>
                                            :
                                            <>

                                            </>

                                    }
                                    {
                                        <RadioGroup aria-label="membership" name="membership" value={selectedValue}
                                            onChange={(ev) => {
                                                // handleChange(ev)
                                            }}>

                                            {
                                                cardImage === "Seguro Inter Salud " && cardType === "free" && (
                                                    Health?.benefits.map((benefit, index) => {
                                                        return <MembershipItemCheck
                                                            cardType={cardType}
                                                            title={benefit.title}
                                                            description={benefit.description}
                                                            checkedProp={selectedValue}
                                                            onCheckedChange={(isChecked) => {
                                                                handleCheckedChange(index, isChecked);
                                                                setSelectedValue(benefit.title);
                                                                setBenefitData({ benefit, plan: Health });
                                                            }}
                                                            isLastItem={index === Health?.benefits.length - 1}
                                                        />
                                                    })
                                                )


                                            }
                                        </RadioGroup>

                                    }

                                    {
                                        cardImage !== "Seguro Inter Salud " && cardType === "premium" && (
                                            Gadgets?.benefits.map(benefit => {

                                                return <MembershipItem cardType={cardType} title={benefit.title}
                                                    description={benefit.description} />
                                            })
                                        )
                                    }
                                    {
                                        cardImage !== "Seguro Inter Salud " && cardType === "free" && (
                                            Gadgets?.benefits.map(benefit => {

                                                return <MembershipItem cardType={cardType} title={benefit.title}
                                                    description={benefit.description} />
                                            })
                                        )
                                    }

                                    {
                                        cardImage === "Seguro Inter Salud " ?
                                            <>
                                                {
                                                    cardType === "free" ?
                                                        <div className="labelBenefit" style={{
                                                            background: cardType === "free" ?
                                                                window.location.pathname === "/finsus" ? myColorPalette.getFinsusSecondary() :
                                                                    MembershipColor.freeSecondary : MembershipColor.premiumSecondary,
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            margin: '0px 12px 0px 12px',
                                                            width: '-webkit-fill-available',
                                                        }}>
                                                            <p style={{
                                                                padding: '5px',
                                                                margin: '0px',

                                                            }}>
                                                                <strong style={{
                                                                    fontSize: 20,
                                                                    fontWeight: 600,
                                                                    color: window.location.pathname === "/finsus" ? myColorPalette.getSecondaryColor() : 'none'
                                                                }}>
                                                                    Cobertura base
                                                                </strong>
                                                            </p>
                                                        </div>
                                                        :
                                                        Health?.benefits.map(benefit => {

                                                            return <MembershipItem cardType={cardType}
                                                                title={benefit.title}
                                                                description={benefit.description} />
                                                        })
                                                }
                                                {
                                                    cardType === "free" && (
                                                        Gadgets?.benefits.map(benefit => {

                                                            if (benefit.title === 'Muerte accidental')
                                                                return <MembershipItem cardType={cardType}
                                                                    title={benefit.title}
                                                                    description={benefit.description} />
                                                        })
                                                    )
                                                }

                                            </>
                                            :
                                            <></>
                                    }

                                    <div className="labelBenefit" style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: '0px 12px 0px 12px',
                                        width: '-webkit-fill-available',
                                        background: cardType === "free" ?
                                            window.location.pathname === "/finsus" ? myColorPalette.getFinsusSecondary() :
                                                MembershipColor.freeSecondary : MembershipColor.premiumSecondary
                                    }}>
                                        <p style={{
                                            padding: '5px',
                                            margin: '0px',
                                            //  background: 'red'
                                        }}>


                                            <strong style={{
                                                fontSize: 20,
                                                fontWeight: 600,
                                                color: window.location.pathname === "/finsus" ? myColorPalette.getSecondaryColor() : 'none'
                                            }}>
                                                Además, tu seguro incluye:
                                            </strong>
                                        </p>
                                    </div>
                                    {
                                        Gadgets?.extra.map(benefit => {

                                            return <MembershipItem cardType={cardType} title={benefit.title}
                                                description={benefit.description} />
                                        })
                                    }
                                    {
                                        cardImage === "Seguro Inter Salud " ?

                                            <div className="card-content" style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                margin: '0px 12px 0px 12px',
                                                borderRadius: '0 0 8px 8px',
                                                width: '-webkit-fill-available',
                                                padding: '8px',
                                                background: cardType === "free" ? MembershipColor.freeOpacity : MembershipColor.premiumOpacity
                                            }} />

                                            :
                                            <>
                                                <div className="labelBenefit" style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    margin: '0px 12px 0px 12px',
                                                    width: '-webkit-fill-available',
                                                    background: cardType === "free" ?
                                                        window.location.pathname === "/finsus" ? myColorPalette.getFinsusSecondary() :
                                                            MembershipColor.freeSecondary : MembershipColor.premiumSecondary
                                                }}>
                                                    <p style={{
                                                        padding: '5px',
                                                        margin: '0px',

                                                    }}>
                                                        <strong style={{
                                                            fontSize: 20,
                                                            fontWeight: 600,
                                                            color: window.location.pathname === "/finsus" ? myColorPalette.getSecondaryColor() : 'none'
                                                        }}>
                                                            Restricciones:
                                                        </strong>
                                                    </p>
                                                </div>
                                                <div style={{
                                                    background: cardType === "free" ? window.location.pathname === '/finsus' ? myColorPalette.getFinsusRgb() : MembershipColor.freeOpacity : MembershipColor.premiumOpacity,
                                                    padding: '12px 18px',
                                                    borderRadius: '0 0 8px 8px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    margin: '0px 12px 24px',
                                                    width: '-webkit-fill-available',
                                                }}>
                                                    <p style={{
                                                        fontSize: 16,
                                                        fontStyle: 'normal',
                                                        fontWeight: 400,
                                                        lineHeight: 'normal'
                                                    }}>
                                                        {
                                                            cardType === "free" ? 'Solo aplica para modelos de celular que no tengan más de 5 años de antigüedad de fabricación'
                                                                : 'Solo aplica para modelos de celular que su factura o comprobante de compra no tenga más de 3 años de antigüedad.'
                                                        }

                                                    </p>
                                                </div>
                                            </>
                                    }
                                    <Box sx={{
                                        display: 'flex',
                                        width: { xs: '90%', sm: '90%', md: '60%' },
                                        padding: '24px 12px 24px'
                                    }}>
                                        {
                                            /**
                                             * El primer boton es para ejecutar el submit del formulario
                                             * y el segundo se ejecuta de forma normal en la landingpage(HOME)
                                             */
                                        }
                                        {newProductRegistration ?
                                            <Button
                                                type="submit"
                                                size={'medium'}
                                                variant={'contained'}
                                                disabled={(selectedButton && cardCategory === "salud" && cardType === "free")}
                                                onClick={() => {
                                                    window.dataLayer.push({
                                                        event: cardType == "free" ? 'TRUS_nuevo_miseguro_freemium' : "TRUS_nuevo_miseguro_premium",
                                                        seguro: cardCategory == "celular" ? "Celular Protect" : "Salud Protect",
                                                        tipo_seguro: cardType == "free" ? "De regalo" : "Premium",
                                                        telefono: userResponse?.phone,
                                                        correo: userResponse?.email,
                                                        boton_contacto: 'Continuar',
                                                    })
                                                }}
                                                style={{
                                                    color: 'var(--white, #FFF)',
                                                    backgroundColor: selectedButton && cardImage === "Seguro Inter Salud " && cardType === "free" ? '#8F8F8F' : 'rgb(3, 158, 204)',
                                                    fontSize: '16px',
                                                    textTransform: 'uppercase',
                                                    fontWeight: '600',
                                                    padding: '8px 10px',
                                                    minWidth: 'auto',
                                                    width: '100%'
                                                }}
                                            >¡Lo quiero!</Button>
                                            :
                                            <CustomButton
                                                text={"¡Lo quiero!"}
                                                onClick={() => eventFiunction()}
                                                size={'medium'}
                                                variant={'contained'}

                                                style={{
                                                    color: 'var(--white, #FFF)',
                                                    backgroundColor: window.location.pathname === "/finsus" ? myColorPalette.getfinsusColor() :
                                                        selectedButton && cardImage === "Seguro Inter Salud " &&
                                                            cardType === "free" ? '#8F8F8F' : 'rgb(3, 158, 204)',
                                                    //filter: selectedButton && cardImage === "Seguro Inter Salud " && cardType === "free" ? 'blur(1px)' : 'none',
                                                    fontSize: '16px',
                                                    textTransform: 'uppercase',
                                                    fontWeight: '600',
                                                    padding: '8px 10px',
                                                    minWidth: 'auto',
                                                    width: '100%'
                                                }}
                                            />}
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                    :

                    isAlliance && allianceSettingResponse && cardImage === 'Seguro Inter Salud ' && cardCategory === "salud" && allianceBenefit ?

                        <div className={`slider`}
                            id='identifier-123'
                            style={{
                                display: Gadgets != undefined ? 'flex' : 'none',
                                width: '100%',
                                margin: isIphone5 ? 'auto 10px' : 'auto',
                                height: 'fit-content',
                            }}
                        >
                            <div
                                key={idCard}
                            >
                                <div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: 'fit-content',
                                        height: 'fit-content',
                                        alignItems: 'center',
                                        borderRadius: '10px',

                                    }}>

                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            margin: '24px 12px 0px 12px',
                                            borderRadius: '8px 8px 0 0',
                                            width: '-webkit-fill-available',
                                            padding: '8px',
                                        }}>
                                            {
                                                cardImage === "Seguro Inter Salud " &&
                                                <Box sx={{
                                                    color: cardType === "free" ?
                                                        allianceSettingResponse?.data?.colors?.primary ? allianceSettingResponse?.data?.colors?.primary :
                                                            "#212121" : MembershipColor.premium,
                                                    textAlign: "center",
                                                    fontFamily: "Montserrat",
                                                    fontSize: "28px",
                                                    fontStyle: "normal",
                                                    fontWeight: 700,
                                                    lineHeight: "normal",
                                                }}>{"$0 / mes"}</Box>

                                            }
                                            <Box sx={{
                                                color: "var(--Black, #212121)",
                                                textAlign: "center",
                                                fontFeatureSettings: "'clig' off, 'liga' off",
                                                fontFamily: "Montserrat",
                                                fontSize: "16px",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                lineHeight: "normal",
                                            }}>{cardType === "free" ? "Sin dejar datos bancarios" : ""}</Box>
                                        </Box>
                                        <div
                                            id='promo-celular'
                                            style={{
                                                fontSize: '22px',
                                                textAlign: 'center',
                                                display: cardType === "free" ? 'none' : 'none',
                                            }}>
                                            <p>
                                                Por menos de <strong>$4 pesos al día</strong> llévate{
                                                    cardImage === "Seguro Inter Salud " ? ' Salud Protect Premium' : ' Celular Protect Premium'
                                                },<strong>¡Y si contratas hoy el primer mes es de regalo!</strong>
                                            </p>
                                        </div>
                                        <div className="card-content" style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            margin: '24px 12px 0px 12px',
                                            borderRadius: '8px 8px 0 0',
                                            width: '-webkit-fill-available',
                                            padding: '8px',
                                            background: cardType === "free" ? MembershipColor.alianzaOpacity : MembershipColor.premiumOpacity
                                        }} />

                                        <div id='info-healt-premium'
                                            className="labelBenefit" style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                //margin: '0px 12px 0px 12px',
                                                //width: '-webkit-fill-available',
                                                width: '100%',
                                                background: cardType === "free" ?

                                                    allianceSettingResponse?.data?.colors?.primary ? allianceSettingResponse?.data?.colors?.primary : '#8F8F8F'
                                                    : MembershipColor.premiumSecondary
                                            }}
                                        >
                                            <p style={{
                                                padding: '5px',
                                                margin: '0px',
                                            }}>
                                                <strong style={{
                                                    fontSize: 20, fontWeight: 600, color: allianceSettingResponse?.data?.colors?.primary ? "#fff" :
                                                        "var(--Black, #000)"
                                                }}>
                                                    {
                                                        isAlliance && cardType === "free" &&
                                                            cardImage === "Seguro Inter Salud " ?
                                                            "Mis coberturas"
                                                            :
                                                            cardType === "free" &&
                                                                cardImage === "Seguro Inter Salud " ?
                                                                'Selecciona una cobertura para crear tu plan ideal'
                                                                :
                                                                'Coberturas Incluidas'
                                                    }
                                                </strong>
                                            </p>
                                        </div>

                                        {
                                            <RadioGroup aria-label="membership" name="membership" value={selectedValue}
                                                onChange={(ev) => {
                                                    // handleChange(ev)
                                                }}>

                                                {
                                                    /* cardImage === "Seguro Inter Salud " && cardType === "free" && (
                                                       Health?.benefits.map((benefit, index) => {
                                                         return <MembershipItemCheck
                                                             cardType={cardType}
                                                             title={benefit.title}
                                                             description={benefit.description}
                                                             checkedProp={selectedValue}
                                                             onCheckedChange={(isChecked) => {
                                                               handleCheckedChange(index, isChecked);
                                                               setSelectedValue(benefit.title);
                                                               setBenefitData({benefit, plan: Health});
                                                             }}
                                                             isLastItem={index === Health?.benefits.length - 1}
                                                         />
                                                       })
                                                   )


                                               */}
                                            </RadioGroup>

                                        }

                                        <Box sx={{
                                            margin: '0px 12px 0px 12px',
                                            width: '-webkit-fill-available',
                                            background: MembershipColor.alianzaOpacity
                                        }}>
                                            {/* <MembershipItem icon={"https://cdn-icons-png.flaticon.com/512/9239/9239821.png"}
                                                title={"Check-up médico"}
                                                description={benefitIdHasCoberturas ?
                                                    (healt123 !== undefined ? healt123.toString() : "Si requieres un estudio de laboratorio te apoyamos con Química Sanguínea de 24 elementos,biometría hemática y examen general de orina, en laboratorios participantes. (1 evento al año).")
                                                    : "Si requieres un estudio de laboratorio te apoyamos con Química Sanguínea de 24 elementos,biometría hemática y examen general de orina, en laboratorios participantes. (1 evento al año)."} /> */}

                                            <MembershipItem icon={allianceBenefit.icon}
                                                title={allianceBenefit.title}
                                                description={allianceBenefit.description} />

                                            <MembershipItem icon={"https://cdn-icons-png.flaticon.com/512/1669/1669610.png"}
                                                title={"Muerte accidental"}
                                                description={"Protege a tus seres queridos por $20,000 MXN en caso de fallecimiento."} />

                                        </Box>


                                        <div className="labelBenefit" style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            //margin: '0px 12px 0px 12px',
                                            //width: '-webkit-fill-available',
                                            width: '100%',
                                            background: cardType === "free" ?
                                                allianceSettingResponse?.data?.colors?.primary ? allianceSettingResponse?.data?.colors?.primary : '#8F8F8F'
                                                : MembershipColor.premiumSecondary
                                        }}>
                                            <p style={{
                                                padding: '5px',
                                                margin: '0px',

                                            }}>

                                                <strong style={{
                                                    fontSize: 20, fontWeight: 600, color: allianceSettingResponse?.data?.colors?.primary ? "#fff" :
                                                        "var(--Black, #000)"
                                                }}>
                                                    {"Además, tu seguro incluye:1"}
                                                </strong>
                                            </p>
                                        </div>
                                        <Box sx={{
                                            margin: '0px 12px 0px 12px',
                                            width: '-webkit-fill-available',
                                            background: MembershipColor.alianzaOpacity
                                        }}>
                                            <MembershipItem icon={"https://cdn-icons-png.flaticon.com/512/2977/2977921.png"} title={"Club de descuentos"}
                                                description={"Ofertas en miles de comercios participantes, con hasta un 40% de descuento."} />
                                            <MembershipItem icon={"https://cdn-icons-png.flaticon.com/512/4422/4422877.png"} title={"Telemedicina general ilimitada"}
                                                description={"Consulta en línea y receta digital válida para comprar tus medicamentos."} />

                                        </Box>
                                        {
                                            cardImage === "Seguro Inter Salud " && cardType === "free" &&

                                            <div className="card-content" style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                margin: '0px 12px 0px 12px',
                                                borderRadius: '0 0 8px 8px',
                                                width: '-webkit-fill-available',
                                                padding: '8px',
                                                background: cardType === "free" ? MembershipColor.alianzaOpacity : MembershipColor.premiumOpacity
                                            }} />


                                        }
                                        <Box sx={{
                                            display: 'flex',
                                            width: { xs: '90%', sm: '90%', md: '60%' },
                                            padding: '24px 12px 24px',
                                        }}>
                                            {
                                                /**
                                                 * El primer boton es para ejecutar el submit del formulario
                                                 * y el segundo se ejecuta de forma normal en la landingpage(HOME)
                                                 */
                                            }

                                            <CustomButton
                                                text={"¡Lo quiero!"}
                                                onClick={() => eventFunctionAlianza()}
                                                size={'medium'}
                                                variant={'contained'}

                                                style={{
                                                    color: 'var(--white, #FFF)',
                                                    backgroundColor: allianceSettingResponse?.data?.colors?.primary ? allianceSettingResponse?.data?.colors?.primary : '#8F8F8F',
                                                    fontSize: '16px',
                                                    textTransform: 'uppercase',
                                                    fontWeight: '600',
                                                    padding: '8px 10px',
                                                    minWidth: 'auto',
                                                    width: '100%'
                                                }}
                                            />
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ''
            }

        </Box>
    );
};

export default MemberShipCardNewDesign;
