import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import {Typography} from "@mui/material";


interface TimeLineTrackingStatusCheckUpMedicoI {
    data ?: any;
}
const DESCRIPTION_1 = (description: any) => {

    return <div>
        <Typography
            fontSize={18}
            dangerouslySetInnerHTML={{__html: description}}
        />
    </div>
}






export const timeLineProccess=(status1:boolean=false,status2:boolean=false,status3:boolean=false,days:string="7")=>  {

    let array_process = [
            {
                isActive: status1,
                info: "Estamos buscando el laboratorio más cercano a tu domicilio."
            },
            {
                isActive: status2,
                info: "Ya localizamos el laboratorio, estamos generando tu orden."
            },
            {
                isActive: status3,
                info:  DESCRIPTION_1(`Te enviamos un correo con la orden de check-up, tienes <b>${days || 7} días</b> para hacer uso de tu beneficio.`)
            },
        ]

    return array_process
}

export  const validateStatusExist =(status:string= "",navigate:any=null)=>{
    if (status !== "Inprogress"  && status !== "Issued") {
        navigate("/portal-del-cliente")
    }
}

export const validate_status =(status:string="Inprogress",days:any="7")=>{
    const tableEventArray: any = [
        {"Inprogress" : timeLineProccess (true,false,false, days) },
        {"Issued" : timeLineProccess (true,true,true, days)  },
    ];

    let detection_array =  []
    if (status){
        const filtered = tableEventArray.filter((x:any) => x.hasOwnProperty(status));
        detection_array = filtered.length > 0 ?  filtered[0][status] : []
    }


    return detection_array
}


export const TimeLineTrackingStatusCheckUpMedico: React.FC<TimeLineTrackingStatusCheckUpMedicoI> = ({data=[]}: TimeLineTrackingStatusCheckUpMedicoI) => {

    return (
        <>
            <Timeline>
                {data.map(({isActive, info}:any,index:number) => {
                        return <TimelineItem key={index} sx={{
                            "&::before":{
                                flex: 0,
                                padding: 0
                            }
                        }}>
                            <TimelineSeparator sx={{marginTop: index ===0 ? 3: 0 }}>
                                {
                                    index !==  0 &&
                                  <TimelineConnector  sx={{backgroundColor: isActive ? "green" : "#C7C7C7"}}/>
                                }
                                <TimelineDot style={{ margin: '0 0 0 0' }} color={isActive ? "success" : "grey"} />
                                {
                                    data.length - 1 !== index &&
                                  <TimelineConnector sx={{backgroundColor:  isActive ? "green" : "#C7C7C7"}}/>
                                }
                                {
                                    data.length - 1 === index &&
                                  <TimelineConnector sx={{backgroundColor:'transparent'}} />
                                }
                            </TimelineSeparator>
                            <TimelineContent sx={{
                                opacity: isActive ? 1 : 0.3,
                                fontSize:18,
                                marginTop:index !==0 ? 1.5: 0}}>{info}</TimelineContent>
                        </TimelineItem>
                    })
                }
            </Timeline>
        </>
    );
};
