import React from 'react';
import { 
  Box,
  Typography
} from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Label } from '../../../atoms/Label/Label';

/**
 * NOTA: si marca error de referencia en algun componente
 * de material ui, es debido a la referencia 
 * del componente de material que no puede extender 
 * del componente modificado.
 */

const BasicModalContent = React.forwardRef<HTMLDivElement>((props, ref) => {
  const style = {
    width: 260,
    minWidth: 260,
    maxWidth: 400,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    backgroundColor: '#fff'
  };

  return (
    <Box sx={style} ref={ref}>
      <Typography variant="h6" component="h6" style={{ fontWeight: 700 }}> ¿No sabes qué onda? </Typography>
      <Typography variant="h6" component="h6" style={{ fontWeight: 700, fontSize: 16 }}> Llámanos al</Typography>
      <Label textType='h5' fontSize={700} url="tel:5544246837" color='rgb(3, 158, 204)' value='5544246837' />
      <Typography variant="h6" component="h6" style={{ fontWeight: 700, fontSize: 16 }}> o mándanos un mensajito por WhatsApp </Typography>
      <Label textType='h5' fontSize={700} url="https://wa.me/5544246837" color='rgb(3, 158, 204)' value='5543269037' icon={<WhatsAppIcon style={{ color: 'rgb(3, 158, 204)' }} />} />
      <Typography variant="h6" component="h6" style={{ fontWeight: 700, fontSize: 16 }}> o a nuestro mail </Typography>
      <Label textType='h5' fontSize={700} url="mailto:hola_teregalamosunseguro@inter.mx" color='rgb(3, 158, 204)' value='hola_teregalamosunseguro@inter.mx' icon={<MailOutlineIcon  style={{ color: 'rgb(3, 158, 204)'}} />} />
    </Box>
  );
});

export default BasicModalContent;
