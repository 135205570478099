import React from "react";
import CustomContainer from "../../organisms/Container/Container";
import Login from "../../organisms/Login";

const LoginTemplate: React.FC = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 40 }}>
      <CustomContainer childComponent={<Login />} />
    </div>
  );
};

export default LoginTemplate;
