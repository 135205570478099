import React from 'react';
import CustomContainer from '../../organisms/Container/Container';
import { styled } from 'styled-components';
import { CustomTypography } from '../../atoms/Label/Label';
import CustomTab from './CustomTab';

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 85px;
  gap: 40px;
  align-items: center;
`;

const AvatarGroupWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 328px;
`;

const TelemedicinaCustomComponent: React.FC = () => {


    const ConsultantValue: String = "NONE"

    return (
        <React.Fragment>
            <CustomContainer childComponent={<ComponentWrapper>
                <CustomTypography
                    item={"Telemedicina"}
                    style={{
                        fontSize: 24,
                        textAlign: 'center',
                        fontWeight: 700,
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                        letterSpacing: '0.5px'
                    }}
                />

                <div style={{ width: '100%' }}>
                    <CustomTab consultantValue={ConsultantValue} />
                </div>
            </ComponentWrapper>} />
        </React.Fragment>
    );
};

export default TelemedicinaCustomComponent;
