import React, {useEffect} from 'react';
import HowItWorks from '../../components/organisms/HowItWorks/HowItWorks';
import MemberShipSlider from '../../components/MeetOurMemberships/MembershipSlider';
import ContainerJoinRevolution from '../../components/molecules/JoinRevolution/ContainerJoinRevolution';
import Revolution from '../../components/organisms/Revolution/Revolution';
import Question from '../../components/organisms/Questions/Question';
import {Box, Container, Typography, useMediaQuery, useTheme} from '@mui/material';
import {styled} from 'styled-components';
import CustomContainer from '../../components/organisms/Container/Container';
import {useParams} from "react-router-dom";
import LandingAllianceBackgroundTitle
    from "../../components/organisms/LandingAllianceBackgroundTitle/LandingAllianceBackgroundTitle";
import {DESCRIPTION_ALLIANCE} from "../../utils/ConstantsAlliance";
import SimpleSteps from "../../components/organisms/SimpleStepsAlliance/SimpleSteps";
import useFetch from "../../hooks/useFetch";
import {
    apiError as ErrorGadgets,
    apiLoading as LoadingGadgets,
    apiSuccess as SuccessGadgets
} from "../../api/gadgetsSlice";
import {apiError as ErrorHealth, apiLoading as LoadingHealth, apiSuccess as SuccessHealth} from "../../api/healthSlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {restartValue as restartAllianceSetting} from "../../api/allianceSettingSlice";
import QuestionsAlliance from "../../components/organisms/QuestionsAlliance/QuestionAlliance";
import { restartValue as restartAllianceUser } from '../../api/userAlianceSlice';

/**
 * componentes de molecules y molecules obtiene elementos de atoms
 **/

interface WelcomeProps {
    // Define las propiedades del componente aquí
}

const LandingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
`;

const LandingAlliance: React.FC<WelcomeProps> = () => {
    const location = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch()

    let {id} = useParams();
    const allianceSettingResponse = useSelector((state: any) => state.allianceSetting.response);


    const matches = useMediaQuery('(min-width:600px)');
    const {breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const isTablet = useMediaQuery(breakpoints.down('md'));
    useEffect(() => {
        dispatch(restartAllianceUser())
    }, []);

    const {fetchData} = useFetch();
    const fetchingDataGadgets = () => {
        return fetchData('catalogs/gadgets', 'GET', {}, {}
            , LoadingGadgets, SuccessGadgets, ErrorGadgets);
    }
    const fetchingDataHealth = () => {
        return fetchData('catalogs/health', 'GET', {}, {}
            , LoadingHealth, SuccessHealth, ErrorHealth);
    }
    useEffect(() => {
        fetchingDataGadgets();
        fetchingDataHealth();
    }, []);


    useEffect(() => {
        if (allianceSettingResponse && allianceSettingResponse.data === 404) {
            dispatch(restartAllianceSetting())
            navigate("/")
        }
    }, [allianceSettingResponse]);
    // Implementa el componente aquí
    return (
        <React.Fragment>

            <LandingWrapper style={{
                //paddingTop: matches ? '80px' : '40px'
            }}>
                <LandingAllianceBackgroundTitle nameCompany={allianceSettingResponse?.data?.name}
                                                colors={allianceSettingResponse?.data?.colors} image={
                    isMobile ?
                        allianceSettingResponse?.data?.imagenes?.landing_mobile
                        :
                        isTablet ?
                            allianceSettingResponse?.data?.imagenes?.landing_tablet
                            :
                            allianceSettingResponse?.data?.imagenes?.landing_desktop
                }/>

                <Container maxWidth="xl">
                    <Box justifyContent={'center'} marginTop={5}>
                        {DESCRIPTION_ALLIANCE.map((desc, i) => {
                            return (
                                <div key={i}>
                                    {desc.description}
                                </div>
                            )
                        })}
                    </Box>
                </Container>

                <CustomContainer childComponent={
                    <MemberShipSlider
                        typeCurrent='free'
                        title='Conoce nuestros seguros'
                        newProductRegistration={false}
                        isAlliance={true}
                    />
                }/>

                <SimpleSteps/>
                {/*<ContainerJoinRevolution />*/}
                {
                    allianceSettingResponse?.data?.faqs?.length
                        ? <QuestionsAlliance />
                        : <Question/>
                }
            </LandingWrapper>
        </React.Fragment>
    );
};

export default LandingAlliance;
