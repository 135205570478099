import React from "react";
import {styled} from "styled-components";
import {useField} from "formik";
import FormItemGenerate from "../../../atoms/FormItems/FormItemGenerate";
import {Box, Typography} from "@mui/material";



type ResetPasswordContentProps = {
    errors?: any;
    handleChange?: any;
    handleBlur?: any;
}

const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;


const ResetPasswordContent: React.FC<ResetPasswordContentProps> = ({handleChange, handleBlur}) => {
    const [password, passwordMeta] = useField("password");
    const [passwordConfirm, passwordConfirmMeta] = useField("passwordConfirm");

    const items = [
        {
            id: 1,
            label: 'Contraseña',
            name: 'password',
            as: 'textField',
            type: 'password',
            error: password,
            meta: passwordMeta
        },
        {
            id: 2,
            label: 'Confirma contraseña',
            name: 'passwordConfirm',
            type: 'password',
            as: 'textField',
            error: passwordConfirm,
            meta: passwordConfirmMeta
        }
    ];

    return (
        <ContentStyled>
            <FormItemGenerate
                items={items}
                handleChange={handleChange}
                handleBlur={handleBlur}
            />

            <Box>
                <Typography style={{ fontWeight: 600 }}>
                    Una contraseña segura debe tener:
                </Typography>
                <ul>
                    <li>Al menos 10 caracteres </li>
                    <li>Un carácter especial</li>
                    <li>Debe de tener mayúsculas y minúsculas</li>
                </ul>
            </Box>

        </ContentStyled>
    )

};

export default ResetPasswordContent;
