import React from "react";

function MasterCardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="16"
      fill="none"
      viewBox="0 0 23 16"
    >
      <path
        fill="url(#paint0_linear_2435_8396)"
        d="M21.506 0H1.636C.811 0 .143.686.143 1.533v12.934C.143 15.314.81 16 1.636 16h19.87c.825 0 1.494-.686 1.494-1.533V1.533C23 .686 22.331 0 21.506 0z"
      ></path>
      <path
        fill="#282828"
        d="M12.587 11.452c-.655-.006-1.355-.138-1.786-.337l-.314-.144-.085.395H8l.878-4.105-1.72 4.105H4.96L3.727 6.598a.593.593 0 00-.049-.152.445.445 0 00-.056-.034c-.274-.149-.766-.31-1.285-.424l-.463-.1.212-1.002h2.819c.562 0 1.015.381 1.126.947l.298 1.584 1.002-2.53h4.457l-.181.851c.502-.593 1.354-.952 2.398-.952.51 0 .987.087 1.5.274l.39.142-.462 2.158-.54-.249c-.391-.18-.741-.217-.965-.217-.079 0-.142.005-.184.01a.746.746 0 00-.082.015c.108.067.252.143.34.189.51.268 1.236.648 1.474 1.423l1.235-2.947a1.12 1.12 0 011.047-.697h1.608l1.353 6.48h-2.295l-.173-.826h-1.113l-.299.825h-2.55l.153-.366c-.49.29-1.122.452-1.852.452h-.003zM11.46 9.041c.335.157.651.305 1.211.305h.05a.918.918 0 00.351-.07 2.448 2.448 0 00-.44-.255c-.769-.373-1.248-.837-1.433-1.381l-.248 1.163.51.238z"
        opacity="0.05"
      ></path>
      <path
        fill="#282828"
        d="M12.588 11.214c-.622-.007-1.284-.13-1.688-.316l-.172-.08.361-1.69.271.128c.341.16.699.327 1.311.327h.052c.248-.004.62-.089.622-.306.001-.09-.072-.21-.608-.47-.346-.169-1.399-.682-1.385-1.722.01-1.213 1.1-2.061 2.654-2.061.482 0 .933.083 1.419.26l.194.07-.356 1.665-.27-.124a2.558 2.558 0 00-1.065-.24c-.091 0-.165.006-.214.012-.29.038-.398.16-.4.232-.003.114.32.283.579.42.603.316 1.429.75 1.425 1.767-.006 1.272-1.102 2.128-2.728 2.128h-.002zm6.03-.087l-.173-.825H16.97l-.299.825h-2.025l2.283-5.451a.885.885 0 01.829-.552h1.414l1.254 6.003h-1.81zm-.51-2.434l-.201-.968-.352.968h.553zm-9.814 2.434l1.284-6.003h1.916l-1.284 6.003H8.294zm-3.149 0L3.96 6.538c-.06-.231-.088-.263-.223-.336-.292-.159-.808-.33-1.348-.447l-.23-.05.122-.58h2.626c.445 0 .804.302.893.754l.45 2.391 1.245-3.146h2.021L7 11.127H5.145z"
        opacity="0.07"
      ></path>
      <path
        fill="#fff"
        d="M11.59 7.088c-.01.796.71 1.24 1.252 1.504.556.271.743.445.741.687-.004.371-.444.535-.856.541-.718.011-1.136-.194-1.468-.349l-.258 1.211c.333.153.95.288 1.59.293 1.5 0 2.484-.741 2.489-1.89.006-1.459-2.018-1.54-2.004-2.192.005-.197.194-.408.607-.462.205-.027.77-.048 1.41.247l.25-1.171a3.825 3.825 0 00-1.337-.246c-1.414 0-2.408.752-2.416 1.827zm6.169-1.726a.652.652 0 00-.609.405l-2.145 5.122h1.5l.3-.825h1.833l.173.825h1.323L18.98 5.362h-1.221zm.21 1.493l.432 2.076h-1.186l.754-2.076zM9.77 5.362L8.588 10.89h1.43L11.2 5.362H9.771zm-2.116 0L6.167 9.124l-.602-3.199a.666.666 0 00-.66-.563H2.472l-.033.16c.5.11 1.067.284 1.41.471.211.114.271.214.34.486l1.14 4.41h1.512l2.317-5.526H7.655z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_2435_8396"
          x1="9.688"
          x2="14.255"
          y1="1.099"
          y2="16.975"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00B3EE"></stop>
          <stop offset="1" stopColor="#0082D8"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default MasterCardIcon;