export enum MODES {
    develop = "develop",
    qa = "qa",
    stage = "stage",
    production = "production"
}

export type ModesTypes = keyof typeof MODES;

export interface IConfig {
    base: string;
    backend: string;
    auth0: {
        domain: string;
        clientId: string;
    },
    stripe: {
        publicKey: string;
    }
}

export type ConfigEnv = {
    [key in MODES]: IConfig
}