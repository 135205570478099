import * as React from 'react';
import { useTheme, useMediaQuery, Box } from '@mui/material';
import terminoY from '../../../assets/TermsAndConditions/términos y.jpg';
import condiciones from '../../../assets/TermsAndConditions/condiciones.jpg';

import AccordionContent from './components/AccordionContent';
import coberturas from './data';
import CustomContainer from '../Container/Container';

interface IPTermsAndConditionsContentAlliance {
  benefit_id: string
}

function TermsAndConditionsContentAlliance(props: IPTermsAndConditionsContentAlliance) {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  };

  const AcordionContainer: React.CSSProperties = {
    width: '100%',
    height: '100%',
    paddingBottom: '96px'
  }

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return (
    <Box sx={{ backgroundColor: '#FFF' }}>
      <CustomContainer childComponent={
        <div
          style={{
            display: 'flex',
            width: isMobile ? '100%' : '100%',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '80px',
            marginTop: '50px',
            height: '100%'
          }}
        >
          <div style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: 12,
            width: isMobile ? '' : '100%',
            justifyContent: isMobile ? 'flex-start' : 'center'
          }}>
            <img style={{ height: "33px" }} src={terminoY} alt="Preguntas Frecuentes" />
            <img style={{ height: "27px", paddingTop: "5px" }} src={condiciones} alt="Preguntas Frecuentes" />
          </div>

          <div style={AcordionContainer}>
            {
              coberturas
                .filter(cob => cob.benefit_id === props.benefit_id)
                .map(cob => <AccordionContent data={cob} expanded={cob.title} handleChange={handleChange} hideTitle />)
            }
          </div>
        </div>
      } />
    </Box>);
}

export default TermsAndConditionsContentAlliance;