import { BenefitValues } from "../components/benefitEngine/benefit.values";


export interface BenefitInfo {
    path?: string;
    secondPath?: string;
    externalUrl?: string;
    waitDays?: number;
    badge?: string;
    modal?: string;
}

export type DictionaryBenefit = {
    [key: string]: BenefitInfo;
};

export const BenefitRef: DictionaryBenefit = {
    [BenefitValues.PagoMedicamentos]: {
        waitDays: 30,
    },
    [BenefitValues.ReembolsoGastosMedicos]: {
        waitDays: 30
    },
    [BenefitValues.CheckUpMedico]: {
        waitDays: 30
    },
    [BenefitValues.PerdidasOrganicas]: {
        waitDays: 30
    },
    [BenefitValues.InvalidezTotal]: {
        waitDays: 30
    },
    [BenefitValues.ApoyoIncapacidad]: {
        waitDays: 30
    },
    [BenefitValues.Reparacion]: {
        path: '/reparacion-celular',
        waitDays: 30
    },
    [BenefitValues.Descuento]: {
        path: '/ofertas'
        //externalUrl: 'https://webview.ofertaspin.com/#/main?Webkey=628B9559-FC6E-4634-B37E-D296EBECBC13&Latitud=0&Longitud=0&Tarjeta=INTERMX01'

    },
    [BenefitValues.Telemedicina]: {
        //path: '/telemedicina'
        externalUrl: ''
    },
    [BenefitValues.Muerte]: {
        path: '/muerte-accidental',
    },
    [BenefitValues.Robo]: {
        path: '/robo-celular',
        waitDays: 30
    },
    [BenefitValues.DescuentosInter]: {

    }

}


