import { useState, useRef } from 'react'
import FormContainer from '../../../components/organisms/Form/FormContainer'
import DatosDelCelular from '../../../components/organisms/Form/FormContent/DatosDeCelular';
import { fieldIsRequired } from '../../../utils/scrollToTop';
import * as Yup from "yup";
import { apiSuccess } from '../../../api/userSlice';
import { useSelector, useDispatch } from "react-redux";
import { InterInstance, addAccessToken } from '../../../api/config';
import RenderAlert from '../../../components/organisms/Modal';
import LoadingComponent from '../../../components/organisms/loading/loading';

const DeviceForm = () => {
  const dispatch = useDispatch();
  const [buttonNextHidded, setButtonNextHidded] = useState<boolean>(true);
  const response = useSelector((state: any) => state?.user?.response);
  const formikRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const getValuesByPropertyValue = (object: any, propertyCopied: any) => {
    let newObject: { [key: string]: any } = {};

    for (const property of propertyCopied) {
      if (object.hasOwnProperty(property)) {
        newObject[property] = typeof object[property] === "string" ? object[property].trim() : object[property];
      }
    }

    return newObject;
  };

  const membershipTypeModule = {
    celular: {
      formSteps: 0,
      steps: ["Paso 1 de 1"],
      titles: [
        "Para obtener tu seguro, primero ayúdanos con los datos de tu celular"
      ],
      initialValues: {
        marca: "",
        modelo: "",
        descripcion: "",
        imei: "",
        recaptcha: false,
      },
      stepsTextRequired: [0]
    },
  };

  const renderStepContent = (
    step: number,
    errors: any,
    handleChange: any,
    handleBlur: any
  ) => {
    switch (step) {
      case 0:
        setButtonNextHidded(true);
        return (
            isLoading 
            ? <LoadingComponent isLoaded={!isLoading} /> 
            : <DatosDelCelular
                handleChange={handleChange}
                handleBlur={handleBlur}
            />
        );
      default:
        return <></>;
    }
  };

  const _handleSubmit = async (values: any, actions: any) => {
    const steppers = [
      ["marca", "modelo", "descripcion"]
    ];

    let content: any = {};
    if (steppers[0].length > 0) {
      content = getValuesByPropertyValue(values, steppers[0]);
    }
    let updateRedux = JSON.parse(JSON.stringify(response))
    updateRedux.data.gadgets = {
      ...updateRedux.data.gadgets,
      id_brand: content?.marca,
      id_line: content?.modelo,
      id_model: content?.descripcion
    }
    try {
        setIsLoading(true)
        await addAccessToken()
        const { data } = await InterInstance.post(`/gadgets`,
            {
                event: "update-phone",
                content
            }
        )
        if (data?.data?.error) {
            return RenderAlert({ severity: "warning", message: "Ocurrio un error inesperado" })
        }else{
            dispatch(apiSuccess(updateRedux))
            return RenderAlert({ severity: "success", message: "Tu celular ha sido actualizado" })
        }
    } catch (error) {
        RenderAlert({ severity: "warning", message: "Ocurrio un error inesperado" })
        console.log(error)
    } finally {
        setIsLoading(false)
    }
  }

  const validationSchemaStep4 = Yup.object().shape({
    marca: Yup.string().required(fieldIsRequired),
    modelo: Yup.string().required(fieldIsRequired),
    descripcion: Yup.string().required(fieldIsRequired)
  });

  const validatorSchemaStepper = () => {
    return validationSchemaStep4;
  };

  const getFormikReference = (formik: any) => {
    formikRef.current = formik;
  };

  return (
    <div>
      <FormContainer
        buttonNext={buttonNextHidded}
        steps={membershipTypeModule['celular']?.steps}
        initialValues={membershipTypeModule['celular']?.initialValues}
        activeStep={0}
        setActiveStep={0}
        title={['']}
        renderStepContent={renderStepContent}
        _handleSubmit={_handleSubmit}
        validatorSchemaStepper={validatorSchemaStepper}
        formObserverKey="FormObserver"
        formObserverSlice={apiSuccess}
        showTextRequire={true}
        localData={response}
        formikRef={getFormikReference}
      />
    </div>
  )
}

export default DeviceForm
