import React from "react";
import {Box, Grid, useMediaQuery, useTheme} from "@mui/material";
import {CustomTypography} from '../../atoms/Label/Label';
import {CustomButton} from '../../atoms/Buttons/Buttons';
import { useSelector } from 'react-redux';

interface LandingProps {
    nameCompany?: string;
    title?: string;
    subTitle?: string;
    image?: string;
    colors:any
}

const eventFiunction = () => {
    const element = document.getElementById('my-section');
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
}
const LandingAllianceBackgroundTitle: React.FC<LandingProps> = ({nameCompany="Rappi",
                                                                    title= "inter.mx quieren proteger tu salud",
                                                                    subTitle= "Por eso te damos Salud Protect de Regalo.",
                                                                    image="https://inter-rappi-prod.web.app/static/media/hero-desktop.5d97ea59feac78a31d77.png",
                                                                    colors
                                                                    }) => {
    const {breakpoints} = useTheme();
    const allianceSettingResponse = useSelector((state: any) => state.allianceSetting.response);
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    return (<>
        <Grid
            container
            direction={isMobile ? "row" : "column"}
            justifyContent="space-between"
            alignItems="center"
        >
            {isMobile ?
                <Grid item xs={12}>
                    <Box sx={{position: 'relative',}}>
                        <Box sx={{position: 'absolute',
                            display:'flex',flexDirection:'column' ,width:'100%',height:'100%'}}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                textAlign: 'center',
                                justifyContent: 'center',
                                gap: 2,
                                padding: '16px 16px 0 16px'
                            }}>
                                <CustomTypography
                                    style={{
                                        color: "var(--White, #FFF)",
                                        fontSize: "29px",
                                        textAlign: 'left',
                                        fontStyle: "normal",
                                        fontWeight: 900,
                                        lineHeight: "normal",
                                        letterSpacing: "-0.36px",
                                    }
                                    }
                                >
                                    {allianceSettingResponse?.data?.imagenes?.logo_white ? `${nameCompany} e ${title}` : title}</CustomTypography>
                                <CustomTypography
                                    style={{
                                        color: "var(--White, #FFF)",
                                        textAlign: 'left',
                                        fontSize: "19px",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        lineHeight: "normal",
                                        letterSpacing: "-0.36px",
                                    }
                                    }
                                >
                                    {subTitle}</CustomTypography>
                            </Box>

                            <Box sx={{display:'flex',width:'100%',height:"60%",justifyContent:'center',alignItems:'flex-end'}}>
                                <CustomButton
                                    text={"¡Quiero mi seguro!"}
                                    variant={"contained"}
                                    onClick={()=>eventFiunction()}
                                    style={{
                                        fontWeight: 'bold',
                                        borderRadius: 10,
                                        boxShadow: 'none',
                                        color: "#FFF",
                                        background: colors?.primary? colors?.primary : "#8F8F8F",
                                        fontSize: "1rem",
                                        textTransform: "uppercase",
                                        maxWidth: "580px",
                                        border: "none",
                                        justifyContent: "center",
                                        width: "60%",
                                        height: "48px",
                                    }}
                                />
                            </Box>


                        </Box>

                        <Box component={"img"} src={image} sx={{
                            height: 582,
                            width: '100%',
                            maxHeight: {xs: 582, md: 738, lg: 896},
                            maxWidth: {xs: '100%', md: '100%', lg: '100%'},
                        }}/>
                    </Box>
                </Grid>
                :
                <Grid item xs={12}>
                    <Box sx={{position: 'relative',display:'flex',flexDirection:'row'}}>
                        <Box sx={{position: 'absolute',width:{sm:'40%',lg:'40%'},padding:'50px'}}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                textAlign: 'center',
                                justifyContent: 'center',
                                gap: 2,

                            }}>
                                <CustomTypography
                                    style={{
                                        color: "var(--White, #FFF)",
                                        fontSize: "42px",
                                        textAlign: 'left',
                                        fontStyle: "normal",
                                        fontWeight: 900,
                                        lineHeight: "normal",
                                        letterSpacing: "-0.72px",
                                    }
                                    }
                                >
                                    {allianceSettingResponse?.data?.imagenes?.logo_white ? `${nameCompany} e ${title}` : title} </CustomTypography>
                                <CustomTypography
                                    style={{
                                        color: "var(--White, #FFF)",
                                        textAlign: 'left',
                                        fontSize: "24px",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        lineHeight: "normal",
                                        letterSpacing: "-0.36px",
                                    }
                                    }
                                >
                                    {subTitle}</CustomTypography>
                            </Box>
                            <CustomButton
                                text={"¡Quiero mi seguro!"}
                                variant={"contained"}
                                onClick={()=>eventFiunction()}
                                style={{
                                    marginTop:48,
                                    fontWeight: 'bold',
                                    borderRadius: 10,
                                    boxShadow: 'none',
                                    color: "#FFF",
                                    background: colors?.primary? colors?.primary : "#8F8F8F",
                                    fontSize: "1rem",
                                    textTransform: "uppercase",
                                    maxWidth: "580px",
                                    border: "none",
                                    justifyContent: "center",
                                    width: "60%",
                                    height: "48px",

                                }}
                            />
                        </Box>
                        <Box component={"img"} src={image} sx={{
                            height: 582,
                            width: '100%',
                            maxHeight: {xs: 582, md: 738, lg: 896},
                            maxWidth: {xs: '100%', md: '100%', lg: '100%'},
                        }}/>
                    </Box>
                </Grid>
            }
        </Grid>
    </>)
}
export default LandingAllianceBackgroundTitle;
