import React, { useEffect, useState } from "react";
import BasicModal from "../../../components/molecules/BasicModal/BasicModal";
import CardAction from "../../../components/organisms/Card";
import { useSelector } from 'react-redux';
import ManiCelebrating from '../../../assets/ManiCelebrateSuccessRegitration/maniCelebrating.svg';
import { IStorageMembershipData } from "../../../utils/localStorageKeys";
import { MembershipCategoryType, MembershipColor, MembershipType } from "../../../components/organisms/Membership/MembershipMainValues";
import { myColorPalette } from "../../../theme/themeGenerated";
interface IModalWelcome {
  closeNewUserModal?: () => void;
  type?: MembershipType;
  isNewUser?: boolean;
  planId?: string;
}

type CurrentValueProps = {
  type: string;
  category: string;
}

const ModalWelcomeToUser = ({ closeNewUserModal, type, isNewUser, planId }: IModalWelcome) => {
  const [open, setOpenModal] = useState<boolean>(false);
  const userResponse = useSelector((state: any) => state?.user?.response);
  const userData = JSON.parse(sessionStorage.getItem('user-storage')!);

  let currentValue: IStorageMembershipData = {
    ...useSelector((state: any) => state.productsCards.currentValue),
  };
  
  const [storage, setStorage] = useState<CurrentValueProps>({
    type: currentValue.type,
    category: currentValue.category
  });

  useEffect(() => {
    if(planId){
      const value = userResponse?.data?.plans.find((p: any) => p.plan === planId);
      
      if(value){
        setStorage({
          type: value.tier ? "premium" : "free",
          category: value.plan === "P01" ? "celular" : "salud"
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userResponse]);

  useEffect(() =>{
    if(isNewUser && !sessionStorage?.modalWelcomeToUser){
      setOpenModal(true);
    }
  }, []);
  const allianceSettingResponse = useSelector((state: any) => state.allianceSetting.response);
  let dataStorage: IStorageMembershipData = {
    ...useSelector((state: any) => state.productsCards.currentValue),
  };

  useEffect(() => {
    if (open) {
      window.dataLayer.push({
        event: "TRUS_primer_portal_v",
        tipo_seguro: storage.type === "free" ? "De regalo" : "Premium",
        correo: userResponse?.data?.user?.email,
        telefono: userResponse?.data?.user?.phone,
        page_location: window.location.href,
        seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
        poliza: userResponse?.data?.user?.certificate,
        alianza: dataStorage?.business ? dataStorage.business : allianceSettingResponse?.data?.id_bussiness,
      })
    }
  }, [open])

  return (  
    <BasicModal
      styleRoot={{
        maxWidth: 728,
        boxShadow: "none",
      }}
      styleContent={{
        margin: 0,
        padding: 0,
      }}
      disableBackdropClick={true}
      closeButton
      onClick={() => {
        // sessionStorage.setItem('newUser', 'true');
        sessionStorage.setItem('modalWelcomeToUser', "true");
        // setOpenModal(false);
        window.dataLayer.push({
          event: "TRUS_primer_portal",
          tipo_seguro: storage.type == "free" ? "De regalo" : "Premium",
          correo: userResponse?.data?.user?.email,
          telefono: userResponse?.data?.user?.phone,
          boton_contacto: "Cerrar",
          seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
          poliza: userResponse?.data?.user?.certificate,
          alianza: dataStorage?.business ? dataStorage.business : allianceSettingResponse?.data?.id_bussiness,
        })
        if (closeNewUserModal) {
          closeNewUserModal()
        }
      }}
      open={userResponse?.data?.user?.name && open}
      setOpenModal={setOpenModal}
      bodyDialog={<CardAction
        onClick={() => {
          sessionStorage.setItem('modalWelcomeToUser', "true");
          setOpenModal(false);
          if (closeNewUserModal) {
            closeNewUserModal()
          }
          // sessionStorage.setItem('newUser', 'true');
          window.dataLayer.push({
            event: "TRUS_primer_portal",
            tipo_seguro: storage.type == "free" ? "De regalo" : "Premium",
            correo: userResponse?.data?.user?.email,
            telefono: userResponse?.data?.user?.phone,
            boton_contacto: "Entendido",
            seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
            poliza: userResponse?.data?.user?.certificate,
            alianza: dataStorage?.business ? dataStorage.business : allianceSettingResponse?.data?.id_bussiness,
          })
        }}
        buttonText={"Entendido"}
        cardContent={
          planId ? 
            <span style={{
              fontSize: 20,
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal'
            }}>¡Listo <b style={{ textTransform: 'capitalize' }}>{userResponse?.data?.user?.name} {userResponse?.data?.user?.middle_name} {userResponse?.data?.user?.last_name}</b>! <br />
              Ya tienes {storage.category === 'celular' ? 'Celular' : 'Salud'} Protect {storage?.type === 'free' ? 'de Regalo' : 'Premium'}, continúa disfrutando de tus beneficios y nuevas coberturas.
              <br></br>
            </span>
            :
            <span style={{
              fontSize: 20,
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal'
            }}>¡Listo <b style={{ textTransform: 'capitalize' }}>{userResponse?.data?.user?.name} {userResponse?.data?.user?.middle_name} {userResponse?.data?.user?.last_name}</b>! <br />
              Ya tienes tu {storage.category === 'celular' ? 'Celular' : 'Salud'} Protect {storage?.type === 'free' ? 'de Regalo' : 'Premium'}, este es tu portal de cliente, aquí podrás consultar tu certificado y hacer uso de todos tus beneficios.
              <br></br>
            </span>  
        }
        imgSrc={ManiCelebrating}
        style={{
          backgroundColor: storage?.type === "free" ? userResponse?.data?.business?.id_bussiness === 'finsus' ? myColorPalette.getSecondaryColor() : MembershipColor.free : MembershipColor.premium,
          border: "1px solid " + storage?.type === "free" ? userResponse?.data?.business?.id_bussiness === 'finsus' ? myColorPalette.getSecondaryColor() : MembershipColor.free : MembershipColor.premium,
        }}
        styleTextContent={{
          fontSize: "17px",
        }}
        styleImg={{
          backgroundColor: `var(--azul-25, ${storage?.type === 'free' ? MembershipColor.freeOpacity : MembershipColor.premiumOpacity})`,
          padding: 0,
          display: "flex",
          flexWrap: "wrap",
          alignContent: "flex-end",
        }}
        styleImgCard={{
          padding: 0,
        }}
        styleButton={{
          color: storage?.type === "free" ? userResponse?.data?.business?.id_bussiness === 'finsus' ? '#fff' : MembershipColor.free : userResponse?.data?.business?.id_bussiness === 'finsus' ? '#fff' : MembershipColor.premium,
          backgroundColor:  userResponse?.data?.business?.id_bussiness === 'finsus' ? myColorPalette.getPrimaryColor() : "#fff",
          border: `1px solid ${userResponse?.data?.business?.id_bussiness === 'finsus' ? myColorPalette.getPrimaryColor() : "#fff"}`
        }}
        styleButtonSecondary={{
          color: storage?.type === "free" ? MembershipColor.free : MembershipColor.premium,
        }}
      />}
    />
  )
}

export default ModalWelcomeToUser;