import React from 'react';
import {ArrowBack, SaveAltOutlined} from "@mui/icons-material";
import {Container, Typography, Button, Box, Grid, useMediaQuery, useTheme} from '@mui/material';
import {CustomButton} from '../../atoms/Buttons/Buttons';
import {useNavigate} from "react-router-dom";
import {CustomTypography} from '../../atoms/Label/Label';
import {OutlinedButton, PrimaryButton} from '../../molecules/CellPhoneRepairButtons';
import styled from 'styled-components';
import ButtonInter from '../../atoms/Buttons/ButtonInter';
import {IStorageMembershipData} from '../../../utils/localStorageKeys';
import {useSelector} from 'react-redux'

const ContentStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`;
const AccidentalDeathComponent: React.FC = () => {
    const navigate = useNavigate();
    const {breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const requirementsOptions: string[] = [
        'Formato de Reclamación de siniestro',
        'Acta de defunción digitalizada',
        'Actas oficiales de nacimiento del titular y el beneficiario, en caso de que este último sea el cónyuge, también se necesita el acta de matrimonio',
        'Actuaciones ministeriales completas',
        'Identificación oficial del asegurado',
        'Identificación oficial vigente del beneficiario',
        'Estado de cuenta bancario del beneficiario con antigüedad no mayor a tres meses',
        'Certificado/consentimiento original con designación de beneficiario'
    ]
    let storage: IStorageMembershipData = {
        ...useSelector((state: any) => state.productsCards.currentValue),
    };

    const userData = JSON.parse(sessionStorage.getItem('user-storage')!);
    const userResponse = useSelector((state: any) => state.user.response);

    React.useEffect(() => {
        window.dataLayer.push({
            'event': 'TRUS_muerte_accidental_v',
            tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium",//premium | gratuito
            'correo': userResponse?.data?.user?.email,
            'telefono': userResponse?.data?.user?.phone,
            'page_location': window.location.href,
            seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
        });
    }, [])
    return (<>
            <Grid sx={{padding: isMobile ? "5px" : '0 50px 20px'}}>
                <Typography fontSize={24} fontWeight={700} textAlign={'center'} marginBottom={5}>
                    Muerte accidental
                </Typography>
                <Typography className='Info' fontSize={20} fontWeight={400} textAlign={'center'} style={{}}>
                    Para iniciar el trámite de reclamación de esta cobertura necesitas enviar un correo
                    a <br/><strong
                    style={{fontSize: isMobile ? '18px' : "20px"}}>chubb-siniestros.mx@chubb.com</strong><br/> con los
                    siguientes documentos:
                </Typography>
            </Grid>
            <div style={{
                background: '#F2FAFC',
                padding: '20px',
                display: 'flex',
                boxSizing: 'border-box'
            }}>
                <ul style={{
                    listStyle: 'initial',
                    padding: '0',
                    paddingLeft: '15px'
                }}>
                    {
                        requirementsOptions.map(require => {
                            return <li style={{
                                /*  margin: '10px',*/
                                textAlign: 'start',
                                margin: '15px 0',
                                fontSize: '20px'
                            }}>
                                {require}.
                            </li>
                        })
                    }
                </ul>
            </div>
        <div className='AccidentalButtons'>
            <ContentStyled style={{
                justifyContent: 'center',
                marginTop: '20px'
            }}>
                <ButtonInter variant='text' onClick={() => {
                    window.dataLayer.push({
                        event: 'TRUS_formato_muerte_accidental',
                        tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium",//premium | gratuito
                        correo: userResponse?.data?.user?.email,
                        telefono: userResponse?.data?.user?.phone,
                        seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
                    })
                    window.open('https://storage.googleapis.com/inter-public-cloudstorage-prod/tr1s/documentos/SaludProtect/Formato_Reclamacion_Fallecimiento.pdf', "_blank")
                }}>
                    Formato de reclamacion <SaveAltOutlined style={{marginLeft: '5px', marginRight: '5px'}}/>
                </ButtonInter>
                <ButtonInter variant='contained' onClick={() => {
                    window.dataLayer.push({
                        event: 'TRUS_muerte_accidental',
                        tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium",//premium | gratuito
                        correo: userResponse?.data?.user?.email,
                        telefono: userResponse?.data?.user?.phone,
                        boton_contacto: "Entiendo",
                        seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
                    })
                    navigate(-1);
                }}>
                    Entendido
                </ButtonInter>
            </ContentStyled>

        </div>

    </>)
}

export default AccidentalDeathComponent;