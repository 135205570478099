import React from 'react';
import { CustomTypography } from '../../../atoms/Label/Label';
import { useMediaQuery } from '@mui/material';
import { myColorPalette } from '../../../../theme/themeGenerated';

type FormSteppersProps = {
    title: string[];
    activeStep: number;
    steps: string[];
    activeDot?: Array<any>;
}

const FormSteppers: React.FC<FormSteppersProps> = ({
    title,
    activeStep,
    steps,
    activeDot
}) => {
    const matches = useMediaQuery('(min-width:600px)');
    const active = activeDot ? activeDot?.indexOf(activeStep) : activeStep;

    return <React.Fragment>
        {title[active] ? <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            maxWidth: 558,
            gap: '1rem'
        }}>
            <CustomTypography 
                item={title[active][0].toUpperCase() + title[active].slice(1)} 
                style={{ 
                    display: 'block',
                    fontSize: !matches ? 16 : 28,
                    textAlign: 'center', 
                    fontWaight: 700,
                    fontStyle: 'normal',
                    lineHeight: 'normal', 
                    alignSelf: 'stretch',
                    // padding: !matches ? '0 1rem' : "",
                    // textWrap: !matches ? 'balance' : ""
                }} 
            />
            <label>{steps[active]}</label>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                gap: 5
            }}>
                {steps[active] && steps.map((label: string, key: number) => (
                    <span key={label} style={{borderRadius: 8, background: active >= key ? myColorPalette.getStepperColor() : 'var(--black-25, #C7C7C7)', padding: 4, flexGrow: 1}}></span>
                ))}
            </div>
        </div> : null}
    </React.Fragment>
}

export default FormSteppers;