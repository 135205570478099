import React from 'react';
import InterFAQsTemplate from '../../components/templates/InterFAQs';
import CustomContainer from '../../components/organisms/Container/Container';

const InterFAQs: React.FC = () => {
  // Implementa el componente aquí
  return (
    <React.Fragment>
        <CustomContainer childComponent={ <InterFAQsTemplate />} />
    </React.Fragment>

  );
};

export default InterFAQs;
 