import React, { useState } from "react";
import { styled } from "styled-components";
import { ErrorMessage, Field, useFormikContext } from "formik";
import { ExpandMore, Info, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Slider
} from "@mui/material";
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { onlyNumbers, onlyNumbersandCharacters } from "../../../utils/validationInput";
import "./formErrorHandler.css";
import { IconButton, InputAdornment } from "@material-ui/core";
import { myColorPalette } from "../../../theme/themeGenerated";

const InputWrapper = styled.span`
  width: 100%;
`;

type FormItemGenerateProps = {
  items: any;
  handleChange: any;
  handleBlur: any;
};

const FormItemGenerate: React.FC<FormItemGenerateProps> = ({
  items,
  handleChange,
  handleBlur,
}) => {
  const formik = useFormikContext();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [sliderValue, setSliderValue] = useState<number | number[] | null>(null);

  const handleInputPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {items.map(
        ({
          id,
          name,
          label,
          type,
          value,
          option,
          limit,
          error,
          meta,
          xs,
          event,
          loading,
          autofocus,
          disabled = false,
          iconAdornment,
          styled,
          required = true
        }: any) => {
          switch (type) {
            case "password":
              return (<Grid item xs={xs || 12} key={id} style={{ display: styled ? 'none' : '' }}>
                <InputWrapper>
                  {<Field
                    autoFocus={autofocus && autofocus}
                    name={name}
                    type={showPassword ? 'text' : 'password'}
                    as={TextField}
                    label={label}
                    variant={"standard"}
                    {...error}
                    className={`${meta?.touched && meta?.error && "error-message"}`}
                    onChange={(ev: any) => {
                      if (name === "phone") {
                        const number = onlyNumbers(ev.target.value);
                        if (number && ev.target.value.length < 11)
                          handleChange(ev);
                      } else {
                        handleChange(ev);
                      }
                    }}
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end" style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
                          {meta?.touched && meta?.error ?
                            <Info style={{ color: 'red' }} />
                            :
                            <IconButton
                              style={{ padding: 0 }}
                              aria-label="toggle password visibility"
                              onClick={handleInputPassword}
                              onMouseDown={() => { }}>
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          }
                        </InputAdornment>

                    }}
                    sx={{
                      '&.MuiFormControl-root > .MuiFormLabel-root.Mui-focused': {
                        color: window.location.pathname.includes('finsus') ? myColorPalette.getSecondaryColor() : myColorPalette.getPrimaryColor()
                      },
                      '&.MuiFormControl-root > .MuiInput-root.Mui-focused::after': {
                        borderBottom: `1px solid ${window.location.pathname.includes('finsus') ? myColorPalette.getSecondaryColor() : myColorPalette.getPrimaryColor()}`
                      },
                      '&.error-message > .MuiInput-root::before': {
                        borderBottom: `1px solid #f44336`
                      },
                    }}
                    onBlur={handleBlur}
                    helperText={meta?.touched && meta?.error}
                    required
                    fullWidth
                  />
                  }
                </InputWrapper>
              </Grid>)
            case "select":
              return (
                <Grid item xs={xs || 12} key={id} style={{ display: styled ? 'none' : '' }}>
                  <InputWrapper>
                    <FormControl fullWidth>
                      <InputLabel sx={{
                        left: '-14px',
                        color: (meta?.touched && meta?.error) ? 'red' : window.location.pathname.includes('finsus') ? myColorPalette.getSecondaryColor() : myColorPalette.getPrimaryColor(),
                        '&.Mui-focused': {
                          color: window.location.pathname.includes('finsus') ? myColorPalette.getSecondaryColor() : myColorPalette.getPrimaryColor()
                        }
                      }}>{label}</InputLabel>
                      <Select
                        defaultValue={""}
                        value={meta?.value || ""}
                        className={`${meta?.touched && meta?.error && "error-message"}`}
                        variant="standard"
                        name={name}
                        label={label}
                        style={{ borderRadius: "8px" }}
                        sx={{
                          '&:after': {
                            borderBottom: `2px solid ${window.location.pathname.includes('finsus') ? myColorPalette.getSecondaryColor() : myColorPalette.getPrimaryColor()}`
                          },
                          '&.error-message::before': {
                            borderBottom: '1px solid #f44336'
                          }
                        }}
                        onChange={(ev) => {
                          handleChange(ev);
                          event(ev.target.value);
                          switch (name) {
                            case "marca":
                              // formik.setFieldValue('id_brand ', );

                              break;
                            case "modelo":
                              // formik.setFieldValue('id_brand ', );

                              break;
                            case "descripcion":
                              // formik.setFieldValue('id_brand ', );
                              break;
                            default:
                              handleChange(ev);
                          }
                        }}
                        onBlur={handleBlur}
                        IconComponent={(props) => (
                          !loading ? <ExpandMore style={{ color: window.location.pathname.includes('finsus') ? myColorPalette.getSecondaryColor() : myColorPalette.getPrimaryColor() }} {...props} />
                            :
                            <CircularProgress style={{ width: 20, height: 20, color: window.location.pathname.includes('finsus') ? myColorPalette.getSecondaryColor() : myColorPalette.getPrimaryColor() }} />
                        )}
                      >
                        {option.map((value: string, i: number) => (
                          <MenuItem key={i} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                      <ErrorMessage
                        render={msg => {
                          return <small style={{ fontSize: '.75rem' }} className="error-message">{msg}</small>
                        }}
                        name={name}
                        component="small"
                      />
                    </FormControl>
                  </InputWrapper>
                </Grid>
              );
            case "date":
              return (
                <React.Fragment key={id}>
                  <Grid item xs={xs || 12} style={{ display: styled ? 'none' : '' }}>
                    <InputWrapper>
                      <Field
                        name={name}
                        as={TextField}
                        type={type}
                        label={label}
                        helperText={meta?.touched && meta?.error}
                        className={`${meta?.touched && meta?.error && "error-message"
                          }`}
                        InputLabelProps={{ shrink: type ? true : false }}
                        variant={"standard"}
                        onChange={(ev: any) => {
                          const date = ev.target.value;
                          const regex = /^\d{4}-\d{2}-\d{2}$/;
                          if (regex.test(date)) formik.setFieldValue(name, date);
                        }}

                        sx={{
                          '&.MuiFormControl-root > .MuiFormLabel-root.Mui-focused': {
                            color: window.location.pathname.includes('finsus') ? myColorPalette.getSecondaryColor() : myColorPalette.getPrimaryColor()
                          },
                          '&.MuiFormControl-root > .Mui-focused::after': {
                            borderBottom: `1px solid ${window.location.pathname.includes('finsus') ? myColorPalette.getSecondaryColor() : myColorPalette.getPrimaryColor()}`
                          },
                          '&.error-message > .MuiInput-root::before': {
                            borderBottom: `1px solid #f44336`
                          },
                        }}
                        onBlur={handleBlur}
                        max={limit.MaxAgeAcept}
                        min={limit.MinAgeAcept}
                        disabled={disabled}
                        fullWidth
                      />
                    </InputWrapper>
                  </Grid>
                </React.Fragment>
              );
            case "text_area":
              return (
                <React.Fragment key={id}>
                  <Grid item xs={xs || 12} style={{ display: styled ? 'none' : '' }}>
                    <InputLabel sx={{ fontSize: '13px', fontWeight: 400, color: meta?.touched && meta?.error && 'red' }}>{label}</InputLabel>
                    <InputWrapper>
                      <Field
                        style={{
                          border: 'none',
                          borderBottom: '1.5px solid #000',
                          width: '100%',
                          font: 'inherit',
                          letterSpacing: 'inherit',
                          padding: '4px 0 5px',
                          boxSizing: 'content-box',
                          background: 'none',
                          height: '1.4375em',
                          margin: 0,
                          display: 'block',
                          minWidth: 0,
                          animationName: 'mui-auto-fill-cancel',
                          animationDuration: '10ms',
                          outlined: 'none'
                        }}
                        sx={{
                          border: 'none',
                          borderBottom: '2px solid green',
                          borderRadius: 0,
                          '&:hover': {
                            borderColor: 'green'
                          },
                          '&::before': {
                            transform: 'scaleX(0)',
                            left: 0,
                            right: 0,
                            bottom: '-2px',
                            top: 'unset',
                            transition: 'transform .15s cubic-bezier(0.1,0.9,0.2,1)',
                            borderRadius: 0,
                          },
                          '&:focus-within::before': {
                            transform: 'scaleX(1)',
                          },
                        }}
                        // className={meta?.touched && meta?.error && 'error-message'}
                        name={name}
                        as={BaseTextareaAutosize}
                        type={type}
                        variant="soft"
                        {...error}
                        maxRows={3}
                        label={label}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small style={{ fontSize: '.75rem' }} className="error-message">
                        {meta?.touched && meta?.error && meta?.error}
                      </small>
                    </InputWrapper>
                  </Grid>
                </React.Fragment>
              )
            case "select_button":
              return (
                <>
                  <Grid
                    item xs={xs || 12} key={id} style={{ display: styled ? 'none' : '' }}>
                    <InputLabel sx={{ fontSize: '13px', marginBottom: '12px', fontWeight: 400, color: meta?.error && 'red' }}>{label}</InputLabel>
                    <InputWrapper style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', background: window.location.pathname.includes('finsus') ? 'rgba(255, 95, 84, 0.05)' : 'rgb(244, 244, 244)' }}>
                      {option.map((value: string, i: number) => {
                        const selected = meta?.value || "";
                        const style: React.CSSProperties = {
                          fontWeight: 600,
                          borderRadius: '8px',
                          borderStyle: 'none',
                          borderWidth: '0px',
                          textTransform: 'none',
                          color: 'rgb(66, 66, 66)',
                          backgroundColor: 'transparent',
                          width: '100%',
                          padding: '10px'
                        }

                        const selectedStyle: React.CSSProperties = {
                          backgroundColor: window.location.pathname.includes('finsus') ? myColorPalette.getPrimaryColor() : 'rgb(245, 251, 253)',
                          color: window.location.pathname.includes('finsus') ? '#FFFFFF' : 'rgb(3, 158, 204)'
                        }

                        return <Button disabled={disabled} name={name} key={value.toLowerCase() + '_' + i} style={value === selected ? { ...style, ...selectedStyle } : { ...style }} onClick={() => {
                          event(value);
                          formik.setFieldValue("gender", value)
                        }}>{value}</Button>
                      })
                      }
                      {/* <Field
                        name={name}
                        as={TextField}
                        type="button"
                        {...error}
                        className={`${meta?.touched && meta?.error && "error-message"
                          }`}
                        label={label}
                        onChange={(ev: any) => {
                          if (name === "phone") {
                            const number = onlyNumbers(ev.target.value);
                            if (number && number.length < 11)
                              handleChange(ev);
                          } else {
                            handleChange(ev);
                          }
                        }}
                        onBlur={handleBlur}
                        variant={"standard"}
                        helperText={meta?.touched && meta?.error}
                        required
                        fullWidth
                      /> */}

                    </InputWrapper>
                    <small style={{ fontSize: '.75rem' }} className="error-message">
                      {meta?.error && meta?.error}
                    </small>
                  </Grid>
                </>)
            case "slider":
              return (
                <Grid item xs={xs || 12} key={id} style={{ display: styled ? 'none' : '' }}>
                  <p style={{ color: 'rgba(143, 143, 143, .5)', margin: 0 }}>{label}</p>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Slider
                      name={name}
                      style={{ width: '100%' }}
                      min={limit.min}
                      max={limit.max}
                      value={value}
                      disabled={disabled}
                      valueLabelDisplay="auto"
                      onChange={(event, val) => {
                        handleChange(event)
                        setSliderValue(val)
                      }}
                    /> <div style={{ color: 'rgba(33, 33, 33, 0.5)', fontSize: '16px', fontWeight: 700, marginLeft: '24px' }}>{`${sliderValue !== null ? sliderValue : value || 0}%`}</div>
                  </div>
                  {meta.error && <p style={{ color: 'red', margin: 0, fontSize: '0.75rem' }}>{meta.error}</p>}
                </Grid>
              )
            default:
              return (
                <React.Fragment key={id}>
                  <Grid item xs={xs || 12} style={{ display: styled ? 'none' : '' }}>
                    <InputWrapper>
                      <Field
                        autoFocus={autofocus && autofocus}
                        name={name}
                        as={TextField}
                        type={type}
                        {...error}
                        className={`${meta?.touched && meta?.error && "error-message"
                          }`}
                        label={label}
                        onChange={(ev: any) => {
                          const value = ev.target.value;
                          const onlyLetters = /^[a-zA-ZñÑáéíóúüÜ\p{L}]+(?: [a-zA-ZñÑáéíóúüÜ\p{L}]*)*$/u;

                          switch (name) {
                            case "phone":
                              const number = onlyNumbers(value);
                              if (number && value.length < 11)
                                handleChange(ev);
                              break;
                            case "number":
                              const isNumber = onlyNumbers(value);
                              if (isNumber)
                                handleChange(ev);
                              break;
                            case "imei":
                              const num = value.replace(/[^0-9]/g, '');
                              const isRegected = onlyNumbersandCharacters(num);
                              if (isRegected && num.length < 16)
                                formik.setFieldValue('imei', num);
                              // handleChange(ev);
                              break;
                            case "email":
                              formik.setFieldValue('email', value.toLowerCase());
                              break;
                            case "zipcode":
                              const zip = value.replace(/[^0-9]/g, '');
                              if (/^\d{0,5}$/.test(zip)) formik.setFieldValue('zipcode', zip);
                              // handleChange(ev);
                              break;
                            case "porcentage":
                              const porcentage = value.replace(/[^0-9]/g, '');
                              if (/^(100|[1-9][0-9]?|)$/.test(porcentage)) formik.setFieldValue('porcentage', porcentage);
                              // handleChange(ev);
                              break;
                            case "name":
                              if (onlyLetters.test(value) || value === "") formik.setFieldValue('name', value);
                              break;
                            case "middle_name":
                              if (onlyLetters.test(value) || value === "") formik.setFieldValue('middle_name', value);
                              break;
                            case "last_name":
                              if (onlyLetters.test(value) || value === "") formik.setFieldValue('last_name', value);
                              break;
                            default:
                              handleChange(ev);
                          }
                        }}
                        onBlur={handleBlur}
                        variant={"standard"}
                        helperText={meta?.touched && meta?.error}
                        required={required}
                        fullWidth
                        disabled={disabled}
                        sx={{
                          '&.MuiFormControl-root > .MuiFormLabel-root.Mui-focused': {
                            color: window.location.pathname.includes('finsus') ? myColorPalette.getSecondaryColor() : myColorPalette.getPrimaryColor()
                          },
                          '&.MuiFormControl-root > .MuiInput-root.Mui-focused::after': {
                            borderBottom: `1px solid ${window.location.pathname.includes('finsus') ? myColorPalette.getSecondaryColor() : myColorPalette.getPrimaryColor()}`
                          },
                          '&.error-message > .MuiInput-root::before': {
                            borderBottom: `1px solid #f44336`
                          },
                        }}
                        InputProps={{
                          endAdornment:
                            <InputAdornment position="end" >
                              {iconAdornment && meta?.touched && meta?.error ? <></> : iconAdornment}
                              {meta?.touched && meta?.error && <Info style={{ color: 'red' }} />}
                            </InputAdornment>

                        }}
                      />
                    </InputWrapper>
                  </Grid>
                </React.Fragment>
              );
          }
        }
      )}
    </Grid>
  );
};

export default FormItemGenerate;
