import React from 'react';
/*import ImgItem1 from '../../../assets/HowitworksAssets/ImgItem1';
import ImgItem2 from '../../../assets/HowitworksAssets/ImgItem2';
import ImgItem3 from '../../../assets/HowitworksAssets/ImgItem3';*/
import Secure from '../../../assets/HowitworksAssets/secure.svg';
import { styled } from 'styled-components';
import {
    Box,
    Grid, Typography
} from '@mui/material';
import CardWrapper from '../../molecules/CardWrapper';
import { ReactComponent } from "*.svg";
import Stack from "@mui/material/Stack";

const TitleElement = styled.span`
  color: var(--black, #212121);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const SubTitleElement = styled.span`
  color: var(--black, #212121);
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const CenterElement = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 80px;
  padding-bottom: 80px;
`;


const ContentComponent: React.FC = () => {

    const DESCRIPTION_1 = (description: any) => {

        return <div>
            <Typography
                fontSize={20}
                dangerouslySetInnerHTML={{ __html: description }}
            />
        </div>
    }


    return (
        <CenterElement>
            <TitleElement>¿Cómo Funciona?</TitleElement>
            <SubTitleElement>Te lo explicamos en 3 simples pasos</SubTitleElement>
            <Box sx={{ flexGrow: 1, width: '100%', display: 'flex', marginTop: 2 }}>
                <Stack direction={{ sx: 'column', sm: 'row', md: 'row' }} spacing={0}
                    justifyContent="center"
                    alignItems="center" >
                    <Grid container spacing={0} sx={{
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                    }}>
                        <Grid item xs={12} sm={12} md={12} sx={{
                            justifyContent: 'flex-end',
                            display: 'flex',

                        }}>
                            <CardWrapper imgItem={''} value={1}
                                description={'Eliges tu seguro y te registras con nosotros.'} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx={{
                            justifyContent: 'flex-end',
                            display: 'flex'
                        }}>
                            <CardWrapper imgItem={''} value={2}
                                description={'Obtienes acceso a tus coberturas y plan de descuentos.'} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx={{
                            justifyContent: 'flex-end',
                            display: 'flex'
                        }}>
                            <CardWrapper imgItem={''} value={3}
                                descriptionBold={DESCRIPTION_1('¡Listo! Ya estás protegido con el respaldo de  <b>inter.mx.</b>')} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{
                        width: '100%',
                        justifyContent: { xs: 'center', sm: 'flex-start' },
                        alignItems: 'center',
                        display: 'flex'
                    }}>
                        <Box component={'img'} src={Secure} sx={
                            {
                                marginLeft: { xs: '0px', sm: '50px' }
                            }
                        } alt={'Secure'} />


                    </Grid>
                </Stack>
            </Box>
        </CenterElement>
    )
};

export default ContentComponent;
