import React from 'react';
import TrackingStatusComponent from '../../components/templates/TrackingStatus';
import TrackingStatusComponentCheckUpMedico from "../../components/templates/TrackingStatusCheckUpMedico";

const TrackingStatusCheckUpMedico: React.FC = () => {
    return (
        <React.Fragment>
            <TrackingStatusComponentCheckUpMedico />
        </React.Fragment>

    );
};

export default TrackingStatusCheckUpMedico;
