import React, {useEffect} from 'react';
import CustomContainer from '../../organisms/Container/Container';
import {styled} from 'styled-components';
import {CustomTypography} from '../../atoms/Label/Label';
import {Box, Typography} from '@mui/material';
import ManiBody from "../../../assets/ManiPosesBody";
import {PrimaryButton} from '../../molecules/CellPhoneRepairButtons';
import {useNavigate} from "react-router-dom";
import {
    validate_status,
    TimeLineTrackingStatusCheckUpMedico, validateStatusExist
} from "../../organisms/TimeLineTrackingStatusCheckUpMedico";
import {CustomButton} from "../../atoms/Buttons/Buttons";
import {ArrowBack} from '@mui/icons-material';
import moment from "moment";
import {useSelector} from "react-redux";
import { IStorageMembershipData } from '../../../utils/localStorageKeys';

const ComponentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto;
    padding-top: 40px;
    padding-bottom: 85px;
    gap: 40px;
    align-items: center;
`;

const TrackingStatusComponentCheckUpMedico: React.FC = () => {
    const navigate = useNavigate();
    let storage: IStorageMembershipData = {
        ...useSelector((state: any) => state.productsCards.currentValue),
      };
    const userResponse = useSelector((state: any) => state?.user?.response);

    useEffect(() => {
        window.dataLayer.push({
            event: 'TRUS_solicitud_check_medico_v',
            tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium",
            correo: userResponse?.data?.user?.email,
            seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
            telefono: userResponse?.data?.user?.phone,
            page_location: window.location.href,
          });
    }, [])

    const assignment = useSelector((state: any) => state.assignment.response);

    useEffect(() => {
        if (!assignment) {
            navigate("/portal-del-cliente")
        }else {
            validateStatusExist(assignment?.data?.status,navigate)
        }
    }, []);

    const getDays = (init: string = "", end: string = "") => {
        let result_days: any = "7"

        /*if (init && end) {
            let today, init_, newDate;
            today = new Date();
            init_ = new Date(init);


            /!*CONVIERTO LAS FECHAS*!/
            today.setHours(0, 0, 0, 0);
            init_.setHours(0, 0, 0, 0)

            if (init_ >= today) {
                newDate = init;
            } else {
                newDate = today;
            }

            let fecha1 = moment(newDate, "YYYY-MM-DD");
            let fecha2 = moment(end, "YYYY-MM-DD");


            result_days = fecha2.diff(fecha1, 'days')
        }*/


        return result_days
    }

    const DESCRIPTION = (description: any) => {

        return <div>
            <Typography
                style={{
                    color: "var(--Black, #212121)",
                    fontSize: "20px",
                    textAlign: 'center',
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                }
                }
                dangerouslySetInnerHTML={{__html: description}}
            />
        </div>
    }

    return (
        <React.Fragment>
            <CustomContainer childComponent={<ComponentWrapper>
                <div style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    width: '100%'
                }}>
                    <CustomButton
                        text={"Regresar"}
                        onClick={() => navigate(-1)}
                        size={"medium"}
                        startIcon={<ArrowBack/>}
                        variant={"contained"}
                        style={{
                            color: "var(--Azul-Inter, #039ECC)",
                            backgroundColor: "transparent",
                            fontSize: "1rem",
                            textTransform: "none",
                            fontWeight: "600",
                            fontStyle: "normal",
                            padding: "8px 10px",
                            minWidth: "64px",
                            border: "none",
                            justifyContent: "flex-start",
                            width: "fit-content",
                            lineHeight: 'normal'
                        }}
                    />
                </div>
                <div style={{
                    display: 'flex', flexDirection: 'column',
                    width: '100%',
                    gap: 24, alignItems: 'center'
                }}>
                    <CustomTypography
                        item={"Aquí podrás consultar el estatus de tu solicitud de check-up"}
                        style={{
                            maxWidth: 528,
                            fontSize: 24,
                            textAlign: 'center',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            textTransform: 'inherit',
                            letterSpacing: '0.5px',
                            textWrap: 'balance'
                        }}
                    />
                    <div
                        style={{
                            maxWidth: 428,
                            width: 'auto',
                            height: 'auto'
                        }}
                    >
                        <ManiBody/>
                    </div>
                    <Box sx={{
                        maxWidth: 428,
                    }}>
                        <TimeLineTrackingStatusCheckUpMedico
                            data={validate_status(assignment?.data?.status, getDays(
                                assignment?.data?.created_at,
                                assignment?.data?.expiration_date
                            ))}/>


                        {
                            assignment?.data?.status === "Issued" &&
                          <Box sx={{background: '#F2FAFC', padding:1,gap: '10px',marginBottom:3}}>
                              {DESCRIPTION(' <b>¡Importante!</b> Recuerda que si no lo realizas a tiempo, podrías perder este beneficio.')}
                          </Box>
                        }

                    </Box>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        maxWidth: 428,
                        gap: 16
                    }}>
                        <PrimaryButton text={"Entendido"} onClick={() => {
                            window.dataLayer.push({
                                event: 'TRUS_solicitud_check_medico',
                                tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium",
                                correo: userResponse?.data?.user?.email,
                                seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
                                telefono: userResponse?.data?.user?.phone,
                                boton_contacto: "Entendido",
                                });

                            navigate("/portal-del-cliente")
                        }}/>

                    </div>
                </div>
            </ComponentWrapper>}/>
        </React.Fragment>
    );
};

export default TrackingStatusComponentCheckUpMedico;
