import {useState, useEffect} from 'react'
import { CustomTypography } from '../../../components/atoms/Label/Label'
import { Button } from '@mui/material'
import { CustomButton } from '../../../components/atoms/Buttons/Buttons'
import { useSelector, useDispatch } from 'react-redux'
import { Input, FormControl } from '@mui/material';
import Icon from '../AddAddres'
import Edit from '../DeviceIcon'
import moment from 'moment'
import ButtonInter from '../../../components/atoms/Buttons/ButtonInter'
import DeviceForm from '../forms/DeviceForm'
import { InterInstance, addAccessToken } from '../../../api/config'
import RenderAlert from '../../../components/organisms/Modal'
import LoadingComponent from '../../../components/organisms/loading/loading'
import { useNavigate } from 'react-router-dom'
import { setCurrentValue } from '../../../api/productsSlice'

interface InfoInterface {
    title: string;
    isPremium: boolean;
}

const CelularInfo = ({title, isPremium}: InfoInterface) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userResponse = useSelector((state: any) => state?.user?.response);
  const [userInfo, setUserInfo] = useState<any>({});
  const [nextYear, setNextYear] = useState<any>();
  const [deviceInfo, setDeviceInfo] = useState<any>({});
  const [imei, setImei] = useState('');
  //Booleans
  const [editImei, setEditImei] = useState<boolean>(false);
  const [editDevice, setEditDevice] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const { data } = userResponse
    if(data){
        const { user } = data
        if(user){
          setUserInfo(user)
          let date = new Date(`${user?.created_at}`)
          if(date){
              date.setFullYear(date.getFullYear() + 1)
              const tmpDate = moment(date).format('DD-MM-YYYY')
              setNextYear(tmpDate)
          }
        }
    }
  },[])

  useEffect(()=>{
    const { data } = userResponse
    if(data){
        const { gadgets } = data
        if(gadgets){
            setEditDevice(false)
            setDeviceInfo(gadgets)
            setImei(gadgets?.imei)
        }
    }
  },[userResponse?.data?.gadgets])

  const handleChange = (event: any) => {
    setImei(event.target.value)
  }

  const handleSubmit = async () => {
    if(imei?.length < 15){
        return RenderAlert({ severity: "warning", message: "El minimo de caracteres es 15" })
    }
    try {
        setIsLoading(true)
        await addAccessToken()
        const { data } = await InterInstance.post(`/gadgets`,
            {
                event: "update-imei",
                content: {
                    imei,
                    tracking_id: userInfo?.tracking_id
                }
            }
        )
        if (data?.data?.error) {
            return RenderAlert({ severity: "warning", message: "Ocurrio un error inesperado" })
        }else{
            setEditImei(false)
            return RenderAlert({ severity: "success", message: "Tu imei ha sido actualizado" })
        }
    } catch (error) {
        RenderAlert({ severity: "warning", message: "Ocurrio un error inesperado" })
        console.log(error)
    } finally {
        setIsLoading(false)
    }
  }

  const upgradePlan = () => {
    const sendDataLocalStorage = {
        type: "premium",
        subtype: "premium",
        category: "celular",
        cardTitle: "Seguro Inter Celular",
        benefit_id: "C01",
        plan_id: "P01"
    };
    dispatch(setCurrentValue(sendDataLocalStorage));
    navigate('/resumen-membresia', {
        state: {
            path: 'portal-del-cliente',
            tabHidden: "freemium"
        }
    })
  }

  return (
    <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            gap: '24px',
            marginBottom: "24px"
        }}
    >
        {
            isLoading ?
                <LoadingComponent isLoaded={!isLoading} />
                :
                <>
                    <div>
                    <CustomTypography
                        style={{
                            color: "var(--Black, #212121)",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            textAlign: "center",
                            height: "24px"
                        }}
                    >
                        <span>Titular</span>
                    </CustomTypography>
                    <CustomTypography
                        style={{
                            color: "var(--Black, #212121)",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            textAlign: "center",
                        }}
                    >
                        <span> <b>{`${userInfo?.name} ${userInfo?.middle_name} ${userInfo?.last_name}`}</b> </span>
                    </CustomTypography>
                    </div>

                    <div>
                    <CustomTypography
                        style={{
                            color: "var(--Black, #212121)",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            textAlign: "center",
                            height: "24px"
                        }}
                    >
                        <span>Vigencia</span>
                    </CustomTypography>
                    <CustomTypography
                        style={{
                            color: "var(--Black, #212121)",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            textAlign: "center",
                        }}
                    >
                        <span> <b>{nextYear}</b> </span>
                    </CustomTypography>
                    </div>

                    <div style={{width: "100%"}}>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}
                        >
                            <CustomTypography
                                style={{
                                    color: "var(--Black, #212121)",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "normal",
                                    textAlign: "center",
                                    height: "32px",
                                    alignItems: "center"
                                }}
                            >
                                <span>Dispositivo asegurado</span>
                            </CustomTypography>

                            <Button 
                                variant='text' 
                                startIcon={<Edit />}
                                onClick={() => setEditDevice(true)}
                            />
                        </div>

                        { editDevice 
                            ? <div>
                                <DeviceForm />
                            </div> 
                            :<div>
                                <CustomTypography
                                    style={{
                                        color: "var(--Black, #212121)",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "normal",
                                        textAlign: "center",
                                    }}
                                >
                                    <span><b>{deviceInfo?.id_brand}</b></span>
                                </CustomTypography>
                                
                                <CustomTypography
                                    style={{
                                        color: "var(--Black, #212121)",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "normal",
                                        textAlign: "center",
                                    }}
                                >
                                    <span><b>{deviceInfo?.id_line}</b></span>
                                </CustomTypography>

                                <CustomTypography
                                    style={{
                                        color: "var(--Black, #212121)",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "normal",
                                        textAlign: "center",
                                    }}
                                >
                                    <span><b>{deviceInfo?.id_model}</b></span>
                                </CustomTypography>
                            </div>
                        }
                    </div>

                    <div style={{width: "100%"}} >
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}
                        >
                            <CustomTypography
                                style={{
                                    color: "var(--Black, #212121)",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "normal",
                                    textAlign: "center",
                                    height: "36px",
                                    alignItems: "center"
                                }}
                            >
                                <span>IMEI</span>
                            </CustomTypography>
                            {imei 
                                ? <Button 
                                    variant='text' 
                                    startIcon={<Edit />}
                                    onClick={() => setEditImei(true)}
                                />
                                : <div>
                                    <Button 
                                        variant='text' 
                                        startIcon={<Icon />}
                                        onClick={() => setEditImei(true)}
                                    >Agregar</Button>
                                </div>
                            }
                        </div>

                        <div>
                            {imei && !editImei && <CustomTypography
                                style={{
                                    color: "var(--Black, #212121)",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "normal",
                                    textAlign: "center",
                                }}
                            >
                                <span> <b>{imei}</b> </span>
                            </CustomTypography>}

                            {editImei && <div>
                                <FormControl fullWidth={true} style={{marginBottom: "24px"}}>
                                    <Input
                                        id="imei"
                                        name="imei"
                                        onChange={(e) => {
                                            if (e.target.value.length > 15)
                                                return
                                            handleChange(e);
                                        }}
                                        type="text"
                                        placeholder='IMEI'
                                        value={imei}
                                        // color={errors.imei && touched.imei ? 'error' : touched.imei ? 'success' : 'primary'} 
                                    />
                                    {/* {errors.imei && touched.imei ? <div><small style={{ color: 'red' }}>IMEI inválido</small></div> : ''} */}
                                </FormControl>
                                <ButtonInter onClick={handleSubmit} variant='contained' >
                                    Guardar
                                </ButtonInter>
                            </div>}
                            
                        </div>
                    </div>

                    { !isPremium && <div style={{width: "100%"}}>
                    <CustomButton
                        text={"Quiero ser Premium"}
                        variant={"contained"}
                        onClick={upgradePlan}
                        style={{
                            fontWeight: 'bold',
                            borderRadius: 10,
                            boxShadow: 'none',
                            color: "white",
                            fontSize: "1rem",
                            textTransform: "uppercase",
                            maxWidth: "580px",
                            justifyContent: "center",
                            width: "100%",
                            background: '#E6B331',
                        }}
                    />
                    </div>}
                </>
        }

    </div>
  )
}

export default CelularInfo
