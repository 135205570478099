import Typography from "@mui/material/Typography";

const DESCRIPTION_ALLIANCE_UNO =
    <div>
        <Typography
            fontFamily={'Montserrat'}
            fontSize={20}
            fontStyle={'normal'}
            fontWeight={'400'}
            color={'#212121'}
            align={'center'}>
            En alianza con <b>inter.mx</b> lanzamos un seguro que protege tu salud.
        </Typography>
    </div>

const DESCRIPTION_ALLIANCE_DOS =
    <div>
        <Typography
            fontFamily={'Montserrat'}
            fontSize={20}
            fontStyle={'normal'}
            fontWeight={'400'}
            color={'#212121'}
            align={'center'}
            marginTop={3}
        >
            ¡Por eso queremos regalarte un  Check up médico! 
        </Typography>
    </div>

const DESCRIPTION_ALLIANCE_TRES =
    <div>
        <Typography
            fontFamily={'Montserrat'}
            fontSize={20}
            fontStyle={'normal'}
            fontWeight={'400'}
            color={'#212121'}
            align={'center'}
            marginTop={3}
        >
            Además beneficios adicionales como telemedicina y descuentos en miles de comercios participantes.
        </Typography>
    </div>

export  const DESCRIPTION_ALLIANCE =[
    {ID:1,description:  DESCRIPTION_ALLIANCE_UNO},
    {id:2,description: DESCRIPTION_ALLIANCE_DOS},
    {id:3,description:DESCRIPTION_ALLIANCE_TRES}
]

