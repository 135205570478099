export type MessageType = {
    text: string;
    error: boolean;
}

type MessageConfig = {
    [key: string]: MessageType
}

export const MessageModal: MessageConfig = {
    'CREATED': {
        text: "Has agregado con éxito a tu beneficiario",
        error: false
    },
    'UPDATED': {
        text: "Has editado con éxito a tu beneficiario",
        error: false
    },
    'ADDED': {
        text: "Has añadido con éxito a tu beneficiario",
        error: false
    },
    'DELETED': {
        text: "Has eliminado con éxito a tu beneficiario",
        error: false
    },
    'ERROR': {
        text: "El Titular no puede ser el beneficiario de la cobertura",
        error: true
    },
}