import { ThemeOptions } from '@mui/material';

type ColorPalette = {
  primary: {
    main: string;
  };
  secondary: {
    main: string;
  };
  stepper: {
    main: string;
  };
  finsus: {
    main: string;
  },
  opacity40: {
    main: string;
  };
  finsusRGBBackground: {
    main: string
  };
  finsusSecondary: {
    main: string
  };
  finsusFooterBackground: {
    main: string
  }
};

const createColorPalette = (initialColors: ColorPalette) => {
  let colors: ColorPalette = initialColors;

  return {
    getPrimaryColor: () => colors.primary.main,
    setPrimaryColor: (newColor: string) => {
      colors = { ...colors, primary: { main: newColor } };
    },
    getSecondaryColor: () => colors.secondary.main,
    setSecondaryColor: (newColor: string) => {
      colors = { ...colors, secondary: { main: newColor } };
    },
    getStepperColor: () => colors.stepper.main,
    setStepperColor: (newColor: string) => {
      colors = { ...colors, stepper: { main: newColor } };
    },

    getfinsusColor: () => colors.finsus.main,
    setFinsusColor: (newColor: string) => {
      colors = { ...colors, finsus: { main: newColor } };
    },

    getOpacity40Color: () => colors.opacity40.main,
    setOpacity40Color: (newColor: string) => {
      colors = { ...colors, opacity40: { main: newColor } };
    },
    getFinsusRgb: () => colors.finsusRGBBackground.main,
    setFinsusRgb: (newColor: string) => {
      colors = { ...colors, finsusFooterBackground: { main: newColor } };
    },

    getFinsusSecondary: () => colors.finsusSecondary.main,
    setFinsusSecondary: (newColor: string) => {
      colors = { ...colors, finsusSecondary: { main: newColor } };
    },

    getFinsusFooter: () => colors.finsusFooterBackground.main,
    setFinsusFooter: (newColor: string) => {
      colors = { ...colors, finsusFooterBackground: { main: newColor } };
    }
  };
};

/**
 * Uso del objeto con getters y setters
**/
export const myColorPalette = createColorPalette({
  primary: { main: '#039ECC' },
  secondary: { main: '#fafafa' },
  stepper: { main: '#FFD60E' },

  finsus: {
    main: 'rgb(34, 34, 62)'
  },
  opacity40: {
    main: '#FF5F54'
  },

  finsusSecondary: {
    main: '#FFA29C'
  },

  finsusRGBBackground: {
    main: '#FF5F541A'
  }
  ,

  finsusFooterBackground: {
    main: '#23223F'
  }
});

export const themeValues: ThemeOptions = {
  palette: {
    common: {
      black: '#000',
      white: '#FFF',
    },
    primary: {
      main: myColorPalette.getPrimaryColor(),
    },
    secondary: {
      main: myColorPalette.getSecondaryColor(),
    },
    error: {
      main: '#f44336',
    },
    warning: {
      main: '#FF9E1B',
    },
    success: {
      main: '#5AB52F',
    },
    text: {
      primary: '#212121',
      secondary: '#424242',
      disabled: '#9E9E9E',
    },
  }
};
