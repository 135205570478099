import { createSlice } from '@reduxjs/toolkit';

const formCellPhoneRepairSlice = createSlice({
  // Nombre descriptivo que sirve como referencia en el state de redux
  name: 'formCellPhoneRepair',
  // Aqui inicializamos el estado api
  initialState: {
    response: null,
    loading: false,
    error: null,
    front: null,
    back: null,
    form: {
      street: '',
      municipality: '',
      city: '',
      state: '',
      zipcode: '',
      additional_info: '',
      decription: '',
      date_siniestro: '',
    }
  },
  reducers: {
    setFiles(state, action) {
      if (action?.payload) {
        if (action?.payload?.front) {
          state.front = action.payload.front;
        }

        if (action?.payload?.back) {
          state.back = action.payload.back
        }
      }
    },
    apiLoading: (state) => {
      /**
       * El loading se activa cuado se llama,
       * en el futuro se puede cambiar para que se modifique 
       * cada vez que se llame, ex: state.loading = !loadingArgument
       * asi se asigna el valor contrario (true/false)
       */
      state.loading = true;
    },
    apiSuccess: (state, action) => {
      /**
       * Esta condicional es para actualizar el store
       * el state signa state.response el valor, si es nulo 
       * no se activa.
       */
      if (action?.payload) state.response = action.payload;
      state.loading = false;
      state.error = null;
    },
    apiError: (state, action) => {
      /**
       * Si obtenemos un error esta se asignara al estado
       * para mostrar un mensaje/pagina de error
       */
      state.loading = false;
      state.error = action.payload;
    },
  },
});
/**
 * 
 * @param state 
 * @returns 
 * selectApiResponse state.api se obtiene de la carpeta store -> formCellPhoneRepairSlice,
 * se asigna un objeto con este reducer para agregarlo al arbol de estado
 * de esta manera se puede obtener el estado en cualquier componente,
 * sin tener que llamarlo otra vez.
 */
export const selectApiResponse = (state: any) => state.formCellPhoneRepairSlice.response;

/**
 * Aqui tenemos las acciones desde el customhook useFetch, 
 * este mismo se activa con POST, PUT, GET, DELETE y se ejecutan los eventos del 
 * metodo reducers
 */
export const { apiLoading, apiSuccess, apiError, setFiles } = formCellPhoneRepairSlice.actions;

export default formCellPhoneRepairSlice.reducer;
