import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ButtonInter from '../../atoms/Buttons/ButtonInter';
import { SaveAltOutlined } from "@mui/icons-material";
import { useSelector } from 'react-redux';
import useFetch from '../../../hooks/useFetch';
import { ArrowBack } from '@mui/icons-material';

import {
    apiSuccess as apiUserSuccess,
    apiError as apiUserError,
    apiLoading as apiUserLoading
} from '../../../api/userSlice';
import { useNavigate } from "react-router-dom";
import { CustomButton } from '../../atoms/Buttons/Buttons';

import { MembershipType } from '../Membership/MembershipMainValues';
import { InterInstance, addAccessToken } from '../../../api/config';
import RenderAlert from '../Modal';
import { any } from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import QuestionsPlus from "../../../assets/Questions/QuestionsPlus";

const AditionalDocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 80px;
  gap: 40px;
  align-items: flex-start;
  max-width: 946px;
`;

const StyleAdionalDocumentsContainer: React.CSSProperties = {
    marginTop: '50px',
    marginBottom: '20px'
}

const AditionalDocumentComponents = () => {
    const navigate = useNavigate();
    const userResponse = useSelector((state: any) => state.user.response);
    const [benefitId, setBenefitId] = useState<string>("")
    const [plans, setPlans] = useState([])

    const userIsLogged = sessionStorage.access_token


    const responseCurrentCategory = useSelector((state: any) => state.productsCards.currentValue.category) as
        | any
        | null;

    const responseCurrenttype = useSelector((state: any) => state.productsCards.currentValue.type) as
        | any
        | null;

    let [type, setType] = useState<MembershipType>(responseCurrenttype);

    const getUser = () => {
        return fetchData('users/auth/get-user', 'GET', {}, {}, apiUserLoading, apiUserSuccess, apiUserError);
    }
    useEffect(() => {
        if (userIsLogged) {
            getUser();
        } else {
            navigate('/iniciar-sesion', { state: { path: '/documentos-adicionales' } })
        }
    }, []);

    useEffect(() => {
        if (userResponse?.length !== 0 && userResponse?.data?.user?.name) {
            const tmpType = userResponse?.data?.user?.benefit_id === "C02" ? 'premium' : 'free'
            setBenefitId(userResponse?.data?.user?.benefit_id)
            setPlans(userResponse?.data?.plans)
            setType(tmpType);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userResponse?.code]);

    const getCertificado = async (PlanId: string) => {
        try {
            await addAccessToken()
            const { data } = await InterInstance.get(`certificates/get-pdf${PlanId}`)
            if (data?.data?.error) {
                return RenderAlert({ severity: "warning", message: "Su certificado aún no se encuentra disponible." })
            }
            return data?.data
        } catch (error) {
            RenderAlert({ severity: "warning", message: "Su certificado aún no se encuentra disponible." })
            console.log(error)
        }
    }

    const { fetchData } = useFetch();

    const certifiicateRedirect = async (PlanId = "") => {
        const url = await getCertificado(PlanId)
        if (!url)
            return
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.target = "_blank";
        anchor.click();
    }

    const tycRedirect = async () => {
        if (type === 'free') {
            var anchor = document.createElement('a');
            anchor.href = 'https://storage.googleapis.com/inter-public-cloudstorage-prod/tr1s/documentos/TyC-DeRegalo.pdf';
            anchor.target = "_blank";
            anchor.click();
        } else {
            var anchorw = document.createElement('a');
            anchorw.href = 'https://storage.googleapis.com/inter-public-cloudstorage-prod/tr1s/documentos/TyC-Premium.pdf';
            anchorw.target = "_blank";
            anchorw.click();
        }
    }
    const CRedirect = async () => {
        var anchor = document.createElement('a');
        anchor.href = 'https://storage.googleapis.com/inter-public-cloudstorage-prod/tr1s/documentos/CondicionesGenerales-Chubb.pdf';
        anchor.target = "_blank";
        anchor.click();
    }
    const CPRedirect = async () => {
        var anchorw = document.createElement('a');
        anchorw.href = 'https://storage.googleapis.com/inter-public-cloudstorage-prod/tr1s/documentos/CondicionesGenerales_Zurich.pdf';
        anchorw.target = "_blank";
        anchorw.click();
    }

    const CPRedirectRobo = async () => {
        var anchorw = document.createElement('a');
        anchorw.href = 'https://storage.googleapis.com/inter-public-cloudstorage-prod/tr1s/documentos/CelularProtect/CARTA%20RECLAMACION%20GADGET.doc';
        anchorw.target = "_blank";
        anchorw.click();
    }

    const renderDocuments = () => {
        let documents: any[] = []
        plans.forEach(plan => {
            // @ts-ignore
            const benefitId = plan?.benefit_id

            if (benefitId === "C01") {
                documents.push(renderCelularGratuito())
            }

            if (benefitId === "C02") {
                documents.push(renderCelularPremium())
            }

            if (benefitId === "S01" || benefitId === "S02" || benefitId === "S03") {
                documents.push(renderSaludGratuito(benefitId))
            }

            if (benefitId === "S04") {
                documents.push(renderSaludPremium())
            }
        });
        return documents
    }

    const [expanded, setExpanded] = React.useState<string | false>('');
    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };
    const renderCelularGratuito = () => {
        return (
            <Accordion
                expanded={expanded === 'panelCelularGratuito'} onChange={handleChange('panelCelularGratuito')}
                sx={{
                    padding: expanded === 'panelCelularGratuito' ? '20px 0' : '20px 0 20px 0',
                    margin: 0
                }}
                style={{
                    boxShadow: 'none',
                }}
            >

                <AccordionSummary expandIcon={expanded === 'panelCelularGratuito' ? < RemoveIcon /> : < QuestionsPlus />}>
                    <Typography
                        color={'var(--black, #212121)'}
                        fontSize={'16px'}
                        fontWeight={700}
                        sx={{
                            textWrap: 'balance',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            marginRight: '12px'
                        }}
                    >{"Celular Protect de Regalo"}
                    </Typography>

                </AccordionSummary>

                <AccordionDetails>
                    <ButtonInter style={{
                        textTransform: 'none',
                        color: 'black',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center'
                    }}>
                        <p style={{ textAlign: 'left', fontSize: '16px', fontWeight: '900' }}>Certificado Celular Protect
                            de Regalo</p>  <SaveAltOutlined style={{ marginBottom: '8px' }}
                                onClick={() => certifiicateRedirect()}></SaveAltOutlined>
                    </ButtonInter>
                    <hr />
                    <ButtonInter style={{
                        textTransform: 'none',
                        color: 'black',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center'
                    }}>
                        <p style={{ textAlign: 'left', fontSize: '16px', fontWeight: '900' }}>Términos y Condiciones
                            Celular Protect de Regalo</p>  <SaveAltOutlined style={{ marginBottom: '8px' }}
                                onClick={() => {
                                    tycRedirect()
                                }}></SaveAltOutlined>
                    </ButtonInter>
                    <hr />
                    <ButtonInter style={{
                        textTransform: 'none',
                        color: 'black',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center'
                    }}>
                        <p style={{ textAlign: 'left', fontSize: '16px', fontWeight: '900' }}>Condiciones Generales
                            Celular Protect de Regalo (Muerte accidental)</p>  <SaveAltOutlined
                                style={{ marginBottom: '8px' }} onClick={() => {
                                    CRedirect()
                                }}></SaveAltOutlined>
                    </ButtonInter>
                    <hr />
                    <ButtonInter style={{
                        textTransform: 'none',
                        color: 'black',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center'
                    }}>
                        <p style={{ textAlign: 'left', fontSize: '16px', fontWeight: '900' }}>Formato de reclamación
                            Muerte accidental</p>  <a
                                href="https://storage.googleapis.com/inter-public-cloudstorage-prod/tr1s/documentos/Claim%20kit%20A%26H%20Life%20MA%20GM%20v1.pdf"
                                target="_blank" rel="noreferrer"><SaveAltOutlined
                                    style={{ marginBottom: '8px' }}></SaveAltOutlined></a>
                    </ButtonInter>
                    <hr />
                </AccordionDetails>
            </Accordion>
        )
    }

    const renderCelularPremium = () => {
        return <Accordion
            expanded={expanded === 'panelCelularPremium'} onChange={handleChange('panelCelularPremium')}
            sx={{
                padding: expanded === 'panelCelularPremium' ? '20px 0' : '20px 0 20px 0',
                margin: 0
            }}
            style={{
                boxShadow: 'none',
            }}
        >

            <AccordionSummary expandIcon={expanded === 'panelCelularPremium' ? < RemoveIcon /> : < QuestionsPlus />}>
                <Typography
                    color={'var(--black, #212121)'}
                    fontSize={'16px'}
                    fontWeight={700}
                    sx={{
                        textWrap: 'balance',
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        marginRight: '12px'
                    }}
                >{"Celular Protect Premium"}
                </Typography>

            </AccordionSummary>
            <AccordionDetails>
                <ButtonInter style={{
                    textTransform: 'none',
                    color: 'black',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center'
                }}>
                    <p style={{ textAlign: 'left', fontSize: '16px', fontWeight: '900' }}>Certificado Celular Protect
                        Premium</p>  <SaveAltOutlined style={{ marginBottom: '8px' }}
                            onClick={() => certifiicateRedirect()}></SaveAltOutlined>
                </ButtonInter>
                <hr />

                <ButtonInter style={{
                    textTransform: 'none',
                    color: 'black',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center'
                }}>
                    <p style={{ textAlign: 'left', fontSize: '16px', fontWeight: '900' }}>Formato de Robo
                    </p>  <SaveAltOutlined style={{ marginBottom: '8px' }}
                        onClick={() => CPRedirectRobo()}></SaveAltOutlined>
                </ButtonInter>
                <hr />

                <ButtonInter style={{
                    textTransform: 'none',
                    color: 'black',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center'
                }}>
                    <p style={{ textAlign: 'left', fontSize: '16px', fontWeight: '900' }}>Términos y Condiciones Celular
                        Protect Premium</p>  <SaveAltOutlined style={{ marginBottom: '8px' }} onClick={() => {
                            tycRedirect()
                        }}></SaveAltOutlined>
                </ButtonInter>
                <hr />
                <ButtonInter style={{
                    textTransform: 'none',
                    color: 'black',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center'
                }}>
                    <p style={{ textAlign: 'left', fontSize: '16px', fontWeight: '900' }}>Condiciones Generales Celular
                        Protect Premium (Muerte accidental)</p>  <SaveAltOutlined style={{ marginBottom: '8px' }}
                            onClick={() => {
                                CRedirect()
                            }}></SaveAltOutlined>
                </ButtonInter>
                <hr />
                <ButtonInter style={{
                    textTransform: 'none',
                    color: 'black',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center'
                }}>
                    <p style={{ textAlign: 'left', fontSize: '16px', fontWeight: '900' }}>Condiciones Generales Celular
                        Protect Premium (Robo de celular)</p>  <SaveAltOutlined style={{ marginBottom: '8px' }}
                            onClick={() => {
                                CPRedirect()
                            }}></SaveAltOutlined>
                </ButtonInter>
                <hr />
                {/* <ButtonInter style={{ textTransform: 'none', color: 'black', display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                <p style={{ textAlign: 'left', fontSize: '16px', fontWeight: '900' }}>Formato de reclamación Robo de celular</p>  <SaveAltOutlined style={{ marginBottom: '8px' }} onClick={() => { CRedirect() }}></SaveAltOutlined>
            </ButtonInter>
            <hr /> */}
                <ButtonInter style={{
                    textTransform: 'none',
                    color: 'black',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center'
                }}>
                    <p style={{ textAlign: 'left', fontSize: '16px', fontWeight: '900' }}>Formato de reclamación Muerte
                        accidental</p>  <a
                            href="https://storage.googleapis.com/inter-public-cloudstorage-prod/tr1s/documentos/Claim%20kit%20A%26H%20Life%20MA%20GM%20v1.pdf"
                            target="_blank" rel="noreferrer"><SaveAltOutlined
                                style={{ marginBottom: '8px' }}></SaveAltOutlined></a>
                </ButtonInter>
                <hr />
            </AccordionDetails>
        </Accordion>
    }

    const renderSaludGratuito = (benefitId: any) => {
        let url = "S01"
        let title = "S01"

        const objectUrls = {
            "S01": "https://storage.googleapis.com/inter-public-cloudstorage-prod/tr1s/documentos/SaludProtect/SPDR_Medicamentos.pdf",
            "S02": "https://storage.googleapis.com/inter-public-cloudstorage-prod/tr1s/documentos/SaludProtect/SPDR_Reembolso.pdf",
            "S03": "https://storage.googleapis.com/inter-public-cloudstorage-prod/tr1s/documentos/SaludProtect/SPDR_CheckUp.pdf",
        }

        const getTitle = {
            "S01": "Pago de medicamentos",
            "S02": "Reembolso de gastos médicos",
            "S03": "Check-up médico",
        }
        // @ts-ignore
        url = objectUrls[benefitId]
        // @ts-ignore
        title = getTitle[benefitId]
        return <Accordion
            expanded={expanded === 'panelSaludGratuito'} onChange={handleChange('panelSaludGratuito')}
            sx={{
                padding: expanded === 'panelSaludGratuito' ? '20px 0' : '20px 0 20px 0',
                margin: 0
            }}
            style={{
                boxShadow: 'none',
            }}>
            <AccordionSummary expandIcon={expanded === 'panelSaludGratuito' ? < RemoveIcon /> : < QuestionsPlus />}>
                <Typography
                    color={'var(--black, #212121)'}
                    fontSize={'16px'}
                    fontWeight={700}
                    sx={{
                        textWrap: 'balance',
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        marginRight: '12px'
                    }}
                >{"Salud Protect de Regalo"}
                </Typography>

            </AccordionSummary>
            <AccordionDetails>
                <ButtonInter style={{
                    textTransform: 'none',
                    color: 'black',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center'
                }}>
                    <p style={{
                        textAlign: 'left',
                        fontSize: '16px',
                        fontWeight: '900'
                    }}>{`Certificado Salud Protect de Regalo (${title})`}</p>  <SaveAltOutlined
                        style={{ marginBottom: '8px' }} onClick={() => certifiicateRedirect("/P02")}></SaveAltOutlined>
                </ButtonInter>
                <hr />
                <ButtonInter style={{
                    textTransform: 'none',
                    color: 'black',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center'
                }}>
                    <p style={{
                        textAlign: 'left',
                        fontSize: '16px',
                        fontWeight: '900'
                    }}>{`Términos y Condiciones Salud Protect de Regalo (${title})`}</p>  <SaveAltOutlined
                        style={{ marginBottom: '8px' }} onClick={() => window.open(url, '_blank')}></SaveAltOutlined>
                </ButtonInter>
                <hr />
                <ButtonInter style={{
                    textTransform: 'none',
                    color: 'black',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center'
                }}>
                    <p style={{ textAlign: 'left', fontSize: '16px', fontWeight: '900' }}>Condiciones Generales Salud
                        Protect
                        de Regalo</p>  <SaveAltOutlined style={{ marginBottom: '8px' }}
                            onClick={() => window.open('https://storage.googleapis.com/inter-public-cloudstorage-prod/tr1s/documentos/SaludProtect/Condiciones_Generales_Salud_Protect.pdf', '_blank')}></SaveAltOutlined>
                </ButtonInter>
                <hr />
                <ButtonInter style={{
                    textTransform: 'none',
                    color: 'black',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center'
                }}>
                    <p style={{ textAlign: 'left', fontSize: '16px', fontWeight: '900' }}>Formato de reclamación Muerte
                        accidental</p>  <a
                            href="https://storage.googleapis.com/inter-public-cloudstorage-prod/tr1s/documentos/Claim%20kit%20A%26H%20Life%20MA%20GM%20v1.pdf"
                            target="_blank" rel="noreferrer"><SaveAltOutlined
                                style={{ marginBottom: '8px' }}></SaveAltOutlined></a>
                </ButtonInter>
                <hr />
            </AccordionDetails>
        </Accordion>
    }

    const renderSaludPremium = () => {
        return <Accordion
            expanded={expanded === 'panelSaludPremium'} onChange={handleChange('panelSaludPremium')}
            sx={{
                padding: expanded === 'panelSaludPremium' ? '20px 0' : '20px 0 20px 0',
                margin: 0
            }}
            style={{
                boxShadow: 'none',
            }}
        >

            <AccordionSummary expandIcon={expanded === 'panelSaludPremium' ? < RemoveIcon /> : < QuestionsPlus />}>
                <Typography
                    color={'var(--black, #212121)'}
                    fontSize={'16px'}
                    fontWeight={700}
                    sx={{
                        textWrap: 'balance',
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        marginRight: '12px'
                    }}
                >{"Salud Protect Premium"}
                </Typography>

            </AccordionSummary>
            <AccordionDetails>
                <ButtonInter style={{
                    textTransform: 'none',
                    color: 'black',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center'
                }}>
                    <p style={{ textAlign: 'left', fontSize: '16px', fontWeight: '900' }}>Certificado Salud Protect
                        Premium</p>  <SaveAltOutlined style={{ marginBottom: '8px' }}
                            onClick={() => certifiicateRedirect("/P02")}></SaveAltOutlined>
                </ButtonInter>
                <hr />
                <ButtonInter style={{
                    textTransform: 'none',
                    color: 'black',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center'
                }}>
                    <p style={{ textAlign: 'left', fontSize: '16px', fontWeight: '900' }}>Términos y Condiciones Salud Protect
                        Premium</p>  <SaveAltOutlined style={{ marginBottom: '8px' }}
                            onClick={() => window.open('https://storage.googleapis.com/inter-public-cloudstorage-prod/tr1s/documentos/SaludProtect/SPP.pdf', '_blank')}></SaveAltOutlined>
                </ButtonInter>
                <hr />
                <ButtonInter style={{
                    textTransform: 'none',
                    color: 'black',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center'
                }}>
                    <p style={{ textAlign: 'left', fontSize: '16px', fontWeight: '900' }}>Condiciones Generales Salud Protect
                        Premium</p>  <SaveAltOutlined style={{ marginBottom: '8px' }}
                            onClick={() => window.open('https://storage.googleapis.com/inter-public-cloudstorage-prod/tr1s/documentos/SaludProtect/Condiciones_Generales_Salud_Protect.pdf', '_blank')}></SaveAltOutlined>
                </ButtonInter>
                <hr />
                <ButtonInter style={{
                    textTransform: 'none',
                    color: 'black',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center'
                }}>
                    <p style={{ textAlign: 'left', fontSize: '16px', fontWeight: '900' }}>Formato de reclamación Muerte
                        accidental</p>  <a
                            href="https://storage.googleapis.com/inter-public-cloudstorage-prod/tr1s/documentos/Claim%20kit%20A%26H%20Life%20MA%20GM%20v1.pdf"
                            target="_blank" rel="noreferrer"><SaveAltOutlined style={{ marginBottom: '8px' }}></SaveAltOutlined></a>
                </ButtonInter>
                <hr />
            </AccordionDetails>
        </Accordion>
    }

    const userData = JSON.parse(sessionStorage.getItem('user-storage')!);
    React.useEffect(() => {
        window.dataLayer.push({
            event: 'TRUS_documentos_adicionales_v',
            tipo_seguro: userResponse ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : "De regalo",//premium | gratuito
            correo: userResponse?.data?.user?.email,
            telefono: userResponse?.data?.user?.phone,
            page_location: window.location.href,
        });
    }, [])
    return (<AditionalDocumentContainer style={StyleAdionalDocumentsContainer}>

        <CustomButton
            text={"Regresar"}
            onClick={() => navigate(-1)}
            size={"medium"}
            startIcon={<ArrowBack />}

            variant={"contained"}
            style={{
                color: "#039ECC",
                backgroundColor: "transparent",
                fontSize: "1rem",
                textTransform: "none",
                fontWeight: "600",
                fontStyle: "normal",
                padding: "0px",
                minWidth: "64px",
                border: "none",
                justifyContent: "flex-start",
                width: "fit-content",
            }}
        />
        <p style={{
            fontWeight: '900',
            fontSize: '24px',

        }}>Documentos adicionales</p>
        <p style={{ fontSize: '20px', textAlign: 'center', marginTop: '60px', marginBottom: '60px' }}>
            Aquí podrás consultar o descargar todos los documentos relacionados a tu
            plan {responseCurrentCategory} Protect {responseCurrenttype}
        </p>
        <div style={{ width: '100%', margin: 'auto' }}>
            {
                responseCurrentCategory && responseCurrenttype && plans.length ? renderDocuments() : <></>
            }
        </div>
    </AditionalDocumentContainer>)
}

export default AditionalDocumentComponents;
