import { combineReducers, configureStore } from '@reduxjs/toolkit';
import apiReducer from '../api/apiSlice';
import gadgetsSlice from '../api/gadgetsSlice';
import healthSlice from '../api/healthSlice';
import gadgetsModelsSlice from '../api/gadgetsModelsSlice';
import gadgetsDescriptionSlice from '../api/gadgetsDescriptionSlice';
import validateCodeSlice from '../api/validateCodeSlice';
import storage from 'redux-persist/lib/storage';
import productsSlice from '../api/productsSlice';
import formRegisterSlice from '../api/formRegisterSlice';
import formSignInSlice from '../api/formSignInSlice';
import formCellPhoneRepairSlice from '../api/formCellPhoneRepairSlice';
import trackingSlice from '../api/trackingSlice';
import leadsSlice from '../api/leadsSlice';
import signUpSlice from '../api/signUpSlice';
import loginSlice from '../api/loginSlice';
import userSlice from '../api/userSlice';
import gadgetsBrandSlice from '../api/gadgetsBrandSlice';
import beneficiarySlice from '../api/beneficiarySlice';
import multipleBeneficiariesSlice from '../api/multipleBeneficiarySlice';
import trackingIdSlice from '../api/trackingIdSlice';
import validationCellphoneSlice from '../api/validationCellphoneSlice';
import newProductRegisterSlice from '../api/newProductRegisterSlice';
import allianceSettingSlice from '../api/allianceSettingSlice';

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import paymentSlice from '../api/paymentSlice';
import sendEmailSlice from "../api/sendEmailSlice";
import sendImeiSlice from "../api/sendImeiSlice";
import resetPasswordSlice from "../api/resetPasswordSlice";
import userAlianceSlice from '../api/userAlianceSlice';
import assignmentSlice from '../api/assignmentSlice';
import assignmentModalSlice from '../api/assignmentModalSlice';
import assignmentModalOffSlice from '../api/assignmentModalOffSlice';
import checkUpMedicSlice from "../api/checkUpMedicSlice";
import statusSlice from '../api/statusSlice';
import priceSlice from '../api/priceSlice';
import claimSlice from '../api/claimSlice';
import addressSlice from '../api/addressSlice';
import deleteUserSlice from '../api/deleteUserSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [
    'beneficiary',
    'formSignIn',
    "sendEmail",
    "resetPassword",
    "sendImei",
    "assignmentModal",
    "assignmentModalOff"
  ]
}

const gadgetsPersistConfig = {
  key: 'gadgets',
  storage,
  blacklist: ['loading']
}

const reducer = combineReducers({
  // aqui asignamos nuestros reducers(archivos) api/*Slice
  api: apiReducer,
  gadgets: persistReducer(gadgetsPersistConfig, gadgetsSlice),
  health: healthSlice,
  gadgetsModels: gadgetsModelsSlice,
  gadgetsDescription: gadgetsDescriptionSlice,
  validateCode: validateCodeSlice,
  productsCards: productsSlice,
  formRegister: formRegisterSlice,
  formSignIn: formSignInSlice,
  formCellPhoneRepair: formCellPhoneRepairSlice,
  tracking: trackingSlice,
  leads: leadsSlice,
  user: userSlice,
  signUp: signUpSlice,
  login: loginSlice,
  gadgetsBrand: gadgetsBrandSlice,
  beneficiary: beneficiarySlice,
  paymentperiod: paymentSlice,
  multipleBeneficiaries: multipleBeneficiariesSlice,
  trackingId: trackingIdSlice,
  validationCellphone: validationCellphoneSlice,
  sendEmail: sendEmailSlice,
  resetPassword: resetPasswordSlice,
  sendImei: sendImeiSlice,
  newProductRegister: newProductRegisterSlice,
  userAliance: userAlianceSlice,
  allianceSetting: allianceSettingSlice,
  assignment: assignmentSlice,
  assignmentModal: assignmentModalSlice,
  assignmentModalOff: assignmentModalOffSlice,
  checkUpMedic: checkUpMedicSlice,
  status: statusSlice,
  price: priceSlice,
  claim: claimSlice,
  address: addressSlice,
  deleteUserSlice: deleteUserSlice
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store: any = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV !== 'production'
});

export const persistor = persistStore(store);

