import React from "react";
import { useDispatch } from 'react-redux';
import { styled } from "styled-components";
import { CardMedia, CardContent, Card, Typography } from "@mui/material";
import { CustomButton } from "../../atoms/Buttons/Buttons";
import ManiPosesModal from "../../../assets/CustomerPortalAssets/ManiposesModal.svg";
import { useNavigate } from "react-router-dom";
import { setCurrentValue } from '../../../api/productsSlice';
import { useSelector } from 'react-redux'
import { IStorageMembershipData } from "../../../utils/localStorageKeys";

const CardHeader = styled.div`
  borderradius: 8px 8px 0px 0px;
  background: #ffd56b;
  border-bottom: 1px solid #fff;
  @media (min-width: 600px) {
    border-bottom: none;
    border-right: 1px solid #fff;
  }
`;

type CardActionProps = {
  cardType?: string,
  cardCategory?: string,
  cardTitle?: string,
  cardDescription?: string,
  subType?: string,
  visibleImg?: boolean,
  cardContent?: string | React.ReactNode,
  imgSrc?: any;
  style?: object;
  styleTextContent?: object;
  styleImg?: object;
  styleImgCard?: object;
  styleButton?: object;
  styleButtonSecondary?: object;
  buttonText?: string;
  buttonTextSecondary?: string;
  onClick?: any;
  onClickSecondary?: any;
  secundaryButton?: boolean;
  setOpenModal?: any;
}

const CardAction: React.FC<CardActionProps> = ({
  cardType,
  cardCategory,
  cardDescription,
  cardTitle,
  subType,
  visibleImg,
  cardContent,
  imgSrc = ManiPosesModal,
  style,
  styleTextContent,
  styleImg,
  styleImgCard,
  styleButton,
  styleButtonSecondary,
  buttonText = "Quiero ser premium",
  buttonTextSecondary = "Continuar",
  onClick,
  onClickSecondary,
  secundaryButton,
  setOpenModal
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let storage: IStorageMembershipData = {
    ...useSelector((state: any) => state.productsCards.currentValue),
  };

  const userData = JSON.parse(sessionStorage.getItem('user-storage')!);
  const userResponse = useSelector((state: any) => state.user.response);


  React.useEffect(() => {
    if (window.location.pathname !== "/registrate") {
        if (cardType == "premium") {
          window.dataLayer.push({
            'event': 'TRUS_quiero_premium_v',
            'correo': userResponse?.data?.user?.email,
            'telefono': userResponse?.data?.user?.phone,
            'page_location': window.location.href,
            tipo_seguro: storage.type == "free" ? "De regalo" : "Premium",
            seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
          });
        }
    }

  }, [])


  if (cardType != undefined) {
    const cardTypeStorage = sessionStorage.setItem('card-type-storage', JSON.stringify(cardType));
  }


  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row", md: "row" },
        maxWidth: 725,
        borderRadius: "8px",
      }}
    >
      {!visibleImg && <CardHeader style={{ flex: 1, ...styleImg }}>
        <CardMedia
          component="img"
          image={imgSrc}
          alt="mani poses"
          sx={{
            padding: "1em 0 0 0",
            margin: "auto",
            width: 230,
            height: 276,
            objectFit: "cover",
            ...styleImgCard
          }}
        />
      </CardHeader>}
      <CardContent
        style={{
          flex: 1,
          ...style
        }}
        sx={{
          border: "1px solid #E6B331",
          background: "#E6B331",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          alignItems: "center",
          padding: { xs: "1rem", md: "40px" },
        }}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="p"
          sx={{ textWrap: "balance" }}
          style={{
            textAlign: "center",
            color: "var(--white, #FFF)",
            fontSize: 20,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            ...styleTextContent
          }}
        >
          {cardContent
            ||
            <span>No pierdas el tiempo y disfruta todos los beneficios de ser premium {" "}
              <span
                style={{
                  fontWeight: 700,
                }}
              >
                por sólo $99 pesos al mes
              </span></span>}
        </Typography>
        <CustomButton
          onClick={() => {
            if (onClick) {
              onClick();
            } else {
              const sendDataLocalStorage = {
                type: cardType,
                subtype: subType,
                category: cardCategory,
                cardTitle: cardTitle,
                cardDescription: cardDescription
              };

              window.dataLayer.push({
                event: 'TRUS_quiero_premium',
                boton_contacto: 'Quiero ser premium',
                page_location: window.location.href,
                correo: userResponse?.data?.user?.email,
                telefono: userResponse?.data?.user?.phone,
                tipo_seguro: storage.type == "free" ? "De regalo" : "Premium",
                seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
              });
              dispatch(setCurrentValue(sendDataLocalStorage));

              if (sessionStorage.access_token) {
                navigate('/resumen-membresia', { state: { path: 'portal-del-cliente', tabHidden: "freemium" } })
              } else {
                navigate('/resumen-membresia', { state: { path: 'registrate' } })
              }
            }
          }}
          text={buttonText}
          style={{
            color: "#E6B331",
            borderRadius: 8,
            border: "1px solid #FFD66F",
            background: "var(--white, #FFF)",
            textAlign: "center",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            textTransform: "uppercase",
            padding: "14px 16px",
            width: "100%",
            ...styleButton
          }}
        />
        {secundaryButton &&
          <CustomButton
            onClick={() => {
              if (onClickSecondary) {
                onClickSecondary();
              }
            }}
            text={buttonTextSecondary}
            style={{
              color: "#E6B331",
              borderRadius: 8,
              border: "1px solid #FFD66F",
              background: "var(--white, #FFF)",
              textAlign: "center",
              fontSize: 16,
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              textTransform: "uppercase",
              padding: "14px 16px",
              width: "100%",
              ...styleButtonSecondary
            }}
          />
        }
      </CardContent>
    </Card>
  );
};

export default CardAction;
