import React from 'react'
import { useSelector } from 'react-redux';
import { styled } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import CellPhoneDataDetails from '../CellPhoneDataDetails';
import { OutlinedButton, PrimaryButton } from '../../../molecules/CellPhoneRepairButtons';
import { IStorageMembershipData } from '../../../../utils/localStorageKeys';

const data = [
	{
		title: "Marca",
		value: "Samsung"
	},
	{
		title: "Modelo",
		value: "A53"
	},
	{
		title: "Descripción",
		value: "5G 128GB Azul"
	},
	{
		title: "IMEI",
		value: "867890349803456"
	}
]

const TitleComponent = styled.div`
    font-size: 24px;
	font-weight: 700;
	text-align: center;
	margin-bottom: 10px;
`;

const CellData: React.FC = ({ }) => {
	const navigate = useNavigate();
	let storage: IStorageMembershipData = {
		...useSelector((state: any) => state.productsCards.currentValue),
	};

	const userResponse = useSelector((state: any) => state.user.response);

	return (
		<>
			<TitleComponent>Nos ayudas a confirmar los datos de tu celular</TitleComponent>
			<CellPhoneDataDetails data={data} />
			<PrimaryButton text={"Sí, continuar"} onClick={() => {
				// setActiveStep(activeStep + 1);
				window.dataLayer.push({
					event: 'TRUS_reparacion_actualizar_datos',
					marca_cel: '',
					modelo_cel: '',
					descripcion_cel: '',
					tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium",//premium | gratuito
					telefono: userResponse?.data?.user?.phone,
					correo: userResponse?.data?.user?.email,
					boton_contacto: 'Si, continuar'
				})
				navigate('/confirmar-datos/1')
			}} />
			<OutlinedButton text={"No, ya cambió"} onClick={() => {
				window.dataLayer.push({
					event: 'TRUS_reparacion_confirmar_datos',
					marca: '',
					modelo: '',
					tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium",//premium | gratuito
					telefono: userResponse?.data?.user?.phone,
					correo: userResponse?.data?.user?.email,
					boton_contacto: 'No, ya cambió'
				})
				// navigate("/editar-informacion")
				window.open('https://intermx-digital.atlassian.net/servicedesk/customer/portal/8/group/34/create/89')
			}} />
		</>
	)
}
export default CellData
