
import React, { useEffect } from 'react';
import { Typography } from "@mui/material";
import { CustomButton } from "../../atoms/Buttons/Buttons";
import { useLocation, useNavigate } from 'react-router-dom';
import fail from '../../../assets/payment/fail.gif';
import giffy from '../../../assets/payment/gifgif.gif';
import { useSelector } from 'react-redux';
import { IStorageMembershipData } from '../../../utils/localStorageKeys';

interface PaymentProps {
    styleButtonPrimary?: object;
    styleButtonSecondary?: object;
    paymentResultImage?: boolean;
    paymentResulValue?: String;
    paymentResultText?: String;
    titleButton?: string;
    onClick1?: () => void;
    onClick2?: () => void;
}

const PaymentOk: React.FC<PaymentProps> = ({
    styleButtonPrimary,
    styleButtonSecondary,
    paymentResultImage,
    paymentResulValue,
    paymentResultText,
    titleButton,
    onClick1,
    onClick2

}) => {
    const location = useLocation();
    const { state: { success, path } } = location
    const navigate = useNavigate();
    const userResponse = useSelector((state: any) => state?.user?.response);
    let storage: IStorageMembershipData = {
        ...useSelector((state: any) => state.productsCards.currentValue),
      };
    const allianceSettingResponse = useSelector((state: any) => state.allianceSetting.response);

    const CRedirect = async () => {
        window.dataLayer.push({
            event: 'TRUS_pagar_error',
            tipo_seguro: "Premium",
            correo: userResponse?.data?.user?.email,
            telefono: userResponse?.data?.user?.phone,
            boton_contacto: 'Reportar un problema',
            seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
            alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
          })
        var anchor = document.createElement('a');
        anchor.href = 'https://intermx-digital.atlassian.net/servicedesk/customer/portal/8/group/32/create/94';
        anchor.target="_blank";
        anchor.click();
    }

    useEffect(() => {
        if (!success){
            window.dataLayer.push({
                'event': 'TRUS_pagar_error_v',
                'tipo_seguro': "Premium",
                seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
                'correo': userResponse?.data?.user?.email,
                'telefono': userResponse?.data?.user?.phone,
                'page_location': window.location.href,  
                alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,    
            })
        }
    }, [])

    const mediaQuery = window.matchMedia('(max-width: 414px) and (max-height: 996px) and (-webkit-device-pixel-ratio: 2)');
    return (
        <div style={{ 
            maxWidth: '752px',
            margin: 'auto',
            padding: '0px 16px' 
        }}>
            <div
                id='full-container'
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '24px',
                    alignSelf: 'stretch',
                    width: '100%',
                    height: '100%',
                    maxWidth: '557px',
                    margin: 'auto',
                    marginTop: "20px",
                    marginBottom: "50px"
                }}
            >
                <img width='224px'
                    height='224px'

                    src={success ? giffy : fail} alt="Logo" />
                <Typography
                    gutterBottom
                    variant="h5"
                    component="p"
                    sx={{ textWrap: "balance" }}
                    style={{
                        width: '100%',
                        color: 'var(--Black, #212121)',
                        textAlign: 'center',
                        fontFamily: 'Montserrat',
                        fontSize: '28px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: 'normal',
                    }}
                >
                    {
                        success ? "¡Tu pago se ha realizado con éxito!" : "¡Oops! Tuvimos un problema al procesar tu pago"
                    }
                </Typography>
                <Typography

                    sx={{ textWrap: "balance" }}
                    style={{
                        color: 'var(--Black, #212121)',
                        textAlign: 'center',
                        fontFeatureSettings: 'clig off, liga off',
                        fontFamily: 'Montserrat',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                    }}
                >
                    {
                        success ? "Todo listo hemos procesado el pago, en un lapso máximo de 24 horas te llegará por correo tu certificado junto con toda la información de tu plan." 
                        : "No te preocupes, nuestros medios de pago son completamente seguros, puedes volverlo a intentar o reportar el problema a nuestra mesa de servicio"
                    }
                </Typography>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    }}
                >
                    <CustomButton
                        onClick={() => {
                            navigate(path, {state: {path: path}})
                            if(!success){
                                window.dataLayer.push({
                                    event: 'TRUS_pagar_error',
                                    seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
                                    tipo_seguro: "Premium",
                                    correo: userResponse?.data?.user?.email,
                                    telefono: userResponse?.data?.user?.phone,
                                    boton_contacto: 'Reintentar',
                                    alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
                                })
                            }
                        }}
                        text={success ? 'Ver membresía' : 'Reintentar'}
                        style={{
                            display: 'flex',
                            height: '48px',
                            padding: '16px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            width: mediaQuery ? '100%' : '344px',

                            alignSelf: 'stretch',
                            borderRadius: '10px',
                            border: '1px solid var(--White, #FFF)',
                            opacity: 0.8,
                            background: 'var(--Azul-Inter, #039ECC)',
                            color: "white",
                            ...styleButtonPrimary
                        }}
                    />

                    {!success && (
                        <CustomButton
                            onClick={() => CRedirect}
                            text={'Reportar Problema '}
                            style={{
                                display: 'flex',
                                height: '56px',
                                padding: '14px 16px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: mediaQuery ? '100%' : '344px',
                                gap: '10px',
                                alignSelf: 'stretch',
                                ...styleButtonSecondary,
                                borderRadius: '8px',
                                border: '1.5px solid var(--Azul-Inter, #039ECC)',
                                opacity: 0.8,
                                marginTop: "20px",
                                color: "#039ECC",
                                textHeight: 'auto'
                            }}
                        />
                    )}
                </div>
            </div >
        </div>)
}

export default PaymentOk