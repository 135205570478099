import moment from 'moment';

export interface IFilesBucket {
    name: string; //nombre del archivo
    id: string; //nombre del elemento
    type: any; //mimetype
    data: any; //Data del buffer
}

export interface IBucket {
    name: string;
    created: string;
    files: IFilesBucket[];
}

export class BucketStorageFiles {
    private bucketName: string = '';
    public instance: IBucket = {
        name: '',
        created: '',
        files: []
    }
    constructor(bucket: string) {
        this.bucketName = `${bucket}-bucket`;
        //Check Bucket 
        const oldBucketValue = localStorage.getItem(this.bucketName);
        if (oldBucketValue && oldBucketValue !== null && oldBucketValue !== undefined) {
            const oldBucket: IBucket = JSON.parse(oldBucketValue);
            this.instance = oldBucket;
        } else {
            this.instance.name = this.bucketName;
            this.instance.created = moment().format('YYYY-MM-DD HH:mm:ss');
            localStorage.setItem(this.bucketName, JSON.stringify(this.instance));
        }
    }

    private convertFileToBlobAndAddToList(file: File, key: string) {
        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
            if (event && event.target) {
                const fileData: IFilesBucket = {
                    id: key,
                    name: file.name,
                    type: file.type,
                    data: event.target.result
                }
                this.instance.files.push(fileData);
                this.updateBucket()
                return fileData
            }
        };
        reader.readAsDataURL(file);
    }

    private updateBucket() {
        const data = JSON.stringify(this.instance);
        localStorage.setItem(this.bucketName, data);
    }
    private convertBlobToFile(element: IFilesBucket) {
        const file = new File([element.data], element.name, { type: element.type });
        return file;
    }

    public setFile(key: string, file: File) {
        const search = this.instance.files.find(e => e.id === key);
        if (search) {
            this.instance.files = this.instance.files.filter(e => e.id != e.id);
        }
        this.convertFileToBlobAndAddToList(file, key);
    }

    public getFile(key: string): File {
        const search = this.instance.files.find(e => e.id === key);
        if (search !== undefined) {
            return this.convertBlobToFile(search);
        } else {
            throw new Error("No se encontro el archivo");
        }
    }
}