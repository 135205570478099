import React from 'react';
import "./JoinRevolution.css";
import person from './assets/img/banner.png'
import { themeValues, myColorPalette } from '../../../../src/theme/themeGenerated';

import { styled } from '@mui/material/styles';


const JoinComponent = styled('div')(({ theme }) => ({

}));


interface JoinRevolutionProps {

}

const JoinRevolution: React.FC<JoinRevolutionProps> = ({ }) => {
    const converBold = (text: string) => {
        const parts = text.split('*');
        return parts.map((part, index) => {
            if (index % 2 === 0) {
                return part;
            } else {
                return (<strong key={index}>{part}</strong>)
            }
        });
    }

    const title1: string = '¿Por qué ser parte de';
    const title2: string = ' #TeRegalamosUnSeguro?';
    const points: string[] = [
        'Todos los mexicanos deben tener la oportunidad *de cuidar su bienestar y el de su familia.*',
        'Queremos que todos *tengan acceso a productos de protección.*',
        '*Unidos somos más fuertes.* Cuantos más se unan a este movimiento, podremos ayudar a más personas.',
        '*La empatía nos mueve,* queremos que más personas la experimenten y se beneficien de ella.'
    ];
    return (

        <JoinComponent>
            <div className='JoinConteiner'>
                <div className="TitleItem">
                    <span className="titlePart">{title1}</span>
                    <span className="titlePart">{title2}</span>
                </div>
                <div className='ImageItem'>
                    <div className='Image'>
                        <img src={person} alt="Person" className='PersonImg' />
                    </div>
                </div>

                <div className='StepPathsItem'>
                    <div>
                        {points.map((e, index) => (
                            <div key={index} className='element'>
                                <div

                                    className="elementPoint">
                                    <div className="circle" style={{ backgroundColor: window.location.pathname === "/finsus" ? myColorPalette.getfinsusColor() : '' }}>
                                    </div>
                                    {index !== points.length - 1 ?
                                        (<div
                                            className={window.location.pathname === "/finsus" ? 'lineFinzus' : 'line'}


                                        >

                                        </div>) : ''}
                                </div>
                                <div className="elementText"

                                >
                                    <p>
                                        {converBold(e)}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </JoinComponent>

    )
}

export default JoinRevolution;