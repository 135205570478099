import React, {useEffect} from "react";
import {styled} from "styled-components";
import CustomContainer from "../Container/Container";
import {Avatar, Box} from "@mui/material";
import ManiErrorLogin from "../../../assets/ManiPosesGuyStadistics/ManiForgotPassword.svg";
import {CustomTypography} from "../../atoms/Label/Label";
import ForgotPasswordForm from "./ForgotPasswordFrom";
import {loadStripe} from "@stripe/stripe-js";
import BASE_CONFIG from "../../../env/env";
import useFetch from "../../../hooks/useFetch";
import {apiError, apiLoading, apiSuccess} from "../../../api/sendEmailSlice";
import {useSelector} from "react-redux";
import {CustomButton} from "../../atoms/Buttons/Buttons";
import {ArrowBack} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import RenderAlert from "../../organisms/Modal";

const LayOutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 558px;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 80px;
`;

type ForgotPasswordProps = {};
const urlBaseSite = BASE_CONFIG.base;
const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
    const navigate = useNavigate();
    const { fetchData } = useFetch();

    const response = useSelector((state: any) => state.sendEmail.response) as any | null;
    const sendEmail =(email:string)=>{
            return fetchData('users/auth', 'POST', {
                "event": "send-recovery-email",
                "content": {
                    "email": email,
                    "url": urlBaseSite+"/reset-password/"
                }
            }, {}, apiLoading, apiSuccess, apiError);
    }

    useEffect(() => {
       if (response?.code==200){
           RenderAlert({severity: "success", message: response.data.mesasage});
           navigate(-1)
       }
    }, [response?.code]);


    return (
        <div style={{ marginTop: 40, marginBottom: 40 }}>
            <CustomButton
                text={"Regresar"}
                onClick={() =>  navigate(-1)}
                size={"medium"}
                startIcon={<ArrowBack />}
                variant={"contained"}
                style={{
                    color: "#039ECC",
                    backgroundColor: "transparent",
                    fontSize: "1rem",
                    textTransform: "none",
                    fontWeight: "600",
                    fontStyle: "normal",
                    padding: "0px",
                    minWidth: "64px",
                    border: "none",
                    justifyContent: "flex-start",
                    width: "fit-content",
                }}
            />
            <LayOutContainer>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        marginBottom: 3,
                        justifyContent: 'center',
                    }}
                >
                    <Avatar
                        sx={{
                            maxWidth: '100%',
                            width: '100%',
                            height: 'auto',
                            margin: {xs: 0, lg: '20px'},
                        }}
                        alt='ManiErrorLogin'
                        src={ManiErrorLogin}
                        variant='square'
                    />
                </Box>
                <Box
                    sx={{
                        width:'100%',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <CustomTypography
                        style={{
                            fontSize: 24,
                            fontWeight: 700,
                            letterSpacing: "0.5px",
                            textAlign: "center",
                            Padding: "0 1rem",
                            textWrap: "balance",
                        }}
                    >
                        {"¿Olvidaste tu contraseña?"}
                    </CustomTypography>
                </Box>

                <CustomTypography
                    style={{
                        fontSize: 20,
                        fontWeight: 400,
                        fontStyle: "normal",
                        letterSpacing: "0.5px",
                        textAlign: "center",
                        Padding: "0 1rem",
                        textWrap: "balance",
                    }}
                >
                    {"No te preocupes, ingresa el correo o teléfono con el que te registraste y en breve recibirás las instrucciones para crear una nueva contraseña."}
                </CustomTypography>

                <ForgotPasswordForm handleSend={sendEmail}/>

            </LayOutContainer>
        </div>
    )

}

export default ForgotPassword;
