import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone';
import { useField } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import photo_gif from '../../../../assets/CellPhoneData/PhotoPhone.gif'
import upload_logo from '../../../../assets/CellPhoneData/upload.jpg'
import FormItemGenerate from '../../../atoms/FormItems/FormItemGenerate';
import { PrimaryButton } from '../../../molecules/CellPhoneRepairButtons';
import { IStorageMembershipData } from '../../../../utils/localStorageKeys';
import { UseSelector } from 'react-redux/es/hooks/useSelector';
import { setFiles } from '../../../../api/formCellPhoneRepairSlice';
import { InterInstance } from '../../../../api/config';
import { BucketStorageFiles } from '../../../../utils/bucket.storage';
import ButtonInter from '../../../atoms/Buttons/ButtonInter';
interface UploadImageProps {
	handleChange?: any;
	handleBlur?: any;
	setActiveStep: any;
	activeStep: number;
	errors?: any;
}

const Container = styled.div`
	flex: 1;
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	border-width: 2px;
	border-radius: 5px;
	border-color: #77CAE3;
	border-style: dashed;
	outline: none;
	transition: border .24s ease-in-out;
`;


const UploadImage: React.FC<UploadImageProps> = ({ handleChange, handleBlur, setActiveStep, activeStep, errors }) => {
	const navigate = useNavigate();
	const [description, descriptionMeta] = useField("description");
	const userResponse = useSelector((state: any) => state.user.response);
	const reparacion = useSelector((state: any) => state.formCellPhoneRepair);
	const storage: IStorageMembershipData = {
		...useSelector((state: any) => state.productsCards.currentValue),
	};
	const response = useSelector((state: any) => state.formCellPhoneRepair.response) as any | null;
	const bucket = new BucketStorageFiles('celular-reparacion');
	const dispatch = useDispatch();


	const onDropFront = (acceptedFiles: File[]) => {
		// Asumiendo que solo permites un archivo, tomamos el primero
		const uploadedFile = acceptedFiles[0];
		bucket.setFile('front_image', uploadedFile);
	};

	const onDropBack = (acceptedFiles: File[]) => {
		// Asumiendo que solo permites un archivo, tomamos el primero
		const uploadedFile = acceptedFiles[0];
		bucket.setFile('back_image', uploadedFile);
	};

	const {
		acceptedFiles: acceptedFilesFront,
		getRootProps: getRootPropsFront,
		getInputProps: getInputPropsFront,
		isFocused: isFocusedFront,
		isDragAccept: isDragAcceptFront,
		isDragReject: isDragRejectFront,
	} = useDropzone({
		onDrop: onDropFront,
		maxFiles: 1,
		minSize: 0,
		maxSize: 5242880,
		accept: {
			'image/jpg': [],
			'image/jpeg': [],
			'image/png': []
		},
	});

	const filesFront = acceptedFilesFront.map((file) => (
		<li key={file?.name}>
			{file?.name} - {file.size} bytes
		</li>
	));

	const {
		acceptedFiles: acceptedFilesBack,
		getRootProps: getRootPropsBack,
		getInputProps: getInputPropsBack,
		isFocused: isFocusedBack,
		isDragAccept: isDragAcceptBack,
		isDragReject: isDragRejectBack,
	} = useDropzone({
		onDrop: onDropBack,
		maxFiles: 1,
		minSize: 0,
		maxSize: 5242880,
		accept: {
			'image/jpg': [],
			'image/jpeg': [],
			'image/png': []
		},
	});

	const filesBack = acceptedFilesBack.map((file) => (
		<li key={file?.name}>
			{file?.name} - {file.size} bytes
		</li>
	));



	// const items = [{
	// 	id: 1,
	// 	label: 'Descripción del accidente',
	// 	autofocus: true,
	// 	name: 'description',
	// 	as: 'text_area',
	// 	type: 'text_area',
	// 	error: description,
	// 	meta: descriptionMeta
	// }]

	const isValid = (): boolean => {
		const validation = acceptedFilesBack.length > 0 && acceptedFilesBack.length > 0;
		return validation
	}


	return (
		<>
			{/* <img src={photo_gif} alt="photo_phone_gif" width='250px' height='250px' />
			<p style={{ textAlign: 'justify', fontWeight: 400, fontSize: '20px', color: '#212121' }}>
				Toma la foto a una distancia de 10 cm, frontal y de preferencia con la pantalla encendida
			</p> */}
			<div style={{
				background: '#F2FAFC',
				padding: '10px',
				width: '100%',
				borderRadius: '10px'
			}}>
				<strong style={{
					padding: 0,
					margin: 0
				}}>
					Importante
				</strong>
				<p style={{
					padding: 0,
					margin: 0
				}}>
					Recuerda que solo admite imágenes en formato .jpg o .png
				</p>
			</div>
			<div>Parte frontal*</div>

			<Container {...getRootPropsFront({ isFocusedFront, isDragAcceptFront, isDragRejectFront })}>
				<input {...getInputPropsFront()} />
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
					<p style={{ color: '#039ECC', fontSize: '16px', fontWeight: '600', margin: '16px' }}>
						{filesFront.length > 0 ? filesFront : 'SUBIR ARCHIVO'}
					</p>
					<img src={upload_logo} style={{ margin: '16px' }} />
				</div>
			</Container>

			<div>Parte trasera*</div>

			<Container {...getRootPropsBack({ isFocusedBack, isDragAcceptBack, isDragRejectBack })}>
				<input {...getInputPropsBack()} />
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
					<p style={{ color: '#039ECC', fontSize: '16px', fontWeight: '600', margin: '16px' }}>
						{filesBack.length > 0 ? filesBack : 'SUBIR ARCHIVO'}
					</p>
					<img src={upload_logo} style={{ margin: '16px' }} />
				</div>
			</Container>

			{/* <FormItemGenerate
				items={items}
				handleChange={handleChange}
				handleBlur={handleBlur}
			/> */}
			<ButtonInter
				variant='contained'
				type='submit'
				disabled={!isValid()}
				onClick={() => {
					navigate("/aviso")
					window.dataLayer.push({
						event: "TRUS_reparacion_image",
						description: description.value,
						boton_contacto: 'Continuar',
						tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium",//premium | gratuito 
						correo: userResponse?.data?.user?.email,
						telefono: userResponse?.data?.user?.phone,
					});
				}}>
				Continuar
			</ButtonInter>
		</>
	)
}

export default UploadImage