import React from 'react';

const EditIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.0205 9.26164C16.0205 9.06851 16.0972 8.8833 16.2338 8.74674C16.3704 8.61018 16.5556 8.53346 16.7487 8.53346C16.9418 8.53346 17.1271 8.61018 17.2636 8.74674C17.4002 8.8833 17.4769 9.06851 17.4769 9.26164V17.2717C17.4769 17.4648 17.4002 17.65 17.2636 17.7866C17.1271 17.9231 16.9418 17.9998 16.7487 17.9998H0.728672C0.535546 17.9998 0.350329 17.9231 0.213768 17.7866C0.0772075 17.65 0.000488281 17.4648 0.000488281 17.2717V1.25162C0.000488281 1.05849 0.0772075 0.873279 0.213768 0.736718C0.350329 0.600157 0.535546 0.523437 0.728672 0.523438H8.73869C8.93182 0.523438 9.11703 0.600157 9.25359 0.736718C9.39016 0.873279 9.46687 1.05849 9.46687 1.25162C9.46687 1.44475 9.39016 1.62996 9.25359 1.76652C9.11703 1.90309 8.93182 1.9798 8.73869 1.9798H1.45686V16.5435H16.0205V9.26164Z" fill="#039ECC" />
            <path d="M7.77473 10.2224L8.97302 10.051L16.3342 2.69088C16.4035 2.62389 16.4588 2.54374 16.4969 2.45513C16.535 2.36653 16.555 2.27122 16.5558 2.17479C16.5567 2.07835 16.5383 1.98272 16.5018 1.89346C16.4653 1.8042 16.4113 1.72311 16.3432 1.65492C16.275 1.58673 16.1939 1.5328 16.1046 1.49628C16.0154 1.45976 15.9197 1.44139 15.8233 1.44222C15.7269 1.44306 15.6316 1.4631 15.543 1.50116C15.4544 1.53922 15.3742 1.59455 15.3073 1.66392L7.94467 9.02405L7.77328 10.2224H7.77473ZM17.361 0.635507C17.5635 0.837849 17.7241 1.07811 17.8337 1.34255C17.9433 1.60699 17.9997 1.89043 17.9997 2.17668C17.9997 2.46292 17.9433 2.74636 17.8337 3.01081C17.7241 3.27525 17.5635 3.5155 17.361 3.71784L9.82998 11.2494C9.71892 11.3609 9.57467 11.4332 9.41893 11.4556L7.02235 11.7984C6.91066 11.8145 6.79677 11.8043 6.68971 11.7686C6.58265 11.733 6.48537 11.6729 6.40558 11.5931C6.32579 11.5133 6.2657 11.416 6.23005 11.309C6.19441 11.2019 6.18421 11.088 6.20025 10.9763L6.54303 8.57957C6.56504 8.424 6.6369 8.27976 6.74783 8.1685L14.2804 0.636959C14.6889 0.228489 15.243 -0.000976563 15.8207 -0.000976562C16.3984 -0.000976563 16.9525 0.228489 17.361 0.636959V0.635507Z" fill="#039ECC" />
        </svg>

    );
}

export default EditIcon;
