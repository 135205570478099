import React from 'react';
import { CustomButton } from '../Buttons/Buttons';
import IconsPaymentMethod from './IconsPaymentMethod';

type CustomButtonPaymentIconProps = {
    text?: string;
    icon?: React.ReactNode; 
}

const CustomButtonPaymentIcon: React.FC<CustomButtonPaymentIconProps> = ({ text, icon }) => {
    return <CustomButton style={{
        maxWidth: 580,
        width: '100%',
        justifyContent: text ? 'space-between' : 'center',
        borderRadius: 8,
        padding: 16,
        height: 54,
        textTransform: 'inherit',
        border: '1px solid var(--black-25, #C7C7C7)',
        background: 'var(--White, #FFF)',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 24

    }} onClick={() => {}} text={text || ''} endIcon={icon || <IconsPaymentMethod />} />
};

export default CustomButtonPaymentIcon;
