export const pageData: any = [
    [{
        title: "¿Qué es Inter.mx? ",
        description: "Somos un broker de seguros, es decir, un intermediario entre tú y las compañías de seguros, quienes son nuestros aliados, junto con las empresas a través de las cuales vendemos los seguros. Nuestra misión es ayudarte a encontrar el mejor seguro para ti ¡sin complicaciones!"
    }, {
        title: "¿Qué es #TeRegalamosUnSeguro?",
        description: " #TeRegalamosUnSeguro es una iniciativa que tiene como objetivo principal proteger a todos los mexicanos con un seguro de regalo para ellos, además de acercarlos al mundo de los seguros, dándoles la opción de elegir cómo quieren estar protegidos."
    }, {
        title: "¿Por qué es de regalo?",
        description: "Esta vez decidimos invertir en lo más importante, que eres tú, con el objetivo de darte la tranquilidad y seguridad que necesitas. Así, seguimos adelante con nuestra misión de proteger a todos los mexicanos."
    }, {
        title: "¿Cómo puedo acceder a la protección de #TeRegalamosUnSeguro?",
        description: "¡Es muy sencillo! Sólo debes registrarte en la página y activar tu cuenta, así tendras acceso al portal donde podrás ver todos tus beneficios."
    }, {
        title: "¿Cuál es la diferencia entre un seguro de regalo y premium?",
        description: "Tu seguro de regalo te da coberturas básicas para que conozcas lo que es estar seguro y tranquilo en todo momento. Por otro lado, con premium puedes tener acceso a una protección más completa ¡a un súper precio!"
    }, {
        title: "¿Por qué convertirme en cliente premium de #TeRegalamosUnSeguro?",
        description: "Al convertirte en cliente premium, podrás tener mayores beneficios y coberturas a un súper precio, ideal para cuidar tu seguridad y tu bolsillo."
    }, {
        title: "¿Cuáles son los requisitos para obtener #TeRegalamosUnSeguro?",
        description: "Para poder registrarte sólo debes ser mayor de edad y tener nacionalidad mexicana."
    }, {
        title: "¿Qué es y para qué sirve el portal de cliente?",
        description: "El portal de cliente será tu mejor aliado para disfrutar tu seguro al máximo, ya que en él podrás consultar tus coberturas, los datos de tu plan, solicitar servicios o realizar reclamaciones de tu seguro."
    }, {
        title: "¿Puedo registrarme si vivo fuera de México?",
        description: "Desafortunadamente nuestras coberturas sólo son válidas para personas de nacionalidad mexicana que vivan actualmente dentro del país."
    }, {
        title: "¿Cómo puedo pagar la versión premium de #TeRegalamosUnSeguro?",
        description: "Puedes hacer tu pago con cualquier tarjeta de crédito o débito, de forma mensual ¡Elige lo que más te convenga!"
    }],

    [{
        title: "¿Qué pasa si quiero cancelar mi plan premium?",
        description: "Si quieres cancelar tu plan, ¡no hay problema! Puedes hacerlo desde tu portal de cliente."
    }, {
        title: "¿Cómo renovar la versión premium de #TeRegalamosUnSeguro?",
        description: "La renovación es automática mientras sigas realizando tus pagos regularmente. "
    }, {
        title: "¿Qué pasa si dejo de pagar mi plan premium?",
        description: "Si por algún motivo te es difícil hacer los pagos de tu plan, tendrás 3 meses para ponerte al corriente. Después de esto tu plan se cancelará automáticamente. Recuerda que una vez que se haya cancelado tu plan, no es posible reactivarlo. "
    }, {
        title: "¿Quien paga por mi seguro de regalo?",
        description: "#TeRegalamosUnSeguro es una iniciativa pagada por inter.mx, pues queremos que todos los mexicanos prueben los beneficios de contar con un seguro."
    }, {
        title: "¿Cómo puedo obtener el primer mes de regalo en premium?",
        description: "Si aún no tienes cuenta en Te Regalamos Un Seguro, debes realizar tu registro en premium. Tendrás que ingresar los datos de tu tarjeta pero no se hará ningún cobro hasta el segundo mes. Recuerda que puedes cancelar tu plan en cualquier momento sin costo. Si ya estás registrado con tu seguro de regalo, sólo debes realizar el upgrade dentro de tu portal de cliente y podrás disfrutar del primer mes gratis."
    }, {
        title: "¿Quién respalda este programa? ",
        description: "Para poder llevar a cabo esta iniciativa contamos con el respaldo de aliadios increibles como lo son CHUBB, Zurich, Everhealth y ofertas Pin. "
    }, {
        title: "¿Quien paga por mi seguro de regalo?",
        description: "#TeRegalamosUnSeguro es una iniciativa de inter.mx que busca proteger a todos los mexicanos y que prueben la tranquilidad de estar asegurados, por eso decidimos invertir en tu tranquilidad regalándote un seguro SIN COSTO, en serio, ¡va por nuestra cuenta! "
    }, {
        title: "¿Durante cuánto tiempo es vigente mi seguro de regalo?",
        description: " Tu seguro tiene una vigencia de un año, después de este periodo podrás covertirte a Premium para disfrutar todos los beneficios adicionales que tenemos para ti ¡a un súper precio!"
    }, {
        title: "¿Por qué tengo que validar mi número de celular?",
        description: "Tu número de celular es una pieza clave en tu registro porque se convertirá en tu número de certificado, y dará validez a tu seguro. "
    }, {
        title: "¿Qué es el programa de descuentos?",
        description: "Es uno de los grandes beneficios de #TeRegalamosUnSeguro que te da cupones de hasta el 70% de descuento en miles de comercios, como restaurantes, hoteles, laboratorios, ¡y mucho más! Puedes encontrar tus cupones en el portal de cliente. Recuerda que debes presentarlos al momento de hacer tus compras. "
    }],

    [{
        title: "¿Cómo puedo hacer uso de mis cupones?",
        description: "Puedes usarlos desde tu portal de cliente. Sólo necesitas presentarlos en el establecimiento donde vayas a realizar tu compra. "
    }, {
        title: "¿Cada cuánto puedo hacer uso de mis cupones?",
        description: "¡El uso de tus cupones es ilimitado! Recuerda que los cupones pueden variar, y no siempre se mostrarán los mismos. "
    }, {
        title: "¿Qué es la Telemedicina gratuita e ilimitada?",
        description: "Es la nueva forma de tener consultas médicas de forma segura y cómoda. Estas son por videollamada con un médico a distancia, además puedes agendar las consultas que necesites ¡totalmente gratis! "
    }, {
        title: "¿Cómo agendo mi cita de Telemedicina?",
        description: "¡Es muy fácil! Puedes hacerlo desde tu portal de cliente, en la sección de Beneficios adicionales ingresa a Telemedicina y desde allí podrás elegir la fecha y hora disponible que prefieras. "
    }, {
        title: "¿Cómo puedo asegurar que mi experiencia sea óptima?",
        description: "Intenta estar en un espacio libre de ruido y distracciones, y asegúrate de estar conectado al wifi o contar con muy buena señal de red."
    }, {
        title: "¿Puedo tener más de un seguro de regalo? ",
        description: "Únicamente puedes tener un seguro de regalo, pero puedes registrarte en Premium en caso de que deses adquirir otro plan y obtener todos los beneficios que ofrece. "
    }, {
        title: "¿Cómo funciona el pago de medicamentos?",
        description: " Primero debes solicitar una teleconsulta (o consulta en línea) con un médico general dentro de tu portal de cliente. El médico, de ser necesario, te preescribira tu receta con los medicamentos que necesitas. En un lapso de 24 a 72 horas te llegará a tu correo tu cupón de canje para tus medicamentos hasta por $1000 pesos en la farmacia que se indique dentro del cupón. ¡Es muy importante que el código postal que ingreses en tu registro sea de tu domicilio! Tendrás 10 días hábiles para cambiar tus medicamentos, si no lo haces en este tiempo ya no será válido, y perderas la  cobertura ya que se dara por utilizada."
    }, {
        title: "¿Las recetas médicas son limitadas?",
        description: "La recetas médicas y las teleconsultas ¡son ilimitadas! Lo que esta limitado a cierto numero de eventos, dependiendo tu plan, son los cupones de medicamentos para canjearlos en farmacias seleccionadas."
    }, {
        title: "¿Qué pasa si no hay medicamentos o no quieren hacer válido mi cupón?",
        description: "Si por algún motivo no canjean tu cupón de medicamentos en la sucursal indicada, debes levantar un reporte desde tu portal de cliente para que te ayudemos a dar seguimiento a tu caso. ¡No te preocupes! Siempre hay una solución. "
    }, {
        title: "¿Qué estudios cubre el check-up en el seguro de regalo?",
        description: "Los estudios que puedes tener con tu seguro de regalo son: química sanguínea de 12 elementos, biometría hemática o exámen general de orina. Recuerda que tu seguro de regalo cubre un evento al año. "
    }],

    [{
        title: "¿Qué estudios cubre mi check-up en plan premium?",
        description: " Con tu seguro Premium puedes realizar los estudios médicos que te soliciten en tu consulta de telemedicina y te apoyamos con hasta $600 MXN para que los realices. ¡Tienes cobertura de 3 eventos al año!"
    }, {
        title: "¿Cómo puedo hacer uso de mi check-up médico?",
        description: " Primero debes solicitar una teleconsulta con médico general dentro de tu portal de cliente. El médico, de ser necesario, te solicitará que te realices ciertos estudios de laboratorio. En un lapso de 24 a 72 horas te llegará vía correo la orden para que acudas al laboratorio que se te indique a solicitar los estudios. Tendrás 10 días hábiles para usar tu orden, si no lo haces en este tiempo ¡ya no será válida!"
    }, {
        title: "¿Qué pasa si no me quieren hacer mi estudio en el laboratorio?",
        description: " Si por algún motivo no canjean tu cupún en la sucursal indicada, debes levantar un reporte desde tu portal de cliente para que te ayudemos a dar seguimiento a tu caso. ¡No te preocupes! Siempre hay una solución."
    }, {
        title: "¿Cómo solicito mi reembolso de gastos médicos?",
        description: "En este caso, primero tú pagas tu cuenta en el hospital. Después, con los documentos del checklist de siniestros, debes solicitar el reembolso por correo. Estos datos los puedes consultar en el portal de cliente o el certificado. Recuerda que te reembolsamos sólo el monto asegurado, y es un evento al año. No olvides que no se puede repartir el monto en otros eventos aunque no lo gastes en su totalidad. "
    }, {
        title: "¿Cómo funciona la cobertura de pérdidas orgánicas?",
        description: " Si desafortunadamente llegas a tener una pérdida orgánica, envía el checklist completo, que puedes consultar en tu portal de cliente, para que se te haga la indemnización en menos de 72 horas. Una vez que tu checklist esté completo, se te hará un reembolso. El monto depende de la parte de cuerpo que haya sido afectada. Tu cobertura incluye un evento al año."
    }, {
        title: "¿Cómo funciona la cobertura de invalidez?",
        description: " Debes enviar por correo el checklist de documentos que se solicita para comprobar la invalidez total o permamnete, este lo puedes consultar en tu portal de cliente. Una vez comprobada, se te realizará la transferencia con la cantidad de dinero que se indica en tu cobertura."
    }, {
        title: "¿Cómo puedo solicitar mi apoyo de incapacidad por accidente?",
        description: " Debes validar tu estancia en el hospital enviando los documentos del check list por correo, te apoyaremos con $220 pesos por día cubriendo hasta 30 días de tu estancia. Únicamente incluye un evento al año sin importar que permanezcas menos días de los que se indica en tu cobertura."
    }, {
        title: "¿Cómo puede mi familia solicitar la indemnización por muerte accidental?",
        description: " Para esta cobertura, es muy importante que registres a tu beneficiario dentro del portal de cliente, ya que le haremos llegar los documentos que debe enviar en caso del fallecimiento del titular para que solicite el monto indicado en la cobertura. De igual manera, en tu portal de cliente podrás encontrar el checklist de los documentos que necesitará tu beneficiario."
    }, {
        title: "¿Cómo puedo hacer uso de mis coberturas para celular?",
        description: "Puedes pedir la reparación de tu celular desde el portal de cliente, donde te daremos una guía y algunas opciones para que envíes tu celular a reparar, recuerda que una vez que adquieres tu seguro debes esperar 30 días para poder hacer uso de tus coberturas "
    }, {
        title: "¿Qué hacer en caso de que se rompa mi pantalla y cuento con el plan de regalo?",
        description: ' ¡No te preocupes! Sólo debes entrar a tu portal de cliente y dar clic en "reparación de pantalla". Aquí vas a verificar algunos de los datos para pedir la reparación, y así te daremos tu guía con la que podrás hacer el envío de tu celular para que lo reparen.'
    }],


    [{
        title: "¿Qué pasa si la reparación de la pantalla de mi celular rebasa el límite de mi cobertura?",
        description: " Si te encuentras en esta situación te recomendamos dos cosas: puedes cambiar tu plan a Premium para tener mayor cobertura, o pagar el excedente."
    }, {
        title: "¿Cómo funciona la cobertura de reparación de pantalla?",
        description: " La reparación de pantalla la hace directamente la aseguradora, por eso no es válida si la reparas en otros lugares. Recuerda siempre contactarnos en caso de incidente."
    }, {
        title: "¿Qué hago si me roban mi celular?",
        description: " Si esto pasa, primero respira y recuerda que estamos para ayudarte. Después, contacta a la aseguradora por los medios que encontrarás disponibles en el portal, además debes recopilar toda la información del checklist que podrás encontrar en la app."
    }, {
        title: "¿Qué tipo de celulares puedo proteger?",
        description: " Tu celular debe ser smartphone, no mayor a 3 años y adquirido sólo en México."
    }, {
        title: "¿Qué es el IMEI?",
        description: " El IMEI es el número que identifica tu celular como único, y nos permite verificar que sea válido."
    }, {
        title: "¿Cuántas veces puedo solicitar las coberturas de mi plan celular?",
        description: " Puedes pedir las coberturas de reparación y robo únicamente una vez al año, pero acuérdate de que las coberturas de telemedicina y descuentos son ilimitadas y de regalo."
    }, {
        title: "¿Cuánto tiempo tarda el proceso de reparación del celular?",
        description: "Normalmente tenemos un lapso de 96 horas a partir de que tu celular esté en los laboratorios del proveedor. Puede pasar que este tiempo aumente, pero no te perocupes, esto es derivado de las empresas de logística que son las responsables de recolectar y entregar tu teléfono.* "
    }, {
        title: "¿Qué pasa si dejo de pagar mi seguro premium y quiero usar mi cobertura?",
        description: " Cuando dejas de pagar tu plan premium, tus coberturas se suspenden, por eso no podrás usarlas. No olvides que tienes hasta tres meses para ponerte al corriente con los pagos antes de que se cancele tu plan. "
    }, {
        title: "¿Cómo puedo saber el estatus de mi reparación?",
        description: " Puedes consultarlo directamente en tu portal de cliente."
    }, {
        title: "¿Qué tipo de daños no cubre mi seguro gratuito?",
        description: " Tu seguro no cubre descarga eléctrica, robo o si se llega a mojar."
    }],


    [{
        title: "¿Qué es y por qué tengo que pagar un copago si se daña o rompe mi celular?",
        description: " El copago se debe hacer para que puedas activar tu cobertura de $4,000. Para hacer el pago, se te enviará un link para que puedas pagar antes de que recolecten tu celular."
    }, {
        title: "¿Qué es y por qué tengo que pagar un deducible si se daña o rompe mi celular?",
        description: "Este es un descuento del 20% que hace la aseguradora sobre el total de la indemnización. Por ejemplo, si tu celular es indemnizado por $5,000, tú vas a recibir $4,000. "
    }, {
        title: "¿Qué pasa cuando la reparación de mi celular excede el monto de mi seguro?",
        description: " Si esto llega a pasar, debes cubrir el costo excedente de la reparación. No olvides que el proveedor te mandará un link para que puedas hacer el pago. "
    }, {
        title: "¿Qué coberturas incluye el producto que va por cuenta de inter.mx?",
        description: " Rotura de pantalla hasta por $4,000, muerte accidental por $20,000, además de telemedicina ilimitada y club de descuentos en miles de comercios. "
    }, {
        title: "¿Qué coberturas incluye el producto premium?",
        description: " Rotura de pantalla hasta por $4,000, seguro de robo con violencia hasta por $10,000, muerte accidental por $20,000, telemedicina ilimitada y club de descuentos en miles de comercios. "
    }, {
        title: "¿Cómo funciona la cobertura de muerte accidental?",
        description: " Es muy importante que recuerdes lo siguiente: la muerte accidental debe ser provocada de manera fortuita, imprevista y externa (no aplica para enfermedades). Si la persona protegida sobrevive más de 90 días en el hospital, no se considera como muerte accidental. Por otro lado, la reclamación del monto se realizará por el beneficiario que haya protegido el titular, enviando los documentos necesarios a la aseguradora. Estos los encuentras en tu portal de cliente."
    }, {
        title: "¿Qué es robo con violencia?",
        description: " El robo debe ser por medio de la fuerza o violencia física o moral, además es muy importante que generes un acta ante el ministerio público para que puedas hacer tu proceso de cobertura. "
    }, {
        title: "¿Siempre debo enviar mi equipo celular?",
        description: " ¡Sí! Nuestros aliados dedicados a la reparación de equipos celulares son especialistas certificados por las marcas. Al ser centros especializados de reparación, ¡tu celular estará en buenas manos! "
    }, {
        title: "¿Cómo consigo la guía para enviar mi equipo a reparación?",
        description: " Tu guía te llegará a tu correo una vez que hayas hecho el copago y/o pago por excedente. "
    }, {
        title: "¿Debo respaldar mi equipo antes de la reparación?",
        description: " Así es, y es un paso muy importante ya que nuestros técnicos certificados cumplen la ley de proteccion de datos y esquemas de privacidad, por eso toda reparación implica el borrado completo de tu equipo y/o restauración de fábrica. "
    }],


    [{
        title: "¿Qué pasa si no recolectan mi equipo?",
        description: " Te recomendamos que la dirección y referencias sean lo más claras posibles, así las empresas de logística podrán recolectar más fácilmente tu equipo en casa. Debes poner mucha atención a su llegada ya que si pasan dos o tres intentos fallidos, perderás la recolección y el pago hecho previamente. "
    }, {
        title: "¿Qué pasa si no estoy en casa para recibir mi equipo?",
        description: " Te recomendamos que la dirección y referencias sean lo más claras posibles, así las empresas de logística podrán recolectar más fácilmente tu equipo en casa. Debes poner mucha atención a su llegada ya que si pasan dos o tres intentos fallidos, tu equipo será reenviado al centro de reparación, y para recuperarlo deberás cubrir los gastos de envío. "
    }, {
        title: "¿Por qué podrían cancelar mi reparación?",
        description: " Hay algunos motivos por los cuales inter.mx y sus proveedores pueden cancelar tu reparación: el IMEI registrado no corresponde con el equipo enviado al laboratorio; el equipo, marca y modelo no correspondan; que no sea una reparación de pantalla y el problema sea por descarga eléctrica o salpicaduras de agua; el IMEI esté alterado; falta de respeto al personal que se contacte contigo. "
    }, {
        title: "¿Dónde puedo consultar mi IMEI de mi celular?",
        description: " ¡Es muy sencillo! Sólo debes marcar en tu celular *#06# y se mostrara en tu pantalla. Si tu celular cuenta con 2 numeros debes resgistrar el IMEI 1. "
    }, {
        title: "¿Si roban mi teléfono qué monto recibiré?",
        description: " El monto dependerá del valor de tu celular y se le aplicará una depreciación del 17.5% por año, de igual manera, el deducible se descuenta del total del monto para evitar que tengas que realizar pagos. "
    }, {
        title: "¿Que sucede si ingreso un IMEI que no sea de mi celular?",
        description: " Si el IMEI que ingresas no corresponde con el IMEI de tu equipo, no podrás hacer uso de tu cobertura de rotura de pantalla. "
    }]
]