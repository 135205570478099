import React from "react";

function ElipseFinsus() {


    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="57"
            height="57"
            fill="none"
            viewBox="0 0 40 40"
        >
            <circle cx="20" cy="20" r="20" fill="#FF5F54"></circle>
        </svg>
    );
}

export default ElipseFinsus;
