import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { styled } from 'styled-components';

import FormContainer from '../Form/FormContainer';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BasicModal from "../../molecules/BasicModal/BasicModal";
import ManiPosesBody from "../../../assets/ManiErrorHandler/ManiPosesErrorHandler.jpg";
import CardAction from "../Card";

import { apiSuccess } from '../../../api/formSignInSlice';
import { 
    apiError as apiLoginError, 
    apiLoading as apiLoginLoading, 
    apiSuccess as apiLoginSuccess
} from '../../../api/loginSlice';
import useFetch from '../../../hooks/useFetch';
import { addAccessToken } from '../../../api/config';
import LogIn from '../Form/FormContent/LogIn';
import LoadingComponent from '../loading/loading';
import { fieldIsRequired } from '../../../utils/scrollToTop';

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`;

interface ILogin{
  redirectTo?: string;
}

const LogInForm: React.FC<ILogin> = ({redirectTo}) => {
    const navigate = useNavigate();
    const [open, setOpenModal] = useState<boolean>(false);
    const [ activeStep, setActiveStep ] = useState<number>(0);
    // const dispatch = useDispatch();
    const response = useSelector((state: any) => state.formSignIn.response) as any | null;
    // const data = useSelector((state: any) => state.formRegister.response) as any | null;
    
    // const tracking = useSelector((state: any) => state.tracking.response) as any | null;
    // const responseRegister = useSelector((state: any) => state.formRegister.response) as any | null;
    // const productsCards = useSelector((state: any) => state.productsCards.response) as any | null;
    // const currentValue = useSelector((state: any) => state.productsCards.currentValue) as any | null;
    // const signInResponse = useSelector((state: any) => state.signUp.response) as any | null;
    const loginResponse = useSelector((state: any) => state.login.response) as any | null;
    const loginLoading = useSelector((state: any) => state.login.loading) as any | null;
    const loginError = useSelector((state: any) => state.login.error?.response?.data) as any | null;
    
    const { fetchData } = useFetch();

    const membershipTypeModule = {
        signIn: {
            formSteps: 0, //starting from 0
            steps: [], // steps title
            titles: [], // content title 
            initialValues: {
                phone: '',
                password: '',
                passwordConfirm: ''
            }
        }
    }

    const renderStepContent = (step: number, errors: any, handleChange: any, handleBlur: any) => {
        switch (step) {
        case 0:
            return ( <LogIn handleChange={handleChange} handleBlur={handleBlur} errors={errors}/> );
        default:
            return null;
        }
    };
    const onClick = () => {
    setOpenModal(false);
    sessionStorage.setItem('userError', '0');
  };
  const onClick2 = () => {
    setOpenModal(false);
    sessionStorage.setItem('userError', '0');
    navigate("/");
  };

    const _handleSubmit = async(values: any, actions: any) => {
            logInWithCredentials();
    }

    const validationSchemaStep = Yup.object().shape({
      phone: Yup.string().phone('MX', 'Por favor, ingresa un número de teléfono válido en México').max(10, "maximo 10 caracteres").required(fieldIsRequired),
      password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).{10,}$/, "No cumple con las especificaciones para una contraseña segura").required(fieldIsRequired),
    })

    const validatorSchemaStepper = () => {
        return validationSchemaStep;
    }

    const logInWithCredentials = () => {
        sessionStorage.clear()
        fetchData('users/auth', 'POST', {
            event: 'login',
            content: {phone: response.phone, password: response.password}
        }, {}, apiLoginLoading, apiLoginSuccess, apiLoginError);
    }

    useEffect(() => {
        if(loginResponse && loginResponse?.data?.user){
            sessionStorage.setItem("access_token", loginResponse?.data?.token);
            
            const redirectFunc = async() => {
            try {
                    await addAccessToken(loginResponse?.data?.token || null);
                    if(redirectTo){
                      navigate(redirectTo, { replace: true });
                    }else{
                      navigate('/portal-del-cliente', { replace: true, state: { showModalUpsell: true } });
                    }
                } catch (error) {
                    sessionStorage.removeItem("access_token");
                }
            }

            redirectFunc();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ loginResponse ]);

    useEffect(() => {
        if(loginError && loginError?.data){
          setOpenModal(true);  
        }
    }, []);

    return (
        <FormWrapper>
            <BasicModal
          styleRoot={{
            maxWidth: 728,
            boxShadow: "none",
            gap: "2px",
            marginTop: "62px",
          }}
          styleContent={{
            margin: 0,
            padding: 0,
          }}
          disableBackdropClick={true}
          closeButton
          open={open}
          setOpenModal={setOpenModal}
          onClick={onClick}
          bodyDialog={
            <CardAction
              onClick={onClick}
              onClickSecondary={onClick2}
              buttonText={"Entendido"}
              buttonTextSecondary="Registrarme"
              cardContent={
                "¡Oops! Esta cuenta no esta registrada, asegurate de que hayas escrito correctamente tu correo y si aun no estas registrado te invitamos a que te sumes a #TeRegalamosUnSeguro."
              }
              imgSrc={ManiPosesBody}
              secundaryButton={true}
              style={{
                backgroundColor: "#039ECC",
                border: "1px solid #039ECC",
              }}
              styleTextContent={{
                fontSize: "17px",
              }}
              styleImg={{
                backgroundColor: "#C0E6F2",
                padding: 0,
                display: "flex",
                flexWrap: "wrap",
                alignContent: "flex-end",
              }}
              styleImgCard={{
                padding: 0,
              }}
              styleButton={{
                color: "#039ECC",
              }}
              styleButtonSecondary={{
                color: "#039ECC",
              }}
            />
          }
        />
            <LoadingComponent isLoaded={!loginLoading} isError={false} ErrorMessage='Hubo un error al cargar las coberturas' ></LoadingComponent>
            {!loginLoading && <FormContainer
                initialValueImportant
                buttonText="iniciar sesión"
                steps={membershipTypeModule.signIn.steps} 
                initialValues={membershipTypeModule.signIn.initialValues} 
                activeStep={activeStep} 
                setActiveStep={setActiveStep}
                title={membershipTypeModule.signIn.titles}
                renderStepContent={renderStepContent}
                _handleSubmit={_handleSubmit}
                validatorSchemaStepper={validatorSchemaStepper}
                localStorageOmit={['password']}
                formObserverKey='FormObserverSignIn'
                formObserverSlice={apiSuccess}
                localData={response}
            />}
        </FormWrapper>
    );
};

export default LogInForm;
