import React from 'react';
import HiringPaymentMembershipTemplate from '../../components/templates/HiringPaymentMembership';

type HiringPaymentMembershipProps = {

}
//DEPRECATED
const HiringPaymentMembership: React.FC<HiringPaymentMembershipProps> = () => {
    return <HiringPaymentMembershipTemplate />
};

export default HiringPaymentMembership
