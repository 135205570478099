import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import BasicModal from "../../../../components/molecules/BasicModal/BasicModal";
import CardAction from "../../../../components/organisms/Card";
import { MembershipColor } from "../../../../components/organisms/Membership/MembershipMainValues";
import ManiCelebrating from '../../../../assets/ManiArmsCrused/ManiArmsCrused.svg';
import { restartValueError } from "../../../../api/leadsSlice";

interface IModalWelcome {
  closeNewUserModal?: () => void;
}

type CurrentValueProps = {
  type: string;
  category: string;
}

const ModalUserAlreadyRegistrated: React.FC = ({ closeNewUserModal }: IModalWelcome) => {
  const [open, setOpenModal] = useState<boolean>(false);
  const getAlreadyRegitredUser = useSelector((state: any) => state.leads.error);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let currentValue: any = {
    ...useSelector((state: any) => state.productsCards.currentValue),
  };
  
  const [storage] = useState<CurrentValueProps>({
    type: currentValue.type,
    category: currentValue.category
  });

  useEffect(() =>{
    if(getAlreadyRegitredUser && getAlreadyRegitredUser?.response?.data?.data?.message === "Homonimo encontrado o persona ya registrada"){
        setOpenModal(true);
        dispatch(restartValueError());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAlreadyRegitredUser]);

  return (  
    <BasicModal
      styleRoot={{
        maxWidth: 728,
        boxShadow: "none",
      }}
      styleContent={{
        margin: 0,
        padding: 0,
      }}
      disableBackdropClick={true}
      closeButton
      onClick={() => {
        if (closeNewUserModal) {
          closeNewUserModal()
        }
      }}
      open={open}
      setOpenModal={setOpenModal}
      bodyDialog={<CardAction
        onClick={() => {
          setOpenModal(false);
          navigate('/iniciar-sesion')
          if (closeNewUserModal) {
            closeNewUserModal()
          }
        }}
        onClickSecondary={() => window.open('https://intermx-digital.atlassian.net/servicedesk/customer/portal/8', '_blank')}
        buttonText={"Iniciar sesión"}
        buttonTextSecondary="Necesito ayuda"
        secundaryButton
        cardContent={
            <span style={{
              fontSize: 20,
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal'
            }}>Parece que ya tenemos un usuario registrado con la información que ingresaste. Si estás interesado en adquirir {storage.category === 'celular' ? 'Celular' : 'Salud'} Protect, puedes hacerlo a través de tu portal de cliente.
              <br></br>
            </span>  
        }
        imgSrc={ManiCelebrating}
        style={{
          backgroundColor: MembershipColor.free,
          border: MembershipColor.free,
        }}
        styleTextContent={{
          fontSize: "17px",
        }}
        styleImg={{
          borderRadius: '8px 8px 0px 0px',
          backgroundColor: 'var(--azul-25, #C0E6F2)',
          padding: 0,
          display: "flex",
          flexWrap: "wrap",
          alignContent: "flex-end",
        }}
        styleImgCard={{
          padding: 0,
        }}
        styleButton={{
            borderRadius: 8,
            border: '2px solid var(--Azul-Inter, #039ECC)',
            background: 'var(--White, #FFF)',
            color: MembershipColor.free
        }}
        styleButtonSecondary={{
            color: '#FFFFFF',
            backgroundColor: `var(--azul-25, ${MembershipColor.free})`,
            border: 'none'
        }}
      />}
    />
  )
}

export default ModalUserAlreadyRegistrated;