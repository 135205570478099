/* eslint-disable no-restricted-globals */
import React, {
  useContext
} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomTypography } from "../../atoms/Label/Label";
import { styled } from "styled-components";
import SignInForm from "../SignInForm";
import { ContextAuth0 } from "../../../auth/contextAPIProvider";
import { FacebookIcon } from "../../../assets/SocialMediaIcons/FacebookIcon";
import { GoogleAuth2Icon } from "../../../assets/SocialMediaIcons/Google-auth2";
import { IStorageMembershipData } from "../../../utils/localStorageKeys";
import { useSelector } from "react-redux";
import { CustomButton } from "../../atoms/Buttons/Buttons";
import { ArrowBack } from "@mui/icons-material";
import BASE_CONFIG from "../../../env/env";

const LayOutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  max-width: 558px;
  margin: auto;
`;

const SocialButton = styled.button`
  display: flex;
  padding: 14px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #efefef;
  font-weight: 600;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  cursor: pointer;
  text-transform: uppercase;
  `;

type CreateAccountProps = {};

const CreateAccount: React.FC<CreateAccountProps> = () => {
  // const [signIn, setSignIn] = useState("landingSignIn");
  const navigate = useNavigate();

  const webAuth: any = useContext(ContextAuth0);
  const location = useLocation();

  let storage: IStorageMembershipData = {
    ...useSelector((state: any) => state.productsCards.currentValue),
  };

  React.useEffect(() => {
    if (sessionStorage?.access_token) {
      navigate('/portal-del-cliente', { replace: true, state: { showModalUpsell: true } });
    } else if (!location?.state?.path && location.pathname === '/crear-cuenta') {
      navigate('/registrate/1', { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const userData = JSON.parse(sessionStorage.getItem('user-storage')!);
  const allianceSettingResponse = useSelector((state: any) => state.allianceSetting.response);

  const handleSignUpLoginRedirect = (connectTo: string) => {
    window.dataLayer.push({
      event: 'TRUS_activar',
      option: 'Google',
      correo: userData?.correo,
      telefono: userData?.telefono,
      tipo_seguro: storage.type == "free" ? "De regalo" : "Premium",
      seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
      alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
    })
    webAuth && webAuth.authorize({
      connection: connectTo,
      prompt: 'login',
      redirect_uri: `${BASE_CONFIG.base}/redirect`,
      scope: 'openid profile email',
      responseType: 'code token id_token',
      state: location.pathname.includes('/registrate') ? 'signup' : 'login'
    })
  }

  return (
    <React.Fragment>
      <LayOutContainer>
        <p style={{ fontSize: '12px', padding: 12, marginTop: '0px', textAlign: "left", lineHeight: "24px", borderRadius: 8, backgroundColor: window.location.pathname.includes('finsus') ? 'rgba(35, 34, 63, 0.10)' : 'var(--azul-5, #F2FAFC)' }}>
          <strong>Importante</strong>
          <br />
          <strong>En un lapso máximo de 72 horas podrás descargar tu certificado en tu portal de cliente</strong>  en la sección de documentos adicionales.
        </p>
          <SignInForm />
          <React.Fragment>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: 24,
                alignItems: "center",
              }}
            >
              <CustomTypography
                style={{
                  fontWeight: 400,
                  fontSize: 18,
                  fontStyle: "normal",
                }}
              >
                O
              </CustomTypography>


              <SocialButton
                onClick={() => handleSignUpLoginRedirect('google-oauth2')}
                style={{ border: "none", backgroundColor: "#EFEFEF" }}
              >
                <GoogleAuth2Icon />
                Actívala con Gmail
              </SocialButton>
              <span style={{
                fontWeight: 400,
                fontSize: '14px'
              }}>Para activar tu cuenta con Gmail usa el mismo correo con el que te registraste</span>
            </div>
          </React.Fragment>
      </LayOutContainer>
    </React.Fragment>
  );
};

export default CreateAccount;
