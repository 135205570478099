import React from 'react';
import CustomContainer from '../../organisms/Container/Container';
import { styled } from 'styled-components';
import { CustomTypography } from '../../atoms/Label/Label';
import { Avatar } from '@mui/material';
import lottieFile from "../../../assets/LottieFileIcon/lottieFile.svg";
import { TimeLineBasic } from '../../organisms/TimeLineTrackingStatus';
import { OutlinedButton, PrimaryButton } from '../../molecules/CellPhoneRepairButtons';
import { useNavigate } from 'react-router-dom';
import { IStorageMembershipData } from '../../../utils/localStorageKeys';
import {useSelector} from 'react-redux'

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 85px;
  gap: 40px;
  align-items: center;
  max-width: 560px;
`;

const RecomendationComponent: React.FC = () => {
    const navigate = useNavigate();

    let storage: IStorageMembershipData = {
        ...useSelector((state: any) => state.productsCards.currentValue),
      };

    const userData = JSON.parse(sessionStorage.getItem('user-storage')!);
    const userResponse = useSelector((state: any) => state.user.response);

    React.useEffect(() => {
        window.dataLayer.push({
            'event': 'TRUS_reparacion_recomendaciones_v',
            tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium" ,//premium | gratuito
            'correo': userResponse?.data?.user?.email,
            'telefono': userResponse?.data?.user?.phone,
            'page_location': window.location.href,
           });
    }, [])

    return (
        <React.Fragment>
            <CustomContainer childComponent={<ComponentWrapper>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 24, alignItems: 'center' }}>
                    <CustomTypography
                        item={"¡Tu reparación ya está programada!, te dejamos estas recomendaciones para que todo salga perfecto"}
                        style={{
                            fontSize: 24,
                            textAlign: 'center',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            textTransform: 'inherit',
                            letterSpacing: '0.5px'
                        }}
                    />
                    <Avatar
                        style={{
                            maxWidth: 327,
                            width: 'auto',
                            height: 'auto'
                        }}
                        alt="Remy Sharp"
                        src={lottieFile}
                    />
                    <TimeLineBasic />
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        maxWidth: 558,
                        gap: 16
                    }}>
                        <PrimaryButton text={"Entendido"} onClick={() => {
                            navigate("/portal-del-cliente")
                            window.dataLayer.push({
                                event: 'TRUS_reparacion_recomendaciones',
                                boton_contacto:'Confirmar Reparacion',
                                tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium" ,//premium | gratuito
                                telefono: userResponse?.data?.user?.phone,
                                correo: userResponse?.data?.user?.email,
                            })
                        }} />
                      {/*  <OutlinedButton text={"Talvez después"} onClick={() => {
                            navigate("/portal-del-cliente")
                            window.dataLayer.push({
                                event: 'TRUS_reparacion_recomendaciones',
                                boton_contacto:'Tal vez despues',
                                tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium" ,//premium | gratuito
                                telefono: userResponse?.data?.user?.phone,
                                correo: userResponse?.data?.user?.email,
                            })
                        }} />*/}
                    </div>
                </div>
            </ComponentWrapper>} />
        </React.Fragment>
    );
};

export default RecomendationComponent;
