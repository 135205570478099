import React from "react";
import CustomContainer from "../../organisms/Container/Container";
import NewPassword from "../../organisms/NewPassword";
const NewPasswordTemplate: React.FC = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 40 }}>
            <CustomContainer childComponent={<NewPassword />} />
        </div>
    );
};

export default NewPasswordTemplate;