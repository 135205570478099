import React, { useEffect } from 'react';
import styled from 'styled-components';
import { MembershipCategoryType, MembershipColor, MembershipSubType, MembershipType } from '../../../Membership/MembershipMainValues';
import BasicModal from '../../../../molecules/BasicModal/BasicModal';
import CardAction from '../../../Card';
import { useSelector } from 'react-redux';
import { IStorageMembershipData } from '../../../../../utils/localStorageKeys';
import { IUserData } from '../../interfaces/user';
import { useNavigate } from 'react-router-dom';
import { ModalValues } from './benefit.item';

const BenefitModalContainer = styled.div`
    
`
const BenefitModalContent = styled.div`

`
interface BenefitModalProps {
    modalData: ModalValues,
    open: boolean;
    setOpen: (state: boolean) => void,
    type: MembershipType,
    subType: MembershipSubType,
    category: MembershipCategoryType,
    close: () => void,
}

const BenefitModalComponent: React.FC<BenefitModalProps> = ({
    category,
    open,
    setOpen,
    type,
    subType,
    close,
    modalData
}) => {
    const navigate = useNavigate();
    const storage: IStorageMembershipData = useSelector((state: any) => state.productsCards.currentValue);
    const userResponse: IUserData = useSelector((state: any) => state.user.response?.data);
    const colorPrimary: string = MembershipColor.free;
    const colorSecondary: string = MembershipColor.freeSecondary;
    const colorBackground: string = MembershipColor.freeOpacity;
    useEffect(() => {
        setOpen(open);
    }, [open]);

    return (userResponse && storage && <BenefitModalContainer>
        <BasicModal
            styleRoot={{
                maxWidth: 728,
                boxShadow: "none",
                marginTop: '62px'
            }}
            styleContent={{
                margin: 0,
                padding: 0,
            }}
            disableBackdropClick={true}
            closeButton
            open={open}
            setOpenModal={setOpen}
            bodyDialog={<CardAction
                onClick={() => {
                    window.dataLayer.push({
                        'event': 'TRUS_espera_cobertura',
                        tipo_seguro: type == "free" ? 'De regalo' : 'Premium',//premium | De regalo
                        seguro: storage.category === "celular" ? 'Celular Protect' : 'Salud Protect',
                        'correo': userResponse.user.email,
                        boton_contacto: modalData.buttonText,
                        telefono: userResponse.user.phone,
                    });
                    if (modalData.path !== undefined) {
                        navigate(modalData.path);
                    } else {
                        setOpen(false)
                    }
                }}
                buttonText={modalData.buttonText}
                imgSrc={modalData.imageAvatar}
                style={{
                    backgroundColor: colorPrimary,
                    borderRadius: '0 0 8px 8px',
                    justifyContent: 'space-around',
                    border: 'none',
                    color: '#000'
                }}
                styleTextContent={{
                    color: '#FFF'
                }}
                styleButton={{
                    color: colorPrimary,
                    borderRadius: '8px',
                    background: '#FFFFFF',
                    border: 'none'
                }}
                styleImg={{
                    backgroundColor: colorSecondary,
                    padding: 0,
                    display: "flex",
                    flexWrap: "wrap",
                    alignContent: "flex-end",
                }}
                setOpenModal={setOpen}
                cardContent={
                    <BenefitModalContent>
                        {modalData.content({ fullname: userResponse.user.name + '  ' + userResponse.user.middle_name + ' ' + userResponse.user.last_name })}
                    </BenefitModalContent>}
                cardType={type}
                subType={subType}
                cardCategory={category}
                cardDescription={modalData.description}
                cardTitle={category === "celular" ? "Seguro Inter Celular" : "Seguro Inter Salud"}
            />}
        />
    </BenefitModalContainer>)
}

export default BenefitModalComponent
