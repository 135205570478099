import React from "react";
import CustomContainer from "../../organisms/Container/Container";
import ErrorLogin from "../../organisms/ErrorLogin";
const ErrorLoginTemplate: React.FC = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 40 }}>
            <CustomContainer childComponent={<ErrorLogin />} />
        </div>
    );
};

export default ErrorLoginTemplate;
