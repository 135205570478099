import React, { useEffect } from 'react';
import RegistrationForm from '../../organisms/AllianceRegisterForm';
import CustomContainer from '../../organisms/Container/Container';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const AllianceRegisterComponent: React.FC = () => {
    const navigate = useNavigate();
    const { idStepper } = useParams();
    const currentValue = useSelector(
        (state: any) => state.productsCards.currentValue
    ) as any | null;

    useEffect(() => {
        if (!currentValue?.category) {
            navigate("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<CustomContainer childComponent={<RegistrationForm />} />);
};

export default AllianceRegisterComponent;
