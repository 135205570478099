/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  apiSuccess as apiTrackingSuccess
} from '../../api/trackingSlice';
import {
  apiSuccess as apiRedirectTrackingIdSuccess
} from '../../api/trackingIdSlice';
import { apiLoading, apiSuccess, apiError } from '../../api/trackingIdSlice';
import { apiSuccess as apiSuccessRegister } from '../../api/formRegisterSlice';
import { 
  apiLoading as apiLoadingProducts, 
  apiSuccess as apiSuccessProducts, 
  apiError as apiErrorProducts,
  setCurrentValue as setCurrentValueProducts 
} from '../../api/productsSlice';
import useFetch from '../../hooks/useFetch';
import {
  apiLoading as LoadingGadgets,
  apiSuccess as SuccessGadgets,
  apiError as ErrorGadgets
} from '../../api/gadgetsSlice';
import {
  apiLoading as LoadingHealth,
  apiSuccess as SuccessHealth,
  apiError as ErrorHealth
} from '../../api/healthSlice';
import LoadingComponent from '../../components/organisms/loading/loading';

const RedirectTrackingId: React.FC = () => {
  // Implementa el componente aquí
  const location = useParams();
  const dispatch = useDispatch();
  const navigate  = useNavigate();
  const { fetchData } = useFetch();
  const responsegetLeads = useSelector((state: any) => state.trackingId.response) as any | null;
  const currentValueProducts = useSelector((state: any) => state.productsCards.currentValue) as any | null;
//   const responseProducts = useSelector((state: any) => state.productsCards.response) as
//     | any
//     | null;
  const responseGadgets = useSelector((state: any) => state.gadgets.response) as any | null;

  const createTrackingId = () => {
    const createTrackingIdPersistant = {
      "response":{
         "code":200,
         "ok":true,
         "msg":"ok",
         "data":{
            "data":{},
            "navigator":"Google Chrome Generated",
            "RedirectTrackingIded":false,
            "created_at":"2023-11-03T21:53:07.974Z",
            "tracking_id":location?.id,
            "status":false
         }
      },
      "loading":false,
      "error":null
    };

    dispatch(apiTrackingSuccess(createTrackingIdPersistant?.response))
    fetchData(`/users/leads/get-one-lead/${location?.id}`, 'GET', {}, {}, apiLoading, apiSuccess, apiError)
  }

  const fetchingDataHealth = () => {
    return fetchData('catalogs/health', 'GET', {}, {}
        , LoadingHealth, SuccessHealth, ErrorHealth);
  }

  const fetchingDataGadgets = () => {
    return fetchData('catalogs/gadgets', 'GET', {}, {}
      , LoadingGadgets, SuccessGadgets, ErrorGadgets);
  }

  const fetchingDataProducts = () => {
    const url: string = 'catalogs/products';

    return fetchData(url, 'GET', {}, {}, apiLoadingProducts, apiSuccessProducts, apiErrorProducts);
  };

  useEffect(() => {
    if(location?.id){
      createTrackingId();
      fetchingDataProducts();
    }
  }, []);

  useEffect(()=>{
    if(responsegetLeads?.ok){
        let leads = {...responsegetLeads.data};
        leads['otp'] = "";
        localStorage.setItem("id_model", leads?.id_model)
        localStorage.setItem("id_line", leads?.id_line)
        localStorage.setItem("id_brand", leads?.id_brand)

      dispatch(apiRedirectTrackingIdSuccess(responsegetLeads))
      dispatch(apiSuccessRegister(leads));
    }
  }, [responsegetLeads]);

//   useEffect(() => {
//     if(responseProducts){
//       console.log(responseProducts)
      
//     }
//   }, [responseProducts]);

  useEffect(() => {
    if(responseGadgets?.ok && responsegetLeads?.data){
      const { benefit_id, plan_id, tier } = responsegetLeads?.data;
    //   let getBenfitId = null;

    //   const verifyFreemium = responseGadgets.data.freemium.benefits.filter((benefit: any) => benefit.benefit_id === benefit_id)
    //   const verifyPremium = responseGadgets.data.premium.benefits.filter((benefit: any) => benefit.benefit_id === benefit_id)
      
    //   if(verifyFreemium.length > 0){
    //     getBenfitId = verifyFreemium;
    //   } else {
    //     getBenfitId = verifyPremium;
    //   }


    //   console.log(getBenfitId, responsegetLeads)
      
      const sendDataLocalStorage = {
          type: tier === 'premium' ? 'premium' : 'free',
          subtype: tier,
          category: plan_id === "P01" ? 'celular' : 'salud',
          benefit_id,
          plan_id
      };

      dispatch(setCurrentValueProducts(sendDataLocalStorage));
    }
  }, [responseGadgets, responsegetLeads]);

  useEffect(() => {
    if(currentValueProducts && responsegetLeads?.data){
        const { step } = responsegetLeads?.data;
        if(currentValueProducts.category === "celular"){
          fetchingDataGadgets();
        }
        else if(currentValueProducts.category === "salud"){
          fetchingDataHealth();
        }
        else {
          throw Error("No se encontro una categoria valida")
        }

        navigate(`/registrate/${(step + 1)}`, {state: { isRestartingProccess: true }});
    }
  }, [currentValueProducts, responsegetLeads]);
  
  return (
    <React.Fragment>
        <LoadingComponent 
            isLoaded={!true} 
            interTextMessage={'Te estamos redirigiendo a tu pagina'} 
        />
    </React.Fragment>
  );
};

export default RedirectTrackingId;
