import React from "react";

function BorderLinePremium({isDesktop}: any) {
  const width = isDesktop ? '526' : '294';
  
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height="2" viewBox={`0 0 ${width} 2`} fill="none">
        <path d={`M0 1H${width}`} stroke="#E6B331"/>
    </svg>
  );
}

export default BorderLinePremium;