import React from 'react';
import { useField } from 'formik';
import FormItemGenerate from '../../../atoms/FormItems/FormItemGenerate';
import { styled } from 'styled-components';
import { CustomButton } from '../../../atoms/Buttons/Buttons';
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

type LogInProps = {
    errors?: any;
    handleChange?: any;
    handleBlur?: any;
}

const ContentStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
`;

const LogIn: React.FC<LogInProps> = ({ handleChange, handleBlur }) => {
    const navigate = useNavigate();
    const [phone, phoneMeta] = useField("phone");
    const [password, passwordMeta] = useField("password");

    const items = [{
        id: 1,
        label: 'Teléfono',
        name: 'phone',
        as: 'textField',
        error: phone,
        meta: phoneMeta,
        autofocus: true
    },
    {
        id: 2,
        label: 'Contraseña',
        name: 'password',
        as: 'textField',
        type: 'password',
        error: password,
        meta: passwordMeta
    }];

    const onForgotPassword = () => {
        navigate('/olvidaste-contrasena')
        window.dataLayer.push({
            event: 'TRUS_olvidar_contraseña',
            telefono: phone.value,
            boton_contacto: 'Olvide mi contraseña',
        });
    }
    return (
        <ContentStyled>
            <FormItemGenerate
                items={items}
                handleChange={handleChange}
                handleBlur={handleBlur}
            />
            {/*  <a
                href="https://intermx-digital.atlassian.net/servicedesk/customer/portal/8/group/34/create/100"
                rel="noopener noreferrer"
                target='_blank'
            >*/}
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                <CustomButton
                    text={"Olvidé mi contraseña"}
                    onClick={() => onForgotPassword()}
                    size={"medium"}
                    variant={"contained"}
                    style={{
                        color: "#039ECC",
                        backgroundColor: "transparent",
                        fontSize: "16px",
                        fontWeight: "500",
                        fontStyle: "normal",
                        padding: "0px",
                        minWidth: "64px",
                        border: "none",
                        textTransform: "inherit",
                        width: "auto",
                        justifyContent: "flex-end",
                        textWrap: "nowrap"
                    }}
                />
            </Box>
            {/*  </a>*/}

        </ContentStyled>
    )
};

export default LogIn;
