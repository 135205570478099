import React, { useEffect, useState, useLayoutEffect } from 'react';
import * as Yup from 'yup';
import { styled } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowBack } from '@mui/icons-material';

import FormContainer from '../../organisms/Form/FormContainer';
import { CustomButton } from '../../atoms/Buttons/Buttons';
import DatosDeBeneficiario from '../../organisms/Form/FormContent/DatosDeBeneficiario';
import { CustomTypography } from '../../atoms/Label/Label';
import { fieldIsRequired, scrollToTop } from '../../../utils/scrollToTop';
import useFetch from '../../../hooks/useFetch';
import LoadingComponent from '../../organisms/loading/loading';
import { apiSuccess, restartValue } from "../../../api/formRegisterSlice";
import MemberShipSlider from '../../MeetOurMemberships/MembershipSlider';
import { setCurrentValue } from '../../../api/productsSlice';
import {
    apiLoading as apiNewProductRegisterLoading,
    apiSuccess as apiNewProductRegisterSuccess,
    apiError as apiNewProductRegisterError,
    restartValue as restartValueRegister
} from '../../../api/newProductRegisterSlice';
import MembershipSummaryCard from '../../organisms/Membership/MembershipCard/MembershipCard';
import MakePaymentTemplate from '../MakePayment';
import { MembershipCategoryType } from "../../organisms/Membership/MembershipMainValues";
import DatosDelCelular from '../../organisms/Form/FormContent/DatosDeCelular';
import { Box, Container, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";

type Props = {
    maxWidth: string;
}

const FormWrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 80px;
  gap: 24px;
  align-items: flex-start;
  max-width: ${(props: any) => props.maxWidth};
`;

enum SubtypeCurrentValue {
    free = 'free',
    premium = 'premium'
}

const NewProductRegister: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useState<number>(0);
    const { breakpoints } = useTheme();
    const isMobile = useMediaQuery(breakpoints.down("sm"));
    const isTablet = useMediaQuery(breakpoints.down("md"));
    const response = useSelector((state: any) => state.formRegister.response) as any | null;
    const loading = useSelector((state: any) => state.formRegister.loading) as any | null;
    const error = useSelector((state: any) => state.formRegister.error) as any | null;

    const registerSuccess = useSelector((state: any) => state.newProductRegister.response) as any | null;
    const loadingRegister = useSelector((state: any) => state.newProductRegister.loading) as any | null;
    const errorRegister = useSelector((state: any) => state.newProductRegister.error) as any | null;

    const currentValue = useSelector(
        (state: any) => state.productsCards.currentValue
    ) as any | null;
    let membershipType: MembershipCategoryType = currentValue?.category?.toLowerCase();
    const membershipSubtype: SubtypeCurrentValue = currentValue?.type;
    const [buttonNextHidded, setButtonNextHidded] = useState<boolean>(true);
    const tracking = useSelector((state: any) => state.tracking.response) as
        | any
        | null;
    const { fetchData } = useFetch();
    const matches = useMediaQuery('(min-width:600px)');
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [styledCustomized, setStyledCustomized] = useState('558px');

    const renderStepContent = (step: number, errors: any, handleChange: any, handleBlur: any) => {
        setStyledCustomized('558px');

        switch (step) {
            case 0:
                setButtonNextHidded(false)
                return (<MemberShipSlider
                    newProductRegistration={true}
                    typeCurrent='free'
                    title='Conoce nuestros seguros' />);
            case 1:
                setButtonNextHidded(isMobile === false && isTablet === false ? false : true);
                setStyledCustomized(isMobile === false && isTablet === false ? '946px' : '558px')
                return (<MembershipSummaryCard onlyCardPresentation />);
            default:
                return null;
        }
    };

    const renderStepContentCelular = (step: number, errors: any, handleChange: any, handleBlur: any) => {
        setStyledCustomized('558px');

        switch (step) {
            case 0:
                setButtonNextHidded(false)
                return (<MemberShipSlider
                    newProductRegistration={true}
                    typeCurrent='free'
                    title='Conoce nuestros seguros' />);
            case 1:
                setButtonNextHidded(true)
                return (
                    <React.Fragment>
                        <CustomTypography
                            item={"Para obtener tu seguro, ayúdanos con los datos de tu celular"}
                            style={{
                                display: 'block',
                                fontSize: !matches ? 16 : 28,
                                textAlign: 'center',
                                fontWaight: 700,
                                fontStyle: 'normal',
                                lineHeight: 'normal',
                                textTransform: !matches ? 'inherit' : '',
                                alignSelf: 'stretch',
                                textWrap: 'balance'
                            }}
                        />
                        <DatosDelCelular
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                        />
                    </React.Fragment>
                );
            default:
                return null;
        }
    };

    const renderStepContentPremium = (step: number, errors: any, handleChange: any, handleBlur: any) => {
        setStyledCustomized('558px');

        switch (step) {
            case 0:
                setButtonNextHidded(false)
                return (
                    <Box sx={{ maxWidth: '558px' }}>
                        <MemberShipSlider
                            newProductRegistration={true}
                            typeCurrent='free'
                            title='Conoce nuestros seguros' /></Box>);
            case 1:
                setButtonNextHidded(isMobile === false && isTablet === false ? false : true);
                setStyledCustomized(isMobile === false && isTablet === false ? '946px' : '558px')
                return (<MembershipSummaryCard onlyCardPresentation />);

            case 2:
                setButtonNextHidded(false)
                setStyledCustomized(isMobile === false && isTablet === false ? '946px' : '558px')
                return (<MakePaymentTemplate handleChange={handleChange} onlyCardPresentation newProductRegistration setActiveStep={setActiveStep} />);
            default:
                return null;
        }
    };

    const renderStepContentPremiumCelular = (step: number, errors: any, handleChange: any, handleBlur: any) => {
        setStyledCustomized('558px');

        switch (step) {
            case 0:
                setButtonNextHidded(false)
                return (<MemberShipSlider
                    newProductRegistration={true}
                    typeCurrent='free'
                    title='Conoce nuestros seguros' />);
            case 1:
                setButtonNextHidded(true)
                return (
                    <React.Fragment>
                        <CustomTypography
                            item={"Para obtener tu seguro, ayúdanos con los datos de tu celular"}
                            style={{
                                display: 'block',
                                fontSize: !matches ? 16 : 28,
                                textAlign: 'center',
                                fontWaight: 700,
                                fontStyle: 'normal',
                                lineHeight: 'normal',
                                textTransform: !matches ? 'inherit' : '',
                                alignSelf: 'stretch',
                                textWrap: 'balance'
                            }}
                        />
                        <DatosDelCelular
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                        />
                    </React.Fragment>
                );
            case 2:
                setButtonNextHidded(false)
                setStyledCustomized(isMobile === false && isTablet === false ? '946px' : '558px')
                return (<MakePaymentTemplate handleChange={handleChange} onlyCardPresentation newProductRegistration setActiveStep={setActiveStep} />);
            default:
                return null;
        }
    };

    const membershipTypeModule = {
        salud: {
            free: {
                formSteps: 1, //starting from 0
                steps: [], // steps title
                titles: [], // content title
                initialValues: {
                    category: '',
                    cardType: '',
                    benefitId: '',
                    planId: ''
                }
            },
            premium: {
                formSteps: 2, //starting from 0
                steps: [], // steps title
                titles: [], // content title
                initialValues: {
                    category: '',
                    cardType: '',
                    benefitId: '',
                    planId: ''
                }
            }
        },
        celular: {
            free: {
                formSteps: 1, //starting from 0
                steps: [], // steps title
                titles: [], // content title
                initialValues: {
                    category: '',
                    cardType: '',
                    benefitId: '',
                    planId: '',
                    marca: '',
                    modelo: '',
                    descripcion: ''
                }
            },
            premium: {
                formSteps: 2, //starting from 0
                steps: [], // steps title
                titles: [], // content title
                initialValues: {
                    category: '',
                    cardType: '',
                    benefitId: '',
                    planId: '',
                    marca: '',
                    modelo: '',
                    descripcion: ''
                }
            }
        }
    }

    useLayoutEffect(() => {
        /**
         * Reiniciamos en redux el slice de registro
         * por si no se elimino anteriormente.
         */

        if (response && registerSuccess) {
            dispatch(restartValue());
            dispatch(restartValueRegister());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        /**
         * Esto es para modificar de forma facil el currentValue y hacer pruebas con
         * los productos que tenemos, como celular/salud - premium/freemium por ejemplo
         */
        // const data = {
        //     type: "free", // premium -free
        //     subtype: "freemium", // premium - freemium
        //     category: "celular" // celular-salud
        // }

        // dispatch(setCurrentValue(data));
    }, []);

    useEffect(() => {
        if (registerSuccess?.ok) {
            sessionStorage.removeItem('modalWelcomeToUser');
            navigate('/portal-del-cliente', { state: { newPlan: true, planId: currentValue?.plan_id } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registerSuccess]);

    const _submitForm: any = async () => {
        // await _sleep(1000);
        // actions.setSubmitting(true);
        const content: any = {
            "tracking_id": tracking?.data?.tracking_id,
            "tier": currentValue.type === "free" ? 0 : 1,
            "plan_id": currentValue.plan_id,
            "benefit_id": currentValue.benefit_id
        }

        if (currentValue?.category === "celular") {
            content['id_line'] = localStorage.id_line
            content['id_model'] = localStorage.id_model
            content['id_brand'] = localStorage.id_brand
        }

        fetchData('users/auth', 'POST', {
            event: 'add-new-plan',
            content: content
        }, {}, apiNewProductRegisterLoading, apiNewProductRegisterSuccess, apiNewProductRegisterError);
    }

    // useEffect(() => {
    //     if (response?.ok) {
    //         RenderAlert({ severity: "info", message: response?.data });
    //         navigate(-1)
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [response]);


    useEffect(() => {
        if (error) {
            console.error(error)
        }
    }, [error]);

    const formSteps = membershipTypeModule[membershipType][membershipSubtype]?.formSteps;

    const _handleSubmit = async (values: any, actions: any) => {
        setSubmitted(true);
        if (activeStep === 0) {
            const data = {
                type: values.cardType, // premium -free
                subtype: values.cardType === "free" ? "freemium" : "premium", // premium - freemium
                category: values.category, // celular-salud
                benefit_id: values.benefitId,
                plan_id: values.planId
            }

            dispatch(setCurrentValue(data));
        }
        if (activeStep === formSteps) {
            setSubmitted(true);
            _submitForm(values, actions);
        } else {
            setActiveStep(activeStep + 1);
            setSubmitted(false);
        }

    }

    const validationSchemaStep = Yup.object().shape({})
    const validationSchemaStep4 = Yup.object().shape({
        marca: Yup.string().required(fieldIsRequired),
        modelo: Yup.string().required(fieldIsRequired),
        descripcion: Yup.string().required(fieldIsRequired)
    });
    const validatorSchemaStepper = () => {
        return validationSchemaStep;
    }

    const validatorSchemaStepperCelular = () => {
        switch (activeStep) {
            case 0:
                return validationSchemaStep;
            case 1:
                return validationSchemaStep4;
            case 2:
                return validationSchemaStep;
            default:
                throw Error("Verificar que el schema este asignado");
        }
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    useEffect(() => {
        if (activeStep) {
            scrollToTop();
        }
    }, [activeStep])

    return (
        <Container maxWidth={'lg'}>
            <Box sx={
                {
                    display: "flex",
                    flexDirection: "column",
                    margin: "auto",
                    paddingTop: '40px',
                    paddingBottom: '80px',
                    gap: ' 24px',
                    maxWidth: activeStep === 2 ? '778px' : styledCustomized,
                    alignItems: 'flex-start'
                }
            }>
                <CustomButton
                    text={"Regresar"}
                    onClick={() => (activeStep === 0 ? navigate(-1) : handleBack())}
                    size={"medium"}
                    startIcon={<ArrowBack />}
                    variant={"contained"}
                    style={{
                        color: "#039ECC",
                        backgroundColor: "transparent",
                        fontSize: "1rem",
                        textTransform: "none",
                        fontWeight: "600",
                        fontStyle: "normal",
                        padding: "0px",
                        minWidth: "64px",
                        border: "none",
                        justifyContent: "flex-start",
                        width: "fit-content",
                    }}
                />

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 40,
                    width: '100%'
                }}>
                    {loading ?
                        <LoadingComponent isLoaded={!loading} isError={false} ErrorMessage='Hubo un error al cargar las coberturas' ></LoadingComponent>
                        :
                        <FormContainer
                            buttonNext={buttonNextHidded}
                            steps={membershipTypeModule[membershipType][membershipSubtype].steps}
                            initialValues={membershipTypeModule[membershipType][membershipSubtype].initialValues}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            title={membershipTypeModule[membershipType][membershipSubtype].titles}
                            renderStepContent={currentValue?.type === "free" ? currentValue?.category === "celular" ? renderStepContentCelular : renderStepContent : currentValue?.category === "celular" ? renderStepContentPremiumCelular : renderStepContentPremium}
                            _handleSubmit={_handleSubmit}
                            validatorSchemaStepper={currentValue?.category === "celular" ? validatorSchemaStepperCelular : validatorSchemaStepper}
                            formObserverKey='FormObserverDatosCelularEdit'
                            formObserverSlice={apiSuccess}
                            localData={response}
                            submitted={submitted}
                        />}
                </div>
            </Box>
        </Container>
    );
};

export default NewProductRegister;
