import React from 'react';
import { Box } from '@mui/material';
import CustomContainer from '../Container/Container';
import QuestionContentAlliance from '../QuestionsAlliance/QuestionContentAlliance';

const QuestionsAlliance: React.FC = () => {

    return (
        <Box sx={{ backgroundColor: '#FFF' }}>
            <CustomContainer childComponent={<QuestionContentAlliance />} />
        </Box>
    )
};

export default QuestionsAlliance;
