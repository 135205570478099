import React, { useEffect } from 'react';
import styled from 'styled-components';
import BenefitDetailsComponent from '../../components/organisms/CustomerPortalContent/components/benefitDetails/BenefitInfo';
import { useParams } from 'react-router-dom';
import { benefitGetKeyByName, benefitValidKey, benefitValidKeyAndReturnType } from '../../components/organisms/CustomerPortalContent/components/benefitEngine/benefit.tools';
import CustomContainer from '../../components/organisms/Container/Container';
import { IStorageMembershipData } from '../../utils/localStorageKeys';
import { useSelector } from "react-redux";

const BenefitDetailsPage = () => {
    const { benefit } = useParams();
    const benefitKeyByName = benefitGetKeyByName(benefit);
    const benefitKeyByKey = benefitValidKeyAndReturnType(benefit);
    const benefitKey = benefitKeyByName ? benefitKeyByName : benefitKeyByKey;
    let storage: IStorageMembershipData = {
        ...useSelector((state: any) => state.productsCards.currentValue),
      };
    const userResponse = useSelector((state: any) => state?.user?.response);

    
    useEffect(() => {
        window.dataLayer.push({ 
            event: benefit == "Pago de medicamentos"
            ? "TRUS_pago_medicamentos_v"
            : benefit == "CheckUpMedico"
            ? "TRUS_check_medico_v"
            : benefit == "Reembolso de gastos médicos"
            ? "TRUS_reembolso_v"
            : benefit == "Pérdidas Orgánicas"
            ? "TRUS_perdidas_organicas_v"
            : benefit == "Invalidez total y permanente por accidente"
            ? "TRUS_invalidez_total_v"
            : benefit == "Apoyo de incapacidad por accidente"
            ? "TRUS_apoyo_incapacidad_v"
            : ""
            , 
            tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium", 
            correo: userResponse?.data?.user?.email, 
            seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
            telefono: userResponse?.data?.user?.phone,
            page_location: window.location.href,
          });
          
    }, [])

    return (
        <>
            {
                benefitKey ? (<CustomContainer childComponent={
                                <BenefitDetailsComponent benefitKey={benefitKey} />
                            } /> )
                    : <div>
                        No se encontro el beneficio <code>{benefit}</code>
                    </div>
            }
        </>)
}

export default BenefitDetailsPage;