/* eslint-disable no-restricted-globals */
import React, { useContext, useState } from 'react';
import LoadingComponent from '../../organisms/loading/loading';
import { ContextAuth0 } from '../../../auth/contextAPIProvider';
import { isAuth } from '../../../auth/auth0';
import { useNavigate, useLocation } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import { apiError, apiLoading, apiSuccess } from '../../../api/signUpSlice';
import { useDispatch, useSelector } from 'react-redux';
import { InterInstance, addAccessToken } from '../../../api/config';
import CardAction from '../../organisms/Card';
import BasicModal from '../../molecules/BasicModal/BasicModal';
import ManiPosesBody from '../../../assets/ManiErrorHandler/ManiPosesErrorHandler.jpg';
import { restartValue as restartAllianceValue } from '../../../api/userAlianceSlice';

const RedirectComponent: React.FC = () => {
  const webAuth: any = useContext(ContextAuth0);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const locate = useLocation();
    const { fetchData } = useFetch();
    const responseRegister = useSelector((state: any) => state.signUp.response) as any | null;
    const errorRegister = useSelector((state: any) => state.signUp.error) as any | null;
    const response = useSelector((state: any) => state.formRegister.response) as any | null;
    const tracking = useSelector((state: any) => state.tracking.response) as any | null;
    const productsCards = useSelector((state: any) => state.productsCards.response) as any | null;
    const currentValue = useSelector((state: any) => state.productsCards.currentValue) as any | null;
    const [open, setOpenModal] = useState<boolean>(false);
    const benefits = useSelector((state: any) => state.gadgets.response) as any | null;
    const responseUserAlliance = useSelector((state: any) => state.userAliance.response) as
    | any
    | null;
    const allianceSettingResponse = useSelector((state: any) => state.allianceSetting.response);
    
    React.useEffect(() => {
        if(webAuth.client){
              const bool = async() => await isAuth(webAuth);
              bool().then(res => {
                if(res){
                  const splitToken = location.hash.split('&')[0].replace('#', '');
                  const getTokenURL = splitToken.split('=')[1];

                  const stateUrl = location.hash.split('&')[4].split('=')[1];
                  const hash = location.hash;
                  const splitAccessToken = hash.split('&')[6];
                  const getAccessTokenURL = splitAccessToken.split('=')[1];

                  sessionStorage.setItem('access_token', getAccessTokenURL);
                  webAuth.client.userInfo(getTokenURL,
                    function(err: any, user: any){
                        if(stateUrl === 'login' || stateUrl === "%2Fdocumentos-adicionales"){
                          if(stateUrl === 'login'){
                            logIn('/portal-del-cliente');
                          }else{
                            logIn('/documentos-adicionales');
                          }
                        }else if(stateUrl === 'signup'){
                          const getProduct = productsCards.data.products.find((product: any) => product.category === currentValue.category && product.type === currentValue.type)
                          const content = {...response};
                          content.tracking_id = tracking?.data?.tracking_id;
                          content.status = true;
                          content.tier = getProduct.type === "free" ? "freemium" : "premium";
                          content.gender = content.gender === 'Hombre' ? 'h' : 'm'
                          content.cp = Number(content.zipcode);
                          content['plan_id'] = currentValue.plan_id;
                          content.soy_mexicano = content.mexicanciticen;
                          content.imei = null;
                          content.terms = content.acceptTerms;
                          content.id_brand = localStorage.id_brand;
                          content.id_line = localStorage.id_line;
                          content.id_model = localStorage.id_model;
                          // const hash: any = [];
                          if(responseUserAlliance?.data?.register){
                            content.register = responseUserAlliance?.data?.register;
                            // content.business = responseUserAlliance?.data?.business;
                          }
                        
                          if(allianceSettingResponse?.data?.id_bussiness){
                              content.business = allianceSettingResponse?.data?.id_bussiness
                          }

                          if(responseUserAlliance?.data?.tracking_id){
                            content.tracking_id = responseUserAlliance?.data?.tracking_id
                            content.id_aliance = responseUserAlliance?.data?.id_aliance
                          }

                          content['benefit_id'] = currentValue.benefit_id

                          if(user?.email === content?.email) {
                          fetchData('users/auth', 'POST', {
                            event: 'register',
                            content: content
                          }, {}, apiLoading, apiSuccess, apiError);
                        } else {
                          sessionStorage.removeItem("access_token");
                          setOpenModal(true);
                          // alert("Error: el correo debe de ser el mismo que ingresaste en el formulario de registro");
                          // navigate('/crear-cuenta', {state: {path: 'resume'}})
                        }
                        }
                      if(err) {
                        sessionStorage.removeItem("access_token");
                        navigate(`/Error?1${JSON.stringify(err)}`, { replace: true });
                      }
                    })
                }
                }).catch(err => {
                  sessionStorage.removeItem("access_token");
                  navigate(`/Error?2${JSON.stringify(err)}`, { replace: true });
                })
              
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [ webAuth.client ]);

      const logIn = async(path: string) => {
        try {
          await addAccessToken();
          navigate(path, { replace: true, state: { showModalUpsell: true } });
        } catch (error) {
          sessionStorage.removeItem("access_token");
        }
      };

      React.useEffect(() => {
        if(webAuth?.client){
          if(responseRegister?.ok){
            try {
              const add = async () => await addAccessToken();
              // sessionStorage.setItem('access_token', responseRegister.data.user);
              add();
              navigate('/portal-del-cliente', { replace: true, state: { newUser: true, showModalUpsell: true } });
              dispatch(restartAllianceValue()) 
            } catch (error) {
              sessionStorage.removeItem("access_token");
            }
          }
          if(errorRegister){
            navigate(`/Error?3${JSON.stringify(errorRegister)}`, { replace: true });
            sessionStorage.removeItem("access_token");
          }
        }
        
        // logIn();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [responseRegister, errorRegister]);

    const onClick = () => {
      navigate('/crear-cuenta', {state: {path: 'resume'}})
    }
    
    return (
        <React.Fragment>
          <BasicModal
            styleRoot={{
              maxWidth: 728,
              boxShadow: "none",
            }}
            styleContent={{
              margin: 0,
              padding: 0,
            }}
            disableBackdropClick={true}
            closeButton
            open={open}
            setOpenModal={setOpenModal}
            onClick={onClick}
            bodyDialog={<CardAction 
              onClick={onClick}
              buttonText="entendido"
              cardContent={"¡Importante! Para activar tu cuenta con Google usa el mismo correo con el que te registraste"}
              imgSrc={ManiPosesBody}
              style={{
                backgroundColor: '#039ECC',
                border: '1px solid #039ECC'
              }}
              styleImg={{
                backgroundColor: '#C0E6F2'
              }}
              styleButton={{
                color: '#039ECC'
              }}
            />}
          />
            <LoadingComponent 
                isLoaded={!true} 
                interTextMessage={'Te estamos redirigiendo a tu pagina'} 
            />
        </React.Fragment>
    );
};

export default RedirectComponent;
