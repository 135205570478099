import PickInsurance from '../../../components/insurances/PickInsurance';
import React, { useEffect, useState, useContext, useRef } from 'react';
import { useTheme, useMediaQuery, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import './CustomerPortalContent.css';
import chevronRight from './assets/chevronRight.png';
import guy from './assets/guy.png';

import {
    MembershipCategoryType,
    MembershipSubType,
    MembershipType,
} from '../Membership/MembershipMainValues';
import {
    IStorageMembershipData,
} from '../../../utils/localStorageKeys';
import { setCurrentValue } from '../../../api/productsSlice';
import { useNavigate } from 'react-router-dom';
import {
    apiSuccess as apiUserSuccess,
    apiError as apiUserError,
    apiLoading as apiUserLoading,
    setCurrentValue as setCurrentMembershipValue
} from '../../../api/userSlice';
import {
    apiSuccess as apiStatusSuccess,
    apiError as apiStatusError,
    apiLoading as apiStatusLoading
} from '../../../api/statusSlice';

import {
    apiSuccess as apiTrackingSuccess
} from '../../../api/trackingSlice';
import useFetch from '../../../hooks/useFetch';
import { IUserInfo } from './interfaces/user.interfaces';
import { BenefitRef } from './interfaces/dictionary';
import { InterInstance } from '../../../api/config';
import { logout } from '../../../auth/auth0';
import { restartValue } from '../../../api/loginSlice';
import { ContextAuth0 } from '../../../auth/contextAPIProvider';
import LoadingComponent from '../loading/loading';
import moment from 'moment';
import BasicModal from '../../molecules/BasicModal/BasicModal';
import CardAction from '../Card';
import Avatar from './assets/avatar.png';
import { CapitalizeText } from "../../../utils/utils";
import CustomerPortalTemplate from '../../templates/CustomerPortalTemplate';
import { MembershipPlanItem } from './interfaces/membership';

import { ConvertPlanToMembershipItem } from './utils/convertPlantoMembership';
import {
    apiLoading as LoadingGadgets,
    apiSuccess as SuccessGadgets,
    apiError as ErrorGadgets
} from '../../../api/gadgetsSlice';
import {
    apiLoading as LoadingHealth,
    apiSuccess as SuccessHealth,
    apiError as ErrorHealth
} from '../../../api/healthSlice';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import BenefitEnigineComponent from './components/benefitEngine';
import { BenefitValues } from './components/benefitEngine/benefit.values';
import CardUserComponent from './components/CardUser';


export interface BenefitsDetails {
    title: string;
    icon?: React.ReactNode;
    url?: string | undefined;
    benefit_id?: string;
}

interface UserEvents {
    gadgets: number;
}

interface MembershipSingleItemPros extends BenefitsDetails {
    colorBadge?: string,
    badge?: string
}

interface ICustomerPortal {
    modalNewUserIsOpen?: boolean;
    showModalUpsell?: boolean;
}

export const MembershipSingleItem = ({
    title,
    icon,
    url,
    colorBadge = "#90268D",
    badge
}: MembershipSingleItemPros) => {

    return (
        <div className='item' style={{ width: '100%' }}>
            <div className='innerItem'>
                <div className='icon'>{icon}</div>
                <div className='text' style={{ width: '100%' }}>
                    <p>
                        {badge && badge !== '' ? <span style={{
                            display: 'flex',
                            fontSize: '10px',
                            borderRadius: '14px',
                            background: colorBadge,
                            color: 'white',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            fontWeight: '100',
                            maxWidth: '100%',
                        }}>{badge}</span> : ''}
                        {title}
                    </p>
                </div>
            </div>
            <img src={chevronRight} alt='' />
        </div>
    );
};

export const CustomerPortalContent = ({ modalNewUserIsOpen, showModalUpsell }: ICustomerPortal) => {
    const [MembershipsPlans, setMembershipPlans] = useState<MembershipPlanItem[]>([]);
    const [currentMembership, setCurrentMembership] = useState<number>(0);
    const [loadingBenefit, setLoadingBenefit] = useState<boolean>(true);
    const getCurrentMembershipCretedAt = (membership: MembershipPlanItem) => {
        return membership ? membership?.createdAt : ''
    }

    const { breakpoints } = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const isDesktop = useMediaQuery(breakpoints.up('lg'));
    const isTablet = useMediaQuery(breakpoints.up('md'));


    const isIpad = useMediaQuery('(min-width:768px) and (max-width:1366px)');


    const [open, setOpen] = useState(false);
    const [modalInfoOpen, setModalInfoOpen] = useState(false);
    const [hasBeneficiaries, setHasBeneficiaries] = useState<boolean>(false)
    const [userEvents, setUserEvents] = useState<UserEvents>({
        gadgets: 0
    });
    const navigate = useNavigate();
    const { fetchData } = useFetch();

    const dispatch = useDispatch();
    let storage: IStorageMembershipData = {
        ...useSelector((state: any) => state.productsCards.currentValue),
    };
    const [isValidateCellPhone, setIsValidationCellPhone] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const userResponse = useSelector((state: any) => state.user.response);
    const userError = useSelector((state: any) => state.user.error);
    const [user, setUser] = useState<IUserInfo>(userResponse?.data);
    const webAuth: any = useContext(ContextAuth0);
    const [userData, setUserData] = useState({
        fullName: "",
        folio: "",
        vigencia: "",
    });

    if (userError && userError.message) {
        //mover usuario al home si no existe!
        console.error("OCURRIO UN ERROR CON LA INFORMACION DE USUARIO", userError);
        sessionStorage.setItem('userError', '1');
        dispatch(restartValue());
        logout(webAuth);
    }
    if (sessionStorage?.getItem('exitoPremium') == '1') {
        sessionStorage?.setItem('exitoPremium', '0');
        // window.location.reload();
    }

    const validationBenefitDiff = (date: string, waitDays?: number) => {
        const validateDays: number = waitDays || 30;
        // let type: moment.unitOfTime.DurationConstructor = validateDays > 1 ? 'days' : 'hours';
        let formatoMensaje
        const MomentDate = moment(date);
        MomentDate.add(validateDays, 'days');
        const MomentToday = moment();
        let diffDays = MomentDate.diff(MomentToday, "days");
        if (diffDays === 1) {
            formatoMensaje = 'dia'
        } else if (diffDays === 0) {
            formatoMensaje = "horas"
            diffDays = Math.abs(MomentDate.diff(MomentToday, "hours"))
        } else {
            formatoMensaje = 'dias'
        }
        const message: string = `${diffDays} ${formatoMensaje}`;
        return message
    }

    const generatedVigenceDate = (created_at: string) => {
        const createdDate = new Date(created_at);
        createdDate.setFullYear(createdDate.getFullYear() + 1)
        const timeZone = "America/Mexico_City";
        createdDate.setTime(createdDate.getTime() + createdDate.getTimezoneOffset() * 60000);
        createdDate.toLocaleString("es-MX", { timeZone });
        const year = createdDate.getFullYear();
        const month = String(createdDate.getMonth() + 1).padStart(2, '0');
        const day = String(createdDate.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const [category, setCategory] = useState<MembershipCategoryType>('celular');

    let [type, setType] = useState<MembershipType>('free');

    let [subType, setSubType] = useState<MembershipSubType>(
        type === 'free'
            ? 'freemium'
            : storage && storage.subtype
                ? storage.subtype
                : storage && storage.category === 'celular'
                    ? 'premium'
                    : 'esencial'
    );
    if (!storage.subtype) {
        storage.subtype = subType;
    }
    let [benefits, setBenefit] = useState<BenefitsDetails[]>([]);
    let [extraBenefits, setExtraBenefit] = useState<BenefitsDetails[]>([]);
    const alreadyCalled = useRef(false)

    const ResponseProductsCards = useSelector((state: any) => state.productsCards.response) as any | null;
    const responseUser = useSelector((state: any) => state.user.response) as any | null;
    const statusResponse = useSelector((state: any) => state.status) as any | null;

    const getStatus = () => {
        const trakingId: string = userResponse?.data?.user?.tracking_id;
        return fetchData('/gadgets/', 'POST', {
            event: 'get-claim-cel',
            content: {
                tracking_id: trakingId
            }
        }, {}, apiStatusLoading, apiStatusSuccess, apiStatusError);
    }

    const getValidation = async () => {
        try {
            const trakingId: string = userResponse?.data?.user?.tracking_id;
            const { data } = await InterInstance.get(`/gadgets/validate-cellphone/${trakingId}`)
            if (data) {
                const { data: isValid } = data
                setIsValidationCellPhone(isValid)
            }
        } catch (error) {
            console.log(error)
            setIsValidationCellPhone(false)
        } finally {
            setLoading(false)
            alreadyCalled.current = true
        }
    }

    useEffect(() => {
        getUser();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (MembershipsPlans && MembershipsPlans.length > 0) {
            setCategory(MembershipsPlans[currentMembership].category);
            // const tmpType = MembershipsPlans[currentMembership].type
            const tmpCategory = MembershipsPlans[currentMembership].plan === "P01" ? "celular" : "salud"
            setCategory(tmpCategory)
            setType(MembershipsPlans[currentMembership].type)
            getBenefits();
            setLoadingBenefit(true);
            dispatch(setCurrentMembershipValue(currentMembership))
            if (tmpCategory === "celular" && !alreadyCalled) {
                setLoading(true)
                getValidation();
            } else {
                setIsValidationCellPhone(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMembership, MembershipsPlans, subType])

    useEffect(() => {
        if (userResponse?.length !== 0 && userResponse?.data?.user?.name) {
            setUser(userResponse?.data?.user);
            const tmpUser = userResponse?.data?.user;
            const { name, middle_name, last_name, created_at } = tmpUser
            const folio = userResponse?.data?.user?.certificate;//generateFolio(name, middle_name, last_name, created_at);
            const vigencia = generatedVigenceDate(getCurrentMembershipCretedAt(MembershipsPlans[currentMembership]));
            const fullName = `${name} ${middle_name} ${last_name}`;

            setUserEvents({
                gadgets: userResponse?.data?.events?.gadgets || 0
            })
            setUserData({
                folio: folio,
                vigencia: vigencia,
                fullName: fullName
            });

            const plans = ConvertPlanToMembershipItem(userResponse?.data?.plans);
            setMembershipPlans(plans);

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userResponse]);

    React.useEffect(() => {
        if (userResponse?.data?.user) {
            window.dataLayer.push({
                'event': 'TRUS_portal_v',
                tipo_seguro: userResponse?.data?.user?.benefit_id === "C01" ? 'De regalo' : 'Premium',
                'correo': userResponse?.data?.user?.email,
                'telefono': userResponse?.data?.user?.phone,
                'page_location': window.location.href,
                seguro: userResponse?.data?.user?.plan_id == "P01" ? "Celular Protect" : "Salud Protect",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (responseUser && responseUser?.data?.user) {
            const data = {
                "code": 200,
                "ok": true,
                "msg": "ok",
                "data": {
                    "data": {},
                    "navigator": "Google Chrome Generated",
                    "registered": false,
                    "created_at": "2023-10-18",
                    "tracking_id": responseUser?.data?.user?.tracking_id,
                    "status": false
                }
            }
            dispatch(apiTrackingSuccess(data))
        }

        if (responseUser && responseUser?.data?.beneficiaries && responseUser?.data?.beneficiaries.length > 0) {
            setHasBeneficiaries(true)
        }
    }, [responseUser]);


    const getUser = async () => {
        const user = await fetchData('users/auth/get-user', 'GET', {}, {}, apiUserLoading, apiUserSuccess, apiUserError);
        if (user !== undefined) {
            await getStatus();
        }

        return user
    }

    const fetchingDataGadgets = () => {
        const url: string = category === 'salud' ? '/catalogs/health' : '/catalogs/gadgets';

        return fetchData(url, 'GET', {}, {}
            , category === 'salud' ? LoadingHealth : LoadingGadgets, category === 'salud' ? SuccessHealth : SuccessGadgets, category === 'salud' ? ErrorHealth : ErrorGadgets);
    }

    const cleanBenefitIdsThatAreNotInTheProccess = (category: any, subType: string, assign: string) => {
        /**
         * El siguiente codigo genera un nuevo objeto para los datos de health
         * para filtrar el tipo benefit_id === S00 ya que es un dato que nos devuelve
         * BE y no debe de ir en el proceso, esto se hizo para no modificar el componente
         * que genera los beneficios.
         */
        const newHealth = category?.data[subType][assign];
        const benefits = newHealth.filter((b: any) => !b.title.includes("Descuentos Inter.mx"));
        const hash = { health: { ...category?.data[subType] } };

        hash.health['benefits'] = [...benefits];

        return hash.health.benefits;
    }

    const getBenefits = async () => {
        const url: string = MembershipsPlans[currentMembership]?.category === 'salud' ? '/catalogs/health' : '/catalogs/gadgets';
        const { data } = await InterInstance.get(url);
        const tmpSubtype = MembershipsPlans[currentMembership].subtype
        const clearData = cleanBenefitIdsThatAreNotInTheProccess(data, tmpSubtype, 'extra');
        setType(MembershipsPlans[currentMembership].type);
        setSubType(MembershipsPlans[currentMembership].subtype);
        setBenefit(data.data[subType].benefits)
        setExtraBenefit(clearData);
        setLoadingBenefit(false);

    }

    useEffect(() => {
        if (category) {
            fetchingDataGadgets();
        }
    }, [category]);

    useEffect(() => {
        if (userResponse?.data?.plans && userResponse?.data?.plans?.length > 0) {
            // const subtypeTmp: MembershipSubType = FremiumCodes.includes(user?.benefit_id) ? 'freemium' : 'premium';
            // setSubType(subtypeTmp)

            const planFree = userResponse?.data?.plans?.filter((p: any) => p.certificate === MembershipsPlans[currentMembership]?.certificate);
            const sendDataLocalStorage = {
                type: MembershipsPlans[currentMembership]?.type,
                subtype: MembershipsPlans[currentMembership]?.subtype,
                category: MembershipsPlans[currentMembership]?.category,
                cardTitle: MembershipsPlans[currentMembership]?.category === "celular" ? "Seguro Inter Celular" : "Seguro Inter Salud",
                benefit_id: MembershipsPlans[currentMembership]?.benefitId,
                plan_id: planFree && planFree[0]?.plan
            };
            dispatch(setCurrentValue(sendDataLocalStorage));
        }
    }, [user]);

    const styleButton: React.CSSProperties = {
        border: 'none',
        width: '100%',
        backgroundColor: 'transparent',
    }

    const getUrlTelemedicine = async () => {
        try {
            const { data } = await InterInstance.get("plansalud/telemedicina/otp")
            return data?.data?.url
        } catch (error) {
            console.error(error)
        }
    }

    const renderExtraBenefitList = (array: BenefitsDetails[]) => {
        return array.map((element, index) => {
            const blackList: string[] = [BenefitValues.DescuentosInter];
            const noShow: boolean = blackList.includes(element.title);
            const nagivateElement = BenefitRef[element.title];
            return (
                <div style={noShow ? { display: 'none' } : { display: 'flex' }}>
                    <button key={index} style={nagivateElement ? { ...styleButton, cursor: 'pointer' } : {
                        ...styleButton,
                        opacity: '0.7'
                    }
                    } onClick={async () => {
                        if (nagivateElement && nagivateElement.path) {
                            window.dataLayer.push({
                                event: 'TRUS_beneficios_adicionales',
                                seguro: category === "celular" ? 'Celular Protect' : 'Salud Protect',
                                tipo_seguro: type === "free" ? "De regalo" : "Premium",
                                beneficio_adicional: element.title,
                                correo: user.email,
                                telefono: user.phone,
                            });
                            navigate(nagivateElement.path);
                        } else {
                            window.dataLayer.push({
                                event: 'TRUS_beneficios_adicionales',
                                seguro: category === "celular" ? 'Celular Protect' : 'Salud Protect',
                                tipo_seguro: type === "free" ? "De regalo" : "Premium",
                                beneficio_adicional: element.title,
                                correo: user.email,
                                telefono: user.phone,
                            });
                            const url = await getUrlTelemedicine();
                            telemedicineRedirect(url);
                        }
                    }}>

                        <MembershipSingleItem
                            key={'extra_' + index}
                            title={element.title}
                            icon={element.icon}
                        //url={element.title.includes('Club de descuentos') ? urlClubDescuentos : undefined}
                        />
                    </button>
                </div>


            );
        });
    };

    const telemedicineRedirect = async (urlTelemedicina: any) => {
        var anchor = document.createElement('a');
        anchor.href = urlTelemedicina;
        anchor.target = "_blank";
        anchor.click();
    }

    const sendReferrGtm = () => {
        window.dataLayer.push({
            event: 'TRUS_referidos_invitacion',
            correo: user.email,
            telefono: user.phone,
            boton_contacto: 'Enviar invitacion',
        })
    }

    React.useEffect(() => {
        if (open) {
            window.dataLayer.push({
                'event': 'TRUS_espera_cobertura_v',
                'tipo_seguro': type === "free" ? "De regalo" : "Premium",//premium | De regalo
                seguro: storage.category === "celular" ? 'Celular Protect' : 'Salud Protect',
                correo: user.email,
                telefono: user.phone,
                'page_location': window.location.href,
            })
        }
    }, [open])

    React.useEffect(() => {
        if (modalInfoOpen) {
            window.dataLayer.push({
                'event': 'TRUS_agregar_imei_v',
                'tipo_seguro': type === "free" ? "De regalo" : "Premium",//premium | De regalo
                correo: user.email,
                telefono: user.phone,
                'page_location': window.location.href,
                seguro: storage.category === "celular" ? "Celular Protect" : "Salud Protect",
            })
        }
    }, [modalInfoOpen])

    return (
        <div className='mainContainer' style={{}}>
            <div style={{}}>
                <div style={{}}>
                    {(!loading && isValidateCellPhone && type === 'free' && !modalNewUserIsOpen && showModalUpsell) ?
                        <>
                            <CustomerPortalTemplate />
                        </>
                        : ''}
                    <BasicModal
                        styleRoot={{
                            maxWidth: 728,
                            boxShadow: "none",
                            marginTop: '62px'
                        }}
                        styleContent={{
                            margin: 0,
                            padding: 0,
                        }}
                        disableBackdropClick={true}
                        closeButton
                        open={open}
                        setOpenModal={setOpen}
                        bodyDialog={<CardAction
                            onClick={() => {
                                setOpen(false)
                                window.dataLayer.push({
                                    'event': 'TRUS_espera_cobertura',
                                    tipo_seguro: type == "free" ? 'De regalo' : 'Premium',//premium | De regalo
                                    seguro: storage.category === "celular" ? 'Celular Protect' : 'Salud Protect',
                                    'correo': user.email,
                                    boton_contacto: 'Entendido',
                                    telefono: user.phone,
                                });
                            }}
                            buttonText='entendido'
                            imgSrc={Avatar}
                            style={{
                                backgroundColor: '#039ECC',
                                borderRadius: '0 0 8px 8px',
                                justifyContent: 'space-around',
                                border: 'none',
                                color: '#000'
                            }}
                            styleTextContent={{
                                color: '#FFF'
                            }}
                            styleButton={{
                                color: '#039ECC',
                                borderRadius: '8px',
                                background: '#FFFFFF',
                                border: 'none'
                            }}
                            styleImg={{
                                backgroundColor: "#C0E6F2",
                                padding: 0,
                                display: "flex",
                                flexWrap: "wrap",
                                alignContent: "flex-end",
                            }}
                            setOpenModal={setOpen}
                            cardContent={<React.Fragment>
                                {userData && userData.fullName !== '' && user && user.created_at ? <span>
                                    ¡Hola <strong>{CapitalizeText(userData.fullName.toLowerCase())}</strong>! Para utilizar esta cobertura, hay un período de espera de {validationBenefitDiff(getCurrentMembershipCretedAt(MembershipsPlans[currentMembership]), 30)}. Esto significa que si ocurriera algún incidente antes de que entre en vigencia, lamentablemente no podríamos cubrirlo.
                                </span> : ''}

                            </React.Fragment>}
                            cardType={"free"}
                            subType={"freemium"}
                            cardCategory={"celular"}
                            cardDescription={"Bloqueo de Dias"}
                            cardTitle={"Seguro Inter Celular"}
                        />}
                    />


                    <BasicModal
                        styleRoot={{
                            maxWidth: 728,
                            boxShadow: "none",
                            marginTop: '62px'
                        }}
                        styleContent={{
                            margin: 0,
                            padding: 0,
                        }}
                        disableBackdropClick={true}
                        closeButton
                        open={modalInfoOpen}
                        setOpenModal={setModalInfoOpen}
                        bodyDialog={<CardAction
                            onClick={() => {
                                window.dataLayer.push({
                                    event: "TRUS_agregar_imei",
                                    boton_contacto: "Agregar",
                                    seguro: storage.category === "celular" ? "Celular Protect" : "Salud Protect",
                                    tipo_seguro: storage.type === "free" ? "De regalo" : "Premium",
                                    telefono: userResponse?.data?.user?.phone,
                                    correo: userResponse?.data?.user?.email,
                                });
                                navigate('/registrar-imei');
                            }}
                            buttonText='Agregar'
                            imgSrc={Avatar}
                            style={{
                                backgroundColor: '#039ECC',
                                borderRadius: '0 0 8px 8px',
                                justifyContent: 'space-around',
                                border: 'none',
                                color: '#000'
                            }}
                            styleTextContent={{
                                color: '#FFF'
                            }}
                            styleButton={{
                                color: '#039ECC',
                                borderRadius: '8px',
                                background: '#FFFFFF',
                                border: 'none'
                            }}
                            styleImg={{
                                backgroundColor: "#C0E6F2",
                                padding: 0,
                                display: "flex",
                                flexWrap: "wrap",
                                alignContent: "flex-end",
                            }}
                            setOpenModal={setOpen}
                            cardContent={<React.Fragment>
                                {userData && userData.fullName !== '' && user && user.created_at ? <span>
                                    Hola <strong>{userData.fullName}</strong>, para poder usar tu cobertura de reparación de pantalla es muy importante que nos dejes el <strong>IMEI</strong> del celular que registraste.
                                </span> : ''}

                            </React.Fragment>}
                            cardType={"free"}
                            subType={"freemium"}
                            cardCategory={"celular"}
                            cardDescription={""}
                            cardTitle={""}
                        />}
                    />

                    <div className='tituloMain' style={{
                        display: 'flex',
                        justifyContent: isMobile ? 'center' : isIpad ? 'center' : ' flex-start'
                    }}>
                        <div className='tituloContainer'>
                            <p className='titulo'>Mi portal de cliente </p>
                        </div>
                    </div>

                    <LoadingComponent isError={false} isLoaded={userData && userData.folio === '' ? false : true} />
                    {userData && userData.folio &&
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                marginLeft: isDesktop ? '-27px' : 'unset'
                            }}
                        >
                            <Swiper
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    // background: 'yellow',
                                    // transform: 'translate3d(0px, 0px, 0px)',
                                }}

                                slidesPerView={'auto'}
                                centeredSlides={true}
                                slidesPerGroup={1}
                                modules={isDesktop ? [Navigation, Pagination, Scrollbar, A11y] : []}
                                // style={{ display: 'flex', justifyContent: 'left', paddingRight: '40px' }}
                                navigation={isDesktop && MembershipsPlans.length > 0 ? false : false}
                                pagination={{
                                    el: '.swiper-pagination',
                                    clickable: true,
                                    enabled: isDesktop ? MembershipsPlans.length > 0 ? true : false : false,
                                }}
                                scrollbar={{
                                    draggable: true,
                                    enabled: isDesktop ? MembershipsPlans.length > 0 ? true : false : false
                                }}
                                onSlideChange={(event) => {
                                    setCurrentMembership(event.activeIndex);
                                }}
                            >
                                {
                                    MembershipsPlans.map((item, index) => {
                                        return <SwiperSlide key={index}
                                            style={
                                                {
                                                    width: isIpad ? '75%' : '90%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignContent: 'center',
                                                    // background: 'red',
                                                    //  transform: 'translate3d(0px, 0px, 0px)'
                                                }
                                            }>
                                            <CardUserComponent
                                                type={item.type}
                                                category={item.category}
                                                subtype={item.subtype}
                                                certificate={item.certificate}
                                                createdAt={item.createdAt}
                                                isValidNumber={isValidateCellPhone}
                                                isLoading={loading}
                                                handleClick={() => {
                                                    const productPremium = ResponseProductsCards?.data?.products.find((product: any) => {
                                                        if (product?.category === storage?.category && product?.type === "premium") {
                                                            return product;
                                                        }
                                                        return null;
                                                    })

                                                    const planFree = userResponse?.data?.plans?.filter((p: any) => p.certificate === item?.certificate && p.tier === 0);
                                                    const sendDataLocalStorage = {
                                                        type: "premium",
                                                        subtype: "premium",
                                                        category: item?.category,
                                                        cardTitle: item?.category === "celular" ? "Seguro Inter Celular" : "Seguro Inter Salud",
                                                        benefit_id: item?.benefitId,
                                                        plan_id: planFree[0]?.plan
                                                    };
                                                    dispatch(setCurrentValue(sendDataLocalStorage));
                                                    navigate('/resumen-membresia', {
                                                        state: {
                                                            path: 'portal-del-cliente',
                                                            tabHidden: "freemium"
                                                        }
                                                    })
                                                }}
                                            />
                                        </SwiperSlide>
                                    })
                                }
                            </Swiper>
                        </div>
                    }
                </div>
                <div className='coberturasContainer'>
                    <div className='innerCoberturas'>






                        <p className='beneficiosTexto'>Coberturas incluidas</p>
                        <div className='benefitsList'>
                            <LoadingComponent position='top' isLoaded={!loadingBenefit} />
                            {!loadingBenefit && userResponse !== undefined && MembershipsPlans[currentMembership] ? <BenefitEnigineComponent key={benefits.length} benefits={benefits} created_at={MembershipsPlans && MembershipsPlans.length > 0 ? MembershipsPlans[currentMembership]?.createdAt : ''} membership={MembershipsPlans[currentMembership]} /> : ''}
                        </div>
                        <p className='beneficiosTexto'>Beneficios adicionales</p>
                        <div className='benefitlist'>
                            {!loadingBenefit ? renderExtraBenefitList(extraBenefits) : ''}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className='referidosContainer' style={{
                    display: 'flex',
                    justifyContent: isDesktop ? 'initial' : 'center'
                }}>
                    <div className='innerReferidos' style={{ display: 'none' }}>
                        <p className='beneficiosTexto' style={{
                            marginTop: isDesktop ? '0px' : '0px'
                        }}>Programa de referidos</p>
                        <div className='cardReferidos'>
                            <img src={guy} alt='' />
                            <div>
                                <p className='cardText'>
                                    Invita a tus amigos y seres queridos para que se unan a
                                </p>
                                <p className='hashtag'>#TeRegalamosUnSeguro</p>
                                {/* Cuando se agregue funcion para enviar invitacion de referidos agregar a esta la capa de datos de sendReferrGtm */}
                                <button className='buttonReferidos' onClick={() => sendReferrGtm()}
                                    style={{ cursor: 'pointer' }}>Enviar Invitación
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    MembershipsPlans[0] &&
                    <Box sx={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}>
                        <Box sx={{
                            marginTop: { xs: 5, lg: 0 },
                            display: 'flex',
                            width: { xs: '90%', sm: '70%', lg: '80%' },
                            padding: { xs: "0px", lg: "40px 16px" },
                            flexDirection: "column",
                            gap: { xs: '40%', sm: '40%', lg: '0%' },
                            borderRadius: "16px",
                            background: "var(--azul-5, #F2FAFC)"
                        }}>
                            <Box className='referidosContainer' sx={{
                                display: 'flex',
                                textAlign: { xs: "left", lg: "center" },
                                justifyContent: isDesktop ? 'center' : 'center'
                            }}>
                                <div className='innerReferidos'>
                                    <Box component={'p'} className='beneficiosTexto' sx={{
                                        marginTop: { xs: 5, lg: 0 },
                                    }}>Otros seguros para ti </Box>
                                </div>
                            </Box>
                            <PickInsurance plans={MembershipsPlans} />
                        </Box>
                    </Box>
                }
            </div>
        </div >
    );
};
