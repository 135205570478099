import BASE_CONFIG from "../env/env";

const isAuthenticatedCustom = (webAuth: any) => {
    // wrap parseHash in a Promise
    return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-restricted-globals
        const splitAccessToken = location.hash.split('&')[0].replace('#', '');
        const getAccessTokenURL = splitAccessToken.split('=')[1];
        webAuth.client.userInfo(getAccessTokenURL,
            function (err: any, user: any) {
                if (user) {
                    resolve(true)
                }
                else {
                    reject(false)
                }
                if (err) {
                    sessionStorage.removeItem("access_token");
                    reject(false)
                }
            })
    })
}

export const isAuth = async (webAuth: any) => {
    try {
        const response: any = await isAuthenticatedCustom(webAuth);

        return response;
    } catch (error) {
        return false;
    }
}

export const logout = (webAuth: any) => {
    const getAccessToken = sessionStorage.access_token;

    sessionStorage.removeItem("access_token");
    webAuth.logout({
        returnTo: `${BASE_CONFIG.base}/iniciar-sesion`,
        client_id: BASE_CONFIG.auth0.clientId,
        id_token_hint: getAccessToken
    });
}