import ReactDOM from 'react-dom'
import { ModalProps, IAlertData } from './interfaces';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';

export function Modal(props: ModalProps) {
  const { data, exit } = props

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    exit();
  };

  return (
    <Snackbar
      open={true}
      autoHideDuration={8000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      style={{ marginTop: "80px" }}
    >
      <Stack sx={{
        width: '100%'
      }} spacing={2}>
        <Alert variant='filled' severity={data.severity}>{data.message}</Alert>
      </Stack>
    </Snackbar>
  );
}

const RenderAlert = (data: IAlertData) => {
  const div = document.createElement('div')
  document.body.appendChild(div)

  const exit = () => {
    ReactDOM.unmountComponentAtNode(div)
  }

  ReactDOM.render(
    <Modal data={data} exit={exit} />
    , div
  )
}

export default RenderAlert