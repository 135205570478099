import React from "react";
import CustomContainer from "../../organisms/Container/Container";
import ForgotPassword from "../../organisms/ForgotPassword";
const ForgotPasswordTemplate: React.FC = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 40 }}>
            <CustomContainer childComponent={<ForgotPassword />} />
        </div>
    );
};
export default ForgotPasswordTemplate;
