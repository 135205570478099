import React, { useEffect, useId, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js'
import MakePaymentTemplate from '../../../components/templates/MakePayment'; //legacy
import { PaymentElement } from '@stripe/react-stripe-js';
import { InterInstance } from '../../../api/config';
import InterContainer from '../../../components/organisms/Container/ContainerFixed';
import BASE_CONFIG from '../../../env/env';
import { styled } from 'styled-components';
import PaymentElementComponent from '../../../components/templates/MakePayment/PaymentElement/PaymentElement';
const ContainerComponent = styled.div`
    display:flex;
    flex-direction: column;
`;

type MakePaymentProps = {

}

const MakePayment: React.FC<MakePaymentProps> = () => {
    const [clientSecret, setClientSecret] = useState();

    const stipePromise = loadStripe(BASE_CONFIG.stripe.publicKey);

    const paymentElementId = useId();
    const fetchSetupIntent = async () => {
        const { data } = await InterInstance.get(`/payments/stripe-setup-intent`);

        const key = data.data.client_secret;
        setClientSecret(data.data.client_secret);
    }
    useEffect(() => {
        fetchSetupIntent();
    }, [])
    return (
        <InterContainer>
            <ContainerComponent>
                {clientSecret && <Elements stripe={stipePromise} options={{
                    clientSecret
                }}>
                    <PaymentElementComponent
                        key={paymentElementId}
                        title='Ingresa los datos de la tarjeta con la que quieres realizar el pago'
                    />
                </Elements>}
            </ContainerComponent>
        </InterContainer>
    )
};

export default MakePayment
