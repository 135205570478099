import React from "react";
import { styled } from "styled-components";
import CustomButtonPaymentIcon from "./CustomButtonPaymentWithIcon";

const ChoosePaymentMethodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  align-items: center
`;

type ChoosePaymentMethodProps = {
  extraPaymentMethods?: Array<any>;
}

const ChoosePaymentMethod: React.FC<ChoosePaymentMethodProps> = ({ extraPaymentMethods }) => {

  return (
    <ChoosePaymentMethodWrapper>
        <CustomButtonPaymentIcon text={"Tarjeta de débito"} />
        <CustomButtonPaymentIcon text={"Tarjeta de crédito"} />
        {extraPaymentMethods && extraPaymentMethods.length > 0 && extraPaymentMethods?.map((item) => <CustomButtonPaymentIcon key={item.title} icon={item.icon || null}/>)}
    </ChoosePaymentMethodWrapper>
  );
};

export default ChoosePaymentMethod;
