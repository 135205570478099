import * as React from 'react';
import AccordionDetails from '@mui/material/AccordionDetails';
import QuestionsPlus from '../../../assets/Questions/QuestionsPlus';
import Link from '@mui/material/Link';
import { Accordion, AccordionSummary, Typography } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTheme, useMediaQuery } from '@mui/material';
import terminoY from '../../../assets/TermsAndConditions/términos y.jpg';
import condiciones from '../../../assets/TermsAndConditions/condiciones.jpg';

import terminoYy from '../../../assets/TermsAndConditions/términosYY.svg';
import condicionesyy from '../../../assets/TermsAndConditions/condicionesYY.svg';
import { styled } from "styled-components";

import coberturas from './data';
import AccordionContent from './components/AccordionContent';
import Chubb from './components/Chubb';


function TermsAndConditionsContent() {

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false)
    };

    const AcordionCustom: React.CSSProperties = {
        flex: '1 0 0'
    }
    const AcordionContainer: React.CSSProperties = {
        width: '100%',
        height: '100%',
        paddingBottom: '96px'
    }

    const { breakpoints } = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));



    const style = {
        border: '1px solid black',
        // borderCollapse: collapse,
        padding: '10px',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal'
    };


    const styleforStyle = {
        border: '1px solid black',
        // borderCollapse: collapse,
        padding: '10px',
        fontSize: '16px',
        fontStyle: 'normal',
        // fontWeight: 700,
        lineHeight: 'normal'
    };

    const styletitleTable = {
        border: '1px solid black',
        // borderCollapse: collapse,
        padding: '10px',
        color: 'red'
    };

    const BlackTextButton = styled.div`
        color: var(--black-75, #595959);
        font-family: 'Montserrat';
        font-size: '16px';
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    `;

    const TweleMount = styled.div`
        color: var(--Black, #212121);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    `;

    const Conditions = styled.div`
        color: var(--azul-75, #42B6D9);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-decoration-line: underline;
    `;

    const Exclusions = styled.div`
        color: var(--Black, #212121);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    `;

    const hola = window.location.pathname === "/finsus/terminos-y-condiciones"

    return (
        <div

            style={{
                display: 'flex',
                width: isMobile ? '100%' : '100%',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '80px',
                marginTop: '50px',
                height: '100%'
            }}
        >
            <div style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                gap: 12,
                width: isMobile ? '' : '100%',
                justifyContent: isMobile ? 'flex-start' : 'center',
                // background: 'red'
            }}>




                {

                    window.location.pathname === "/finsus/terminos-y-condiciones" ?

                        <>



                            <img style={{ height: "33px" }} src={terminoYy} alt="Preguntas Frecuentes" />
                            <img style={{ height: "27px", paddingTop: "5px" }} src={condicionesyy} alt="Preguntas Frecuentes" />


                        </>

                        :
                        <>
                            <img style={{ height: "33px" }} src={terminoY} alt="Preguntas Frecuentes" />
                            <img style={{ height: "27px", paddingTop: "5px" }} src={condiciones} alt="Preguntas Frecuentes" />

                        </>

                }





            </div>

            <div
                style={AcordionContainer}

            >
                <Accordion style={AcordionCustom} expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
                    <AccordionSummary expandIcon={expanded === 'panel1' ? < RemoveIcon /> : < QuestionsPlus />}
                    >

                        <Typography
                            style={{
                                color: 'var(--black-75, #595959)',
                                fontFamily: 'Montserrat',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 700,
                                lineHeight: 'normal',
                            }}

                        >Celular Protect de Regalo</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <br />
                            <div>
                                <thead>

                                    <th
                                        style={{
                                            border: '1px solid var(--Black, #212121)',
                                            background: 'var(--Black, #212121)',
                                            color: 'var(--White, #FFF)',
                                            padding: '10px'
                                        }}
                                        colSpan={2}
                                    >Resumen de coberturas</th>



                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={style}>Concepto</td>
                                        <td style={style}>Detalle</td>
                                    </tr>
                                    <tr>
                                        <td style={style}>Muerte
                                            accidental</td>
                                        <td style={styleforStyle}>Suma Asegurada de $20,000 pesos.</td>
                                    </tr>
                                    <tr>
                                        <td style={style}>Reparación de pantalla</td>
                                        <td style={styleforStyle}>1 evento durante la vigencia del plan, hasta por $4,000 pesos. Aplica copago de $400 pesos</td>
                                    </tr>
                                    <tr>
                                        <td style={style}>Descuentos</td>
                                        <td style={styleforStyle}>Club de descuentos en comercios participantes Ilimitado y sin costo</td>
                                    </tr>
                                    <tr>
                                        <td style={style}>Telemedicina</td>
                                        <td style={styleforStyle}>Atención con médico general Ilimitado y sin costo</td>
                                    </tr>
                                </tbody>
                            </div>
                            <br />
                        </div>
                        <br />
                        <br />

                        <div
                            style={{
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: 'normal',
                            }}
                        >
                            <br />
                            <br />
                            <TweleMount

                            >
                                Vigencia del programa: 12 meses.
                            </TweleMount>
                            <br />
                            <br />
                            <TweleMount

                            >
                                Coberturas de seguros:
                            </TweleMount>
                            <br />
                            <br />
                            <BlackTextButton

                            >
                                Muerte accidental:
                            </BlackTextButton>

                            <Typography variant="body1" >


                                Se pagará al Beneficiario designado la Suma Asegurada correspondiente a esta cobertura si ocurre el fallecimiento del Asegurado a consecuencia de un Accidente Cubierto dentro de los noventa (90) días siguientes a la fecha del mismo, siempre y cuando la Póliza se encuentre en vigor al momento del siniestro.
                                Será cubierta por una póliza de seguro, emitida por Chubb Seguros México S.A.
                            </Typography>
                            <br />
                            <br />
                            <BlackTextButton>
                                Suma Asegurada:
                            </BlackTextButton>


                            <Typography variant="body1">
                                $20,000.00 (veinte mil pesos 00/100 M.N.)
                                Edad de aceptación: de 18 a 69 años

                                Podrá consultar las Condiciones generales y particulares de la póliza de seguro:
                                Condiciones generales.
                            </Typography>
                            <br />
                            <br />

                            <BlackTextButton>
                                Edad de aceptación:
                            </BlackTextButton>

                            <Typography variant="body1">
                                de 18 a 69 años
                            </Typography>
                            <br />
                            <br />
                            <Typography variant="body1">
                                Podrá consultar las Condiciones generales y particulares de la póliza de seguro:
                            </Typography>

                            <br />
                            <br />

                            <Link href="https://www.chubb.com/content/dam/chubb-sites/chubb-com/mx-es/condiciones-generales/global/documents/pdf/a323-aba-as-seguro-de-accidentes-personales-colectivo.pdf"
                                target={'_blank'}>
                                Condiciones generales.

                            </Link>

                            <br />
                            <br />
                            <BlackTextButton>
                                Detalle de las coberturas de:
                            </BlackTextButton>
                            <br />
                            <br />
                            <BlackTextButton>
                                Asistencias:
                            </BlackTextButton>
                            <br />
                            <br />
                            <BlackTextButton>
                                Reparación de pantalla:
                            </BlackTextButton>

                            <Typography variant="body1">
                                Reparación de la pantalla en caso de la rotura, del celular que se haya registrado en el portal; siempre que el mismo ocurra mientras el celular esté siendo portado o usado por el Titular. Se reparará la pantalla del celular hasta por $4,000 (cuatro mil pesos M.N.), si el costo de la reparación es superior, el usuario tendrá que pagar el excedente de la reparación o, en su caso, rechazar el uso de la cobertura.

                                En caso de utilizar la asistencia, se contactará al titular, ya sea para cubrir el copago o el excedente de la reparación. Una vez confirmado el pago se visualizará en el portal y enviará por correo electrónico la siguiente información:

                            </Typography>

                            <Typography variant="body1">

                                -Nombre de la empresa de paquetería (quien recolectará el celular)
                                <br />
                                - Número de guía de la empresa de paquetería.
                                <br />
                                - Link de seguimiento del envío.
                            </Typography>

                            <br />
                            <br />
                            <BlackTextButton >
                                Entrega:                            </BlackTextButton>

                            <Typography variant="body1">
                                El Titular tendrá hasta 3 intentos para enviar su equipo con la empresa de paquetería.
                            </Typography>
                            <br />
                            <br />
                            <BlackTextButton >
                                Devolución:                          </BlackTextButton>

                            <Typography variant="body1">
                                Una vez reparado el equipo, se notificará por medio del portal y correo electrónico para gestionar la entrega del celular; en caso de que no tenga éxito (hasta 2 intentos) en la entrega del equipo en el domicilio proporcionado por el Titular, este será devuelto a las instalaciones del proveedor y el usuario tendrá que pagar los costos de envío.

                                Se visualizará en el portal y enviará por correo electrónico la siguiente información:

                                Nombre de la empresa de paquetería (quien recolectará el celular)
                                Número de guía de la empresa de paquetería.
                                Link de seguimiento del envío.
                            </Typography>
                            <br />
                            <br />
                            <BlackTextButton >
                                Límite de monto:                          </BlackTextButton>

                            <Typography variant="body1">
                                Hasta $4,000 (cuatro mil pesos M.N.)
                            </Typography>
                            <br />
                            <br />
                            <BlackTextButton>
                                Copago:                        </BlackTextButton>

                            <Typography variant="body1">
                                El titular deberá cubrir un copago de $400 pesos.                            </Typography>


                            <br />
                            <br />
                            <BlackTextButton>
                                Límite de eventos:                        </BlackTextButton>

                            <Typography variant="body1">
                                1 evento durante la vigencia del plan.                          </Typography>
                            <br />
                            <br />

                            <BlackTextButton>
                                Exclusiones:                       </BlackTextButton>

                            <Typography variant="body1">
                                1.-No son objeto de esta cobertura de asistencia equipos celulares con una antigüedad mayor a 3 años.
                                <br />
                                2.-Si el proveedor considera que no es posible continuar con la reparación por motivos Técnicos (equipos/ piezas descontinuadas u obsoletas o cualquier otro motivo) no será procedente, el usuario no perderá su evento y podrá solicitar el cambio del equipo registrado en el portal/certificado.
                                <br />
                                3.-Si la reparación no puede llevarse a cabo por que esta fuera del alcance o de cobertura, se indicará por medio de correo electrónico al usuario que la reparación requiere un costo adicional debido al modelo o daño registrado, se preguntará si desea continuar y en caso de que el usuario continue, se compartirá la guía de envío, si no desea continuar se concluye el proceso y no perderá el evento.
                                <br />
                                4.-Si en la validación inicial se detecta que no es el mismo equipo que se registró o algún patrón que indique que es un posible fraude, será rechazado y el usuario perderá el evento, ya sea en el modelo del Celular Protect de Regalo o Premium.
                                <br />
                                5.-El proveedor puede enviar de regreso el equipo en caso de que detecte patrones de fraude: Equipo alterado/modificado o que el Equipo/IMEI no correspondan al registrado previamente.
                                <br />
                                6.-La reparación es únicamente para pantalla y no aplica si los equipos tuvieron sobrecarga eléctrica y/o presencia de líquido, si fuera así, serán sujetos a la cancelación del evento.                        </Typography>
                            <br />

                            <br />
                            <br />

                            <BlackTextButton>
                                Descuentos:                     </BlackTextButton>

                            <Typography variant="body1">
                                Acceso a descuentos online en comercios de diversas categorías y subcategorías asociadas a la membresía del producto.

                                Para hacer válidos los descuentos se deberá verificar la ubicación (territorio nacional) y disponibilidad del comercio del club de descuentos dentro del portal de usuario a través de la geolocalización, pudiendo hacer válidos los descuentos dentro del establecimiento, presentando el cupón en sus diferentes presentaciones, según el comercio.
                                <br />
                                <br />
                            </Typography>

                            <BlackTextButton>
                                Límite de monto:                     </BlackTextButton>

                            <Typography variant="body1">
                                El descuento está sujeto al comercio de preferencia, así mismo el Titular será el responsable de cubrir los costos del producto/servicio adquirido.

                            </Typography>
                            <br />
                            <br />

                            <BlackTextButton>
                                Límite de eventos:                  </BlackTextButton>

                            <Typography variant="body1">
                                Ilimitado
                            </Typography>

                            <br />
                            <br />

                            <BlackTextButton>
                                Exclusiones:
                            </BlackTextButton>

                            <Typography variant="body1">
                                Promociones/descuentos que no se encuentren dentro del portal.             </Typography>

                            <br />
                            <br />

                            <BlackTextButton>
                                Telemedicina:
                            </BlackTextButton>

                            <Typography variant="body1">
                                Orientación en salud que recibirán los Titulares al acceder al portal, la cual se limitará a lo siguiente:
                                <br />
                                <br />
                                1.Servicio de orientación médica, con médico general, la cual no podrá ser considerará como una consulta médica presencial.
                                <br />
                                <br />
                                2.Expedición de receta médica, la cual se puede utilizar para adquirir los medicamentos prescritos por el médico en la farmacia de preferencia.
                                <br />
                                <br />
                                3.El servicio no reemplaza, sustituye o suple una consulta médica presencial.
                                <br />
                                <br />
                                4.En caso de ser necesario, usted deberá consultar a un médico o a una Institución con la capacidad necesaria para brindar la atención a su padecimiento.
                                <br />
                                <br />
                                5.Los consejos y opiniones emitidos por el personal no deben ser tomados como una opinión médica definitiva por lo que, en caso de tomarlos e implementarlos, será absolutamente bajo su responsabilidad.
                                <br />
                                <br />
                                La asesoría médica a la que se tendrá acceso a través del portal, es exclusivamente respecto de padecimientos de primer nivel de atención o atención primaria, siendo estos padecimientos de baja complejidad médica, cuyo objetivo y alcance es resolver las necesidades de atención básicas y más frecuentes que pueden ser resueltas por actividades de promoción de salud, prevención de la enfermedad y por procedimientos de recuperación y rehabilitación, sin que la atención de dichos padecimientos a través de la Plataforma pueda poner en peligro su vida y/o su integridad física.
                                El personal de salud que realiza las asesorías no se hace responsables por los potenciales efectos adversos y/o reacciones desfavorables derivados del uso de estos, incluyendo reacciones alérgicas.
                                <br />
                                <br />
                                En caso de que el padecimiento o sintomatología referida sea distinta a padecimientos de primer nivel o de atención primaria o se trate de una urgencia o emergencia médica, se podrá suspender la asesoría y referirlo de manera inmediata a un centro de salud o institución facultado para atender su padecimiento de forma adecuada, en salvaguarda de su salud e integridad física, no representando esto un incumplimiento al servicio.
                                <br />
                                <br />
                                El servicio de Telemedicina es prestado por profesionales de la salud que se encuentran legalmente facultados para ejercer la medicina en los Estados Unidos Mexicanos.
                                <br />
                                <br />
                                Dependiendo del tipo de padecimiento presentado o referido durante la asesoría, podría resultar necesario que muestre partes de su cuerpo al profesional que le estará atendiendo. Usted tiene plena potestad de decidir si muestra o no las partes de su cuerpo al médico en el entendido que:
                                <br />
                                <br />
                                1.Los médicos únicamente solicitarán la vista o exploración de alguna de las partes de su cuerpo cuando sea estrictamente necesario; y previo consentimiento verbal.
                                <br />
                                <br />
                                2.No almacenaremos y/o guardaremos imagen alguna dentro de nuestros expedientes; salvo las imágenes obtenidas durante la asesoría y relacionadas con la patología por la cual se está consultando y que forman parte de su expediente clínico.
                                <br />
                                <br />
                                3.Usted entiende y acepta que en caso de que se niegue a mostrar a nuestros médicos alguna parte de su cuerpo, la asesoría podría ser suspendida si el médico considera que no cuenta con elementos para emitir una opinión, no representado esto un incumplimiento a nuestros servicios.
                            </Typography>

                            <br />
                            <br />



                            <BlackTextButton>
                                Límite de eventos:                            </BlackTextButton>

                            <Typography variant="body1">
                                ilimitado, cada orientación médica tendrá una duración de hasta 25 minutos.          </Typography>
                            <br />
                            <br />
                            <BlackTextButton>
                                Exclusiones:
                            </BlackTextButton>

                            <Typography variant="body1">
                                <br />
                                <br />
                                - Se podrá suspender la consulta en caso de cometer faltas de respecto, o se mantengan conductas inadecuadas.
                                <br />
                                <br />

                                -La cesión de uso por parte del Titular a terceras personas no autorizadas por. Ejemplo: conectar para hacer una consulta de un no autorizado haciéndose pasar por el Titular.
                                <br />
                                <br />

                                -La explotación comercial de los servicios a los que tiene acceso. Ejemplo: persona o empresa que revende consultas utilizando este servicio.
                                <br />
                                <br />

                                -Cualquier forma de violación de los derechos de terceros. Entendiendo como tal que el Titular pretenda grabar al Prestador o a cualquier persona de su red medica con fines de lucro, para difundir los videos en algún medio digital, página web, canal de internet, redes sociales, etc.
                                <br />
                                <br />

                                -Utilizando el servicio para eludir responsabilidades, es decir, el informe expedido por el Prestador a través de su red medica nunca podrá funcionar como justificante médico.            </Typography>

                            <br />
                            <br />
                        </div>

                        <Chubb />

                    </AccordionDetails>

                </Accordion>

                <Accordion style={AcordionCustom} expanded={expanded === 'panel2'} onChange={handleChange('panel2')} >
                    <AccordionSummary expandIcon={expanded === 'panel2' ? < RemoveIcon /> : < QuestionsPlus />}
                    >
                        <Typography
                            style={{
                                color: 'var(--black-75, #595959)',
                                fontFamily: 'Montserrat',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 700,
                                lineHeight: 'normal',
                            }}

                        >Celular Protect Premium</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <div>
                                <thead>

                                    <th
                                        style={{
                                            border: '1px solid var(--Black, #212121)',
                                            background: 'var(--Black, #212121)',
                                            color: 'var(--White, #FFF)'
                                        }}
                                        colSpan={2}
                                    >Resumen de coberturas</th>


                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={style}>Concepto</td>
                                        <td style={style}>Detalle</td>
                                    </tr>
                                    <tr>
                                        <td style={style}>Muerte accidental</td>
                                        <td style={styleforStyle}>Suma Asegurada de $20,000 pesos.</td>
                                    </tr>
                                    <tr>
                                        <td style={style}>Robo del gadget con violencia</td>
                                        <td style={styleforStyle}>1 evento durante la vigencia del plan, hasta por $10,000 pesos. Aplica deducible del 20%</td>
                                    </tr>
                                    <tr>
                                        <td style={style}>Reparación de pantalla</td>
                                        <td style={styleforStyle}>1 evento durante la vigencia del plan, hasta por $4,000 pesos. Aplica copago de $400 pesos</td>
                                    </tr>
                                    <tr>
                                        <td style={style}>Descuentos</td>
                                        <td style={styleforStyle}>Club de descuentos en comercios participantes Ilimitado y sin costo</td>
                                    </tr>
                                    <tr>
                                        <td style={style}>Telemedicina</td>
                                        <td style={styleforStyle}>Atención con médico general Ilimitado y sin costo</td>
                                    </tr>
                                </tbody>
                            </div>
                            <br />
                            <br />
                        </div>

                        <br />

                        <BlackTextButton>
                            Vigencia del programa:
                        </BlackTextButton>
                        <TweleMount>
                            12 meses.
                        </TweleMount>
                        <br />
                        <br />
                        <BlackTextButton

                        >
                            Coberturas de seguros:
                        </BlackTextButton>
                        <br />
                        <br />
                        <BlackTextButton

                        >
                            Muerte accidental:
                        </BlackTextButton>
                        <br />
                        <br />
                        <Exclusions>
                            Cobertura:                             </Exclusions>
                        <TweleMount>
                            Se pagará al Beneficiario designado la Suma Asegurada correspondiente a esta cobertura si ocurre el fallecimiento del Asegurado a consecuencia de un Accidente Cubierto dentro de los noventa (90) días siguientes a la fecha del mismo, siempre y cuando la Póliza se encuentre en vigor al momento del siniestro.
                            Será cubierta por una póliza de seguro, emitida por Chubb Seguros México S.A
                        </TweleMount>
                        <br />
                        <br />

                        <BlackTextButton>
                            Suma Asegurada:                           </BlackTextButton>
                        <TweleMount>
                            $20,000.00 pesos (veinte mil  M.N.)
                        </TweleMount>

                        <br />
                        <br />
                        <BlackTextButton>
                            Edad de aceptación:
                        </BlackTextButton>

                        <TweleMount>
                            de 18 a 69 años
                        </TweleMount>
                        <br />
                        <br />
                        <TweleMount>
                            Podrá consultar las Condiciones generales y particulares de la póliza de seguro:
                        </TweleMount>

                        <br />
                        <br />

                        <Link href="https://www.chubb.com/content/dam/chubb-sites/chubb-com/mx-es/condiciones-generales/global/documents/pdf/a323-aba-as-seguro-de-accidentes-personales-colectivo.pdf"
                            target={'_blank'}>
                            Condiciones generales.

                        </Link>

                        <br />
                        <br />

                        <BlackTextButton>
                            Robo total con violencia del gadget:                        </BlackTextButton>
                        <br />
                        <br />

                        <Exclusions>
                            Cobertura:                 </Exclusions>

                        <TweleMount>
                            Cubre al Gadget Asegurado contra la Pérdida Total a consecuencia de Robo con Violencia, mientras el Gadget esté siendo portado o usado por el Asegurado y fuera de su casa habitación. Se indemnizará el Gadget a un valor de mercado al momento del siniestro aplicando una depreciación del 17.5% anual sobre la factura de compra, al momento del Siniestro, sin exceder de la Suma Asegurada en vigor.
                            Será cubierta por una póliza de seguro, emitida por Zurich Aseguradora Mexicana S.A. de C.V.
                        </TweleMount>
                        <br />
                        <br />
                        <BlackTextButton>
                            Suma Asegurada:                  </BlackTextButton>

                        <TweleMount>
                            hasta $10,000.00 pesos (diez mil pesos 00/100 M.N.).
                        </TweleMount>
                        <br />
                        <br />
                        <BlackTextButton>
                            Aceptación:                      </BlackTextButton>

                        <TweleMount>
                            El gadget no debe rebasar el límite de 3 años de antigüedad                           </TweleMount>

                        <br />
                        <br />
                        <BlackTextButton>
                            Periodo de espera:                      </BlackTextButton>

                        <TweleMount>
                            90 días                         </TweleMount>

                        <br />
                        <br />
                        <TweleMount>
                            Podrá consultar las Condiciones generales y particulares de la póliza de seguro:
                            condiciones generales                        </TweleMount>
                        <Link href="https://zam.zurich.com.mx/-/media/project/zwp/zam/docs/regulaciones/2020/condiciones-generales-gadget-protection---abril-2020.pdf"
                            target={'_blank'}>
                            Condiciones generales.

                        </Link>
                        <br />
                        <br />

                        <BlackTextButton>
                            Asistencias:                       </BlackTextButton>
                        <br />
                        <br />


                        <BlackTextButton>
                            Reparación de pantalla:                  </BlackTextButton>



                        <Exclusions>
                            Asistencia:              </Exclusions>

                        <TweleMount>
                            Reparación de la pantalla en caso de la rotura, del celular que se haya registrado en el portal; siempre que el mismo ocurra mientras el celular esté siendo portado o usado por el Titular. Se reparará la pantalla del celular hasta por $4,000.00 pesos (cuatro mil M.N.), si el costo de la reparación es superior, el usuario tendrá que pagar el excedente de la reparación o, en su caso, rechazar el uso de la cobertura.

                            En caso de utilizar la asistencia, se contactará al titular, ya sea para cubrir el copago y/o el excedente de la reparación. Una vez confirmado el pago se visualizará en el portal y enviará por correo electrónico la siguiente información:

                            - Nombre de la empresa de paquetería (quien recolectará el celular)
                            <br />
                            - Número de guía de la empresa de paquetería.
                            <br />
                            -Link de seguimiento del envío.
                            <br />
                        </TweleMount>
                        <br />

                        <br />


                        <BlackTextButton>
                            Entrega:
                        </BlackTextButton>

                        <TweleMount>
                            El Titular tendrá hasta 3 intentos para enviar su equipo con la empresa de paquetería.
                        </TweleMount>
                        <br />
                        <br />

                        <BlackTextButton>
                            Devolución:
                        </BlackTextButton>

                        <TweleMount>
                            Una vez reparado el equipo, se notificará por medio del portal y correo electrónico para gestionar la entrega del celular; en caso de que no tenga éxito (hasta 2 intentos) en la entrega del equipo en el domicilio proporcionado por el Titular, este será devuelto a las instalaciones del proveedor y el usuario tendrá que pagar los costos de envío.

                            Se visualizará en el portal y enviará por correo electrónico la siguiente información:
                            <br />
                            - Nombre de la empresa de paquetería (quien recolectará el celular)
                            <br />
                            - Número de guía de la empresa de paquetería.
                            <br />
                            - Link de seguimiento del envío.
                        </TweleMount>
                        <br />
                        <br />

                        <BlackTextButton>
                            Límite de monto:             </BlackTextButton>

                        <TweleMount>
                            Hasta $4,000.00 pesos (cuatro mil M.N.)
                        </TweleMount>
                        <br />
                        <br />

                        <Exclusions>
                            Copago:
                        </Exclusions>

                        <TweleMount>
                            El titular deberá cubrir un copago de $400.00 pesos.          </TweleMount>

                        <br />
                        <br />


                        <BlackTextButton>
                            Límite de eventos:
                        </BlackTextButton>

                        <TweleMount>
                            1 evento durante la vigencia del plan.:
                            <br />
                        </TweleMount>
                        <br />
                        <br />

                        <Exclusions>
                            Exclusiones:                          </Exclusions>
                        <br />
                        <br />


                        <Exclusions>
                            1-Si el proveedor considera que no es posible continuar con la reparación por motivos Técnicos (equipos/ piezas descontinuadas u obsoletas o cualquier otro motivo) no será procedente, el usuario no perderá su evento y podrá solicitar el cambio del equipo registrado en el portal/certificado.
                            <br />
                            <br />

                            2-Si la reparación no puede llevarse a cabo por que esta fuera del alcance o de cobertura, se indicará por medio de correo electrónico al usuario que la reparación requiere un costo adicional debido al modelo o daño registrado, se preguntará si desea continuar y en caso de que el usuario continue, se compartirá la guía de envío, si no desea continuar se concluye el proceso y no perderá el evento.
                            <br />
                            <br />

                            3-Si en la validación inicial se detecta que no es el mismo equipo que se registró o algún patrón que indique que es un posible fraude, será rechazado y el usuario perderá el evento, ya sea en el modelo del Celular Protect de Regalo o Premium.
                            <br />
                            <br />

                            4-El proveedor puede enviar de regreso el equipo en caso de que detecte patrones de fraude: Equipo alterado/modificado o que el Equipo/IMEI no correspondan al registrado previamente.
                            <br />
                            <br />

                            5-La reparación es únicamente para pantalla y no aplica si los equipos tuvieron sobrecarga eléctrica y/o presencia de líquido, si fuera así, serán sujetos a la cancelación del evento.
                            <br />
                            <br />
                        </Exclusions>

                        <br />
                        <br />

                        <BlackTextButton>
                            Descuentos:                         </BlackTextButton>
                        <br />

                        <Exclusions>
                            Asistencia:                     </Exclusions>
                        <br />

                        <TweleMount>
                            Acceso a descuentos online en comercios de diversas categorías y subcategorías asociadas a la membresía del producto.

                            Para hacer válidos los descuentos se deberá verificar la ubicación (territorio nacional) y disponibilidad del comercio del club de descuentos dentro del portal de usuario a través de la geolocalización, pudiendo hacer válidos los descuentos dentro del establecimiento, presentando el cupón en sus diferentes presentaciones, según el comercio.
                        </TweleMount>

                        <br />
                        <br />
                        <BlackTextButton>
                            Límite de monto:                       </BlackTextButton>
                        <br />

                        <Exclusions>
                            El descuento está sujeto al comercio de preferencia, así mismo el Titular será el responsable de cubrir los costos del producto/servicio adquirido.
                        </Exclusions>
                        <br />


                        <BlackTextButton>
                            Límite de eventos:                   </BlackTextButton>
                        <br />

                        <TweleMount>
                            Ilimitado                    </TweleMount>
                        <BlackTextButton>
                            Exclusiones:
                        </BlackTextButton>


                        <Exclusions>
                            Promociones/descuentos que no se encuentren dentro del portal.             </Exclusions>

                        <BlackTextButton>
                            Telemedicina:                    </BlackTextButton>

                        <br />
                        <br />
                        <br />
                        <Exclusions>
                            Asistencia:           </Exclusions>

                        <TweleMount>
                            Orientación en salud que recibirán los Titulares al acceder al portal, la cual se limitará a lo siguiente:
                            <br />

                            1- Servicio de orientación médica, con médico general, la cual no podrá ser considerará como una consulta médica presencial.
                            <br />
                            2-Expedición de receta médica, la cual se puede utilizar para adquirir los medicamentos prescritos por el médico en la farmacia de preferencia.
                            <br />
                            3- El servicio no reemplaza, sustituye o suple una consulta médica presencial.
                            <br />
                            4-En caso de ser necesario, usted deberá consultar a un médico o a una Institución con la capacidad necesaria para brindar la atención a su padecimiento.
                            <br />
                            5- Los consejos y opiniones emitidos por el personal no deben ser tomados como una opinión médica definitiva por lo que, en caso de tomarlos e implementarlos, será absolutamente bajo su responsabilidad.
                        </TweleMount>

                        <TweleMount>

                            La asesoría médica a la que se tendrá acceso a través del portal, es exclusivamente respecto de padecimientos de primer nivel de atención o atención primaria, siendo estos padecimientos de baja complejidad médica, cuyo objetivo y alcance es resolver las necesidades de atención básicas y más frecuentes que pueden ser resueltas por actividades de promoción de salud, prevención de la enfermedad y por procedimientos de recuperación y rehabilitación, sin que la atención de dichos padecimientos a través de la Plataforma pueda poner en peligro su vida y/o su integridad física.
                            El personal de salud que realiza las asesorías no se hacen responsables por los potenciales efectos adversos y/o reacciones desfavorables derivados del uso de estos, incluyendo reacciones alérgicas.
                            <br />
                            En caso de que el padecimiento o sintomatología referida sea distinta a padecimientos de primer nivel o de atención primaria o se trate de una urgencia o emergencia médica, se podrá suspender la asesoría y referirlo de manera inmediata a un centro de salud o institución facultado para atender su padecimiento de forma adecuada, en salvaguarda de su salud e integridad física, no representando esto un incumplimiento al servicio.
                            <br />
                            <br />
                            El servicio de Telemedicina es prestado por profesionales de la salud que se encuentran legalmente facultados para ejercer la medicina en los Estados Unidos Mexicanos.
                            <br />
                            <br />
                            Dependiendo del tipo de padecimiento presentado o referido durante la asesoría, podría resultar necesario que muestre partes de su cuerpo al profesional que le estará atendiendo. Usted tiene plena potestad de decidir si muestra o no las partes de su cuerpo al médico en el entendido que:

                        </TweleMount>

                        <TweleMount>

                            1-Los médicos únicamente solicitarán la vista o exploración de alguna de las partes de su cuerpo cuando sea estrictamente necesario; y previo consentimiento verbal.
                            <br />
                            2-No almacenaremos y/o guardaremos imagen alguna dentro de nuestros expedientes; salvo las imágenes obtenidas durante la asesoría y relacionadas con la patología por la cual se está consultando y que forman parte de su expediente clínico.
                            <br />
                            3-Usted entiende y acepta que en caso de que se niegue a mostrar a nuestros médicos alguna parte de su cuerpo, la asesoría podría ser suspendida si el médico considera que no cuenta con elementos para emitir una opinión, no representado esto un incumplimiento a nuestros servicios.
                            <br />
                        </TweleMount>

                        <BlackTextButton>
                            Límite de eventos:                 </BlackTextButton>
                        <br />

                        <TweleMount>
                            ilimitado, cada orientación médica tendrá una duración de hasta 25 minutos.                </TweleMount>


                        <br />
                        <br />
                        <BlackTextButton>
                            Exclusiones:               </BlackTextButton>
                        <br />

                        <TweleMount>
                            -Se podrá suspender la consulta en caso de cometer faltas de respecto, o se mantengan conductas inadecuadas.
                            <br />
                            <br />
                            -La cesión de uso por parte del Titular a terceras personas no autorizadas por. Ejemplo: conectar para hacer una consulta de un no autorizado haciéndose pasar por el Titular.
                            <br />
                            <br />
                            -La explotación comercial de los servicios a los que tiene acceso. Ejemplo: persona o empresa que revende consultas utilizando este servicio.
                            <br />
                            <br />
                            -Cualquier forma de violación de los derechos de terceros. Entendiendo como tal que el Titular pretenda grabar al Prestador o a cualquier persona de su red medica con fines de lucro, para difundir los videos en algún medio digital, página web, canal de internet, redes sociales, etc.
                            <br />
                            <br />
                            -Utilizando el servicio para eludir responsabilidades, es decir, el informe expedido por el Prestador a través de su red medica nunca podrá funcionar como justificante médico.
                        </TweleMount>
                        <br />
                        <br />

                        <Chubb />

                    </AccordionDetails>
                </Accordion>

                {coberturas.map(cob => <AccordionContent data={cob} expanded={expanded} handleChange={handleChange} />)}
            </div>
        </div>);
}

export default TermsAndConditionsContent;