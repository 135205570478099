import React, {useEffect, useState} from "react";
import CustomContainer from "../../organisms/Container/Container";
import {useLocation, useNavigate} from 'react-router-dom';
import {CustomTypography} from "../../atoms/Label/Label";
import {styled} from "styled-components";
import  {Box} from "@mui/material";
import {OutlinedButton, PrimaryButton} from "../../molecules/CellPhoneRepairButtons";

import validation from "../../../assets/payment/gifgif.gif";
import errorGift from "../../../assets/RegisterAssets/errorgif.gif";


const LayOutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 558px;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 80px;
`;

export const download = (link:string) => {
    let element = document.createElement('a');
    element.setAttribute('href', link);
    element.setAttribute('target', '_blank')
    element.setAttribute( "rel","noopener noreferre")
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

const PasswordResetResponse: React.FC = () => {
    const navigate = useNavigate();
const[error, setError]= useState(true);
    const location = useLocation();
    const locationState = (location.state as {status? : boolean});

// get userId
    let status = locationState?.status;

    useEffect(() => {
        //alert(JSON.stringify(status))
     // @ts-ignore
        setError(status)
    },[status])


    return (
        <CustomContainer childComponent={
            <LayOutContainer>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Box component={"img"} sx={{width:{xs:250,md:300,lg:400}}}  src={error ? errorGift : validation} alt="validation" />
                </Box>
                <CustomTypography
                    style={{
                        fontSize: 28,
                        fontWeight: 700,
                        letterSpacing: "0.5px",
                        textAlign: "center",
                        Padding: "0 1rem",
                        textWrap: "balance",
                    }}
                >
                    {error ?  " ¡Oops! Tuvimos un problema al cambiar tu contraseña" : "¡Tu contraseña se cambió con éxito!"}
                </CustomTypography>

                <CustomTypography
                    style={{
                        fontSize: 14,
                        fontWeight: 400,
                        fontStyle: "normal",
                        letterSpacing: "0.5px",
                        textAlign: "center",
                        Padding: "0 1rem",
                        textWrap: "balance",
                    }}
                >
                    { error ?  "No te preocupes, a veces se presentan algunos problemas al realizar el cambio, puedes reintentarlo o si prefieres reportarlo a nuestra mesa de ayuda"
                        :
                        "Todo listo, ya podrás iniciar sesión en tu portal de cliente y seguir disfrutando todos tus beneficios"}
                </CustomTypography>
                <Box sx={{mt: 5}}/>
                <PrimaryButton text={error?"reintentar" : "Iniciar sesión"} onClick={() => {
                    error ? navigate(-1) :  navigate("/iniciar-sesion")
                }}/>
                {error &&
                <OutlinedButton text={"reportar problema"} onClick={() => {
                       download("https://intermx-digital.atlassian.net/servicedesk/customer/portal/8/group/34/create/100")
                }}/>
                }
            </LayOutContainer>
        }/>
    );
};

export default PasswordResetResponse;
