import React from 'react';
import { styled } from "styled-components";

import { useMediaQuery, useTheme, Tabs, Tab } from "@mui/material";
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import AccordeonCustomized from '../../organisms/Acordeon';
import preguntas from '../../../assets/AcordeonTitle/FAQs/preguntas.jpg';
import frecuentes from '../../../assets/AcordeonTitle/FAQs/frecuentes.jpg';

import preguntas2 from '../../../assets/AcordeonTitle/FAQs/preguntas2.svg';
import frecuentes2 from '../../../assets/AcordeonTitle/FAQs/frecuentes2.svg';
import FAQS from '../../../assets/AcordeonTitle/FAQs/preguntas-frecuentes-finsus.png';
import { pageData } from './FAQs'

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 80px;
  gap: 40px;
  align-items: flex-start;
  max-width: 946px;
`;

const SPANTitleBolded = styled.span`
    text-align: center;
    font-size: 44px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #039ECC;
`;

const SPANTitleTransparent = styled(SPANTitleBolded)`
    color: transparent; /* Hace que el color del texto sea transparente */
    background: none; /* Elimina cualquier fondo, para que el interior sea transparente */
    outline: none; /* Elimina el resplandor al hacer clic en el input */
    -webkit-text-fill-color: white;
    -webkit-text-stroke: 1px #039ECC; /* Agrega un borde azul al texto en navegadores webkit */
`;

const InterFAQs: React.FC = () => {
    const [page, setPage] = React.useState(0);
    const [filterData, setFilterData] = React.useState([])
    const [data, setData] = React.useState([]);
    const [search, setSearch] = React.useState('');
    const { breakpoints } = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));

    React.useEffect(() => {
        if (search === '') {
            setPage(0)
            setData(pageData[0])
        }
    }, [search])

    React.useEffect(() => {
        setData(pageData[page])
    }, [page]);

    const handleChange = (event: string, value: number) => {
        setPage(event === "page" ? value - 1 : value);
    };

    const handleChangeInput = (event: any) => {
        const value = event?.target?.value
        setSearch(value)
        const arr = concatArray(pageData)

        const filterData = arr.filter((message: any) => {
            const title = message.title.toLowerCase()
            return title.includes(value.toLowerCase())
        })

        const timeOutId = setTimeout(() => setFilterData(filterData), 500);
        return () => clearTimeout(timeOutId);
    }

    const concatArray = (array: any[]) => {
        let res: any = [];
        array.map(arr => res.push(...arr))

        return res;
    }
    const isSearching = search !== ''

    return (
        <React.Fragment>

            <FormWrapper>
                {/* <label style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <SPANTitleTransparent>
                        <b>preguntas</b>
                    </SPANTitleTransparent>
                    <SPANTitleBolded><b>frecuentes</b></SPANTitleBolded>
                </label> */}
                <div style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    gap: 12,
                    width: isMobile ? '100%' : '100%',
                    justifyContent: isMobile ? 'flex-start' : 'center'
                }}>


                    {window.location.pathname !== "/finsus/preguntas-frecuentes" &&
                        <>
                            <img src={preguntas} alt="Preguntas Frecuentes" />
                            <img src={frecuentes} alt="Preguntas Frecuentes" />
                        </>


                    }

                    {window.location.pathname === "/finsus/preguntas-frecuentes" &&
                        <>

                            <img src={preguntas2} alt="Preguntas Frecuentes" />
                            <img src={frecuentes2} alt="Preguntas Frecuentes" />
                        </>


                    }




                </div>

                <TextField
                    label="Buscar"
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                    onChange={handleChangeInput}
                />

                <div>

                </div>

                <AccordeonCustomized data={!isSearching ? data : filterData} />
                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Stack spacing={2}>
                        <Pagination
                            color="primary"
                            count={!isSearching ? pageData.length : 0}
                            page={page + 1}
                            onChange={(event, page) => { handleChange("page", page) }}
                            sx={window.location.pathname === "/finsus/preguntas-frecuentes" ?
                                {
                                    '.MuiPaginationItem-root.Mui-selected': {
                                        background: '#212121',
                                        color: '#FFF'
                                    }
                                } :
                                {
                                }

                            }
                        />
                    </Stack>
                </div>
            </FormWrapper>
        </React.Fragment>
    );
};

export default InterFAQs;
