import React, { useEffect, useState, FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomContainer from '../../components/organisms/Container/Container';
import AccidentalDeathComponent from '../../components/organisms/AccidentalDeath/AccidentalDeathComponent';
import CustomTab from '../../components/organisms/CustomTabs';
import { CustomTypography } from '../../components/atoms/Label/Label';
import AccordeonCustomized from '../../components/organisms/Acordeon/AccordeonAction';
import { CustomButton } from '../../components/atoms/Buttons/Buttons';
import BackButtonInter from '../../components/atoms/Buttons/BackButtonInter';
import RenderAlert from '../../components/organisms/Modal';
import { restartValue, apiSuccess } from '../../api/multipleBeneficiarySlice';
import useFetch from '../../hooks/useFetch';

import {
    apiSuccess as apiUserSuccess,
    apiError as apiUserError,
    apiLoading as apiUserLoading
} from '../../api/userSlice';
import BasicModal from '../../components/molecules/BasicModal/BasicModal';
import BeneficiaryModal from './Beneficiary/Modals/BeneficiaryModal';
import { MessageModal, MessageType } from './Beneficiary/Modals/interfaces';
import { Grid, useMediaQuery, useTheme } from "@mui/material";

const AccidentalDeathPage: FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { breakpoints } = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const { fetchData } = useFetch();
    const responseUser = useSelector((state: any) => state.user.response?.data?.beneficiaries) as any | null;
    const responseBeneficiary = useSelector((state: any) => state.multipleBeneficiaries) as any | null;
    const [beneficiaries, setBeneficiaries] = useState<Array<any>>([]);
    const [totalPercentage, setTotalPercentage] = useState<number>(0);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [valuesModal, setValuesModal] = useState<MessageType>(MessageModal['CREATED']);
    // console.log(location)

    const closeModal = () => {
        setOpenModal(false)
        dispatch(restartValue())
    }

    useEffect(() => {
        if (location?.state?.showModal) {
            if (responseBeneficiary?.response?.ok || responseBeneficiary.error) {
                renderTextAlert()
                setOpenModal(true)
            }
        }
    }, [responseBeneficiary])

    useEffect(() => {
        getUser()
    }, []);

    useEffect(() => {
        if (responseUser) {
            const beneficiariesJSON = responseUser?.map((b: any, index: any) => {
                return {
                    // title: `Beneficiario ${index + 1}`,
                    title: `${b.name} ${b.middle_name}`,
                    expanded: true,
                    description: renderDescription(b),
                    json: b
                }
            })

            const porcentages = responseUser?.reduce((acc: any, val: any) => {
                return acc + val.porcentage
            }, 0)

            setBeneficiaries(beneficiariesJSON)
            setTotalPercentage(porcentages)
        }
    }, [responseUser])

    const renderTextAlert = () => {
        const { response, error } = responseBeneficiary;
        // const { Beneficiarys } = responseUser;

        let option = 'CREATED'

        if (response.data === 'El beneficiario se guardo de forma correcta' && responseUser.length <= 0) option = 'CREATED';
        if (response.data === 'El beneficiario se guardo de forma correcta') option = 'UPDATED';
        if (response.data === 'El beneficiario se guardo de forma correcta') option = 'ADDED';
        if (response.data === 'El beneficiario se elimino de forma correcta') option = 'DELETED';
        if (!!error) option = 'ERROR'

        setValuesModal(MessageModal[option])
    }

    const getUser = () => {
        return fetchData('users/auth/get-user', 'GET', {}, {}, apiUserLoading, apiUserSuccess, apiUserError);
    }

    const renderDescription = ({
        name,
        last_name,
        middle_name,
        birthdate,
        porcentage = 0,
        relationship,
        email,
        phone
    }: any) => {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 17
            }}>
                <label style={{ textAlign: 'start', fontSize: '14px' }}><b>Nombre(s): </b><span>{name}</span></label>
                <label style={{ textAlign: 'start', fontSize: '14px' }}><b>Primer Apellido: </b><span>{middle_name}</span></label>
                <label style={{ textAlign: 'start', fontSize: '14px' }}><b>Segundo Apellido: </b><span>{last_name}</span></label>
                <label style={{ textAlign: 'start', fontSize: '14px' }}><b>Fecha de
                    nacimiento: </b><span>{birthdate}</span></label>
                <label style={{
                    textAlign: 'start',
                    fontSize: '14px'
                }}><b>Parentesco: </b><span>{relationship}</span></label>
                <label style={{ textAlign: 'start', fontSize: '14px' }}><b>Correo
                    electrónico: </b><span>{email}</span></label>
                <label style={{ textAlign: 'start', fontSize: '14px' }}><b>Número de
                    celular: </b><span>{phone}</span></label>
                <span
                    style={{ textAlign: 'start', fontSize: '14px' }}><b>Porcentaje: </b><span>{porcentage}%</span></span>
            </div>
        )
    }

    const editIconHandler = (json: any, to: any) => {
        const porcentage = json?.porcentage ?? 0

        dispatch(apiSuccess(json))
        navigate('beneficiario', {
            state: {
                porcentage: totalPercentage - porcentage,
                beneficiaries: beneficiaries.length,
                to
            }
        })
    }

    const showAlert = () => {
        RenderAlert({
            severity: 'info',
            message: totalPercentage === 100 ? "has usado el 100% del porcentaje para tus beneficiarios" : "has agregado el numero maximo de Beneficiarios"
        })
    }

    const handleClick = () => {
        if (totalPercentage >= 100 || beneficiaries.length >= 5) {
            showAlert()
        } else {
            dispatch(restartValue())
            navigate('beneficiario',
                {
                    state:
                        { porcentage: totalPercentage, beneficiaries: beneficiaries.length }
                })
        }
    }

    return (
        <CustomContainer childComponent={
            <>
                <BasicModal
                    styleRoot={{
                        maxWidth: 728,
                        boxShadow: "none",
                        borderRadius: 0,
                    }}
                    styleContent={{
                        margin: 0,
                        padding: 0,
                    }}
                    closeButton
                    open={openModal}
                    setOpenModal={closeModal}
                    bodyDialog={<BeneficiaryModal message={valuesModal} setOpenModal={closeModal} />}
                />
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{ maxWidth: 558, margin: 'auto' }}
                >
                    <div style={{ width: '100%', marginTop: '40px', display: 'flex', flexDirection: 'column' }}>
                        <BackButtonInter path='/portal-del-cliente' />
                        <CustomTab data={[
                            {
                                label: 'Coberturas',
                                content: <AccidentalDeathComponent />
                            },
                            {
                                label: 'Beneficiarios',
                                content: <>
                                    <Grid sx={{ padding: isMobile ? "5px" : '0 50px 20px' }}>
                                        <CustomTypography
                                            item={"Mis Beneficiarios"}
                                            style={{
                                                fontSize: 24,
                                                fontWeight: 700,
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                textTransform: 'inherit',
                                                letterSpacing: '0.5px',
                                                justifyContent: 'center',
                                                marginBottom: '5px'
                                            }}
                                        />
                                        <CustomTypography
                                            item={"Puedes añadir hasta 5 beneficiarios"}
                                            style={{
                                                fontSize: 16,
                                                fontWeight: 400,
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                textTransform: 'inherit',
                                                justifyContent: 'center'
                                            }}
                                        />
                                    </Grid>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                        padding: 8
                                    }}>
                                        <CustomButton
                                            text={"Añadir beneficiario"}
                                            onClick={handleClick}
                                            size={"medium"}
                                            startIcon={<>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                    viewBox="0 0 20 20" fill="none">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M10 1.33333C14.7865 1.33333 18.6667 5.21353 18.6667 10C18.6667 14.7865 14.7865 18.6667 10 18.6667C5.21353 18.6667 1.33333 14.7865 1.33333 10C1.33333 5.21353 5.21353 1.33333 10 1.33333ZM20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10Z"
                                                        fill="#039ECC" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M15 10C15 9.44772 14.6802 9 14.2857 9H5.71428C5.3198 9 5 9.44772 5 10C5 10.5523 5.3198 11 5.71428 11H14.2857C14.6802 11 15 10.5523 15 10Z"
                                                        fill="#039ECC" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M10 5C9.44772 5 9 5.3198 9 5.71429V14.2857C9 14.6802 9.44772 15 10 15C10.5523 15 11 14.6802 11 14.2857V5.71429C11 5.3198 10.5523 5 10 5Z"
                                                        fill="#039ECC" />
                                                </svg>
                                            </>}
                                            variant={"contained"}
                                            style={{
                                                color: "#039ECC",
                                                backgroundColor: "transparent",
                                                fontSize: "1rem",
                                                textTransform: "none",
                                                fontWeight: "600",
                                                fontStyle: "normal",
                                                padding: "8px 10px",
                                                minWidth: "64px",
                                                border: "none",
                                                justifyContent: "flex-start",
                                                width: "fit-content",
                                            }}
                                        />
                                    </div>
                                    <AccordeonCustomized data={beneficiaries} editIconHandler={editIconHandler}
                                        getUser={getUser} />
                                </>

                            }]} />
                    </div>
                </Grid>
            </>}
        />
    )
}

export default AccidentalDeathPage;