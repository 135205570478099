import { MembershipCategory } from "../Membership/MembershipMainValues";

const FORMDATA = {
    DatosPersonales: {
        Age: {
            [MembershipCategory.celular]: {
                min: 18,
                max: 69
            },
            [MembershipCategory.salud]: {
                min: 18,
                max: 69
            }

        }
    }
}

export default FORMDATA;