import React, { useEffect } from 'react';
import { useField } from 'formik';
import FormItemGenerate from '../../atoms/FormItems/FormItemGenerate';
import { styled } from 'styled-components';
import { CustomTypography } from '../../atoms/Label/Label';
import { useSelector } from 'react-redux';
import { IUserData } from '../../organisms/CustomerPortalContent/interfaces/user';
import { Box } from '@mui/material';

type CellPhoneDataDetailsProps = {
    data: Array<object>;
}

const ContentStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`;

const CellPhoneDataDetails: React.FC<CellPhoneDataDetailsProps> = ({ data }) => {
    const userData: IUserData = useSelector((state: any) => state.user.response.data);


    return (
        <ContentStyled>
            {
                userData && <Box>
                    <ContentStyled style={{ gap: 8 }}>

                        <CustomTypography
                            style={{
                                fontSize: 14,
                                fontStyle: 'normal',
                                textAlign: 'center',
                                fontWeight: 400,
                                maxWidth: 328,
                                lineHeight: 'normal',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                color: 'var(--Azul-Inter, #039ECC)'
                            }}
                        >Marca</CustomTypography>
                        <CustomTypography
                            style={{
                                fontSize: 16,
                                fontStyle: 'normal',
                                textAlign: 'center',
                                fontWeight: 400,
                                maxWidth: 328,
                                lineHeight: 'normal',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignSelf: 'stretch'
                            }}
                        >{userData.gadgets.id_brand}
                        </CustomTypography>

                        <CustomTypography
                            style={{
                                fontSize: 14,
                                fontStyle: 'normal',
                                textAlign: 'center',
                                fontWeight: 400,
                                maxWidth: 328,
                                lineHeight: 'normal',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                color: 'var(--Azul-Inter, #039ECC)'
                            }}
                        >Modelo</CustomTypography>
                        <CustomTypography
                            style={{
                                fontSize: 16,
                                fontStyle: 'normal',
                                textAlign: 'center',
                                fontWeight: 400,
                                maxWidth: 328,
                                lineHeight: 'normal',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignSelf: 'stretch'
                            }}
                        >{userData.gadgets.id_line}
                        </CustomTypography>

                        <CustomTypography
                            style={{
                                fontSize: 14,
                                fontStyle: 'normal',
                                textAlign: 'center',
                                fontWeight: 400,
                                maxWidth: 328,
                                lineHeight: 'normal',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                color: 'var(--Azul-Inter, #039ECC)'
                            }}
                        >Descripción</CustomTypography>
                        <CustomTypography
                            style={{
                                fontSize: 16,
                                fontStyle: 'normal',
                                textAlign: 'center',
                                fontWeight: 400,
                                maxWidth: 328,
                                lineHeight: 'normal',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignSelf: 'stretch'
                            }}
                        >{userData.gadgets.id_model}
                        </CustomTypography>

                        <CustomTypography
                            style={{
                                fontSize: 14,
                                fontStyle: 'normal',
                                textAlign: 'center',
                                fontWeight: 400,
                                maxWidth: 328,
                                lineHeight: 'normal',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                color: 'var(--Azul-Inter, #039ECC)'
                            }}
                        >IMEI</CustomTypography>
                        <CustomTypography
                            style={{
                                fontSize: 16,
                                fontStyle: 'normal',
                                textAlign: 'center',
                                fontWeight: 400,
                                maxWidth: 328,
                                lineHeight: 'normal',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignSelf: 'stretch'
                            }}
                        >{userData.gadgets.imei}
                        </CustomTypography>
                    </ContentStyled>
                </Box>

            }
        </ContentStyled>
    )
};

export default CellPhoneDataDetails;