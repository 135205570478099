import React from 'react';
import { Box } from '@mui/material';
import CustomContainer from '../../organisms/Container/Container';
import ContentComponent from './JoinRevolution';
import { themeValues, myColorPalette } from '../../../theme/themeGenerated';


const HowItWorks: React.FC = () => {

    return (
        <Box sx={{
            backgroundColor: window.location.pathname === "/finsus" ? myColorPalette.getFinsusRgb() :
                '#F2FAFC'
        }}>
            <CustomContainer childComponent={<ContentComponent />} />
        </Box>
    )
};

export default HowItWorks;
