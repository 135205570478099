import ReactDOM from 'react-dom'
import { ModalProps, IAlertData, IAlertData2, ModalProps2 } from './interfaces';
import React, { useEffect } from "react";
import ManiCelebrating from "../../../assets/ManiCelebrateSuccessRegitration/maniCelebrating.svg";

import { MembershipColor } from "../../organisms/Membership/MembershipMainValues";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import BasicModalContent from "../../molecules/BasicModal/BasicModalContent/BasicModalContent";
import { styled } from "styled-components";
import { CardMedia, CardContent, Card, Typography } from "@mui/material";
import { CustomButton } from "../../atoms/Buttons/Buttons";
import ManiPosesModal from "../../../assets/CustomerPortalAssets/ManiposesModal.svg";
import ManiPoseBase from "./assets/maniPose.png";

import { useNavigate } from "react-router-dom";
import { CapitalizeText } from '../../../utils/utils';



const CardHeader = styled.div`
  borderradius: 8px 8px 0px 0px;
  background: #ffd56b;
  border-bottom: 1px solid #fff;
  @media (min-width: 600px) {
    border-bottom: none;
    border-right: 1px solid #fff;
  }
`;

type CardActionProps = {
    cardType?: string,
    cardCategory?: string,
    cardTitle?: string,
    cardDescription?: string,
    subType?: string,
    visibleImg?: boolean,
    cardContent?: string | React.ReactNode,
    imgSrc?: any;
    style?: object;
    styleTextContent?: object;
    styleImg?: object;
    styleImgCard?: object;
    styleButton?: object;
    styleButtonSecondary?: object;
    buttonText?: string;
    buttonTextSecondary?: string;
    onClick?: any;
    onClickSecondary?: any;
    secundaryButton?: boolean;
    setOpenModal?: any;
}

const CardActionStatic: React.FC<CardActionProps> = ({
    cardType,
    cardCategory,
    cardDescription,
    cardTitle,
    subType,
    visibleImg,
    cardContent,
    imgSrc = ManiPosesModal,
    style,
    styleTextContent,
    styleImg,
    styleImgCard,
    styleButton,
    styleButtonSecondary,
    buttonText = "Quiero ser premium",
    buttonTextSecondary = "Entendido",
    onClick,
    onClickSecondary,
    secundaryButton,
    setOpenModal
}) => {





    return (
        <Card
            sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row", md: "row" },
                maxWidth: 725,
                borderRadius: "8px",
            }}
        >
            {!visibleImg && <CardHeader style={{ flex: 1, ...styleImg }}>
                <CardMedia
                    component="img"
                    image={imgSrc}
                    alt="mani poses"
                    sx={{
                        padding: "1em 0 0 0",
                        margin: "auto",
                        width: 230,
                        objectFit: "contain",
                        ...styleImgCard
                    }}
                />
            </CardHeader>}
            <CardContent
                style={{
                    flex: 1,
                    ...style
                }}
                sx={{
                    border: "1px solid #E6B331",
                    background: "#E6B331",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    alignItems: "center",
                    padding: { xs: "1rem", md: "40px" },
                }}
            >
                <Typography
                    gutterBottom
                    variant="h5"
                    component="p"
                    sx={{ textWrap: "balance" }}
                    style={{
                        textAlign: "center",
                        color: "var(--white, #FFF)",
                        fontSize: 20,
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                        ...styleTextContent
                    }}
                >
                    {cardContent ? cardContent : ""}
                </Typography>
                <CustomButton
                    onClick={() => {
                        if (onClick) {
                            onClick();
                        }
                    }}
                    text={buttonText}
                    style={{
                        color: MembershipColor.free,
                        borderRadius: 8,
                        border: "1px solid " + MembershipColor.free,
                        background: "var(--white, #FFF)",
                        textAlign: "center",
                        fontSize: 16,
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "normal",
                        textTransform: "uppercase",
                        padding: "14px 16px",
                        width: "100%",
                        ...styleButton
                    }}
                />
                {secundaryButton &&
                    <CustomButton
                        onClick={() => {
                            if (onClickSecondary) {
                                onClickSecondary();
                            }
                        }}
                        text={buttonTextSecondary}
                        style={{
                            color: MembershipColor.free,
                            borderRadius: 8,
                            border: "1px solid " + MembershipColor.free,
                            background: "var(--white, #FFF)",
                            textAlign: "center",
                            fontSize: 16,
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",
                            textTransform: "uppercase",
                            padding: "14px 16px",
                            width: "100%",
                            ...styleButtonSecondary
                        }}
                    />
                }
            </CardContent>
        </Card>
    );
};




interface BasicModalProps {
    setOpenModal: any;
    open: boolean;
    bodyDialog?: React.ReactNode;
    styleContent?: object;
    closeButton?: boolean;
    disableBackdropClick?: boolean;
    styleRoot?: object;
    onClick?: any;
}

const BasicModalStatic: React.FC<BasicModalProps> = ({
    open,
    setOpenModal,
    bodyDialog,
    styleContent,
    closeButton,
    disableBackdropClick,
    styleRoot,
    onClick
}) => {


    return (<React.Fragment>
        <Dialog
            sx={{
                "& .MuiDialog-paper": {
                    borderRadius: "8px",
                    backgroundColor: closeButton ? "transparent" : "",
                    gap: "1rem",
                    ...styleRoot,
                },
            }}
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' || !disableBackdropClick) {
                    setOpenModal(false)
                    onClick && onClick();
                }
            }}
        >
            {closeButton ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                    }}
                >
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            setOpenModal(false)
                            onClick && onClick();
                        }}
                        sx={{
                            right: 0,
                            color: (theme) => theme.palette.grey[500],
                            backgroundColor: "#FFF",
                            margin: ".5rem 0",
                            width: 32,
                            height: 32,
                            "&:hover": {
                                backgroundColor: "#FFF",
                            },
                        }}
                    >
                        <CloseOutlined />
                    </IconButton>
                </div>
            ) : null}
            <DialogContent style={styleContent}>
                {bodyDialog ? bodyDialog : <BasicModalContent />}
            </DialogContent>
        </Dialog>
    </React.Fragment>)
}


export function Modal(props: ModalProps2) {
    const { data, exit } = props

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        exit();
    };


    let user = JSON.parse(localStorage.getItem("persist:root") || "{}")?.formRegister || "{}";

    const userData = JSON.parse(sessionStorage.getItem('user-storage')!);

    useEffect(() => {
        window.dataLayer.push({
            event: 'TRUS_registro_regalo_unico_v',
            tipo_seguro: "De regalo",
            seguro: data?.membership?.currentValue?.category.charAt(0).toUpperCase() + data?.membership?.currentValue?.category.slice(1) == "Salud" ? "Salud Protect" : "Celular Protect",
            correo: userData?.correo,
            telefono: userData?.telefono,
            page_location: window.location.href,
        });
    }, []);

    return (
        <BasicModalStatic
            styleRoot={{
                maxWidth: 728,
                boxShadow: "none",
            }}
            styleContent={{
                margin: 0,
                padding: 0,
            }}
            disableBackdropClick={true}
            closeButton
            onClick={() => {
                window.dataLayer.push({
                    event: 'TRUS_registro_regalo_unico',
                    tipo_seguro: "De regalo",
                    seguro: data?.membership?.currentValue?.category.charAt(0).toUpperCase() + data?.membership?.currentValue?.category.slice(1) == "Salud" ? "Salud Protect" : "Celular Protect",
                    correo: userData?.correo,
                    telefono: userData?.telefono,
                    boton_contacto: "Cerrar"
                });
                handleClose()
            }}
            open={true}
            setOpenModal={() => {
            }}

            bodyDialog={<CardActionStatic
                onClick={() => {
                    window.dataLayer.push({
                        event: 'TRUS_registro_regalo_unico',
                        tipo_seguro: "De regalo",
                        seguro: data?.membership?.currentValue?.category.charAt(0).toUpperCase() + data?.membership?.currentValue?.category.slice(1) == "Salud" ? "Salud Protect" : "Celular Protect",
                        correo: userData?.correo,
                        telefono: userData?.telefono,
                        boton_contacto: "Iniciar Sesion"
                    });
                    handleClose()
                    data.navigate("/iniciar-sesion")
                }}

                onClickSecondary={() => {
                    handleClose()
                    window.dataLayer.push({
                        event: 'TRUS_registro_regalo_unico',
                        tipo_seguro: "De regalo",
                        seguro: data?.membership?.currentValue?.category.charAt(0).toUpperCase() + data?.membership?.currentValue?.category.slice(1) == "Salud" ? "Salud Protect" : "Celular Protect",
                        correo: userData?.correo,
                        telefono: userData?.telefono,
                        boton_contacto: "Continuar"
                    });
                    handleClose()
                    data.navigate("/")
                }}
                secundaryButton={true}

                buttonText={"Iniciar sesión"}
                cardContent={
                    <span style={{
                        fontSize: 20,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal'
                    }}>


                        ¡Hola <b>{JSON.parse(user)?.response?.name} {JSON.parse(user)?.response?.middle_name} {JSON.parse(user)?.response?.last_name}</b>!
                        Ya estás registrad{JSON.parse(user)?.response?.gender === 'Hombre' ? 'o' : 'a'} en #TeRegalamosUnSeguro. Si estás interesad{JSON.parse(user)?.response?.gender === 'Hombre' ? 'o' : 'a'} en adquirir {CapitalizeText(data?.membership?.currentValue?.category)} Protect, puedes hacerlo a través de tu portal de cliente.
                        {/* ¡Hola! <b>{JSON.parse(user)?.response?.name} {JSON.parse(user)?.response?.middle_name} {JSON.parse(user)?.response?.last_name}</b> <br />
                        {data.message === "no puedes tener 2 planes freemium" ?
                            `Solo puedes tener un seguro de regalo, pero si lo deseas, puedes contratar ${data?.membership?.currentValue?.category.charAt(0).toUpperCase() + data?.membership?.currentValue?.category.slice(1)} Protect Premium desde tu portal de cliente`
                            :
                            `Solo puedes tener un seguro de regalo, pero si lo deseas, puedes contratar ${data?.membership?.currentValue?.category.charAt(0).toUpperCase() + data?.membership?.currentValue?.category.slice(1)} Protect Premium desde tu portal de cliente`
                        } */}
                    </span>
                }
                imgSrc={ManiPoseBase}
                style={{
                    backgroundColor: MembershipColor.free,
                    border: "1px solid " + MembershipColor.free,
                }}
                styleTextContent={{
                    fontSize: "17px",
                }}
                styleImg={{
                    backgroundColor: `var(--azul-25, ${MembershipColor.freeOpacity})`,
                    padding: 0,
                    display: "flex",
                    flexWrap: "wrap",
                    alignContent: "flex-end",
                }}
                styleImgCard={{
                    padding: 0,
                }}
                styleButton={{
                    color: MembershipColor.free,
                }}
                styleButtonSecondary={{
                    backgroundColor: MembershipColor.free,
                    color: MembershipColor.freeOpacity,
                }}
            />}
        />
    );
}


const RenderAccountExist = (data: IAlertData2) => {

    const div = document.createElement('div')
    document.body.appendChild(div)

    const exit = () => {
        ReactDOM.unmountComponentAtNode(div)
    }

    ReactDOM.render(
        <Modal data={data} exit={exit} />
        , div
    )
}

export default RenderAccountExist
