import React, { useEffect } from 'react';
import HowItWorks from '../../components/organisms/HowItWorks/HowItWorks';
import MemberShipSlider from '../../components/MeetOurMemberships/MembershipSlider';
import Draggable from 'react-draggable';
import ContainerJoinRevolution from '../../components/molecules/JoinRevolution/ContainerJoinRevolution';
import Revolution from '../../components/organisms/Revolution/Revolution';
import Question from '../../components/organisms/Questions/Question';
import { useMediaQuery, useTheme, Box } from '@mui/material';
import { styled } from 'styled-components';
import CustomContainer from '../../components/organisms/Container/Container';
import { useDispatch, useSelector } from 'react-redux';
import useFetch from '../../hooks/useFetch';
import {
    apiLoading as LoadingGadgets,
    apiSuccess as SuccessGadgets,
    apiError as ErrorGadgets
} from '../../api/gadgetsSlice';
import { getBrowserInfo } from '../../utils/navigatorDetails';


import {
    apiLoading as LoadingHealth,
    apiSuccess as SuccessHealth,
    apiError as ErrorHealth
} from '../../api/healthSlice';

import {
    apiSuccess as apiTrackingSuccess,
    apiError as apiTrackingError,
    apiLoading as apiTrackingLoading
} from "../../api/trackingSlice";
import { fetchTrackingStore } from '../../api/fetchingTrackingStore';
import MembershipCardInter from '../../components/organisms/MembershipCard/MembershipCardInter';
import ButtonFloating from './ButtonFloating';
import DialogLandingHelp from '../../components/organisms/Help/DialogLandingHelp';
import StickyCookies from './Stick';
import SliderContent from "../../components/organisms/Slider/SliderContent";
import { restartValue as restartAllianceSetting } from "../../api/allianceSettingSlice";
import RemovedIndex from '../../pages/RemovedAccounts/index';

/**
 * Se obtiene el componente de organisms -> organisms obtiene
 * componentes de molecules y molecules obtiene elementos de atoms
 **/

interface WelcomeProps {
    // Define las propiedades del componente aquí
}

const LandingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
`;

const Welcome: React.FC<WelcomeProps> = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(restartAllianceSetting())
    }, []);

    React.useEffect(() => {
        window.dataLayer.push({
            event: 'TRUS_home_v',
            tipo_seguro: undefined,
            correo: undefined,
            telefono: undefined,
            page_location: window.location.href,
            seguro: undefined,
        })
    }, [])

    const { fetchData } = useFetch();

    const responseGadgets = useSelector((state: any) => state.gadgets.response) as any | null;
    const loadingGadgets = useSelector((state: any) => state.gadgets.loading);
    const errorGadgets = useSelector((state: any) => state.gadgets.error);
    const { breakpoints } = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const isTablet = useMediaQuery(breakpoints.down('md'));

    const responseHealth = useSelector((state: any) => state.health.response) as any | null;
    const loadingHealth = useSelector((state: any) => state.health.loading);
    const errorHealth = useSelector((state: any) => state.health.error);

    const tracking = useSelector((state: any) => state.tracking.response) as any | null;

    const fetchingDataGadgets = () => {
        return fetchData('catalogs/gadgets', 'GET', {}, {}
            , LoadingGadgets, SuccessGadgets, ErrorGadgets);
    }

    const fetchingDataHealth = () => {
        return fetchData('catalogs/health', 'GET', {}, {}
            , LoadingHealth, SuccessHealth, ErrorHealth);
    }
    useEffect(() => {
        fetchingDataGadgets();
        fetchingDataHealth();
    }, []);

    React.useEffect(() => {
        //console.log(tracking?.data?.tracking_id)
        if (!tracking?.data?.tracking_id) {
            fetchTrackingStore(
                '/users/tracking',
                "create-tracking",
                {
                    content: {
                        "data": {},
                        ...getBrowserInfo()
                    }
                },
                fetchData,
                apiTrackingLoading,
                apiTrackingSuccess,
                apiTrackingError
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tracking]);

    // Implementa el componente aquí
    return (
        <React.Fragment>
            {/* <Box >
            {!isMobile ? <video controls style={{
                width: '100%',
            }}><source src="https://storage.googleapis.com/inter-public-cloudstorage-prod/tr1s/videos/interm-mx_1440x500.mp4" type="video/mp4" /></video> : <video controls style={{
                width: '100%',
            }}><source src="https://storage.googleapis.com/inter-public-cloudstorage-prod/tr1s/videos/interm-mx_768x500.mp4" type="video/mp4" /></video>}
        </Box> */}
            <LandingWrapper style={{
                paddingTop: matches ? '80px' : '40px'
            }}>
                <Revolution />
                <HowItWorks />

                {
                    /*
                     <Draggable>
                              <ButtonFloating
                                children={
                                  <div></div>

                                }
                              ></ButtonFloating>



                            </Draggable>
                    */
                }



                <CustomContainer childComponent={
                    <MemberShipSlider
                        typeCurrent='free'
                        title='Conoce nuestros seguros'
                        newProductRegistration={false}
                    />
                } />



                {/* <CustomContainer childComponent={<PruebaRedux />} /> */}

                <ContainerJoinRevolution />
                <Question />


            </LandingWrapper>
            {/* <StickyCookies/> */}
        </React.Fragment>
    );
};

export default Welcome;
