function obtenerNombreNavegador() {
    const userAgent = navigator.userAgent;
    let nombreNavegador = "Desconocido"; // Valor predeterminado en caso de que no se pueda identificar el navegador
  
    // Detectar el nombre del navegador basado en el user-agent
    if (userAgent.indexOf("Chrome") != -1) {
      nombreNavegador = "Google Chrome";
    } else if (userAgent.indexOf("Firefox") != -1) {
      nombreNavegador = "Mozilla Firefox";
    } else if (userAgent.indexOf("Edge") != -1) {
      nombreNavegador = "Microsoft Edge";
    } else if (userAgent.indexOf("Safari") != -1) {
      nombreNavegador = "Safari";
    } else if (userAgent.indexOf("Opera") != -1 || userAgent.indexOf("OPR") != -1) {
      nombreNavegador = "Opera";
    }
  
    return nombreNavegador;
}

export const getBrowserInfo = () => {

    return {
      nombre: navigator.appName,
      version: navigator.appVersion,
      agent: navigator.userAgent,
      language: navigator.language,
      platform: navigator.platform,
      navigator: obtenerNombreNavegador()
    }
  }