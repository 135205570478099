import { BenefitValues, BenefitValuesType } from "./benefit.values";

/**
 * Funcion que evalua si el beneficio existe dentro de la lista, todo en base al titulo del beneficio
 * retorna el valor de la llave que identifica ese beneficio
 * @param title titulo del beneficio
 * @returns @BenefitValuesType regresa la llave que se compare con el valor del titulo
 */
export function benefitGetKeyByName(title?: string): BenefitValuesType | undefined {
    const keys = Object.keys(BenefitValues) as (keyof typeof BenefitValues)[];
    let foundKey: BenefitValuesType | undefined;
    if (title && title !== '') {
        foundKey = keys.find(key => BenefitValues[key].toLocaleLowerCase() === title.toLocaleLowerCase());
    }
    return foundKey ? foundKey : undefined;
}

/**
 * Valida si una llave existe dentro de la lista @BenefitValues
 * @param key llave del listado de beneficios
 * @returns @boolean si es valida o no la key
 */
export function benefitValidKey(key?: string) {
    return key ? BenefitValues.hasOwnProperty(key) : false;
}

export function benefitValidKeyAndReturnType(key?: string): BenefitValuesType | undefined {
    const list = Object.keys(BenefitValues);
    const find = list.find(e => e.toLocaleLowerCase() === key?.toLocaleLowerCase());
    return find ? find as BenefitValuesType : undefined;
}