import React, { useEffect, useState } from 'react';
import {  Form } from 'formik';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { useField } from 'formik';
import FormItemGenerate from '../../components/atoms/FormItems/FormItemGenerate';
import useFetch from '../../hooks/useFetch';
import {
    apiSuccess as addressSuccess,
    apiLoading as addressLoading,
    apiError as addressError
} from '../../api/addressSlice';

interface AdditionAddresProps {
    handleChange: (event: React.ChangeEvent<any>) => void;
    handleBlur?: (event: React.ChangeEvent<any>) => void;
}

const AdditionAddres: React.FC<AdditionAddresProps> = ({ handleChange, handleBlur }) => {
    const matches = useMediaQuery('(min-width:600px)');
    const [zipCode, zipCodeMeta] = useField("zipcode");

    const [stateField, stateMeta] = useField("state");
    const [state, setState] = useState('');
    const [stateOption, setStateOption] = useState([]);

    const [cityField, cityMeta] = useField("city");
    const [city, setCity] = useState('');
    const [cityOption, setCityOption] = useState([])

    const [municipalityField, municipalityMeta] = useField("municipality");
    const [municipality, setMunicipality] = useState('');
    const [municipalityOption, setMunicipalityOption] = useState([]);

    const [colonyField, colonyMeta] = useField("colony");
    const [colony, setColony] = useState('');
    const [colonyOption, setColonyOption] = useState([]);

    const [street, streetMeta] = useField("street");
    const [number, numberMeta] = useField("number");
    const [reference, referenceMeta] = useField("reference");

    const { fetchData } = useFetch();
    const addressResponse = useSelector((state: any) => state.address.response);

    const getAddress = async (zipcode: string) => {
        return fetchData('/users/auth', 'POST', {
            event: 'get-addess',
            content: {
                zipcode
            }
        }, {}, addressLoading, addressSuccess, addressError);
    }

    useEffect(() => {
        if (addressResponse && addressResponse.data) {
            const data = addressResponse.data;
            setMunicipalityOption(data.municipality);
            setStateOption(data.state);
            setCityOption(data.city)
            setColonyOption(data.col)
        }
    }, [addressResponse])

    useEffect(()=> {
        if (zipCode?.value?.length === 5) {
            getAddress(zipCode.value);
        }
    }, [zipCode.value])

    const items = [{
        id: 1,
        label: 'Código Postal',
        name: 'zipcode',
        autofocus: true,
        as: 'textField',
        error: zipCode,
        meta: zipCodeMeta
    },
    {
        id: 2,
        label: 'Estado*',
        name: 'state',
        type: 'select',
        event: setState,
        value: state,
        option: stateOption,
        error: stateField,
        meta: stateMeta,
        xs: matches ? 6 : 12,
        loading: false
    },
    {
        id: 3,
        label: 'Alcaldía o Municipio*',
        name: 'municipality',
        type: 'select',
        event: setMunicipality,
        value: municipality,
        option: municipalityOption,
        error: municipalityField,
        meta: municipalityMeta,
        xs: matches ? 6 : 12,
        loading: false
    },
    {
        id: 4,
        label: 'Ciudad*',
        name: 'city',
        type: 'select',
        event: setCity,
        value: city,
        option: cityOption,
        error: cityField,
        meta: cityMeta,
        loading: false
    },
    {
        id: 5,
        label: 'Colonia*',
        name: 'colony',
        type: 'select',
        event: setColony,
        value: colony,
        option: colonyOption,
        error: colonyField,
        meta: colonyMeta,
        loading: false
    },
    {
        id: 6,
        label: 'Calle',
        name: 'street',
        type: 'text',
        as: 'textField',
        error: street,
        xs: matches ? 6 : 12,
        meta: streetMeta
    },
    {
        id: 7,
        label: 'Número',
        name: 'number',
        type: 'text',
        as: 'textField',
        error: number,
        xs: matches ? 6 : 12,
        meta: numberMeta
    },
    {
        id: 8,
        label: 'Referencias',
        name: 'reference',
        type: 'text_area',
        as: 'textField',
        error: reference,
        meta: referenceMeta
    }
    ]

    return <div
        style={{
            display: 'flex',
            width: '100%'
        }}
    >
        <Form
            style={{
                width: '100%'
            }}
        >
            <FormItemGenerate
                items={items}
                handleChange={handleChange}
                handleBlur={handleBlur}
            />

            {/* <div
                style={{
                    display: 'flex',
                    justifyContent: "center",
                    paddingTop: '15px'
                }}
            >
                <Button
                    style={{
                        fontWeight: 'bold',
                        borderRadius: 10,
                        boxShadow: 'none',
                        fontSize: "1rem",
                        textTransform: "uppercase",
                        maxWidth: "580px",
                        color: 'white',
                        justifyContent: "center",
                        width: "100%",
                        border: '1px solid var(--Azul-Inter, #039ECC)',
                        background: '#039ECC'

                    }}
                    type="submit">GUARDAR</Button>
            </div> */}
        </Form>
    </div>
};

export default AdditionAddres;