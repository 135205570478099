import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from 'styled-components';
import { CustomTypography } from '../../atoms/Label/Label';
import CustomContainer from '../../organisms/Container/Container';

const EmptyContainer = styled.div`
    border-radius: 8px;
    border: 1px solid var(--azul-5, #F2FAFC);
    background: var(--azul-5, #F2FAFC);
    padding: 16px;
    align-items: center;
    display: flex;
    justify-content: center;
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface customTab {
  consultantValue: String;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 0, py: 2, textAlign: 'center' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CustomTab(props: customTab) {

  const { consultantValue } = props
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function handleIframe() {
    // setLoading(true);
    // console.log("end point to be implemented ")
  }

  return (
    <Box sx={{ width: '100%' }} style={{
      display: 'flex',
      flexDirection: 'column',
      gap: 40
    }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs sx={{ justifyContent: 'space-between' }} style={{ width: '100%', position: 'relative', bottom: '0px' }} value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab sx={{ flex: 1, padding: 0, height: '24px' }} style={{
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 700,
            textAlign: 'center',
            alignSelf: 'stretch',
            textTransform: 'inherit'
          }} label="Mis consultas" {...a11yProps(0)} />
          <Tab sx={{ flex: 1, padding: 0, height: '24px' }} style={{
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 700,
            textAlign: 'center',
            alignSelf: 'stretch',
            textTransform: 'inherit'
          }} label="Historial médico" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>

        {
          consultantValue === 'NONE' ?
            <CustomContainer
              childComponent={
                <iframe
                  style={{
                    width: '100%',
                    height: '100%',
                    border: '0px',
                    position: 'relative',

                  }}
                  onLoad={handleIframe}
                  src={''}
                  title="payment"
                  id="payment"
                />
              }

            /> :
            <div>
              <EmptyContainer>
                <CustomTypography
                  item={"Aún no has solicitado una consulta"}
                  style={{
                    fontSize: 16,
                    textAlign: 'center',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    textTransform: 'inherit',
                    letterSpacing: '0.032px'
                  }}
                />
              </EmptyContainer>
            </div>

        }

      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <EmptyContainer>
          <CustomTypography
            item={"Aún no has solicitado una consulta"}
            style={{
              fontSize: 16,
              textAlign: 'center',
              fontWeight: 400,
              fontStyle: 'normal',
              lineHeight: 'normal',
              textTransform: 'inherit',
              letterSpacing: '0.032px'
            }}
          />
        </EmptyContainer>
      </CustomTabPanel>
    </Box>
  );
}
