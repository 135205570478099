import React from 'react'
import styled from 'styled-components';
import { Typography, Link } from '@mui/material';


function List({ elements, numbers }: any) {

    const values = elements.map((element: any) => {
        return (
            <li>{element}</li>
        )
    })

    return (
        numbers ? <ol style={{ paddingLeft: '20px' }}>{values}</ol> : <ul style={{ paddingLeft: '20px' }}>{values}</ul>
    )
}

export default function Chubb() {
    const BlackText = styled.span`
        color: #212121;
        font-family: 'Montserrat';
        font-size: '16px';
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
    `;

    const Paragraph = styled.div`
        margin-top: 16px;
   `

    return (
        <>
            <Paragraph>
                <BlackText>Chubb Digital Services:</BlackText>
            </Paragraph>

            <List numbers elements={[
                "Chubb Digital Services, S.A. de C.V. (CDS) es una subsidiaria de una compañía estadounidense. Como resultado, CDS está sujeto a ciertas leyes y regulaciones de los Estados Unidos de América además de las restricciones de sanciones nacionales, de la Unión Europea y de las Naciones Unidas que pueden prohibirle dar servicios a ciertas personas, entidades o ciertos tipos de actividades relacionadas con ciertos países como Irán, Siria, Corea del Norte, Crimea, Cuba, Sudán, Venezuela y las RepúblicasnPopulares de Donetsk y Luhansk en la región de Ucrania.",
                "A. Si, en virtud de cualquier ley o regulación aplicable a Chubb Digital Services, S.A. de C.V. (CDS) en el inicio de este contrato o que sea aplicable en cualquier momento posterior, la prestación de servicios al CLIENTE es o sería ilegal porque se infringe un embargo o una sanción, CDS no proporcionará servicios y no asumirá responsabilidad alguna ni ninguna defensa del CLIENTE, ni hará pagos de ningún tipo de los gastos de defensa, ni facilitará ninguna forma de seguridad en nombre del CLIENTE, en la medida en que sea un incumplimiento de la ley o regulación. B. En circunstancias en las que sea lícito que CDS proporcione servicios al CLIENTE, pero la prestación de servicios pueda infringir un embargo o una sanción, CDS adoptará todas las medidas razonables para obtener la autorización necesaria para realizar dicha prestación. C. En caso de que cualquier ley o regulación aplicable durante el período de este contrato restrinja la capacidad de CDS para proporcionar servicios como se especifica en el párrafo 1, tanto el CLIENTE como CDS tendrán el derecho de cancelar su participación en el presente contrato deconformidad con las leyes y regulaciones aplicables."
            ]
            } />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <hr></hr>
                <Typography
                    style={{
                        cursor: 'pointer',
                    }}
                    textAlign={'left'}
                >
                    Chubb Digital Services, S.A. de C.V., con domicilio establecido en Av. Paseo de la Reforma No. 250, edificio “Capital Reforma”, torre Niza, Piso 7, Col. Juárez, Alcaldía Cuauhtémoc, C.P. 06600, en la Ciudad de México, México, es el responsable del tratamiento de sus datos personales y los utilizará para otorgar servicios de asistencia. Consulte la versión integral de este{" "}
                    <Link
                        href="https://www.chubb.com/mx-es/footer/privacy-notices.html..html"
                        target={'_blank'}
                        style={{ textDecoration: 'none' }}
                    >
                        Aviso de Privacidad.
                    </Link>
                </Typography>

            </div>
        </>
    )
}
