import React from "react";

function HeartIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="49"
      fill="none"
      viewBox="0 0 55 49"
    >
      <g fill="#494445" clipPath="url(#clip0_1289_62335)">
        <path d="M31.242 12.238c-.727-5.525 3.943-10.356 9.433-10.31 5.753.047 10.253 4.445 11.52 9.847 1.408 6.05-1.283 11.73-4.685 16.59-3.897 5.541-8.397 10.696-12.773 15.85-1.052 1.25-2.242 3.226-4.144 2.855-1.64-.324-3.294-1.296-4.763-2.083-3.077-1.636-5.892-3.673-8.443-6.05-.913-.849-2.29.51-1.377 1.374a41.571 41.571 0 008.474 6.157c2.645 1.451 6.403 3.843 9.233 1.667 1.391-1.08 2.474-2.639 3.603-3.966a566.902 566.902 0 003.912-4.66c2.428-2.933 4.84-5.896 7.098-8.967 4.036-5.495 7.283-11.9 5.814-18.89C52.861 5.478 47.928.586 41.557.046c-6.743-.571-13.16 5.278-12.248 12.207.155 1.22 2.103 1.235 1.933 0"></path>
        <path d="M30.67 11.96C27.531 4.63 18.887 1.188 11.557 4.32c-3.68 1.575-6.882 4.739-7.531 8.813-.711 4.414 1.36 8.689 3.325 12.5.572 1.112 2.242.124 1.67-.971-1.701-3.288-3.603-7.022-3.155-10.85.449-3.673 3.449-6.559 6.789-7.855C19.15 3.44 26.294 6.62 29 12.947c.495 1.143 2.165.155 1.67-.971"></path>
        <path d="M1.443 34.06h18.325c1.253 0 1.253-1.929 0-1.929H1.443c-1.252 0-1.252 1.93 0 1.93z"></path>
        <path d="M20.773 33.366c1.67-3.72 3.356-7.454 5.026-11.19h-1.67c3.216 5.927 2.814 12.98 5.289 19.153.34.85 1.577.988 1.87 0 .898-2.994 1.779-5.988 2.676-8.982.216-.756.448-1.497.665-2.253.077-.278.232-.602.263-.895.263-.37.247-.51-.031-.432-.232-.031-.232.03-.016.17.109.478 1.145 1.265 1.5 1.635.542.602 1.098 1.204 1.64 1.806.494.54 1.438.247 1.623-.432.217-.787.433-1.667.975-2.3.649-.756 1.933-.88 2.86-1.11 2.103-.526 4.191-1.05 6.294-1.59 1.206-.309.696-2.176-.51-1.868-2.675.664-5.412 1.22-8.057 2.022-2.087.633-2.876 2.361-3.417 4.321a62.723 62.723 0 001.623-.432c-.695-.771-1.391-1.528-2.087-2.3-.51-.555-1.02-1.327-1.67-1.728-1.531-.91-2.274.74-2.63 1.914-1.174 3.966-2.365 7.932-3.54 11.899h1.87c-2.535-6.359-2.18-13.52-5.474-19.616-.324-.602-1.376-.663-1.67 0-1.67 3.735-3.355 7.454-5.026 11.19-.51 1.126 1.16 2.114 1.67.971"></path>
      </g>
      <defs>
        <clipPath id="clip0_1289_62335">
          <path fill="#fff" d="M0 0H54V49H0z" transform="translate(.5)"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default HeartIcon;
