import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import BASE_CONFIG from "../env/env";

export const Auth0ProviderWithNavigate = ({ children }: any) => {
  const navigate = useNavigate();
  const domain = BASE_CONFIG.auth0.domain;
  const clientId = BASE_CONFIG.auth0.clientId;
  const redirectUri = `${BASE_CONFIG.base}/portal-del-cliente`;

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};