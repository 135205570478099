import React, { useEffect } from 'react';
import BasicModal from '../../molecules/BasicModal/BasicModal';
import CardAction from '../../organisms/Card';

import fail from '../../../assets/payment/fail.gif';
import giffy from '../../../assets/payment/gifgif.gif';
import { IStorageMembershipData } from '../../../utils/localStorageKeys';
import { useSelector } from 'react-redux';

type ModalPaymentmethodProps = {
  open: boolean;
  setOpenModal: any;
  onClick: any;
  success: boolean;
}

const ModalPaymentmethod: React.FC<ModalPaymentmethodProps> = ({ open, setOpenModal, onClick, success }) => {

  let storage: IStorageMembershipData = {
    ...useSelector((state: any) => state.productsCards.currentValue),
  };
  const userData = JSON.parse(sessionStorage.getItem('user-storage')!);
  const allianceSettingResponse = useSelector((state: any) => state.allianceSetting.response);

  useEffect(() => {
    if (open && !success) {
      window.dataLayer.push({
        'event': 'TRUS_pagar_error_v',
        'tipo_seguro': "Premium",
        seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
        'correo': userData?.correo,
        'telefono': userData?.telefono,
        'page_location': window.location.href,
        alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
      })
    }
  }, [open])

  return (
    <BasicModal
      styleRoot={{
        maxWidth: 728,
        boxShadow: "none",
        gap: "2px",
        marginTop: "62px",
      }}
      styleContent={{
        margin: 0,
        padding: 0,
      }}
      disableBackdropClick={true}
      closeButton={false}
      open={open}
      setOpenModal={setOpenModal}
      onClick={onClick}
      bodyDialog={
        <CardAction
          onClick={onClick}
          onClickSecondary={() => {
            window.dataLayer.push({
              event: 'TRUS_pagar_error',
              tipo_seguro: "Premium",
              correo: userData?.correo,
              telefono: userData?.telefono,
              boton_contacto: 'Reportar un problema',
              seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
              alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
            })
            window.open('https://intermx-digital.atlassian.net/servicedesk/customer/portal/8/group/32/create/94', '_blank')
          }}
          buttonText={success ? "Continuar" : "Reintentar"}
          buttonTextSecondary='Reportar un problema'
          secundaryButton={!success}
          cardContent={
            success ?
              <TextModal
                title="¡Tu pago se ha realizado con éxito!"
                message="El primer mes va de regalo, a partir del segundo verás reflejado el cobro de $99 MXN en tu estado de cuenta."
              />
              :
              <TextModal
                title="¡Oops!"
                subtitle="Tuvimos un problema al procesar tu pago"
                message="No te preocupes, nuestros medios de pago son completamente seguros, puedes volverlo a intentarlo o reportar el problema a nuestra mesa de servicio"
              />
          }
          imgSrc={success ? giffy : fail}
          style={{
            backgroundColor: "#039ECC",
            border: "1px solid #039ECC",
          }}
          styleTextContent={{
            fontSize: "17px",
          }}
          styleImg={{
            backgroundColor: "#FFFFFF",
            padding: 0,
            display: "flex",
            flexWrap: "wrap",
            alignContent: "flex-end",
          }}
          styleImgCard={{
            padding: 0,
          }}
          styleButton={{
            color: "#039ECC",
          }}
          styleButtonSecondary={{
            color: "#039ECC",
          }}
        />
      }
    />
  );
}

type TextModalProps = {
  title: string
  subtitle?: string
  message: string
}

const TextModal: React.FC<TextModalProps> = ({ title, subtitle, message }) => {
  return (
    <div>
      <h2 style={{ margin: 0 }}>{title}</h2>
      {subtitle && <h2 style={{ margin: 0 }}>{subtitle}</h2>}
      <p style={{ margin: 0, marginTop: '16px' }}>{message}</p>
    </div>
  )
}

export default ModalPaymentmethod;