import React from 'react';
import MascotaSegura from '../../assets/img/landing/GIFMascota.gif';
import Pet from '../../assets/img/insurances/MascotaSegura.svg';
import { Box, Typography, Grid, Stack, Button, } from '@mui/material';

export default function PetInsurance() {
    const userData = JSON.parse(sessionStorage.getItem('user-storage'));
    return (
        <Box
            sx={{
                width: '100%',
                height:{xs:'860px',sm:'860px',md:'860px',lg:'860px',xl:'860px'},
                display: 'flex',
                alignItems: 'center',
                margin: 0,
                //paddingLeft: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3 },
                //paddingRight: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3 },
                paddingTop:{xs:'40px',sm:'40px',md:'40px',lg:'40px',xl:'40px'}
            }}>
            <Grid container
                style={{padding:0}}
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems:'center',
                }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          paddingTop:{xs:'0',sm:'60px',lg:'0',xl:'0'},
                          height:{xs:'276.549px',sm:'276.549px',md:'400px',lg:'520px',xl:'520px'},
                          display:'flex',
                          alignContent:{xs:'start',sm:'start',md:'start',lg:'start',xl:'start'}
                        }}>
                      <Box component={'img'} src={MascotaSegura} sx={{
                        display: 'flex',
                        width:{lg:'470px',xl:'470px'},
                        fontSize: '10px',
                        color: 'white',
                        fontWeight: '100',
                        maxWidth: '80%',
                      }}/>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box
                        sx={{
                            alignSelf: 'center',
                            paddingTop:{xs:'150px',sm:'150px',md:'10px',lg:'10px',xl:'10px'},
                            maxWidth: '100%'
                        }}>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            textAlign="center"
                            height={'100%'}>
                            <img src={Pet} style={{
                              display: 'flex',
                              fontSize: '10px',
                              color: 'white',
                              fontWeight: '100',
                              maxWidth: '90%',
                            }}/>
                        </Stack>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                paddingTop:'40px',
                              padding:{xs:'20px 20px 40px 40px',sm:'20px 70px 70px 70px',md:'20px 90px 90px 90px',lg:'20px 20px 50px 50px'},
                              alignSelf: 'center',
                            }}>
                            <Box sx={{ display: 'flex' }}>
                                <Typography
                                    sx={{
                                        color: '#212121',
                                        fontFamily: 'Montserrat',
                                        fontSize: '22px',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: 'normal',
                                    }}>
                                    Con <b>Mascota Segura</b> protege a tu mejor amigo y disfruta de los beneficios al contratar con nosotros.
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex',paddingBottom:'40px' }}>
                                <Typography
                                sx={{
                                    color: '#039ECC',
                                    fontFamily: 'Montserrat',
                                    fontSize: '20px',
                                    fontStyle: 'normal',
                                    fontWeight: 700,
                                    lineHeight: 'normal',
                                    paddingTop:'16px'
                                }}>
                                    Además, ¡Obtendrás más beneficios a un mejor costo!
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex',justifyContent:'center' }}>
                                <Button variant="contained" sx={{
                                  width:'70%',
                                    background: '#039ECC',
                                    color: '#FFFF',
                                    textAlign: 'center',
                                    fontFeatureSettings: { clig: 'off', liga: 'off' },
                                    fontFamily: 'Montserrat',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    padding: '14px 16px',
                                    gap: '10px',
                                    borderRadius:'8px',
                                    lineHeight: 'normal',
                                    textTransform: "uppercase !important",
                                    ":hover": { backgroundColor: '#026E8E', color: '#FFFF' },
                                    }}
                                    onClick={() => {
                                        window.dataLayer.push({
                                            event: 'TRUS_portal_otros_seguros',
                                            seguro: 'Mascota Segura',
                                            boton_contacto: 'Lo quiero',
                                            correo: userData?.correo,
                                            telefono: userData?.telefono,
                                        })
                                        var anchor = document.createElement('a');
                                        anchor.href = 'https://mascotasegura.inter.mx/';
                                        anchor.target="_blank";
                                        anchor.click();
                                    }}>
                                    ¡Lo quiero!
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};
