import React, {useEffect} from 'react';
// import styled from 'styled-components'
import MembershipCoverageComponent from '../../components/organisms/Membership/MembershipCoverage/MembershipCoverage';
import CustomContainer from "../../components/organisms/Container/Container"

const MembershipCoveragePage: React.FC = () => {

  React.useEffect(() => {
    window.dataLayer.push({ 
      event: 'TRUS_cobertura_v',
      tipo_seguro: undefined, 
      correo: undefined, 
      telefono: undefined,
      page_location: window.location.href,
     });
  }, [])

  return (
    <React.Fragment>
      <CustomContainer childComponent={<MembershipCoverageComponent />}></CustomContainer>
    </React.Fragment>
  );
};

export default MembershipCoveragePage;
