import React from "react";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import BasicModalContent from "./BasicModalContent/BasicModalContent";
import { CloseOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { IStorageMembershipData } from "../../../utils/localStorageKeys";

interface BasicModalProps {
  setOpenModal: any;
  open: boolean;
  bodyDialog?: React.ReactNode;
  styleContent?: object;
  closeButton?: boolean;
  disableBackdropClick?: boolean;
  styleRoot?: object;
  onClick?: any;
}

const BasicModal: React.FC<BasicModalProps> = ({
  open,
  setOpenModal,
  bodyDialog,
  styleContent,
  closeButton,
  disableBackdropClick,
  styleRoot,
  onClick
}) => {
  const userData = JSON.parse(sessionStorage.getItem('user-storage')!);
  const userResponse = useSelector((state: any) => state?.user?.response);
  let storage: IStorageMembershipData = {
    ...useSelector((state: any) => state.productsCards.currentValue),
  };
  return (
    <React.Fragment>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "8px",
            backgroundColor: closeButton ? "transparent" : "",
            gap: "1rem",
            ...styleRoot,
          },
        }}
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick' || !disableBackdropClick) {
            setOpenModal(false)
            onClick && onClick();
          }
        }}
      >
        {closeButton ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              aria-label="close"
              onClick={() => {
                setOpenModal(false)
                const cardTypeStorage = JSON.parse(sessionStorage.getItem('card-type-storage')!);
                if (cardTypeStorage == "premium"){
                  window.dataLayer.push({
                    event: 'TRUS_quiero_premium',
                    boton_contacto: 'Cerrar',
                    page_location: window.location.href,
                    correo: userResponse?.data?.user?.email,
                    telefono: userResponse?.data?.user?.phone,
                    tipo_seguro: storage.type == "free" ? "De regalo" : "Premium",
                    seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
                })                  
                } else if (cardTypeStorage == "free"){
                  window.dataLayer.push({
                    event: "TRUS_espera_cobertura",
                    seguro: storage.category === "celular" ? 'Celular Protect' : 'Salud Protect',
                    tipo_seguro: storage.type == "free" ? "De regalo" : "Premium",
                    boton_contacto: "Cerrar",
                    telefono: userResponse?.data?.user?.phone,
                    correo: userResponse?.data?.user?.email,
                  })
                }
                onClick && onClick();
              }}
              sx={{
                right: 0,
                color: (theme) => theme.palette.grey[500],
                backgroundColor: "#FFF",
                margin: ".5rem 0",
                width: 32,
                height: 32,
                "&:hover": {
                  backgroundColor: "#FFF",
                },
              }}
            >
              <CloseOutlined />
            </IconButton>
          </div>
        ) : null}
        <DialogContent style={styleContent}>
          {bodyDialog ? bodyDialog : <BasicModalContent />}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default BasicModal;
