import React from 'react';
import ConfirmCellphoneDataComponent from '../../components/templates/ConfirmCellphoneData';

const ConfirmCellphoneData: React.FC = () => {
  // Implementa el componente aquí
  return (
    <React.Fragment>
        <ConfirmCellphoneDataComponent />
    </React.Fragment>

  );
};

export default ConfirmCellphoneData;
 