import React from 'react';
import styled from 'styled-components'
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
`
const ResumeCol = styled.div`
    display: flex;
    flex-direction: column;
`
const ResumeRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

`

export interface ResumeItems {
    label: string;
    price: number;
}
interface ResumeProps {
    items: ResumeItems[];
}
const ResumeComponent: React.FC<ResumeProps> = ({
    items
}) => {
    const getPriceFormat = (price: number) => {
        let [integer, decimal] = price.toFixed(2).toString().split('.');
        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return "$" + integer + "." + decimal + " MXN";
    }
    return (<Container>
        <Box sx={{
            marginBottom: '15px'
        }}>
            <Typography style={{ fontWeight: '900' }}>
                Detalle de tu compra
            </Typography>
        </Box>

        <Box>
            {
                items.map((item, index) => {
                    return (<React.Fragment key={"item_" + index
                    }>
                        <ResumeRow>
                            <ResumeCol>
                                {item.label}
                            </ResumeCol>
                            <ResumeCol style={{
                                textAlign: 'right'
                            }}>
                                <strong>
                                    {getPriceFormat(item.price)}
                                </strong>
                                <small>
                                    al mes
                                </small>
                            </ResumeCol>
                        </ResumeRow>
                    </React.Fragment>)
                })
            }
        </Box>
        <hr style={{ width: '100%', }} />
        <Box>
            <p>
                <small>
                    El cobro se realizará de forma recurrente y podrás cancelarlo en cualquier momento.
                </small>
            </p>
        </Box>
        <Box sx={{
            textAlign: 'center',
            color: '#8F8F8F'
        }}>
            <p>
                <small>
                    ¡No te preocupes! Con nosotros tienes asegurada una experiencia de pago segura y certificada, por lo que tus datos estarán a salvo en todo momento.
                </small>
            </p>
        </Box>
    </Container >)
}

export default ResumeComponent