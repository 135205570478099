import React from 'react';
import BeneficiaryComponent from '../../components/templates/Beneficiary';
import CustomContainer from '../../components/organisms/Container/Container';

const Benefiaciary: React.FC = () => {
  // Implementa el componente aquí
  return (
    <React.Fragment>
        <CustomContainer childComponent={ <BeneficiaryComponent />} />
    </React.Fragment>

  );
};

export default Benefiaciary;
 