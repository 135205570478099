import React, { useState } from 'react';
import {
    Field,
    useField
} from 'formik';
import {
    Checkbox
} from '@mui/material';
import InfoButton from '../../../../assets/RegisterAssets/InfoButton';
import { styled } from 'styled-components';
import FormItemGenerate from '../../../atoms/FormItems/FormItemGenerate';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useFormikContext } from 'formik';
import {
    Box,
    ClickAwayListener,
    Tooltip,
    TooltipProps,
    tooltipClasses,
    Typography
} from '@mui/material';

import CustomToolTipComponent from '../../../atoms/Tooltip/CustomTooltip';
import FORMDATA from '../form.config';

const LabelWrapper = styled.span`
    width: 100%;
    display: none;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    text-wrap: balance;
`;
type DatosPersonalesProps = {
    errors: any;
    handleChange?: any;
    handleBlur?: any;
}

const DatosPersonales: React.FC<DatosPersonalesProps> = ({ errors, handleChange, handleBlur }) => {
    const matches = useMediaQuery('(min-width:600px)');
    const [open, setOpen] = React.useState(false);
    const [gender, setGender] = useState('');
    const [name, nameMeta] = useField("name");
    const [middle_name, middle_nameMeta] = useField("middle_name");
    const [last_name, last_nameMeta] = useField("last_name");
    const [birthdate, birthdateMeta] = useField("birthdate");
    const [genderError, genderMeta] = useField("gender");
    const [zipcode, zipcodeMeta] = useField("zipcode");
    const formik = useFormikContext();

    React.useEffect(() => {
        formik.setFieldValue('mexicanciticen', true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const items = [{
        id: 1, // identificador para la key
        label: 'Nombre(s)', // texto en el input
        name: 'name', // identificador que sera usado por formik
        as: 'textField', // tipo de elemento en este caso un texfield de material UI
        error: name, // asigna la referencia de error al input en formik desde yup
        meta: nameMeta, // contiene el error y evento del elemento
        autofocus: true
    },
    {
        id: 2,
        label: 'Primer Apellido',
        name: 'middle_name',
        as: 'textField',
        error: middle_name,
        meta: middle_nameMeta,
        xs: matches ? 6 : 12
    },
    {
        id: 3,
        label: 'Segundo Apellido',
        name: 'last_name',
        as: 'textField',
        error: last_name,
        meta: last_nameMeta,
        xs: matches ? 6 : 12
    },
    {
        id: 4,
        label: 'Sexo de nacimiento*',
        name: 'gender',
        as: 'textField',
        type: 'select_button',
        error: genderError,
        meta: genderMeta,
        event: setGender,
        value: gender,
        option: ['Hombre', 'Mujer']
    },
    {
        id: 5,
        label: 'Fecha de Nacimiento*',
        name: 'birthdate',
        as: 'textField',
        type: 'date',
        error: birthdate,
        meta: birthdateMeta,
        limit: {
            minAge: FORMDATA.DatosPersonales.Age.celular.min,
            maxAge: FORMDATA.DatosPersonales.Age.celular.max
        },
        xs: matches ? 6 : 12
    },
    {
        id: 6,
        label: 'Código postal',
        name: 'zipcode',
        as: 'textField',
        error: zipcode,
        meta: zipcodeMeta,
        xs: matches ? 6 : 12
    }
    ]

    return (<React.Fragment>
        <FormItemGenerate
            handleChange={handleChange}
            handleBlur={handleBlur}
            items={items}
        />
        <LabelWrapper>
            <label>
                <LabelWrapper style={{ justifyContent: 'flex-start', display: 'none' }}>
                    <Field
                        className='error-message'
                        as={Checkbox}
                        type="checkbox"
                        name="mexicanciticen"
                        onChange={(ev: any) => formik.setFieldValue('mexicanciticen', ev.target.checked)}
                        style={{
                            padding: 0
                        }}
                    />
                    {`Declaro que soy mexicano y vivo en México `}
                </LabelWrapper>
                {errors?.mexicanciticen && <span className='error-message'>{errors.mexicanciticen}</span>}
            </label>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
                <CustomToolTipComponent

                    title="Por el momento nuestros productos solo están disponibles en México y solo pueden ser adquiridos por mexicanos de nacimiento">
                    <span style={{ display: 'flex' }} onClick={() => setOpen(true)}>
                        <InfoButton fill={matches ? '#8F8F8F' : '#039ECC'} />
                    </span>
                </CustomToolTipComponent>
                {/* <Tooltip
                    arrow
                    placement="left-start"
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={() => setOpen(false)}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Por el momento nuestros productos solo están disponibles en México y solo pueden ser adquiridos por mexicanos de nacimiento"
                >
                    <span style={{ display: 'flex' }} onClick={() => setOpen(true)}>
                        <InfoButton fill={matches ? '#8F8F8F' : '#039ECC'} />
                    </span>
                </Tooltip> */}
            </ClickAwayListener>
        </LabelWrapper>
    </React.Fragment>)
};

export default DatosPersonales;