import React from 'react'
import { Link } from '@mui/material'
import styled from 'styled-components'

interface IPConditions {
    onlyInsured?: boolean
}

export default function Conditions({onlyInsured = false}: IPConditions) {
    const Paragraph = styled.div`
        margin-top: 16px;
    `

  return (
    <>
        {onlyInsured && <Paragraph>Aplica únicamente para el Asegurado.</Paragraph>}
        <Paragraph>
            Podrá consultar las Condiciones generales y particulares de la póliza de seguro:
                <Link
                    style={{marginLeft: '5px'}}
                    href="https://www.chubb.com/content/dam/chubb-sites/chubb-com/mx-es/condiciones-generales/global/documents/pdf/a323-aba-as-seguro-de-accidentes-personales-colectivo.pdf"
                    target={'_blank'}
                >
                    Condiciones generales
                </Link>.
                Póliza de seguro emitida por Chubb Seguros México S.A.
        </Paragraph>
    </>
  )
}
