import React from "react";
import CustomContainer from "../../organisms/Container/Container";
import MaintenancePage from "../../organisms/MaintenancePage";

const MaintenancePageTemplate: React.FC = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 40 }}>
            <CustomContainer childComponent={<MaintenancePage/>} />
        </div>
    );
};

export default MaintenancePageTemplate;
