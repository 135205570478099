import React from 'react';
import TrackingStatusComponent from '../../components/templates/TrackingStatus';

const TrackingStatus: React.FC = () => {
  // Implementa el componente aquí
  return (
    <React.Fragment>
        <TrackingStatusComponent />
    </React.Fragment>

  );
};

export default TrackingStatus;
 