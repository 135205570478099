import React, { useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { styled } from 'styled-components';
import FormItemGenerate from '../../atoms/FormItems/FormItemGenerate';
import useFetch from '../../../hooks/useFetch';
import {
    apiLoading as marcaLoading,
    apiSuccess as marcaSuccess,
    apiError as marcaError
} from '../../../api/gadgetsBrandSlice';
import { apiLoading as modelLoading, apiSuccess as modelSuccess, apiError as modelError } from '../../../api/gadgetsModelsSlice';
import { apiLoading as descriptionLoading, apiSuccess as descriptionSuccess, apiError as descriptionError } from '../../../api/gadgetsDescriptionSlice';
import { useSelector } from 'react-redux';
import {IStorageMembershipData} from "../../../utils/localStorageKeys";
import {ClickAwayListener, useMediaQuery} from "@mui/material";
import CustomToolTipComponent from "../../atoms/Tooltip/CustomTooltip";
import InfoAdButton from "../../../assets/RegisterAssets/infoAdButton";
type SignInProps = {
    errors?: any;
    handleChange?: any;
    handleBlur?: any;
}

const ContentStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`;

const DatosdeCelularEditar: React.FC<SignInProps> = ({ handleChange, handleBlur }) => {
    const matches = useMediaQuery('(min-width:600px)');
    const [phone, phoneMeta] = useField("phone");
    const [password, passwordMeta] = useField("password");
    const [passwordConfirm, passwordConfirmMeta] = useField("passwordConfirm");

    const [open, setOpen] = React.useState(false);
    const [imei, imeiMeta] = useField("imei");
    const [marcaField, marcaMeta] = useField("marca");
    const [modeloField, modeloMeta] = useField("modelo");
    const [descripcionField, descripcionMeta] = useField("descripcion");
    const [marca, setMarca] = useState('');
    const [marcaOption, setMarcaOption] = useState([]);
    const [modelo, setModelo] = useState('');
    const [modeloOption, setModeloOption] = useState([]);
    const [descripcion, setDescripcion] = useState('');
    const [descripcionOption, setDescripcionOption] = useState([]);
    const formik = useFormikContext();
    const { fetchData } = useFetch();
    //REDUX
    const response = useSelector((state: any) => state.gadgetsBrand.response) as any | null;
    const loading = useSelector((state: any) => state.gadgetsBrand.loading);
    // const error = useSelector((state: any) => state.gadgets.error);

    const responseModels = useSelector((state: any) => state.gadgetsModels.response) as any | null;
    const loadingModels = useSelector((state: any) => state.gadgetsModels.loading);
    // const errorModels = useSelector((state: any) => state.gadgetsModels.error);

    const responseDescription = useSelector((state: any) => state.gadgetsDescription.response) as any | null;
    const loadingDescription = useSelector((state: any) => state.gadgetsDescription.loading);
    // const errorDescription = useSelector((state: any) => state.gadgetsDescription.error);

    //const responseFormRegister = useSelector((state: any) => state.formRegister.response) as any | null;
    //alert(JSON.stringify(responseFormRegister))

    const currentValue = useSelector(
        (state: any) => state.productsCards.currentValue
    ) as any | null;

    const getValue = (): string => {
        return currentValue.type === 'free' ? '0' : '1'
    }

    const getMarca = () => {
        return fetchData('gadgets/get-brands/' + getValue(), 'GET', {}, {}
            , marcaLoading, marcaSuccess, marcaError);
    }

    const getModelos = (idBrand: any) => {
        return fetchData(`gadgets/get-lines/brand/${idBrand}/${getValue()}`, 'GET', {}, {}
            , modelLoading, modelSuccess, modelError);
    }

    const getDescripcion = (idLine: any) => {
        return fetchData(`gadgets/get-models/line/${idLine}/${getValue()}`, 'GET', {}, {}
            , descriptionLoading, descriptionSuccess, descriptionError);
    }

    useEffect(() => {
        if (!response) {
            getMarca();
        } else if (response?.code === 200 && response?.data.length > 0) {
            const resp = response.data.map(({ name }: any) => { return name });
            setMarcaOption(resp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    useEffect(() => {
        if (descripcion) {
            const descripcionID = responseDescription.data.find(({ name }: any) => name === descripcion)
            localStorage.setItem("id_model", descripcionID.idModel)
        }
    }, [descripcion]);

    useEffect(() => {
        if (marcaField.value && response?.code === 200 && response?.data.length > 0) {
            const findMarca = response.data.find(({ name }: any) => name === marcaField.value);
            localStorage.setItem("id_brand", findMarca.idBrand)
            getModelos(findMarca.idBrand);
        }
    }, [marca, response]);

    useEffect(() => {
        if (marca) {
            formik.setFieldValue("modelo", '')
            formik.setFieldValue("descripcion", '')
            setModeloOption([]);
            setDescripcionOption([]);
        }
    }, [marca])

    useEffect(() => {
        if (modelo) {
            formik.setFieldValue("descripcion", '')
            setDescripcionOption([]);
        }
    }, [modelo])

    useEffect(() => {
        if (modeloField.value && responseModels) {
            const findModelo = responseModels.data.find(({ name }: any) => name === modeloField.value);
            if (findModelo?.idLine) {
                localStorage.setItem("id_line", findModelo?.idLine)
                getDescripcion(findModelo?.idLine);
            }
        }
    }, [modeloField.value, responseModels]);

    useEffect(() => {
        if (responseModels?.code === 200 && responseModels?.data.length > 0) {
            const resp = responseModels.data.map(({ name }: any) => { return name });
            setModeloOption(resp);
        }
    }, [responseModels])

    useEffect(() => {
        if (responseDescription?.code === 200 && responseDescription?.data.length > 0) {
            const resp = responseDescription.data.map(({ name }: any) => { return name });
            setDescripcionOption(resp);
        }
    }, [responseDescription]);


    let storage: IStorageMembershipData = {
        ...useSelector((state: any) => state.productsCards.currentValue),
    };

    useEffect(() => {
        window.dataLayer.push({
            event: 'TRUS_registro_dispositivo_v',
            tipo_seguro: storage.type == "free" ? "De regalo" : "Premium",
            correo: undefined,
            telefono: undefined,
            page_location: window.location.href,
        });
    }, [])

    useEffect(() => {
        if (open){
            window.dataLayer.push({
                event: "TRUS_no_encuentro_v",
                tipo_seguro: storage.type == "free" ? "De regalo" : "Premium",
                correo: undefined,
                telefono: undefined,
                page_location: window.location.href,
            });
        }
    }, [open])


    const items = [{
        id: 1,
        label: 'Marca',
        name: 'marca',
        type: 'select',
        event: setMarca,
        value: marca,
        option: marcaOption,
        error: marcaField,
        meta: marcaMeta,
        loading: loading
    },
    {
        id: 2,
        label: 'Modelo',
        name: 'modelo',
        type: 'select',
        event: setModelo,
        value: modelo,
        option: modeloOption,
        error: modeloField,
        meta: modeloMeta,
        loading: loadingModels
    },
    {
        id: 3,
        label: 'Descripción',
        name: 'descripcion',
        type: 'select',
        event: setDescripcion,
        value: descripcion,
        option: descripcionOption,
        error: descripcionField,
        meta: descripcionMeta,
        loading: loadingDescription
    },
    {
        id: 4,
        label: 'IMEI',
        name: 'imei',
        as: 'textField',
        error: imei,
        meta: imeiMeta
    }]
    return (
        <ContentStyled>
            <FormItemGenerate
                items={items}
                handleChange={handleChange}
                handleBlur={handleBlur}
            />

            <ClickAwayListener onClickAway={() => setOpen(false)}>
                <CustomToolTipComponent
                    title="Comentar que descripción llevará">
                    <span style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}
                          onClick={() => setOpen(true)}>
                        {"¿Donde puedo encontrarlo? "}
                        <span style={{display: 'flex', marginLeft: 10}}>
                            <InfoAdButton fill={matches ? '#8F8F8F' : '#039ECC'}/>
                        </span>
                    </span>
                </CustomToolTipComponent>
            </ClickAwayListener>
        </ContentStyled>
    )
};

export default DatosdeCelularEditar;
