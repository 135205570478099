import React from 'react';
import { SaveAltOutlined } from "@mui/icons-material";
import { MembershipType } from "../../../Membership/MembershipMainValues";
import { BenefitValues, BenefitValuesType } from '../benefitEngine/benefit.values';
import { InterInstance } from '../../../../../api/config';

//Interfaces
interface BenefitContentProps {
    type: MembershipType;
}
export interface BenefitDetailsData {
    label: string;
    description: string;
    image: string;
    imageWhite?: string;
    onlyPremium: boolean;
    welcome: (data?: IBenefitDataWelcome) => any;
    content: (primaryColor: string) => any;
    buttons: BenefitButtons[];
}
export interface IBenefitDataWelcome {
    fullname: string
}
export type BenefitTypeButton = "primary" | "secondary" | "text"

export interface BenefitButtons {
    label: React.ReactNode | string;
    action: "back" | "go" | "function" | "outsite" | "inside_function"; //back - regresar, go - ir a una ruta, "function" - ejecutar una funciont, "outsite" - ir  fuera del sitio
    value: string | Function | Promise<void>,
    type: BenefitTypeButton
}
export type BenefitDetails = {
    [key in BenefitValuesType]?: BenefitDetailsData
}

const welcomeBase = () =>
    <div className="BenefitDetails.welcome">
        <p>Para poder obtener esta cobertura debes de seguir los siguientes pasos:</p>
    </div>;
const welcomePremium = () =>
    <div className="BenefitDetails.welcome">
        <p>
            Para iniciar el trámite de reclamación de esta cobertura necesitas enviar un correo a <strong>chubb-siniestros.mx@chubb.com</strong>  con los siguientes documentos:
        </p>
    </div>

const welcomePremiumWithName = (data?: IBenefitDataWelcome) => {
    return <div className="BenefitDetails.welcome">
        <p>
            ¡Hola <strong>{data?.fullname}</strong>! Ya puedes usar tu check-up. Para hacerlo válido, simplemente sigue estos pasos:
        </p>
    </div>
}

const contentBase = (primaryColor: string) => {
    return <div className="BenefitDetails.content" style={{ fontSize: '20px' }}>
        <ul>
            <li>
                <p>
                    Agenda una videoconsulta de telemedicina.
                </p>
            </li>
            <li>
                <p>
                    Toma tu consulta en el día y hora definido.
                </p>
            </li>
            <li>
                <p>
                    Al finalizar tu consulta, el médico tratante generará tu receta.
                </p>
            </li>
            <li>
                <p style={{
                    position: 'relative'
                }}>
                    <span>
                        Envia un correo a <br />
                    </span>
                    <span style={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        background: primaryColor,
                        padding: '4px',
                        // position: 'absolute',
                        transform: 'translate(-43px, 0px)',
                        wordBreak: "break-word"
                    }}>atencion_teregalamosunseguro@inter.mx </span>
                    <br />
                    <span >
                        con los siguientes datos:
                    </span>

                </p>
                <ol>
                    <li>
                        Nombre completo.
                    </li>
                    <li>
                        Número de certificado. (Lo localizas en tu tarjeta del portal de cliente)
                    </li>
                    <li>
                        Código postal de tu domicilio.
                    </li>
                    <li>
                        Receta médica de tu consulta de telemedicina. (Si la receta es de algún médico externo no será válida)
                    </li>
                </ol>
            </li>
            <li>
                En un lapso de 24 a 48 horas te enviaremos a tu correo las indicaciones para que puedas ir a la farmacia por tu medicamento.
            </li>
            <li>
                <strong>¡Importante!</strong> A partir de que recibas el correo, tendrás 3 días para asistir a la farmacia, si no lo haces, la orden perderá validez y perderás tu evento.
            </li>
        </ul>
    </div>
}

const contentPremium = (primaryColor: string) => {
    return <div className="BenefitDetails.content">
        <ul>
            <li>
                <p>
                    Identificación oficial vigente (INE o Pasaporte).
                </p>
            </li>
            <li>
                <p>
                    Comprobante de domicilio del titular con antigüedad no mayor a tres meses.
                </p>
            </li>
            <li>
                <p>
                    Estado de cuenta bancario del titular con antigüedad no mayor a tres meses.
                </p>
            </li>
            <li>
                <p>
                    Formato de reclamación debidamente llenado, legible y escaneado.
                </p>
            </li>
        </ul>
    </div>
}

const contentChekUp = (primaryColor: string) => {
    return <div className="BenefitDetails.content">
        <ul>
            <li>
                <p>
                    Inicia el proceso de tu solicitud al hacer clic en 'Solicitar check-up'.
                </p>
            </li>
            <li>
                <p>
                    Consulta el estado de tu solicitud en esta página.
                </p>
            </li>
            <li>
                <p>
                    Cuando esté disponible, te enviaremos un correo con las indicaciones para que puedas acudir al laboratorio.
                </p>
            </li>
            <li>
                <p>
                    <strong>¡Importante!</strong> A partir de que recibas el correo, tendrás 7 días para asistir al laboratorio; de lo contrario, la orden perderá validez.
                </p>
            </li>
        </ul>
    </div>
}

const getUrlTelemedicine = async () => {
    try {
        const { data } = await InterInstance.get("plansalud/telemedicina/otp")
        window.open(data?.data?.url, '_blank')
    } catch (error) {
        console.error(error)
    }
}

export const BenefitConfigDetails: BenefitDetails = {
    PagoMedicamentos: {
        label: BenefitValues.PagoMedicamentos,
        onlyPremium: false,
        image: require('../assets/botella-de-pastillas.png'),
        imageWhite: require('../assets/botella-de-pastillas-white.png'),
        description: 'Por si te enfermas, te ayudamos hasta con $1,000 MXN para que los compres.',
        buttons: [{
            label: "Entendido",
            action: 'back',
            type: 'primary',
            value: ''
        }, {
            label: 'agendar videoconsulta',
            action: 'function',
            type: 'secondary',
            value: getUrlTelemedicine
        }],
        welcome: welcomeBase,
        content: contentBase
    },
    ReembolsoGastosMedicos: {
        label: BenefitValues.ReembolsoGastosMedicos,
        onlyPremium: false,
        image: require('../assets/money-management.png'),
        imageWhite: require('../assets/money-management-white.png'),
        description: 'Por si te enfermas, te ayudamos hasta con $1,000 MXN para que los compres.',
        buttons: [{
            label: <div style={{display: 'flex', alignItems: 'center'}}>Formato de Reclamacion <span style={{ marginLeft: '10px' }}><SaveAltOutlined /></span>
            </div>,
            action: 'function',
            value: () => window.open('https://storage.googleapis.com/inter-public-cloudstorage-prod/tr1s/documentos/SaludProtect/Formato_Reclamacion_GastosMedicos.pdf', "_blank"),
            type: 'text'
        }, {
            label: "Entendido",
            action: 'back',
            type: 'primary',
            value: ''
        }],
        welcome: welcomePremium,
        content: contentPremium
    },
    PerdidasOrganicas: {
        label: BenefitValues.PerdidasOrganicas,
        onlyPremium: true,
        image: require('../assets/discapacitado.png'),
        description: 'En caso de pérdida de extremidades, audición y ojos en un accidente te cubrimos  hasta $300,000 MXN.',
        buttons: [{
            label: <div style={{display: 'flex', alignItems: 'center'}}>Formato de Reclamacion <span style={{ marginLeft: '10px' }}><SaveAltOutlined /></span>
            </div>,
            action: 'function',
            value: () => window.open('https://storage.googleapis.com/inter-public-cloudstorage-prod/tr1s/documentos/SaludProtect/Formato_Reclamacion_PerdidasOrganicas.pdf', "_blank"),
            type: 'text'
        }, {
            label: 'Entendido',
            action: 'back',
            type: 'primary',
            value: ''
        }],
        welcome: welcomePremium,
        content: contentPremium
    },
    InvalidezTotal: {
        label: BenefitValues.InvalidezTotal,
        onlyPremium: true,
        image: require('../assets/silla-de-ruedas.png'),
        description: 'Si a consecuencia de un accidente sufres invalidez total o permanente te apoyamos con hasta $16,000 MXN.',
        buttons: [{
            label: <div style={{display: 'flex', alignItems: 'center'}}>Formato de Reclamacion <span style={{ marginLeft: '10px' }}><SaveAltOutlined /></span>
            </div>,
            action: 'function',
            value: () => window.open('https://storage.googleapis.com/inter-public-cloudstorage-prod/tr1s/documentos/SaludProtect/Formato_Reclamacion_Invalidez.pdf', "_blank"),
            type: 'text'
        }, {
            label: 'Entendido',
            action: 'back',
            type: 'primary',
            value: ''
        }],
        welcome: welcomePremium,
        content: contentPremium
    },
    ApoyoIncapacidad: {
        label: BenefitValues.ApoyoIncapacidad,
        onlyPremium: true,
        image: require('../assets/incapacidad-para-moverse.png'),
        description: 'Si no puedes trabajar a causa de un accidente y el médico dictamina incapacidad, te apoyamos con $220 MXN por día (Hasta 30 días).',
        buttons: [{
            label: <div style={{display: 'flex', alignItems: 'center'}}>Formato de Reclamacion <span style={{ marginLeft: '10px' }}><SaveAltOutlined /></span>
            </div>,
            action: 'function',
            value: () => window.open('https://storage.googleapis.com/inter-public-cloudstorage-prod/tr1s/documentos/SaludProtect/Formato_Reclamacion_Invalidez.pdf', "_blank"),
            type: 'text'
        }, {
            label: 'Entendido',
            action: 'back',
            type: 'primary',
            value: ''
        }],
        welcome: welcomePremium,
        content: contentPremium
    },
    CheckUpMedico: {
        label: BenefitValues.CheckUpMedico,
        onlyPremium: false,
        image: require('../assets/medicamento.png'),
        imageWhite: require('../assets/medicamento_white.png'),
        description: 'Si en telemedicina te solicitan estudios te apoyamos con hasta $600 MXN en un cupón para que te los realices.',
        buttons: [{
            label: 'Solicitar check-up',
            action: 'inside_function',
            type: 'primary',
            value: (action:any)=>action()
        }],
        welcome: welcomePremiumWithName,
        content: contentChekUp
    },


}
