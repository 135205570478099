import {useState, useEffect} from 'react'
import { CustomTypography } from '../../../components/atoms/Label/Label'
import { CustomButton } from '../../../components/atoms/Buttons/Buttons'
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentValue } from '../../../api/productsSlice';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

interface InfoInterface {
  title: string;
  isPremium: boolean;
}

const SaludInfo = ({title, isPremium}: InfoInterface) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userResponse = useSelector((state: any) => state?.user?.response);
  const [userInfo, setUserInfo] = useState<any>({});
  const [birthdate, setBirthdate] = useState<any>();

  useEffect(() => {
    const { data } = userResponse
    if(data){
        const { user } = data
        if(user){
          setUserInfo(user)
          const { birthdate } = user
          if(birthdate){
            const tmpBirthdate = moment(birthdate).format('DD-MM-YYYY')
            setBirthdate(tmpBirthdate)
          }
        }
    }
  },[])

  const upgradePlan = () => {
    const sendDataLocalStorage = {
        type: "premium",
        subtype: "premium",
        category: "salud",
        cardTitle: "Seguro Inter Salud",
        benefit_id: "S01",
        plan_id: "P02"
    };
    dispatch(setCurrentValue(sendDataLocalStorage));
    navigate('/resumen-membresia', {
        state: {
            path: 'portal-del-cliente',
            tabHidden: "freemium"
        }
    })
  }

  return (
    <div
      style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          gap: '24px',
          marginBottom: "24px"
      }}
    >
      {title && <div>
        <CustomTypography
            style={{
                color: "var(--Black, #212121)",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                textAlign: "center",
            }}
        >
            <span>
                <b>{title}</b>
            </span>
        </CustomTypography>
      </div>}

      <div>
        <CustomTypography
            style={{
                color: "var(--Black, #212121)",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                textAlign: "center",
                height: "24px"
            }}
        >
            <span>
                Titular
            </span>
        </CustomTypography>
        <CustomTypography
            style={{
                color: "var(--Black, #212121)",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                textAlign: "center",
            }}
        >
            <span>
                <b>{`${userInfo?.name} ${userInfo?.middle_name} ${userInfo?.last_name}`}</b>
            </span>
        </CustomTypography>
      </div>

      <div>
        <CustomTypography
            style={{
                color: "var(--Black, #212121)",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                textAlign: "center",
                height: "24px"
            }}
        >
            <span>
                Fecha de nacimiento
            </span>
        </CustomTypography>
        <CustomTypography
            style={{
                color: "var(--Black, #212121)",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                textAlign: "center",
            }}
        >
            <span>
                <b>{birthdate}</b>
            </span>
        </CustomTypography>
      </div>

      <div>
        <CustomTypography
            style={{
                color: "var(--Black, #212121)",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                textAlign: "center",
                height: "24px"
            }}
        >
            <span>
                Sexo de nacimiento
            </span>
        </CustomTypography>
        <CustomTypography
            style={{
                color: "var(--Black, #212121)",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                textAlign: "center",
            }}
        >
            <span>
                <b>{userInfo?.gender === "M" ? "Masculino" : "Femenino"}</b>
            </span>
        </CustomTypography>
      </div>
      
      {!isPremium && <div style={{width: "100%"}}>
        <CustomButton
            text={"Quiero ser Premium"}
            variant={"contained"}
            onClick={upgradePlan}
            style={{
                fontWeight: 'bold',
                borderRadius: 10,
                boxShadow: 'none',
                color: "white",
                fontSize: "1rem",
                textTransform: "uppercase",
                maxWidth: "580px",
                justifyContent: "center",
                width: "100%",
                background: '#E6B331',
            }}
        />
      </div>}

    </div>
  )
}

export default SaludInfo
