import React from 'react';
import styled from 'styled-components';
import BlueCircle from '../../../assets/HowitworksAssets/BlueCircle';
import ElipseFinsus from '../../../assets/HowitworksAssets/ElipseFinsus';
import { themeValues, myColorPalette } from '../../../theme/themeGenerated';

const CardContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    text-align: left;
    align-items: flex-start;
`;
const CardHeader = styled.div`

`;

const CardWrapperElement = styled.div`
    display: flex;
    padding:20px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
    width: 100%;
    max-width: 380px;
/*  margin-right: 30px;*/
`;

const CardDescription = styled.span`
    flex: 1 0 0;
    color: var(--black, #212121);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const NumberContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NumberElement = styled.span`
    position: absolute;
    color: var(--white, #FFF);
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    top: 3px;
`;

type CardWrapperProps = {
    imgItem: React.ReactNode;
    value: number;
    description?: string;
    descriptionBold?: any
};

const CardWrapper: React.FC<CardWrapperProps> = ({ imgItem, value, description, descriptionBold }) => {
    return (<CardWrapperElement>
        <CardContent>
            <NumberContainer>
                <NumberElement>{value}</NumberElement>


                {
                    window.location.pathname === "/finsus" ? <ElipseFinsus /> : <BlueCircle />
                }





            </NumberContainer>
            {description &&
                <CardDescription>{description}</CardDescription>}
            {descriptionBold &&
                <CardDescription>{descriptionBold}</CardDescription>}
        </CardContent>
        {/*<CardHeader>
            {imgItem}
        </CardHeader>*/}
    </CardWrapperElement>);
};

export default CardWrapper;
