import React, { useEffect } from "react";
import { styled } from "styled-components";
import {
  Button,
  FormControl,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@mui/material";
import { CustomTypography } from "../../../atoms/Label/Label";
import MembershipPremiumUpdate from "../../Membership/MembershipCard/MembershipPremiumUpdate/MembershipPremiumUpdate";
import { useFormikContext } from "formik";

const FormLabelCustom = styled.label`
  color: #212121;
  font-weight: 700;
  font-size: 18px;
  line-height: normal;
  padding: 0;
  position: relative;
  padding-left: 4px;
`;

const ColInter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  align-items: center;
`;

const RowInter = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 946px;
  align-items: flex-start;
  gap: 24px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

type ElegirCoberturaProps = {
  toppings: any;
  handleChange: any;
  isSubmitting?: any;
};

const ElegirCobertura: React.FC<ElegirCoberturaProps> = ({
  toppings,
  handleChange,
  isSubmitting,
}) => {
  const formik: any = useFormikContext();
  const [value, setValue] = React.useState("");
  const matches = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    if (formik?.initialValues.picked) {
      setValue(formik?.initialValues?.picked);
    } else {
      setValue(toppings[0].benefit_id);
      formik.setFieldValue('picked', toppings[0].benefit_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RowInter>
      <ColInter
        style={{
          alignItems: "flex-start",
          position: "relative",
          flex: 2,
        }}
      >
        <CustomTypography
          item={"Elige tu cobertura gratuita"}
          style={{
            fontSize: 24,
            fontStyle: "normal",
            textAlign: "center",
            fontWeight: 700,
            lineHeight: "normal",
            letterSpacing: "0.5px",
            color: "var(--black, #212121)",
            padding: "0 1rem",
          }}
        />
        <FormControl>
          <RadioGroup
            aria-labelledby="picked"
            name="picked"
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleChange(event);
              setValue((event.target as HTMLInputElement).value);
            }}
            style={{
              gap: 40,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {toppings.map(({ benefit_id, title, description }: any) => (
              <div
                key={benefit_id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 0,
                  alignItems: "flex-start",
                }}
              >
                <Radio sx={{ p: 0 }} value={benefit_id} />
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 4 }}
                >
                  <FormLabelCustom>{title}</FormLabelCustom>
                  <FormLabelCustom style={{ fontSize: 16, fontWeight: 400 }}>
                    {description}
                  </FormLabelCustom>
                </div>
              </div>
            ))}
          </RadioGroup>
        </FormControl>
      </ColInter>
      <ColInter style={{ flex: 1 }}>
        <MembershipPremiumUpdate 
          cardType={"premium"}
          subType={"esencial"}
          cardCategory={"salud"}
          cardDescription={"Regístrate gratis y elige la cobertura que más te convenga: check- up médico gratuito, pago de medicamentos hasta por $1,000 pesos o pago de gastos médicos hasta por 10,000."} 
          cardTitle={"Seguro Inter Salud"} 
        />
        <Button
          type="submit"
          size={"medium"}
          variant={"contained"}
          disabled={isSubmitting}
          fullWidth
          style={{
            borderRadius: 8,
            background: "var(--azul-inter, #039ECC)",
            width: "100%",
            padding: "14px 16px",
            color: "#FFF",
          }}
        >
          Continuar
        </Button>
      </ColInter>
    </RowInter>
  );
};

export default ElegirCobertura;
