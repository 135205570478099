
import React, { useEffect, useContext } from 'react';
import { Typography } from "@mui/material";
import { CustomButton } from "../../../src/components/atoms/Buttons/Buttons";
import { useLocation } from 'react-router-dom';
import giffy from '../../assets/payment/gifgif.gif';
import { logout } from '../../auth/auth0';
import { restartValue } from '../../api/loginSlice';
import { restartValue as restartBeneficiary } from '../../api/multipleBeneficiarySlice';
import { restartValue as restartAllianceSetting } from '../../api/allianceSettingSlice';
import { useDispatch  } from 'react-redux';
import { ContextAuth0 } from '../../auth/contextAPIProvider';

interface DeleteAccountProps {
    styleButtonPrimary?: object;
}

const OkDeleteAccount: React.FC<DeleteAccountProps> = ({
    styleButtonPrimary,
}) => {
    const location = useLocation();
    //Esta variable servira para saber si se borra toda la cuenta
    //O solo una de las suscripciones
    const { state: { deleteAcount } } = location
    
    const dispatch = useDispatch()
    const webAuth: any = useContext(ContextAuth0);
    const mediaQuery = window.matchMedia('(max-width: 414px) and (max-height: 996px) and (-webkit-device-pixel-ratio: 2)');

    const closeSession = () => {
        sessionStorage.setItem('userError', '2');
        sessionStorage.removeItem('user-storage');
        sessionStorage.removeItem('card-type-storage');
        dispatch(restartValue());
        dispatch(restartBeneficiary());
        dispatch(restartAllianceSetting())
        localStorage.removeItem("persist:root")
        logout(webAuth);
    };

    useEffect(()=>{
        return () => {
            closeSession()
        }
    },[])
    
    return (
        <div style={{
            maxWidth: '752px',
            margin: 'auto',
            padding: '0px 16px'
        }}>
            <div
                id='full-container'
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '24px',
                    alignSelf: 'stretch',
                    width: '100%',
                    height: '100%',
                    maxWidth: '557px',
                    margin: 'auto',
                    marginTop: "20px",
                    marginBottom: "50px"
                }}
            >

                <Typography
                    gutterBottom
                    variant="h5"
                    component="p"
                    sx={{ textWrap: "balance" }}
                    style={{
                        width: '100%',
                        color: 'var(--Black, #212121)',
                        textAlign: 'center',
                        fontFamily: 'Montserrat',
                        fontSize: '28px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: 'normal',
                    }}
                >
                    Tu cuenta se ha cancelado con éxito
                </Typography>

                <img width='224px'
                    height='224px'
                    src={giffy} alt="Logo" 
                />

                <Typography
                    sx={{ textWrap: "balance" }}
                    style={{
                        color: 'var(--Black, #212121)',
                        textAlign: 'center',
                        fontFeatureSettings: 'clig off, liga off',
                        fontFamily: 'Montserrat',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                    }}
                >
                    Si en el futuro deseas tener nuevamente tu seguro con nosotros, podrás registrarte sin ningún problema
                </Typography>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    }}
                >
                    <CustomButton
                        onClick={closeSession}
                        text={'Entendido'}
                        style={{
                            display: 'flex',
                            height: '48px',
                            padding: '16px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            width: mediaQuery ? '100%' : '344px',
                            alignSelf: 'stretch',
                            borderRadius: '10px',
                            border: '1px solid var(--White, #FFF)',
                            opacity: 0.8,
                            background: 'var(--Azul-Inter, #039ECC)',
                            color: "white",
                            ...styleButtonPrimary
                        }}
                    />
                </div>
            </div >
        </div>)
}

export default OkDeleteAccount