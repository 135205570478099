
import React from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { AppleButton } from '../../components/Footer/AppleButton';
import { GoogleButton } from '../../components/Footer/GoogleButton';
import LogoLight from './assets/logo-light.png';
import TikTokIcon from '../../components/Footer/assets/TiktokIcon';

import XforelonMusk from '../../components/Footer/assets/XForElonMusk';
import { useSelector } from "react-redux";
import Stack from "@mui/material/Stack";


const SocialMediaComponent = () => {
    return (
        <Grid item xs={12} md={4}>
            <Box display={'flex'} flexDirection={'column'}>
                <Typography
                    variant={'subtitle1'}
                    color={'white'}
                    fontWeight={'bold'}
                >
                    {'Descarga nuestra app'}
                </Typography>
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    marginTop={2}
                >
                    <Link
                        href="https://apps.apple.com/mx/app/intermx/id1548367767?l=en"
                        target={'_blank'}
                        style={{ textDecoration: 'none' }}
                        onClick={
                            () => {
                                window.dataLayer.push({
                                    event: 'ev_btn_footer', //Static data
                                    option: 'App Store', //Dynamic data
                                })
                            }
                        }
                    >
                        <AppleButton />
                    </Link>
                    <Box marginLeft={0.8}
                    />
                    <Link
                        href="https://play.google.com/store/apps/details?id=grut.inter.mx"
                        target={'_blank'}
                        style={{ textDecoration: 'none' }}
                        onClick={
                            () => {
                                window.dataLayer.push({
                                    event: 'ev_btn_footer', //Static data
                                    option: 'Google Play', //Dynamic data
                                })
                            }
                        }
                    >
                        <GoogleButton />
                    </Link>
                </Box>
                <Box marginBottom={4} />
                <Typography
                    variant={'subtitle1'}
                    fontWeight={'bold'}
                    color={'white'}
                >
                    {'Síguenos en redes sociales'}
                </Typography>
                <Box marginTop={'10px'}>
                    <Link
                        href="https://www.facebook.com/intermx"
                        target={'_blank'}
                        onClick={
                            () => {
                                window.dataLayer.push({
                                    event: 'ev_social_networks', //Static data
                                    option: 'Facebook', //Dynamic data
                                })
                            }
                        }
                    >
                        <FacebookIcon
                            style={{
                                color: '#FFFFFF',
                                marginRight: '28.06px',
                                cursor: 'pointer',
                            }}></FacebookIcon>
                    </Link>
                    <Link
                        href="https://www.instagram.com/intermx/"
                        target={'_blank'}
                        onClick={
                            () => {
                                window.dataLayer.push({
                                    event: 'ev_social_networks', //Static data
                                    option: 'Instagram', //Dynamic data
                                })
                            }
                        }
                    >
                        <InstagramIcon
                            style={{
                                color: '#FFFFFF',
                                marginRight: '28.06px',
                                cursor: 'pointer',
                            }}
                        />
                    </Link>
                    <Link
                        href="https://www.linkedin.com/company/interproteccion/"
                        target={'_blank'}
                        onClick={
                            () => {
                                window.dataLayer.push({
                                    event: 'ev_social_networks', //Static data
                                    option: 'Linkedin', //Dynamic data
                                })
                            }
                        }
                    >
                        <LinkedInIcon
                            style={{
                                color: '#FFFFFF',
                                marginRight: '28.06px',
                                cursor: 'pointer',
                            }}
                        />
                    </Link>
                    <Link
                        href="https://www.tiktok.com/@inter.mx"
                        target={'_blank'}
                    >
                        <TikTokIcon
                            style={{
                                color: '#FFFFFF',
                                marginRight: '28.06px',
                                cursor: 'pointer',
                            }}
                        />
                    </Link>
                    <Link
                        href="https://www.youtube.com/channel/UCZ8wH2JLzDYvvrN-JiG3roA"
                        target={'_blank'}
                        onClick={
                            () => {
                                window.dataLayer.push({
                                    event: 'ev_social_networks', //Static data
                                    option: 'Youtube', //Dynamic data
                                })
                            }
                        }
                    >
                        <YouTubeIcon
                            style={{
                                color: '#FFFFFF',
                                marginRight: '28.06px',
                                cursor: 'pointer',
                            }}
                        />

                    </Link>
                    <Link
                        href="https://twitter.com/intermx"
                        target={'_blank'}
                    >
                        <XforelonMusk
                            style={{
                                color: '#FFFFFF',
                                cursor: 'pointer',
                                marginRight: '28.06px',
                            }}
                        />
                    </Link>
                </Box>
            </Box>
        </Grid>
    );
};

export default SocialMediaComponent;

