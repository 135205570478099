import React from "react";
import CustomContainer from "../../organisms/Container/Container";
import ErrorPagina from "../../organisms/ErrorPagina";

const ErrorTemplate: React.FC = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 40 }}>
      <CustomContainer childComponent={<ErrorPagina />} />
    </div>
  );
};

export default ErrorTemplate;
