import React, { useState } from 'react';
import * as Yup from 'yup';
import { styled } from 'styled-components';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { apiSuccess} from '../../../api/formRegisterSlice';

import DatosdeCelularEditar from './DatosDeCelularEdit';
import FormContainer from '../../organisms/Form/FormContainer';
import { CustomButton } from '../../atoms/Buttons/Buttons';
import { ArrowBack } from '@mui/icons-material';
import { OutlinedButton } from '../../molecules/CellPhoneRepairButtons'
import { IStorageMembershipData } from '../../../utils/localStorageKeys';
import { fieldIsRequired } from '../../../utils/scrollToTop';
import {isAValidIMEI} from "../../../utils/validationInput";

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`;

const ContentStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`;


const CellPhoneEditData: React.FC = () => {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState<number>(0);

    const [open, setOpen] = React.useState(false);
    // const { phone } = JSON.parse(localStorage.getItem('FormObserver') || '{}');
    //const response = useSelector((state: any) => state.formSignIn.response) as any | null;
    const response = useSelector((state: any) => state.formRegister.response) as any | null;

        const { phone } = useSelector((state: any) => state.formRegister.response) as any | null;

    const membershipTypeModule = {
        datosCelularEditar: {
            formSteps: 0, //starting from 0
            steps: [], // steps title
            titles: [], // content title
            initialValues: {
                marca: '',
                modelo: '',
                descripcion: '',
                imei: ''
            }
        }
    }

    const renderStepContent = (step: number, errors: any, handleChange: any, handleBlur: any) => {
        switch (step) {
            case 0:
                return (<DatosdeCelularEditar handleChange={handleChange} handleBlur={handleBlur} errors={errors}/>);
            default:
                return null;
        }
    };

    const _sleep = (ms: number) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const _submitForm: any = async (values: any, actions: any) => {
        await _sleep(1000);
        //alert(JSON.stringify(values, null, 2));
        actions.setSubmitting(true);
        navigate('/confirmar-datos')
    }

    const _handleSubmit = async (values: any, actions: any) => {
        _submitForm(values, actions);
    }

    const validationSchemaStep = Yup.object().shape({
        marca: Yup.string().required(fieldIsRequired),
        modelo: Yup.string().required(fieldIsRequired),
        descripcion: Yup.string().required(fieldIsRequired),
        imei: Yup.string()
           .test("valid-imei", "IMEI inválido", (value) => isAValidIMEI(value || ""))
           .min(15, "El minimo de caracteres es 15")
           .max(15, "El limite de caracteres es 15")
           .required(fieldIsRequired),
    })

    const validatorSchemaStepper = () => {
        return validationSchemaStep;
    }
    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    let storage: IStorageMembershipData = {
        ...useSelector((state: any) => state.productsCards.currentValue),
      };

      const userData = JSON.parse(sessionStorage.getItem('user-storage')!);
      const userResponse = useSelector((state: any) => state.user.response);

      React.useEffect(() => {
        window.dataLayer.push({
            'event': 'TRUS_reparacion_actualizar_datos_v',
            tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium" ,//premium | gratuito
            'correo': userResponse?.data?.user?.email,
            'telefono': userResponse?.data?.user?.phone,
            'page_location': window.location.href,
        });
    }, [])

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
            <FormWrapper style={{ width: '90%', display: 'flex', flexDirection: 'column', maxWidth:'558px'}}>
                <CustomButton
                    text={"Regresar"}
                    onClick={() => (activeStep === 0 ? navigate(-1) : handleBack())}
                    size={"medium"}
                    startIcon={<ArrowBack />}
                    variant={"contained"}
                    style={{
                        color: "#039ECC",
                        backgroundColor: "transparent",
                        fontSize: "1rem",
                        textTransform: "none",
                        fontWeight: "600",
                        fontStyle: "normal",
                        padding: "8px 10px",
                        minWidth: "64px",
                        border: "none",
                        justifyContent: "flex-start",
                        width: "fit-content",
                    }}
                />
                <h2 style={{textAlign: 'center'}}>Solicitar actualización de mis datos de celular</h2>
                <FormContainer
                    buttonNext={true}
                    initialValueImportant
                    steps={membershipTypeModule.datosCelularEditar.steps}
                    initialValues={membershipTypeModule.datosCelularEditar.initialValues}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    buttonText={"Solicitar"}
                    title={membershipTypeModule.datosCelularEditar.titles}
                    renderStepContent={renderStepContent}
                    _handleSubmit={_handleSubmit}
                    validatorSchemaStepper={validatorSchemaStepper}
                    formObserverKey='FormObserverDatosCelularEdit'
                    formObserverSlice={apiSuccess}
                    localData={response}
                />
                <ContentStyled>
                    <OutlinedButton
                        text='CANCELAR'
                        onClick={() => navigate("/portal-del-cliente")}
                    />
                </ContentStyled>
            </FormWrapper>

        </div>

    );
};

export default CellPhoneEditData;
