import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="none"
            viewBox="0 0 22 22"
        >
            <path
                fill="#039ECC"
                fillRule="evenodd"
                d="M1.833 11A9.166 9.166 0 0111 1.833 9.166 9.166 0 0120.167 11 9.166 9.166 0 0111 20.167 9.166 9.166 0 011.833 11zM11 3.667a7.333 7.333 0 100 14.666 7.333 7.333 0 000-14.666z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#039ECC"
                fillRule="evenodd"
                d="M11.917 6.417a.917.917 0 00-1.834 0v3.666H6.417a.917.917 0 000 1.834h3.666v3.666a.917.917 0 001.834 0v-3.666h3.666a.917.917 0 000-1.834h-3.666V6.417z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default Icon;