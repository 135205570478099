import React from 'react';
import LoginTemplate from '../../components/templates/Login';

type LoginProps = {};

const Login: React.FC<LoginProps> = () => {
  return <LoginTemplate />;
};

export default Login;
