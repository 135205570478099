import React from 'react'

interface IPTable {
    title?: string;
    headers?: boolean
    concepts: any[]
}

export default function Table({title = "Resumen de coberturas", headers = true, concepts}:IPTable) {

    const style = {
        border: '1px solid black',
        padding: '10px',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal'
    };

    const styleforStyle = {
        border: '1px solid black',
        padding: '10px',
        fontSize: '16px',
        fontStyle: 'normal',
        lineHeight: 'normal'
    };

    return (
        <table style={{border: '1px solid var(--Black, #212121)', borderCollapse: 'collapse', width: '100%'}}>
            <thead>
                <th
                    style={{
                        border: '1px solid var(--Black, #212121)',
                        background: 'var(--Black, #212121)',
                        color: 'var(--White, #FFF)',
                        padding: '10px'
                    }}
                    colSpan={2}
                >{title}</th>
            </thead>
            <tbody>
                {headers && <tr>
                    <td style={style}>Concepto</td>
                    <td style={style}>Detalle</td>
                </tr>}
                {concepts.map(concept => <tr>
                    <td style={style}>{concept?.title}</td>
                    <td style={styleforStyle}>{concept?.description}</td>
                </tr>)}
            </tbody>
        </table>
    )
}
