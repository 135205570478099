import React, { useState, useEffect, useRef, useContext } from "react";
import * as Yup from "yup";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { styled } from "styled-components";

import FormContainer from "../../../organisms/Form/FormContainer";
import { CustomButton } from "../../../atoms/Buttons/Buttons";
import ValidacionNumeroCelular from "../../../organisms/Form/FormContent/ValidacionNumeroCelular";
import DatosDeContacto from "../../../organisms/Form/FormContent/DatosDeContacto";
import DatosPersonales from "../../../organisms/Form/FormContent/DatosPersonales";
import DatosDelCelular from "../../../organisms/Form/FormContent/DatosDeCelular";
import { isAValidIMEI } from "../../../../utils/validationInput";
import { MembershipCategoryType } from "../../../organisms/Membership/MembershipMainValues";
import { apiSuccess, restartValue } from "../../../../api/formRegisterSlice";
import { restartValue as restartBrand } from "../../../../api/gadgetsBrandSlice";
import { restartValue as restartModels } from "../../../../api/gadgetsModelsSlice";
import { restartValue as restartDescription } from "../../../../api/gadgetsDescriptionSlice";
import { restartValueError as restartLeadsError, restartValue as restartLeads } from "../../../../api/leadsSlice";
import { restartValue as restartUser } from "../../../../api/userSlice";
import { restartValue as restartSignUp } from "../../../../api/signUpSlice";
import { restartValue as restartLogin } from '../../../../api/loginSlice';
import { restartValue as restarBeneficiary } from "../../../../api/multipleBeneficiarySlice";
import {
  apiSuccess as successTracking,
  apiError as errorTracking,
  apiLoading as loadingTracking,
} from "../../../../api/trackingSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchTrackingStore } from "../../../../api/fetchingTrackingStore";
import useFetch from "../../../../hooks/useFetch";
import {
  apiLoading as apiLoadingLeads,
  apiSuccess as apiSuccessLeads,
  apiError as apiErrorLeads,
} from "../../../../api/leadsSlice";
import BasicModal from "../../../molecules/BasicModal/BasicModal";
import CardAction from "../../../organisms/Card";
import ManiPosesBody from "../../../../assets/ManiErrorHandler/ManiPosesErrorHandler.jpg";
import { getBrowserInfo } from "../../../../utils/navigatorDetails";
import { logout } from '../../../../auth/auth0';
import { ContextAuth0 } from '../../../../auth/contextAPIProvider';
import { fieldIsRequired, scrollToTop } from "../../../../utils/scrollToTop";
import MembershipSummaryCard from "../../../organisms/Membership/MembershipCard/MembershipCard";
import CreateAccount from "../../../organisms/CreateAccount";
import MakePaymentTemplate from "../../MakePayment";
import { IStorageMembershipData } from "../../../../utils/localStorageKeys";
import { useMediaQuery, useTheme } from "@mui/material";
import FORMDATA from "../../../organisms/Form/form.config";
import { myColorPalette } from "../../../../theme/themeGenerated";

type Props = {
  maxWidth: string;
}

const FormWrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 85px;
  gap: 24px;
  align-items: flex-start;
  max-width: ${(props:any) => props.maxWidth};
`;

const PremiumHealthForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { idStepper } = useParams();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const isTablet = useMediaQuery(breakpoints.down("md"));
  const [buttonNextHidded, setButtonNextHidded] = useState<boolean>(true);
  const webAuth: any = useContext(ContextAuth0);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [onSubmitActivated, setOnSubmitActivated] = useState("inactive");
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const loadingLeads = useSelector((state: any) => state.leads.loading) as | any | null;
  const errorLeads = useSelector((state: any) => state.leads.error) as | any | null;
  const response = useSelector((state: any) => state.formRegister.response) as
    | any
    | null;
  const currentValue = useSelector(
    (state: any) => state.productsCards.currentValue
  ) as any | null;
  const tracking = useSelector((state: any) => state.tracking.response) as
    | any
    | null;
  const leads = useSelector((state: any) => state.leads.response) as any | null;
  const productsCards = useSelector(
    (state: any) => state.productsCards.response
  ) as any | null;
  const benefits = useSelector((state: any) => state.gadgets.response) as
    | any
    | null;
  const [open, setOpenModal] = useState<boolean>(false);
  const { fetchData } = useFetch();
  const actionsRef: any = useRef();
  const valuesRef: any = useRef();
  const formikRef: any = useRef();
  const [ styledCustomized, setStyledCustomized ] = useState('558px');

  let membershipType: MembershipCategoryType =
    currentValue?.category?.toLowerCase();

  let storage: IStorageMembershipData = {
    ...useSelector((state: any) => state.productsCards.currentValue),
  };
  const allianceSettingResponse = useSelector((state: any) => state.allianceSetting.response);


  useEffect(() => {
    // console.log("CAMBIANDO STEPS", activeStep);
  }, [activeStep]);
  const getValuesByPropertyValue = (object: any, propertyCopied: any) => {
    let newObject: { [key: string]: any } = {};

    for (const property of propertyCopied) {
      if (object.hasOwnProperty(property)) {
        newObject[property] = typeof object[property] === "string" ? object[property].trim() : object[property];
      }
    }

    // const getProduct = productsCards.data.products.find(
    //   (product: any) =>
    //     product.category === currentValue.category &&
    //     product.type === currentValue.type
    // );
    // const hash: any = [];

    // const getBenefitId = benefits?.data[currentValue?.subtype || "freemium"]?.benefits.filter(
    //   (item: any) => {
    //     const duplicated = !hash[item.benefit_id];
    //     hash[item.benefit_id] = true;

    //     return duplicated;
    //   }
    // );

    // if (getBenefitId?.length === 1) {
    //   newObject["benefit_id"] = getBenefitId[0]["benefit_id"];
    // }
    newObject["benefit_id"] = currentValue.benefit_id
    newObject["imei"] = Number(newObject.imei);
    newObject["plan_id"] = currentValue.plan_id;

    return newObject;
  };

  useEffect(() => {
    if (!currentValue?.category) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (idStepper) {
      if(location.pathname.includes('finsus')){
        navigate(`/finsus/registrate/${activeStep + 1}`)
      }else{
        navigate(`/registrate/${activeStep + 1}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  const membershipTypeModule = {
    salud: {
      formSteps: 5,
      steps: ["Paso 1 de 6", "Paso 2 de 6", "Paso 3 de 6", "Paso 4 de 6", "Paso 5 de 6", "Paso 6 de 6"],
      titles: [
        "Para obtener tu seguro, primero ayúdanos con tus datos personales",
        "Vamos con tus datos de contacto",
        "Continuemos con la validación de tu número de celular",
        "Verifica la información del plan que seleccionaste",
        "Ingresa los datos de la tarjeta con la que quieres realizar el pago",
        "Por último, crea tu contraseña para activar tu cuenta"
      ],
      initialValues: {
        name: "",
        middle_name: "",
        last_name: "",
        birthdate: "",
        zipcode: "",
        mexicanciticen: true,
        email: "",
        phone: "",
        acceptTerms: false,
        picked: "",
        otp: "",
        marca: "",
        modelo: "",
        descripcion: "",
        imei: "",
        recaptcha: false,
      },
      stepsTextRequired: [1,2]
    },
    celular: {
      formSteps: 6,
      steps: ["Paso 1 de 7", "Paso 2 de 7", "Paso 3 de 7", "Paso 4 de 7", "Paso 5 de 7", "Paso 6 de 7", "Paso 7 de 7"],
      titles: [
        "Para obtener tu seguro, primero ayúdanos con los datos de tu celular",
        "Ahora, apóyanos con tus datos personales para poder llenar tu certificado",
        "Vamos con tus datos de contacto",
        "Por último, validemos tu número de celular",
        "Verifica la información del plan que seleccionaste",
        "Ingresa los datos de la tarjeta con la que quieres realizar el pago",
        "Por último, crea tu contraseña para activar tu cuenta"
      ],
      initialValues: {
        name: "",
        middle_name: "",
        last_name: "",
        birthdate: "",
        zipcode: "",
        mexicanciticen: true,
        email: "",
        phone: "",
        acceptTerms: false,
        picked: "",
        otp: "",
        marca: "",
        modelo: "",
        descripcion: "",
        imei: "",
        recaptcha: false,
      },
      stepsTextRequired: [1,2,3]
    },
  };

  const handleBack = () => {
    window.dataLayer.push({
      event: 'TRUS_registro_regresar',
      seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
      tipo_seguro: storage.type == "free" ? "De regalo" : "Premium",
      boton_contacto: "Regresar",
      paso: `Paso ${activeStep + 1}`,
      alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
    })
    setActiveStep((prevStep) => prevStep - 1);
  };

  const renderStepContent = (
    step: number,
    errors: any,
    handleChange: any,
    handleBlur: any
  ) => {
    setStyledCustomized('558px');

    switch (step) {

      case 0:
        setButtonNextHidded(true);
        return (
          <DatosPersonales
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors}
          />
        );

      case 1:
        setButtonNextHidded(true);
        return (
          <DatosDeContacto
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors}
          />
        );
      case 2:
        setButtonNextHidded(true);
        return <ValidacionNumeroCelular handleChange={handleChange} />;
      case 3:
        setButtonNextHidded(isMobile === false && isTablet === false ? false : true);
        setStyledCustomized(isMobile === false && isTablet === false ? '946px': '558px')
        return <MembershipSummaryCard onlyCardPresentation />;
      case 4:
        setButtonNextHidded(false);
        setStyledCustomized(isMobile === false && isTablet === false ? '946px': '558px')
        return <MakePaymentTemplate handleChange={handleChange} onlyCardPresentation setActiveStep={setActiveStep} />;
      case 5:
        setButtonNextHidded(false);
        return <CreateAccount />;
      default:
        return <></>;
    }
  };

  const _submitForm: any = async (values: any, actions: any) => {
    // console.log("")
    // actions.setSubmitting(true);
    // navigate("/resumen-membresia", { state: { path: "registrate" } });
  };
  const formSteps = membershipTypeModule[membershipType]?.formSteps;

  const _handleSubmit = async (values: any, actions: any) => {
    const steppers = [
      [
        "name",
        "middle_name",
        "last_name",
        "birthdate",
        "mexicanciticen",
        "zipcode",
        "marca",
        "modelo",
        "descripcion",
        // "imei",
      ],
      [
        "name",
        "middle_name",
        "last_name",
        "birthdate",
        "mexicanciticen",
        "zipcode",
        "email",
        "phone",
        "acceptTerms",
        "marca",
        "modelo",
        "descripcion",
        // "imei",
        "gender"
      ],
      [
        "name",
        "middle_name",
        "last_name",
        "birthdate",
        "mexicanciticen",
        "zipcode",
        "email",
        "phone",
        "acceptTerms",
        "otp",
        "marca",
        "modelo",
        "descripcion",
        // "imei",
        "gender"
      ],
      [
        "name",
        "middle_name",
        "last_name",
        "birthdate",
        "mexicanciticen",
        "zipcode",
        "email",
        "phone",
        "acceptTerms",
        "otp",
        "marca",
        "modelo",
        "descripcion",
        // "imei",
        "gender"
      ],
      [
        "name",
        "middle_name",
        "last_name",
        "birthdate",
        "mexicanciticen",
        "zipcode",
        "email",
        "phone",
        "acceptTerms",
        "otp",
        "marca",
        "modelo",
        "descripcion",
        // "imei",
        "gender"
      ],
    ];
    let content: any = {};
    if (steppers[activeStep].length > 0) {
      content = getValuesByPropertyValue(values, steppers[activeStep]);
    }

    content['tier'] = currentValue?.subtype || "freemium";
    content['step'] = activeStep;

    if(allianceSettingResponse?.data?.id_bussiness){
      content['business'] = allianceSettingResponse?.data?.id_bussiness
    }

    fetchTrackingStore(
      "/users/leads",
      "save-lead",
      {
        content: {
          tracking_id: tracking?.data?.tracking_id, // generado por el endpoint anterior
          ...content,
        },
      },
      fetchData,
      apiLoadingLeads,
      apiSuccessLeads,
      apiErrorLeads
    );
    actionsRef.current = actions;
    valuesRef.current = values;
  }

  useEffect(() => {
    if (loadingLeads && onSubmitActivated === "inactive") {
      setOnSubmitActivated("pending");
    } else if (!loadingLeads && onSubmitActivated === "pending" && !errorLeads) {
      setOnSubmitActivated("active");
    } else if (errorLeads) {
      dispatch(restartLeadsError());
      setOnSubmitActivated("inactive");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingLeads]);

  useEffect(() => {
    if (leads?.ok && actionsRef.current && valuesRef.current && onSubmitActivated === "active") {
      setOnSubmitActivated("inactive")
      scrollToTop()
      if (activeStep === formSteps) {
        _submitForm(valuesRef.current, actionsRef.current);
      } else {
        // if (membershipType === "salud" && activeStep === 2) {
        //   if (currentValue?.type !== "premium") {
        //     navigate("/elegir-cobertura");
        //   } else {
        //     _submitForm(valuesRef.current, actionsRef.current);
        //   }
        // } else {

        // }
        setActiveStep(activeStep + 1);
        actionsRef.current.setTouched({});
        actionsRef.current.setSubmitting(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmitActivated]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(fieldIsRequired),
    middle_name: Yup.string().required(fieldIsRequired),
    last_name: Yup.string().required(fieldIsRequired),
    gender: Yup.string().required(fieldIsRequired),
    birthdate: Yup.date()
      .required(fieldIsRequired)
      .min(
        new Date(new Date().setFullYear(new Date().getFullYear() - FORMDATA.DatosPersonales.Age.salud.max)),
        `El rango de edad debe de ser menor de ${FORMDATA.DatosPersonales.Age.salud.max} años`
      )
      .max(
        new Date(new Date().setFullYear(new Date().getFullYear() - FORMDATA.DatosPersonales.Age.salud.min)),
        `Debes tener al menos ${FORMDATA.DatosPersonales.Age.salud.min} años`
      ),
    zipcode: Yup.string().required(fieldIsRequired),
    // mexicanciticen: Yup.boolean().oneOf(
    //   [true],
    //   "Debes declarar que eres mexicano"
    // ),
  });

  const validationSchemaStep2 = Yup.object().shape({
    phone: Yup.string()
      .phone("MX", "Por favor, ingresa un número de teléfono válido en México")
      .max(10, "maximo 10 caracteres")
      .required(fieldIsRequired),
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]{2,}(\.[a-zA-Z]{2,})+$/,
        "Este correo no es valido"
      ).test('toLoweCase', '', (value: any) => {
        return value?.toLowerCase()
      })
      .required(fieldIsRequired),
    acceptTerms: Yup.boolean().oneOf(
      [true],
      "Debes aceptar los términos y condiciones"
    ),
    //recaptcha: Yup.boolean().oneOf([true], "Debes de validar el captcha")
  });

  const validationSchemaStep3 = Yup.object().shape({
    otp: Yup.string().min(6, "Minimo 6 caracteres").required(fieldIsRequired),
  });

  const validationSchemaStep4 = Yup.object().shape({
    marca: Yup.string().required(fieldIsRequired),
    modelo: Yup.string().required(fieldIsRequired),
    descripcion: Yup.string().required(fieldIsRequired),
    // imei: Yup.string()
    //   .test("valid-imei", "IMEI inválido", (value) => isAValidIMEI(value || ""))
    //   .min(15, "El minimo de caracteres es 15")
    //   .max(15, "El limite de caracteres es 15")
    //   .required(fieldIsRequired),
  });

  const validationSchemaStepEmpty = Yup.object().shape({});

  const validatorSchemaStepper = () => {
    switch (activeStep) {
      case 0:
        return validationSchema;
      case 1:
        return validationSchemaStep2;
      case 2:
        return validationSchemaStep3;
      case 3:
        return validationSchemaStepEmpty;
      case 4:
        return validationSchemaStepEmpty;
      case 5:
        return validationSchemaStepEmpty;
      default:
        throw Error("Verificar que el schema este asignado");
    }
  };

  useEffect(() => {
    if ((leads || sessionStorage?.access_token) && activeStep === 0) {
      setOpenModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = () => {
    if (isLogged) {
      dispatch(restartLogin());
      dispatch(restarBeneficiary());
      logout(webAuth);
    } else {
      setOpenModal(false);
    }
  };

  const clearFields = (dataToClear: Array<string>) => {
    dataToClear.length > 0 && dataToClear.filter((value) => {
      formikRef.current.setFieldValue(value, "");
    })
  }

  const restartRegister = () => {
    dispatch(restartValue());
    dispatch(restartBrand());
    dispatch(restartModels());
    dispatch(restartLeads());
    dispatch(restartDescription());
    dispatch(restartUser());
    dispatch(restartSignUp());
    clearFields([
      "name",
      "middle_name",
      "last_name",
      "birthdate",
      "mexicanciticen",
      "zipcode",
      "email",
      "phone",
      "acceptTerms",
      "marca",
      "modelo",
      "descripcion",
      "imei",
      "gender",
      "otp"
    ])

    fetchTrackingStore(
      "users/tracking",
      "create-tracking",
      {
        content: {
          data: {},
          ...getBrowserInfo(),
        },
      },
      fetchData,
      loadingTracking,
      successTracking,
      errorTracking
    );
    setOpenModal(false);
  };

  const onClickSecondary = () => {
    restartRegister();
  };

  const getFormikReference = (formik: any) => {
    formikRef.current = formik;
  };

  useEffect(() => {
    setIsLogged(sessionStorage?.access_token ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStorage?.access_token])

  useEffect(() => {
    const stepper = Number(idStepper) || ''
    if (stepper && !isNaN(stepper) && stepper <= formSteps && location?.state?.isRestartingProccess) {
      setActiveStep(Number(idStepper) - 1);
    }
  }, []);

  return membershipTypeModule[membershipType] ? (
    <FormWrapper maxWidth={styledCustomized}>
      <BasicModal
        styleRoot={{
          maxWidth: 728,
          boxShadow: "none",
          gap: "2px",
          marginTop: "62px",
        }}
        styleContent={{
          margin: 0,
          padding: 0,
        }}
        disableBackdropClick={true}
        closeButton
        open={open}
        setOpenModal={setOpenModal}
        onClick={onClick}
        bodyDialog={
          <CardAction
            onClick={onClick}
            onClickSecondary={onClickSecondary}
            buttonText={isLogged ? "Finalizar sesion" : "Continuar con el registro"}
            buttonTextSecondary="Nuevo registro"
            cardContent={
              isLogged ?
                "Para continuar con un nuevo registro debes de finalizar tu sesion"
                :
                "Contamos con datos ingresados previamente ¿Desea continuar o crear un nuevo registro? Al crear un nuevo registros los datos se perderan"
            }
            imgSrc={ManiPosesBody}
            secundaryButton={!isLogged}
            style={{
              backgroundColor: "#039ECC",
              border: "1px solid #039ECC",
            }}
            styleTextContent={{
              fontSize: "17px",
            }}
            styleImg={{
              backgroundColor: "#C0E6F2",
              padding: 0,
              display: "flex",
              flexWrap: "wrap",
              alignContent: "flex-end",
            }}
            styleImgCard={{
              padding: 0,
            }}
            styleButton={{
              color: "#039ECC",
            }}
            styleButtonSecondary={{
              color: "#039ECC",
            }}
          />
        }
      />
      {membershipType === "salud" ? (
        membershipTypeModule.salud.steps[activeStep] && (
          <CustomButton
            text={"Regresar"}
            onClick={() => (activeStep === 0 ? allianceSettingResponse?.ok ? navigate('/finsus') : navigate('/') : handleBack())}
            size={"medium"}
            startIcon={<ArrowBack />}
            variant={"contained"}
            style={{
              color: myColorPalette.getPrimaryColor(),
              backgroundColor: "transparent",
              fontSize: "1rem",
              textTransform: "none",
              fontWeight: "600",
              fontStyle: "normal",
              padding: "8px 0px",
              minWidth: "64px",
              border: "none",
              justifyContent: "flex-start",
              width: "fit-content",
            }}
          />
        )
      ) : (
        <CustomButton
          text={"Regresar"}
          onClick={() => {
            if (activeStep === 0) {
              window.dataLayer.push({
                event: 'TRUS_registro_regresar',
                seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
                tipo_seguro: storage.type == "free" ? "De regalo" : "Premium",
                boton_contacto: "Regresar",
                paso: `Paso ${activeStep + 1}`,
                alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
              })
            }
            activeStep === 0 ? navigate("/") : handleBack()
          }
          }
          size={"medium"}
          startIcon={<ArrowBack />}
          variant={"contained"}
          style={{
            color: myColorPalette.getPrimaryColor(),
            backgroundColor: "transparent",
            fontSize: "1rem",
            textTransform: "none",
            fontWeight: "600",
            fontStyle: "normal",
            padding: "8px 0px",
            minWidth: "64px",
            border: "none",
            justifyContent: "flex-start",
            width: "fit-content",
          }}
        />
      )}
      <FormContainer
        buttonNext={buttonNextHidded}
        steps={membershipTypeModule[membershipType]?.steps}
        initialValues={membershipTypeModule[membershipType]?.initialValues}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        title={membershipTypeModule[membershipType]?.titles}
        renderStepContent={renderStepContent}
        _handleSubmit={activeStep !== formSteps && _handleSubmit}
        validatorSchemaStepper={validatorSchemaStepper}
        formObserverKey="FormObserver"
        showTextRequire={membershipTypeModule[membershipType]?.stepsTextRequired.includes(activeStep + 1)}
        formObserverSlice={apiSuccess}
        localData={response}
        formikRef={getFormikReference}
      />
    </FormWrapper>
  ) : null;
};

export default PremiumHealthForm;
