import React from "react";

interface VectorBackgroundIconProps {
  width?: number;
  height?: number;
  fill?: string;
}
const VectorBackgroundIcon: React.FC<VectorBackgroundIconProps> = ({
  height = 45,
  width = 63,
  fill = "#039ECC"
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill='none'
      viewBox="0 0 63 45"
    >
      <path
        fill={fill}
        d="M9.387 3.156C5.246 4.743 1.094 7.59.164 12.687c-.422 3.041-.024 6.16 1.14 8.925 2.771 7.314 8.693 12.832 14.756 16.46 13.525 8.166 35.513 10.768 44.746-2.333C70.04 22.64 47.721 8.033 38.418 4.241 28.644.286 18.3-.274 9.408 3.075"
      ></path>
    </svg>
  );
}

export default VectorBackgroundIcon;