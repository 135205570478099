import { MembershipPlanItem } from "../interfaces/membership";
import { IUserPlans } from "../interfaces/user.interfaces"

export enum PlanItem {
    celular = "P01",
    salud = "P02"
}

export const FremiumCodes = ["C01", "S01", "S02", "S03",]
export const PremiumCodes = ["C02", "S04"]

export function ConvertPlanToMembershipItem(plans: IUserPlans[]): MembershipPlanItem[] {
    const memberships: MembershipPlanItem[] = [];
    plans.forEach(plan => {
        const membership: MembershipPlanItem = {
            category: plan.plan === PlanItem.celular ? 'celular' : 'salud',
            type: FremiumCodes.includes(plan.benefit_id) ? 'free' : 'premium',
            subtype: FremiumCodes.includes(plan.benefit_id) ? 'freemium' : 'premium',
            certificate: plan.certificate,
            benefitId: plan.benefit_id,
            createdAt: plan.created_at,
            plan: plan.plan
        }
        memberships.push(membership);
    });
    return memberships;
}