import React, { useState, useEffect, useRef } from 'react';
import { CustomTypography } from "../../components/atoms/Label/Label";
import { CustomButton } from '../../components/atoms/Buttons/Buttons';
import AddAdrres from './AddAddres'
import AdditionAddres from './AdittionAddresForm';
import BasicModal from '../../../src/components/molecules/BasicModal/BasicModal';
import miImagen from './many-removed.png';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CardAction from "../../components/organisms/Card";
import { fieldIsRequired } from '../../utils/scrollToTop';
import { apiSuccess } from '../../api/userSlice';
import FormContainer from '../../components/organisms/Form/FormContainer';
import * as Yup from 'yup';
import { InterInstance, addAccessToken } from '../../api/config';
import RenderAlert from '../../components/organisms/Modal';
import LoadingComponent from '../../components/organisms/loading/loading';
import { Button } from '@mui/material';

const TabOneComponent = () => {
    const navigate = useNavigate();
    const formikRef: any = useRef();
    const dispatch = useDispatch();
    const userResponse = useSelector((state: any) => state?.user?.response) as any | null;
    const [userInfo, setUserInfo] = useState<any>({});
    const [addressInfo, setAddressInfo] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpenModal] = useState<boolean>(false);
    const [AdditionAddresState, setAdditionAddres] = useState<boolean>(false);

    useEffect(() => {
        const { data } = userResponse
        if(data){
            const { user } = data
            if(user){
              setUserInfo(user)
            }
            const { address } = data
            if(address)
              setAddressInfo(address[0])
        }
    },[userResponse])

    const membershipTypeModule = {
        formSteps: 0, //starting from 0
        steps: [], // steps title
        titles: [""],
        initialValues: {
            zipcode: '',
            state: '',
            municipality: '',
            city: '',
            colony: '',
            street: '',
            number: '',
            reference: ''
        }
    }

    const validationSchemaStep2 = Yup.object().shape({
        zipcode: Yup.string().required(fieldIsRequired),
        state: Yup.string().required(fieldIsRequired),
        municipality: Yup.string().required(fieldIsRequired),
        city: Yup.string().required(fieldIsRequired),
        colony: Yup.string().required(fieldIsRequired),
        street: Yup.string().required(fieldIsRequired),
        number: Yup.string().required(fieldIsRequired),
        reference: Yup.string(),
    })
    
    const validatorSchemaStepper = () => {
        return validationSchemaStep2;
    }

    const handleClickOpen = () => {
        setOpenModal(true);
    };

    const getNavigate = () => {
        navigate("/survey-form")
    }

    const renderStepContent = (step: number, errors: any, handleChange: any, handleBlur: any) => {
        switch (step) {
            case 0:
                return (
                    isLoading
                    ? <LoadingComponent isLoaded={!isLoading} /> 
                    : <AdditionAddres 
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                    />
                )

            default:
                return null;
        }
    };

    const getValuesByPropertyValue = (object: any, propertyCopied: any) => {
        let newObject: { [key: string]: any } = {};
    
        for (const property of propertyCopied) {
          if (object.hasOwnProperty(property)) {
            newObject[property] = typeof object[property] === "string" ? object[property].trim() : object[property];
          }
        }
    
        return newObject;
    };

    const _handleSubmit = async (values: any, actions: any) => {
        const steppers = [
          ["zipcode", "state", "municipality", "city", 
          "colony", "street", "number", "reference"]
        ];
    
        let content: any = {};
        if (steppers[0].length > 0) {
          content = getValuesByPropertyValue(values, steppers[0]);
        }
        let updateRedux = JSON.parse(JSON.stringify(userResponse))
        updateRedux.data.address = [content]
        try {
            setIsLoading(true)
            await addAccessToken()
            const { data } = await InterInstance.post(`/users/auth`,
                {
                    event: "save-addess",
                    content
                }
            )
            if (data?.data?.error) {
                return RenderAlert({ severity: "warning", message: "Ocurrio un error inesperado" })
            }else{
                dispatch(apiSuccess(updateRedux))
                setAdditionAddres(false)
                return RenderAlert({ severity: "success", message: "Tu domicilio ha sido actualizado" })
            }
        } catch (error) {
            RenderAlert({ severity: "warning", message: "Ocurrio un error inesperado" })
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }
    
    const getFormikReference = (formik: any) => {
        formikRef.current = formik;
    };

    return <>
        <div
            className='main-container-data'
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                gap: '24px',
            }}
        >
            <div>
                <CustomTypography
                    style={{
                        color: "var(--Black, #212121)",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                        textAlign: "center",
                        height: "24px"
                    }}
                >
                    <span>
                        Nombre del Asegurado
                    </span>
                </CustomTypography>
                <CustomTypography
                    style={{
                        color: "var(--Black, #212121)",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                        textAlign: "center",
                    }}
                >
                    <span>
                        <b>{`${userInfo?.name} ${userInfo?.middle_name} ${userInfo?.last_name}`}</b>
                    </span>
                </CustomTypography>
            </div>

            <div>
                <CustomTypography
                    style={{
                        color: "var(--Black, #212121)",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                        textAlign: "center",
                        height: "24px"
                    }}
                >
                    <span>
                        Fecha de nacimiento
                    </span>
                </CustomTypography>

                <CustomTypography
                    style={{
                        color: "var(--Black, #212121)",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                        textAlign: "center",
                    }}
                >
                    <span>
                        <b>  {userInfo?.birthdate}</b>
                    </span>
                </CustomTypography>
            </div>

            <div style={{width: "100%"}} >
                <div
                    className='address-general-date'
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <CustomTypography
                        style={{
                            color: "var(--Black, #212121)",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            textAlign: "center",
                            minHeight: "24px"
                        }}
                    >
                        <span>
                            Domicilio
                        </span>
                    </CustomTypography>

                    {(!AdditionAddresState || !addressInfo) && <Button 
                        variant='text' 
                        startIcon={<AddAdrres />}
                        onClick={() => setAdditionAddres(true)}
                    >Agregar</Button>}
                </div>

                {
                    AdditionAddresState 
                    ? <>
                        <FormContainer
                            buttonNext={true}
                            steps={membershipTypeModule.steps}
                            initialValues={membershipTypeModule.initialValues}
                            activeStep={0}
                            setActiveStep={0}
                            title={membershipTypeModule.titles}
                            renderStepContent={renderStepContent}
                            _handleSubmit={_handleSubmit}
                            validatorSchemaStepper={validatorSchemaStepper}
                            formObserverKey='FormObserverSignIn'
                            formObserverSlice={apiSuccess}
                            localData={userResponse}
                            formikRef={getFormikReference}
                        />
                    </>
                    : addressInfo?.street &&  <div>
                    <CustomTypography
                        style={{
                            color: "var(--Black, #212121)",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            textAlign: "center",
                        }}
                    >
                        <span><b>{`${addressInfo?.street} ${addressInfo?.number}`}</b></span>
                    </CustomTypography>
                    
                    <CustomTypography
                        style={{
                            color: "var(--Black, #212121)",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            textAlign: "center",
                        }}
                    >
                        <span><b>{addressInfo?.colony}</b></span>
                    </CustomTypography>

                    <CustomTypography
                        style={{
                            color: "var(--Black, #212121)",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            textAlign: "center",
                        }}
                    >
                        <span><b>{addressInfo?.municipality}</b></span>
                    </CustomTypography>

                    <CustomTypography
                        style={{
                            color: "var(--Black, #212121)",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            textAlign: "center",
                        }}
                    >
                        <span><b>{addressInfo?.state}</b></span>
                    </CustomTypography>

                    <CustomTypography
                        style={{
                            color: "var(--Black, #212121)",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            textAlign: "center",
                        }}
                    >
                        <span><b>{addressInfo?.zipcode}</b></span>
                    </CustomTypography>

                    <CustomTypography
                        style={{
                            color: "var(--Black, #212121)",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            textAlign: "center",
                        }}
                    >
                        <span><b>{addressInfo?.reference}</b></span>
                    </CustomTypography>
                    </div>
                }
            </div>

            <div>
                <CustomTypography
                    style={{
                        color: "var(--Black, #212121)",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                        display: "flex",
                        textAlign: "center",
                        height: "24px"
                    }}
                >
                    <span>
                        Datos de contacto
                    </span>
                </CustomTypography>

                <CustomTypography
                    style={{
                        color: "var(--Black, #212121)",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                        display: "flex",
                        textAlign: "center",
                    }}
                >
                    <span><b>{userInfo?.phone}</b></span>
                </CustomTypography>

                <CustomTypography
                    style={{
                        color: "var(--Black, #212121)",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                        display: "flex",
                        textAlign: "center",
                    }}
                >
                    <span><b>{userInfo?.email}</b></span>
                </CustomTypography>
            </div>

            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <CustomButton
                    text={"cancelar cuenta"}
                    variant={"contained"}
                    onClick={handleClickOpen}
                    style={{
                        width: '100%',
                        height: "48px",
                        borderRadius: '8px',
                        border: ' 1px solid var(--Azul-Inter, #039ECC)',
                        background: 'var(--White, #FFF)',
                        color: 'var(--Azul-Inter, #039ECC)',
                        textAlign: 'center',
                        fontFamily: 'Montserrat',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: 'normal',
                        textTransform: 'uppercase',
                    }}
                />
            </div>
            
        </div>
        <BasicModal
            styleRoot={{
                boxShadow: "none",
                marginTop: '62px'
            }}
            styleContent={{
                margin: 0,
                padding: 0,
            }}
            disableBackdropClick={true}
            closeButton
            open={open}
            setOpenModal={setOpenModal}
            bodyDialog={
                <CardAction
                    onClick={
                        getNavigate
                        // navigate('/registrar-imei');
                    }
                    buttonText='SI, Quiero Cancelar'
                    secundaryButton={true}
                    buttonTextSecondary='NO Quiero Cancelar'
                    onClickSecondary={() => {
                        setOpenModal(false)
                    }}
                    styleButtonSecondary={{
                        backgroundColor: '#039ECC',
                        borderRadius: '0 0 8px 8px',
                        justifyContent: 'space-around',
                        border: 'none',
                        color: '#FFFFFF',
                    }}
                    imgSrc={miImagen}
                    style={{
                        backgroundColor: '#039ECC',
                        borderRadius: '0 0 8px 8px',
                        justifyContent: 'space-around',
                        border: 'none',
                        color: '#000',
                        //flexDirection: 'column'
                    }}
                    css-1hokx2j-MuiPaper-root-MuiCard-root={{
                        flexDirection: 'column!important',
                    }}
                    styleTextContent={{
                        color: '#FFF'
                    }}
                    styleButton={{
                        color: '#039ECC',
                        borderRadius: '8px',
                        background: '#FFFFFF',
                        border: 'none'
                    }}
                    styleImg={{
                        backgroundColor: "#C0E6F2",
                        padding: 0,
                        display: "flex",
                        flexWrap: "wrap",
                        alignContent: "flex-end",
                    }}
                    setOpenModal={setOpenModal}
                    cardContent={<React.Fragment>
                        <div
                            className='modal-container'
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <CustomTypography

                                style={{
                                    color: 'var(--White, #FFF)',
                                    textAlign: 'center',

                                    /* Body 3 */
                                    fontFamily: 'Montserrat',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                }}
                            >
                                {'No nos gustaría que dejes de estar protegido con nosotros, pero entendemos que a veces las cosas no funcionan'}
                            </CustomTypography>
                        </div>
                    </React.Fragment>}
                    cardType={"free"}
                    subType={"freemium"}
                    cardCategory={"celular"}
                    cardDescription={""}
                    cardTitle={""}
            />}
        />
    </>
};

export default TabOneComponent;