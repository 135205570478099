import * as React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

type ProgressBarProps = {
    value?: number,
    labelActive?: boolean,
    color?: string,
    size?: 'small' | 'medium' | 'large';
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number, labelActive: boolean, customColor: string, customSize: string }) {
  
  const linearHeight: any = {
    'small': '4px', 
    'medium': '6px', 
    'large': '8px'
  };
  
  const size: string = props.customSize;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress 
          variant="determinate" 
          {...props} 
          color='inherit' 
          sx={{
            backgroundColor: '#F4F4F4', 
            color: props.customColor, 
            height: linearHeight[size],
            '&.MuiLinearProgress-root':{
              borderRadius: 8
            },
            '&.MuiLinearProgress-root::before':{
              backgroundColor: 'transparent',
            },
            '&.MuiLinearProgress-root > .MuiLinearProgress-bar': {
              borderRadius: 8
            }
          }} 
        />
      </Box>
      {props.labelActive && <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>}
    </Box>
  );
}

export default function ProgressBar({value = 0, labelActive = true, color = '#F4F4F4', size = 'small'}: ProgressBarProps) {
    
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={value > 100 ? 100 : value} labelActive={labelActive} customColor={color} customSize={size} />
    </Box>
  );
}
