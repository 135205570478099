import React, { useEffect, useState } from "react";
import FormContainer from "../../Form/FormContainer";
import { apiSuccess, apiError, apiLoading } from "../../../../api/resetPasswordSlice";
import { useSelector } from "react-redux";
import { styled } from "styled-components";
import ResetPasswordContent from "../../Form/FormContent/ResetPasswordContent";
import * as Yup from "yup";
import { fieldIsRequired } from '../../../../utils/scrollToTop';
import { useLocation, useNavigate } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`;
interface ResetPasswordForm {
    redirectTo?: string;
    trackingId?: string
}
const ResetPasswordForm: React.FC<ResetPasswordForm> = ({ redirectTo, trackingId }) => {
    const navigate = useNavigate();
    const { fetchData } = useFetch();


    const [activeStep, setActiveStep] = useState<number>(0);
    const response = useSelector((state: any) => state.resetPassword.response) as any | null;
    const error = useSelector((state: any) => state.resetPassword.error) as any | null;

    const membershipTypeModule = {
        resetPassword: {
            formSteps: 0, //starting from 0
            steps: [], // steps title
            titles: [], // content title
            initialValues: {
                password: '',
                passwordConfirm: ''
            }
        }
    }

    const renderStepContent = (step: number, errors: any, handleChange: any, handleBlur: any) => {
        switch (step) {
            case 0:
                return (<ResetPasswordContent handleChange={handleChange} handleBlur={handleBlur} errors={errors} />);
            default:
                return null;
        }
    };

    const resetPasswordAction = (pass: string) => {
        return fetchData('users/auth', 'POST', {
            "event": "update-password",
            "content": {
                "token": trackingId,
                "newPassword": pass

            }
        }, {}, apiLoading, apiSuccess, apiError);
    }

    useEffect(() => {
        if (response?.code === 200) {
            navigate('/password-reset-response', {
                state: {
                    status: false,
                }
            });
        }
    }, [response?.code]);

    useEffect(() => {
        if (error?.response?.data?.code === 400) {
            navigate('/password-reset-response', {
                state: {
                    status: true,
                }
            });
        }
    }, [error]);


    const _handleSubmit = async (values: any, actions: any) => {
        resetPasswordAction(values.passwordConfirm)
    }

    const validationSchemaStep = Yup.object().shape({
        password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).{10,}$/, "No cumple con las especificaciones para una contraseña segura").required(fieldIsRequired),
        passwordConfirm: Yup.string().oneOf([Yup.ref('password'),], 'Las contraseñas deben coincidir').required('Confirmar contraseña requerida'),
    })
    const validatorSchemaStepper = () => {
        return validationSchemaStep;
    }

    return (<FormWrapper>
        <FormContainer
            initialValueImportant
            showTextRequire={false}
            buttonText={"Cambiar contraseña"}
            steps={membershipTypeModule.resetPassword.steps}
            initialValues={membershipTypeModule.resetPassword.initialValues}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            title={membershipTypeModule.resetPassword.titles}
            renderStepContent={renderStepContent}
            _handleSubmit={_handleSubmit}
            validatorSchemaStepper={validatorSchemaStepper}
            formObserverKey='FormObserverResetPassword'
            formObserverSlice={apiSuccess}
            localData={response}
        />
    </FormWrapper>)
}
export default ResetPasswordForm
