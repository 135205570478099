interface StorageItem<T> {
    value: T;
    timestamp: number;
}

export default class LocalStorageManager{
    constructor(private prefix?:string){}

    private getKey(key:string):string{
        return this.prefix ? `${this.prefix}_${key}` : `${key}`;
    }

    listKeys(){
        let keys:string[] = [];
        for(let i = 0; i< localStorage.length ; i++){
            let keyValue = localStorage.key(i);
            if(keyValue){
                keys.push(keyValue);
            }
        }
        return keys
    }

    setItem<T>(key:string,value:any):void{
        let itemStr:string;
        if(this.prefix){
            const item:StorageItem<T> = {
                value,
                timestamp:Date.now()
            }
            itemStr = JSON.stringify(item);
        }else{
            itemStr = JSON.stringify(value);
        }
        localStorage.setItem(this.getKey(key),itemStr);        
    }

    getItem<T>(key:string):T|null{
        const itemStr = localStorage.getItem(this.getKey(key));
        if(itemStr){
            if(this.prefix){
                const item:StorageItem<T> = JSON.parse(itemStr);
                return item.value;
            }else{
                const item:T = JSON.parse(itemStr);
                return item;
            }
        }
        return null;
    }

    removeItem(key:string):void{
        localStorage.removeItem(this.getKey(key));
    }
}

