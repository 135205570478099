import React from 'react';
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import QuestionsPlus from '../../../assets/Questions/QuestionsPlus';
import { scrollToTop } from '../../../utils/scrollToTop';

type AccordeonCustomizedProps = {
    data?: Array<any>;
    isAlliance?:boolean;
}

const AccordeonCustomized: React.FC<AccordeonCustomizedProps> = ({data,isAlliance=false}) => {
    const [expanded, setExpanded] = React.useState<any>([]);
    const handleChange = (panel: any) => (event: any, isExpanded: any) => {
        if (isExpanded) {
            setExpanded([...expanded, panel]); // Agrega el panel abierto al arreglo
        } else {
            const reduce = expanded.filter(((item:any) => item !== panel));
            setExpanded(reduce); // Elimina el panel cerrado del arreglo
        }
    }

    React.useEffect(() => {
        if(data && data?.length > 0){
            setExpanded([]);
            scrollToTop();
        }
    }, [data]);

    return (
        <div style={{width:'100%'}}>
            {data && data?.length > 0 && data.map((items, index) => {
                return(
                <Accordion
                    sx={{
                        padding: !expanded.includes(index) ? '20px 0':'0px',
                        margin: 0
                    }}
                    style={{
                        boxShadow: 'none',
                    }}
                    key={index} expanded={expanded.includes(index)} onChange={handleChange(index)} >
                    <AccordionSummary expandIcon={expanded.includes(index) ? < RemoveIcon /> : < QuestionsPlus />}
                    >
                        <Typography
                            color={'var(--black, #212121)'}
                            fontSize={'16px'}
                            fontWeight={700}
                        sx={{
                            textWrap: 'balance',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            marginRight: '12px'
                        }}
                        >{isAlliance ? items.question:items.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography
                        color={'var(--black, #212121)'}
                        fontSize={'16px'}
                        fontWeight={400}
                        >{isAlliance ? items.answer:items.description}</Typography>
                    </AccordionDetails>
                </Accordion>);
            })}
        </div>
    )
};

export default AccordeonCustomized;
