import React from 'react';
import { Box } from '@mui/material';
import CustomContainer from '../Container/Container';
import ContentComponent from './ContentComponent';

const SimpleSteps: React.FC = () => {

    return (
        <Box sx={{ backgroundColor: '#F4F4F4' }}>
            <CustomContainer childComponent={<ContentComponent />} />
        </Box>
    )
};

export default SimpleSteps;
