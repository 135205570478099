import React from 'react';
import { MembershipType } from '../../MembershipMainValues';
import styled from 'styled-components';
import { createTheme, useTheme, useMediaQuery } from '@mui/material';
import { themeValues } from '../../../../../theme/theme';
import mygif from '../../../../../assets/MemeberShipCarrusel/start1-2.gif'
import HealtIconWithText from '../../../../../assets/HealtIconWithText';
import PhoneIconWithText from '../../../../../assets/PhoneIconWithText';
import { useSelector } from 'react-redux';
import { myColorPalette } from '../../../../../theme/themeGenerated';

const theme = createTheme(themeValues);
type SelectProps = {
    color?: string;
}
const colorPallete = {
    free: theme.palette.primary.main,
    freeSecondary: '#C0E6F2',
    premium: '#E6B331',
    premiumSecondary: '#FAD882'
}

const Selectssection = styled.div`
    display: flex;
    position:relative;
    width: 100%;
`

const ButtonBackground = styled.div<SelectProps>`
    padding: 0px;
    margin:10px;
    border-radius: 8px;
    width: 100%;
    background-color: ${(props) => props.color};
    display: flex;
    justify-content: center;
    max-width:600px;
    margin:auto;
`

const SelectElement = styled.div`
    border-radius: 8px;
    width: 100%;
    font-size: 15px;
    border:none;
    color:gray;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
`



interface MembershipButtonProps {
    type: MembershipType;
    onClickSubType: Function;
    newStyle?: React.CSSProperties;
    tabHidden?: boolean;
}

const MembershipButton: React.FC<MembershipButtonProps> = ({ type, onClickSubType, newStyle, tabHidden }) => {
    const { breakpoints } = useTheme();
    const currentValue = useSelector((state: any) => state.productsCards.currentValue);
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const isTablet = useMediaQuery(breakpoints.down('md'));
    const ElementActive: React.CSSProperties = Object.assign({},
        {
            color: 'white',
            backgroundColor: colorPallete.free,
        },
        newStyle // Reemplaza tuNuevoEstilo con newStyle
    );

    const ElementActivePremium: React.CSSProperties =
    {
        color: 'white',
        backgroundColor: colorPallete.premium,
        zIndex: 1000
    }
    const leftSpace: number = isMobile ? 211 : isTablet ? 220 : 200;

    return <Selectssection>
        <ButtonBackground color={window.location.pathname === "/finsus" ? myColorPalette.getFinsusRgb() : '#F4F4F4'}>
            <SelectElement style={(type === 'free' ? ElementActive : { display: tabHidden ? 'none' : 'flex' })} onClick={() => { onClickSubType('free') }}>
                DE REGALO
            </SelectElement>
            {!tabHidden ? <React.Fragment>
                {type === "premium" && <img
                    style={{
                        height: '108px',
                        width: '104px',
                        zIndex: '500',
                        position: 'absolute',
                        left: leftSpace,
                        top: -30,
                    }} src={mygif} alt="loading..." />}
                <SelectElement style={(type === 'premium' ? ElementActivePremium : {})} onClick={() => { !tabHidden && onClickSubType('premium') }}>
                    PREMIUM
                </SelectElement>
            </React.Fragment>
                :
                <React.Fragment>
                    {
                        currentValue.category === 'salud' ?
                            <HealtIconWithText />
                            :
                            <PhoneIconWithText />
                    }
                </React.Fragment>
            }
        </ButtonBackground>
    </Selectssection>
}

export default MembershipButton;
