
import { IConfig, ConfigEnv, ModesTypes, MODES } from './types';
export const MODE: ModesTypes = MODES.qa;

//Configuration by envirotments
const MAIN_CONFIG: ConfigEnv = {
    develop: {
        base: process.env.REACT_APP_BASE_DEVELOP || '', //For redirection
        backend: process.env.REACT_APP_BACKEND_DEVELOP || '', //For API BACKEND
        auth0: {
            domain: process.env.REACT_APP_AUTH0_DOMAIN_DEVELOP || '',
            clientId: process.env.REACT_APP_AUTH0_CLIENTID_DEVELOP || '',
        },
        stripe: {
            publicKey: process.env.REACT_APP_STRIPE_KEY_DEVELOP || ''
        }
    },
    qa: {
        base: process.env.REACT_APP_BASE_QA || '', //For redirection
        backend: process.env.REACT_APP_BACKEND_QA || '', //For API BACKEND
        auth0: {
            domain: process.env.REACT_APP_AUTH0_DOMAIN_QA || '',
            clientId: process.env.REACT_APP_AUTH0_CLIENTID_QA || '',
        },
        stripe: {
            publicKey: process.env.REACT_APP_STRIPE_KEY_QA || ''
        }
    },
    stage: {
        base: process.env.REACT_APP_BASE_STAGE || '', //For redirection
        backend: process.env.REACT_APP_BACKEND_STAGE || '', //For API BACKEND
        auth0: {
            domain: process.env.REACT_APP_AUTH0_DOMAIN_STAGE || '',
            clientId: process.env.REACT_APP_AUTH0_CLIENTID_STAGE || '',
        },
        stripe: {
            publicKey: process.env.REACT_APP_STRIPE_KEY_STAGE || ''
        }
    },
    production: {
        base: process.env.REACT_APP_BASE_PRODUCTION || '', //For redirection
        backend: process.env.REACT_APP_BACKEND_PRODUCTION || '', //For API BACKEND
        auth0: {
            domain: process.env.REACT_APP_AUTH0_DOMAIN_PRODUCTION || '',
            clientId: process.env.REACT_APP_AUTH0_CLIENTID_PRODUCTION || '',
        },
        stripe: {
            publicKey: process.env.REACT_APP_STRIPE_KEY_PRODUCTION || ''
        }
    }

}

const BASE_CONFIG: IConfig = MAIN_CONFIG[MODE];
// console.info("CONFIGURATION=> " + MODE, BASE_CONFIG);
export default BASE_CONFIG;




