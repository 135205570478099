import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { styled } from 'styled-components';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { apiSuccess } from '../../../api/beneficiarySlice';

import FormContainer from '../../organisms/Form/FormContainer';
import { CustomButton } from '../../atoms/Buttons/Buttons';
import { ArrowBack } from '@mui/icons-material';
import DatosDeBeneficiario from '../../organisms/Form/FormContent/DatosDeBeneficiario';
import { CustomTypography } from '../../atoms/Label/Label';
import { useMediaQuery } from '@material-ui/core';
import { fieldIsRequired } from '../../../utils/scrollToTop';
import useFetch from '../../../hooks/useFetch';
import {
    apiError as apiBeneficiaryError,
    apiLoading as apiBeneficiaryLoading,
    apiSuccess as apiBeneficiarySuccess
} from '../../../api/beneficiarySlice';
import RenderAlert from '../../organisms/Modal';
import LoadingComponent from '../../organisms/loading/loading';
import { IStorageMembershipData } from '../../../utils/localStorageKeys';
import { validateEmail } from '../../../utils/utils';

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 80px;
  gap: 24px;
  align-items: flex-start;
  max-width: 558px;
`;

const Beneficiary: React.FC = () => {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState<number>(0);
    const response = useSelector((state: any) => state.beneficiary.response) as any | null;
    const loading = useSelector((state: any) => state.beneficiary.loading) as any | null;
    const error = useSelector((state: any) => state.beneficiary.error) as any | null;

    const { fetchData } = useFetch();

    const matches = useMediaQuery('(min-width:600px)');

    const membershipTypeModule = {
        datosdeBeneficiarios: {
            formSteps: 0, //starting from 0
            steps: [], // steps title
            titles: [], // content title 
            initialValues: {
                name: '',
                middle_name: '',
                last_name: '',
                email: '',
                phone: '',
                birthdate: '',
                relationship: ''
            }
        }
    }

    const renderStepContent = (step: number, errors: any, handleChange: any, handleBlur: any) => {
        switch (step) {
            case 0:
                return (<DatosDeBeneficiario handleChange={handleChange} handleBlur={handleBlur} errors={errors} />);
            default:
                return null;
        }
    };

    // const _sleep = (ms: number) => {
    //     return new Promise(resolve => setTimeout(resolve, ms));
    // }

    const _submitForm: any = async (values: any, actions: any) => {
        // await _sleep(1000);
        actions.setSubmitting(true);
        const content = {
            "name": values.name.trim(),
            "middle_name": values.middle_name.trim(),
            "last_name": values.last_name.trim(),
            "birthdate": values.birthdate,
            "relationship": values.relationship,
            "email": values.email.trim(),
            "phone": Number(values.phone),
            "porcentage": 100
        }

        fetchData('users/auth', 'POST', {
            event: 'save-benefi',
            content: content
        }, {}, apiBeneficiaryLoading, apiBeneficiarySuccess, apiBeneficiaryError);
        sessionStorage.setItem('exitoPremium', '1');
        // navigate('/')
        window.dataLayer.push({
            event: "TRUS_registrar_beneficiario",
            fecha_nacimiento: values.birthdate.split("-").reverse().join("/"),
            parentesco: values.relationship,
            correo: values.email,
            telefono: values.phone,
            boton_contacto: "Guardar"
        });
    }

    useEffect(() => {
        if (response?.ok) {
            RenderAlert({ severity: "info", message: response?.data });
            navigate(-1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);


    useEffect(() => {
        if (error) {
            console.error(error)
        }
    }, [error]);

    const _handleSubmit = async (values: any, actions: any) => {
        _submitForm(values, actions);
    }

    const validationSchemaStep = Yup.object().shape({
        name: Yup.string().required(fieldIsRequired),
        middle_name: Yup.string().required(fieldIsRequired),
        last_name: Yup.string().required(fieldIsRequired),
        birthdate: Yup.date()
            .required(fieldIsRequired),
        phone: Yup.string()
            .phone("MX", "Por favor, ingresa un número de teléfono válido en México")
            .max(10, "maximo 10 caracteres")
            .required(fieldIsRequired),
        email: Yup.string()
            .required(fieldIsRequired)
            .test('email', 'Este correo no es valido',
                function(value) {
                    return validateEmail(value);
            }),
        relationship: Yup.string().required(fieldIsRequired)
    })

    const validatorSchemaStepper = () => {
        return validationSchemaStep;
    }

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    let storage: IStorageMembershipData = {
        ...useSelector((state: any) => state.productsCards.currentValue),
    };

    const userData = JSON.parse(sessionStorage.getItem('user-storage')!);
    const userResponse = useSelector((state: any) => state.user.response);

    useEffect(() => {
        window.dataLayer.push({
            'event': 'TRUS_registrar_beneficiario_v',
            tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium",//premium | gratuito  
            'correo': userResponse?.data?.user?.email,
            'telefono': userResponse?.data?.user?.phone,
            'page_location': window.location.href
        });

    }, [])

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
            <FormWrapper>
                <CustomButton
                    text={"Regresar"}
                    onClick={() => (activeStep === 0 ? navigate(-1) : handleBack())}
                    size={"medium"}
                    startIcon={<ArrowBack />}
                    variant={"contained"}
                    style={{
                        color: "#039ECC",
                        backgroundColor: "transparent",
                        fontSize: "1rem",
                        textTransform: "none",
                        fontWeight: "600",
                        fontStyle: "normal",
                        padding: "0px",
                        minWidth: "64px",
                        border: "none",
                        justifyContent: "flex-start",
                        width: "fit-content",
                    }}
                />

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 40
                }}>
                    <CustomTypography
                        item={"Tu seguro incluye una cobertura de muerte accidental, ¿Nos ayudas con los datos del beneficiario?"}
                        style={{
                            display: 'block',
                            fontSize: !matches ? 16 : 28,
                            textAlign: 'center',
                            fontWaight: 700,
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            textTransform: !matches ? 'inherit' : '',
                            alignSelf: 'stretch',
                            textWrap: 'balance'
                        }}
                    />
                    {loading ?
                        <LoadingComponent isLoaded={!loading} isError={false} ErrorMessage='Hubo un error al cargar las coberturas' ></LoadingComponent>
                        :
                        <FormContainer
                            buttonNext={true}
                            steps={membershipTypeModule.datosdeBeneficiarios.steps}
                            initialValues={membershipTypeModule.datosdeBeneficiarios.initialValues}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            title={membershipTypeModule.datosdeBeneficiarios.titles}
                            renderStepContent={renderStepContent}
                            _handleSubmit={_handleSubmit}
                            validatorSchemaStepper={validatorSchemaStepper}
                            formObserverKey='FormObserverDatosCelularEdit'
                            formObserverSlice={apiSuccess}
                            localData={response}
                        />}
                </div>
            </FormWrapper>

        </div>

    );
};

export default Beneficiary;
