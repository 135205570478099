import React, { useEffect, useState } from "react";
import BasicModal from "../../../components/molecules/BasicModal/BasicModal";
import CardAction from "../../../components/organisms/Card";
import { useDispatch, useSelector } from 'react-redux';
import clockImg from '../../../assets/ClockImg/clockImg.svg';
import ExclamationImgCircle from '../../../assets/ExclamationImgCircle/ExclamationimgCircle.svg';
import maniAprovIt from '../../../assets/ManiFingerUp/Mani poses vol 3 v12 1.png';
import { MembershipColor, MembershipType } from "../../../components/organisms/Membership/MembershipMainValues";
import { setCurrentValue } from "../../../api/productsSlice";
import { useNavigate } from "react-router-dom";
import { generatedVigenceDate } from "../../../utils/generateVigenceDate";
import { IStorageMembershipData } from "../../../utils/localStorageKeys";
import useFetch from "../../../hooks/useFetch";
import {
  apiSuccess as apiAssignmentModalOffSuccess,
  apiError as apiAssignmentModalOffError,
  apiLoading as apiAssignmentModalOffLoading
} from '../../../api/assignmentModalOffSlice';
import {
  apiSuccess as apiAssignmentSuccess,
  apiError as apiAssignmentError,
  apiLoading as apiAssignmentLoading
} from '../../../api/assignmentSlice';
import {
  apiSuccess as apiAssignmentModalSuccess,
  apiError as apiAssignmentModalError,
  apiLoading as apiAssignmentModalLoading
} from '../../../api/assignmentModalSlice';

interface IModalWelcome {
  closeNewUserModal?: () => void;
  type?: MembershipType;
  planId?: string;
  openModal: boolean;
  badgeEvent: DataJSONToShow;
  setOpenCheckUpModal: any;
}

// type CurrentValueProps = {
//   type: string;
//   category: string;
// }

interface IDataJSONToShow {
  check_up_timer: {
    cardContent: React.ReactNode,
    imgSrc: string;
    buttonTextSecundary?: string;
    buttonText: string;
    styleImg?: object;
  }, 
  all_events_used: {
    cardContent: React.ReactNode,
    imgSrc: string;
    buttonTextSecundary?: string;
    buttonText: string;
    styleImg?: object;
  }, 
  event_used: {
    cardContent: React.ReactNode,
    imgSrc: string;
    buttonTextSecundary?: string;
    buttonText: string;
    styleImg: object;
  }, 
  event_used_free: {
    cardContent: React.ReactNode,
    imgSrc: string;
    buttonText: string;
    buttonTextSecundary?: string;
    styleImg: object;
  }, 
  event_lost_with_more_events: {
    cardContent: React.ReactNode,
    imgSrc: string;
    buttonTextSecundary?: string;
    buttonText: string;
    styleImg?: object;
  }, 
  event_lost_without_events_pendings: {
    cardContent: React.ReactNode,
    imgSrc: string;
    buttonTextSecundary?: string;
    buttonText: string;
    styleImg?: object;
  }
  event_lost_free: {
    cardContent: React.ReactNode,
    imgSrc: string;
    buttonTextSecundary?: string;
    buttonText: string;
    styleImg?: object;
  }
} 

type DataJSONToShow = 'check_up_timer'| 'all_events_used' | 'event_used' | 'event_used_free' | 'event_lost_with_more_events' | 'event_lost_without_events_pendings' | 'event_lost_free';

const ModalCheckUp = ({ closeNewUserModal, type, planId, openModal, badgeEvent, setOpenCheckUpModal }: IModalWelcome) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { fetchData } = useFetch();
  const [ModalContentType, setModalContentType] = useState<DataJSONToShow | undefined>();
  const assignment = useSelector((state: any) => state.assignment.response);
  const assignmentModal = useSelector((state: any) => state.assignmentModal.response);
  const assignmentModalLoading = useSelector((state: any) => state.assignmentModal.loading);
  const [isLoading, setLoading] = useState<string | null>(null);
  const userResponse = useSelector((state: any) => state?.user?.response);
  const storage: IStorageMembershipData = {
    ...useSelector((state: any) => state.productsCards.currentValue),
  };

  const statusModal:any = assignmentModal?.data?.status;

  const getAssignmentModalOff = (event: any, loading: any, success: any, error: any) => {
    return fetchData('/plansalud', 'POST', {
      "event": event,
      "content":{
          "id": assignmentModal?.data?.id
      }
    }, {}, loading, success, error);
  }

  useEffect(() => {
    setModalContentType(badgeEvent)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [badgeEvent]);

  useEffect(()=> {
    if(assignmentModalLoading){
      setLoading('isLoading')
    }else if(isLoading === "isLoading" && !assignmentModalLoading){
      setLoading(null);
      getAssignmentModalOff(
        "get-assistances", 
        apiAssignmentLoading, 
        apiAssignmentSuccess, 
        apiAssignmentError
      );
      getAssignmentModalOff(
        "get-assistancesModal", 
        apiAssignmentModalLoading, 
        apiAssignmentModalSuccess, 
        apiAssignmentModalError
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentModalLoading]);

  useEffect(() => {
    if (
      ModalContentType === "all_events_used" || 
      ModalContentType === "check_up_timer" || 
      ModalContentType === "event_lost_free" || 
      ModalContentType === "event_lost_with_more_events" || 
      ModalContentType === "event_lost_without_events_pendings" || 
      ModalContentType === "event_used" || 
      ModalContentType === "event_used_free"
    ){
      window.dataLayer.push({ 
        event: 
        ModalContentType === "check_up_timer"
        ? "TRUS_recordatorio_check_medico_v"
        : ModalContentType === "event_lost_free" || ModalContentType === "event_lost_with_more_events" || ModalContentType === "event_lost_without_events_pendings"
        ? "TRUS_check_medico_expirado_v"
        : ModalContentType === "event_used" || ModalContentType === "event_used_free" || ModalContentType === "all_events_used"
        ? "TRUS_check_medico_exitoso_v"
        : ""
        ,
        tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type === "free" ? "De regalo" : "Premium", 
        correo: userResponse?.data?.user?.email, 
        seguro: storage.category === "celular" ? "Celular Protect" : "Salud Protect",
        telefono: userResponse?.data?.user?.phone,
        page_location: window.location.href,
      });
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [ModalContentType])

  const dataJson: IDataJSONToShow = {
    check_up_timer:{
      cardContent: <span style={{
          fontSize: 20,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal'
        }}>¡Hola <b style={{ textTransform: 'capitalize' }}>{userResponse?.data?.user?.name} {userResponse?.data?.user?.middle_name} {userResponse?.data?.user?.last_name}</b>! <br />
          Tienes <b>7 días</b> para hacer válido el check-up. Recuerda que si no lo realizas a tiempo, podrías perder este beneficio.
          <br></br>
        </span>,
      imgSrc: clockImg,
      buttonText: "Entendido",
      styleImg: {alignContent: "center"}
    }, 
    event_used: {
      cardContent: <span style={{
          fontSize: 20,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal'
        }}>¡Hola <b style={{ textTransform: 'capitalize' }}>{userResponse?.data?.user?.name} {userResponse?.data?.user?.middle_name} {userResponse?.data?.user?.last_name}</b>! <br />
          Nos alegra que hayas utilizado tu check-up. Todavía tienes {'dos'} eventos disponibles para cuando lo necesites.
          <br></br>
        </span>,
      imgSrc: maniAprovIt,
      buttonText: "Entendido",
      styleImg: {}
    }, 
    event_used_free: {
      cardContent: <span style={{
          fontSize: 20,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal'
        }}>¡Hola <b style={{ textTransform: 'capitalize' }}>{userResponse?.data?.user?.name} {userResponse?.data?.user?.middle_name} {userResponse?.data?.user?.last_name}</b>! <br />
          Nos encanta que hayas usado tu cobertura de check-up ¿Te gustaría probar Salud Protect Premium para obtener más beneficios?
          <br></br>
        </span>,
      imgSrc: maniAprovIt,
      buttonText: "Quiero ser premium",
      buttonTextSecundary: 'entendido',
      styleImg: {}
    },
    all_events_used: {
    cardContent: <span style={{
          fontSize: 20,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal'
        }}>¡Hola <b style={{ textTransform: 'capitalize' }}>{userResponse?.data?.user?.name} {userResponse?.data?.user?.middle_name} {userResponse?.data?.user?.last_name}</b>! <br />
          Nos alegra que hayas utilizado todos tus check-ups disponibles. Por ahora, has alcanzado el límite de eventos, pero no te preocupes, el {generatedVigenceDate(assignment?.data?.created_at || assignmentModal?.data?.created_at)} volverán a estar disponibles para ti.
          <br></br>
        </span>,
      imgSrc: maniAprovIt,
      buttonText: "Entendido",
      styleImg: {}
    },  
    event_lost_with_more_events: {
      cardContent: <span style={{
          fontSize: 20,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal'
        }}>¡Hola <b style={{ textTransform: 'capitalize' }}>{userResponse?.data?.user?.name} {userResponse?.data?.user?.middle_name} {userResponse?.data?.user?.last_name}</b>! <br />
          Lamentablemente, el plazo para hacer válido el check-up ha expirado. Necesitarás generar una nueva solicitud para hacer uso de la cobertura.
          <br></br>
        </span>,
      imgSrc: ExclamationImgCircle,
      buttonText: "Entendido",
      styleImg: {alignContent: "center"}
    }, 
    event_lost_without_events_pendings: {
      cardContent: <span style={{
          fontSize: 20,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal'
        }}>¡Hola <b style={{ textTransform: 'capitalize' }}>{userResponse?.data?.user?.name} {userResponse?.data?.user?.middle_name} {userResponse?.data?.user?.last_name}</b>! <br />
          Lamentablemente, el plazo para hacer válido el check-up ha expirado. Por el momento, has alcanzado el límite, pero no te preocupes, el {generatedVigenceDate(assignment?.data?.created_at || assignmentModal?.data?.created_at)} volverán a estar disponibles para ti.
          <br></br>
        </span>,
      imgSrc: ExclamationImgCircle,
      buttonText: "Entendido",
      styleImg: {alignContent: "center"}
    },
    event_lost_free: {
      cardContent: <span style={{
          fontSize: 20,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal'
        }}>¡Hola <b style={{ textTransform: 'capitalize' }}>{userResponse?.data?.user?.name} {userResponse?.data?.user?.middle_name} {userResponse?.data?.user?.last_name}</b>! <br />
          Lamentablemente, el plazo para hacer válido el check-up ha expirado. Pero tenemos más opciones para ti. ¿Te gustaría probar Salud Protect Premium?
          <br></br>
        </span>,
      imgSrc: ExclamationImgCircle,
      buttonTextSecundary: 'entendido',
      buttonText: "Quiero ser premium",
      styleImg: {alignContent: "center"}
    }
  }

  const turnOffAssignmentProccess = () => {
    if(statusModal){
      getAssignmentModalOff(
        "get-assistancesModalOff", 
        apiAssignmentModalOffLoading, 
        apiAssignmentModalOffSuccess, 
        apiAssignmentModalOffError
      );
    }
  };

  return (  
    ModalContentType && dataJson[ModalContentType] ?
    <BasicModal
      styleRoot={{
        maxWidth: 728,
        boxShadow: "none",
      }}
      styleContent={{
        margin: 0,
        padding: 0,
      }}
      disableBackdropClick={true}
      closeButton
      onClick={() => {
        sessionStorage.setItem('modalCheckUp', "true");
        setOpenCheckUpModal(false);
        turnOffAssignmentProccess();
      }}
      open={userResponse?.data?.user?.name && openModal}
      setOpenModal={setOpenCheckUpModal}
      bodyDialog={<CardAction
        onClick={() => {
          sessionStorage.setItem('modalCheckUp', "true");
          if(ModalContentType === 'event_used_free' || ModalContentType === 'event_lost_free'){
            const sendDataLocalStorage = {
                type: "premium",
                subtype: 'premium',
                category: "salud",
                planId: "P02",
                cardTitle: "Seguro Inter Salud",
                benefitId: "S03"
            };

            dispatch(setCurrentValue(sendDataLocalStorage));
            navigate('/resumen-membresia', {
                state: {
                    path: 'portal-del-cliente',
                    tabHidden: "freemium"
                }
            })
          }else {
            if(ModalContentType === 'event_used'){
              localStorage.setItem("completado_con_eventos", "true")
            }
            if(ModalContentType === 'all_events_used'){
              localStorage.setItem("completado_sin_eventos", "true")
            }
            if(ModalContentType === 'event_lost_without_events_pendings' || ModalContentType === 'event_lost_with_more_events'){
              localStorage.setItem("evento_cancelado", "true")
            }
            setOpenCheckUpModal(false)
            turnOffAssignmentProccess();
          }
        }}
        onClickSecondary={() => {
          if(userResponse?.data?.events?.checkup === 0){
            localStorage.setItem("completado_sin_eventos", "true")
          }
          setOpenCheckUpModal(false)
        }}
        buttonText={dataJson[ModalContentType].buttonText}
        buttonTextSecondary={dataJson[ModalContentType]?.buttonTextSecundary}
        secundaryButton={dataJson[ModalContentType]?.buttonTextSecundary ? true : false}
        cardContent={
          dataJson[ModalContentType].cardContent
        }
        imgSrc={dataJson[ModalContentType].imgSrc}
        style={{
          backgroundColor: MembershipColor.free,
          border: "1px solid " + MembershipColor.free,
        }}
        styleTextContent={{
          fontSize: "17px",
        }}
        styleImg={{
          backgroundColor: `#C0E6F2`,
          padding: 0,
          display: "flex",
          flexWrap: "wrap",
          alignContent: "flex-end",
          ...dataJson[ModalContentType].styleImg
        }}
        styleImgCard={{
          padding: 0,
        }}
        styleButton={{
          color: MembershipColor.free
        }}
        styleButtonSecondary={{
          color: '#FFF',
          backgroundColor: "transparent",
          border: 'none'
        }}
      />}
    /> : null
  ) 
}

export default ModalCheckUp;