import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import "./MemberShipItem.css";
import { themeValues, myColorPalette } from '../../../../../theme/themeGenerated';

import { MembershipColor } from '../../../Membership/MembershipMainValues';

export interface BenefitsDetailsCheck {
    title: string;
    description: string;
    icon?: string;
    cardType?: string;
    checkedProp: string;
    onCheckedChange: (newChecked: any) => void;
}
interface MembershipItemProsCheck extends BenefitsDetailsCheck {
    isLastItem?: boolean;
}

const MembershipItemCheck: React.FC<MembershipItemProsCheck> = ({
    title,
    description,
    icon = '',
    cardType,
    onCheckedChange,
    checkedProp,
    isLastItem = false
}) => {

    return (
        <>
            <div className="itemCardBenefit" style={{
                padding: '8px',
                margin: '0px 12px 0px',
                backgroundColor: cardType === "free" ?
                    window.location.pathname === "/finsus" ? myColorPalette.getFinsusRgb() :
                        MembershipColor.freeOpacity : cardType ? MembershipColor.premiumOpacity : 'transparent'
            }}>
                <div className="icon">
                    {icon && icon !== '' ? icon :
                        <FormControlLabel
                            style={{ marginRight: '0px' }}
                            onChange={onCheckedChange} value={{ title }}
                            control={<Radio checked={checkedProp === title}
                            />} label="" />}
                </div>
                <div className="text">
                    <div className="titleItem">
                        <p>
                            {title}
                        </p>

                    </div>
                    <div
                        className="textDescription"
                        style={{
                            paddingBottom: '10px'
                        }}
                    >
                        <p>
                            {description}
                        </p>
                    </div>
                </div>
            </div>
            {!isLastItem && (
                <div style={{
                    padding: '8px',
                    margin: '0px 12px 0px',
                    backgroundColor: cardType === "free" ?
                        window.location.pathname === "/finsus" ? myColorPalette.getFinsusRgb() :
                            MembershipColor.freeOpacity : cardType ? MembershipColor.premiumOpacity : 'transparent',
                    width: '-webkit-fill-available',
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <p>
                        {'O'}
                    </p>
                </div>
            )}
        </>)
}

export default MembershipItemCheck;
