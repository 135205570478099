import React from 'react';
import styled from "styled-components";

import Table from '../components/Table';
import { Link } from '@mui/material';

function Paragraph({ children }: any) {
    const Paragraph = styled.div`
        margin-top: 16px;
   `

    return (
        <Paragraph>{children}</Paragraph>
    )
}

function BlackText({ children }: any) {
    const BlackText = styled.span`
        color: #212121;
        font-family: 'Montserrat';
        font-size: '16px';
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
    `;

    return (
        <BlackText>{children}</BlackText>
    )
}

function List({ elements, numbers }: any) {

    const values = elements.map((element: any) => {
        return (
            <li>{element}</li>
        )
    })

    return (
        numbers ? <ol style={{ paddingLeft: '20px' }}>{values}</ol> : <ul style={{ paddingLeft: '20px' }}>{values}</ul>
    )
}

function TableLosses() {
    const info = [
        { title: "Ambas manos", description: "100%" },
        { title: "Ambos pies", description: "100%" },
        { title: "Ambos ojos", description: "100%" },
        { title: "Una mano y un pie", description: "100%" },
        { title: "Una mano o un pie, conjuntamente con un ojo", description: "100%" },
        { title: "Una mano o un pie", description: "50%" },
        { title: "Un ojo", description: "30%" },
        { title: "Amputación parcial de un pie, comprendiendo todos los dedos", description: "30%" },
        { title: "Tres dedos de una mano incluyendo el dedo pulgar o el índice", description: "25%" },
        { title: "La audición total e irreversible de ambos oídos", description: "25%" },
        { title: "El índice de una mano y cualquier otro dedo que no sea el pulgar", description: "20%" },
        { title: "El dedo pulgar de cualquier mano", description: "15%" },
        { title: "El acortamiento de por lo menos 5 cm de un miembro inferior", description: "15%" },
        { title: "El índice de cualquier mano", description: "10%" },
        { title: "El dedo medio, el anular o el meñique", description: "5%" },
    ]

    return (
        <Paragraph>
            <Table title="Escala B" headers={false} concepts={info} />
        </Paragraph>
    )
}

const coberturas = [
    // {
    //     title: "Celular Protect de Regalo",
    //     table: [
    //         { title: "Muerte accidental", description: "Suma Asegurada de $20,000 pesos." },
    //         { title: "Reparación de pantalla", description: "1 evento durante la vigencia del plan, hasta por $4,000 pesos. Aplica copago de $400 pesos" },
    //         { title: "Descuentos", description: "Club de descuentos en comercios participantes Ilimitado y sin costo" },
    //         { title: "Telemedicina", description: "Atención con médico general Ilimitado y sin costo" }
    //     ]
    // },


    {
        benefit_id: "S02",
        title: "Salud Protect de Regalo (Reembolso de gastos médicos)",
        table: [
            { title: "Reembolso de gastos médicos", description: "1 evento durante la vigencia del plan hasta por $10,000 pesos" },
            { title: "Muerte accidental", description: "Suma Asegurada de $20,000 pesos" },
            { title: "Descuentos", description: "Club de descuentos en comercios participantes Ilimitado y sin costo" },
            { title: "Telemedicina", description: "Atención con médico general Ilimitado y sin costo" },
        ],
        duration: "12 meses",
        coverage_details: [
            {
                title: "Reembolso de gastos médicos por accidente:",
                description: <>Si durante la vigencia de esta Póliza, como consecuencia directa de un Accidente Cubierto y dentro de los noventa (90) días siguientes a la fecha del mismo, el Asegurado se viera precisado a someterse a tratamiento médico, intervención quirúrgica, a hospitalizarse, o hacer uso de ambulancia, servicios de enfermera, medicinas o estudios de laboratorio y de gabinete, la Compañía reembolsará el monto de los mencionados gastos, previa comprobación.
                    <Paragraph>El reembolso de gastos médicos tendrá las limitaciones siguientes:</Paragraph>
                    <BlackText>1. Los gastos que resulten de aparatos de prótesis, dental o de cualquier otra clase, y tratamientos de ortodoncia se cubren hasta el límite del quince por ciento (15%) de la Suma Asegurada contratada para esta sección.</BlackText>
                    <BlackText>2. Los gastos de ambulancia o del traslado se cubren hasta un máximo del cinco por ciento (5%) de la Suma Asegurada contratada para esta sección.</BlackText>
                    <Paragraph>No están cubiertos los gastos realizados por acompañantes del Asegurado durante su internamiento en una Institución Hospitalaria.
                        La Compañía tan sólo cubrirá los honorarios de médicos y enfermeras graduadas y oficialmente autorizadas para el ejercicio de su profesión</Paragraph>
                </>,
                amount: "$10,000.00 (diez mil pesos 00/100 M.N.), 1 evento durante la vigencia del plan.",
                age: "de 18 a 69 años",
                onlyInsured: true
            },
            {
                title: "Muerte accidental:",
                description: <>
                    Se pagará al Beneficiario designado la Suma Asegurada correspondiente a esta cobertura si ocurre el fallecimiento del Asegurado a consecuencia de un Accidente Cubierto dentro de los noventa (90) días siguientes a la fecha del mismo, siempre y cuando la Póliza se encuentre en vigor al momento del siniestro.
                </>,
                amount: "$20,000.00 (veinte mil pesos 00/100 M.N.)",
                age: "de 18 a 69 años",
                onlyInsured: true
            }
        ],
        assistances: [
            {
                title: "Descuentos",
                description: <>
                    Acceso a descuentos online en comercios de diversas categorías y subcategorías asociadas a la membresía del producto.
                    <Paragraph>
                        Para hacer válidos los descuentos se deberá verificar la ubicación (territorio nacional) y disponibilidad del comercio del club de descuentos dentro del portal de cliente con tu usuario y contraseña
                        <Link
                            // style={{display: 'inline-block'}}
                            href="https://teregalamosunseguro.inter.mx"
                            target={'_blank'}
                        >
                            <BlackText>
                                https://teregalamosunseguro.inter.mx,
                            </BlackText>
                        </Link> a través de la geolocalización, pudiendo hacer válidos los descuentos dentro del establecimiento, presentando el cupón en sus diferentes presentaciones, según el comercio.
                    </Paragraph>
                </>,
                limit: "Ilimitado",
                exclusions: "Promociones/descuentos que no se encuentren dentro del portal."
            },
            {
                title: "Telemedicina:",
                description: <>
                    Orientación en salud que recibirán los Asegurado es al acceder al portal de cliente
                    <Link
                        style={{ display: 'inline-block' }}
                        href="https://teregalamosunseguro.inter.mx"
                        target={'_blank'}
                    >
                        <BlackText>
                            https://teregalamosunseguro.inter.mx,
                        </BlackText>
                    </Link>  la cual se limitará a lo siguiente:
                    <List numbers elements={[
                        "Servicio de orientación médica, con médico general, la cual no podrá ser considerará como una consulta médica presencial;",
                        "Expedición de receta médica, la cual se puede utilizar para adquirir los medicamentos prescritos por el médico en la farmacia de preferencia.",
                        "El servicio no reemplaza, sustituye o suple una consulta médica presencial;",
                        "En caso de ser necesario, usted deberá consultar a un médico o a una Institución con la capacidad necesaria para brindar la atención a su padecimiento;",
                        "Los consejos y opiniones emitidos por el personal no deben ser tomados como una opinión médica definitiva por lo que, en caso de tomarlos e implementarlos, será absolutamente bajo su responsabilidad."
                    ]} />
                    <Paragraph>
                        La asesoría médica a la que se tendrá acceso a través del portal, es exclusivamente respecto de padecimientos de primer nivel de atención o atención primaria, siendo estos padecimientos de baja complejidad médica, cuyo objetivo y alcance es resolver las necesidades de atención básicas y más frecuentes que pueden ser resueltas por actividades de promoción de salud, prevención de la enfermedad y por procedimientos de recuperación y rehabilitación, sin que la atención de dichos padecimientos a través de la Plataforma pueda poner en peligro su vida y/o su integridad física.
                        El personal de salud que realiza las asesorías no se hace responsables por los potenciales efectos adversos y/o reacciones desfavorables derivados del uso de estos, incluyendo reacciones alérgicas.
                    </Paragraph>
                    <Paragraph>
                        En caso de que el padecimiento o sintomatología referida sea distinta a padecimientos de primer nivel o de atención primaria o se trate de una urgencia o emergencia médica, se podrá suspender la asesoría y referirlo de manera inmediata a un centro de salud o institución facultado para atender su padecimiento de forma adecuada, en salvaguarda de su salud e integridad física, no representando esto un incumplimiento al servicio.
                    </Paragraph>
                    <Paragraph>
                        El servicio de Telemedicina es prestado por profesionales de la salud que se encuentran legalmente facultados para ejercer la medicina en los Estados Unidos Mexicanos.
                        <Paragraph>
                        </Paragraph>
                        Dependiendo del tipo de padecimiento presentado o referido durante la asesoría, podría resultar necesario que muestre partes de su cuerpo al profesional que le estará atendiendo. Usted tiene plena potestad de decidir si muestra o no las partes de su cuerpo al médico en el entendido que:
                    </Paragraph>
                    <List numbers elements={[
                        "Los médicos únicamente solicitarán la vista o exploración de alguna de las partes de su cuerpo cuando sea estrictamente necesario; y previo consentimiento verbal.",
                        "No almacenaremos y/o guardaremos imagen alguna dentro de nuestros expedientes; salvo las imágenes obtenidas durante la asesoría y relacionadas con la patología por la cual se está consultando y que forman parte de su expediente clínico.",
                        "Usted entiende y acepta que en caso de que se niegue a mostrar a nuestros médicos alguna parte de su cuerpo, la asesoría podría ser suspendida si el médico considera que no cuenta con elementos para emitir una opinión, no representado esto un incumplimiento a nuestros servicios."
                    ]} />
                </>,
                limit: "ilimitado, cada orientación médica tendrá una duración de hasta 25 minutos.",
                exclusions: <List elements={[
                    "Se podrá suspender la consulta en caso de cometer faltas de respecto, o se mantengan conductas inadecuadas.",
                    "La cesión de uso por parte del Asegurado a terceras personas no autorizadas por. Ejemplo: conectar para hacer una consulta de un no autorizado haciéndose pasar por el Asegurado.",
                    "La explotación comercial de los servicios a los que tiene acceso. Ejemplo: persona o empresa que revende consultas utilizando este servicio.",
                    "Cualquier forma de violación de los derechos de terceros. Entendiendo como tal que el Asegurado pretenda grabar al Prestador o a cualquier persona de su red medica con fines de lucro, para difundir los videos en algún medio digital, página web, canal de internet, redes sociales, etc.",
                    "Utilizando el servicio para eludir responsabilidades, es decir, el informe expedido por el Prestador a través de su red medica nunca podrá funcionar como justificante médico."
                ]} />
            }
        ],
        chubb: true
    },
    {
        benefit_id: "S01",
        title: "Salud Protect de Regalo (Pago de medicamentos)",
        table: [
            { title: "Pago de medicamentos", description: "1 evento durante la vigencia del plan hasta por $1,000 pesos" },
            { title: "Muerte accidental", description: "Suma Asegurada de $20,000 pesos" },
            { title: "Descuentos", description: "Club de descuentos en comercios participantes Ilimitado y sin costo" },
            { title: "Telemedicina", description: "Atención con médico general Ilimitado y sin costo" },
        ],
        duration: "12 meses",
        coverage_details: [
            {
                title: "Muerte accidental:",
                description: <>Se pagará al Beneficiario designado la Suma Asegurada correspondiente a esta cobertura si ocurre el fallecimiento del Asegurado a consecuencia de un Accidente Cubierto dentro de los noventa (90) días siguientes a la fecha del mismo, siempre y cuando la Póliza se encuentre en vigor al momento del siniestro.</>,
                amount: "$20,000.00 (veinte mil pesos 00/100 M.N.)",
                age: "de 18 a 69 años",
                onlyInsured: true
            }
        ],
        assistances: [
            {
                title: "Descuentos",
                description: <>
                    Acceso a descuentos online en comercios de diversas categorías y subcategorías asociadas a la membresía del producto.
                    <Paragraph>
                        Para hacer válidos los descuentos se deberá verificar la ubicación (territorio nacional) y disponibilidad del comercio del club de descuentos dentro del portal de cliente con tu usuario y contraseña
                        <Link
                            style={{ display: 'inline-block' }}
                            href="https://teregalamosunseguro.inter.mx"
                            target={'_blank'}
                        >
                            <BlackText>
                                https://teregalamosunseguro.inter.mx,
                            </BlackText>
                        </Link> a través de la geolocalización, pudiendo hacer válidos los descuentos dentro del establecimiento, presentando el cupón en sus diferentes presentaciones, según el comercio.
                    </Paragraph>
                </>,
                limit: "Ilimitado",
                exclusions: "Promociones/descuentos que no se encuentren dentro del portal."
            },
            {
                title: "Telemedicina:",
                description: <>
                    Orientación en salud que recibirán los Asegurado es al acceder al portal de cliente
                    <Link
                        style={{ display: 'inline-block' }}
                        href="https://teregalamosunseguro.inter.mx"
                        target={'_blank'}
                    >
                        <BlackText>
                            https://teregalamosunseguro.inter.mx,
                        </BlackText>
                    </Link>  la cual se limitará a lo siguiente:
                    <List numbers elements={[
                        "Servicio de orientación médica, con médico general, la cual no podrá ser considerará como una consulta médica presencial;",
                        "Expedición de receta médica, la cual se puede utilizar para adquirir los medicamentos prescritos por el médico en la farmacia de preferencia.",
                        "El servicio no reemplaza, sustituye o suple una consulta médica presencial;",
                        "En caso de ser necesario, usted deberá consultar a un médico o a una Institución con la capacidad necesaria para brindar la atención a su padecimiento;",
                        "Los consejos y opiniones emitidos por el personal no deben ser tomados como una opinión médica definitiva por lo que, en caso de tomarlos e implementarlos, será absolutamente bajo su responsabilidad."
                    ]} />
                    <Paragraph>
                        La asesoría médica a la que se tendrá acceso a través del portal, es exclusivamente respecto de padecimientos de primer nivel de atención o atención primaria, siendo estos padecimientos de baja complejidad médica, cuyo objetivo y alcance es resolver las necesidades de atención básicas y más frecuentes que pueden ser resueltas por actividades de promoción de salud, prevención de la enfermedad y por procedimientos de recuperación y rehabilitación, sin que la atención de dichos padecimientos a través de la Plataforma pueda poner en peligro su vida y/o su integridad física.
                        El personal de salud que realiza las asesorías no se hace responsables por los potenciales efectos adversos y/o reacciones desfavorables derivados del uso de estos, incluyendo reacciones alérgicas.
                    </Paragraph>
                    <Paragraph>
                        En caso de que el padecimiento o sintomatología referida sea distinta a padecimientos de primer nivel o de atención primaria o se trate de una urgencia o emergencia médica, se podrá suspender la asesoría y referirlo de manera inmediata a un centro de salud o institución facultado para atender su padecimiento de forma adecuada, en salvaguarda de su salud e integridad física, no representando esto un incumplimiento al servicio.
                    </Paragraph>
                    <Paragraph>
                        El servicio de Telemedicina es prestado por profesionales de la salud que se encuentran legalmente facultados para ejercer la medicina en los Estados Unidos Mexicanos.
                        <Paragraph>
                        </Paragraph>
                        Dependiendo del tipo de padecimiento presentado o referido durante la asesoría, podría resultar necesario que muestre partes de su cuerpo al profesional que le estará atendiendo. Usted tiene plena potestad de decidir si muestra o no las partes de su cuerpo al médico en el entendido que:
                    </Paragraph>
                    <List numbers elements={[
                        "Los médicos únicamente solicitarán la vista o exploración de alguna de las partes de su cuerpo cuando sea estrictamente necesario; y previo consentimiento verbal.",
                        "No almacenaremos y/o guardaremos imagen alguna dentro de nuestros expedientes; salvo las imágenes obtenidas durante la asesoría y relacionadas con la patología por la cual se está consultando y que forman parte de su expediente clínico.",
                        "Usted entiende y acepta que en caso de que se niegue a mostrar a nuestros médicos alguna parte de su cuerpo, la asesoría podría ser suspendida si el médico considera que no cuenta con elementos para emitir una opinión, no representado esto un incumplimiento a nuestros servicios."
                    ]} />
                </>,
                limit: "ilimitado, cada orientación médica tendrá una duración de hasta 25 minutos.",
                exclusions: <List elements={[
                    "Se podrá suspender la consulta en caso de cometer faltas de respecto, o se mantengan conductas inadecuadas.",
                    "La cesión de uso por parte del Asegurado a terceras personas no autorizadas por. Ejemplo: conectar para hacer una consulta de un no autorizado haciéndose pasar por el Asegurado.",
                    "La explotación comercial de los servicios a los que tiene acceso. Ejemplo: persona o empresa que revende consultas utilizando este servicio.",
                    "Cualquier forma de violación de los derechos de terceros. Entendiendo como tal que el Asegurado pretenda grabar al Prestador o a cualquier persona de su red medica con fines de lucro, para difundir los videos en algún medio digital, página web, canal de internet, redes sociales, etc.",
                    "Utilizando el servicio para eludir responsabilidades, es decir, el informe expedido por el Prestador a través de su red medica nunca podrá funcionar como justificante médico."
                ]} />
            }
        ],
        medications: true,
        chubb: true
    },
    {
        benefit_id: "S03",
        title: "Salud Protect de Regalo (Check-Up médico)",
        table: [
            { title: "Check-Up médico", description: "1 evento durante la vigencia del plan de Química sanguínea 24 elementos, BH y EGO" },
            { title: "Muerte accidental", description: "Suma Asegurada de $20,000 pesos" },
            { title: "Descuentos", description: "Club de descuentos en comercios participantes Ilimitado y sin costo" },
            { title: "Telemedicina", description: "Atención con médico general Ilimitado y sin costo" },
        ],
        duration: '12 meses',
        coverage_details: [
            {
                title: "Muerte accidental:",
                description: <>Se pagará al Beneficiario designado la Suma Asegurada correspondiente a esta cobertura si ocurre el fallecimiento del Asegurado a consecuencia de un Accidente Cubierto dentro de los noventa (90) días siguientes a la fecha del mismo, siempre y cuando la Póliza se encuentre en vigor al momento del siniestro.</>,
                amount: "$20,000.00 (veinte mil pesos 00/100 M.N.)",
                age: "de 18 a 69 años",
                onlyInsured: true
            }
        ],
        assistances: [
            {
                title: "Descuentos",
                description: <>
                    Acceso a descuentos online en comercios de diversas categorías y subcategorías asociadas a la membresía del producto.
                    <Paragraph>
                        Para hacer válidos los descuentos se deberá verificar la ubicación (territorio nacional) y disponibilidad del comercio del club de descuentos dentro del portal de cliente con tu usuario y contraseña
                        <Link
                            style={{ display: 'inline-block' }}
                            href="https://teregalamosunseguro.inter.mx"
                            target={'_blank'}
                        >
                            <BlackText>
                                https://teregalamosunseguro.inter.mx,
                            </BlackText>
                        </Link> a través de la geolocalización, pudiendo hacer válidos los descuentos dentro del establecimiento, presentando el cupón en sus diferentes presentaciones, según el comercio.
                    </Paragraph>
                </>,
                limit: "Ilimitado",
                exclusions: "Promociones/descuentos que no se encuentren dentro del portal."
            },
            {
                title: "Telemedicina:",
                description: <>
                    Orientación en salud que recibirán los Asegurado es al acceder al portal de cliente
                    <Link
                        style={{ display: 'inline-block' }}
                        href="https://teregalamosunseguro.inter.mx"
                        target={'_blank'}
                    >
                        <BlackText>
                            https://teregalamosunseguro.inter.mx,
                        </BlackText>
                    </Link>  la cual se limitará a lo siguiente:
                    <List numbers elements={[
                        "Servicio de orientación médica, con médico general, la cual no podrá ser considerará como una consulta médica presencial;",
                        "Expedición de receta médica, la cual se puede utilizar para adquirir los medicamentos prescritos por el médico en la farmacia de preferencia.",
                        "El servicio no reemplaza, sustituye o suple una consulta médica presencial;",
                        "En caso de ser necesario, usted deberá consultar a un médico o a una Institución con la capacidad necesaria para brindar la atención a su padecimiento;",
                        "Los consejos y opiniones emitidos por el personal no deben ser tomados como una opinión médica definitiva por lo que, en caso de tomarlos e implementarlos, será absolutamente bajo su responsabilidad."
                    ]} />
                    <Paragraph>
                        La asesoría médica a la que se tendrá acceso a través del portal, es exclusivamente respecto de padecimientos de primer nivel de atención o atención primaria, siendo estos padecimientos de baja complejidad médica, cuyo objetivo y alcance es resolver las necesidades de atención básicas y más frecuentes que pueden ser resueltas por actividades de promoción de salud, prevención de la enfermedad y por procedimientos de recuperación y rehabilitación, sin que la atención de dichos padecimientos a través de la Plataforma pueda poner en peligro su vida y/o su integridad física.
                        El personal de salud que realiza las asesorías no se hace responsables por los potenciales efectos adversos y/o reacciones desfavorables derivados del uso de estos, incluyendo reacciones alérgicas.
                    </Paragraph>
                    <Paragraph>
                        En caso de que el padecimiento o sintomatología referida sea distinta a padecimientos de primer nivel o de atención primaria o se trate de una urgencia o emergencia médica, se podrá suspender la asesoría y referirlo de manera inmediata a un centro de salud o institución facultado para atender su padecimiento de forma adecuada, en salvaguarda de su salud e integridad física, no representando esto un incumplimiento al servicio.
                    </Paragraph>
                    <Paragraph>
                        El servicio de Telemedicina es prestado por profesionales de la salud que se encuentran legalmente facultados para ejercer la medicina en los Estados Unidos Mexicanos.
                        <Paragraph>
                        </Paragraph>
                        Dependiendo del tipo de padecimiento presentado o referido durante la asesoría, podría resultar necesario que muestre partes de su cuerpo al profesional que le estará atendiendo. Usted tiene plena potestad de decidir si muestra o no las partes de su cuerpo al médico en el entendido que:
                    </Paragraph>
                    <List numbers elements={[
                        "Los médicos únicamente solicitarán la vista o exploración de alguna de las partes de su cuerpo cuando sea estrictamente necesario; y previo consentimiento verbal.",
                        "No almacenaremos y/o guardaremos imagen alguna dentro de nuestros expedientes; salvo las imágenes obtenidas durante la asesoría y relacionadas con la patología por la cual se está consultando y que forman parte de su expediente clínico.",
                        "Usted entiende y acepta que en caso de que se niegue a mostrar a nuestros médicos alguna parte de su cuerpo, la asesoría podría ser suspendida si el médico considera que no cuenta con elementos para emitir una opinión, no representado esto un incumplimiento a nuestros servicios."
                    ]} />
                </>,
                limit: "ilimitado, cada orientación médica tendrá una duración de hasta 25 minutos.",
                exclusions: <List elements={[
                    "Se podrá suspender la consulta en caso de cometer faltas de respecto, o se mantengan conductas inadecuadas.",
                    "La cesión de uso por parte del Asegurado a terceras personas no autorizadas por. Ejemplo: conectar para hacer una consulta de un no autorizado haciéndose pasar por el Asegurado.",
                    "La explotación comercial de los servicios a los que tiene acceso. Ejemplo: persona o empresa que revende consultas utilizando este servicio.",
                    "Cualquier forma de violación de los derechos de terceros. Entendiendo como tal que el Asegurado pretenda grabar al Prestador o a cualquier persona de su red medica con fines de lucro, para difundir los videos en algún medio digital, página web, canal de internet, redes sociales, etc.",
                    "Utilizando el servicio para eludir responsabilidades, es decir, el informe expedido por el Prestador a través de su red medica nunca podrá funcionar como justificante médico."
                ]} />
            }
        ],
        checkup: true,
        chubb: true
    },
    {
        benefit_id: "S04",
        title: "Salud Protect Premium",
        table: [
            { title: "Reembolso de gastos médicos", description: "1 evento durante la vigencia del plan hasta por $35,000 pesos por evento" },
            { title: "Pago de medicamentos", description: "3 eventos durante la vigencia del plan hasta por $1,000 pesos por evento" },
            { title: "Check-Up médico", description: "3 eventos durante la vigencia del plan de Química sanguínea 24 elementos, BH y EGO" },
            { title: "Pérdidas orgánicas escala B", description: "1 evento durante la vigencia del plan hasta por $300,000 pesos por evento (Aplica tabulador en condiciones generales)" },
            { title: "Invalidez total y permanente por accidente", description: "1 evento durante la vigencia del plan hasta por $16,000 pesos por evento" },
            { title: "Apoyo de incapacidad por accidente", description: "Apoyo diario de $220 pesos hasta por 30 días, 1 evento durante la vigencia del plan" },
            { title: "Muerte accidental", description: "Suma Asegurada de $300,000 pesos." },
            { title: "Telemedicina", description: "Atención con médico general Ilimitado y sin costo" },
            { title: "Descuentos", description: "Club de descuentos en comercios participantes Ilimitado y sin costo" },
        ],
        duration: "12 meses",
        coverage_details: [
            {
                title: "Reembolso de gastos médicos por accidente:",
                description: <>Si durante la vigencia de esta Póliza, como consecuencia directa de un Accidente Cubierto y dentro de los noventa (90) días siguientes a la fecha del mismo, el Asegurado se viera precisado a someterse a tratamiento médico, intervención quirúrgica, a hospitalizarse, o hacer uso de ambulancia, servicios de enfermera, medicinas o estudios de laboratorio y de gabinete, la Compañía reembolsará el monto de los mencionados gastos, previa comprobación.
                    <Paragraph>El reembolso de gastos médicos tendrá las limitaciones siguientes:</Paragraph>
                    <BlackText>1. Los gastos que resulten de aparatos de prótesis, dental o de cualquier otra clase, y tratamientos de ortodoncia se cubren hasta el límite del quince por ciento (15%) de la Suma Asegurada contratada para esta sección.</BlackText>
                    <BlackText>2. Los gastos de ambulancia o del traslado se cubren hasta un máximo del cinco por ciento (5%) de la Suma Asegurada contratada para esta sección.</BlackText>
                    <Paragraph>No están cubiertos los gastos realizados por acompañantes del Asegurado durante su internamiento en una Institución Hospitalaria.
                        La Compañía tan sólo cubrirá los honorarios de médicos y enfermeras graduadas y oficialmente autorizadas para el ejercicio de su profesión</Paragraph>
                </>,
                amount: "$35,000.00 (treinta y cinco mil pesos 00/100 M.N.), 1 evento durante la vigencia del plan.",
                age: "de 18 a 69 años",
                onlyInsured: true
            },
            {
                title: "Perdidas Organicas:",
                description: <>Si como consecuencia de un Accidente Cubierto y dentro de los noventa (90) días siguientes a la fecha del mismo, el Asegurado sufre cualquiera de las pérdidas enunciadas en la Tabla de Indemnizaciones incluida en este apartado, la Aseguradora pagará el porcentaje de la Suma Asegurada correspondiente a esta cobertura indicado en dicha tabla:
                    <TableLosses />
                    <Paragraph>Para las pérdidas anteriores se entenderá:</Paragraph>
                    <List numbers elements={["Por pérdida de la mano: la mutilación, anquilosamiento o pérdida de la funcionalidad motriz total a nivel de la articulación carpometacarpiana o arriba de ella (a nivel de la muñeca o arriba de ella).", "Por pérdida del pie: la mutilación completa, anquilosamiento o pérdida de la funcionalidad motriz total desde la articulación tibio-tarsiana o arriba de ella.", "Por pérdida de los dedos: la mutilación, anquilosamiento o pérdida de la funcionalidad motriz total desde la coyuntura metacarpo o metatarso falangeal, según sea el caso, o arriba de la misma (entre el inicio y final de los nudillos).", "En cuanto a los ojos, la pérdida completa e irreparable de la vista"]} />
                </>,
                amount: "Hasta $300,000.00 (trecientos mil pesos 00/100 M.N.)",
                age: "de 18 a 69 años",
                onlyInsured: true
            },
            {
                title: "Invalidez total y permanente por accidente:",
                description: <>
                    Si como consecuencia directa de un Accidente cubierto e independientemente de cualquier otra causa el Asegurado, dentro de los noventa (90) días siguientes a la fecha del mismo, sufriere un estado de Invalidez Total y Permanente, la Compañía pagará la Suma Asegurada correspondiente a esta cobertura.
                    <Paragraph>A fin de determinar el estado de Invalidez Total y Permanente, el Asegurado deberá presentar a la Aseguradora, además de lo establecido en la cláusula de pruebas y documentos para el pago de la Indemnización, el dictamen de Invalidez Total y Permanente avalado por una institución o médico con cédula profesional, certificados y especialistas en la materia, así como todos los exámenes, análisis y documentos que sirvieron de base para determinar la Invalidez Total y Permanente, mismos que, en caso de controversia sobre la procedencia del estado de Invalidez Total y Permanente, serán evaluados por un médico especialista certificado por el Consejo de la Especialidad de Medicina del Trabajo, que elija el Asegurado dentro de los previamente designados por la Aseguradora, para estos efectos y en caso de proceder el estado de Invalidez Total y Permanente, la Aseguradora cubrirá lo correspondiente en términos del contrato de seguro.</Paragraph>
                    <Paragraph>De no proceder el estado de Invalidez Total y Permanente, el costo del peritaje correrá a cargo del Asegurado.</Paragraph>
                </>,
                amount: "Hasta $16,000.00 (dieciséis mil pesos 00/100 M.N.)",
                age: "de 18 a 69 años",
                onlyInsured: true
            },
            {
                title: "Indemnización diaria por hospitalización:",
                description: <>
                    La Compañía será responsable del pago de una (1) renta diaria por cada Día de Hospitalización del Asegurado como consecuencia de un accidente cubierto. Esta cobertura aplicará siempre y cuando transcurra por lo menos un (1) Día de Hospitalización del Asegurado, el pago del cual quedará a cargo de éste.
                    <Paragraph>Esta cobertura cubrirá el número de eventos, por año Póliza de cada Asegurado, especificados en la Carátula de la Póliza, por lo que la cobertura finalizará automáticamente al concluir el número máximo de eventos especificados en la Carátula de la Póliza</Paragraph>
                </>,
                amount: "$220.00 pesos por cada día (doscientos veinte pesos 00/100 M.N.) y hasta por 30 días. Limitado a 1 evento durante la vigencia del seguro.",
                age: "de 18 a 69 años",
                onlyInsured: false
            },
            {
                title: "Muerte accidental:",
                description: <>Se pagará al Beneficiario designado la Suma Asegurada correspondiente a esta cobertura si ocurre el fallecimiento del Asegurado a consecuencia de un Accidente Cubierto dentro de los noventa (90) días siguientes a la fecha del mismo, siempre y cuando la Póliza se encuentre en vigor al momento del siniestro.</>,
                amount: "$300,000.00 (trescientos mil pesos 00/100 M.N.)",
                age: "de 18 a 69 años",
                onlyInsured: true
            }
        ],
        assistances: [
            {
                title: "Descuentos",
                description: <>
                    Acceso a descuentos online en comercios de diversas categorías y subcategorías asociadas a la membresía del producto.
                    <Paragraph>
                        Para hacer válidos los descuentos se deberá verificar la ubicación (territorio nacional) y disponibilidad del comercio del club de descuentos dentro del portal de cliente con tu usuario y contraseña
                        <Link
                            style={{ display: 'inline-block' }}
                            href="https://teregalamosunseguro.inter.mx"
                            target={'_blank'}
                        >
                            <BlackText>
                                https://teregalamosunseguro.inter.mx,
                            </BlackText>
                        </Link> a través de la geolocalización, pudiendo hacer válidos los descuentos dentro del establecimiento, presentando el cupón en sus diferentes presentaciones, según el comercio.
                    </Paragraph>
                </>,
                limit: "Ilimitado",
                exclusions: "Promociones/descuentos que no se encuentren dentro del portal."
            },
            {
                title: "Telemedicina:",
                description: <>
                    Orientación en salud que recibirán los Asegurado es al acceder al portal de cliente
                    <Link
                        style={{ display: 'inline-block' }}
                        href="https://teregalamosunseguro.inter.mx"
                        target={'_blank'}
                    >
                        <BlackText>
                            https://teregalamosunseguro.inter.mx,
                        </BlackText>
                    </Link>  la cual se limitará a lo siguiente:
                    <List numbers elements={[
                        "Servicio de orientación médica, con médico general, la cual no podrá ser considerará como una consulta médica presencial;",
                        "Expedición de receta médica, la cual se puede utilizar para adquirir los medicamentos prescritos por el médico en la farmacia de preferencia.",
                        "El servicio no reemplaza, sustituye o suple una consulta médica presencial;",
                        "En caso de ser necesario, usted deberá consultar a un médico o a una Institución con la capacidad necesaria para brindar la atención a su padecimiento;",
                        "Los consejos y opiniones emitidos por el personal no deben ser tomados como una opinión médica definitiva por lo que, en caso de tomarlos e implementarlos, será absolutamente bajo su responsabilidad."
                    ]} />
                    <Paragraph>
                        La asesoría médica a la que se tendrá acceso a través del portal, es exclusivamente respecto de padecimientos de primer nivel de atención o atención primaria, siendo estos padecimientos de baja complejidad médica, cuyo objetivo y alcance es resolver las necesidades de atención básicas y más frecuentes que pueden ser resueltas por actividades de promoción de salud, prevención de la enfermedad y por procedimientos de recuperación y rehabilitación, sin que la atención de dichos padecimientos a través de la Plataforma pueda poner en peligro su vida y/o su integridad física.
                        El personal de salud que realiza las asesorías no se hace responsables por los potenciales efectos adversos y/o reacciones desfavorables derivados del uso de estos, incluyendo reacciones alérgicas.
                    </Paragraph>
                    <Paragraph>
                        En caso de que el padecimiento o sintomatología referida sea distinta a padecimientos de primer nivel o de atención primaria o se trate de una urgencia o emergencia médica, se podrá suspender la asesoría y referirlo de manera inmediata a un centro de salud o institución facultado para atender su padecimiento de forma adecuada, en salvaguarda de su salud e integridad física, no representando esto un incumplimiento al servicio.
                    </Paragraph>
                    <Paragraph>
                        El servicio de Telemedicina es prestado por profesionales de la salud que se encuentran legalmente facultados para ejercer la medicina en los Estados Unidos Mexicanos.
                        <Paragraph>
                        </Paragraph>
                        Dependiendo del tipo de padecimiento presentado o referido durante la asesoría, podría resultar necesario que muestre partes de su cuerpo al profesional que le estará atendiendo. Usted tiene plena potestad de decidir si muestra o no las partes de su cuerpo al médico en el entendido que:
                    </Paragraph>
                    <List numbers elements={[
                        "Los médicos únicamente solicitarán la vista o exploración de alguna de las partes de su cuerpo cuando sea estrictamente necesario; y previo consentimiento verbal.",
                        "No almacenaremos y/o guardaremos imagen alguna dentro de nuestros expedientes; salvo las imágenes obtenidas durante la asesoría y relacionadas con la patología por la cual se está consultando y que forman parte de su expediente clínico.",
                        "Usted entiende y acepta que en caso de que se niegue a mostrar a nuestros médicos alguna parte de su cuerpo, la asesoría podría ser suspendida si el médico considera que no cuenta con elementos para emitir una opinión, no representado esto un incumplimiento a nuestros servicios."
                    ]} />
                </>,
                limit: "ilimitado, cada orientación médica tendrá una duración de hasta 25 minutos.",
                exclusions: <List elements={[
                    "Se podrá suspender la consulta en caso de cometer faltas de respecto, o se mantengan conductas inadecuadas.",
                    "La cesión de uso por parte del Asegurado a terceras personas no autorizadas por. Ejemplo: conectar para hacer una consulta de un no autorizado haciéndose pasar por el Asegurado.",
                    "La explotación comercial de los servicios a los que tiene acceso. Ejemplo: persona o empresa que revende consultas utilizando este servicio.",
                    "Cualquier forma de violación de los derechos de terceros. Entendiendo como tal que el Asegurado pretenda grabar al Prestador o a cualquier persona de su red medica con fines de lucro, para difundir los videos en algún medio digital, página web, canal de internet, redes sociales, etc.",
                    "Utilizando el servicio para eludir responsabilidades, es decir, el informe expedido por el Prestador a través de su red medica nunca podrá funcionar como justificante médico."
                ]} />
            }
        ],
        medications: true,
        checkup: true,
        chubb: true
    }
]

export default coberturas