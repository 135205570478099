import React from 'react';

interface BlueCircleProps {
    width?: number;
    height?: number;
    fill?: string;
    stroke?: string;
}

const InfoButton: React.FC<BlueCircleProps> = ({ 
    width = 25, 
    height = 24, 
    fill= '#039ECC', 
    stroke="#77CAE3" 
}) => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 21 20" fill="none">
      <circle cx="10.5" cy="10" r="10" fill="#039ECC"/>
      <path d="M9.24767 11.5139C9.24767 9.56944 11.5717 9.13889 11.5717 7.93055C11.5717 7.25 10.9311 6.81944 9.96276 6.81944C9.00931 6.81944 8.27933 7.20833 7.80261 7.84722L6 6.86111C6.80447 5.72222 8.20484 5 10.216 5C12.4507 5 14 5.93056 14 7.56944C14 9.79167 11.4972 10.0417 11.4972 11.5139H9.24767ZM10.3799 15C9.54562 15 8.94972 14.4306 8.94972 13.7361C8.94972 13.0278 9.54562 12.5 10.3799 12.5C11.2142 12.5 11.8101 13.0278 11.8101 13.7361C11.8101 14.4306 11.2142 15 10.3799 15Z" fill="white"/>
    </svg>
  );
};

export default InfoButton;
