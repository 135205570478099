import React, { useState, useLayoutEffect, useEffect, useRef, useContext } from "react";
import * as Yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { styled } from "styled-components";

import FormContainer from "../../organisms/Form/FormContainer";
import { CustomButton } from "../../atoms/Buttons/Buttons";
import ValidacionNumeroCelular from "../../organisms/Form/FormContent/ValidacionNumeroCelular";
import DatosDeContacto from "./FormContent/DatosDeContacto";
import DatosPersonales from "./FormContent/DatosPersonales";
import { apiSuccess, restartValue } from "../../../api/formRegisterSlice";
import { restartValue as restartBrand } from "../../../api/gadgetsBrandSlice";
import { restartValue as restartModels } from "../../../api/gadgetsModelsSlice";
import { restartValue as restartDescription } from "../../../api/gadgetsDescriptionSlice";
import { restartValueError as restartLeadsError, restartValue as restartLeads } from "../../../api/leadsSlice";
import { restartValue as restartUser } from "../../../api/userSlice";
import { restartValue as restartSignUp } from "../../../api/signUpSlice";
import { restartValue as restartLogin } from '../../../api/loginSlice';
import { restartValue as restartBeneficiary } from "../../../api/multipleBeneficiarySlice";
import { restartValue as restartUserAlliance } from "../../../api/userAlianceSlice";
import {
  apiSuccess as successTracking,
  apiError as errorTracking,
  apiLoading as loadingTracking,
} from "../../../api/trackingSlice";
import {
  restartValue as restartStatus
} from "../../../api/statusSlice"
import { useDispatch, useSelector } from "react-redux";
import { fetchTrackingStore } from "../../../api/fetchingTrackingStore";
import useFetch from "../../../hooks/useFetch";
import {
  apiLoading as apiLoadingLeads,
  apiSuccess as apiSuccessLeads,
  apiError as apiErrorLeads,
} from "../../../api/leadsSlice";
import BasicModal from "../../molecules/BasicModal/BasicModal";
import CardAction from "../../organisms/Card";
import ManiPosesBody from "../../../assets/ManiErrorHandler/ManiPosesErrorHandler.jpg";
import { getBrowserInfo } from "../../../utils/navigatorDetails";
import { logout } from '../../../auth/auth0';
import { ContextAuth0 } from '../../../auth/contextAPIProvider';
import { fieldIsRequired, scrollToTop } from "../../../utils/scrollToTop";
// import MembershipSummaryCard from "../../organisms/Membership/MembershipCard/MembershipCard";
import CreateAccount from "../../organisms/CreateAccount";
import { MembershipAlliance } from './MemberShipAlliance';
// import { IStorageMembershipData } from "../../../utils/localStorageKeys";

import { createTheme } from '@mui/material';
import { themeValues, myColorPalette } from '../../../theme/themeGenerated';

const theme = createTheme(themeValues);

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 85px;
  gap: 24px;
  align-items: flex-start;
  max-width: 558px;
`;

enum MembershipCategory {
  salud = 'salud'
}



type InitialValues = 'formSteps' | 'steps';

type ComponentsMap = {
  [key: number]: React.FC<any>;
};

const AllianceRegisterForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { idStepper } = useParams();
  const [buttonNextHidded, setButtonNextHidded] = useState<boolean>(true);
  const webAuth: any = useContext(ContextAuth0);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [onSubmitActivated, setOnSubmitActivated] = useState("inactive");
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const loadingLeads = useSelector((state: any) => state.leads.loading) as | any | null;
  const errorLeads = useSelector((state: any) => state.leads.error) as | any | null;
  const response = useSelector((state: any) => state.formRegister.response) as
    | any
    | null;
  const responseUserAlliance = useSelector((state: any) => state.userAliance.response) as
    | any
    | null;
  const currentValue = useSelector(
    (state: any) => state.productsCards.currentValue
  ) as any | null;
  const tracking = useSelector((state: any) => state.tracking.response) as
    | any
    | null;
  const leads = useSelector((state: any) => state.leads.response) as any | null;
  // const productsCards = useSelector(
  //   (state: any) => state.productsCards.response
  // ) as any | null;
  // const benefits = useSelector((state: any) => state.gadgets.response) as
  //   | any
  //   | null;
  const [open, setOpenModal] = useState<boolean>(false);
  const { fetchData } = useFetch();
  const actionsRef: any = useRef();
  const valuesRef: any = useRef();
  const formikRef: any = useRef();
  let membershipType: MembershipCategory =
    currentValue?.category?.toLowerCase();
  const [business, setBusiness] = useState<string>("");
  const [register, setRegister] = useState<boolean>(false);
  // const [ IsLoadedRegistrationData, setisLoadedRegistrationData ] = useState<boolean>(false);
  const [dataLevelToShow, setDataLevelToShow] = useState<MembershipAlliance>('empty');
  const allianceSettingResponse = useSelector((state: any) => state.allianceSetting.response);
  const [allianceData, setAllianceData] = useState({});
  const [needIdentification, setNeedIdentification] = useState(false);
  const navigateStepper = useRef<object>({});
  
  const fetchFullData = ({ content }: any) => {
    content['tier'] = currentValue?.subtype || "freemium";
    content['step'] = activeStep;

    fetchTrackingStore(
      "/users/leads",
      "save-lead",
      {
        content: {
          tracking_id: responseUserAlliance?.data?.tracking_id || tracking?.data?.tracking_id, // generado por el endpoint anterior
          ...content,
        },
      },
      fetchData,
      apiLoadingLeads,
      apiSuccessLeads,
      apiErrorLeads
    );
  }

  useEffect(() => {
    if (allianceSettingResponse?.ok) {
      if (allianceSettingResponse?.data?.colors?.primary) {
        myColorPalette.setPrimaryColor(allianceSettingResponse?.data?.colors?.primary)
        myColorPalette.setStepperColor(allianceSettingResponse?.data?.colors?.primary)
      }
      if (allianceSettingResponse?.data?.colors?.secondary) myColorPalette.setSecondaryColor(allianceSettingResponse?.data?.colors?.secondary)
      setBusiness(allianceSettingResponse?.data?.id_bussiness)
      setNeedIdentification(allianceSettingResponse?.data?.alliance_data)
    }

    if (responseUserAlliance?.ok && responseUserAlliance?.data) {
      const {
        business,
        email,
        gender,
        name,
        middle_name,
        last_name,
        birthdate,
        register,
        phone,
        zipcode
      } = responseUserAlliance?.data;
      register && setRegister(register);
      const GENDER = gender ? (gender === 'Hombre' || gender === "HOMBRE" ? 'Hombre' : 'Mujer') : null;
      const data: any = {
        email,
        gender: GENDER,
        name,
        middle_name,
        last_name,
        birthdate,
        zipcode: zipcode ? `${zipcode}` : zipcode,
        phone
      }
      Object.keys(data).filter((value: any) => {
        if (data[value]){
          formikRef.current.setFieldValue(value, data[value]); 
        }
      })
      setAllianceData(data);
      if(name && middle_name && last_name && zipcode && birthdate && gender && phone && email){
        setDataLevelToShow('full_data');
        fetchFullData({content: {...data, business, register}})
      }else if(name && middle_name && last_name && zipcode && birthdate && gender) {
        setDataLevelToShow('full');
      }else if(name && middle_name && last_name) {
        setDataLevelToShow('middle');
      }
      /**
       * Esta validacion se encarga de decirle al componente que 
       * renderiza el contenido del formulario cuales seran renderizados y cuales no
       */
      const nav: any = [0,1,2,3]

      // if (!name || !middle_name || !last_name || !zipcode || !birthdate || !gender) {
      //   // Se comento para Calimax
      //   nav.push(0)
      // }
      // if (!phone || !email) {
      //   nav.push(1)
      // }

      // nav.push(...[0,1,2,3]);
      // console.log({nav})

      if (nav.length < 3) {
        fetchFullData({ content: { ...data, business, register } })
      }

      // setDataLevelToShow('root');

      const formsteps = nav.length;
      const steps = nav.map((value: string, key: number) => { return `paso ${key + 1} de ${nav.length}` })
      const titles = [
        "Para obtener tu seguro, primero ayúdanos con tus datos personales",
        "Vamos con tus datos de contacto",
        "Continuemos con la validación de tu número de celular",
        "Por último, crea tu contraseña para activar tu cuenta"
      ].filter((value: string, key: number) => nav.includes(key))

      navigateStepper.current = {
        formsteps,
        steps,
        titles,
        nav
      }
      setActiveStep(nav[0])
      // membershipTypeModule.salud.root.steps.push();
    } else {
      setDataLevelToShow('root');
      const nav: any = [0, 1, 2, 3]
      const formsteps = nav.length;
      const steps = nav.map((value: string, key: number) => { return `paso ${key + 1} de ${nav.length}` })
      const titles = [
        "Para obtener tu seguro, primero ayúdanos con tus datos personales",
        "Vamos con tus datos de contacto",
        "Continuemos con la validación de tu número de celular",
        "Por último, crea tu contraseña para activar tu cuenta"
      ].filter((value: string, key: number) => nav.includes(key))

      navigateStepper.current = {
        formsteps,
        steps,
        titles,
        nav
      }

      setActiveStep(nav[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseUserAlliance]);

  const getValuesByPropertyValue = (object: any, propertyCopied: any) => {
    let newObject: { [key: string]: any } = {};

    for (const property of propertyCopied) {
      if (object.hasOwnProperty(property)) {
        newObject[property] = typeof object[property] === "string" ? object[property].trim() : object[property];
      }
    }

    newObject["benefit_id"] = currentValue.benefit_id
    // newObject["imei"] = Number(newObject.imei);
    newObject["plan_id"] = currentValue.plan_id;

    return newObject;
  };

  useEffect(() => {
    if (!currentValue?.category) {
      navigate(`/landing-alliance/${business}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const membershipTypeModule: any = {
    salud: {
      root: {
        ...navigateStepper.current,
        initialValues: {
          name: "",
          middle_name: "",
          last_name: "",
          birthdate: "",
          mexicanciticen: true,
          email: "",
          phone: "",
          gender: "",
          acceptTerms: false,
          picked: "",
          otp: "",
          zipcode: "",
          recaptcha: false,
        }
      },
      empty: {
        formSteps: 3, //starting from 0
        steps: ["Paso 1 de 4", "Paso 2 de 4", "Paso 3 de 4", "Paso 4 de 4"], // steps title
        titles: [
          "Para obtener tu seguro, primero ayúdanos con tus datos personales",
          "Vamos con tus datos de contacto",
          "Continuemos con la validación de tu número de celular",
          "Por último, crea tu contraseña para activar tu cuenta"
        ], // content title
        ...navigateStepper.current,
        initialValues: {
          name: "",
          middle_name: "",
          last_name: "",
          birthdate: "",
          mexicanciticen: true,
          email: "",
          phone: "",
          gender: "",
          acceptTerms: false,
          picked: "",
          otp: "",
          zipcode: "",
          recaptcha: false,
        }
      },
      middle: {
        formSteps: 3, //starting from 0
        steps: ["Paso 1 de 4", "Paso 2 de 4", "Paso 3 de 4", "Paso 4 de 4"], // steps title
        titles: [
          "Para obtener tu seguro, primero ayúdanos con tus datos personales",
          "Vamos con tus datos de contacto",
          "Continuemos con la validación de tu número de celular",
          "Por último, crea tu contraseña para activar tu cuenta"
        ], // content title
        ...navigateStepper.current,
        initialValues: {
          name: "",
          middle_name: "",
          last_name: "",
          birthdate: "",
          mexicanciticen: true,
          email: "",
          phone: "",
          gender: "",
          acceptTerms: false,
          picked: "",
          otp: "",
          zipcode: "",
          recaptcha: false,
        }
      },
      full: {
        formSteps: 3, //starting from 0
        steps: ["Paso 1 de 4", "Paso 2 de 4", "Paso 3 de 4", "Paso 4 de 4"], // steps title
        titles: [
          "Para obtener tu seguro, primero ayúdanos con tus datos personales",
          "Vamos con tus datos de contacto",
          "Continuemos con la validación de tu número de celular",
          "Por último, crea tu contraseña para activar tu cuenta"
        ], // content title
        ...navigateStepper.current,
        initialValues: {
          name: "",
          middle_name: "",
          last_name: "",
          birthdate: "",
          mexicanciticen: true,
          email: "",
          phone: "",
          gender: "",
          acceptTerms: false,
          picked: "",
          otp: "",
          zipcode: "",
          recaptcha: false,
        }
      },
      full_data: {
        formSteps: 1, //starting from 0
        steps: ["Paso 1 de 2", "Paso 2 de 2"], // steps title
        titles: [
          "Continuemos con la validación de tu número de celular",
          "Por último, crea tu contraseña para activar tu cuenta"
        ], // content title
        ...navigateStepper.current,
        initialValues: {
          name: "",
          middle_name: "",
          last_name: "",
          birthdate: "",
          mexicanciticen: true,
          email: "",
          phone: "",
          gender: "",
          acceptTerms: false,
          picked: "",
          otp: "",
          zipcode: "",
          recaptcha: false,
        }
      }
    }
  };

  useEffect(() => {
    if (idStepper) {
      const activeStepNav = membershipTypeModule[membershipType]?.[dataLevelToShow]?.nav;

      if (activeStepNav?.length > 0) {
        const index = activeStepNav.indexOf(activeStep)
        navigate(`/alianza/registrate/${index + 1}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  const handleBack = () => {
    const activeStepNav = membershipTypeModule[membershipType]?.[dataLevelToShow]?.nav?.indexOf(activeStep);

    setActiveStep(membershipTypeModule[membershipType]?.[dataLevelToShow]?.nav[activeStepNav - 1]);
  };

  const renderStepContent = (
    step: number,
    errors: any,
    handleChange: any,
    handleBlur: any
  ) => {
    if (dataLevelToShow === 'root') {
      // const ComponenteSeleccionado = components[membershipTypeModule[membershipType]?.[dataLevelToShow]?.nav[step]];
      // return ComponenteSeleccionado ? <ComponenteSeleccionado {...props} />
      const activeStepNav = membershipTypeModule[membershipType]?.[dataLevelToShow]?.nav?.indexOf(activeStep);
      switch (membershipTypeModule[membershipType]?.[dataLevelToShow]?.nav[activeStepNav]) {
        case 0:
          setButtonNextHidded(true);
          return (
            <DatosPersonales
              dataLevelToShow={dataLevelToShow}
              allianceData={allianceData}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errors={errors}
            />
          );
        case 1:
          setButtonNextHidded(true);
          return (
            <DatosDeContacto
              allianceData={allianceData}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errors={errors}
            />
          );
        case 2:
          setButtonNextHidded(true);
          return <ValidacionNumeroCelular handleChange={handleChange} />;
        case 3:
          setButtonNextHidded(false);
          return <CreateAccount />;
        default:
          return <></>;
      }
    }
    if (dataLevelToShow === 'full_data') {
      switch (step) {
        case 0:
          setButtonNextHidded(true);
          return <ValidacionNumeroCelular handleChange={handleChange} trackingFromAlliance={responseUserAlliance?.data?.tracking_id} />;
        case 1:
          setButtonNextHidded(false);
          return <CreateAccount />;
        default:
          return <></>;
      }
    } else if (dataLevelToShow === 'full') {
      switch (step) {
        case 0:
          setButtonNextHidded(true);
          return (
            <DatosPersonales
              dataLevelToShow={dataLevelToShow}
              allianceData={allianceData}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errors={errors}
            />
          );
        case 1:
          setButtonNextHidded(true);
          return (
            <DatosDeContacto
              allianceData={allianceData}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errors={errors}
            />
          );
        case 2:
          setButtonNextHidded(true);
          return <ValidacionNumeroCelular handleChange={handleChange} trackingFromAlliance={responseUserAlliance?.data?.tracking_id} />;
        case 3:
          setButtonNextHidded(false);
          return <CreateAccount />;
        default:
          return <></>;
      }
    } else {
      switch (step) {
        case 0:
          setButtonNextHidded(true);
          return (
            <DatosPersonales
              dataLevelToShow={dataLevelToShow}
              allianceData={allianceData}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errors={errors}
            />
          );
        case 1:
          setButtonNextHidded(true);
          return (
            <DatosDeContacto
              allianceData={allianceData}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errors={errors}
            />
          );
        case 2:
          setButtonNextHidded(true);
          return <ValidacionNumeroCelular handleChange={handleChange} trackingFromAlliance={responseUserAlliance?.data?.tracking_id} />;
        case 3:
          setButtonNextHidded(false);
          return <CreateAccount />;
        default:
          return <></>;
      }
    }
  };

  const _submitForm: any = async (values: any, actions: any) => {
    // actions.setSubmitting(true);
    // navigate("/resumen-membresia", { state: { path: "registrate" } });
  };
  const formSteps = membershipTypeModule[membershipType]?.[dataLevelToShow]?.formsteps;
  // const formSteps = navigateStepper.current.length - 1;
  const _handleSubmit = async (values: any, actions: any) => {
    const steppers = [
      [
        "name",
        "middle_name",
        "last_name",
        "birthdate",
        "mexicanciticen",
        "zipcode",
        "marca",
        "modelo",
        "descripcion",
        // "imei",
      ],
      [
        "name",
        "middle_name",
        "last_name",
        "birthdate",
        "mexicanciticen",
        "zipcode",
        "email",
        "phone",
        "acceptTerms",
        "marca",
        "modelo",
        "descripcion",
        // "imei",
        "gender"
      ],
      [
        "name",
        "middle_name",
        "last_name",
        "birthdate",
        "mexicanciticen",
        "zipcode",
        "email",
        "phone",
        "acceptTerms",
        "otp",
        "marca",
        "modelo",
        "descripcion",
        // "imei",
        "gender"
      ],
      [
        "name",
        "middle_name",
        "last_name",
        "birthdate",
        "mexicanciticen",
        "zipcode",
        "email",
        "phone",
        "acceptTerms",
        "otp",
        "marca",
        "modelo",
        "descripcion",
        // "imei",
        "gender"
      ],
      [
        "name",
        "middle_name",
        "last_name",
        "birthdate",
        "mexicanciticen",
        "zipcode",
        "email",
        "phone",
        "acceptTerms",
        "otp",
        "marca",
        "modelo",
        "descripcion",
        // "imei",
        "gender"
      ],
    ];

    let content: any = {};
    const activeStepNav = membershipTypeModule[membershipType]?.[dataLevelToShow]?.nav?.indexOf(activeStep);
    const index = membershipTypeModule[membershipType]?.[dataLevelToShow]?.nav[activeStepNav]

    if (steppers[index].length > 0) {
      content = getValuesByPropertyValue(values, steppers[index]);
    }

    content['tier'] = currentValue?.subtype || "freemium";
    content['step'] = index;
    content['business'] = business;
    content['register'] = register;
    content['zipcode'] = `${content['zipcode']}`

    fetchTrackingStore(
      "/users/leads",
      "save-lead",
      {
        content: {
          tracking_id: responseUserAlliance?.data?.tracking_id || tracking?.data?.tracking_id, // generado por el endpoint anterior
          ...content,
        },
      },
      fetchData,
      apiLoadingLeads,
      apiSuccessLeads,
      apiErrorLeads
    );

    actionsRef.current = actions;
    valuesRef.current = values;
  }

  useEffect(() => {
    if (loadingLeads && onSubmitActivated === "inactive" && valuesRef.current) {
      setOnSubmitActivated("pending");
    } else if (!loadingLeads && onSubmitActivated === "pending" && !errorLeads) {
      setOnSubmitActivated("active");
    } else if (errorLeads) {
      dispatch(restartLeadsError());
      setOnSubmitActivated("inactive");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingLeads]);

  useEffect(() => {
    if (leads?.ok && actionsRef.current && valuesRef.current && onSubmitActivated === "active") {

      setOnSubmitActivated("inactive")
      scrollToTop()
      const activeStepNav = membershipTypeModule[membershipType]?.[dataLevelToShow]?.nav;
      const index = activeStepNav.indexOf(activeStep)
      if (index === formSteps) {
        _submitForm(valuesRef.current, actionsRef.current);
      } else {
        setActiveStep(activeStepNav[index + 1]);
        actionsRef.current.setTouched({});
        actionsRef.current.setSubmitting(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmitActivated]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(fieldIsRequired),
    middle_name: Yup.string().required(fieldIsRequired),
    last_name: Yup.string().required(fieldIsRequired),
    gender: Yup.string().required(fieldIsRequired),
    birthdate: Yup.date()
      .required(fieldIsRequired)
      .min(
        new Date(new Date().setFullYear(new Date().getFullYear() - 65)),
        "El rango de edad debe de ser menor de 65 años"
      )
      .max(
        new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        "Debes tener al menos 18 años"
      ),
    zipcode: Yup.string().required(fieldIsRequired),
    // mexicanciticen: Yup.boolean().oneOf(
    //   [true],
    //   "Debes declarar que eres mexicano"
    // ),
  });

  const validationSchemaStep2 = Yup.object().shape({
    phone: Yup.string()
      .phone("MX", "Por favor, ingresa un número de teléfono válido en México")
      .max(10, "maximo 10 caracteres")
      .required(fieldIsRequired),
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]{2,}(\.[a-zA-Z]{2,})+$/,
        "Este correo no es valido"
      ).test('toLoweCase', '', (value: any) => {
        return value?.toLowerCase()
      })
      .required(fieldIsRequired),
    acceptTerms: Yup.boolean().oneOf(
      [true],
      "Debes aceptar los términos y condiciones"
    )
  });

  const validationSchemaStep3 = Yup.object().shape({
    otp: Yup.string().min(6, "Minimo 6 caracteres").required(fieldIsRequired),
  });

  //   const validationSchemaStep4 = Yup.object().shape({
  //     marca: Yup.string().required(fieldIsRequired),
  //     modelo: Yup.string().required(fieldIsRequired),
  //     descripcion: Yup.string().required(fieldIsRequired)
  //   });

  const validationSchemaStepEmpty = Yup.object().shape({});

  const validatorSchemaStepper = () => {
    switch (activeStep) {
      case 0:
        return validationSchema;
      case 1:
        return validationSchemaStep2;
      case 2:
        return validationSchemaStep3;
      case 3:
        return validationSchemaStepEmpty;
      case 4:
        return validationSchemaStepEmpty;
      default:
        throw Error("Verificar que el schema este asignado");
    }
  };

  useEffect(() => {
    if ((leads || sessionStorage?.access_token) && activeStep === 0) {
      setOpenModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = () => {
    if (isLogged) {
      debugger;
      dispatch(restartLogin());
      dispatch(restartBeneficiary());
      dispatch(restartStatus());
      logout(webAuth);
    } else {
      setOpenModal(false);
    }
  };

  const clearFields = (dataToClear: Array<string>) => {
    dataToClear.length > 0 && dataToClear.filter((value) => {
      formikRef.current.setFieldValue(value, "");
    })
  }

  const restartRegister = () => {
    dispatch(restartValue());
    dispatch(restartBrand());
    dispatch(restartModels());
    dispatch(restartLeads());
    dispatch(restartDescription());
    dispatch(restartUser());
    dispatch(restartSignUp());
    clearFields([
      "name",
      "middle_name",
      "last_name",
      "birthdate",
      "mexicanciticen",
      "zipcode",
      "email",
      "phone",
      "acceptTerms",
      "imei",
      "gender",
      "otp"
    ])

    fetchTrackingStore(
      "users/tracking",
      "create-tracking",
      {
        content: {
          data: {},
          ...getBrowserInfo(),
        },
      },
      fetchData,
      loadingTracking,
      successTracking,
      errorTracking
    );
    setOpenModal(false);
  };

  const onClickSecondary = () => {
    restartRegister();
  };

  const getFormikReference = (formik: any) => {
    formikRef.current = formik;
  };

  useEffect(() => {
    setIsLogged(sessionStorage?.access_token ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStorage?.access_token])

  // useEffect(() => {
  //   const stepper = Number(idStepper) || ''
  //   if (stepper && !isNaN(stepper) && stepper <= formSteps && location?.state?.isRestartingProccess) {
  //     setActiveStep(Number(idStepper) - 1);
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const goBack = () => {
    dispatch(restartUserAlliance())
    if(needIdentification){
      navigate('/alianza/empleado')
    }else{
      navigate(`/landing-alliance/${business}`)
    }
  }

  return membershipTypeModule[membershipType] ? (
    <FormWrapper>
      <BasicModal
        styleRoot={{
          maxWidth: 728,
          boxShadow: "none",
          gap: "2px",
          marginTop: "62px",
        }}
        styleContent={{
          margin: 0,
          padding: 0,
        }}
        disableBackdropClick={true}
        closeButton
        open={open}
        setOpenModal={setOpenModal}
        onClick={onClick}
        bodyDialog={
          <CardAction
            onClick={onClick}
            onClickSecondary={onClickSecondary}
            buttonText={isLogged ? "Finalizar sesion" : "Continuar con el registro"}
            buttonTextSecondary="Nuevo registro"
            cardContent={
              isLogged ?
                "Para continuar con un nuevo registro debes de finalizar tu sesion"
                :
                "Contamos con datos ingresados previamente ¿Desea continuar o crear un nuevo registro? Al crear un nuevo registros los datos se perderan"
            }
            imgSrc={ManiPosesBody}
            secundaryButton={!isLogged}
            style={{
              backgroundColor: myColorPalette.getPrimaryColor(),
              border: `1px solid ${myColorPalette.getPrimaryColor()}`,
            }}
            styleTextContent={{
              fontSize: "17px",
            }}
            styleImg={{
              backgroundColor: "#C0E6F2",
              padding: 0,
              display: "flex",
              flexWrap: "wrap",
              alignContent: "flex-end",
            }}
            styleImgCard={{
              padding: 0,
            }}
            styleButton={{
              color: myColorPalette.getPrimaryColor(),
            }}
            styleButtonSecondary={{
              color: myColorPalette.getPrimaryColor(),
            }}
          />
        }
      />

      <CustomButton
        text={"Regresar"}
        onClick={() => {
          const activeStepNav = membershipTypeModule[membershipType]?.[dataLevelToShow]?.nav;
          const index = activeStepNav.indexOf(activeStep)

          index === 0 ? goBack() : handleBack()
        }
        }
        size={"medium"}
        startIcon={<ArrowBack />}
        variant={"contained"}
        style={{
          color: myColorPalette.getPrimaryColor(),
          backgroundColor: "transparent",
          fontSize: "1rem",
          textTransform: "none",
          fontWeight: "600",
          fontStyle: "normal",
          padding: "8px 10px",
          minWidth: "64px",
          border: "none",
          justifyContent: "flex-start",
          width: "fit-content",
        }}
      />
      <FormContainer
        buttonNext={buttonNextHidded}
        steps={membershipTypeModule[membershipType]?.[dataLevelToShow]?.steps}
        initialValues={membershipTypeModule[membershipType]?.[dataLevelToShow]?.initialValues}
        activeStep={activeStep}
        activeDot={membershipTypeModule[membershipType]?.[dataLevelToShow]?.nav}
        setActiveStep={setActiveStep}
        title={membershipTypeModule[membershipType]?.[dataLevelToShow]?.titles}
        renderStepContent={renderStepContent}
        _handleSubmit={_handleSubmit}
        validatorSchemaStepper={validatorSchemaStepper}
        formObserverKey="FormObserver"
        formObserverSlice={apiSuccess}
        localData={response}
        formikRef={getFormikReference}
      />
    </FormWrapper>
  ) : null;
};

export default AllianceRegisterForm;
