import React from 'react';

export const GoogleAuth2Icon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path d="M21.7791 11.2639C21.7791 10.521 21.7072 9.75411 21.5874 9.03516H11.2129V13.277H17.1563C16.9166 14.643 16.1258 15.8413 14.9515 16.6082L18.4983 19.3642C20.5833 17.423 21.7815 14.5951 21.7815 11.2639H21.7791Z" fill="#4280EF"/>
            <path d="M11.2125 21.9999C14.1841 21.9999 16.6765 21.0174 18.4979 19.3398L14.951 16.6078C13.9684 17.2788 12.6983 17.6622 11.2125 17.6622C8.33663 17.6622 5.91614 15.7211 5.02943 13.1328L1.38672 15.9367C3.256 19.6513 7.04251 21.9999 11.2125 21.9999Z" fill="#34A353"/>
            <path d="M5.02737 13.1092C4.57203 11.7432 4.57203 10.2574 5.02737 8.89137L1.38705 6.06348C-0.170684 9.17895 -0.170684 12.8456 1.38705 15.9371L5.02976 13.1092H5.02737Z" fill="#F6B704"/>
            <path d="M11.2125 4.36208C12.7702 4.33812 14.304 4.93725 15.4303 6.01568L18.5698 2.85228C16.5807 0.982991 13.9445 -0.0235469 11.2125 0.000418262C7.04011 0.000418262 3.25361 2.34901 1.38672 6.06361L5.02943 8.89151C5.91614 6.2793 8.33663 4.36208 11.2125 4.36208Z" fill="#E54335"/>
        </svg>
    )
} 
