import React, {useEffect} from 'react';
import { Box } from '@mui/material';
import CustomContainer from '../Container/Container';
import RevolutionContent from './RevolutionContent';
import { scrollToTop } from '../../../utils/scrollToTop';

const Revolution: React.FC = () => {
    
    useEffect(() => {
        scrollToTop();
    }, []);
    
    return (
        <Box sx={{ backgroundColor: '#FFF' }}>
            <CustomContainer childComponent={<RevolutionContent />} />
        </Box>
    )
};

export default Revolution;
