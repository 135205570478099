import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import CelularInfo from './Products/CelularInfo';
import SaludInfo from './Products/SaludInfo';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMore from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles({
    root: {
        boxShadow: 'none',
        '&:before': {
            display: 'none',
        },
        '&:after': {
            content: '""',
            display: 'block',
            width: '100%',
            height: '1px',
            backgroundColor: '#000',
            position: 'absolute',
            bottom: 0,
        },
    },
});

const TabTwoComponent = () => {
    const { breakpoints } = useTheme();
    const classes = useStyles();
    const userResponse = useSelector((state: any) => state?.user?.response);

    const [plans, setPlans] = useState([]);

    const handleTitle = (data: any) => {
        switch (data?.benefit_id) {
            case 'S01':
            case 'S02':
            case 'S03':
                return 'Salud Protect'

            case 'S04':
                return 'Salud Protect Premium'

            case 'C01':
                return 'Celular Protect'

            case 'C02':
                return 'Celular Protect Premium'
            default:
                return ''
        }
    }

    const handleRenders = (data: any, showTitle: boolean) => {
        switch (data?.benefit_id) {
            case 'S01':
            case 'S02':
            case 'S03':
                return <SaludInfo 
                          title={showTitle ? handleTitle(data) : ''} 
                          isPremium={false}
                        />

            case 'S04':
                return <SaludInfo 
                            title={showTitle ? handleTitle(data) : ''} 
                            isPremium={true}
                        />

            case 'C01':
                return <CelularInfo 
                            title={showTitle ? handleTitle(data) : ''} 
                            isPremium={false}
                        />

            case 'C02':
                return <CelularInfo 
                            title={showTitle ? handleTitle(data) : ''}
                            isPremium={true}
                        />
            default:
                break;
        }
    }

    useEffect(() => {
        const { data } = userResponse
        if(data){
            const { plans } = data
            if(plans){
                setPlans(plans)
            }
        }
    },[])



    return <>
        {
            plans.length && plans.length > 1 
                ? <>
                  {plans.map((plan, id)=>{
                    return <Accordion classes={{ root: classes.root }}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls={`panel${id}-content`}
                            id={`panel${id}-header`}
                            style={{padding: '0px'}}
                        >
                            {handleTitle(plan)}
                        </AccordionSummary>
                        <div>
                            {handleRenders(plan, false)}
                        </div>
                    </Accordion>
                  })}    
                </>
                : plans.map((plan)=> handleRenders(plan, true))
        }
    </>
};

export default TabTwoComponent;