import React, { useEffect, useState } from 'react';
import RegistrationForm from '../../organisms/Form';
import CustomContainer from '../../organisms/Container/Container';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PremiumPhoneForm from './PremiumPhone';
import PremiumHealthForm from './PremiumHealth';
import FreemiumHealthForm from './FreemiumHealth'
import ModalUserAlreadyRegistrated from './ModalUserAlreadyRegistrated';
import { themeValues, myColorPalette } from '../../../theme/themeGenerated';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import BASE_CONFIG from '../../../env/env';
import { InterInstance } from '../../../api/config';

const RegisterComponent: React.FC = () => {
    const navigate = useNavigate();
    const { idStepper } = useParams();
    const currentValue = useSelector(
        (state: any) => state.productsCards.currentValue
    ) as any | null;
    const allianceSettingResponse = useSelector((state: any) => state.allianceSetting.response);

    const [clientSecret, setClientSecret] = useState();
    const stipePromise = loadStripe(BASE_CONFIG.stripe.publicKey);

    const fetchSetupIntent = async () => {
        const { data } = await InterInstance.get(`/payments/stripe-setup-intent`);
        setClientSecret(data.data.client_secret);
    }
    useEffect(() => {
        fetchSetupIntent();
    }, [])

    useEffect(() => {
        if (!currentValue?.category) {
            navigate("/");
        } else if (window.location.pathname.includes('finsus') && !allianceSettingResponse?.ok) {
            navigate('/finsus')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (allianceSettingResponse?.ok) {
            if (allianceSettingResponse?.data?.colors?.primary) {
                myColorPalette.setPrimaryColor(allianceSettingResponse?.data?.colors?.primary)
                myColorPalette.setStepperColor(allianceSettingResponse?.data?.colors?.secondary)
                myColorPalette.setOpacity40Color("#FFEFEE")
            }
            if (allianceSettingResponse?.data?.colors?.secondary) myColorPalette.setSecondaryColor(allianceSettingResponse?.data?.colors?.secondary)
        } else {
            myColorPalette.setPrimaryColor('#039ECC')
            myColorPalette.setSecondaryColor('#FFFFFF')
            myColorPalette.setStepperColor('#ffd60e')
            myColorPalette.setOpacity40Color("#F2FAFC")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <ModalUserAlreadyRegistrated />
            {clientSecret && <Elements stripe={stipePromise} options={{
                clientSecret
            }}>
                {
                    currentValue?.category === 'celular' &&
                    currentValue?.subtype === 'freemium' &&
                    <CustomContainer childComponent={<RegistrationForm />} />
                }
                {
                    currentValue?.category === 'celular' &&
                    currentValue?.subtype === 'premium' &&
                    <CustomContainer childComponent={<PremiumPhoneForm />} />
                }
                {
                    currentValue?.category === 'salud' &&
                    currentValue?.subtype === 'freemium' &&
                    <CustomContainer childComponent={<FreemiumHealthForm />} />
                }
                {
                    currentValue?.category === 'salud' &&
                    currentValue?.subtype === 'premium' &&
                    <CustomContainer childComponent={<PremiumHealthForm />} />
                }
            </Elements>}
        </React.Fragment>
    );
};

export default RegisterComponent;
