import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { styled } from 'styled-components';
import { fetchTrackingStore } from "../../../api/fetchingTrackingStore";
import {
	apiSuccess as PriceSuccess,
	apiLoading as PriceLoading,
	apiError as PriceError
} from '../../../api/priceSlice'
import FormContainer from '../../organisms/Form/FormContainer';
import { Form, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SendAddress from './Forms/SendAddress';
import CustomContainer from '../../organisms/Container/Container';
import { OutlinedButton, PrimaryButton } from '../../molecules/CellPhoneRepairButtons';
import CellPhoneDataDetails from './CellPhoneDataDetails';
import { CustomButton } from '../../atoms/Buttons/Buttons';
import { ArrowBack } from '@mui/icons-material';
import { apiSuccess } from '../../../api/formCellPhoneRepairSlice';
import { IStorageMembershipData } from '../../../utils/localStorageKeys';
import { fieldIsRequired } from '../../../utils/scrollToTop';
import { useMediaQuery } from "@mui/material";
import { CustomTypography } from "../../atoms/Label/Label";
import Repair_cost from '../../../utils/repair_cost.json';
import { getStatusRepairPhone, setStatusRepairPhone } from "../../../utils/utils";
import CellData from './Forms/CellData';
import UploadImage from './Forms/UploadImage';
import AccidentDetail from './Forms/AccidentDetail';
import useFetch from '../../../hooks/useFetch';
import { InterInstance } from '../../../api/config';
import { BucketStorageFiles } from '../../../utils/bucket.storage';
import moment from 'moment';

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 558px;
  align-items: center;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 40px;
`;

const ConfirmCellPhoneData: React.FC = () => {
	const navigate = useNavigate();
	const { idStepper } = useParams();
	const matches = useMediaQuery('(min-width:600px)');
	const { fetchData } = useFetch();

	const [activeStep, setActiveStep] = useState<number>(0);
	const response = useSelector((state: any) => state.formCellPhoneRepair.response) as any | null;
	const imageFront = useSelector((state: any) => state.formCellPhoneRepair.front);
	const imageBack = useSelector((state: any) => state.formCellPhoneRepair.back);

	const priceData = useSelector((state: any) => state.price.response) as any | null;
	const userData = JSON.parse(sessionStorage.getItem('user-storage')!);
	let storage: IStorageMembershipData = {
		...useSelector((state: any) => state.productsCards.currentValue),
	};
	const bucket = new BucketStorageFiles('celular-reparacion');

	const userResponse = useSelector((state: any) => state.user.response);

	const getPrice = () => {
		const trakingId: string = userResponse?.data?.user?.tracking_id;
		return fetchData('/gadgets/get-price/', 'GET', {}, {}, PriceLoading, PriceSuccess, PriceError);
	}
	useEffect(() => {

	}, [priceData, imageFront, imageBack]);

	useEffect(() => {
		getPrice();
		window.dataLayer.push({
			'event': 'TRUS_reparacion_confirmar_datos_v',
			tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium",//premium | gratuito
			'correo': userResponse?.data?.user?.email,
			'telefono': userResponse?.data?.user?.phone,
			'page_location': window.location.href,
		});
	}, [])

	useEffect(() => {
		if (idStepper) {
			navigate(`/confirmar-datos/${activeStep + 1}`)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeStep]);

	useEffect(() => {
		const stepper = Number(idStepper) || ''
		if (stepper && !isNaN(stepper) && stepper <= formSteps) {
			setActiveStep(Number(idStepper) - 1);
		}
	}, []);

	const membershipTypeModule = {
		signIn: {
			formSteps: 2, //starting from 0
			steps: ["Paso 1 de 3", "Paso 2 de 3 ", "Paso 3 de 3"], // steps title
			// titles: [getStatusRepairPhone()?.status === 2 ?"Déjanos tu dirección para completar tu solicitud" : ""], // content title
			titles: [
				"Primero, ayúdanos con los detalles del siniestro",
				"Ahora, vamos con tu domicilio",
				"Por último, compártenos tu identificación oficial",
			],
			initialValues: {
				zipCode: '',
				state: '',
				municipality: '',
				colony: '',
				city: '',
				street: '',
				number: '',
				reference: ''
			}
		}
	}
	const formSteps = membershipTypeModule.signIn?.formSteps;

	const renderStepContent = (step: number, errors: any, handleChange: any, handleBlur: any) => {
		switch (step) {
			case 0:
				return (
					<AccidentDetail
						handleChange={handleChange}
						handleBlur={handleBlur}
						setActiveStep={setActiveStep}
						activeStep={activeStep}
						errors={errors}

					/>
				);
			case 1:
				return (
					<SendAddress
						handleChange={handleChange}
						handleBlur={handleBlur}
						errors={errors}
						setActiveStep={setActiveStep}
						activeStep={activeStep}
					/>
				)
			case 2:
				return (
					<UploadImage
						handleChange={handleChange}
						handleBlur={handleBlur}
						setActiveStep={setActiveStep}
						activeStep={activeStep}
						errors={errors}

					/>
				);
			default:
				return null;
		}
	};

	const _submitForm: any = async (values: any, actions: any) => {
		actions.setSubmitting(true);
	};

	const _handleSubmit = async (values: any, actions: any) => {

		if (activeStep === formSteps) {


			navigate('/aviso')
			_submitForm(values, actions);
		} else {
			if (activeStep === 1) {
				setActiveStep(activeStep + 1);
			} else if (activeStep === 2) {
				setActiveStep(activeStep + 1);
			} else if (activeStep === 3) {
				actions.setSubmitting(true);
			} else {
				setActiveStep(activeStep + 1);
				actions.setTouched({});
				actions.setSubmitting(false);
			}
		}

		/*
		if (getStatusRepairPhone() && getStatusRepairPhone().status === 2) {
			setStatusRepairPhone({ status: 3, title: "Estatus de tu reparación" })
			navigate("/recomendacion")
		}*/
	};

	const today = moment().format('YYYY-MM-DD');
	const validationSchemaStep1 = Yup.object().shape({
		date: Yup.date().required("La fecha de accidente es requerida").max(
			today,
			"La fecha no puede ser mayor de hoy"
		),
		description: Yup.string().required(fieldIsRequired)
	});

	const validationSchemaStep2 = Yup.object().shape({
		zipCode: Yup.string().required(fieldIsRequired).matches(/^\d{5}$/, 'El código postal debe ser numerico y valido'),
		state: Yup.string().required(fieldIsRequired),
		municipality: Yup.string().required(fieldIsRequired),
		colony: Yup.string().required(fieldIsRequired),
		city: Yup.string().required(fieldIsRequired),
		street: Yup.string().required(fieldIsRequired),
		number: Yup.number().required(fieldIsRequired),
		reference: Yup.string().max(250, 'No debe ser mayor a 250 caracteres'),
	})
	const validationSchemaStep3 = Yup.object().shape({});

	const validatorSchemaStepper = () => {
		switch (activeStep) {
			case 0:
				return validationSchemaStep1;
			case 1:
				return validationSchemaStep2;
			case 2:
				return validationSchemaStep3;
			default:
				throw Error("Verificar que el schema este asignado");
		}
	}

	const handleBack = () => {
		setActiveStep((prevStep) => prevStep - 1);
	};

	return (
		<CustomContainer childComponent={
			<FormWrapper>
				<div style={{
					display: 'flex',
					alignItems: 'flex-start',
					width: '100%'
				}}>
					<CustomButton
						text={"Regresar"}
						onClick={() => (activeStep === 0 ? navigate(-1) : handleBack())}
						size={"medium"}
						startIcon={<ArrowBack />}
						variant={"contained"}
						style={{
							color: "#039ECC",
							backgroundColor: "transparent",
							fontSize: "1rem",
							textTransform: "none",
							fontWeight: "600",
							fontStyle: "normal",
							padding: "0",
							minWidth: "64px",
							border: "none",
							justifyContent: "flex-start",
							width: "fit-content",
						}}
					/>
				</div>
				<FormContainer
					buttonNext={activeStep === 2 ? false : true}
					steps={membershipTypeModule.signIn.steps}
					initialValues={membershipTypeModule.signIn.initialValues}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					title={membershipTypeModule.signIn.titles}
					renderStepContent={renderStepContent}
					_handleSubmit={_handleSubmit}
					validatorSchemaStepper={validatorSchemaStepper}
					formObserverKey='FormObserverSignIn'
					formObserverSlice={apiSuccess}
					localData={response}
					showTextRequire
				/>
			</FormWrapper>
		} />
	);
};

export default ConfirmCellPhoneData;
