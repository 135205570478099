import React from 'react';
import CustomContainer from '../../organisms/Container/Container';
import { styled } from 'styled-components';
import { CustomTypography } from '../../atoms/Label/Label';
import { Avatar } from '@mui/material';
import CellPhoneRepairMani from "../../../assets/CellphoneRepairMani/CellPhoneRepairMani.svg";
import { 
    OutlinedButton, 
    PrimaryButton 
} from '../../molecules/CellPhoneRepairButtons';
import { ArrowBack } from '@mui/icons-material';
import { CustomButton } from '../../atoms/Buttons/Buttons';
import CardAction from '../../organisms/Card';

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 85px;
  gap: 40px;
  align-items: center;
  max-width: 558px;
`;

const RecomendationComponent: React.FC = () => {

    return (
        <React.Fragment>
            <CustomContainer childComponent={ <ComponentWrapper>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 40, alignItems: 'center' }}>
                <div style={{ 
                    width: '100%', 
                    alignItems: 'flex-end', 
                    textAlign: 'left' 
                }}>
                    <CustomButton
                        text={"Regresar"}
                        onClick={() => {}}
                        size={"medium"}
                        startIcon={<ArrowBack />}
                        variant={"contained"}
                        style={{
                        color: "#039ECC",
                        backgroundColor: "transparent",
                        fontSize: "1rem",
                        textTransform: "none",
                        fontWeight: "600",
                        fontStyle: "normal",
                        padding: "8px 10px",
                        minWidth: "64px",
                        border: "none",
                        justifyContent: "flex-start",
                        width: "fit-content",
                        }}
                    />
                </div>
                    <Avatar 
                        style={{
                            maxWidth: 327,
                            width: 'auto',
                            height: 'auto'
                        }}
                        alt="Remy Sharp" 
                        src={CellPhoneRepairMani} 
                        variant="square"
                    />
                    <CustomTypography 
                        item={"El costo de reparación de tu pantalla rebasa el límite de la cobertura gratuita, tendrías que pagar $3,000 pesos extras para poder realizarla, ¿quieres seguir con tu solicitud?"} 
                        style={{ 
                            fontSize: 22,
                            textAlign: 'center', 
                            fontWeight: 400,
                            fontStyle: 'normal',
                            lineHeight: 'normal', 
                            textTransform: 'inherit',
                            letterSpacing: '0.5px'
                        }} 
                    />
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: 40
                    }}>
                        <PrimaryButton text={"Solicitar reparación"} onClick={() => {}} />
                        <OutlinedButton text={"No me interesa"} onClick={() => {}} />
                    </div>
                    <CardAction 
                        visibleImg
                        cardContent="Sí te conviertes a premium ahora, incrementa tu nivel de cobertura a $10,000 pesos y accede a más beneficios por sólo $90 pesos al mes"
                        cardType={"premium"}
                        subType={ "premium"}
                        cardCategory={"celular"}
                        cardDescription={"Regístrate gratis y protege tu dispositivo en caso de que se te llegue a romper la pantalla hasta por $4,000 pesos, además tendrás acceso a un club de descuentos en entretenimiento, salud moda, restaurantes, acceso a Telemedicina gratuita ilimitada y mucho más."} 
                        cardTitle={"Seguro Inter Celular"} 
                    />
                </div>
            </ComponentWrapper> } />
        </React.Fragment>
    );
};

export default RecomendationComponent;
