import styled from 'styled-components';
import React from 'react';
import { useTheme, useMediaQuery, Grid, List, ListItem, ListItemText, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { IUserData } from '../interfaces/user';
import { MembershipCategoryType, MembershipColor, MembershipSubType, MembershipType } from '../../Membership/MembershipMainValues';
//Images
import logo from '../assets/logo.svg';
import CategoryLabel from '../../../molecules/LabelProduct/LabelProduct';
import { generatedVigenceDate } from '../../../../utils/generateVigenceDate';
import { CapitalizeText, getFullName } from '../../../../utils/utils';

import cardLines from '../assets/cardLinesBG.png'
import cardWaves from '../assets/cardLeftWave.png';
import ButtonInter from '../../../atoms/Buttons/ButtonInter';

const CardContainer = styled.div`
    width: 99%;
    height: auto;
    border-radius: 10px;
    overflow: hidden;
    position:relative;
    box-sizing: border-box;
    max-width: 550px;
`;

const CardUserMain = styled.div`
    padding:'5px';
   
`;
const CardBackground = styled.img`
    width: 100%;
    height: 100%;
    position: absolute; 
    display: flex;
    align-items: center;
    justify-content: center;
    mix-blend-mode: multiply;
    opacity: 0.5;
`;
const CardWaveBackgroud = styled.img`
    width: 100%;
    height: 100%;
    position: absolute; 
    display: flex;
    align-items: center;
    justify-content: center;
    transform: st;
    mix-blend-mode: multiply;
    opacity: 0.1;
    filter: grayscale(100%);
`

const CardUserHeader = styled.div`
    display: flex;
    flex-direction: column;
    padding:5px;
`;

const CardUserBody = styled.div`

`;

const CardUserFooter = styled.div`
`;

const CardUserInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin:10px;
    color:white;
    > div > p {
        font-size: 12px;
        margin: 3px 0;
        padding: 5px;
        box-sizing: border-box;
        > span {
            font-weight: 700;
        }
    }
`;


interface CardComponentInterface {
    category: MembershipCategoryType;
    type: MembershipType;
    subtype: MembershipSubType;
    certificate: string;
    createdAt: string;
    isValidNumber: boolean;
    handleClick?: () => void;
    isLoading: boolean;
}

const CardUserComponent: React.FC<CardComponentInterface> = ({
    category,
    type,
    subtype,
    certificate,
    createdAt,
    handleClick,
    isValidNumber,
    isLoading
}) => {
    const userResponse: IUserData = useSelector((state: any) => state.user.response.data);
    //const userData = JSON.parse(sessionStorage.getItem('user-storage')!);
    let showPremium: boolean = type === 'free' && isValidNumber && !isLoading ? isValidNumber : false;
    if (category === "salud" && type === "free") {
        showPremium = true;
    }
    const { breakpoints } = useTheme();
    //breakpoints
    const isDesktop = useMediaQuery(breakpoints.up('lg'));
    const isTablet = useMediaQuery(breakpoints.down('md'));
    const isMobile = useMediaQuery(breakpoints.down('sm'));

    const LogoMaxWidth: number = isMobile ? 80 : 90;
    //Color
    const ColorMain = type === 'free' ? MembershipColor.free : MembershipColor.premium;
    const ColorSecondary = type === 'free' ? MembershipColor.freeSecondary : MembershipColor.premiumSecondary;
    const ColorOpacity = type === 'free' ? MembershipColor.freeOpacity : MembershipColor.premiumOpacity;
    
    return (userResponse && <CardContainer>

        <CardUserMain style={{
            backgroundColor: ColorMain
        }}>
            <CardBackground src={cardLines} />
            {
                type === "free" && <CardWaveBackgroud src={cardWaves} />
            }
            <CardUserHeader>
                <Grid style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    padding: '2px'
                }}>
                    <Grid className='cardLeftImages'>
                        <img className='logo' src={logo} alt="" width={LogoMaxWidth} />
                        {
                            (userResponse.business && userResponse.business.imagenes.sublogo_white) && 
                                <img className='logoCompany' src={userResponse.business.imagenes.sublogo_white} width={LogoMaxWidth} height={'35px'}/>
                        }
                    </Grid>
                    <Grid className='cardRigthImages'>
                        <CategoryLabel type={type} category={category} size={isMobile ? 50 : 70} />
                    </Grid>
                </Grid>
                <hr style={{ width: '100%', backgroundColor: 'white', border: 'none', height: '2px', margin: '0px', padding: '0px' }} />
            </CardUserHeader>
            <CardUserBody style={{}}>
                <CardUserInfo>
                    <div>
                        <p>
                            <span>No.Certificado: </span> {certificate}
                        </p>
                    </div>
                    <div>
                        <p>
                            <span>Titular: </span>
                            {getFullName(userResponse.user.name, userResponse.user.middle_name, userResponse.user.last_name)}
                        </p>
                    </div>
                    <div>
                        <p>
                            <span>Plan: </span> {type === 'free' ? "De Regalo" : "Premium"}
                        </p>
                    </div>
                    <div>
                        <p>
                            <span> Vigencia: </span> {generatedVigenceDate(createdAt)}
                        </p>
                    </div>
                    <code style={{
                        display: 'none',
                        position: 'absolute',
                        right: '20px',
                        background: 'white',
                        opacity: '0.7',
                        color: 'black',
                        padding: '10px'
                    }}>
                        <div>
                            isMobile: <strong>{isMobile.toString()}</strong>
                            <br />
                            isTablet: <strong>{isTablet.toString()}</strong>
                            <br />
                            isDesktop: <strong>{isDesktop.toString()}</strong>
                        </div>
                    </code>

                </CardUserInfo>
            </CardUserBody>
            <CardUserFooter>
                {showPremium ? <Button style={{
                    background: MembershipColor.premium,
                    color: 'white',
                    width: '100%',
                    fontSize: isMobile ? '17px' : '20px',
                    borderRadius: '0px'
                }}
                    onClick={() => {
                        if (handleClick) {
                            handleClick();
                        } else {
                            console.error("No esta configurado el click de este boton");
                            alert("Error: unknow")
                        }
                    }}
                    disabled={!handleClick}
                >
                    Quiero ser premium
                </Button> :
                    <Grid style={{ height: '20px' }}>
                    </Grid>}
            </CardUserFooter>
        </CardUserMain>
    </CardContainer >)
}

export default CardUserComponent;