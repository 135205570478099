import React, { useEffect, useState } from 'react';
import NewProductRegisterTemplate from '../../components/templates/NewProductRegister';
import CustomContainer from '../../components/organisms/Container/Container';
import { loadStripe } from '@stripe/stripe-js';
import BASE_CONFIG from '../../env/env';
import { InterInstance } from '../../api/config';
import { Elements } from '@stripe/react-stripe-js';

type NewProductRegisterProps = {};

const NewProductRegister: React.FC<NewProductRegisterProps> = () => {
  const [clientSecret, setClientSecret] = useState();
  const stipePromise = loadStripe(BASE_CONFIG.stripe.publicKey);

  const fetchSetupIntent = async () => {
    const { data } = await InterInstance.get(`/payments/stripe-setup-intent`);
    const value = data.data.client_secret
    setClientSecret(value);
  }

  useEffect(() => {
    fetchSetupIntent();
  }, [])

  return <CustomContainer childComponent={<>
    {
      clientSecret && <Elements stripe={stipePromise} options={{
        clientSecret
      }} >
        <NewProductRegisterTemplate />
      </Elements>
    }

  </>} />;
};

export default NewProductRegister;
