import React from 'react';
import CustomContainer from "../../components/organisms/Container/Container"
import Iframe from '../../components/templates/Telemedicina/Iframe';

const IframePage: React.FC = () => {
  return (
    <React.Fragment>
      <CustomContainer childComponent={<Iframe />}></CustomContainer>
    </React.Fragment>
  );
};

export default IframePage;
