import React from "react";
import CustomContainer from "../../organisms/Container/Container";
import {useNavigate} from 'react-router-dom';
import {CustomTypography} from "../../atoms/Label/Label";
import {styled} from "styled-components";
import ManiErrorLogin from '../../../assets/ManiPosesGuyStadistics/ManiErrorLogin.png';
import {Avatar, Box} from "@mui/material";
import {PrimaryButton} from "../../molecules/CellPhoneRepairButtons";
import {OutlinedButton} from '../../molecules/CellPhoneRepairButtons'

const LayOutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 558px;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 80px;
`;
const ErrorLogin: React.FC = () => {
    const navigate = useNavigate();
    return (
        <CustomContainer childComponent={
            <LayOutContainer>

                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        // marginTop: 3,
                        marginBottom: 3,
                        justifyContent: 'center',
                    }}
                >
                    <Avatar
                        sx={{
                            maxWidth: 250,
                            width: '100%',
                            height: 'auto',
                            margin: {xs: 0, lg: '20px'},
                        }}
                        alt='ManiErrorLogin'
                        src={ManiErrorLogin}
                        variant='square'
                    />
                </Box>
                <CustomTypography
                    style={{
                        fontSize: 28,
                        fontWeight: 700,
                        letterSpacing: "0.5px",
                        textAlign: "center",
                        Padding: "0 1rem",
                        textWrap: "balance",
                    }}
                >
                    {"¡Oops! Esta cuenta no esta registrada"}
                </CustomTypography>
                <CustomTypography
                    style={{
                        fontSize: 20,
                        fontWeight: 400,
                        fontStyle: "normal",
                        letterSpacing: "0.5px",
                        textAlign: "center",
                        Padding: "0 1rem",
                        textWrap: "balance",
                    }}
                >
                    {"Asegúrate de que hayas ingresado los datos correctos y si aún no estas registrado...\n¿Qué estás esperando?"}
                </CustomTypography>

                <PrimaryButton text={"ENTENDIDO"} onClick={() => {
                    navigate(-1)
                }}/>

                <OutlinedButton text={"REGISTRARME"} onClick={() => {
                    navigate("/registrate/1")
                }}/>
            </LayOutContainer>
        }/>
    );
};
export default ErrorLogin;
