import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./MembershipCard.css";
import MembershipItemComponent from "./MemberShipItem/MembershipItem";
import { BenefitsDetails } from "./MemberShipItem/MembershipItem";
import {
  MembershipColor,
  MembershipSubType,
  MembershipType,
} from "../MembershipMainValues";
import { IStorageMembershipData } from "../../../../utils/localStorageKeys";
import TotalCard, {
  TotalCardItem,
} from "../../../molecules/TotalCard/TotalCard";
import MembershipButton from "../MembershipCoverage/MembershipDetailsCards/MembershipButtons";
import { setCurrentValue } from "../../../../api/productsSlice";
import BorderLinePremium from "../../../../assets/BorderLine/borderLinePremium";
import { CustomTypography } from "../../../atoms/Label/Label";
import { myColorPalette } from "../../../../theme/themeGenerated";

//Cambiar nombre
interface MembershipItem {
  benefits: BenefitsDetails[];
  extraBenefis: BenefitsDetails[];
  type: MembershipType;
  interName: string;
  interPrice: string;
  total: string;
}

type MembershipSummaryCardProps = {
  onlyCardPresentation?: boolean;
};

const MembershipSummaryCard: React.FC<MembershipSummaryCardProps> = ({
  onlyCardPresentation,
}) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const isTablet = useMediaQuery(breakpoints.down("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storage: IStorageMembershipData = {
    ...useSelector((state: any) => state.productsCards.currentValue),
  };
  const current = useSelector((state: any) => state.productsCards.currentValue);
  const gadgets = useSelector((state: any) => state.gadgets.response);
  const health = useSelector((state: any) => state.health.response);
  const formStorage = {
    ...useSelector((state: any) => state.formRegister.response),
  };
  const type: MembershipType = storage ? storage.type : "free";
  const subType: MembershipSubType =
    storage && storage.subtype ? storage.subtype : "freemium";
  const location = useLocation();
  const [ma, setMA] = useState<any>();
  let title: string = "Resumen";
//   let sublabel: string = "";
  let [price, setPrice] = useState<number>(0);
  let [benefits, setBenefits] = useState<BenefitsDetails[]>([]);
  let [extra, setExtraBenefits] = useState<BenefitsDetails[]>([]);
  let [sublabel, setSubLabel] = useState<string>("");
  let MembershipSummaryInfo: MembershipItem = {
    type: storage ? storage.type : "free",
    interName: sublabel,
    interPrice: price + ".00",
    total: price + ".00",
    benefits: benefits,
    extraBenefis: extra,
  };

  const cleanBenefitIdsThatAreNotInTheProccess = (category: any, subType: string) => {
    /**
     * El siguiente codigo genera un nuevo objeto para los datos de health
     * para filtrar el tipo benefit_id === S00 ya que es un dato que nos devuelve 
     * BE y no debe de ir en el proceso, esto se hizo para no modificar el componente 
     * que genera los beneficios.
     */
    const newHealth = category?.data[subType].benefits;
    const benefits = newHealth.filter((b: any) => b.benefit_id !== "S00");
    const hash = { health: {...category?.data[subType]} };

    hash.health['benefits'] = [...benefits];

    return hash.health.benefits;
  }

  useEffect(() => {
    let sublabelText: string = "";
    
    if (storage?.category === "salud" && health) {
        sublabelText += " Salud Protect";
    
      if (storage.type === "premium") {
        sublabelText += " " + storage?.subtype;
      }

      setSubLabel(sublabelText);
      const mybenefitsFiltered = cleanBenefitIdsThatAreNotInTheProccess(health, storage?.subtype);
      setBenefits(mybenefitsFiltered);
      setExtraBenefits(health?.data[storage?.subtype].extra);
      setPrice(health?.data[storage?.subtype].price);
    }

    if (storage?.category === "celular" && gadgets) {
      sublabelText += "Celular Protect";

      if (storage.type === "premium") {
        sublabelText += " " + storage?.subtype;
      }
      setSubLabel(sublabelText);
      const mybenefitsFiltered = cleanBenefitIdsThatAreNotInTheProccess(gadgets, storage?.subtype);
      setBenefits(mybenefitsFiltered);
      setExtraBenefits(gadgets?.data[storage?.subtype].extra);
      setPrice(gadgets?.data[storage?.subtype].price);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gadgets?.data, health?.data]);

  if (
    formStorage &&
    formStorage.picked &&
    formStorage.picked !== "" &&
    type === "free" &&
    storage.category === "salud"
  ) {
    if (benefits != null)
      benefits = benefits.filter((e) => e.title === formStorage.picked);
  }

  const items: TotalCardItem[] = [
    {
      label:
        subType === "premium"
          ? storage?.category === "salud"
            ? "Salud Protect Premium"
            : "Celular Protect Premium"
          : storage?.category === "salud"
          ? "Salud Protect"
          : "Celular Protect",
      price: price,
      sublabel: subType === "premium" ? "al mes" : "",
    },
  ];

  MembershipSummaryInfo = {
    type: storage ? storage.type : "free",
    interName: sublabel,
    interPrice: price + ".00",
    total: price + ".00",
    benefits: benefits,
    extraBenefis: extra,
  };

  const renderBenefits = (array: BenefitsDetails[]) => {
    if (onlyCardPresentation) {
      return array.map((benefit, index) => {
        if (current.benefit_id === benefit.benefit_id) {
          return (
            <MembershipItemComponent
              key={index}
              title={benefit.title}
              description={benefit.description}
            />
          );
        } else if (!benefit.benefit_id) {
          return (
            <MembershipItemComponent
              key={index}
              title={benefit.title}
              description={benefit.description}
            />
          );
        } else if (
          benefit?.title === "Muerte accidental" &&
          current?.category === "salud"
        ) {
          return (
            <MembershipItemComponent
              key={index}
              title={benefit.title}
              description={benefit.description}
            />
          );
        } else {
          return <></>;
        }
      });
    }
    return array.map((benefit, index) => {
      return (
        <MembershipItemComponent
          key={index}
          title={benefit.title}
          description={benefit.description}
        />
      );
    });
  };
  const userData = JSON.parse(sessionStorage.getItem("user-storage")!);
  const allianceSettingResponse = useSelector((state: any) => state.allianceSetting.response);

  React.useEffect(() => {
    if (window.location.pathname !== "/portal-del-cliente/registro-nuevo-producto"){
      window.dataLayer.push({
        event: "TRUS_resumen_v",
        correo: userData?.correo,
        telefono: userData?.telefono,
        page_location: window.location.href,
        total_pagar: price,
        tipo_seguro: storage.type === "free" ? "De regalo" : "Premium",
        seguro:
        storage?.category === "celular" ? "Celular Protect" : "Salud Protect",
        alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickHandlerModule = (value: MembershipType) => {
    const sendDataredux = {
      type: value,
      category: storage.category,
      subtype: value === "premium" ? "premium" : "freemium",
    };
    dispatch(setCurrentValue(sendDataredux));
  };

  useEffect(() => {
    if (gadgets?.data?.freemium?.benefits) {
      const MA = gadgets?.data.freemium.benefits.filter(
        (b: any) => b.benefit_id === "C01" && b.title === "Muerte accidental"
      );

      setMA(MA);
    }
  }, [gadgets]);

  return (
    <Box sx={{
      maxWidth: '946px',
      margin: 'auto'
    }}>
      <Box padding={0}>
        {!onlyCardPresentation && (
          <div className="buttonsSuperior">
            <button
              style={{
                padding: '40px 0'
              }}
              onClick={() => {
                navigate("/" + location.state.path);
              }}
            >
              <ArrowBackIcon /> Regresar
            </button>
          </div>
        )}

        {!onlyCardPresentation && false && (
          <div style={{ width: "80%" }}>
            {!isMobile ? (
              <p
                className="title"
                style={{ fontSize: "28px", textAlign: "left" }}
              >
                <strong>{title}</strong>
              </p>
            ) : (
              ""
            )}
          </div>
        )}

        <div
          className="MembershipConteiner"
          style={onlyCardPresentation ? { flexDirection: isMobile || isTablet ? "column" : "row", alignItems: isMobile || isTablet ? 'center' : '' } : {marginBottom: 50}}
        >
          <div
            className="MembershipCard"
            style={
              type === "free"
                ? { border: "1px solid " + myColorPalette.getPrimaryColor() }
                : { border: "1px solid " + MembershipColor.premium }
            }
          >
            {onlyCardPresentation && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  padding: "25px 0px 0px 0px",
                }}
              >
                <p
                  style={{
                    fontSize: "24px",
                  }}
                >
                  <strong>{title}</strong>
                </p>
              </div>
            )}
            {!onlyCardPresentation && (
              <div
                className="header"
                style={{ display: "none" }}
              >
                <p
                  className="title"
                  style={{
                    fontSize: "24px",
                    margin: "10px auto 10px auto",
                  }}
                >
                  <strong>{title}</strong>
                </p>
              </div>
            )}
            {!onlyCardPresentation && (
              <div
                style={{
                  display: "flex",
                  padding: "40px 25px"
                }}
              >
                <MembershipButton
                  type={type}
                  tabHidden={location?.state?.tabHidden ? true : false}
                  onClickSubType={onClickHandlerModule}
                />
              </div>
            )}
            <div>
              {subType === "premium" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BorderLinePremium isDesktop={!isMobile && !isTablet ? true : false} />
                  <CustomTypography
                    style={{
                      color: "var(--Black, #212121)",
                      fontSize: "22px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      padding: 24,
                      display: "flex",
                      textAlign: "center",
                    }}
                  >
                    <span>
                      Por <b> ${price} pesos al mes</b> obtendrás:
                    </span>
                  </CustomTypography>
                </div>
              )}
            </div>
            <div className="body">
              <div className="benefits">
                <div
                  className="labelBenefit"
                  style={
                    type === "free"
                      ? { background: window.location.pathname.includes('finsus') ? myColorPalette.getPrimaryColor() : '#C0E6F2', color: window.location.pathname.includes('finsus') ? '#fff' : '#000' }
                      : { background: MembershipColor.premiumSecondary }
                  }
                >
                  <p
                    style={
                      isMobile ? { textAlign: "center", margin: '25px 0' } : { textAlign: "left", margin: '25px 0' }
                    }
                  >
                    <strong>Coberturas incluidas</strong>
                  </p>
                </div>
                <div className="benefit" style={{
                      gap: 24,
                      display: 'flex',
                      flexDirection: 'column'
                }}>{renderBenefits(benefits)}</div>
                {ma &&
                  ma?.length > 0 &&
                  current?.category === "salud" &&
                  current?.type === "free" && (
                    <React.Fragment>
                      <div className="benefit" style={{
                      gap: 40,
                      display: 'flex',
                      fontSize: "20px",
                      flexDirection: 'column'
                }}>
                        {renderBenefits(ma)}
                      </div>
                    </React.Fragment>
                  )}
                <div
                  className="labelBenefit"
                  style={
                    type === "free"
                      ? { background: window.location.pathname.includes('finsus') ? myColorPalette.getPrimaryColor() : '#C0E6F2', color: window.location.pathname.includes('finsus') ? '#fff' : '#000' }
                      : { background: MembershipColor.premiumSecondary }
                  }
                >
                  <p
                    style={
                      isMobile ? { textAlign: "center", margin: '25px 0' } : { textAlign: "left", margin: '25px 0' }
                    }
                  >
                    <strong>Beneficios adicionales</strong>
                  </p>
                </div>
                <div className="extraBenefit" style={{
                      gap: 24,
                      display: 'flex',
                      flexDirection: 'column',
                      fontSize: "20px", 
                      paddingBottom: '24px'
                }}>
                  {renderBenefits(MembershipSummaryInfo.extraBenefis)}
                </div>
              </div>
            </div>
            <div
              className="footer"
              style={
                isMobile || isTablet
                  ? { display: "flex" }
                  : { display: "none" }
              }
            >
              <div
                className="BlueDetail"
                style={
                  type === "free"
                    ? {
                        backgroundColor: myColorPalette.getOpacity40Color(),
                        borderTop: myColorPalette.getPrimaryColor() + " 1px solid",
                      }
                    : {
                        backgroundColor: 'rgba(233, 192, 88, 0.05)',
                        borderTop: MembershipColor.premium + " 1px solid",
                      }
                }
              >
                {subType === "premium" && (
                  <React.Fragment>
                    <CustomTypography style={{
                      background: '#FAD882',
                      display: 'flex',
                      flexDirection: 'column',
                      padding: 4,
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>  
                        <CustomTypography style={{ fontSize: '18px' }}>Primer mes de regalo</CustomTypography> 
                        <CustomTypography style={{
                            fontWeight: 400, 
                            fontSize: '18px'
                        }}>a partir del segundo pagarás:</CustomTypography>
                    </CustomTypography>
                  </React.Fragment>
                )}
                <div className="ItemFooter">
                  <p>{MembershipSummaryInfo.interName}</p>
                  <p style={{ textAlign: "end", paddingRight: 0 }}>
                    <strong> ${price}.00</strong>
                    {subType === "premium" && <p>al mes</p>}
                  </p>
                </div>
                <hr></hr>
                <div className="ItemFooter">
                  {subType === "freemium" ? (
                    <>
                      <p>Total a pagar:</p>
                      <p>
                        <strong>${MembershipSummaryInfo.total}</strong>
                      </p>
                    </>
                  ) : (
                    <p>
                      El cobros se realizará de forma recurrente y podrás
                      cancelarlo en cualquier momento.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          
            <div className="ElementTotal" style={{ maxWidth: '332px' }}>
              {isMobile === false && isTablet === false ? (
                <React.Fragment>
                  <TotalCard
                    totalPymentVisibility
                    tatalPaymentPrice={{
                      label:
                        subType === "premium"
                          ? "El cobro se realizará de forma recurrente y podrás cancelarlo en cualquier momento."
                          : "Total a pagar",
                      total: subType === "freemium" ? price + ".00" : "",
                      subLabel: subType === "premium" ? "" : "",
                    }}
                    items={items}
                    key={"totalCard"}
                    style={{
                      root: {
                        display: "flex",
                        padding: 16,
                        flexDirection: "column",
                        gap: 24,
                        alignSelf: "stretch",
                        borderRadius: 8,
                        border:
                          subType === "premium" ? "1px solid #E9C058" : "none",
                        backgroundColor:
                          subType === "premium"
                            ? "rgba(233, 192, 88, 0.05)"
                            : myColorPalette.getOpacity40Color(),
                      },
                      itemsCard: {
                        gap: 24
                      },
                      totalInfo: {
                        margin: 0,
                        color: "var(--Black, #212121)",
                        fontSize: 16,
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                        gap: 4,
                      },
                    }}
                  ></TotalCard>
                  <div
                    className="buttonConteiner"
                    style={{
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                  >
                    <button
                      className="buttonContinue"
                      style={{
                        backgroundColor: myColorPalette.getPrimaryColor(),
                        color: '#ffffff'
                      }}
                      onClick={() => {
                        if(!onlyCardPresentation){
                          if (subType === "freemium") {
                            window.dataLayer.push({
                              event: "TRUS_miseguro_freemium",
                              seguro:
                                storage.category == "celular"
                                  ? "Celular Protect"
                                  : "Salud Protect",
                              tipo_seguro: "De regalo",
                              telefono: userData?.telefono,
                              correo: userData?.correo,
                              boton_contacto: "Activar mi seguro",
                              alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
                            });
                            navigate("/crear-cuenta", {
                              state: { path: location.state.path },
                            });
                          } else {
                            window.dataLayer.push({
                              event: "TRUS_miseguro_premium",
                              seguro:
                                storage.category == "celular"
                                  ? "Celular Protect"
                                  : "Salud Protect",
                              tipo_seguro: "De regalo",
                              telefono: userData?.telefono,
                              correo: userData?.correo,
                              boton_contacto: "Quiero mi seguro",
                              alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
                            });
                            navigate("/contratacion", {
                              state: { path: location.state.path },
                            });
                          }
                        } else if (onlyCardPresentation){
                          if (subType === "freemium") {
                            window.dataLayer.push({
                              event: "TRUS_miseguro_freemium",
                              seguro:
                                storage.category == "celular"
                                  ? "Celular Protect"
                                  : "Salud Protect",
                              tipo_seguro: "De regalo",
                              telefono: userData?.telefono,
                              correo: userData?.correo,
                              boton_contacto: "Continuar",
                              alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
                            });
                          } else {
                            window.dataLayer.push({
                              event: "TRUS_miseguro_premium",
                              seguro:
                                storage.category == "celular"
                                  ? "Celular Protect"
                                  : "Salud Protect",
                              tipo_seguro: "De regalo",
                              telefono: userData?.telefono,
                              correo: userData?.correo,
                              boton_contacto: "Quiero mi seguro",
                              alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
                            })
                          }
                        }
                      }}
                    >
                      {onlyCardPresentation ? 
                        "CONTINUAR"
                      :
                      subType === "premium"
                        ? "QUIERO MI SEGURO"
                        : "ACTIVAR MI SEGURO"}
                    </button>
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}
            </div>
            {!onlyCardPresentation && (isMobile || isTablet) && <div
              className="buttonConteiner"
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                width: '100%'
              }}
            >
              <button
                className="buttonContinue"
                onClick={() => {
                  if(!onlyCardPresentation){
                    if (subType === "freemium") {
                      window.dataLayer.push({
                        event: "TRUS_miseguro_freemium",
                        seguro:
                          storage.category == "celular"
                            ? "Celular Protect"
                            : "Salud Protect",
                        tipo_seguro: "De regalo",
                        telefono: userData?.telefono,
                        correo: userData?.correo,
                        boton_contacto: "Activar mi seguro",
                      });
                      navigate("/crear-cuenta", {
                        state: { path: location.state.path },
                      });
                    } else {
                      window.dataLayer.push({
                        event: "TRUS_miseguro_premium",
                        seguro:
                          storage.category == "celular"
                            ? "Celular Protect"
                            : "Salud Protect",
                        tipo_seguro: "De regalo",
                        telefono: userData?.telefono,
                        correo: userData?.correo,
                        boton_contacto: "Quiero mi seguro",
                      });
                      navigate("/contratacion", {
                        state: { path: location.state.path },
                      });
                    }
                  }
                }}
              >
                {onlyCardPresentation ? 
                  "CONTINUAR"
                :
                subType === "premium"
                  ? "QUIERO MI SEGURO"
                  : "ACTIVAR MI SEGURO"}
              </button>
            </div>}
        </div>
      </Box>
    </Box>
  );
};

export default MembershipSummaryCard;
