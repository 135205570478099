import React, { useEffect, useState } from 'react';
import CustomContainer from "../../components/organisms/Container/Container"

import MembershipSummaryCard from '../../components/organisms/Membership/MembershipCard/MembershipCard';
import { useLocation, useNavigate } from 'react-router-dom';
import CardAction from '../../components/organisms/Card';
import BasicModal from '../../components/molecules/BasicModal/BasicModal';
import Avatar from '../../assets/ManiAproveit/banner.png';
import { useSelector } from "react-redux";
import { scrollToTop } from '../../utils/scrollToTop';
import { IStorageMembershipData } from '../../utils/localStorageKeys';
import MembershipItemComponent from '../../components/organisms/Membership/MembershipCard/MemberShipItem/MembershipItem';
import { BenefitsDetails } from '../../components/organisms/Membership/MembershipCard/MemberShipItem/MembershipItem';
import { MembershipColor, MembershipDataCelular, MembershipDataSalud, MembershipSubType, MembershipType } from '../../components/organisms/Membership/MembershipMainValues';


interface MembershipSummaryProps { }
interface MembershipItem {
    benefits: BenefitsDetails[],
    extraBenefis: BenefitsDetails[]
    type: MembershipType;
    interName: string;
    interPrice: string;
    total: string;
}

const MemberShipSummary: React.FC<MembershipSummaryProps> = () => {
  // Implementa el componente aquí
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpenModal] = useState<boolean>(true);
  const currentValue = useSelector((state: any) => state.productsCards.currentValue) as any | null;
  const storage: IStorageMembershipData = { ...useSelector((state: any) => state.productsCards.currentValue) }
  const current = useSelector((state: any) => state.productsCards.currentValue)
  const gadgets = useSelector((state: any) => state.gadgets.response)
  const formStorage = { ...useSelector((state: any) => state.formRegister.response) };
  const type: MembershipType = storage ? storage.type : 'free';
  const subType: MembershipSubType = storage && storage.subtype ? storage.subtype : 'freemium';



  let sublabel: string = '';
  let benefits: BenefitsDetails[] = [];
  let extra: BenefitsDetails[] = [];
  let price: number = 0;

  if (storage?.category === 'salud') {
        sublabel += ' Salud Protect';
        if (storage.type === 'premium') {
            sublabel += ' ' + subType;
        }
        benefits = MembershipDataSalud['premium'].benefits;
        extra = MembershipDataSalud['premium'].extra;
        price = MembershipDataSalud['premium'].price;
    }

    if (storage?.category === 'celular') {
        sublabel += 'Celular Protect';
        // benefits = MembershipDataCelular[subType].benefits;
        benefits = gadgets?.data['premium'].benefits || [];
        // extra = MembershipDataCelular[subType].extra;
        extra = gadgets?.data['premium'].extra;
        // price = MembershipDataCelular[subType].price;
        price = gadgets?.data['premium'].price;
    }

    if (storage?.type === 'premium') {
        sublabel += ' Premium'
    }
  
  useEffect(() => {
    scrollToTop();
    // if(!location?.state?.path){
    //   navigate('/registrate', { replace: true })
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const MembershipSummaryInfo: MembershipItem = {
        type: storage ? storage.type : 'free',
        interName: sublabel,
        interPrice: price + '.00',
        total: price + '.00',
        benefits: benefits,
        extraBenefis: extra
    }
  const renderBenefits = (array: BenefitsDetails[]) => {
        return array.map((benefit, index) => {
          if(benefit.title === 'Robo de celular' || benefit.title === 'Descuentos Inter.mx'){
            return <MembershipItemComponent key={index} title={benefit.title} description={benefit.description} />
          }
        })
    }

const userData = JSON.parse(sessionStorage.getItem('user-storage')!);

// React.useEffect(() => {
//     window.dataLayer.push({ 
//         'event': 'TRUS_reparacion_estatus_v',
//         'tipo_seguro': storage.type == "free" ? "De regalo" : "Premium",//premium | gratuito 
//         'correo': userData?.correo, 
//         'telefono': userData?.telefono,
//         'page_location': window.location.href
//        });
       
// }, [])

  // useEffect(() => {
  //   if (!open){
  //     window.dataLayer.push({
  //       event: "TRUS_quiero_premium",
  //       boton_contacto: 'Cerrar',
  //       page_url: window.location.href 
  //   });     
  //   }
  // }, [open])


  return (
    <React.Fragment>
      <CustomContainer childComponent={<MembershipSummaryCard />}></CustomContainer>
    </React.Fragment>
  );
};

export default MemberShipSummary;
