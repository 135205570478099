import React from "react";

interface xForElonMusk {
    style?: React.CSSProperties;
}

const XforelonMusk: React.FC<xForElonMusk> = (props) => {
    return (




        <svg
            style={props.style}

            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="20"
            fill="none"
            viewBox="0 0 26 20"
        >
            <g>
                <path
                    fill="#fff"
                    d="M.975 0l9.495 11.031L.915 20h2.15l8.367-7.852L18.19 20h7.318L15.48 8.348 24.373 0h-2.15l-7.705 7.232L8.293 0H.975zm3.162 1.376H7.5l14.847 17.248h-3.362L4.137 1.376z"
                ></path>
            </g>
        </svg>

    );
}

export default XforelonMusk;
