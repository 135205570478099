import React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material'
import ButtonInter from '../../atoms/Buttons/ButtonInter';
import { SaveAltOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router'
import { IStorageMembershipData } from '../../../utils/localStorageKeys';
import { useSelector } from 'react-redux'
const ContainerCellPhoneTheft = styled.div``;
const StyleContainerCellPhone: React.CSSProperties = {
    marginTop: '20px',
    marginBottom: '20px'
}
const CellPhoneTheftComponent: React.FC = () => {
    const navigate = useNavigate();
    const requisitosRoboCelular: React.ReactNode[] = [
        'Copia de la Póliza Vigente',
        'Formato de Reclamación',
        'Baja de línea en la compañía de celular',
        'Denuncia ante las autoridades (ministerio público) Ratificada*, con fecha de ocurrencia, marca, modelo del equipo robado y breve descripción de los hechos',
        'Identificación oficial vigente',
        'Comprobante de domicilio del titular con antigüedad no mayor a tres meses',
        'Estado de cuenta bancario del beneficiario con antigüedad no mayor a tres meses',
        <span>CURP, <a href='http://google.com'>consúltala aquí</a></span>
    ]
    let storage: IStorageMembershipData = {
        ...useSelector((state: any) => state.productsCards.currentValue),
    };

    const userData = JSON.parse(sessionStorage.getItem('user-storage')!);
    const userResponse = useSelector((state: any) => state.user.response);

    React.useEffect(() => {
        window.dataLayer.push({
            'event': 'TRUS_robo_celular_v',
            tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium",//premium | gratuito 
            'correo': userResponse?.data?.user?.email,
            'telefono': userResponse?.data?.user?.phone,
            'page_location': window.location.href,
        });
    }, [])
    return (<ContainerCellPhoneTheft style={StyleContainerCellPhone}>
        <Typography style={{
            fontSize: '24px',
            fontWeight: '900',
            textAlign: 'center',
            marginBottom: '40px',
            marginTop: '40px'
        }}>
            Robo de celular
        </Typography>
        <Typography style={{
            textAlign: 'center',
            fontSize: '20px',
            marginBottom: '20px'
        }}>
            Para iniciar el trámite de reclamación de esta cobertura necesitas enviar un correo a <strong>siniestrosmasivoszam@zurich.com</strong> con los siguientes documentos:
        </Typography>

        <div className='requisitosCellPhoneTheft' style={{
            padding: '15px',
            background: '#F2FAFC',
            fontSize: '20px'
        }}>
            <ul>
                {
                    requisitosRoboCelular.map(requisito => {
                        return <li>{requisito}</li>;
                    })
                }
            </ul>
        </div>
        <Typography style={{
            marginTop: '30px'
        }}>
            <small>
                *La denuncia digital sin ratificar no es válida
            </small>
        </Typography>
        <div style={{
            display: "flex",
            flexDirection: 'column',
            gap: '24px',
            width: '100%',
            marginTop: '20px'
        }}>
            <ButtonInter variant='text' onClick={() => {
                window.dataLayer.push({
                    event: 'TRUS_formato_robo_celular',
                    tipo_seguro: userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium",
                    correo: userResponse?.data?.user?.email,
                    telefono: userResponse?.data?.user?.phone,
                })
            }}>
                Formato de Reclamacion <span style={{ marginLeft: '10px' }}><SaveAltOutlined /></span>
            </ButtonInter>
            <ButtonInter variant='contained' onClick={() => {
                window.dataLayer.push({
                    event: 'TRUS_robo_celular',
                    tipo_seguro: userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium",
                    correo: userResponse?.data?.user?.email,
                    telefono: userResponse?.data?.user?.phone,
                    boton_contacto: "Entiendo",
                })
                navigate(-1)
            }} >
                Entiendo
            </ButtonInter>
        </div>
    </ContainerCellPhoneTheft>)
}

export default CellPhoneTheftComponent;