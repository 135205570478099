import React from "react";
import CustomContainer from "../../organisms/Container/Container";
import {useNavigate} from 'react-router-dom';
import {CustomTypography} from "../../atoms/Label/Label";
import {styled} from "styled-components";


import guy from '../../../assets/ManiPosesGuyStadistics/mani-error.png';
import {Avatar, Box} from "@mui/material";
import {PrimaryButton} from "../../molecules/CellPhoneRepairButtons";


const LayOutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 558px;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 80px;
`;


const ServerCrash: React.FC = () => {
    const navigate = useNavigate();


    return (
        <CustomContainer childComponent={
            <LayOutContainer>
                <CustomTypography
                    style={{
                        fontSize: 24,
                        fontWeight: 700,
                        letterSpacing: "0.5px",
                        textAlign: "center",
                        Padding: "0 1rem",
                        textWrap: "balance",
                    }}
                >
                    ¡Oops! Estamos haciendo historia y tenemos muchos registros
                </CustomTypography>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        marginBottom: 3,
                        justifyContent: 'center',
                    }}
                >
                    <Avatar
                        sx={{
                            maxWidth: 250,
                            width: '100%',
                            height: 'auto',
                            margin: {xs: 0, lg: '20px'},
                        }}
                        alt='ManiError'
                        src={guy}
                        variant='square'
                    />
                </Box>
                <CustomTypography
                    style={{
                        fontSize: 20,
                        fontWeight: 400,
                        fontStyle: "normal",
                        letterSpacing: "0.5px",
                        textAlign: "center",
                        Padding: "0 1rem",
                        textWrap: "balance",
                    }}
                >
                    Te pedimos un poco de paciencia, en un momento estaremos de vuelta para que puedas registrarte.
                </CustomTypography>
                <Box sx={{mt: 5}}/>
                <PrimaryButton text={"Entendido"} onClick={() => {
                    navigate("/")
                }}/>
            </LayOutContainer>
        }/>
    );
};

export default ServerCrash;
