import React from 'react';
import styled from 'styled-components';
import { MembershipCategoryType, MembershipType } from '../../organisms/Membership/MembershipMainValues';
import celular from './assets/SmarthPhoneFull.svg'
import celularWhite from './assets/SmarthPhoneFullWhite.svg'
import HealthWhite from './assets/HealthIcon.svg'
import HeartIcon from '../../organisms/MembershipCard/assets/HeartIcon';

const LabelProductContainer = styled.div`
    display: flex;
    background:'red';
`;
const ImageLogo = styled.img``


interface CategoryLabelProps {
    category: MembershipCategoryType,
    type: MembershipType,
    size?: number,
}

const CategoryLabel: React.FC<CategoryLabelProps> = ({
    category,
    type,
    size
}) => {
    const sizeWidth: number = size ? size : 70;
    return <LabelProductContainer>
        {category === "celular"
            ? <ImageLogo src={celularWhite} width={sizeWidth} loading='lazy' />
            : <ImageLogo src={HealthWhite} width={sizeWidth + 5} loading='lazy' />
        }
    </LabelProductContainer>
}

export default CategoryLabel;