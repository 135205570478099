import React from "react";
import CustomContainer from "../../organisms/Container/Container";
import {useParams} from 'react-router-dom';
import {CustomTypography} from "../../atoms/Label/Label";
import {styled} from "styled-components";


import Contrasena from '../../../assets/RecuperacionContrasena/buena-contrasena.svg';
import {Avatar, Box} from "@mui/material";
import ResetPasswordForm from "../../organisms/NewPassword/ResetPasswordForm";
import jwt from 'jwt-decode'



const LayOutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 558px;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 80px;
`;
const NewPassword: React.FC = () => {
    const location:any = useParams();
    const userData:any = jwt(location?.id)
    return (
        <CustomContainer childComponent={
            <LayOutContainer>
                <CustomTypography
                    style={{
                        fontSize: 24,
                        fontWeight: 700,
                        letterSpacing: "0.5px",
                        textAlign: "center",
                        textWrap: "balance",
                    }}
                >
                    {"¡Hola, "}{ userData ? userData?.username : ""}
                </CustomTypography>
                <CustomTypography
                    style={{
                        fontSize: 20,
                        fontWeight: 400,
                        letterSpacing: "0.5px",
                        textAlign: "center",
                        Padding: "0 1rem",
                        textWrap: "balance",
                    }}
                >
                    Crea tu nueva contraseña para
                    #TeRegalamosUnSeguro
                </CustomTypography>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        marginBottom: 3,
                        justifyContent: 'center',
                    }}
                >
                    <Avatar
                        sx={{
                            maxWidth: 250,
                            width: '100%',
                            height: 'auto',
                            margin: {xs: 0, lg: '20px'},
                        }}
                        alt='ManiError'
                        src={Contrasena}
                        variant='square'
                    />
                </Box>
                <ResetPasswordForm trackingId={location?.id}/>
            </LayOutContainer>
        }/>
    );
};

export default NewPassword;
