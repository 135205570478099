import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import FormItemGenerate from '../../../atoms/FormItems/FormItemGenerate';
import { styled } from 'styled-components';
import { Box, Typography, Button, Slider } from '@mui/material';
import { useSelector } from "react-redux";
import { IStorageMembershipData } from '../../../../utils/localStorageKeys';
import { LoadingButton } from '@mui/lab';
import { myColorPalette } from '../../../../theme/themeGenerated';
import { 
  hasLowercaseFunc, 
  hasSpecialCharFunc, 
  hasUppercaseFunc, 
  hasANumberFunc 
} from '../../../../utils/validationInput';
import ProgressBar from '../../../atoms/ProgressBar';
import Dots from '../../../../assets/Dots';
import { CustomTypography } from '../../../atoms/Label/Label';

type SignInProps = {
  errors?: any;
  handleChange?: any;
  handleBlur?: any;
  isSubmitting?: any;
  validationFields?: any;
  loading?: boolean;
  onClickSubmit?: any;
}

const ContentStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`;

const Ul = styled.ul`
  padding: 0 1rem;
`;

const Li = styled.li`
  list-style-type: none; 
  color: #212121; 
  display: flex; 
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const SignIn: React.FC<SignInProps> = ({ handleChange, handleBlur, isSubmitting, validationFields, loading, onClickSubmit }) => {
    const [phone, phoneMeta] = useField("phone");
    const [password, passwordMeta] = useField("password");
    const [passwordConfirm, passwordConfirmMeta] = useField("passwordConfirm");
    const [ upperLowerCase, setUpperLowerCase ] = useState<boolean>(false);
    const [ hasANumber, setHasANumber ] = useState<boolean>(false);
    const [ minTenCharacters, setMinTenCharacters ] = useState<boolean>(false);
    const [ specialCharacter, setSpecialCharacter ] = useState<boolean>(false);
    const [ porcentage, setPorcentage ] = useState('0');
    let storage: IStorageMembershipData = {
      ...useSelector((state: any) => state.productsCards.currentValue),
    };
    const userData = JSON.parse(sessionStorage.getItem('user-storage')!);
    const allianceSettingResponse = useSelector((state: any) => state.allianceSetting.response);

    useEffect(() => {
      if (!window.location.pathname.includes("/alianza")){
        window.dataLayer.push({
          'event': 'TRUS_activar_v',
          'tipo_seguro': storage.type === "free" ? "De regalo" : "Premium",
          'correo': userData?.correo,
          'telefono': userData?.telefono,
          'page_location': window.location.href,
          seguro: storage?.category == "celular" ? "Celular Protect" : "Salud Protect",
          alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
        });
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if(password?.value){
        const hasMinLength = password.value.length >= 10;
        const hasUppercase = hasUppercaseFunc(password.value);
        const hasLowercase = hasLowercaseFunc(password.value);
        const hasANumber = hasANumberFunc(password.value);
        const hasSpecialChar = hasSpecialCharFunc(password.value);

        setUpperLowerCase(hasUppercase && hasLowercase)
        setMinTenCharacters(hasMinLength)
        setSpecialCharacter(hasSpecialChar)
        setHasANumber(hasANumber)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password]);

    useEffect(() => {
      let cont = 0;
      upperLowerCase && (cont += 25) 
      hasANumber && (cont += 25) 
      specialCharacter && (cont += 25) 
      minTenCharacters && (cont += 25) 
      setPorcentage(`${cont}`)
    }, [upperLowerCase, hasANumber, specialCharacter, minTenCharacters]);

    const items = [{
        id: 1,
        label: 'Teléfono',
        name: 'phone',
        as: 'textField',
        error: phone,
        meta: phoneMeta,
        autofocus: true,
        disabled: true
    },
    {
        id: 2,
        label: 'Contraseña',
        name: 'password',
        as: 'textField',
        type: 'password',
        error: password,
        meta: passwordMeta
    }]

    const confirmPassword =[
      {
          id: 3,
          label: 'Confirma contraseña',
          name: 'passwordConfirm',
          type: 'password',
          as: 'textField',
          error: passwordConfirm,
          meta: passwordConfirmMeta
      }]

    const palette: any = {
      '25': '#D26B20',
      '50': '#F6B704',
      '75': '#FFD60E',
      '100': '#5AB52F'
    }

    return (
        <ContentStyled>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
              <FormItemGenerate
                  items={items}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
              />
              <Box sx={{margin: 0}}>
                <ProgressBar value={Number(porcentage)} labelActive={false} color={palette[porcentage]} size='large' />
              </Box>
              <Box>
                  <Typography style={{ 
                      fontWeight: 600, 
                      fontSize: 14 
                    }}>
                      Una contraseña segura debe tener:
                  </Typography>
                  <Ul>
                      <Li>
                        <Dots color={minTenCharacters ? palette[porcentage] : '#F4F4F4'} />
                        Al menos 10 caracteres 
                      </Li>
                      <Li>
                        <Dots color={upperLowerCase ? palette[porcentage] : '#F4F4F4'} />
                        Una mayúscula y minúscula
                      </Li>
                      <Li>
                        <Dots color={hasANumber ? palette[porcentage] : '#F4F4F4'} />
                        Un número
                      </Li>
                      <Li>
                        <Dots color={specialCharacter ? palette[porcentage] : '#F4F4F4'} />
                        Un carácter especial
                      </Li>
                  </Ul>
              </Box>
            </Box>
            <Box>
              <FormItemGenerate
                  items={confirmPassword}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
              />
            </Box>
            {
                
                !loading ? (
                    <Button
                      type="button"
                      onClick={() => {
                        onClickSubmit({values: {phone: phoneMeta.value, password: passwordMeta.value}})
                        window.dataLayer.push({
                          event: 'TRUS_activar',
                          option: 'Telefono',
                          correo: userData?.correo,
                          telefono: userData?.telefono,
                          tipo_seguro: storage.type == "free" ? "De regalo" : "Premium",
                          seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
                          alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
                        })
                      }}
                      size={"medium"}
                      variant={"contained"}
                      disabled={isSubmitting || !validationFields}
                      style={
                        {
                          borderRadius: 8,
                          width: "100%",
                          padding: "14px 16px",
                          background: !validationFields ? "#cfcfcf" : myColorPalette.getPrimaryColor(),
                          color: !validationFields ? "gray" : "#FFF",
                          opacity: '0.8'
                        }
                      }
                    >
                      Activar
                    </Button>
                    )
                    :
                    (
                      <LoadingButton
                        style={{
                          borderRadius: 8,
                          width: "100%",
                          padding: "14px 16px",
                          background: myColorPalette.getPrimaryColor(),
                          color: "#FFF",
                          opacity: '0.8'
                        }}
                        size="medium"
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                      >
                        <span>Activando cuenta</span>
                      </LoadingButton>
                    )
            }
        </ContentStyled>
    )
};

export default SignIn;