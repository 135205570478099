import React, { useEffect } from 'react';
import styled from 'styled-components';

import email from '../assets/email.png';
import { Box, Typography } from '@mui/material';
import BackButtonInter from '../../../atoms/Buttons/BackButtonInter';
import ButtonInter from '../../../atoms/Buttons/ButtonInter';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    apiSuccess as StatusSuccess,
    apiLoading as StatusLoading,
    apiError as StatusError
} from '../../../../api/statusSlice'
import useFetch from '../../../../hooks/useFetch';
const ContainerEmail = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 850px;
    margin: auto;
    padding-top: 15px;
    padding-bottom: 15px;
`
const IdleEmail: React.FC = () => {
    const navigate = useNavigate();
    const statusResponse = useSelector((state: any) => state.status?.response) as any | null;
    const statusError = useSelector((state: any) => state.status?.error) as any | null;

    const userResponse = useSelector((state: any) => state.user.response);
    const { fetchData } = useFetch();
    const getStatus = () => {
        const trakingId: string = userResponse?.data?.user?.tracking_id;
        return fetchData('/gadgets/', 'POST', {
            event: 'get-claim-cel',
            content: {
                tracking_id: trakingId
            }
        }, {}, StatusLoading, StatusSuccess, StatusError);
    }
    useEffect(() => {
        if (userResponse?.data?.user) {
            getStatus();
        }
    }, [userResponse]);

    return (<ContainerEmail>
        <Box>
            <BackButtonInter />
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px',
                marginBottom: '20px'
            }}>
                <img src={email} alt="" width={'200px'} />
            </Box>
            <Box sx={{
                marginTop: '15px',
                marginBottom: '20px'
            }}>
                <Typography>
                    En un máximo de 24 horas recibirás un correo con las opciones de pago, es muy importante que lo realices en <strong> un lapso no mayor a 15 días </strong> , para que podamos continuar con tu solicitud.
                </Typography>
            </Box>
            <Box>
                <ButtonInter variant='contained' onClick={() => {
                    navigate('/portal-del-cliente')
                }}>
                    Entiendo
                </ButtonInter>
            </Box>
        </Box>
    </ContainerEmail>)
}

export default IdleEmail