import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { useMediaQuery, useTheme } from '@mui/material';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
        {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#F4F4F4',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
    },
}));

interface CustomToolTipProps extends Pick<TooltipProps, "className"> {
    children: ReactElement;
    title: React.ReactNode | string;

}

const CustomToolTipComponent: React.FC<CustomToolTipProps> = ({ children, title, className, ...props }) => {
    const { breakpoints } = useTheme();
    const isTablet = useMediaQuery(breakpoints.down('md'));
    const [open, setOpen] = React.useState(false);
    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };


    return (
            isTablet 
            ? 
            <CustomTooltip
                onClose={handleTooltipClose}
                open={open}
                {...props}
                title={title}>

                <div onClick={handleTooltipOpen} style={{ cursor: 'pointer' }}>
                    {children}
                </div>
            </CustomTooltip> 
            : 
            <CustomTooltip {...props} title={title}>
                {children}
            </CustomTooltip>
        )
}

export default CustomToolTipComponent;