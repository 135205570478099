import React, { useState } from "react";
import * as Yup from "yup";
import { styled } from "styled-components";

import FormContainer from "../Form/FormContainer";
import ElegirCobertura from "../Form/FormContent/ElegirCobertura";
import CustomContainer from "../Container/Container";
import { useNavigate } from "react-router-dom";
import {
  apiSuccess
} from '../../../api/formRegisterSlice';
import { useSelector } from "react-redux";
import { fetchTrackingStore } from "../../../api/fetchingTrackingStore";
import useFetch from "../../../hooks/useFetch";
import { apiLoading as apiLoadingLeads, apiSuccess as apiSuccessLeads, apiError as apiErrorLeads } from "../../../api/leadsSlice";
import { fieldIsRequired } from "../../../utils/scrollToTop";

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
`;

const ChooseCoverage: React.FC = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<number>(0);
  const { fetchData } = useFetch();
  // const initValues = JSON.parse(localStorage.getItem("FormObserver") || "{}");
  const response = useSelector((state: any) => state.formRegister.response) as any | null;
  const tracking = useSelector((state: any) => state.tracking.response) as any | null;
  const currentValue = useSelector((state: any) => state.productsCards.currentValue) as any | null;
  const productsCards = useSelector((state: any) => state.productsCards.response) as any | null;
  const toppings = useSelector((state: any) => state.health.response) as any | null;

  const membershipTypeModule = {
    chooseCoverage: {
      formSteps: 0, //starting from 0
      steps: [], // steps title
      titles: [], // content title
      initialValues: response,
    },
  };

  const renderStepContent = (
    step: number,
    errors: any,
    handleChange: any,
    handleBlur: any,
    isSubmitting: any
  ) => {
    switch (step) {
      case 0:
        // const getProduct = productsCards.data.products.find((product: any) => product.category === currentValue.category && product.type === currentValue.type)
        return (
          <ElegirCobertura
            isSubmitting={isSubmitting}
            handleChange={handleChange}
            toppings={toppings?.data?.freemium?.benefits || []}
          />
        );
      default:
        return null;
    }
  };

  const _sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };


  const getValuesByPropertyValue = (object: any, propertyCopied: any) => {
    let newObject: { [key: string]: string } = {};

    for (const property of propertyCopied) {
      if (object.hasOwnProperty(property)) {
        newObject[property] = typeof object[property] === "string" ? object[property].trim() : object[property];
      }
    }

    const getProduct = productsCards.data.products.find((product: any) => product.category === currentValue.category && product.type === currentValue.type)
    newObject['plan_id'] = getProduct.plan_id;
    newObject['benefit_id'] = newObject.picked;

    return newObject;
  }

  const _submitForm: any = async (values: any, actions: any) => {
    await _sleep(1000);
    // alert(JSON.stringify(values, null, 2));
    actions.setSubmitting(true);

    const content = getValuesByPropertyValue(values, ['name', 'middle_name', 'last_name', 'birthdate', 'mexicanciticen', 'zipcode', 'email', 'phone', 'acceptTerms', 'otp', 'picked']);

    fetchTrackingStore(
      '/users/leads',
      "save-lead",
      {
        "content": {
          "tracking_id": tracking?.data?.tracking_id, // generado por el endpoint anterior
          ...content
        }
      },
      fetchData,
      apiLoadingLeads,
      apiSuccessLeads,
      apiErrorLeads
    );

    navigate("/resumen-membresia", { state: { path: 'registrate' } });
  };

  const _handleSubmit = async (values: any, actions: any) => {
    _submitForm(values, actions);
  };

  const validationSchemaStep = Yup.object().shape({
    picked: Yup.string().min(3, "Debes eleguir una de las siguientes opciones").required(fieldIsRequired)
  });

  const validatorSchemaStepper = () => {
    return validationSchemaStep;
  };

  return (
    <CustomContainer
      childComponent={
        <FormWrapper>
          <FormContainer
            initialValueImportant
            steps={membershipTypeModule.chooseCoverage.steps}
            initialValues={membershipTypeModule.chooseCoverage.initialValues}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            title={membershipTypeModule.chooseCoverage.titles}
            renderStepContent={renderStepContent}
            _handleSubmit={_handleSubmit}
            validatorSchemaStepper={validatorSchemaStepper}
            formObserverKey="FormObserver"
            formObserverSlice={apiSuccess}
            buttonNext={false}
            localData={response}
          />
        </FormWrapper>
      }
    />
  );
};

export default ChooseCoverage;
