import ReactDOM from 'react-dom'
import { ModalProps, IAlertData } from './interfaces';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import {AlertTitle, Box, Collapse, IconButton} from "@mui/material";
import IconWarningFraud from "../../../assets/AlertWarning/IconWarning.svg";
import React, {useState} from "react";
import CloseIcon from '@mui/icons-material/Close';

export function ModalWarning(props: ModalProps) {
  const { data, exit } = props
  const [open,setOpen] = useState(true)

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    exit();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={8000}
      onClose={handleClose}
      anchorOrigin={{vertical: "top", horizontal: "center"}}
      style={{marginTop: "80px",padding:0}}
    >
      <Stack sx={{ width: '100%',display:'flex'}}>
        <Collapse in={open}>
          <Alert
              sx={{
                backgroundColor: '#FFE7C3',
                padding: '0 10px 0 0px',
                alignItems: 'center', borderRadius: 0, height: { xs:80,sm:80,md:70}
              }}
              severity="success"
              icon={<Stack sx={{
                width:{ xs:80,sm:80,md:70}, height: { xs:80,sm:80,md:70}, backgroundColor: '#FFD089',
                justifyContent: 'center',
                alignItems: 'center'
              }} spacing={0}>
                <Box
                    component={'img'}
                    src={IconWarningFraud}
                    width={'auto'}
                    height={'auto'}
                />
              </Stack>}
              action={
                <IconButton
                    style={{ color: '#E39217' }}
                    aria-label="close"
                    size="medium"
                    onClick={() => {
                      setOpen(false)
                    }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }

          >
            <Box component={'span'} sx={{
              display:'flex',
              color:'black',
              padding:'10px 0 px 0',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize:{ xs:14,sm:16,md:16}
            }}>{data?.message}</Box>
          </Alert>
        </Collapse>
      </Stack>
    </Snackbar>
  );
}

const RenderAlertWarning = (data: IAlertData) => {
  const div = document.createElement('div')
  document.body.appendChild(div)

  const exit = () => {
    ReactDOM.unmountComponentAtNode(div)
  }

  ReactDOM.render(
    <ModalWarning data={data} exit={exit} />
    ,div
  )
}

export default RenderAlertWarning
