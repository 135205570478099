
import React from 'react';
import styled from 'styled-components';
import './TotalCard.css'
import { CustomTypography } from '../../atoms/Label/Label';

const DetailsImport = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    text-wrap: balance;
`;

const Span = styled.span`
    color: var(--Black, #212121);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
`;

export interface TotalCardItem {
    label: string;
    price?: number;
    sublabel?: string;
}

interface TotalCardProps {
    title?: string,
    items: TotalCardItem[],
    totalPymentVisibility?: boolean;
    tatalPaymentPrice?: {
        label: string;
        total?: string;
        subLabel: string;
    };
    style?: {
        root?: object;
        title?: object;
        itemsCard?: object;
        totalInfo?: object;
    };
    hideExtraText?:boolean
}

const convertToString = (number: number) => {
    return number.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
}



const TotalCard: React.FC<TotalCardProps> = ({ title, items, totalPymentVisibility = false, style, tatalPaymentPrice, hideExtraText = false }) => {
    let total: number = 0
    //@ts-ignore
    items.forEach(item => total += item?.price);
    return (
        <>
            <div className="CardTotalDetail" style={style?.root}>
                {title && <div className="Title" style={style?.title}>
                    <p>
                        <strong>
                            {title}
                        </strong>
                    </p>
                </div>}
                <div className="itemsCard" style={style?.itemsCard}>
                    {
                        items.map((item, index) => {
                            return <React.Fragment key={item.label}>
                                {!hideExtraText && item?.sublabel && <Span style={{
                                    background: '#FAD882',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: 4,
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <CustomTypography style={{ fontSize: '18px' }}>Primer mes de regalo</CustomTypography>
                                    <CustomTypography style={{
                                        fontWeight: 400,
                                        fontSize: '18px'
                                    }}>a partir del segundo pagarás:</CustomTypography>
                                </Span>}
                                <DetailsImport>
                                    <Span style={{width:'500px'}}>{item.label}</Span>

                                    {item?.sublabel ? <Span style={{
                                        textAlign: 'end'
                                    }}>
                                        {/*@ts-ignore*/}
                                        <strong> {convertToString(item.price)}</strong> {item.sublabel}
                                    <br /></Span>
                                    :<>
                                        {/*@ts-ignore*/}
                                        <Span><strong>{convertToString(item.price)}</strong> <br /></Span>
                                    </>}
                                </DetailsImport>

                                {(items.length - 1) !== index && <div style={{
                                    width: '100%',
                                    margin: '8px 0',
                                    borderBottom: '1px solid'
                                }}></div>}
                                <hr className='lineAbove' />

                                <div className="itemCard" key={index}>
                                    <div className="labelText" style={!totalPymentVisibility ? { display: 'flex' } : { display: 'none' }}>
                                        <p>
                                            {item.label}
                                        </p>
                                    </div>

                                    <div className="priceText" style={!totalPymentVisibility ? { display: 'flex' } : { display: 'none' }}>
                                        <p>
                                            <strong>Primer mes de regalo</strong> y después paga
                                            <strong>
                                                {/*@ts-ignore*/}
                                                { convertToString(item.price)}
                                            </strong>
                                        </p>
                                        <p className='subLabelText' style={item.sublabel ? { display: 'flex' } : { display: 'none' }}>
                                            <small>
                                                {item.sublabel}
                                            </small>
                                        </p>
                                    </div>

                                    <div className="totalInfo" style={totalPymentVisibility ? { ...style?.totalInfo, display: 'flex' } : { display: 'none' }}>
                                        <div className='labelText'>
                                            <p>{tatalPaymentPrice?.label || 'Total a pagar:'}</p>
                                        </div>
                                        <div className="priceText">
                                            <p style={{textAlign: 'end'}}>
                                                <strong>
                                                    {tatalPaymentPrice?.total &&  `$${tatalPaymentPrice?.total || ''}` }
                                                    <br />
                                                </strong>
                                                <span>{tatalPaymentPrice?.subLabel}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        })
                    }
                </div>
            </div>
        </>)
}

export default TotalCard
