import React from "react";
import { useSelector } from 'react-redux';
import { Formik, Form, useFormikContext } from "formik";
import { Button, createTheme } from "@mui/material";
import { styled } from "styled-components";
import FormSteppers from "../FormSteppers";
import FormObserver from "../FormikContexCustom";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import "yup-phone-lite";
import LoadingButton from '@mui/lab/LoadingButton';
import { CustomTypography } from "../../../atoms/Label/Label";
import Yup from 'yup'
import { IStorageMembershipData } from "../../../../utils/localStorageKeys";
import { myColorPalette } from '../../../../theme/themeGenerated';

const LayOutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
`;

type FormContainerProps = {
  renderStepContent: any;
  steps: string[];
  initialValues: any;
  activeStep: number;
  setActiveStep: any;
  title: string[];
  _handleSubmit: any;
  validatorSchemaStepper: any;
  localStorageOmit?: Array<string>;
  formObserverKey?: string;
  initialValueImportant?: boolean;
  buttonNext?: boolean;
  showTextRequire?: boolean;
  formObserverSlice?: any;
  localData?: any;
  buttonText?: string | React.ReactNode;
  formikRef?: any;
  loading?: boolean;
  submitted?: boolean;
  activeDot?: Array<any>;
  customText?: string;
};

const FormContainer: React.FC<FormContainerProps> = ({
  renderStepContent,
  steps,
  initialValues,
  activeStep,
  title,
  _handleSubmit,
  validatorSchemaStepper,
  localStorageOmit,
  formObserverKey,
  initialValueImportant,
  buttonNext = true,
  showTextRequire = true,
  formObserverSlice,
  localData,
  buttonText = "Continuar",
  formikRef,
  loading = false,
  submitted = false,
  activeDot,
  customText = ''
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [formValues, setFormValues] = useLocalStorage(formObserverKey, "");
  // const response = useSelector((state: any) => state.formRegister.response) as any | null;
  // const loading = useSelector((state: any) => state.formRegister.loading);
  // const error = useSelector((state: any) => state.formRegister.error);

  let storage: IStorageMembershipData = {
    ...useSelector((state: any) => state.productsCards.currentValue),
  };

  const validationFields = validatorSchemaStepper().isValidSync(localData);
  const allianceSettingResponse = useSelector((state: any) => state.allianceSetting.response);

  const userData = JSON.parse(sessionStorage.getItem('user-storage')!);
  const userResponse = useSelector((state: any) => state.user.response);
  React.useEffect(() => {
    if (
      (activeStep == 1 && window.location.pathname.includes("/registrate/") && storage.category == "celular") ||
      (activeStep == 0 && window.location.pathname == "/registrate/1" && storage.category == "salud") ||
      (activeStep == 0 && window.location.pathname == "/alianza/registrate/1" && storage.category == "salud") ||
      (activeStep == 1 && window.location.pathname == "/alianza/registrate/2" && storage.category == "celular") ||
      (activeStep == 0 && window.location.pathname == "/finsus/registrate/1" && storage.category == "salud")
    ) {
      window.dataLayer.push({
        event: 'TRUS_registro_demograficos_v',
        tipo_seguro: storage.type == "free" ? "De regalo" : "Premium",
        seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
        correo: undefined,
        telefono: undefined,
        page_location: window.location.href,
        alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
      });
    } else if (
      (activeStep == 2 && window.location.pathname.includes("/registrate") && storage.category == "celular") ||
      (activeStep == 1 && window.location.pathname.includes("/registrate") && storage.category == "salud") ||
      (window.location.pathname.includes("/alianza/registrate/2") && activeStep == 1)) {
      window.dataLayer.push({
        event: 'TRUS_registro_contacto_v',
        tipo_seguro: storage.type == "free" ? "De regalo" : "Premium",
        seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
        correo: undefined,
        telefono: undefined,
        page_location: window.location.href,
        alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
      });
    } else if (
      (activeStep == 3 && window.location.pathname.includes("/registrate") && storage.category == "celular") ||
      (activeStep == 2 && window.location.pathname.includes("/registrate") && storage.category == "salud")) {
      window.dataLayer.push({
        event: 'TRUS_registro_validacion_v',
        tipo_seguro: storage.type == "free" ? "De regalo" : "Premium",
        seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
        correo: localData.email,
        telefono: localData.phone,
        page_location: window.location.href,
        alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
      });
    } else if (activeStep == 0 && window.location.pathname == "/portal-del-cliente/registro-nuevo-producto") {
      window.dataLayer.push({
        event: "TRUS_nuevo_resumen_v",
        correo: userData?.correo,
        telefono: userData?.telefono,
        page_location: window.location.href,
        total_pagar: storage.type === "free" ? 0 : 99,
        tipo_seguro: storage.type === "free" ? "De regalo" : "Premium",
        seguro:
          storage?.category === "celular" ? "Celular Protect" : "Salud Protect",
        alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    } else if (window.location.pathname.includes("/alianza/registrate") && activeStep == 3) {
      window.dataLayer.push({
        event: "TRUS_nuevo_seleccion_seguro_v",
        correo: userData?.correo,
        telefono: userData?.telefono,
        page_location: window.location.href,
        seguro: storage?.category == "celular" ? "Celular Protect" : "Salud Protect",
        alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
      });
    } else if (activeStep == 1 && window.location.pathname == "/portal-del-cliente/registro-nuevo-producto" && storage.category == "salud") {
      window.dataLayer.push({
        event: "TRUS_nuevo_resumen_v",
        correo: userData?.correo,
        telefono: userData?.telefono,
        page_location: window.location.href,
        total_pagar: storage.type === "free" ? 0 : 99,
        tipo_seguro: storage.type === "free" ? "De regalo" : "Premium",
        seguro: "Salud Protect",
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    /*else if (window.location.pathname == "/reparacion-celular") {
      window.dataLayer.push({
        'event': 'TRUS_solicitud_reparacion_v',
        tipo_seguro: storage.type == "free" ? "De regalo" : "Premium",
        correo: userData?.correo,
        telefono: userData?.telefono,
        page_location: window.location.href,
        seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
      });
    }*/
  }, [activeStep])

  const sendGtmStep0 = () => {
    if (localData.marca && localData.modelo, localData.descripcion) {
      window.dataLayer.push({
        event: 'TRUS_registro_dispositivo',
        marca_cel: localData.marca,
        modelo_cel: localData.modelo,
        descripcion_cel: localData.descripcion,
        boton_contacto: "Continuar",
        tipo_seguro: storage?.type == "free" ? "De regalo" : "Premium",
        seguro: storage?.category == "celular" ? "Celular Protect" : "Salud Protect",
        alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
      })
    } else if (window.location.pathname === "/iniciar-sesion") {
      window.dataLayer.push({
        event: 'TRUS_iniciar_sesion',
        option: "Telefono",
        telefono: localData.phone,
        boton_contacto: 'Iniciar sesion'
      })
    }
  }
  const sendGtmStep1 = () => {
    if (window.location.pathname.includes("/registrate")) {
      window.dataLayer.push({
        event: 'TRUS_registro_demograficos',
        fecha_nacimiento: localData.birthdate.split("-").reverse().join("/"),
        cp: localData.zipcode,
        sexo: localData.gender,
        boton_contacto: 'Continuar',
        tipo_seguro: storage?.type == "free" ? "De regalo" : "Premium",
        seguro: storage?.category == "celular" ? "Celular Protect" : "Salud Protect",
        alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
      })
    } else if (window.location.pathname == "/portal-del-cliente/registro-nuevo-producto" && storage.category == "celular") {
      window.dataLayer.push({
        event: 'TRUS_nuevo_registro_dispositivo',
        marca_cel: localData.marca,
        modelo_cel: localData.modelo,
        descripcion_cel: localData.descripcion,
        boton_contacto: "Continuar",
        tipo_seguro: storage?.type == "free" ? "De regalo" : "Premium",
        seguro: storage?.category == "celular" ? "Celular Protect" : "Salud Protect",
        alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
      })
    } else if (window.location.pathname == "/alianza/empleado") {
      window.dataLayer.push({
        event: 'TRUS_alianza_registro_empleado',
        tipo_seguro: storage.type === "free" ? "De regalo" : "Premium",
        seguro: storage?.category === "celular" ? "Celular Protect" : "Salud Protect",
        alianza: storage?.business,
      })
    }
  }
  const sendGtmStep2 = () => {
    if (window.location.pathname !== "/portal-del-cliente/registro-nuevo-producto") {
      window.dataLayer.push({
        event: 'TRUS_registro_contacto',
        correo: localData.email,
        telefono: localData.phone,
        boton_contacto: 'Continuar',
        tipo_seguro: storage?.type == "free" ? "De regalo" : "Premium",
        seguro: storage?.category == "celular" ? "Celular Protect" : "Salud Protect",
      })
    } else if (window.location.pathname == "/portal-del-cliente/registro-nuevo-producto" && storage?.category == "salud") {
      window.dataLayer.push({
        event: storage?.type == "free" ? 'TRUS_miseguro_freemium' : 'TRUS_miseguro_premium',
        seguro: "Salud Protect",
        tipo_seguro: storage?.type == "free" ? "De regalo" : "Premium",
        // seguro: storage?.category == "celular" ? "Celular Protect" : "Salud Protect",
        alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
      })
    }
    const userData = {
      correo: localData.email,
      telefono: localData.phone,
    }
    sessionStorage.setItem('user-storage', JSON.stringify(userData));
  }
  const sendGtmStep3 = () => {
    window.dataLayer.push({
      event: 'TRUS_registro_validacion',
      boton_contacto: 'Continuar',
      correo: userData?.correo,
      telefono: userData?.telefono,
      tipo_seguro: storage?.type == "free" ? "De regalo" : "Premium",
      seguro: storage?.category == "celular" ? "Celular Protect" : "Salud Protect",
      alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
    })
  }
  const sendGtmStep4 = () => {
    if (storage.type == "free" && !window.location.pathname.includes("/alianza/registrate")) {
      window.dataLayer.push({
        event: 'TRUS_miseguro_freemium',
        seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
        tipo_seguro: "De regalo",
        telefono: userData?.telefono,
        correo: userData?.correo,
        boton_contacto: 'Continuar',
        alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
      })
    } else if (storage.type == "premium" && !window.location.pathname.includes("/alianza/registrate")) {
      window.dataLayer.push({
        event: 'TRUS_miseguro_premium',
        seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
        tipo_seguro: "Premium",
        telefono: userData?.telefono,
        correo: userData?.correo,
        boton_contacto: "Continuar",
        alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
      })
    }
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={
          initialValueImportant ? initialValues : localData || initialValues
        }
        validationSchema={() => validatorSchemaStepper()}
        onSubmit={_handleSubmit}
      >
        {({ handleChange, handleBlur, isSubmitting, errors }) => (
          <Form
            noValidate
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              gap: 40,
            }}
          >
            <FormObserver
              getFormikReference={formikRef}
              localStorageOmit={localStorageOmit}
              formObserverSlice={formObserverSlice}
            />
            <FormSteppers
              title={title}
              activeStep={activeStep}
              steps={steps}
              activeDot={activeDot}
            />
            <LayOutContainer>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 40,
                  alignItems: "center",
                }}
              >
                {renderStepContent(
                  activeStep,
                  errors,
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  validationFields
                )}
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                width: '100%'
              }}>

                {buttonNext && (
                  <Button
                    type="submit"
                    size={"medium"}
                    variant={"contained"}
                    disabled={isSubmitting || !validationFields || submitted}
                    onClick={() => {
                      if (storage.category == "celular") {
                        if (activeStep == 0) {
                          sendGtmStep0();
                        } else if (activeStep == 1) {
                          sendGtmStep1();
                        } else if (activeStep == 2) {
                          sendGtmStep2();
                        } else if (activeStep == 3) {
                          sendGtmStep3();
                        } else if (activeStep == 4) {
                          sendGtmStep4();
                        }
                      } else if (storage.category == "salud") {
                        if (activeStep == 0) {
                          sendGtmStep1();
                        } else if (activeStep == 1) {
                          sendGtmStep2();
                        } else if (activeStep == 2) {
                          sendGtmStep3();
                        } else if (activeStep == 3) {
                          sendGtmStep4();
                        }
                      }
                    }
                    }
                    style={
                      {
                        borderRadius: 8,
                        width: "100%",
                        padding: "14px 16px",
                        background: !validationFields ? "#cfcfcf" : myColorPalette.getPrimaryColor(),
                        color: !validationFields ? "gray" : "#FFF",
                        opacity: '0.8'
                      }
                    }
                  >
                    {buttonText}
                  </Button>
                )
                }
                {buttonNext && showTextRequire && (<CustomTypography
                  style={{
                    width: '100%',
                    fontWeight: 400,
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontHeight: 'normal'
                  }}
                >*Campos obligatorios</CustomTypography>)}
                {buttonNext && customText && !showTextRequire && (<CustomTypography
                  style={{
                    width: '100%',
                    fontWeight: 400,
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontHeight: 'normal'
                  }}
                >{customText}</CustomTypography>)}
              </div>
            </LayOutContainer>
          </Form>
        )}
      </Formik>
    </React.Fragment >
  );
};

export default FormContainer;
