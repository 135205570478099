import React from 'react';
import { ListItem, ListItemText, List, Collapse } from '@mui/material';
import styled from 'styled-components';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const ListItemTextElement = styled(ListItemText)`
    font-weight: 700;
    text-align: center;
`;

interface SubMenuProps {
  label: string;
  items: { label: string; 
  onClick: () => void }[];
  icon?: boolean;
}

const SubMenu: React.FC<SubMenuProps> = ({ label, items, icon=true }) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  
  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemTextElement style={{ textAlign: 'left' }} primary={<b style={{color: '#000'}}>{label}</b>} />
        {!icon ? '' : open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map((item, index) => (
            <ListItem button key={index} onClick={item.onClick}>
              <ListItemText style={{ textAlign: 'left', paddingLeft: '8px' }} primary={item.label} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default SubMenu;
