export interface IBenefitBadge {
    color: string;
    message: string;
}

export const BadgeList = {
    pendingBeneficiary: <IBenefitBadge>{
        color: '#90268D',
        message: 'Pendiente de agregar beneficiario'
    },
    pedingImegiRegister: <IBenefitBadge>{
        color: '#90268D',
        message: 'Pendiente de agregar IMEI'
    },
}

export const ReparacionCelular = {
    inProgres: <IBenefitBadge>{
        color: '#90268D',
        message: "En proceso"
    },
    paymentPending: <IBenefitBadge>{
        color: '#90268D',
        message: "Pendiente de pago"
    },
    onRepair: <IBenefitBadge>{
        color: '#F72E67',
        message: 'En reparación'
    },
}

