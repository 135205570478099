import React, {useEffect, useState} from "react";
import {styled} from "styled-components";
import ForgotPasswordContent from "../../Form/FormContent/ForgotPasswordContent";
import * as Yup from "yup";
import {fieldIsRequired} from "../../../../utils/scrollToTop";
import {apiError, apiLoading, apiSuccess} from "../../../../api/sendEmailSlice";
import FormContainer from "../../Form/FormContainer";
import {useSelector} from "react-redux";
import {validateEmail, validatePhoneNumber} from "../../../../utils/utils";


const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`;
interface IForgotPasswordForm{
    redirectTo?: string;
    handleSend:(email: string) => void
}


const ForgotPasswordForm: React.FC<IForgotPasswordForm> = ({redirectTo,handleSend}) => {
    const [ activeStep, setActiveStep ] = useState<number>(0);
    const response = useSelector((state: any) => state.sendEmail.response) as any | null;


    const membershipTypeModule = {
        forgotPassword: {
            formSteps: 0, //starting from 0
            steps: [], // steps title
            titles: [], // content title
            initialValues: {
                email: ''
            }
        }
    }

    const renderStepContent = (step: number, errors: any, handleChange: any, handleBlur: any) => {
        switch (step) {
            case 0:
                return ( <ForgotPasswordContent handleChange={handleChange} handleBlur={handleBlur} errors={errors}/> );
            default:
                return null;
        }
    };


    const _handleSubmit = async(values: any, actions: any) => {
        handleSend(values.email)
    }

    const validationSchemaStep = Yup.object().shape({
        email: Yup.string()
            .required(fieldIsRequired)
            .test('test-name', 'Ingresé Teléfono o Correo electrónico valido',
                function(value) {
                    let isValidEmail = validateEmail(value);
                    let isValidPhone = validatePhoneNumber(value);
                    if (!isValidEmail && !isValidPhone ){
                        return false;
                    }
                    return true;
                })
    });

    const validatorSchemaStepper = () => {
        return validationSchemaStep;
    }


    return(<FormWrapper>
        <FormContainer
            initialValueImportant
            showTextRequire={false}
            buttonText={"Recuperar contraseña"}
            steps={membershipTypeModule.forgotPassword.steps}
            initialValues={membershipTypeModule.forgotPassword.initialValues}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            title={membershipTypeModule.forgotPassword.titles}
            renderStepContent={renderStepContent}
            _handleSubmit={_handleSubmit}
            validatorSchemaStepper={validatorSchemaStepper}
            formObserverKey='FormObserverRecoveryPassword'
            formObserverSlice={apiSuccess}
            localData={response}
        />
    </FormWrapper>)
}

export default ForgotPasswordForm
