export const setStatusRepairPhone = (data: any) => {
    /**Status**/
    // Pendiente de pago status 1
    // Pago confirmado  status 2
    // En proceso de reparación status 3

    try {
        sessionStorage.setItem('repair-phone', JSON.stringify(data));
    } catch (e) {
        return null
    }
}

export const getStatusRepairPhone = () => {
    try {
        let data = sessionStorage.getItem('repair-phone');
        if (data) {
            return JSON.parse(data)
        } else {
            return null
        }
    } catch (e) {
        return null
    }
}

export const removeStatusRepairPhone = () => {
    sessionStorage.removeItem("repair-phone");
}

const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
export const validateEmail = (email: string) => {
    const [beforeAt, afterAt] = email.split('@')
    const blacklist = ['gmail.mx', 'hotmail.mx', 'aol', 'hotmail.com.mx', 'gmail.com.mx', 'live.com.mx', 'gmail.co', 'ensamble.dev', 'outloot.com', 'yahoo.com.sg']
    if (beforeAt.length <= 3) return false
    if (blacklist.includes(afterAt)) return false
    if (!emailRegex.test(email)) return false
    return true
}
const isThereFourConsecutiveNumber = (phoneNumber: string) => {
    if (/(^|(.)(?!\2))(\d)\3{4,}(?!\3)/.test(phoneNumber)) {
        return true;
    }
    return false;
}

const allEqual = (arr: (string | number)[]) => {
    return arr.every((val) => val === arr[0])
}

export const validatePhoneNumber = (phoneNumber: string) => {
    let isValid = false
    if (isThereFourConsecutiveNumber(phoneNumber)) {
        isValid = false
    } else if (allEqual(Array.from(phoneNumber))) {
        isValid = false
    } else if (phoneNumber.includes('123456789') || phoneNumber.includes('987654321')) {
        isValid = false
    } else if (phoneNumber.length <= 9 || phoneNumber.length >= 11) {
        isValid = false
    } else {
        isValid = true

    }
    return isValid
}

export function getFullName(name: string, middle: string, last: string) {
    return CapitalizeText(`${name} ${middle} ${last}`);
}

export function CapitalizeText(text: string) {
    return text.split(' ').map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
}
