import React from "react";

function VisaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="16"
      fill="none"
      viewBox="0 0 26 16"
    >
      <path fill="#FF9800" d="M17.143 0a8 8 0 100 16 8 8 0 000-16z"></path>
      <path fill="#D50000" d="M8 0a8 8 0 100 16A8 8 0 008 0z"></path>
      <path
        fill="#FF3D00"
        d="M9.143 8a7.986 7.986 0 003.428 6.56A7.986 7.986 0 0016 8a7.986 7.986 0 00-3.429-6.56A7.986 7.986 0 009.143 8z"
      ></path>
    </svg>
  );
}

export default VisaIcon;