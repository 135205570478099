import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M7.944 9.292a1.52 1.52 0 00-.444 1.073V12.5h2.148c.403 0 .79-.16 1.075-.445l6.333-6.337a1.519 1.519 0 000-2.147l-.626-.626a1.519 1.519 0 00-2.148 0L7.944 9.292z"
            ></path>
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M17.5 10c0 3.536 0 5.303-1.098 6.402C15.303 17.5 13.535 17.5 10 17.5c-3.536 0-5.303 0-6.402-1.098C2.5 15.303 2.5 13.535 2.5 10c0-3.536 0-5.303 1.098-6.402C4.697 2.5 6.465 2.5 10 2.5"
            ></path>
        </svg>
    );
}

export default Icon;