import React from "react";
import CustomContainer from "../../organisms/Container/Container";
import ServerCrash from "../../organisms/ServerCrash";
const ServerCrashTemplate: React.FC = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 40 }}>
            <CustomContainer childComponent={<ServerCrash />} />
        </div>
    );
};

export default ServerCrashTemplate;