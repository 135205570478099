import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import './styles.css';
import React, { useState, useEffect } from 'react';
import MemberShipCard from '../organisms/MembershipCard/MemberShipCard';
import MembershipButton from '../organisms/Membership/MembershipCoverage/MembershipDetailsCards/MembershipButtons';
import { MembershipType } from '../organisms/Membership/MembershipMainValues';
import Paper from '@mui/material/Paper';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import ActiveStepIndicator from './ActiveStepIndicator';
import DesactiveStepIndicator from './DesactiveStepIndicator';
import useFetch from '../../hooks/useFetch';
import { useSelector } from 'react-redux';
import { apiLoading, apiSuccess, apiError } from '../../api/productsSlice';
import ActiveGoldenStepIndicator from './ActiveGoldenStepIndicator';
import MembershipItem from '../organisms/Membership/MembershipCard/MemberShipItem/MembershipItem';
import MembershipDetailCard
    from '../organisms/Membership/MembershipCoverage/MembershipDetailsCards/MembershipDetailCard';
import celular from "../templates/Celular";
import SliderContent from "../organisms/Slider/SliderContent"
import TabMembership from "../organisms/TabMembership/TabMembership";
import MemberShipCardNewDesign from "../organisms/MembershipCard/MemberShipCardNewDesign";
import { themeValues, myColorPalette } from '../../../src/theme/themeGenerated';


export interface IMemberShipData {
    title: string;
    descriptions: Array<string>;
}

interface Membership {
    title?: string;
    description?: string;
    image?: string;
    typeCurrent: MembershipType;
    category?: string;
    newProductRegistration?: boolean;
    isAlliance?: boolean;
    //data: IMemberShipData;
}


const MemberShipSlider: React.FC<Membership> = ({ title, typeCurrent, newProductRegistration, isAlliance }) => {
    const { fetchData } = useFetch();
    const url: string =
        'catalogs/products';

    const allianceSettingResponse = useSelector((state: any) => state.allianceSetting.response);


    const fetchingData = () => {
        return fetchData(url, 'GET', {}, {}, apiLoading, apiSuccess, apiError);
    };

    const response = useSelector((state: any) => state.productsCards.response) as
        | any
        | null;
    const currentValue = useSelector((state: any) => state.productsCards.currentValue) as
        | any
        | null;
    const loading = useSelector((state: any) => state.productsCards.loading);

    type Producto = {
        image: string;
        description: string;
        title: string;
        type: string;
        category: string;
        data: Array<IMemberShipData>;
    };

    let [dataApi, setDataApi] = useState<Producto[]>([]);

    const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

    const { breakpoints } = useTheme();

    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const isTablet = useMediaQuery(breakpoints.down('md'));

    const [isActive, setIsActive] = useState(true);
    const [typeCategory, setTypeCategory] = useState(isAlliance ? 'salud' : "celular");

    const maxStepss = dataApi.filter(
        membership => membership.type === 'free' && membership.category === "celular"
    ).length;

    const maxStepssPremium = dataApi.filter(
        membership => membership.type === 'premium' && membership.category === "celular"
    ).length;

    const freeMemberships = dataApi.filter(
        membership => {
            return membership.type === 'free' && membership.category === "celular"
        }
    );

    const freeMembershipsSalud = dataApi.filter(
        membership => {
            return membership.type === 'free' && membership.category === "salud"

        }
    );

    const premiumMemberShipsSalud = dataApi.filter(
        membership => membership.type === 'premium' && membership.category === "salud"
    );

    const premiumMemberShips = dataApi.filter(
        membership => membership.type === 'premium' && membership.category === "celular"
    );

    const asignMembbership = newProductRegistration ? currentValue?.type || 'free' : 'free'

    let [type, setType] = useState<MembershipType>(asignMembbership);

    const [dinamycType, setDinamycType] = useState('');

    useEffect(() => {
        setDinamycType(asignMembbership);
    }, []);

    useEffect(() => {
        fetchingData();
    }, []);

    useEffect(() => {
        if (response != null && response?.code === 200) {
            const data = response.data.products;
            setDataApi(data);
        }
    }, [response, loading]);

    const currentIndicatorValue = () => {
        if (dinamycType === 'premium') {
            return maxStepssPremium;
        } else if (dinamycType === 'free') return maxStepss;
    };

    const currentMemberShipsValues = () => {
        if (dinamycType === 'premium') {
            return premiumMemberShips;
        }
        return freeMemberships;
    };

    const currentMemberShipsSaludValues = () => {
        if (dinamycType === 'premium') {
            return premiumMemberShipsSalud;
        }
        return freeMembershipsSalud;
    };

    const onClickHandlerModule = (value: MembershipType) => {
        setDinamycType(value);
        setType(value);
        setIsActive(!isActive);
        // window.dataLayer.push({
        //     event: 'TRUS_nuestros_seguros',
        //     tipo_seguro: value == "free" ? "De regalo" : "Premium",
        //     seguro: "Celular Protect"
        // })
    };

    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    const [arrayCard, setArrayCard] = useState([]);

    const unionCards = (celularArray: any, saludArray: any, typeCategory: string, newProductRegistration: boolean) => {
        const new_array: any = typeCategory === "celular" ? celularArray : saludArray;
        let array_union: any = []
        if (new_array.length > 0) {
            array_union = new_array.map((card: any, id: any) => {

                if (dinamycType === card?.type) {
                    return (
                        <MemberShipCardNewDesign
                            key={id}
                            idCard={id}
                            isAlliance={isAlliance}
                            cardTitle={card.category === 'celular' ? card.title : 'Selecciona una cobertura para crear tu plan ideal'}
                            cardDescription={card.description}
                            cardImage={card.category === 'celular' ? card.title : 'Seguro Inter Salud '}
                            cardImageTitle={card.title}
                            cardType={card.category === 'celular' ? card.type : typeCurrent === "premium" ? "premium" : card.type}
                            cardCategory={card.category}
                            cardData={card.data}
                            cardSubtype={card.category === 'celular' ? '' : typeCurrent === "premium" ? "premium" : "freemium"}
                            newProductRegistration={newProductRegistration}
                        />
                    );
                }

            })
        }
        return array_union;

    }

    useEffect(() => {
        if (newProductRegistration && currentValue?.category) {
            setTypeCategory(currentValue.category)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentValue?.category])

    return (
        <>
            {/*Version con slider*/}
            {/* <div id='my-section'>
                <Box
                    id='my-section'
                    sx={{
                        backgroundColor: '#FFF',

                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Paper
                        square
                        elevation={0}
                        sx={{
                            width: isDesktop ? '100%' : 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            gap: '24px',
                            pl: 0,
                            bgcolor: 'background.default',
                        }}
                    >
                        <Typography
                            color={'var(--black, #212121)'}
                            fontSize={'24px'}
                            fontWeight={700}
                            style={{
                                cursor: 'pointer',
                            }}
                            textAlign={'center'}
                        >
                            {title}
                        </Typography>
                        {title && (
                            <div className='container-gallery-buttons'>
                                <MembershipButton
                                    newStyle={{
                                        width: '100%'
                                    }}
                                    type={type}
                                    onClickSubType={onClickHandlerModule}
                                />
                            </div>
                        )}
                    </Paper>

                    <Box
                        style={{
                            width: '100%',
                            justifyContent: 'center',
                            display: 'flex',
                            marginTop: '24px'
                        }}
                    >
                        <SliderContent
                            arrayComponent={unionCards(currentMemberShipsValues(), currentMemberShipsSaludValues())}
                            type={dinamycType}

                        />
                    </Box>

                    {isMobile && false ? (
                        <div className='indicator-slider-container'>
                            {[...Array(currentIndicatorValue())].map((step, index) => {
                                return (
                                    <span
                                        key={index}
                                        onClick={() => setActiveStep(index)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '0 5px',
                                        }}
                                    >
                                        {index === activeStep ? (
                                            <>
                                                {dinamycType === 'premium' ? (
                                                    <ActiveGoldenStepIndicator />
                                                ) : (
                                                    <ActiveStepIndicator />
                                                )}
                                            </>
                                        ) : (
                                            <DesactiveStepIndicator /> || 'indicator'
                                        )}
                                    </span>
                                );
                            })}
                        </div>
                    ) : (
                        <div></div>
                    )}
                </Box>
            </div> */}

            {/*Version solo celular */}
            <div id='my-section'>
                {!newProductRegistration && <Box
                    id='my-section'
                    sx={{
                        backgroundColor: '#FFF',

                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Paper
                        square
                        elevation={0}
                        sx={{
                            width: isDesktop ? '100%' : 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            gap: '24px',
                            pl: 0,
                            mb: 5,
                            bgcolor: 'background.default',
                        }}
                    >
                        <Typography
                            color={'var(--black, #212121)'}
                            fontSize={'24px'}
                            fontWeight={700}
                            style={{
                                cursor: 'pointer',
                                // background: 'red'
                            }}
                            textAlign={'center'}
                        >
                            {title}
                        </Typography>

                    </Paper>
                </Box>}


                <Box sx={{
                    width: isDesktop ? '100%' : 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '24px',
                    pl: 0,

                }}>

                    {!isAlliance && !newProductRegistration &&
                        <Box
                            sx={{
                                justifyContent: 'center',
                                width: {
                                    sx: '100%',
                                    sm: '80%',
                                    md: '49%'
                                }
                            }}>
                            <TabMembership typeCategory={typeCategory} setTypeCategory={setTypeCategory} />
                        </Box>}

                    <Box sx={{
                        display: 'flex', flexDirection: 'column',
                        width: { sx: '32%', sm: '80%', md: newProductRegistration ? '90%' : '49%' },
                        alignItems: 'center',
                        border: dinamycType === "free" ?
                            isAlliance ? '1px solid #000' : `1px solid ${window.location.pathname === "/finsus" ? myColorPalette.getfinsusColor() : 'rgb(3,158,204)'}` : '1px solid #E6B331',
                        borderRadius: '10px',
                    }}>{!isAlliance && title && (
                        <Box sx={{
                            padding: '18px'
                        }}>
                            <div className='container-gallery-buttons'>
                                <MembershipButton
                                    newStyle={{
                                        width: '100%',
                                        background: window.location.pathname === "/finsus" ? myColorPalette.getfinsusColor() : 'rgb(3,158,204)',

                                    }}
                                    type={type}
                                    tabHidden={!newProductRegistration ? false : currentValue?.type === "premium"}
                                    onClickSubType={onClickHandlerModule}
                                />
                            </div>
                        </Box>
                    )}

                        {
                            unionCards(currentMemberShipsValues(), currentMemberShipsSaludValues(), typeCategory, (newProductRegistration || false))
                        }
                    </Box>

                </Box>
            </div>
        </>

    );
};

export default MemberShipSlider;
