import React, { useState } from 'react';
import styled from 'styled-components';
import { CustomButton } from '../../../atoms/Buttons/Buttons';
import { ArrowBack } from '@mui/icons-material';
import { Avatar } from '@material-ui/core';
import CellPhoneRepairMani from '../../../../assets/CellphoneRepairMani/CellPhoneRepairMani.svg';
import { CustomTypography } from '../../../atoms/Label/Label';
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from '../../../molecules/CellPhoneRepairButtons';
import { useSelector, useDispatch } from 'react-redux';
import { IUserInfo } from '../../../organisms/CustomerPortalContent/interfaces/user.interfaces';
import { IStorageMembershipData } from '../../../../utils/localStorageKeys';
import { Box } from "@mui/material";
import CustomContainer from "../../../organisms/Container/Container";
import {
    apiSuccess as apiUserSuccess,
    apiError as apiUserError,
    apiLoading as apiUserLoading
} from '../../../../api/userSlice';
import useFetch from "../../../../hooks/useFetch";

import broken_phone from '../../../../assets/CellPhoneData/BrokenPhone.svg';
import broken_phone_white from '../../../../assets/CellPhoneData/BrokenPhoneWhite.svg'

import CellData from '../../ConfirmCellphoneData/Forms/CellData';
import BackButtonInter from '../../../atoms/Buttons/BackButtonInter';
import { MembershipColor } from '../../../organisms/Membership/MembershipMainValues';
import { ConvertPlanToMembershipItem } from '../../../organisms/CustomerPortalContent/utils/convertPlantoMembership';
import { MembershipPlanItem } from '../../../organisms/CustomerPortalContent/interfaces/membership';

const ContainerDiv = styled.div`
    border-radius: 5px 5px 0 0;
`;

const TitleDiv = styled.div`
    display: flex;
    font-weight: 700;
    font-size: 20px;
    text-align: left;
    justify-content: center;
    align-items: center;
    gap: 35px;
    padding: 16px;
`;

const DescriptionDiv = styled.div`
   
    border-radius: 5px 5px 0 0;
    font-size: 16px;
    font-weight: 400;
    padding: 16px;
`;

const EventsDiv = styled.div`
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    padding: 16px;
`;

const textStyle: React.CSSProperties = {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
    display: 'flex',
};

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-width: 558px;
    align-items: center;
    margin: auto;
    padding-top: 40px;
    padding-bottom: 40px;
`;

const InitScreen: React.FC<any> = () => {
    const currentMembership = useSelector((state: any) => state.user?.currentMembership);
    const [MembershipsPlans, setMembershipsPlans] = useState<MembershipPlanItem[]>([]);
    const [currentplan, setCurrentPlan] = useState<MembershipPlanItem>();

    const userResponse = useSelector((state: any) => state.user?.response);
    const [user, setUser] = useState<IUserInfo>(userResponse?.data?.user);
    const [repair, setRepair] = useState<boolean>(false);
    const [type, setType] = useState<string>("");
    const { fetchData } = useFetch();
    const name: string = `${user?.name} ${user?.middle_name} ${user?.last_name}`;
    const [color, setColor] = useState(MembershipColor.freeSecondary)
    let storage: IStorageMembershipData = {
        ...useSelector((state: any) => state.productsCards.currentValue),
    };

    const userIsLogged = sessionStorage.access_token;
    const getUser = () => {
        return fetchData('users/auth/get-user', 'GET', {}, {}, apiUserLoading, apiUserSuccess, apiUserError);
    }

    React.useEffect(() => {
        if (userIsLogged) {
            getUser();
        } else {
        }
    }, []);

    React.useEffect(() => {
        if (userResponse && userResponse.data && userResponse.data.plans) {
            const plans = ConvertPlanToMembershipItem(userResponse?.data?.plans);
            setMembershipsPlans(plans);
            if (MembershipsPlans && MembershipsPlans.length > 0) {
                setCurrentPlan(MembershipsPlans[currentMembership]);
            }
        }
    }, [userResponse])

    React.useEffect(() => {
        if (userResponse?.length !== 0 && userResponse?.data?.user?.name) {
            // const tmpType = userResponse?.data?.user?.benefit_id === "C01" ? 'free' : 'premium'
            const tmpPlans = userResponse?.data?.plans
            if (tmpPlans) {
                const tmpType = tmpPlans.find((plan: any) => plan.plan === "P01")
                if (tmpType?.tier === 1) {
                    setType("premium")
                } else {
                    setType("free")
                }
            }
            setUser(userResponse?.data?.user);
        }

    }, [userResponse?.code]);


    React.useEffect(() => {
        window.dataLayer.push({
            'event': 'TRUS_solicitud_reparacion_v',
            tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium",//premium | gratuito
            'correo': user?.email,
            'telefono': user?.phone,
            'page_location': window.location.href
        });

    }, [])


    return (
        <CustomContainer childComponent={
            <>
                <FormWrapper>
                    {repair ? <>
                        <div style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            width: '100%'
                        }}>
                            <CustomButton
                                text={'Regresar'}
                                onClick={() => setRepair(!repair)}
                                size={'medium'}
                                startIcon={<ArrowBack />}
                                variant={'contained'}
                                style={{
                                    color: '#039ECC',
                                    backgroundColor: 'transparent',
                                    fontSize: '1rem',
                                    textTransform: 'none',
                                    fontWeight: '600',
                                    fontStyle: 'normal',
                                    padding: '8px 10px',
                                    minWidth: '64px',
                                    border: 'none',
                                    justifyContent: 'flex-start',
                                    width: 'fit-content',
                                }}
                            />
                        </div>
                        <CellData />
                    </> : <>
                        <Box sx={{
                            width: '100%',
                        }}>
                            <BackButtonInter />
                        </Box>
                        <ContainerDiv
                            style={{
                                backgroundColor: type === "premium" ? MembershipColor.premiumSecondary : MembershipColor.freeSecondary,
                                color: type === "premium" ? 'white' : 'blac'
                            }}
                        >
                            <TitleDiv>
                                <img src={type === "premium" ? broken_phone_white : broken_phone} style={{ marginLeft: '20px' }} /> Reparación de pantalla
                            </TitleDiv>
                            <DescriptionDiv style={{
                                color: 'black',
                                backgroundColor: type === "premium" ? MembershipColor.premiumOpacity : MembershipColor.freeOpacity
                            }}>Te cubrimos con hasta $4,000 MXN para que la repares (No aplica otro tipo de daño), con un copago de $400 MXN.</DescriptionDiv>
                            <EventsDiv>Eventos disponibles al año: {type === "premium" ? "3" : "1"}

                            </EventsDiv>
                        </ContainerDiv>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Avatar
                                style={{
                                    maxWidth: 250,
                                    width: '100%',
                                    height: 'auto',
                                    margin: '20px',
                                }}
                                alt='Remy Sharp'
                                src={CellPhoneRepairMani}
                                variant='square'
                            />
                        </div>
                        <div style={textStyle}>
                            <p style={{ fontSize: '22px', textAlign: 'center' }}>
                                Hola <strong>{name}</strong>, lamentamos que tu celular se haya
                                dañado, pero no te preocupes estamos aquí para ayudarte
                            </p>
                        </div>
                        <div>
                            <PrimaryButton
                                text={'Solicitar reparación'}
                                onClick={() => {
                                    window.dataLayer.push({
                                        event: 'TRUS_solicitud_reparacion',
                                        tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium",//premium | gratuito
                                        telefono: user?.email,
                                        celular: user?.phone,
                                        boton_contacto: 'Solicitar reparacion',
                                    })
                                    setRepair(!repair)
                                    // navigate('/confirmar-datos/1')
                                }}
                            />
                        </div>
                    </>
                    }
                </FormWrapper>
            </>

        } />
    );
};

export default InitScreen;
