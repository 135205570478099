import React from "react";
import { CustomTypography } from "../../atoms/Label/Label";
import ChoosePaymentMethod from "../../atoms/ChoosePaymentMethod";
import { styled } from "styled-components";
import Logo from "../../../assets/Logo/Logo";
import { useMediaQuery } from "@mui/material";

const PaymentMethodWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

const PaymentMethod: React.FC = () => {
  const matches = useMediaQuery('(min-width:600px)');

  const extraPaymentMethods = [
    {title: 'mercadopago', icon: <Logo />},
    {title: 'stori', icon: <Logo />},
    {title: 'undostres', icon: <Logo />}
  ]

  return (
    <PaymentMethodWrapper>
        <CustomTypography
            style={{
                color: 'var(--Black, #212121)',
                fontSize: 16,
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                maxWidth: 580,
                width: '100%'
            }}
        >Medio de Pago</CustomTypography>
        <ChoosePaymentMethod extraPaymentMethods={matches ? extraPaymentMethods : []} />
        {/* <CreditContentLogo /> */}
    </PaymentMethodWrapper>
  );
};

export default PaymentMethod;
