import React, { useState } from "react";
import Logo from "../../../assets/Logo/Logo";
import { styled } from "styled-components";

type Props = {
    height: string;
}

const LogoCreditWrapper = styled.div<Props>`
    display: flex;
    padding: 40px 16px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--black-25, #C7C7C7);
    background: var(--White, #FFF);
    height: ${(props) => props.height};
    @media (min-width: 600px) {
      max-width: 294px;
    }
`;

type CreditContentLogoProps = {
    elementContent?: React.ReactNode;
}

const CreditContentLogo: React.FC<CreditContentLogoProps> = ({ elementContent }) => {

  return (
    <LogoCreditWrapper height={elementContent ? '119px' : '179px'}>
        <Logo />
        { elementContent }
    </LogoCreditWrapper>
  );
};

export default CreditContentLogo;
