import React from 'react';
import AppleIcon from '@mui/icons-material/Apple';
import { Box, Button, Typography } from '@mui/material';

export const AppleButton: React.FC = () => {
  return (
    <Button
      variant={'outlined'}
      size={'small'}
      style={{
        color: '#FFFFFF',
        borderColor: '#',
        borderRadius: '10px',
        paddingLeft: '1px',
        border: "1px solid #FFFFFF"
      }}
    >
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <AppleIcon sx={{ fontSize: 32 }} />
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
          <Typography
            variant={'caption'}
            color={'#FFFFFF'}
            style={{ textTransform: 'none', fontSize: '9px' }}
          >
            {'Descargalo en'}
          </Typography>
          <Typography
            variant={'body2'}
            color={'#FFFFFF'}
            fontWeight={'bold'}
            style={{ textTransform: 'none', marginTop: '-2px' }}
          >
            {'App store'}
          </Typography>
        </Box>
      </Box>
    </Button>
  );
};
