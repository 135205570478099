import React, { useEffect, useState } from 'react';
import './MembershipDetailCard.css'
import MembershipItem, { BenefitsDetails } from '../../MembershipCard/MemberShipItem/MembershipItem';
import { MembershipCategoryType, MembershipColor, MembershipDataCelular, MembershipDataSalud, MembershipInfo, MembershipSubType, MembershipType } from '../../MembershipMainValues';
import { Button, ButtonGroup } from '@mui/material';
import MembershipButton from './MembershipButtons';
import LocalStorageManager from '../../../../../utils/localStorageManager';
import { IStorageMembershipData, STORAGE } from '../../../../../utils/localStorageKeys';

interface MembershipCardProps {
    type: MembershipType,
    subType: MembershipSubType,
    benefits: BenefitsDetails[],
    extraBenefits: BenefitsDetails[]
    category: MembershipCategoryType,
    price: number,
    changeSubType: Function
}

const MembershipDetailCard: React.FC<MembershipCardProps> = ({ type, subType, benefits, extraBenefits, category, price, changeSubType }) => {
    const [subTypeState, setSubTypeState] = useState<MembershipSubType>(subType);
    const manager = new LocalStorageManager();
    const storage = manager.getItem<IStorageMembershipData>(STORAGE.memershipData);
    const onClickChangeSubType = (value: MembershipSubType) => {
        setSubTypeState(value);
        changeSubType(value);
    }
    useEffect(() => {
        if (storage && storage.subtype) {
            setSubTypeState(storage.subtype);
        }
    })
    const renderBenefitList = (array: BenefitsDetails[]) => {
        return array.map((element, index) => {
            return <div key={index} >
                <MembershipItem cardType={type} title={element.title} description={element.description} icon={element.icon} />
            </div>

        })
    }

    const renderExtraBenefitList = (array: BenefitsDetails[]) => {
        return array.map((element, index) => {
            return <MembershipItem cardType={type} key={'extra_' + index} title={element.title} description={element.description} icon={element.icon} />
        })
    }

    return (
        <>
            <div className="containerCard " style={type === 'free' ? { border: '1px solid ' + MembershipColor.free } : { border: '1px solid ' + MembershipColor.premium }}>
                <div className="MembershipCardDetail">
                    {type === 'premium' ? <div className="premiumControls">
                        <div className='PremiumText'>
                            <p>
                                Por tan sólo <strong> ${price} pesos al mes</strong> llevate tu <strong>Seguro Inter {category} {category == 'celular' ? type : subType}</strong>
                            </p>
                        </div>
                        <div className="buttonPremium" style={{ display: 'none' }}>
                            {
                                category === 'salud' ?
                                    <ButtonGroup className="buttonGroup" size="small" aria-label="small button group">
                                        <Button className={"itemGroup" + (subTypeState === 'esencial' ? " itemGroupActive " : "")} onClick={() => { onClickChangeSubType('esencial') }}>Esencial</Button>
                                        <Button className={"itemGroup" + (subTypeState === 'superior' ? " itemGroupActive " : "")} onClick={() => { onClickChangeSubType('superior') }}>Superior</Button>
                                        <Button className={"itemGroup" + (subTypeState === 'elite' ? " itemGroupActive " : "")} onClick={() => { onClickChangeSubType('elite') }}>Élite</Button>
                                    </ButtonGroup>
                                    :
                                    ''
                            }

                        </div>
                    </div> : ''}
                    <div className="details">
                        <div className="labelBenefit" style={type === 'free' ? { background: MembershipColor.freeSecondary } : { background: MembershipColor.premiumSecondary }}>
                            <p>
                                <strong>
                                    Coberturas incluida
                                </strong>
                            </p>
                        </div>
                        <div className="benefitsList">
                            {renderBenefitList(benefits)}
                        </div>
                        <div className="labelBenefit" style={type === 'free' ? { background: MembershipColor.freeSecondary } : { background: MembershipColor.premiumSecondary }}>
                            <p>
                                <strong>
                                    Además, tu seguro incluye:
                                </strong>
                            </p>
                        </div>
                        <div className="benefitlist">
                            {renderExtraBenefitList(extraBenefits)}
                        </div>


                    </div>
                </div>
                <div style={{ height: '80px' }}>

                </div>
            </div>
        </>)
}

export default MembershipDetailCard;