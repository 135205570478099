
import { createSlice } from '@reduxjs/toolkit';






const paymentSlice = createSlice({
    name: 'paymentPeriod',
    initialState: { period: 0 },
    reducers: {
        setPeriod: (state, action) => {
            state.period = action.payload;
        },
    },
});


/**
 * 
 * @param state 
 * @returns 
 */


export const { setPeriod } = paymentSlice.actions;

export default paymentSlice.reducer;
