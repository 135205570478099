import { Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
const PageNotFound = () => {
    return (<>
        <Box>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                gap: '24px',
                margin: '10px',
                marginTop: '100px',
                marginBottom: '100px'
            }}>
                <Typography>
                    <span style={{
                        margin: '15px',
                    }}>
                        <ErrorOutlineIcon />
                    </span>
                    <strong>
                        404 Pagina no encontrada
                    </strong>
                </Typography>
            </div>

        </Box>
    </>)
}

export default PageNotFound;