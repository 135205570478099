import React from 'react';
import Avatar from './assets/banner.png';
import "./MembershipPremiumUpdate.css";
import { Box, Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { MembershipCategoryType, MembershipSubType, MembershipType } from '../../MembershipMainValues';
import { setCurrentValue } from '../../../../../api/productsSlice';
import { useDispatch } from 'react-redux';
type MembershipPremiumUpdateProps = {
    cardType?: MembershipType,
    cardCategory?: MembershipCategoryType,
    cardTitle?: string,
    cardDescription?: string,
    subType?: MembershipSubType
}

const MembershipPremiumUpdate: React.FC<MembershipPremiumUpdateProps> = ({
    cardType,
    cardCategory,
    cardDescription,
    cardTitle,
    subType
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    return (
        <>
            <Box>
                <div className="cardPremium">
                    <div className="imagePremium">
                        <img src={Avatar} className='imageFilePremium'></img>
                    </div>
                    <div className="textPremium">
                        <div className='text'>
                            <p>
                                Con nuestro seguro premium puedes ahorrarte hasta $15,000 pesos al año <br /> <strong style={{ fontWeight: 'bolder' }}>¡Pagando sólo 90 pesos al mes!</strong>
                            </p>
                        </div>
                    </div>
                    <div className="buttonPremium">
                        <button className='butttonItem' type='button' onClick={() => {
                            const sendDataLocalStorage = {
                                type: cardType,
                                subtype: subType,
                                category: cardCategory,
                                cardTitle: cardTitle,
                                cardDescription: cardDescription
                            };

                            // localStorage.setItem('myObjectKey', JSON.stringify(sendDataLocalStorage));
                            dispatch(setCurrentValue(sendDataLocalStorage));
                            navigate('/cobertura')
                        }}>
                            QUIERO SER PREMIUM
                        </button>
                    </div>
                </div>
            </Box>

        </>)
}

export default MembershipPremiumUpdate