import React, { useContext, useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import {
    Toolbar,
    IconButton,
    Fade,
    Menu,
    MenuItem,
    Stack,
    Divider, Box,
    Link as LinkMui
} from '@mui/material';
import Logo from '../../../../assets/Logo/Logo';
import SubMenu from '../../../molecules/SubMenu/SubMenu';
import { CustomTypography } from '../../../atoms/Label/Label';
import { CustomButton } from '../../../atoms/Buttons/Buttons';
import { ContextAuth0 } from '../../../../auth/contextAPIProvider';
import { logout } from '../../../../auth/auth0';
import { useDispatch, useSelector } from 'react-redux';
import { restartValue } from '../../../../api/loginSlice';
import { restartValue as restartBeneficiary } from '../../../../api/multipleBeneficiarySlice';
import { restartValue as restartAllianceSetting } from '../../../../api/allianceSettingSlice';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const ToolbarElement = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  gap: 8rem;
`;

const MenuContentElement = styled.div`
    display: flex; 
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
`;

const MenuSeccionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
`;

interface MenuContentProps {
    setOpenModal: any;
    body: Array<any>;
    visibleMenu: boolean;
    isAuthState: boolean;
    isAlliance?: any;
    desktopStylish?: object;
}

const MenuContent: React.FC<MenuContentProps> = ({ isAuthState, setOpenModal, body, visibleMenu, isAlliance, desktopStylish }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [eventHandler, setEventHandler] = React.useState<null | HTMLElement>(null);
    const openMenuHandler = Boolean(eventHandler);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const webAuth: any = useContext(ContextAuth0);
    const [logoAliance, setLogoAliance] = useState('');
    const [alianceName, setAlianceName] = useState('');
    const currentPath = window.location.pathname;

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(open ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openMenu = (event: React.MouseEvent<HTMLElement>) => {
        setEventHandler(eventHandler ? null : event.currentTarget);
    };

    const closeMenu = () => {
        setEventHandler(null);
    };
    const closeSession = () => {
        sessionStorage.setItem('userError', '2');
        sessionStorage.removeItem('user-storage');
        sessionStorage.removeItem('card-type-storage');
        dispatch(restartValue());
        dispatch(restartBeneficiary());
        dispatch(restartAllianceSetting())
        localStorage.removeItem("persist:root")
        logout(webAuth);
    };

    useEffect(() => {
        const img = isAlliance?.data?.imagenes?.logo || isAlliance?.imagenes?.logo;
        setLogoAliance(img);
        setAlianceName(isAlliance?.data?.name || isAlliance?.name);
    }, [isAlliance]);

    return (
        <React.Fragment>
            <ToolbarElement sx={{
                display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' },
                justifyContent: 'space-between'
            }}>
                <Stack
                    /* style={{ flexShrink: 0 }}*/
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    divider={<Divider orientation="vertical" flexItem />}
                >
                    {
                        logoAliance &&
                        <span style={{ flexShrink: 0 }}>
                            <LinkMui
                                onClick={() => (alianceName && alianceName !== '')
                                    ? navigate(alianceName === "Finsus" ? `/${alianceName.toLocaleLowerCase()}` : `/landing-alliance/${alianceName.toLocaleLowerCase()}`) : navigate('/')}
                                underline="none"
                            >
                                <Box component={'img'} src={logoAliance} sx={{ width: 'auto', height: 28 }} />
                            </LinkMui>
                        </span>
                    }
                    <span style={{ flexShrink: 0, marginLeft: "20px" }}>
                            <LinkMui
                                onClick={() => (alianceName && alianceName !== '')
                                    ? navigate(alianceName === "Finsus" ? `/${alianceName.toLocaleLowerCase()}` : `/landing-alliance/${alianceName.toLocaleLowerCase()}`) : navigate('/')}
                                underline="none"
                            >
                            <Logo />
                        </LinkMui>
                    </span>
                </Stack>

                <MenuSeccionWrapper>
                    {visibleMenu ? <Box sx={{ width: '100%' }}><MenuContentElement style={{ ...desktopStylish }}>
                        {
                            body.map(({ item, subItems, url, onClick, active, willBeRendered = true }) => {
                                return willBeRendered ? subItems ? <div key={item}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '4px'
                                    }}>
                                        <CustomTypography
                                            style={{
                                                fontSize: 16,
                                                fontWeight: 600
                                            }}
                                            item={item}
                                            handleClick={handleClick}
                                        />
                                        {open ? <ExpandLess style={{ color: '#000' }} /> : <ExpandMore style={{ color: '#000' }} />}

                                    </div>
                                    <Menu
                                        id="fade-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'fade-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        style={{
                                            top: '28px'
                                        }}
                                    >
                                        {
                                            subItems.map(({ label, onClick }: any) => <MenuItem key={label} onClick={(ev) => {
                                                onClick(ev);
                                            }}>{label}</MenuItem>)
                                        }
                                    </Menu>
                                </div>
                                    :
                                    url ?
                                        <Link
                                            key={item}
                                            target="_blank"
                                            to={url}
                                            style={{
                                                textDecoration: 'none',
                                                padding: '5px',
                                                color: 'black',
                                                fontSize: 16,
                                                fontWeight: 600,
                                                textAlign: 'center'
                                            }}
                                            onClick={() => {
                                                window.dataLayer.push({
                                                    event: "TRUS_menu",
                                                    option: item,
                                                })
                                            }}
                                        >
                                            <CustomTypography
                                                style={{
                                                    fontSize: 16,
                                                    fontWeight: 600
                                                }} item={item} />
                                        </Link>
                                        :
                                        <CustomButton
                                            key={item}
                                            text={item}
                                            onClick={onClick}
                                            size={"medium"}
                                            variant={"contained"}
                                            style={{
                                                color: active ? "#039ECC" : "#000000",
                                                backgroundColor: "transparent",
                                                fontSize: "14px",
                                                fontWeight: "600",
                                                fontStyle: "normal",
                                                padding: "8px 10px",
                                                minWidth: "64px",
                                                border: "none",
                                                justifyContent: "flex-start",
                                                width: "fit-content",
                                                textWrap: "nowrap"
                                            }}
                                        />
                                    :
                                    <></>
                            })
                        }
                        {!currentPath.includes('/registrate/') && !isAuthState && <CustomButton
                            text={"Iniciar sesión"}
                            onClick={() => navigate('/iniciar-sesion')}
                            size={"medium"}
                            variant={"contained"}
                            style={{
                                color: "#039ECC",
                                backgroundColor: "transparent",
                                fontSize: "14px",
                                fontWeight: "600",
                                fontStyle: "normal",
                                padding: "8px 10px",
                                minWidth: "64px",
                                border: "1.5px solid #039ECC",
                                justifyContent: "flex-start",
                                width: "fit-content",
                                textWrap: "nowrap"
                            }}
                        />}
                        {/* <Typography style={{ justifyContent: 'center', color: '#000' }}>
                            <CustomButton
                                text={'¿Necesitas ayuda?'}
                                onClick={() => setOpenModal(true)}
                                size={'medium'}
                                variant={'contained'}
                                startIcon={<PhoneIcon />}
                                style={{
                                    color: '#FFF',
                                    backgroundColor: 'rgb(3, 158, 204)',
                                    fontSize: '0.8125rem',
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    padding: '8px 10px',
                                    minWidth: '64px'
                                }}
                            />
                        </Typography> */}
                    </MenuContentElement></Box> : <div></div>}
                </MenuSeccionWrapper>
            </ToolbarElement>
            <ToolbarElement sx={{
                display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none', xl: 'none' },
                justifyContent: 'space-between',
                padding: 0
            }}>
                <MenuSeccionWrapper>
                    <Stack
                        /* style={{ flexShrink: 0 }}*/
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        divider={<Divider orientation="vertical" flexItem />}
                    >
                        {
                            logoAliance &&
                            <Link to={(alianceName) ? alianceName === "Finsus" ? '/finsus' : `/landing-alliance/${alianceName.toLocaleLowerCase()}` : "/"} style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Box component={'img'} src={logoAliance} sx={{ width: 'auto', height: 28 }} />
                            </Link>
                        }
                        <Link to={(alianceName) ? alianceName === "Finsus" ? '/finsus' : `/landing-alliance/${alianceName.toLocaleLowerCase()}` : "/"} style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Logo />
                        </Link>
                    </Stack>
                    {
                        !isAlliance &&
                        <>
                            {currentPath === '/' && !isAuthState && <CustomButton
                                text={"Iniciar Sesion"}
                                onClick={() => {
                                    window.dataLayer.push({
                                        event: "TRUS_menu",
                                        option: "Iniciar sesion"
                                    })
                                    navigate('/iniciar-sesion')
                                }}
                                size={"medium"}
                                variant={"contained"}
                                style={{
                                    color: "#039ECC",
                                    backgroundColor: "transparent",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    fontStyle: "normal",
                                    padding: "8px 10px",
                                    minWidth: "64px",
                                    border: "none",
                                    justifyContent: "flex-start",
                                    width: "fit-content",
                                    textWrap: "nowrap"
                                }}
                            />}
                            {false && isAuthState && <CustomButton
                                text={"Cerrar Sesión"}
                                onClick={closeSession}
                                size={"medium"}
                                variant={"contained"}
                                style={{
                                    color: "#039ECC",
                                    backgroundColor: "transparent",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    fontStyle: "normal",
                                    padding: "8px 10px",
                                    minWidth: "64px",
                                    border: "none",
                                    justifyContent: "flex-start",
                                    width: "fit-content",
                                    textWrap: "nowrap"
                                }}
                            />}
                        </>
                    }
                    {visibleMenu ? <IconButton
                        size="large"
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={openMenu}
                    >
                        <MenuIcon style={{
                            fill: '#000',
                            width: 32,
                            height: 32
                        }}
                        />
                    </IconButton> : <div></div>}

                </MenuSeccionWrapper>

                <Menu
                    id="menu-appbar"
                    anchorEl={eventHandler}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={openMenuHandler}
                    onClose={closeMenu}
                    TransitionComponent={Fade}
                    style={{
                        color: '#000',
                        textTransform: 'none',
                        fontWeight: 600,
                        fontSize: '16px'
                    }}
                    PaperProps={{ sx: { width: '800px' } }}
                >
                    {
                        body.map(({ item, subItems, url, onClick, willBeRendered = true }) => {
                            return willBeRendered ? subItems ?
                                <SubMenu
                                    key={item}
                                    label={item}
                                    items={subItems}
                                />
                                :
                                url ?
                                    <MenuItem key={item} style={{ justifyContent: 'left' }} onClick={() => {
                                        window.open(url, '_blank');
                                        closeMenu();
                                    }}><b>{item}</b></MenuItem>
                                    :
                                    <MenuItem key={item} style={{ justifyContent: 'left' }} onClick={(ev) => {
                                        onClick(ev);
                                        closeMenu();
                                    }}>
                                        <b>{item}</b>
                                    </MenuItem>
                                :
                                <></>
                        })
                    }
                </Menu>
            </ToolbarElement>
        </React.Fragment>
    );
};

export default MenuContent;
