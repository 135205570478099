import React from 'react';
import AllianceRegisterComponent from '../../components/templates/AllianceRegister';

const AllianceRegister: React.FC = () => {
  // Implementa el componente aquí
  return (
    <React.Fragment>
      <AllianceRegisterComponent />
    </React.Fragment>
  );
};

export default AllianceRegister;
