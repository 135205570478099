import React, { FC } from 'react';
import styled from 'styled-components';

import giffy from '../../../../assets/payment/gifgif.gif';
import fail from '../../../../assets/payment/fail.gif';
import { CustomButton } from '../../../../components/atoms/Buttons/Buttons'
import { MessageType } from './interfaces';

interface IPropsBeneficiaryModal {
  message: MessageType;
  setOpenModal: any;
}

const BeneficiaryModal: FC<IPropsBeneficiaryModal> = ({ message, setOpenModal }) => {

  const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    width: 328px;
    height: 376px;
    padding: 16px;
    border-radius: 8px
  `;

  const Text = styled.div`
    margin-top: 25px;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  `;

  return (
    <>
      <Container>
        <img src={message.error ? fail : giffy} width="224px" height="224px" />
        <Text>
          {message.text}
        </Text>
        <CustomButton
            onClick={() => setOpenModal(false)}
            text="Entendido"
            style={{
              position: 'absolute',
              bottom: '16px',
              color: "#FFFFFF",
              borderRadius: 8,
              border: "#039ECC",
              background: "#039ECC",
              textAlign: "center",
              fontSize: 16,
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              textTransform: "uppercase",
              padding: "14px 16px",
              width: "90%",
            }}
          />
      </Container>
    </>
  );
}

export default BeneficiaryModal