import { MembershipCategoryType, MembershipSubType, MembershipType } from "../components/organisms/Membership/MembershipMainValues";

export enum STORAGE {
    memershipData = "myObjectKey",
    FormObserver = "FormObserver"
}

export enum PREFIX  {
    app = "app"
}

export interface IStorageMembershipData{
    cardDescription:string;
    cardTitle:string;
    category:MembershipCategoryType
    type:MembershipType //TODO:Reemplazar por los tipados
    subtype:MembershipSubType
    business: string;
}

export interface IStorageFormObserver{
    firstName:string,
    lastName1: string,
    lastName2: string,
    birthDate:string,
    mexicanciticen: boolean,
    email:string,
    phone: string,
    acceptTerms: boolean,
    picked: string,
    validateNumber: string
    marca: string,
    modelo:string,
    imei: string,
    zipCode: string
}