import React from 'react';
import ErrorLoginTemplate from "../../components/templates/ErrorLogin";

type ErrorProps = {};

const ErrorLogin: React.FC<ErrorProps> = () => {
    return <ErrorLoginTemplate></ErrorLoginTemplate>;
};

export default ErrorLogin;
