import React from 'react';
import RegisterComponent from '../../components/templates/Register';

const Register: React.FC = () => {
  // Implementa el componente aquí
  return (
    <React.Fragment>
      <RegisterComponent />
    </React.Fragment>
  );
};

export default Register;
