import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { styled } from 'styled-components';

import FormContainer from '../Form/FormContainer';
import SignIn from '../Form/FormContent/SignIn';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { apiSuccess } from '../../../api/formSignInSlice';
import {
    apiError as apiRegisterError,
    apiLoading as apiRegisterLoading,
    apiSuccess as apiRegisterSuccess,
    restartValue
} from '../../../api/signUpSlice';
import {
    apiError as apiLoginError,
    apiLoading as apiLoginLoading,
    apiSuccess as apiLoginSuccess
} from '../../../api/loginSlice';
import useFetch from '../../../hooks/useFetch';
import { addAccessToken } from '../../../api/config';
import { fieldIsRequired } from '../../../utils/scrollToTop';
import { restartValue as restartUserAlliance } from '../../../api/userAlianceSlice';

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`;

const SignInForm: React.FC = () => {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState<number>(0);
    const dispatch = useDispatch();
    const response = useSelector((state: any) => state.formSignIn.response) as any | null;
    // const loadingSignIn = useSelector((state: any) => state.formSignIn.loading) as any | null;
    const data = useSelector((state: any) => state.formRegister.response) as any | null;
    const formikRef: any = useRef();

    const tracking = useSelector((state: any) => state.tracking.response) as any | null;
    const responseRegister = useSelector((state: any) => state.formRegister.response) as any | null;
    const productsCards = useSelector((state: any) => state.productsCards.response) as any | null;
    const currentValue = useSelector((state: any) => state.productsCards.currentValue) as any | null;
    const signInResponse = useSelector((state: any) => state.signUp.response) as any | null;
    const signInLoading = useSelector((state: any) => state.signUp.loading) as any | null;
    const loginResponse = useSelector((state: any) => state.login.response) as any | null;
    const loginError = useSelector((state: any) => state.login.error?.response?.data) as any | null;
    const benefits = useSelector((state: any) => state.gadgets.response) as any | null;
    const responseUserAlliance = useSelector((state: any) => state.userAliance.response) as
        | any
        | null;
    const allianceSettingResponse = useSelector((state: any) => state.allianceSetting.response);

    const { fetchData } = useFetch();

    const membershipTypeModule = {
        signIn: {
            formSteps: 0, //starting from 0
            steps: [], // steps title
            titles: [], // content title 
            initialValues: {
                phone: data?.phone || '',
                password: '',
                passwordConfirm: ''
            }
        }
    }

    const renderStepContent = (step: number, errors: any, handleChange: any, handleBlur: any, isSubmitting: any, validationFields: any) => {
        switch (step) {
            case 0:
                return (<SignIn handleChange={handleChange} handleBlur={handleBlur} errors={errors} isSubmitting={isSubmitting} validationFields={validationFields} loading={signInLoading} onClickSubmit={_handleSubmit} />);
            default:
                return null;
        }
    };

    const _sleep = (ms: number) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const _submitForm: any = async (values: any) => {
        sessionStorage.clear()
        fetchData('users/auth', 'POST', {
            event: 'register',
            content: values
        }, {}, apiRegisterLoading, apiRegisterSuccess, apiRegisterError);
    }

    const _handleSubmit = async ({ values }: any) => {
        if (window.location.pathname === '/iniciar-sesion') {
            logInWithCredentials();
        } else {
            const getProduct = productsCards.data.products.find((product: any) => product.category === currentValue.category && product.type === currentValue.type)
            const content = { ...responseRegister };
            content.tracking_id = tracking?.data?.tracking_id;
            content.status = true;
            content.tier = getProduct.type === "free" ? "freemium" : "premium";
            content.gender = content.gender === 'Hombre' ? 'h' : 'm'
            content.cp = Number(content.zipcode);
            content['plan_id'] = currentValue.plan_id;
            content.soy_mexicano = content.mexicanciticen;
            content.terms = content.acceptTerms;
            content.imei = null;
            content.phone = values.phone;
            content.password = values.password;
            content.id_brand = localStorage.id_brand;
            content.id_line = localStorage.id_line;
            content.id_model = localStorage.id_model;

            if (responseUserAlliance?.data?.register) {
                content.register = responseUserAlliance?.data?.register;
            }

            if (allianceSettingResponse?.data?.id_bussiness) {
                content.business = allianceSettingResponse?.data?.id_bussiness
            }

            if (responseUserAlliance?.data?.tracking_id) {
                content.tracking_id = responseUserAlliance?.data?.tracking_id
                content.id_aliance = responseUserAlliance?.data?.id_aliance
            }

            content['benefit_id'] = currentValue.benefit_id;
            _submitForm(content);
        }
    }

    const validationSchemaStep = Yup.object().shape({
        phone: Yup.string().phone('MX', 'Por favor, ingresa un número de teléfono válido en México').max(10, "maximo 10 caracteres").required(fieldIsRequired),
        password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).{10,}$/, "No cumple con las especificaciones para una contraseña segura").required(fieldIsRequired),
        passwordConfirm: Yup.string().oneOf([Yup.ref('password'),], 'Las contraseñas deben coincidir').required('Confirmar contraseña requerida'),
    })

    const validatorSchemaStepper = () => {
        return validationSchemaStep;
    }

    const logInWithCredentials = () => {
        fetchData('users/auth', 'POST', {
            event: 'login',
            content: { phone: response.phone, password: response.password }
        }, {}, apiLoginLoading, apiLoginSuccess, apiLoginError);
    }

    useEffect(() => {
        if (signInResponse && signInResponse?.data?.user) {
            //PENDIENTE -> asignar el access_token de signIn como token sin pasar por el login
            dispatch(restartValue());
            dispatch(restartUserAlliance());
            // logInWithCredentials();
            // navigate('/portal-del-cliente')
            sessionStorage.setItem("access_token", signInResponse?.data?.access_token);

            const redirectFunc = async () => {
                try {
                    await addAccessToken();

                    navigate('/portal-del-cliente', { replace: true, state: { newUser: true, showModalUpsell: true } });
                } catch (error) {
                    sessionStorage.removeItem("access_token");
                }
            }

            redirectFunc();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signInResponse]);

    useEffect(() => {
        if (loginResponse && loginResponse?.data?.user) {
            sessionStorage.setItem("access_token", loginResponse?.data?.token);

            const redirectFunc = async () => {
                try {
                    await addAccessToken();

                    navigate('/portal-del-cliente', { replace: true, state: { showModalUpsell: true } });
                } catch (error) {
                    sessionStorage.removeItem("access_token");
                }
            }

            redirectFunc();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginResponse]);

    useEffect(() => {
        if (loginError && loginError?.data) {
        }
    }, [loginError]);

    const getFormikReference = (formik: any) => {
        formikRef.current = formik;
    };

    return (
        <FormWrapper>
            <FormContainer
                buttonNext={false}
                initialValueImportant
                steps={membershipTypeModule.signIn.steps}
                initialValues={membershipTypeModule.signIn.initialValues}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                title={membershipTypeModule.signIn.titles}
                renderStepContent={renderStepContent}
                _handleSubmit={_handleSubmit}
                validatorSchemaStepper={validatorSchemaStepper}
                formObserverKey='FormObserverSignIn'
                formObserverSlice={apiSuccess}
                localData={response}
                formikRef={getFormikReference}
            />
        </FormWrapper>
    );
};

export default SignInForm;
