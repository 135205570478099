import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import CustomContainer from '../Container/Container';
import TermsAndConditionsContent from './TermsAndConditions';
import TermsAndConditionsContentAlliance from './TermsAndConditionsAlliance';

const TermsAndConditions: React.FC = () => {
    const response = useSelector((state: any) => state?.allianceSetting?.response)
    const userResponse = useSelector((state: any) => state?.user?.response);

    return (
        <Box sx={{ backgroundColor: '#FFF' }}>
            {response || (window.location.pathname === "/finsus" || userResponse?.data?.business?.name === 'Finsus')
                ? <CustomContainer childComponent={<TermsAndConditionsContent />} />
                : <TermsAndConditionsContentAlliance benefit_id={response?.data?.benefit_id} />
            }
        </Box>
    )
};

export default TermsAndConditions;