import React from 'react';
import MasterCardIcon from '../../../assets/HiringPaymentMembershipAssets/MasterCardIcon';
import VisaIcon from '../../../assets/HiringPaymentMembershipAssets/VisaIcon';
import { styled } from 'styled-components';

const IconsWrapper = styled.div`
    display: flex;
    gap: 16px;
`;

const IconsPaymentMethod: React.FC = () => {
    return <IconsWrapper>
        <VisaIcon />
        <MasterCardIcon />
    </IconsWrapper>
};

export default IconsPaymentMethod;
