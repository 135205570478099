import React from "react";
import {Avatar, Box, Container, Grid, useMediaQuery, useTheme} from "@mui/material";
import {CustomTypography} from "../../atoms/Label/Label";
import CustomContainer from "../Container/Container";
import {styled} from "styled-components";
import guy from '../../../assets/ManiPosesGuyStadistics/mani-error.png';
import Stack from "@mui/material/Stack";

const LayOutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 558px;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 80px;
`;

type MaintenancePageProps = {};

const MaintenancePage: React.FC<MaintenancePageProps> = () => {
    const { breakpoints } = useTheme();
    const isMobile = useMediaQuery(breakpoints.down("md"));
    return (
        <>
            {  isMobile ?
            <CustomContainer childComponent={
                <LayOutContainer>
                    <Stack direction='column' spacing={0}
                           justifyContent="center"
                           alignItems="center" >
                        <CustomTypography
                            style={{
                                color: "var(--Black, #212121)",
                                fontFamily: "Montserrat",
                                fontSize: 24,
                                fontWeight: 700,
                                letterSpacing: "0.5px",
                                textAlign: "center",
                                Padding: "0 1rem",
                                textWrap: "balance",
                            }}
                        >
                            Estamos trabajando en mejoras para seguir protegiéndote
                        </CustomTypography>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                mt: 3,
                                justifyContent: 'center',
                            }}
                        >
                            <Avatar
                                sx={{
                                    maxWidth: 250,
                                    width: '100%',
                                    height: 'auto',
                                    margin: {xs: 0, lg: '20px'},
                                }}
                                alt='ManiError'
                                src={guy}
                                variant='square'
                            />
                        </Box>
                        <Box sx={{mt: 3}}>
                            <CustomTypography
                                style={{
                                    color: "var(--Black, #212121)",
                                    fontFamily: "Montserrat",
                                    fontSize: 20,
                                    fontWeight: 400,
                                    letterSpacing: "0.5px",
                                    textAlign: "center",
                                    Padding: "0 1rem",
                                    textWrap: "balance",
                                }}
                            >
                                No te preocupes, no tardaremos en estar de regreso.
                            </CustomTypography>
                        </Box>
                    </Stack>
                </LayOutContainer>
            }/>
                :
              <Stack direction="row-reverse" spacing={0} justifyContent='center' sx={{mt:5}}>
                  <Box flexDirection={'column'} sx={{justifyContent:'center',alignItems:'center',display:'flex',width:'50%'}}>
                      <CustomTypography
                          style={{
                              color: "var(--Black, #212121)",
                              fontFamily: "Montserrat",
                              fontSize: 48,
                              fontWeight: 700,
                              letterSpacing: "0.5px",
                              textAlign: "center",
                              Padding: "0 1rem",
                              textWrap: "balance",
                          }}
                      >
                          Estamos trabajando en mejoras para seguir protegiéndote
                      </CustomTypography>

                      <CustomTypography
                          style={{
                              color: "var(--Black, #212121)",
                              fontFamily: "Montserrat",
                              fontSize:32,
                              fontWeight: 400,
                              letterSpacing: "0.5px",
                              textAlign: "center",
                              Padding: "0 1rem",
                              textWrap: "balance",
                              marginTop:"40px"
                          }}
                      >
                          No te preocupes, no tardaremos en estar de regreso.
                      </CustomTypography>
                  </Box>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        mt: 3,
                        justifyContent: 'center',
                        width:'30%'
                    }}
                >

                    <Avatar
                        sx={{
                            maxWidth: 250,
                            width: '100%',
                            height: 'auto',
                            margin: {xs: 0, lg: '20px'},
                        }}
                        alt='ManiError'
                        src={guy}
                        variant='square'
                    />
                </Box>
              </Stack>
            }
        </>
    )

}
export default MaintenancePage;