import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';

type FormObserverProps = { 
    getFormikReference?: any;
    localStorageOmit?: Array<string>
    formObserverSlice?: any;
}

/**
 * 
 * @param setFormValues update the initialValues object to update and start tbe values 
 * @param localStorageOmit if you want to omit add to localstorage some values you can add it to this array ['value 1', 'value 2'] 
 * @returns null 
 */
const FormObserver: React.FC<FormObserverProps> = ({ 
    getFormikReference,
    localStorageOmit,
    formObserverSlice 
}) => {
    const { values }: any = useFormikContext();
    const formik: any = useFormikContext();
    const dispatch = useDispatch();

    useEffect(() => {
        if(getFormikReference){
            getFormikReference(formik);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(localStorageOmit && values){
            const filteredObj = Object.keys(values).reduce((acc: any, key: any) => {
                if (!Object.keys(localStorageOmit).includes(key)) {
                    acc[key] = values[key];
                }
                return acc;
            }, {});
            dispatch(formObserverSlice(filteredObj));
            // setFormValues(filteredObj);
        }else {
            dispatch(formObserverSlice(values));
            // setFormValues(values);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);
  
    return null;
  };

  export default FormObserver;