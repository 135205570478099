import React from "react";
import CustomContainer from "../../organisms/Container/Container";
import PasswordResetResponse from "../../organisms/PasswordResetResponse";
const PasswordResetResponseTemplate: React.FC = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 40 }}>
            <CustomContainer childComponent={<PasswordResetResponse />} />
        </div>
    );
};

export default PasswordResetResponseTemplate;