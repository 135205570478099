import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { CustomButton } from './Buttons';
import { ArrowBack } from '@mui/icons-material';
const ButtonBackContainer = styled.div``;
interface BackButtonProps {
    label?: string;
    path?: string;
    handleAction?: any;
}

const BackButtonInter: React.FC<BackButtonProps> = ({
    label = "Regresar",
    path,
    handleAction
}) => {
    const navigate = useNavigate();
    const handleBack = () => {
        if (path) {
            navigate(path);
        } else if (handleAction) {
            handleAction();
        } else {
            navigate(-1)
        }
    }
    return (<ButtonBackContainer>
        <Button onClick={() => handleBack()}
            size={"medium"}
            startIcon={<ArrowBack />}
            variant={"contained"}
            style={{
                fontWeight: 'bold',
                borderRadius: 8,
                boxShadow: 'none',
                color: "#039ECC",
                backgroundColor: "transparent",
                fontSize: "1rem",
                textTransform: "none",
                padding: "0px",
                minWidth: "64px",
                border: "none",
                justifyContent: "flex-start",
                width: "fit-content",
            }}
        >
            {label}
        </Button>

    </ButtonBackContainer>)
}

export default BackButtonInter;