import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import "./MemberShipItem.css";
import { MembershipColor } from '../../../Membership/MembershipMainValues';
import { Box } from "@mui/material";
import { themeValues, myColorPalette } from '../../../../../theme/themeGenerated';


export interface BenefitsDetails {
    title: string;
    description: string;
    icon?: string;
    cardType?: string;
    benefit_id?: string | number;
}

interface MembershipItemPros extends BenefitsDetails {

}

const MembershipItem: React.FC<MembershipItemPros> = ({
    title,
    description,
    icon = '',
    cardType
}) => {
    return (
        <>
            <div className="itemCardBenefit" style={{
                padding: '8px',
                margin: '0px 12px 0px',
                backgroundColor: cardType === "free" ?
                    window.location.pathname === "/finsus" ? myColorPalette.getFinsusRgb() :
                        MembershipColor.freeOpacity : cardType ? MembershipColor.premiumOpacity : 'transparent'
            }}>
                <div className="icon">
                    {icon && icon !== '' ? <Box component={'img'} src={icon} sx={{ width: '28px', height: '28px', paddingRight: 1 }} /> : <CheckIcon style={{ color: '#5AB52F', fontSize: '27px', }} />}
                </div>
                <div className="text">
                    <div className="titleItem">
                        <p>
                            {title}
                        </p>

                    </div>
                    <div className="textDescription">
                        <p>
                            {description}
                        </p>
                    </div>
                </div>
            </div>

        </>)
}

export default MembershipItem;
