import React from 'react';
import CustomContainer from '../../organisms/Container/Container';
import { styled } from 'styled-components';
import { CustomTypography } from '../../atoms/Label/Label';
import { Avatar, Box } from '@mui/material';
import ManiBody from "../../../assets/ManiPosesBody";
import { timeLineProccess, TimeLineTrackingStatus } from '../../organisms/TimeLineTrackingStatus';
import { OutlinedButton, PrimaryButton } from '../../molecules/CellPhoneRepairButtons';
import { IStorageMembershipData } from '../../../utils/localStorageKeys';
import { useSelector } from 'react-redux';
import { removeStatusRepairPhone } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import BackButtonInter from '../../atoms/Buttons/BackButtonInter';
import InterContainer from '../../organisms/Container/ContainerFixed';

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 85px;
  gap: 40px;
  align-items: center;
`;

const TrackingStatusComponent: React.FC = () => {
    const navigate = useNavigate();

    let storage: IStorageMembershipData = {
        ...useSelector((state: any) => state.productsCards.currentValue),
    };

    const userData = JSON.parse(sessionStorage.getItem('user-storage')!);
    const userResponse = useSelector((state: any) => state.user.response);
    const allTrue = timeLineProccess.every(obj => obj.isActive)
    React.useEffect(() => {
        window.dataLayer.push({
            'event': 'TRUS_reparacion_estatus_v',
            tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium",//premium | gratuito
            'correo': userResponse?.data?.user?.email,
            'telefono': userResponse?.data?.user?.phone,
            'page_location': window.location.href
        });

    }, [])
    return (
        <React.Fragment>
            <InterContainer>
                <ComponentWrapper>
                    <div style={{
                        display: 'flex',
                        width: '90%',
                    }}>
                        <BackButtonInter />
                    </div>
                    <div style={{
                        display: 'flex', flexDirection: 'column',
                        width: '100%',
                        gap: 24, alignItems: 'center'
                    }}>
                        <CustomTypography
                            item={"Aquí podrás consultar el estatus de la reparación de tu dispositivo en cualquier momento"}
                            style={{
                                maxWidth: 528,
                                fontSize: 24,
                                textAlign: 'center',
                                fontWeight: 700,
                                fontStyle: 'normal',
                                lineHeight: 'normal',
                                textTransform: 'inherit',
                                letterSpacing: '0.5px',
                                textWrap: 'balance'
                            }}
                        />
                        <div
                            style={{
                                maxWidth: 428,
                                width: 'auto',
                                height: 'auto'
                            }}
                        >
                            <ManiBody />
                        </div>
                        <Box sx={{
                            maxWidth: 428,
                        }}>
                            <TimeLineTrackingStatus />

                        </Box>
                        <div style={{
                            display: allTrue ? 'flex' : 'none',
                            flexDirection: 'column',
                            width: '100%',
                            maxWidth: 428,
                            gap: 16
                        }}>
                            <PrimaryButton text={"Ya lo recibí"} onClick={() => {

                                window.dataLayer.push({
                                    event: "TRUS_reparacion_estatus",
                                    boton_contacto: "Ya lo recibi",
                                    tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium",//premium | gratuito
                                    telefono: userResponse?.data?.user?.phone,
                                    correo: userResponse?.data?.user?.email,
                                });
                                removeStatusRepairPhone()
                                navigate("/portal-del-cliente")
                            }} />
                            <OutlinedButton text={"No ha llegado"} onClick={() => {

                                window.dataLayer.push({
                                    event: "TRUS_reparacion_estatus",
                                    boton_contacto: "No ha llegado",
                                    tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium",//premium | gratuito
                                    telefono: userResponse?.data?.user?.phone,
                                    correo: userResponse?.data?.user?.email,
                                });
                                removeStatusRepairPhone()
                                navigate("/portal-del-cliente")
                            }} />
                        </div>
                    </div>
                </ComponentWrapper>
            </InterContainer>

        </React.Fragment >
    );
};

export default TrackingStatusComponent;
