import React, { useEffect } from 'react';
import { useField } from 'formik';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import * as Yup from "yup";

import FormItemGenerate from '../../../atoms/FormItems/FormItemGenerate';
import { CustomTypography } from '../../../atoms/Label/Label';
import { CustomButton } from '../../../atoms/Buttons/Buttons';
import CustomContainer from '../../Container/Container';
import useFetch from '../../../../hooks/useFetch';
import {
	apiError,
	apiLoading,
	apiSuccess,
	restartValue
} from '../../../../api/userAlianceSlice';
import FormContainer from '../FormContainer';
import { useSelector, useDispatch } from 'react-redux';
import RenderAlert from '../../Modal';
import { IStorageMembershipData } from '../../../../utils/localStorageKeys';

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 85px;
  gap: 24px;
  align-items: flex-start;
  max-width: 558px;
`;

const DivContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 40;
	align-items: center;
`;

type IFieldsProps = {
	handleChange?: any;
	handleBlur?: any;
}

const Fields: React.FC<IFieldsProps> = ({ handleChange, handleBlur }) => {
	const [employee, employeeMeta] = useField("employee");
	const [employeeName, employeeMetaName] = useField("employeeName");


	const items = [
		{
			id: 1, // identificador para la key
			label: 'No. de empleado', // texto en el input
			name: 'employee', // identificador que sera usado por formik
			as: 'textField', // tipo de elemento en este caso un texfield de material UI
			error: employee, // asigna la referencia de error al input en formik desde yup
			meta: employeeMeta, // contiene el error y evento del elemento
			autofocus: true,
			required: false
		},
		{
			id: 2, // identificador para la key
			label: 'Nombre completo', // texto en el input
			name: 'nameEmployee', // identificador que sera usado por formik
			as: 'textField', // tipo de elemento en este caso un texfield de material UI
			error: employeeName, // asigna la referencia de error al input en formik desde yup
			meta: employeeMetaName, // contiene el error y evento del elemento
			autofocus: false,
			required: false
		}
	]

	return (
		<>
			<FormItemGenerate
				handleChange={handleChange}
				handleBlur={handleBlur}
				items={items}
			/>
		</>
	)
}

const NumeroDeEmpleado: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { fetchData } = useFetch();
	const [activeStep, setActiveStep] = React.useState<number>(0);
	const image = useSelector((state: any) => state.allianceSetting.response.data.imagenes.logo)
	const colors = useSelector((state: any) => state.allianceSetting.response.data.colors)
	const response = useSelector((state: any) => state.userAliance.response) as any | null;
	// const error = useSelector((state: any) => state.beneficiary.error) as any | null;

	useEffect(() => {
		if (typeof response?.data === 'string') {
			RenderAlert({ severity: "info", message: response?.data });
			// restartValue();
		} else if(response?.ok){
			navigate("/alianza/registrate/1")
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [response]);

	const validationSchema = Yup.object().shape({
		employee: Yup.string(),
		employeeName: Yup.string()
	});

	const renderStepContent = (
		step: number,
		errors: any,
		handleChange: any,
		handleBlur: any
	) => {
		switch (step) {
			case 0:
				return (
					<Fields
						handleChange={handleChange}
						handleBlur={handleBlur}
					/>
				);
			default:
				return <></>;
		}
	};

	const validatorSchemaStepper = () => {
		switch (activeStep) {
			case 0:
				return validationSchema;
			default:
				throw Error("Verificar que el schema este asignado");
		}
	};

	const _submitForm: any = async (values: any, actions: any) => {
		// actions.setSubmitting(true);
		if(!values.employee && !values.employeeName){
			return RenderAlert({severity: "info", message: "Al menos uno de los datos debe de ser proporcionado"})
		}

		const content = {
			"id_aliance": values.employee.trim(),
			"name_employee_aliance": values.employeeName.trim().toUpperCase()
		}

		fetchData('users/auth', 'POST', {
			event: 'getuser-aliance',
			content: content
		}, {}, apiLoading, apiSuccess, apiError);
	}

	const membershipTypeModule = {
		employee: {
			formSteps: 0, //starting from 0
			steps: [], // steps title
			titles: [], // content title
			initialValues: {
				employee: "",
				employeeName: ""
			},
		}
	};

	let storage: IStorageMembershipData = {
		...useSelector((state: any) => state.productsCards.currentValue),
	  };

	useEffect(() => {
		window.dataLayer.push({
			event: 'TRUS_alianza_registro_empleado_v',
			tipo_seguro: storage?.type == "free" ? "De regalo" : "Premium",
			seguro: storage?.category == "salud" ? "Salud Protect" : "Celular Protect",
			correo: undefined, 
			telefono: undefined,
			page_location: window.location.href,
			alianza: storage?.business
		})
	}, [])

	return (
		<CustomContainer childComponent={
			<FormWrapper>
				<CustomButton
					text={"Regresar"}
					onClick={() => navigate(-1)}
					size={"medium"}
					startIcon={<ArrowBack />}
					variant={"contained"}
					style={{
						color: colors?.primary,
						backgroundColor: "transparent",
						fontSize: "1rem",
						textTransform: "none",
						fontWeight: "600",
						fontStyle: "normal",
						padding: "8px 10px",
						minWidth: "64px",
						border: "none",
						justifyContent: "flex-start",
						width: "fit-content",
					}}
				/>
				
				{image && <DivContainer>
					<img src={image} style={{
						width: '80%',
						height: '80%'
					}} />
				</DivContainer>}
				
				<CustomTypography
					style={{
						display: 'block',
						fontSize: 16,
						textAlign: 'center',
						fontWaight: 700,
						fontStyle: 'normal',
						lineHeight: 'normal',
						alignSelf: 'stretch',
						padding: '0 1rem',
					}}
				>Para obtener tu seguro, primero ayúdanos con tu código de registro o número de empleado</CustomTypography>
				<FormContainer
					steps={membershipTypeModule.employee?.steps}
					initialValues={membershipTypeModule.employee?.initialValues}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					title={membershipTypeModule.employee?.titles}
					renderStepContent={renderStepContent}
					_handleSubmit={_submitForm}
					validatorSchemaStepper={validatorSchemaStepper}
					formObserverKey="FormObserver"
					formObserverSlice={apiSuccess}
					localData={response}
					showTextRequire = {false}
					customText='*Es necesario introducir alguno de los campos'
				/>
			</FormWrapper>
		} />)
};

export default NumeroDeEmpleado;