import styled from 'styled-components';
import BackButtonInter from '../../atoms/Buttons/BackButtonInter';
import { useState, useRef, useEffect } from 'react'
import { Box, Input, Tab, Tabs, Typography, FormControl } from '@mui/material';
import { TabPanel, TabPanelProps } from '@mui/lab';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import { MembershipColor } from '../Membership/MembershipMainValues';
import * as Yup from "yup";
import { useTheme, useMediaQuery } from '@mui/material';

import IPhoneExample from './assets/iphone.svg';
import AndroidExample from './assets/android.svg';
import ButtonInter from '../../atoms/Buttons/ButtonInter';
import { Formik } from 'formik';
import { isAValidIMEI } from '../../../utils/validationInput';
import { useSelector } from "react-redux";
import { apiError, apiLoading, apiSuccess } from "../../../api/sendImeiSlice";
import RenderAlert from "../../organisms/Modal";
import useFetch from "../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { IStorageMembershipData } from '../../../utils/localStorageKeys';
const ImeiContainer = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
`;
const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
`;
enum ImeiListType {
    iphone = "iphone",
    android = "android"
}
type ImeiType = keyof typeof ImeiListType;

const ImeiValidationComponent = () => {
    const { breakpoints } = useTheme();
    const isDesktop = useMediaQuery(breakpoints.up('lg'));
    const isTablet = useMediaQuery(breakpoints.down('md'));
    const isMobile = useMediaQuery(breakpoints.down('sm'));

    const { fetchData } = useFetch();
    const navigate = useNavigate();

    const formRef = useRef(null) as any;
    const handleSubmit = () => {
        if (formRef.current) {
            if (formRef.current?.errors?.imei) {
                RenderAlert({ severity: "warning", message: "El IMEI debe contener 15 caracteres" })
                return
            }
            window.dataLayer.push({
                event: "TRUS_reparacion_agregar_imei",
                boton_contacto: "Guardar",
                seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
                tipo_seguro: storage.type == "free" ? "De regalo" : "Premium",
                telefono: userResponse?.data?.user?.phone,
                correo: userResponse?.data?.user?.email,
            });
            formRef.current?.handleSubmit()
        }
    };
    const [currentValue, setCurrentValue] = useState<ImeiType>('iphone');
    const [fieldTouched, setFieldTouched] = useState(false);
    const inputRef = useRef<any>(null);

    let storage: IStorageMembershipData = {
        ...useSelector((state: any) => state.productsCards.currentValue),
    };

    const userResponse = useSelector((state: any) => state.user.response);

    useEffect(() => {
        window.dataLayer.push({
            'event': 'TRUS_reparacion_agregar_imei_v',
            tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium",
            'correo': userResponse?.data?.user?.email,
            'telefono': userResponse?.data?.user?.phone,
            'page_location': window.location.href,
            seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
        })
    }, [])

    const scrollToInput = () => {
        if (inputRef.current) {
            inputRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    const StyleTab: React.CSSProperties = {
        fontWeight: '700',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        fontSize: '16px',
        width: '50%',
        boxSizing: 'border-box',
        MozBoxSizing: 'border-box',
        WebkitBoxSizing: 'border-box'
    }
    const StyleTabSelected: React.CSSProperties = {
        color: MembershipColor.free
    }

    const ItemTab: React.FC<TabPanelProps> = (props: TabPanelProps) => {
        const { children, value, ...other } = props
        return (
            <div id={"value_" + value} hidden={currentValue !== value}>
                {children}
            </div>)
    }

    const handleChange = (event: React.SyntheticEvent, newValue: ImeiType) => {
        setCurrentValue(newValue);
    };



    const response = useSelector((state: any) => state.sendImei.response) as any | null;
    const error = useSelector((state: any) => state.sendImei.error) as any | null;

    const tracking = useSelector((state: any) => state.tracking.response) as any | null;

    const sendIMEI = (imei: string) => {
        return fetchData('gadgets/', 'POST', {
            "event": "update-imei",
            "content": {
                "imei": imei,
                "tracking_id": tracking?.data?.tracking_id
            }
        }, {}, apiLoading, apiSuccess, apiError);
    }

    useEffect(() => {
        if (response?.code === 200) {
            RenderAlert({ severity: "success", message: "El IMEI se registró exitosamente" });
            navigate(-1)
        }
    }, [response?.code]);

    useEffect(() => {
        if (error?.response?.data?.code === 400) {
            RenderAlert({ severity: "error", message: "Lo sentimos, intente más tarde." });
        }
    }, [error]);

    const validationSchema = Yup.object().shape({
        imei: Yup.string()
            .test("valid-imei", "IMEI inválido", (value) => isAValidIMEI(value || ""))
            .min(15, "El minimo de caracteres es 15")
            .max(15, "El limite de caracteres es 15")
            .required("El campo es requerido"),
    });
    return <ImeiContainer style={{
        display: 'flex',
        justifyContent: 'center',
        maxWidth: isMobile ? '100%' : '752px',
        alignItems:'center',
        margin: 'auto',
    }}>
        <Box sx={{
            marginTop: '40px',
            maxWidth: isMobile ? '100%' : '558px',
        }}>
            <BackButtonInter />
            <div style={{marginTop: '50px'}} />
            <Typography style={{ fontSize: '16px', fontWeight: '700', textAlign: 'center' }}>
                Agrega tu IMEI para que puedas hacer valida tu cobertura
            </Typography>
            <FormWrapper>
                <Formik
                    enableReinitialize
                    innerRef={formRef}
                    initialValues={{ imei: '' }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            sendIMEI(values.imei)
                            setSubmitting(false);
                        }, 400);
                    }}

                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <FormControl fullWidth={true}>
                                <Input
                                    id="imei"
                                    name="imei"
                                    ref={inputRef}
                                    onBlur={() => {
                                        setFieldTouched(true)
                                    }}
                                    onChange={(e) => {
                                        if (e.target.value.length > 15)
                                            return
                                        handleChange(e);
                                        if (validationSchema.isValidSync({ imei: e.target.value })) {
                                            setFieldTouched(false);
                                        }
                                    }}
                                    type="text"
                                    placeholder='IMEI/IMEI1*'
                                    value={values.imei}
                                    color={errors.imei && touched.imei ? 'error' : touched.imei ? 'success' : 'primary'} />
                                {errors.imei && touched.imei ? <div><small style={{ color: 'red' }}>IMEI inválido</small></div> : ''}
                            </FormControl>

                        </form>
                    )}
                </Formik>
            </FormWrapper>
            {
                !isMobile && <ButtonInter onClick={handleSubmit} variant='contained' >
                    Guardar
                </ButtonInter>
            }
            {
                isMobile && <ButtonInter onClick={handleSubmit} variant='contained' >
                Guardar
              </ButtonInter>
            }
            {/* INFORMATION */}
            <Box sx={{ marginTop: '20px' ,
                borderRadius: '8px',
                background: '#F2FAFC',
                display: "flex",
                padding: "16px 8px",
                flexDirection:" column",
                justifyContent: "center",
                alignItems: "center",
                gap: "24px",
                alignSelf: "stretch",
                marginBottom:10
               }}>
                <Typography style={{ fontSize: '16px', fontWeight: '700', textAlign: 'center' }}>¿Dónde lo puedo encontrar?</Typography>
                <Box>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Tabs style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} value={currentValue} onChange={handleChange} >
                            <Tab value={ImeiListType.iphone} id="iphone" style={currentValue === ImeiListType.iphone ? { ...StyleTabSelected, ...StyleTab } : { ...StyleTab }} label={<><span ><AppleIcon /></span> <span style={{ paddingLeft: '2px' }}>IOS</span></>} />
                            <Tab value={ImeiListType.android} id="android" style={currentValue === ImeiListType.android ? { ...StyleTabSelected, ...StyleTab } : { ...StyleTab }} label={<><span><AndroidIcon /></span> <span style={{ paddingLeft: '4px' }}>Android</span></>} />
                        </Tabs>
                    </Box>
                    <ItemTab value='iphone' sx={{ marginTop: "24px"}}>
                        <Box component={'ul'} sx={{display:'flex',flexDirection:'column',gap:3,marginTop:4}}>
                            <Box component={'li'}>
                                Marcar *#06# desde tu dispositivo y te aparecerá imagen con los datos de tu celular.
                            </Box>
                            <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', padding: '0px', boxSizing: 'border-box' }}>
                                <img src={IPhoneExample} alt="Pantalla Iphone" />
                            </Box>
                            <li>
                                O si lo prefieres puedes ingresar a tu menú de <strong>Configuración</strong> y seguir los siguientes pasos:
                            </li>

                            <ol>
                                <li>
                                    Da tap en General
                                </li>
                                <li>
                                    Después, Información
                                </li>
                                <li>
                                    Desliza hasta el final de la página e identifica el IMEI
                                </li>
                                <li>
                                    Cópialo y pégalo en el campo
                                </li>
                            </ol>
                        </Box>
                    </ItemTab>
                    <ItemTab value='android'>
                        <ul>
                            <li>
                                Marcar *#06# desde tu dispositivo y te aparecerá imagen con los datos de tu celular.
                            </li><div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '15px', boxSizing: 'border-box' }}>
                                <img src={AndroidExample} alt="Pantalla Android" />
                            </div>
                            <li>
                                O si lo prefieres puedes ingresar a tu menú de  <strong>Ajustes</strong> y seguir los siguientes pasos:
                            </li>
                            <ol>
                                <li>Ve a: Acerca del teléfono</li>
                                <li>Identifica el IMEI1</li>
                                <li>Cópialo y pégalo en el campo</li>
                            </ol>
                        </ul>
                    </ItemTab>
                </Box>

            </Box>
        </Box>
    </ImeiContainer >
}

export default ImeiValidationComponent;
