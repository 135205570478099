import React, { useEffect } from 'react';
import {
    Field,
    useField,
    useFormikContext
} from 'formik';
import {
    Checkbox
} from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import FormItemGenerate from '../../../atoms/FormItems/FormItemGenerate';
import { CustomButton } from '../../../atoms/Buttons/Buttons';
import { useNavigate } from 'react-router-dom';
import { myColorPalette } from '../../../../theme/themeGenerated';

type DatosDeContactoProps = {
    errors: any;
    handleChange?: any;
    handleBlur?: any;
}

const DatosDeContacto: React.FC<DatosDeContactoProps> = ({ errors, handleChange, handleBlur }) => {
    const [email, emailMeta] = useField("email");
    const [phone, phoneMeta] = useField("phone");
    const formik = useFormikContext();
    const navigate = useNavigate();

    useEffect(() => {
        formik.setFieldValue('recaptcha', false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const tyc = async () => {
        var anchor = document.createElement('a');
        anchor.href = 'https://teregalamosunseguro.inter.mx/terminos-y-condiciones';
        anchor.target = "_blank";
        anchor.click();
    }
    const aviso = async () => {
        var anchor = document.createElement('a');
        anchor.href = 'https://inter.mx/aviso-de-privacidad';
        anchor.target = "_blank";
        anchor.click();
    }

    const items = [{
        id: 1,
        label: 'Correo Electrónico',
        name: 'email',
        as: 'textField',
        error: email,
        meta: emailMeta,
        autofocus: true
    },
    {
        id: 2,
        label: 'Número de celular',
        name: 'phone',
        as: 'textField',
        error: phone,
        meta: phoneMeta
    }
    ]
    return (<React.Fragment>
        <FormItemGenerate
            items={items}
            handleChange={handleChange}
            handleBlur={handleBlur}
        />
        <label style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 0, alignItems: 'flex-start' }}>
            <span style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                gap: 8
            }}>
                <Field
                    className='error-message'
                    as={Checkbox}
                    type="checkbox"
                    name="acceptTerms"
                    style={{ padding: 0, top: '3px', color: '#009DD0' }}
                    onChange={(ev: any) => formik.setFieldValue('acceptTerms', ev.target.checked)}
                />
                <span style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 5, alignItems: 'center', fontSize: 16 }}>
                    <span style={{ paddingBlock: '6px' }}>Acepto</span>
                    <CustomButton
                        style={{
                            textTransform: 'inherit',
                            fontWeight: 500,
                            fontStyle: 'normal',
                            color: '#009DD0',
                            padding: 0,
                            fontSize: '16px'
                        }}
                        onClick={() => tyc()}
                        text='Términos y Condiciones'
                    />
                    <span style={{ paddingBlock: '6px' }}> y el </span>
                    <CustomButton
                        style={{
                            textTransform: 'inherit',
                            fontWeight: 500,
                            fontStyle: 'normal',
                            color: '#009DD0',
                            padding: 0,
                            fontSize: '16px'
                        }}
                        onClick={() => aviso()}
                        text='Aviso de privacidad'
                    />
                </span>
            </span>
            {errors?.acceptTerms && <span className='error-message'>{errors.acceptTerms}</span>}
        </label>
        {/*} <ReCAPTCHA 
            sitekey="6LdVLtUjAAAAAK63bwg6YPLQa1GV6_H1ibukGzz8"
            onChange={(ev: any) => {
                handleChange(ev);
                if(ev){
                    formik.setFieldValue('recaptcha', true)
                }
            }}
            hl="es"
        />*/}
    </React.Fragment>)
};

export default DatosDeContacto;