import React from "react";
import {styled} from "styled-components";
import {useField} from "formik";
import FormItemGenerate from "../../../atoms/FormItems/FormItemGenerate";
import {PrimaryButton} from "../../../molecules/CellPhoneRepairButtons";
import {Box} from "@mui/material";


type ForgotPasswordContentProps = {
    errors?: any;
    handleChange?: any;
    handleBlur?: any;
}

const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;


const ForgotPasswordContent: React.FC<ForgotPasswordContentProps> = ({handleChange, handleBlur}) => {
    const [email, emailMeta] = useField("email");

    const items = [{
        id: 1,
        label: 'Teléfono o Correo electrónico',
        name: 'email',
        as: 'textField',
        error: email,
        meta: emailMeta,
        autofocus: true
    }
    ];


    return (
        <ContentStyled>
            <FormItemGenerate
                items={items}
                handleChange={handleChange}
                handleBlur={handleBlur}
            />

           {/* <Box sx={{marginTop:2}}>
                <PrimaryButton type='submit' text={"Recuperar contraseña"}/>
            </Box>*/}
        </ContentStyled>
    )

};

export default ForgotPasswordContent;
