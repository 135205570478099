import React, {useEffect, useState} from 'react';
import { styled } from 'styled-components';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { restartValue } from '../../../api/loginSlice';
import { restartValue as restartBeneficiary } from '../../../api/multipleBeneficiarySlice';
import { restartValue as restartAllianceSetting } from '../../../api/allianceSettingSlice';
import RenderModal from '../Modal/RenderDialog';
import { useNavigate } from 'react-router-dom';

const breakpoints = {
  xs: "320px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1536px"
}

const devices = {
  xs: `(max-width: ${breakpoints.xs})`,
  sm: `(max-width: ${breakpoints.sm})`,
  md: `(max-width: ${breakpoints.md})`,
  lg: `(max-width: ${breakpoints.lg})`,
  xl: `(max-width: ${breakpoints.xl})`
}

const ContainerElement = styled.main`
    width: 100%;
    max-width: 1440px;
    display: block;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
    @media only screen and ${devices.md} {
      padding-left: 1rem;
      padding-right: 1rem;
    }
`;

type CustomContainerProps = {
  childComponent: React.ReactNode;
};

export const Idle = ({check}: any) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const closeSession = () => {
    sessionStorage.setItem('userError', '2');
    sessionStorage.removeItem('user-storage');
    sessionStorage.removeItem('card-type-storage');
    sessionStorage.removeItem("access_token");
    dispatch(restartValue());
    dispatch(restartBeneficiary());
    dispatch(restartAllianceSetting())
    localStorage.removeItem("persist:root")
    // logout(webAuth);
    navigate("/iniciar-sesion")
    RenderModal()
  };

  // eslint-disable-next-line
  check && useIdleTimer({
    onIdle: () => closeSession(),
    timeout: 3600000,
  })

  return <div key={check} />
}

const CustomContainer = ({ childComponent } : CustomContainerProps) => {
  const [checkIdle, setCheckIdle] = useState<boolean>(false);

  useEffect(()=> {
    if(sessionStorage?.access_token){
      setCheckIdle(true)
    }else{
      setCheckIdle(false)
    }
  }, [sessionStorage["access_token"]])

  return (
    <ContainerElement>
      <Idle key={checkIdle ? "activo" : "inactivo"} check={checkIdle} />
      {childComponent}
    </ContainerElement>
  );
};

export default CustomContainer;
