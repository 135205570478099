import React, { useEffect, useState } from "react";
import CustomContainer from "../../organisms/Container/Container";
import { CustomTypography } from "../../atoms/Label/Label";
import { styled } from "styled-components";
import { useMediaQuery, useTheme, Box } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { TotalCardItem } from "../../molecules/TotalCard/TotalCard";
import { useNavigate, useLocation } from "react-router-dom";
import giftcard from "../../../assets/MakePayment/gifcard.gif";
import { useSelector } from "react-redux";

import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { InterInstance } from "../../../api/config";
import RenderAlert from "../../organisms/Modal";
import ModalPaymentmethod from "./ModalPaymentMethod";
import { IStorageMembershipData } from "../../../utils/localStorageKeys";
import { myColorPalette } from "../../../theme/themeGenerated";
import ResumeComponent from "./PaymentElement/ResumeComponent";

type Props = {
  gap?: string;
  maxWidth?: string
}
const LayOutContainer2 = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  /* width: 85%; */
  gap:  ${({ gap = "24px" }) => gap};
  margin: auto;
  width: ${({ maxWidth = "100%" }) => maxWidth};
  align-items: flex-start;

  @media screen and (min-width:0px) and (max-width:639px) {
    
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
    
  }

  @media screen and (min-width:640px) and (max-width:1023px) {
  
      /* align-items: center; */
      flex-direction: column;
    
  }

  `;

type MakePaymentTemplateProps = {
  onlyCardPresentation?: boolean;
  newProductRegistration?: boolean;
  setActiveStep?: any;
  handleChange?: any;
}

const MakePaymentTemplate: React.FC<MakePaymentTemplateProps> = ({ onlyCardPresentation = true, newProductRegistration, setActiveStep, handleChange }) => {

  const [openModal, setOpenModal] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState<boolean>(false);
  const [items, setItems] = useState<TotalCardItem[]>([]);
  const userDataPersist = useSelector((state: any) => state.user.response);

  const location = useLocation();
  const [success, setSuccess] = useState(false);
  const matches = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down("md"));
  const gadgets = useSelector((state: any) => state.gadgets.response)
  const currentValue = useSelector(
    (state: any) => state.productsCards.currentValue
  ) as any | null;
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const [premiumPlanExist, setPremiumPlanExist] = useState<boolean>(false);
  let intermediateJson = giftcard;

  const tracking = useSelector((state: any) => state.tracking.response);
  const data = useSelector((state: any) => state.formRegister?.response);


  useEffect(() => {
    if (userDataPersist) {
      const planSearch = userDataPersist?.data?.plans.find((p: any) => p.tier);
      setPremiumPlanExist(planSearch)
    }
  }, [userDataPersist]);

  useEffect(() => {
    if (currentValue?.category === "salud") {
      setItems([{
        label: "Salud Protect Premium",
        price: 99,
        sublabel: "al mes",
      }])
    } else if (currentValue?.category === "celular") {
      setItems([{
        label: "Celular Protect Premium",
        price: 99,
        sublabel: "al mes",
      }])
    }
  }, [currentValue]);

  const navigateToPayment = (path: string, success: boolean) => {
    setLoading(false);
    navigate('/payment', { state: { path: path, success: success } })
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const { setupIntent, error } = await stripe.confirmSetup({
      elements,
      redirect: 'if_required'
    });

    if (error) {
      return RenderAlert({ severity: "error", message: "El número de tarjeta es necesario" })
    }
    const userIsAlreadyRegistered = sessionStorage.access_token
    if (userIsAlreadyRegistered) {
      //Flujo de freemium a premium
      const customerObject = {
        event: "stripe-customer",
        content: {
          tracking_id: tracking?.data?.tracking_id,
          payment_method: {
            id: setupIntent?.payment_method
          },
          business: allianceSettingResponse?.data?.id_bussiness || userDataPersist?.data?.business?.id_bussiness
        }
      }
      try {
        await createCustomer(customerObject)
      } catch (error) {
        //Mandar a pantalla de error de pago
        if (onlyCardPresentation) {
          setSuccess(false);
          setOpenModal(true);
        } else {
          navigateToPayment("/contratacion", false)
        }
      }
    } else {
      //Flujo directo a premium
      const objectCustomerBefore = {
        event: "stripe-customer-before",
        content: {
          tracking_id: tracking?.data?.tracking_id,
          name: `${data.name} ${data.middle_name} ${data.last_name}`,
          phone: data.phone,
          email: data.email,

          payment_method: {
            id: setupIntent.payment_method
          },
          business: allianceSettingResponse?.data?.id_bussiness || userDataPersist?.data?.business?.id_bussiness
        }
      }
      try {
        await customerBefore(objectCustomerBefore)
      } catch (error) {
        console.error(error)
      }
    }
  };

  //Metodos a utilizar para usuario sin registro previo
  const customerBefore = async (customerBefore: any) => {
    try {
      const { data } = await InterInstance.post("payments/stripe", customerBefore)
      await customerAfter(data)
    } catch (error) {
      setSuccess(false);
      setLoading(false);
      setOpenModal(true);
      console.error(error)
    }
  }

  let storage: IStorageMembershipData = {
    ...useSelector((state: any) => state.productsCards.currentValue),
  };
  const customerAfter = async (customerAfter: any) => {
    try {
      const objectCustomerAfter: any = {
        event: "stripe-subscription-before",
        content: {
          tracking_id: tracking?.data?.tracking_id,
          token: customerAfter?.data?.token,
          // benefit_id: currentValue.benefit_id === "C01" ? "C02" : "S04",
          benefit_id: (currentValue.benefit_id === "C01" || currentValue.benefit_id === "C02") ? "C02" : "S04",
          business: allianceSettingResponse?.data?.id_bussiness || userDataPersist?.data?.business?.id_bussiness
        },
      }

      if (location.pathname === "/portal-del-cliente/registro-nuevo-producto" && (currentValue.benefit_id === "C01" || currentValue.benefit_id === "C02")) {
        objectCustomerAfter.content["gadgedsInfo"] = {
          "id_brand": localStorage?.id_brand,
          "id_line": localStorage?.id_line,
          "id_model": localStorage?.id_model,
          "imei": null
        }
      }

      const { data: tmpData } = await InterInstance.post("payments/stripe", objectCustomerAfter)
      if (tmpData) {
        //Mandar a pantalla de exito
        window.dataLayer.push({
          event: 'TRUS_pagar',
          boton_contacto: 'Pagar',
          seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
          tipo_seguro: "Premium",
          correo: userData?.correo,
          telefono: userData?.telefono,
          alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
        })
        sessionStorage.setItem('exitoPremium', '1');

        if (onlyCardPresentation) {
          setSuccess(true);
          setOpenModal(true);
          return
        } else {
          navigateToPayment("/crear-cuenta", true)
          return
        }
      }
      //Mandar a pantalla de error de pago
      if (onlyCardPresentation) {
        setSuccess(false);
        setOpenModal(true);
      } else {
        navigateToPayment("/contratacion", false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  //Metodos a utilizar para usuario freemium
  const createCustomer = async (createCustomerObject: any) => {
    if (!premiumPlanExist) {
      const { data } = await InterInstance.post("payments/stripe", createCustomerObject)

      if (data) {
        await createSubscription()
      }
    } else {
      await createSubscription()
    }
  }

  const createSubscription = async () => {
    const customer: any = {
      event: "stripe-subscription",
      content: {
        tracking_id: tracking?.data?.tracking_id,
        // benefit_id: currentValue.benefit_id === "C01" ? "C02" : "S04",
        benefit_id: (currentValue.benefit_id === "C01" || currentValue.benefit_id === "C02") ? "C02" : "S04",
        business: allianceSettingResponse?.data?.id_bussiness || userDataPersist?.data?.business?.id_bussiness
      },
    }

    if (currentValue.benefit_id === "C01" || currentValue.benefit_id === "C02") {
      customer.content["gadgedsInfo"] = {
        "id_brand": localStorage?.id_brand,
        "id_line": localStorage?.id_line,
        "id_model": localStorage?.id_model,
        "imei": null
      }
    }

    const { data } = await InterInstance.post("payments/stripe", customer)
    if (data) {
      //Mandar a pantalla de exito
      if (window.location.pathname == "/portal-del-cliente/registro-nuevo-producto") {
        window.dataLayer.push({
          event: 'TRUS_nuevo_pagar',
          boton_contacto: 'Pagar',
          seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
          tipo_seguro: "Premium",
          correo: userData?.correo,
          telefono: userData?.telefono,
        })
      } else {
        window.dataLayer.push({
          event: 'TRUS_pagar',
          boton_contacto: 'Pagar',
          seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
          tipo_seguro: "Premium",
          correo: userData?.correo,
          telefono: userData?.telefono,
          alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,

        })
      }
      sessionStorage.setItem('exitoPremium', '1');
      if (onlyCardPresentation) {
        setSuccess(true);
        setOpenModal(true);
        handleChange(data)
        return
      } else {
        navigateToPayment("/portal-del-cliente", true)
        return
      }
    }
    //Mandar a pantalla de error de pago
    if (onlyCardPresentation) {
      setSuccess(false);
      setOpenModal(true);
    } else {
      navigateToPayment("/contratacion", false)
    }
  }

  const userData = JSON.parse(sessionStorage.getItem('user-storage')!);
  const allianceSettingResponse = useSelector((state: any) => state.allianceSetting.response);

  useEffect(() => {
    if (window.location.pathname == "/portal-del-cliente/registro-nuevo-producto") {
      window.dataLayer.push({
        'event': 'TRUS_nuevo_pagar_v',
        seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
        'tipo_seguro': 'Premium',
        'correo': userData?.correo,
        'telefono': userData?.telefono,
        'page_location': window.location.href
      })
    } else {
      window.dataLayer.push({
        'event': 'TRUS_pagar_v',
        seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
        'tipo_seguro': 'Premium',
        'correo': userData?.correo,
        'telefono': userData?.telefono,
        'page_location': window.location.href,
        alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
      })
    }
  }, [])

  useEffect(() => {
    if (sessionStorage?.exitoPremium === '1') {
      setSuccess(true)
      setOpenModal(true)
    }
  }, [sessionStorage?.exitoPremium]);

  const getMessage = (type: "free" | "premium") => {
    return type === "premium" ? 'Premium' : ''
  }

  return (
    <Box sx={{
      width: '100%',
      margin: 'auto',
    }}>
      <Box padding={0}>
        <ModalPaymentmethod open={openModal} setOpenModal={setOpenModal} onClick={() => {
          if (success) {
            if (newProductRegistration) {
              sessionStorage.removeItem('modalWelcomeToUser');
              navigate('/portal-del-cliente', { state: { newPlan: true, planId: currentValue?.plan_id } })
            } else {
              setActiveStep((prevStep: number) => {
                return prevStep + 1
              });
            }
          } else {
            setOpenModal(false);
            window.dataLayer.push({
              event: 'TRUS_pagar_error',
              seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
              tipo_seguro: "Premium",
              correo: userData?.correo,
              telefono: userData?.telefono,
              boton_contacto: 'Reintentar',
              alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
            })
          }
        }} success={success} />
        <CustomContainer
          childComponent={
            <React.Fragment>
              <div style={onlyCardPresentation ? {} : { marginTop: "40px" }}>
                <div
                  style={{
                    display: "flex",
                    flexFlow: isMobile ? "column" : "row",
                    width: "100%",
                    justifyContent: 'center',
                  }}
                >
                  {
                    onlyCardPresentation &&
                    <div style={{
                      width: '100%',
                    }}>
                      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', }}>
                        <CustomTypography
                          style={{
                            fontSize: matches ? 28 : 24,
                            maxWidth: isMobile || isTablet ? 580 : '100%',
                            fontWeight: 700,
                            letterSpacing: "0.5px",
                            Padding: "0 1rem",
                            textWrap: "balance",
                            justifyContent: 'center',
                            paddingBottom: 40,
                            textAlign: newProductRegistration && 'center'
                          }} >{newProductRegistration ? 'Ingresa los datos de la tarjeta con la que quieres realizar el pago' : ''}
                        </CustomTypography>
                      </Box>
                      <div style={isMobile ? {
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '30px'
                      } : {
                        display: 'grid',
                        gridTemplateColumns: '2fr 1fr',
                        gap: '30px',

                      }}>
                        <PaymentElement />
                        <div>
                          <div className="payment" style={{
                            margin: 'auto'
                          }}>
                            {currentValue && <ResumeComponent items={[
                              {
                                label: currentValue.cardTitle ? currentValue.cardTitle : currentValue.category === 'celular' ? 'Celular Protect' : 'Salud Protect' + ' ' + getMessage(currentValue.type),
                                price: currentValue.type === 'free' ? 0 : 99
                              }
                            ]} />}
                          </div>
                          <LoadingButton
                            type="button"
                            disabled={loading}
                            size="medium"
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                            onClick={(ev: any) => handleSubmit(ev)}
                            style={{

                              color: '#fff',
                              backgroundColor: myColorPalette.getPrimaryColor(),
                              fontSize: "16px",
                              fontWeight: "600",
                              padding: "12px 16px",
                              minWidth: "64px",
                              width: "100%",
                              borderRadius: "8px",
                              opacity: 0.8,
                              background: myColorPalette.getPrimaryColor(),
                            }}
                          >
                            {loading ? 'PROCESANDO PAGO' : 'PAGAR'}
                          </LoadingButton>
                        </div>

                      </div>

                      <br />

                    </div>
                  }
                </div>
              </div>
            </React.Fragment>
          }
        />
      </Box>
    </Box>
  );
};

export default MakePaymentTemplate;
