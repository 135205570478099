import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { CustomerPortalContent } from '../../components/organisms/CustomerPortalContent/CustomerPortalContent';
import { useSelector } from 'react-redux';
import ModalWelcomeToUser from './ModalWelcomeToUser';
import { useLocation } from 'react-router-dom';
import ModalSuccessBeneficiaryAdded from './ModalSuccessBeneficiaryAdded';
import useFetch from '../../hooks/useFetch';
import {
  apiSuccess as apiUserSuccess,
  apiError as apiUserError,
  apiLoading as apiUserLoading
} from '../../api/userSlice';
import {
  apiSuccess as apiAssignmentSuccess,
  apiError as apiAssignmentError,
  apiLoading as apiAssignmentLoading
} from '../../api/assignmentSlice';
import {
  apiSuccess as apiAssignmentModalSuccess,
  apiError as apiAssignmentModalError,
  apiLoading as apiAssignmentModalLoading
} from '../../api/assignmentModalSlice';
import { myColorPalette } from '../../theme/themeGenerated';

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const CustomerPortal: React.FC = () => {
  const location = useLocation();
  const [modalNewUserIsOpen, setModalNewUserIsOpen] = useState<boolean>(false)
  const userResponse = useSelector((state: any) => state?.user?.response);
  const currentValue = useSelector((state: any) => state.productsCards.currentValue);
  const { fetchData } = useFetch();
  const tracking = useSelector((state: any) => state.tracking.response) as
    | any
    | null;
  const getUser = () => {
    return fetchData('users/auth/get-user', 'GET', {}, {}, apiUserLoading, apiUserSuccess, apiUserError);
  }

  const getAssignment = (event: any, loading: any, success: any, error: any) => {
    return fetchData('/plansalud', 'POST', {
      "event": event,
      "content": {
        "tracking_id": tracking?.data?.tracking_id
      }
    }, {}, loading, success, error);
  }

  const deleteStorageControllerCheckup = () => {
    localStorage.removeItem("completado_sin_eventos");
    localStorage.removeItem("completado_con_eventos");
    localStorage.removeItem("evento_cancelado");
  };

  React.useLayoutEffect(() => {
    deleteStorageControllerCheckup();
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userResponse?.data?.user && location?.state?.newUser) {
      setModalNewUserIsOpen(true)
    } else {
      setModalNewUserIsOpen(false)
    }
    if (currentValue?.plan_id === "P02" && (currentValue?.benefit_id === "S04" || currentValue?.benefit_id === "S03")) {
      getAssignment(
        "get-assistances",
        apiAssignmentLoading,
        apiAssignmentSuccess,
        apiAssignmentError
      );
    }
    if (userResponse?.data?.business?.colors) {
      if (userResponse?.data?.business?.colors?.primary) {
        myColorPalette.setPrimaryColor(userResponse?.data?.business?.colors?.primary)
        myColorPalette.setStepperColor(userResponse?.data?.business?.colors?.secondary)
        myColorPalette.setOpacity40Color("#FFEFEE")
      }
      if (userResponse?.data?.business?.colors?.secondary) myColorPalette.setSecondaryColor(userResponse?.data?.business?.colors?.secondary)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userResponse?.data?.user, location?.state?.newUser])

  useEffect(() => {
    getAssignment(
      "get-assistancesModal",
      apiAssignmentModalLoading,
      apiAssignmentModalSuccess,
      apiAssignmentModalError
    );
  }, []);

  const closeNewUserModal = () => {
    setModalNewUserIsOpen(false)
  }

  return (
    <React.Fragment>
      {userResponse?.data?.user && location?.state?.newUser ?
        <ModalWelcomeToUser
          isNewUser={location?.state?.newUser}
          type={userResponse?.data?.user?.benefit_id === "C01" ? 'free' : 'premium'}
          closeNewUserModal={closeNewUserModal} /> : <></>}
      {userResponse?.data?.user && location?.state?.newPlan ?
        <ModalWelcomeToUser
          isNewUser={location?.state?.newPlan}
          planId={location?.state?.planId}
          closeNewUserModal={closeNewUserModal} /> : <></>}
      <ModalSuccessBeneficiaryAdded />
      <FormWrapper>
        <CustomerPortalContent
          modalNewUserIsOpen={modalNewUserIsOpen}
          showModalUpsell={location?.state?.showModalUpsell} />
      </FormWrapper>
    </React.Fragment>
  );
};

export default CustomerPortal;
