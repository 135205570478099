import React from 'react';
import RecomendationComponent from '../../components/templates/Recomendation';

const Recomendation: React.FC = () => {
  // Implementa el componente aquí
  return (
    <React.Fragment>
        <RecomendationComponent />
    </React.Fragment>

  );
};

export default Recomendation;
 