import { useState, useEffect } from 'react'
import { CustomTypography } from '../../atoms/Label/Label';
import { styled } from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { InterInstance, addAccessToken } from '../../../api/config';
import { useSelector } from 'react-redux';
import { CustomButton } from "../../atoms/Buttons/Buttons";
import { ArrowBack } from "@mui/icons-material";

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  gap: 30px;
  align-items: flex-start;
  height: 100%
`;

const Iframe = () => {
  const { user } = useSelector((state: any) => state?.user?.response?.data);
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const [url, setUrl] = useState("");
  const [title, setTitle] = useState('');
  const [lon, setLongitud]: any = useState("");
  const [lat, setLatitud]: any = useState("");

  const getIframeData = async (longitude: any, latitude: any) => {
    if (pathname === "/ofertas") {
      setUrl(`https://webview.ofertaspin.com/#/main?Webkey=${user?.webKey}&Latitud=${latitude}&Longitud=${longitude}&Tarjeta=${user?.certificate}`)
      setTitle('Mi club de descuentos')
    }

    if (pathname === "/telemedicina") {
      await addAccessToken()
      setTitle("Telemedicina")
      await getUrlTelemedicine()
    }
  }

  const getUrlTelemedicine = async () => {
    try {
      const { data } = await InterInstance.get("plansalud/telemedicina/otp")
      setUrl(data?.data?.url)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    (async () => {
      await navigator.geolocation.getCurrentPosition(
        posicion => {
          setLongitud(posicion.coords.longitude);
          setLatitud(posicion.coords.latitude);
          getIframeData(posicion.coords.longitude, posicion.coords.latitude);
        },
        error => {
          getIframeData(0, 0);
        }
      );
    })();
  }, [pathname]);

  return (

    <div
      style={{
        // background: 'red',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div style={{
        width: "100%",
        height: "90vh",
        maxHeight: "697px",
        maxWidth: "946px",
        marginBottom: '130px'
      }}>
        <CustomButton
          text={"Regresar"}
          onClick={() => navigate(-1)}
          size={"medium"}
          startIcon={<ArrowBack />}
          variant={"contained"}
          style={{
            color: "#039ECC",
            backgroundColor: "transparent",
            fontSize: "1rem",
            textTransform: "none",
            fontWeight: "600",
            fontStyle: "normal",
            padding: "0px",
            minWidth: "64px",
            border: "none",
            justifyContent: "flex-start",
            width: "fit-content",
            marginTop: '50px'

          }}
        />
        <ComponentWrapper>
          <CustomTypography
            item={title}
            style={{
              fontSize: 24,
              textAlign: 'center',
              fontWeight: 700,
              fontStyle: 'normal',
              lineHeight: 'normal',
              letterSpacing: '0.5px'
            }}
          >

          </CustomTypography>

          {
            url && <iframe
              title='Telemedicina'
              src={url}
              width="100%"
              height="100%"
            />
          }

        </ComponentWrapper>
      </div>
    </div>

  )
}

export default Iframe