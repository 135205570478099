import Carousel from 'react-material-ui-carousel';
import CarInsurance from './CarInsurance';
import MedicalInsurance from './MedicalInsurance';
import PetInsurance from './PetInsurance';
import RightVector from '../../assets/img/icons/rightVector.svg';
import LeftVector from '../../assets/img/icons/leftVector.svg';
import {Box} from '@mui/material';
import CardMembershipType from "./CardMembershipType";
import {MembershipPlanItem} from '../organisms/CustomerPortalContent/interfaces/membership';

interface IPickInsurance {
    plans: Array<MembershipPlanItem>;
}

export default function PickInsurance({plans}: IPickInsurance) {
    let insurances
    if(plans.length > 1){
        insurances = [<CarInsurance />, <MedicalInsurance />, <PetInsurance />];
    }else{
        const plan = plans[0]
        insurances = [<CardMembershipType plan={plan} />,<CarInsurance />, <MedicalInsurance />, <PetInsurance />];
        
    }

    return (
        <Box sx={{paddingBottom: '80px', display: 'flex',
            width: '100%', flexDirection: 'column'}}>
            <Carousel
                autoPlay={false}
                animation={'slide'}
                indicators={true}
                navButtonsAlwaysVisible={true}

                NextIcon={
                    <Box
                        component={'img'}
                        src={RightVector}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '33.182px',
                            flexShrink: 0,
                            //marginRight: {lg: 8.75, xl: 8.75}
                        }}
                    />
                }
                PrevIcon={
                    <Box
                        component={'img'}
                        src={LeftVector}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '33.182px',
                            flexShrink: 0,
                            //marginLeft: {lg: 8.75, xl: 8.75}
                        }}
                    />
                }
                navButtonsProps={{
                    style: {
                        background: 'none',
                        margin: 0,
                        flexShrink: 0,
                    },
                    // @ts-ignore
                    sx: {
                        visibility: {xs: 'hidden', sm: 'hidden', md: 'hidden', lg: 'visible', xl: 'visible'}
                    }
                }}

                cycleNavigation={false}

                indicatorContainerProps={{
                    style: {
                        textAlign: 'center',
                        zIndex: 100,
                       marginTop: '30px',
                        position: 'relative',
                    },
                }}
                indicatorIconButtonProps={{
                    style: {
                        background:'#D9D9D9',
                        margin:5,
                        width:20,
                        borderRadius:10,
                        color: '#D9D9D9',
                    }
                }}

        activeIndicatorIconButtonProps={{
          style: {
              color: '#039ECC',
              background:'#039ECC',
              width:40,
              borderRadius:10,
          }
        }}

            >
                {insurances.map((insurance, index) => (
                    <Box key={index} sx={{
                        display: 'flex',
                        width: '100%', flexDirection: 'column'
                    }}>
                        {insurance}
                    </Box>
                ))}
            </Carousel>
        </Box>
    );
};
