import React, { useEffect, useState } from 'react';

import CelularMani from '../../../assets/RevolutionAssets/CelularMani.svg';
import SaludMani from '../../../assets/RevolutionAssets/SaludMani.svg';
import { styled } from 'styled-components';
import {
    Box,
    Grid,
    Typography,
    useTheme,
    useMediaQuery
} from '@mui/material';
import { CustomButton } from '../../atoms/Buttons/Buttons';
import { themeValues, myColorPalette } from '../../../theme/themeGenerated';


const TitleElement = styled.span`
    color: #039ECC;
    font-family: Montserratt;
    font-size: 32px;
    font-weight: 900;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: center;
    display:flex;
`;

const eventFiunction = () => {
    const element = document.getElementById('my-section');
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
    window.dataLayer.push({
        event: 'TRUS_conoce_seguros',
        boton_contacto: 'Asegurarme ahora'
    })
}

const CenterElement = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const ArrayTitles = ["celular", "salud"]

const RevolutionContent: React.FC = () => {
    const { breakpoints } = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));

    let timer: any = null;
    const [title, setTitle] = useState("celular");
    const changeTitleTimer = () => {
        let i = 0;
        timer = setInterval(function () {
            setTitle(ArrayTitles[i]);
            if (i == (ArrayTitles.length - 1)) {
                i = 0;

            } else {
                i++;
            }
        }, 3000)
    }

    useEffect(() => {
        changeTitleTimer()

        return () => {
            clearInterval(timer)
        }
    }, [])

    const RevolutionConteinerStyle: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center'

    }
    const RevolutionBanner: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        textAlign: 'left',
        maxWidth: '789px',
    }

    return (
        <CenterElement style={{ display: 'flex', flexDirection: 'column' }} >
            <Box style={RevolutionConteinerStyle} className="RevolutionConteiner" >
                <Box style={RevolutionBanner} className="RevolutionBanner">


                    {window.location.pathname !== "/finsus" &&
                        <TitleElement >
                            <Typography
                                textAlign={isMobile ? 'center' : 'left'}
                                variant={'caption'}
                                color={'#039ECC'}
                                fontSize={isMobile ? '32px' : '56px'}
                                fontStyle={'normal'}
                                fontWeight={700}
                                lineHeight={'normal'}
                            >
                                {'En inter.mx Te regalamos un seguro, para que protejas'}<span style={{ color: '#FFD100' }}> {'tu ' + title}</span>
                            </Typography>
                        </TitleElement>

                    }
                    {
                        window.location.pathname === "/finsus" &&
                        <TitleElement >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 8

                                }}>
                                <Typography
                                    textAlign={'left'}
                                    variant={'caption'}
                                    color={'#212121'}
                                    fontSize={isMobile ? '32px' : '56px'}
                                    fontStyle={'normal'}
                                    fontWeight={700}
                                    lineHeight={'normal'}
                                >
                                    <span style={{ color: '#FF5F54' }}> {'Finsus '}</span>
                                    {'e'}
                                    <span style={{ color: '#039ECC' }}> {'inter.mx '}</span>

                                    {'quieren protegerte '}
                                </Typography>

                                <Typography
                                    textAlign={'left'}
                                    variant={'caption'}
                                    color={'#212121'}
                                    fontSize={isMobile ? '22px' : '24px'}
                                    fontStyle={'normal'}
                                    fontWeight={500}
                                    lineHeight={'normal'}
                                >

                                    {'Por eso te damos Salud Protect o Celular Protect de Regalo'}
                                </Typography>
                            </div>

                        </TitleElement>
                    }

                    <Box sx={{ flexGrow: 1, width: '100%' }}>
                        <Grid item xs={12} sm={4} md={4} sx={{
                            justifyContent: 'center',
                            display: 'flex',
                            mt: 3,
                        }}>

                            {isMobile ? title === 'celular' ?
                                <img src={CelularMani} alt={'ManiCelular'} loading='lazy' />
                                :
                                <img src={SaludMani} alt={'ManiSalud'} loading='lazy' />
                                : ''}
                        </Grid>
                        {window.location.pathname !== "/finsus" &&
                            <Grid item xs={12} sm={4} md={4} sx={{
                                justifyContent: isMobile ? 'center' : "flex-start",
                                display: 'flex',
                                paddingTop: '24px'

                            }}>
                                <Typography
                                    textAlign={isMobile ? 'center' : 'left'}
                                    variant={'caption'}
                                    color={'var(--black, #212121)'}
                                    fontSize={isMobile ? '22px' : '32px'}
                                    fontStyle={'normal'}
                                    fontWeight={700}
                                    lineHeight={'normal'}

                                >
                                    {'¡Estar seguro, seguro nunca había sido tan fácil!'}
                                </Typography>
                            </Grid>

                        }

                        <Grid item xs={12} sm={4} md={4} sx={{
                            justifyContent: isMobile ? 'center' : 'left',
                            display: 'flex',
                            paddingTop: '40px',
                        }}>
                            <CustomButton
                                text={"¡Quiero mi seguro!"}
                                onClick={() => eventFiunction()}
                                size={'large'}
                                variant={'contained'}

                                style={{

                                    backgroundColor: window.location.pathname === "/finsus" ? myColorPalette.getfinsusColor() : 'rgb(3,158,204)',
                                    color: 'var(--white, #FFF)',
                                    // backgroundColor: 'rgb(3, 158, 204)',
                                    fontSize: '16px',
                                    textTransform: 'uppercase',
                                    fontWeight: '600',
                                    padding: '9px 17px',
                                    minWidth: isMobile ? '318px' : '268px',
                                }}
                            />
                        </Grid>
                    </Box>
                </Box>
                {!isMobile ? title === 'celular' ?
                    <div style={{ height: '386px', width: '334px' }}>
                        <img style={{ height: '386px', width: '334px' }} src={CelularMani} alt={'ImgItem1'} loading='lazy' />
                    </div>
                    :
                    <div style={{ height: '386px', width: '334px' }}>
                        <img style={{ height: '386px', width: '334px' }} src={SaludMani} alt={'ManiSalud'} loading='lazy' />
                    </div> : ''}
            </Box>

        </CenterElement >
    )
};

export default RevolutionContent;
