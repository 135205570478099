export function generatedVigenceDate(created_at: string) {
    const createdDate = new Date(created_at);
    createdDate.setFullYear(createdDate.getFullYear() + 1)
    const timeZone = "America/Mexico_City";
    createdDate.setTime(createdDate.getTime() + createdDate.getTimezoneOffset() * 60000);
    createdDate.toLocaleString("es-MX", { timeZone });
    const year = createdDate.getFullYear();
    const month = String(createdDate.getMonth() + 1).padStart(2, '0');
    const day = String(createdDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}