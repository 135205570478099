import React from 'react';

interface BlueCircleProps {
    width?: number;
    height?: number;
    fill?: string;
    stroke?: string;
}

const BlueCircle: React.FC<BlueCircleProps> = ({ 
    width = 57,
    height = 57,
    fill= '#039ECC', 
    stroke="#77CAE3" 
}) => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 64 65" fill="none">
      <circle cx="32" cy="32.8811" r="30.5" fill={fill} stroke={stroke} />
    </svg>
  );
};

export default BlueCircle;
