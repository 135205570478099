/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { styled } from 'styled-components';
import { useField, useFormikContext } from 'formik';
import FormItemGenerate from '../../../atoms/FormItems/FormItemGenerate';
import InfoButton from '../../../../assets/RegisterAssets/InfoButton';
import useFetch from '../../../../hooks/useFetch';
import {
    apiLoading as marcaLoading,
    apiSuccess as marcaSuccess,
    apiError as marcaError
} from '../../../../api/gadgetsBrandSlice';
import {
    apiLoading as modelLoading,
    apiSuccess as modelSuccess,
    apiError as modelError
} from '../../../../api/gadgetsModelsSlice';
import {
    apiLoading as descriptionLoading,
    apiSuccess as descriptionSuccess,
    apiError as descriptionError
} from '../../../../api/gadgetsDescriptionSlice';
import { useSelector } from 'react-redux';
import CustomToolTipComponent from '../../../atoms/Tooltip/CustomTooltip';
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';
import { CustomButton } from '../../../atoms/Buttons/Buttons';
import BasicModal from '../../../molecules/BasicModal/BasicModal';
import CardAction from '../../Card';
import { IStorageMembershipData } from '../../../../utils/localStorageKeys';
import { myColorPalette } from '../../../../theme/themeGenerated';

type DatosDelCelularProps = {
    handleChange?: any;
    handleBlur?: any;
}

const LabelWrapper = styled.span`
    width: 100%;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
`;

const DatosDelCelular: React.FC<DatosDelCelularProps> = ({ handleChange, handleBlur }) => {
    const [imei, imeiMeta] = useField("imei");
    const [open, setOpen] = useState(false);
    const [marcaField, marcaMeta] = useField("marca");
    const [modeloField, modeloMeta] = useField("modelo");
    const [descripcionField, descripcionMeta] = useField("descripcion");
    const [marca, setMarca] = useState('');
    const [marcaOption, setMarcaOption] = useState([]);
    const [modelo, setModelo] = useState('');
    const [modeloOption, setModeloOption] = useState([]);
    const [descripcion, setDescripcion] = useState('');
    const [descripcionOption, setDescripcionOption] = useState([]);
    const formik = useFormikContext();
    const { fetchData } = useFetch();
    const currentValue = useSelector(
        (state: any) => state.productsCards.currentValue
    ) as any | null;
    const response = useSelector((state: any) => state.gadgetsBrand.response) as any | null;
    const loading = useSelector((state: any) => state.gadgetsBrand.loading);
    // const error = useSelector((state: any) => state.gadgets.error);

    const responseModels = useSelector((state: any) => state.gadgetsModels.response) as any | null;
    const loadingModels = useSelector((state: any) => state.gadgetsModels.loading);
    // const errorModels = useSelector((state: any) => state.gadgetsModels.error);

    const responseDescription = useSelector((state: any) => state.gadgetsDescription.response) as any | null;
    const loadingDescription = useSelector((state: any) => state.gadgetsDescription.loading);
    // const errorDescription = useSelector((state: any) => state.gadgetsDescription.error);
    
    const allianceSettingResponse = useSelector((state: any) => state.allianceSetting.response);
    
    const getValue = (): string => {
        return currentValue.type === 'free' ? '0' : '1'
    }
    
    const getMarca = () => {
        return fetchData('gadgets/get-brands/' + getValue(), 'GET', {}, {}
            , marcaLoading, marcaSuccess, marcaError);
    }

    const getModelos = (idBrand: any) => {
        return fetchData(`gadgets/get-lines/brand/${idBrand}/${getValue()}`, 'GET', {}, {}
            , modelLoading, modelSuccess, modelError);
    }

    const getDescripcion = (idLine: any) => {
        return fetchData(`gadgets/get-models/line/${idLine}/${getValue()}`, 'GET', {}, {}
            , descriptionLoading, descriptionSuccess, descriptionError);
    }

    useEffect(() => {
        if (!response) {
            getMarca();
        } else if (response?.code === 200 && response?.data.length > 0) {
            const resp = response.data.map(({ name }: any) => { return name });
            setMarcaOption(resp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    useEffect(() => {
        if (descripcion) {
            const descripcionID = responseDescription.data.find(({ name }: any) => name === descripcion)
            localStorage.setItem("id_model", descripcionID.idModel)
        }
    }, [descripcion]);

    useEffect(() => {
        if (marcaField.value && response?.code === 200 && response?.data.length > 0) {
            const findMarca = response.data.find(({ name }: any) => name === marcaField.value);
            localStorage.setItem("id_brand", findMarca.idBrand)
            getModelos(findMarca.idBrand);
        }
    }, [marca, response]);

    useEffect(() => {
        if (marca) {
            formik.setFieldValue("modelo", '')
            formik.setFieldValue("descripcion", '')
            setModeloOption([]);
            setDescripcionOption([]);
        }
    }, [marca])

    useEffect(() => {
        if (modelo) {
            formik.setFieldValue("descripcion", '')
            setDescripcionOption([]);
        }
    }, [modelo])

    useEffect(() => {
        if (modeloField.value && responseModels) {
            const findModelo = responseModels.data.find(({ name }: any) => name === modeloField.value);
            if (findModelo?.idLine) {
                localStorage.setItem("id_line", findModelo?.idLine)
                getDescripcion(findModelo?.idLine);
            }
        }
    }, [modeloField.value, responseModels]);

    useEffect(() => {
        if (responseModels?.code === 200 && responseModels?.data.length > 0) {
            const resp = responseModels.data.map(({ name }: any) => { return name });
            setModeloOption(resp);
        }
    }, [responseModels])

    useEffect(() => {
        if (responseDescription?.code === 200 && responseDescription?.data.length > 0) {
            const resp = responseDescription.data.map(({ name }: any) => { return name });
            setDescripcionOption(resp);
        }
    }, [responseDescription]);

    let storage: IStorageMembershipData = {
        ...useSelector((state: any) => state.productsCards.currentValue),
    };
  const userResponse = useSelector((state: any) => state?.user?.response);

    useEffect(() => {
        if(window.location.pathname == "/portal-del-cliente/registro-nuevo-producto"){
            window.dataLayer.push({
                event: 'TRUS_nuevo_registro_dispositivo_v',
                tipo_seguro: storage.type == "free" ? "De regalo" : "Premium",
                seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
                correo: userResponse?.data?.user?.email,
                telefono: userResponse?.data?.user?.phone,
                page_location: window.location.href,
            });    
        } else {
            window.dataLayer.push({
                event: 'TRUS_registro_dispositivo_v',
                tipo_seguro: storage.type == "free" ? "De regalo" : "Premium",
                seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
                correo: undefined,
                telefono: undefined,
                page_location: window.location.href,
                alianza: storage?.business ? storage.business : allianceSettingResponse?.data?.id_bussiness,
            });
        }
    }, [])

    useEffect(() => {
        if (open) {
            window.dataLayer.push({
                event: "TRUS_no_encuentro_v",
                tipo_seguro: storage.type == "free" ? "De regalo" : "Premium",
                seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
                correo: undefined,
                telefono: undefined,
                page_location: window.location.href,
            });
        }
    }, [open])

    const items = [
        {
            id: 1,
            label: 'Marca*',
            name: 'marca',
            type: 'select',
            event: setMarca,
            value: marca,
            option: marcaOption,
            error: marcaField,
            meta: marcaMeta,
            loading: loading,
            autofocus: true
        },
        {
            id: 2,
            label: 'Modelo*',
            name: 'modelo',
            type: 'select',
            event: setModelo,
            value: modelo,
            option: modeloOption,
            error: modeloField,
            meta: modeloMeta,
            loading: loadingModels
        },
        {
            id: 3,
            label: 'Descripción*',
            name: 'descripcion',
            type: 'select',
            event: setDescripcion,
            value: descripcion,
            option: descripcionOption,
            error: descripcionField,
            meta: descripcionMeta,
            loading: loadingDescription
        }
    ]

    const imeiItem = [
        {
            id: 4,
            label: 'IMEI o IMEI 1',
            name: 'imei',
            as: 'textField',
            error: imei,
            meta: imeiMeta,
            iconAdornment:
                (<LabelWrapper style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                    <CustomToolTipComponent title="Para obtener el número de IMEI de tu teléfono celular marca *#06#. También se puede encontrar en una etiqueta blanca debajo de la batería de tu dispositivo.">
                        <div style={{ width: 21, display: 'flex' }}>
                            <InfoButton />
                        </div>
                    </CustomToolTipComponent>
                </LabelWrapper>)
        }
    ];

    return (<React.Fragment>
        <BasicModal
            styleRoot={{
                maxWidth: 526,
                boxShadow: "none",
                marginTop: '62px',
                margin: '32px 16px'
            }}
            styleContent={{
                margin: 0,
                padding: 0,
            }}
            disableBackdropClick={true}
            closeButton
            open={open}
            setOpenModal={setOpen}
            bodyDialog={<CardAction
                onClick={() => {
                    setOpen(false)
                    window.dataLayer.push({
                        event: "TRUS_no_encuentro",
                        tipo_seguro: storage.type == "free" ? "De regalo" : "Premium",
                        boton_contacto: "Entendido",
                    })
                }}
                onClickSecondary={() => {
                    window.dataLayer.push({
                        event: "TRUS_no_encuentro",
                        tipo_seguro: storage.type == "free" ? "De regalo" : "Premium",
                        boton_contacto: "Dejar mis datos",
                    })
                    window.open('https://intermx-digital.atlassian.net/servicedesk/customer/portal/8/group/36/create/96', '_blank')
                }}
                visibleImg
                secundaryButton
                buttonTextSecondary='Dejar mis datos'
                buttonText='entendido'
                style={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: '0 0 8px 8px',
                    justifyContent: 'space-around',
                    border: 'none',
                    color: '#000',
                    gap: '24px'
                }}
                styleTextContent={{
                    color: '#000'
                }}
                styleButton={{
                    borderRadius: 8,
                    background: "var(--azul-inter, #039ECC)",
                    width: "100%",
                    padding: "14px 16px",
                    color: "#FFF",
                    fontSize: 16,
                    fontWeight: 600,
                    lineHeight: 'normal',
                    border: '1.5px solid #039ECC'
                }}
                styleButtonSecondary={{
                    borderRadius: 8,
                    background: "#FFF",
                    width: "100%",
                    padding: "14px 16px",
                    color: "var(--azul-inter, #039ECC)",
                    fontSize: 16,
                    fontWeight: 600,
                    lineHeight: 'normal',
                    fontStyle: 'normal',
                    border: '1.5px solid #039ECC',
                    boxShadow: 'none'
                }}
                styleImg={{
                    backgroundColor: "#C0E6F2",
                    padding: 0,
                    display: "flex",
                    flexWrap: "wrap",
                    alignContent: "flex-end",
                }}
                setOpenModal={setOpen}
                cardContent={<React.Fragment>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem'
                    }}>
                        <span style={{
                            fontSize: '20px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: '28px',
                            letterSpacing: '0.25px'
                        }}>¿No encuentras tu celular?</span>
                        <span style={{
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '24px'
                        }}>Si tu celular no esta en nuestro listado y su fabricación no tiene más de 3 años, déjanos tus datos para verificar que sea asegurable</span>
                    </div>
                </React.Fragment>}
            />}
        />
        <div style={{ width: '100%', minHeight: '110px', marginTop: '0px', borderRadius: 8, backgroundColor: window.location.pathname.includes('finsus') ? 'rgba(35, 34, 63, 0.10)' : 'rgb(242, 250, 252)', justifyContent: 'center' }}>
            <p style={{ marginBottom: '25px', marginLeft: '25px', marginRight: '25px', marginTop: '5px' }}><br /><span style={{
                fontWeight: 600
            }}>¡Importante!</span> <br />
                {
                    currentValue.type === 'free'
                        ? 'Recuerda que tu celular no debe tener más de 5 años de antigüedad de fabricación para poder asegurarlo.'
                        : 'Recuerda que aplica para modelos de celular que su factura o comprobante de compra no tenga más de 3 años de antigüedad.'
                }
            </p>
        </div>
        <FormItemGenerate
            items={items}
            handleChange={handleChange}
            handleBlur={handleBlur}
        />
        {/* <FormItemGenerate
            items={imeiItem}
            handleChange={handleChange}
            handleBlur={handleBlur}
        /> */}
        <div style={{ width: '100%' }}>
            <CustomButton
                onClick={() => setOpen(true)}
                text="No encuentro mi celular"
                style={{
                    borderRadius: 8,
                    background: "#FFF",
                    width: "100%",
                    padding: "0px",
                    color: myColorPalette.getPrimaryColor(),
                    fontSize: 16,
                    fontWeight: 600,
                    lineHeight: 'normal',
                    fontStyle: 'normal',
                    boxShadow: 'none',
                    textDecoration: 'underline',
                    justifyContent: 'end',
                    textTransform: 'none'
                }}
            // href="https://intermx-digital.atlassian.net/servicedesk/customer/portal/8/group/34/create/100"
            // target="_blank"
            ></CustomButton>
            <br />

        </div>
    </React.Fragment>)
};

export default DatosDelCelular;