import * as React from 'react';
import { Button } from '@mui/material';

type ButtonsProps = {
    text: string;
    onClick?: () => void;
    type?: string;
}

export const PrimaryButton: React.FC<ButtonsProps> = ({
    text,
    onClick,
    type = "button"
}) => {

    return (
        <React.Fragment>
            <Button
                type={type}
                href=''
                size={"medium"}
                variant={"contained"}
                style={{
                    borderRadius: 8,
                    background: "var(--azul-inter, #039ECC)",
                    width: "100%",
                    padding: "14px 16px",
                    color: "#FFF",
                    fontSize: 16,
                    fontWeight: 600,
                    lineHeight: 'normal'
                }}
                onClick={onClick}
                >
                {text}
            </Button>
        </React.Fragment>
    );
};

export const OutlinedButton: React.FC<ButtonsProps> = ({
    text,
    onClick
}) => {

    return (
        <Button
            type="button"
            size={"medium"}
            variant={"contained"}
            style={{
                borderRadius: 8,
                background: "#FFF",
                width: "100%",
                padding: "14px 16px",
                color: "var(--azul-inter, #039ECC)",
                fontSize: 16,
                fontWeight: 600,
                lineHeight: 'normal',
                fontStyle: 'normal',
                border: '1.5px solid #039ECC',
                boxShadow: 'none'
            }}
            onClick={onClick}
            >
            {text}
        </Button>
    )
}
