import { useState, useContext } from 'react';
import { Formik, Form } from 'formik';
import { Button, FormControlLabel, Checkbox, Grid } from '@mui/material';
import CustomContainer from '../../components/organisms/Container/Container';
import { ContextAuth0 } from '../../auth/contextAPIProvider';
import { useDispatch, useSelector } from "react-redux";
import { CustomTypography } from "../../components/atoms/Label/Label";
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/system';
import { CustomButton } from '../../components/atoms/Buttons/Buttons';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import useFetch from '../../hooks/useFetch';
import { isAuth, logout } from '../../auth/auth0';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { InterInstance, addAccessToken } from '../../api/config';
import RenderAlert from '../../components/organisms/Modal';

const StyledToggleButton = styled(ToggleButton)(({ theme, selected }) => ({
    color: '#212121',
    backgroundColor: '#FFFFFF',
    borderColor: '#039ECC',
    borderStyle: 'solid',
    marginTop: '10px',
    height: "66px"
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
        borderRadius: '50%'
    },
}));

const options = ['No he usado mi producto',
    ' Es muy complicado de usar',
    ' Las coberturas no me gustaron',
    ' Encontré un producto mejor',
    ' Solo quería probar',];

const SurveyForm = () => {
    const navigate = useNavigate();
    const [selected, setSelected] = useState<Record<string, boolean>>({});

    const deleteAccount = async () => {
        try {
            await addAccessToken()
            const { data } = await InterInstance.get(`/users/auth/cancel-user`)
            if (data?.data?.error) {
                return RenderAlert({ severity: "warning", message: "Ocurrio un error inesperado" })
            }else{
                navigate('/goodbye', { state: { deleteAcount: true } })
            }
        } catch (error) {
            RenderAlert({ severity: "warning", message: "Ocurrio un error inesperado" })
            console.log(error)
        }
    }

    return (
        <CustomContainer childComponent={
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ maxWidth: 558, margin: 'auto' }}
            >
                <div style={{width: "100%", marginBottom: "20px"}}>
                    <CustomButton
                        text={"Regresar"}
                        onClick={() => navigate(-1)}
                        size={"medium"}
                        startIcon={<ArrowBack />}
                        variant={"contained"}
                        style={{
                            color: '#039ECC',
                            backgroundColor: "transparent",
                            fontSize: "1rem",
                            textTransform: "none",
                            fontWeight: "600",
                            fontStyle: "normal",
                            marginTop: '45px',
                            minWidth: "64px",
                            border: "none",
                            justifyContent: "flex-start",
                            width: "fit-content",
                        }}
                    />
                </div>

                <Formik
                    initialValues={{ selectedOptions: [] }}
                    onSubmit={(values) => {
                        deleteAccount()
                    }}
                    style={{
                        width: '100%'
                    }}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '5px'
                                }}>
                                <CustomTypography
                                    style={{
                                        fontFamily: 'Montserrat',
                                        fontSize: '16px',
                                        fontWeight: '700',
                                        lineHeight: '19.5px',
                                        textAlign: 'center',
                                    }}
                                >
                                    {'Antes de que te vayas, ¿nos ayudas a conocer un poco más de tu decisión? '}
                                </CustomTypography>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                <ToggleButtonGroup
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start'

                                    }}
                                    value={values.selectedOptions}
                                    onChange={(event, newOptions) => {
                                        setFieldValue('selectedOptions', newOptions);
                                    }}
                                >
                                    {options.map((option, index) => (
                                        <StyledToggleButton
                                            key={index}
                                            value={option}
                                            onClick={() => setSelected({ ...selected, [option]: !selected[option] })}
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                backgroundColor: selected ? '#FFFFFF' : '',
                                                borderLeft: '1px solid #039ECC',
                                                borderRadius: '10px'
                                            }}
                                        >
                                            {option}
                                            <FormControlLabel
                                                control={
                                                    <>
                                                        <div>
                                                            {selected[option] &&
                                                                <StyledCheckbox
                                                                    checkedIcon={<CheckCircleIcon style={{ borderRadius: '50%' }} ></CheckCircleIcon>}
                                                                    checked={selected[option]}
                                                                />
                                                            }
                                                        </div>
                                                    </>
                                                }
                                                label=""
                                            />
                                        </StyledToggleButton>
                                    ))}
                                </ToggleButtonGroup>

                                <Button
                                    style={{
                                        background: '#039ECC',
                                        color: '#FFFFFF',
                                        width: '100%',
                                        padding: '14px 16px 14px 16px',
                                        marginTop: '25px',
                                        borderRadius: '8px 8px 8px 8px',
                                        opacity: '0px',
                                    }}
                                    type="submit">Cancelar Cuenta</Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Grid>
        } />
    );
};

export default SurveyForm;