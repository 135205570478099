import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    PaymentElement,
    CardElement,
} from "@stripe/react-stripe-js";
import { useStripe, useElements } from '@stripe/react-stripe-js';

import { LoadingButton } from '@mui/lab';
import ResumeComponent from './ResumeComponent';
import { IStorageMembershipData } from '../../../../utils/localStorageKeys';
import { useSelector } from 'react-redux';
import LoadingComponent from '../../../organisms/loading/loading';
import BackButtonInter from '../../../atoms/Buttons/BackButtonInter';
import { useLocation, useNavigate } from 'react-router-dom';
import { InterInstance } from '../../../../api/config';
import RenderAlert from '../../../organisms/Modal';
import { IUserData } from '../../../organisms/CustomerPortalContent/interfaces/user';
import { useMediaQuery, useTheme } from "@mui/material";
import { StripeCardElement } from '@stripe/stripe-js';

const PaymentContainer = styled.div`
    width: 100%;
`

const ContainerBackground = styled.div`
    background:'#F8FAFC';
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 15px;
   
`

const PaymentDetail = styled.div`
    display: flex;
    width: 100%;
`;
interface PaymentElementProps {
    title?: string;
    onlyCardPresentation?: boolean;
    newProductRegistration?: boolean;
    setActiveStep?: any;
    handleChange?: any;
}
const PaymentElementComponent: React.FC<PaymentElementProps> = ({
    title,
    onlyCardPresentation,
    handleChange
}) => {
  const userDataPersist = useSelector((state: any) => state.user.response);
    const { breakpoints } = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const isTablet = useMediaQuery(breakpoints.down('md'))
    const [loading, setLoading] = useState(true);
    const [stripeLoading, setStripeLoading] = useState(false);
    const user: IUserData = useSelector((state: any) => state.user.response.data);
    const tracking = useSelector((state: any) => state.tracking.response);
    const location = useLocation();

    const [success, setSuccess] = useState(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [premiumPlanExist, setPremiumPlanExist] = useState<boolean>(false);
    const dataFormRegister = useSelector((state: any) => state.formRegister?.response);

    const stripe = useStripe();
    const elements = useElements();
    const currentValue: any = useSelector((state: any) => state.productsCards.currentValue);
    const allianceSettingResponse = useSelector((state: any) => state.allianceSetting.response);
    const userData = JSON.parse(sessionStorage.getItem('user-storage')!);
    const navigate = useNavigate();

    const navigateToPayment = (path: string, success: boolean) => {
        setLoading(false);
        navigate('/payment', { state: { path: path, success: success } })
    }

    useEffect(() => {
        if (stripe && elements) {
            setStripeLoading(true);
        }
    }, [stripe, elements]);

    useEffect(() => {
        if (userDataPersist) {
          const planSearch = userDataPersist?.data?.plans.find((p: any) => p.tier);
          setPremiumPlanExist(planSearch)
        }
      }, [userDataPersist]);

    const customerAfter = async (customerAfter: any) => {
        try {
            const objectCustomerAfter: any = {
                event: "stripe-subscription-before",
                content: {
                    tracking_id: tracking?.data?.tracking_id,
                    token: customerAfter?.data?.token,
                    // benefit_id: currentValue.benefit_id === "C01" ? "C02" : "S04",
                    benefit_id: (currentValue.benefit_id === "C01" || currentValue.benefit_id === "C02") ? "C02" : "S04",
                    business: allianceSettingResponse?.data?.id_bussiness || user?.business?.id_bussiness
                },
            }

            if (location.pathname === "/portal-del-cliente/registro-nuevo-producto" && (currentValue.benefit_id === "C01" || currentValue.benefit_id === "C02")) {
                objectCustomerAfter.content["gadgedsInfo"] = {
                    "id_brand": localStorage?.id_brand,
                    "id_line": localStorage?.id_line,
                    "id_model": localStorage?.id_model,
                    "imei": null
                }
            }

            const { data: tmpData } = await InterInstance.post("payments/stripe", objectCustomerAfter)
            if (tmpData) {
                //Mandar a pantalla de exito
                window.dataLayer.push({
                    event: 'TRUS_pagar',
                    boton_contacto: 'Pagar',
                    seguro: currentValue.category == "celular" ? "Celular Protect" : "Salud Protect",
                    tipo_seguro: "Premium",
                    correo: userData?.correo,
                    telefono: userData?.telefono,
                    alianza: currentValue?.business ? currentValue.business : allianceSettingResponse?.data?.id_bussiness,
                })
                sessionStorage.setItem('exitoPremium', '1');

                if (onlyCardPresentation) {
                    setSuccess(true);
                    setOpenModal(true);
                    return
                } else {
                    navigateToPayment("/crear-cuenta", true)
                    return
                }
            }
            //Mandar a pantalla de error de pago
            if (onlyCardPresentation) {
                setSuccess(false);
                setOpenModal(true);
            } else {
                navigateToPayment("/contratacion", false)
            }
        } catch (error) {
            console.error(error)
        }
    }

    //Metodos a utilizar para usuario freemium
    const createCustomer = async (createCustomerObject: any) => {
        if (!premiumPlanExist) {
            const { data } = await InterInstance.post("payments/stripe", createCustomerObject)

            if (data) {
                await createSubscription()
            }
        } else {
            await createSubscription()
        }
    }

    const createSubscription = async () => {
        const customer: any = {
            event: "stripe-subscription",
            content: {
                tracking_id: tracking?.data?.tracking_id,
                // benefit_id: currentValue.benefit_id === "C01" ? "C02" : "S04",
                benefit_id: (currentValue.benefit_id === "C01" || currentValue.benefit_id === "C02") ? "C02" : "S04",
                business: allianceSettingResponse?.data?.id_bussiness || userData?.data?.business?.id_bussiness
            },
        }

        if (currentValue.benefit_id === "C01" || currentValue.benefit_id === "C02") {
            customer.content["gadgedsInfo"] = {
                "id_brand": localStorage?.id_brand,
                "id_line": localStorage?.id_line,
                "id_model": localStorage?.id_model,
                "imei": null
            }
        }

        const { data } = await InterInstance.post("payments/stripe", customer)
        if (data) {
            //Mandar a pantalla de exito
            if (window.location.pathname == "/portal-del-cliente/registro-nuevo-producto") {
                window.dataLayer.push({
                    event: 'TRUS_nuevo_pagar',
                    boton_contacto: 'Pagar',
                    seguro: currentValue.category == "celular" ? "Celular Protect" : "Salud Protect",
                    tipo_seguro: "Premium",
                    correo: userData?.correo,
                    telefono: userData?.telefono,
                })
            } else {
                window.dataLayer.push({
                    event: 'TRUS_pagar',
                    boton_contacto: 'Pagar',
                    seguro: currentValue.category == "celular" ? "Celular Protect" : "Salud Protect",
                    tipo_seguro: "Premium",
                    correo: userData?.correo,
                    telefono: userData?.telefono,
                    alianza: currentValue?.business ? currentValue.business : allianceSettingResponse?.data?.id_bussiness,

                })
            }
            sessionStorage.setItem('exitoPremium', '1');
            if (onlyCardPresentation) {
                setSuccess(true);
                setOpenModal(true);
                handleChange(data)
                return
            } else {
                navigateToPayment("/portal-del-cliente", true)
                return
            }
        }
        //Mandar a pantalla de error de pago
        if (onlyCardPresentation) {
            setSuccess(false);
            setOpenModal(true);
        } else {
            navigateToPayment("/contratacion", false)
        }
    }

    const handleSubmitNew = async (event: React.FormEvent<HTMLFormElement>) => {
        setLoading(true);
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        const { setupIntent, error } = await stripe.confirmSetup({
            elements,
            redirect: 'if_required'
        });


    }
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        setLoading(true);
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        let inputValue = user.user.name + ' ' + user.user.middle_name + ' ' + user.user.last_name;
        const { setupIntent, error } = await stripe.confirmSetup({
            elements,
            redirect: 'if_required'
        });
        const userIsAlreadyRegistered = sessionStorage.access_token
        if (userIsAlreadyRegistered) {
            //Flujo de freemium a premium
            const customerObject = {
                event: "stripe-customer",
                content: {
                    tracking_id: tracking?.data?.tracking_id,
                    payment_method: {
                        id: setupIntent?.payment_method
                    },
                    business: allianceSettingResponse?.data?.id_bussiness || userData?.data?.business?.id_bussiness
                }
            }
            try {
                await createCustomer(customerObject)
            } catch (error) {
                //Mandar a pantalla de error de pago
                if (onlyCardPresentation) {
                    setSuccess(false);
                    setOpenModal(true);
                } else {
                    navigateToPayment("/contratacion", false)
                }
            }
        } else {
            //Flujo directo a premium
            const objectCustomerBefore = {
                event: "stripe-customer-before",
                content: {
                    tracking_id: tracking?.data?.tracking_id,
                    name: `${dataFormRegister.name} ${dataFormRegister.middle_name} ${dataFormRegister.last_name}`,
                    phone: dataFormRegister.phone,
                    email: dataFormRegister.email,

                    payment_method: {
                        id: setupIntent?.payment_method
                    },
                    business: allianceSettingResponse?.data?.id_bussiness || userData?.data?.business?.id_bussiness
                }
            }
            try {
                await customerBefore(objectCustomerBefore)
            } catch (error) {
                console.error(error)
            }
        }
    };

    //Metodos a utilizar para usuario sin registro previo
    const customerBefore = async (customerBefore: any) => {
        try {
            const { data } = await InterInstance.post("payments/stripe", customerBefore)
            await customerAfter(data)
        } catch (error) {
            setSuccess(false);
            setLoading(false);
            setOpenModal(true);
            console.error(error)
        }
    }

    const getMessage = (type: "free" | "premium") => {
        return type === "premium" ? 'Premium' : ''
    }
    return (<React.Fragment>

        <div style={{
            paddingTop: '15px'
        }}>
            <BackButtonInter />
        </div>
        <div id='card-element'></div>
        {
            <LoadingComponent isLoaded={stripeLoading} />
        }
        {
            stripeLoading && <PaymentContainer>

                <form onSubmit={handleSubmit} style={!isMobile ? {
                    display: 'grid',
                    gridTemplateColumns: '3fr 2fr'
                } : {}}>
                    <ContainerBackground>
                        <h3>{title}</h3>
                        <PaymentElement
                            onReady={() => {
                                setStripeLoading(true);
                            }}
                            options={{
                                layout: {
                                    type: 'accordion'
                                },

                            }} />
                    </ContainerBackground>
                    <div>
                        <ContainerBackground>
                            <PaymentDetail>
                                {currentValue && <ResumeComponent items={[
                                    {
                                        label: currentValue.cardTitle + ' ' + getMessage(currentValue.type),
                                        price: currentValue.type === 'free' ? 0 : 99
                                    }
                                ]} />}
                            </PaymentDetail>
                        </ContainerBackground>
                        <LoadingButton loading={!loading} type='submit' variant='contained' fullWidth={true} >
                            Pago
                        </LoadingButton>
                    </div>

                </form>
            </PaymentContainer>
        }

    </React.Fragment>
    )
}

export default PaymentElementComponent