import React from "react";
import CustomContainer from "../../organisms/Container/Container";
import { CustomTypography } from "../../atoms/Label/Label";
import PaymentMethod from "../../organisms/PaymentMethod";
import { styled } from "styled-components";
import PaymentFrequency from "../../organisms/PaymentFrequency";
import { CustomButton } from "../../atoms/Buttons/Buttons";
import CreditContentLogo from "../../organisms/PaymentMethod/CreditContentLogo";
import { Box, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TotalCard, { TotalCardItem } from '../../molecules/TotalCard/TotalCard';

const LayOutContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 85px;
  gap: 40px;
  align-items: center;
  text-align: center;
  max-width: 946px;
  text-wrap: balance;
  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 1fr .7fr;
    grid-template-rows: auto;
    grid-gap: 40px 24px;
    align-items: stretch;
    text-align: left;
    text-wrap: inherit;
  }
`;

const items: TotalCardItem[] = [{
  label: 'Seguro inter salud Esencial',
  price: 90,
  sublabel: 'al mes'
}]

const HiringPaymentMembership: React.FC = () => {
  const navigate = useNavigate();
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <React.Fragment>
      <CustomContainer childComponent={
        <LayOutContainer>
          <Box sx={{
            width: '100%',
            gridColumn: 1,
            gap: '24px',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <CustomTypography
              style={{
                fontSize: matches ? 28 : 24,
                maxWidth: 580,
                fontWeight: 700,
                letterSpacing: "0.5px",
                textAlign: "center",
                Padding: "0 1rem",
                textWrap: "balance",
                margin: matches ? '8px 0' : '8px auto'
              }}
            >Forma de Pago</CustomTypography>
          </Box>
          <Box sx={{
            width: '100%',
            gridColumn: 1,
            gap: '24px',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <PaymentMethod />
          </Box>
          <Box sx={{
            width: '100%',
            gridColumn: 1,
            gap: '24px',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <PaymentFrequency />
          </Box>
          <Box sx={{
            width: '100%',
            gridColumn: 2,
            gridRow: 2,
            gap: '24px',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <TotalCard style={{
              root: {
                display: 'flex',
                padding: 16,
                flexDirection: 'column',
                gap: 24,
                alignSelf: 'stretch',
                borderRadius: 8,
                background: 'rgba(230, 179, 49, 0.05)'
              },
              itemsCard: {
                gap: 24
              },
              totalInfo: {
                margin: 0,
                color: 'var(--Black, #212121)',
                fontSize: 18,
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal'
              }
            }} items={items} key={"totalCard"}></TotalCard>
            <CustomButton style={{
              width: '100%',
              maxWidth: 580,
              borderRadius: 8,
              padding: 16,
              height: 48,
              background: 'var(--Azul-Inter, #039ECC)',
              color: '#FFF',
              fontSize: 16,
              fontStyle: 'normal',
              fontWeight: 600,
              display: 'flex',
              justifyContent: 'center'
            }} onClick={() => navigate('/contratacion/realizar-pago')} text="vamos al pago" />
          </Box>
          <Box sx={{
            width: '100%',
            gridColumn: 1,
            gap: '24px',
            display: 'grid'
          }}>
            <CustomTypography
              style={{
                fontSize: 20,
                fontWeight: 400,
                lineHeight: 'normal'
              }}
            >¿No tienes ninguno de estos medios de pago?
              Te ayudamos a tramitarlo</CustomTypography>
            <CreditContentLogo elementContent={
              <CustomButton
                style={{
                  borderRadius: 8,
                  border: '1.5px solid var(--Azul-Inter, #039ECC)',
                  display: 'flex',
                  padding: '14px 16px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'stretch',
                  color: 'var(--Azul-Inter, #039ECC)',
                  textAlign: 'center',
                  fontSize: 16,
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: 'normal'
                }}
                onClick={() => {}}
                text="Quiero mi credito" />} />
          </Box>
        </LayOutContainer>
      } />
    </React.Fragment>
  );
};

export default HiringPaymentMembership;
