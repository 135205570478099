import React from 'react';
import ErrorTemplate from '../../components/templates/ErrorPagina';

type ErrorProps = {};

const ErrorPagina: React.FC<ErrorProps> = () => {
  return <ErrorTemplate />;
};

export default ErrorPagina;
