import React from 'react';

interface BlueCircleProps {
    width?: number;
    height?: number;
    fill?: string;
    stroke?: string;
}

const InfoAdButton: React.FC<BlueCircleProps> = ({
    width = 25,
    height = 24,
    fill= '#039ECC',
    stroke="#77CAE3"
}) => {
  return(
      <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.5299 3.73302e-05C10.1553 -0.00588449 7.83226 0.692867 5.85494 2.00784C3.87763 3.32281 2.3349 5.19488 1.42206 7.38706C0.509221 9.57924 0.267308 11.993 0.726945 14.3227C1.18658 16.6525 2.3271 18.7934 4.00413 20.4747C5.68116 22.1559 7.8193 23.3018 10.1479 23.7672C12.4765 24.2327 14.8908 23.9968 17.0852 23.0894C19.2797 22.1821 21.1556 20.644 22.4755 18.67C23.7954 16.6959 24.5 14.3747 24.5 12C24.5039 10.4256 24.1972 8.86583 23.5974 7.4101C22.9976 5.95436 22.1165 4.63126 21.0046 3.51656C19.8927 2.40187 18.5718 1.51748 17.1176 0.914059C15.6633 0.310636 14.1044 3.241e-05 12.5299 3.73302e-05ZM11.3539 18.5207L11.7938 10.2584H13.5414L13.9663 18.5207H11.3539ZM13.682 8.37909C13.5459 8.50878 13.3853 8.61 13.2095 8.6768C13.0338 8.7436 12.8465 8.77465 12.6586 8.76811C12.4761 8.77462 12.2942 8.74343 12.1243 8.67649C11.9544 8.60956 11.8001 8.50832 11.6711 8.37909C11.5383 8.2556 11.4334 8.10527 11.3632 7.9381C11.293 7.77093 11.2592 7.59075 11.2641 7.40951C11.2616 7.22653 11.2961 7.04493 11.3655 6.87562C11.435 6.7063 11.5379 6.55276 11.6681 6.4242C11.7984 6.29564 11.9532 6.19471 12.1234 6.12748C12.2936 6.06025 12.4757 6.0281 12.6586 6.03295C12.8465 6.02642 13.0338 6.05747 13.2095 6.12427C13.3853 6.19107 13.5459 6.29229 13.682 6.42198C13.8142 6.54969 13.9186 6.70333 13.9886 6.87326C14.0586 7.04319 14.0928 7.22575 14.089 7.40951C14.0938 7.59075 14.06 7.77093 13.9899 7.9381C13.9197 8.10527 13.8148 8.2556 13.682 8.37909Z" fill="#039ECC"/>
    </svg>
  );
};

export default InfoAdButton;
