import React from 'react'
import styled from 'styled-components';

export default function Medications() {
    const BlackText = styled.span`
        color: #212121;
        font-family: 'Montserrat';
        font-size: '16px';
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
    `;

    const Paragraph = styled.div`
        margin-top: 16px;
   `

    return (
        <>
            <Paragraph>
                <BlackText>Medicamentos:</BlackText>
            </Paragraph>
            A solicitud del cliente se realizará la gestión de medicamentos recetados derivados de alguna consulta médica general por telemedicina. Para que este beneficio sea procedente, el cliente tuvo que haber utilizado la cobertura de Telemedicina y deberá presentar la receta médica expedida durante los primero 10 días naturales posteriores a la consulta. En el caso de que el costo del medicamento exceda el límite de la asistencia le será notificado al cliente referido para validar que acepte cubrir el excedente.

            <Paragraph>A solicitud del cliente se realizará la gestión de medicamentos recetados derivados de alguna consulta médica general por telemedicina. Para que este beneficio sea procedente, el cliente tuvo que haber utilizado la cobertura de Telemedicina y deberá presentar la receta médica expedida durante los primero 10 días naturales posteriores a la consulta. En el caso de que el costo del medicamento exceda el límite de la asistencia le será notificado al cliente referido para validar que acepte cubrir el excedente.</Paragraph>

            El servicio deberá ser coordinado desde un inicio por INTERPROTECCIÓN ASISTENCIAS para hacer válido el beneficio.

            <Paragraph>
                <BlackText>Monto cubierto:</BlackText> 1 evento durante la vigencia del programa hasta por $1,000 MN.
            </Paragraph>

            <Paragraph>
                <BlackText>Edad de aceptación:</BlackText> de 18 a 69 años
            </Paragraph>

            <Paragraph>Aplica únicamente para el Asegurado.</Paragraph>

            <Paragraph>
                <BlackText>
                    No aplica el beneficio en medicamentos controlados.
                </BlackText>
            </Paragraph>
        </>
    )
}
