import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { CustomButton } from '../../../../components/atoms/Buttons/Buttons';
import useFetch from '../../../../hooks/useFetch';
import {
  apiError as apiBeneficiaryError,
  apiLoading as apiBeneficiaryLoading,
  apiSuccess as apiBeneficiarySuccess
} from '../../../../api/multipleBeneficiarySlice';

interface IPropsDeleteBeneficiaryModal {
  beneficiary: any;
  setOpenModal?: any;
}

const DeleteBeneficiaryModal: FC<IPropsDeleteBeneficiaryModal> = ({ beneficiary, setOpenModal }) => {
  const navigate = useNavigate();
  const { fetchData } = useFetch();

  const Container = styled.div`
    background-color: #FFFFFF;
    width: 293px;
    height: 150px;
    max-height: 188px;
    padding: 16px;
    border-radius: 8px
  `;

  const Text = styled.div`
    font-size: 18px;
    text-align: center;
    line-height: 28px;
  `;

  const Name = styled.span`
    font-weight: 700;
  `;

  const ContainerButtons = styled.div`
    display: flex;
    justify-content: space-around;
    gap: 24px;
  `;

  const handleDelete = () => {
    const body = {
      "benefy_index": beneficiary?.index
    };

    fetchData('users/auth', 'POST', {
      event: "deleted-benefi",
      content: body
    }, {}, apiBeneficiaryLoading, apiBeneficiarySuccess, apiBeneficiaryError);

    navigate('/muerte-accidental', { state: { showModal: true } })
  }

  return (
    <>
      <Container>
        <Text>
          ¿Estás seguro de que quieres eliminar a <Name>{beneficiary?.title}</Name> como tu beneficiario?
        </Text>

        <div style={{ position: 'absolute', bottom: '16px', width: '90%'}}>
        <ContainerButtons>
          <CustomButton
            onClick={() => setOpenModal(false)}
            text="Cancelar"
            style={{
              color: "#039ECC",
              borderRadius: 8,
              border: "1px #039ECC solid",
              background: "#FFFFFF",
              textAlign: "center",
              fontSize: 16,
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              textTransform: "uppercase",
              padding: "14px 16px",
              width: "100%",
            }}
          />
          <CustomButton
            onClick={handleDelete}
            text="Confirmar"
            style={{
              color: "#FFFFFF",
              borderRadius: 8,
              border: "#039ECC",
              background: "#039ECC",
              textAlign: "center",
              fontSize: 16,
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              textTransform: "uppercase",
              padding: "14px 16px",
              width: "100%",
            }}
          />
        </ContainerButtons>
        </div>
      </Container>
    </>
  );
}

export default DeleteBeneficiaryModal