import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import BackButtonInter from '../../../atoms/Buttons/BackButtonInter';
import ButtonInter from '../../../atoms/Buttons/ButtonInter';
import { useNavigate } from "react-router-dom";
import imageBanner from '../assets/pendingPayment.png'
import { useSelector } from 'react-redux';
import { BucketStorageFiles } from '../../../../utils/bucket.storage';
import { InterInstance } from '../../../../api/config';
import RenderAlert from '../../Modal';
import { StatusUassits } from '../../CustomerPortalContent/components/benefitEngine/benefit.values';
import CustomContainer from '../../Container/Container';
import InterContainer from '../../Container/ContainerFixed';
import { LoadingButton } from '@mui/lab'
import { myColorPalette } from '../../../../theme/themeGenerated';
const PendingPaymentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px;
    box-sizing: border-box;
    margin:auto;
`;


interface DirectoryValue {
    url: string;
    button: string;
    buttonNegative?: string;
}
const directory = {
    aviso: {
        url: '/estatus',
        button: 'Continuar',
        buttonNegative: 'No me interesa'
    } as DirectoryValue,
    sendForm: {
        url: '/email-espera',
        button: 'Continuar',
        buttonNegative: 'No me interesa'
    } as DirectoryValue,
    sendExtra: {
        url: '/portal-del-cliente',
        button: 'Entiendo',
    } as DirectoryValue

}
type StepsDirectoryTypes = keyof typeof directory;


const formatNumberToCurrency = (number: number, currencySymbol = '$', currencyCode = 'MXN') => {
    const parts = number.toFixed(2).toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `${currencySymbol}${parts.join('.') || '0'} ${currencyCode}`;
}

const PendingPaymentComponent = () => {
    const navigate = useNavigate();
    const { breakpoints } = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));

    const PriceStatus = useSelector((state: any) => state.price.response);
    const StatusState = useSelector((state: any) => state.status.response);
    const [step, setStep] = useState<StepsDirectoryTypes>("sendForm");

    const bucket = new BucketStorageFiles('celular-reparacion');
    const response = useSelector((state: any) => state.formCellPhoneRepair.response) as any | null;
    const [loading, setLoading] = useState(false);

    const sendClaim = async (form: FormData) => {
        try {
            const { data } = await InterInstance.post("/gadgets/claim/", form);
            return data
        } catch (error) {
            console.error(error);
            return error
        }
    }
    const generateFormAndSend = async () => {
        const front = bucket.getFile('front_image');
        const back = bucket.getFile('back_image');

        const formData = new FormData();
        formData.append('street', response.street);
        formData.append('municipality', response.municipality);
        formData.append('city', response.city);
        formData.append('colony', response.colony);
        formData.append('state', response.state);
        formData.append('zipcode', response.zipCode);
        formData.append('additional_info', response.reference);
        formData.append('decription', response.description);
        formData.append('date_siniestro', response.date);
        formData.append('front', front);
        formData.append('back', back);
        const responseData = await sendClaim(formData);
        setLoading(false);
        if (responseData.code === "ERR_BAD_REQUEST") {
            console.error("Error al generar la solicitud de claim", responseData);
            return RenderAlert({ severity: "error", message: "Hubo un error al realizar la solicitud" })
        }
    }

    useEffect(() => {
        if (StatusState && StatusState.data?.estatus) {
            const homoEstatus = StatusState.data?.estatus.toLocaleLowerCase();
            setStep("aviso");
            if (homoEstatus === StatusUassits.pagoExtra) {
                setStep('sendExtra')
            }
        } else {
            setStep('sendForm')
        }
    }, [StatusState])


    const renderMessage = () => {
        if (step === "sendExtra") {
            return (<Typography>
                Hemos detectado que  tu celular tiene <strong>(tipo de falla o fallas adicionales)</strong> en breve se pondrán en contacto contigo.
            </Typography>)
        } else {
            const limit: number = 4000;
            const copago: number = 400;
            if (PriceStatus?.data?.price > limit) {
                const calculo = parseInt(PriceStatus.data.price) - limit;
                return (<Typography>
                    El costo de reparación de tu pantalla rebasa el límite de la cobertura gratuita, <strong>tendrías que pagar {formatNumberToCurrency(calculo)} pesos extras y {formatNumberToCurrency(copago)} de copago </strong> para poder realizarla, ¿quieres seguir con tu solicitud?
                </Typography>)
            } else {
                return (<Typography>
                    Para continuar tu reparación de pantalla <strong> tienes que cubrir {formatNumberToCurrency(copago)} pesos por concepto  de copago </strong> ¿quieres seguir con tu solicitud?
                </Typography>)
            }
        }
    }
    return (<InterContainer>
        <PendingPaymentContainer style={{
            width: isMobile ? '100%' : '70%'
        }}>
            <BackButtonInter />

            <Box sx={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <img src={imageBanner} alt="" />
            </Box>
            <Box>
                {
                    renderMessage()
                }
            </Box>
            <br />
            <Grid style={
                {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                    width: '100%',
                    alignItems: 'center'
                }
            }>
                <LoadingButton variant='contained'
                    type="submit"
                    size="medium"
                    loading={loading}
                    loadingPosition="end"
                    disabled={loading}
                    style={{
                        color: "var(--white, #FFF)",
                        fontSize: "16px",
                        fontWeight: "600",
                        padding: "12px 16px",
                        minWidth: "64px",
                        width: "100%",
                        borderRadius: "8px",
                        opacity: loading ? '0.5' : '1.0',
                        background: myColorPalette.getPrimaryColor(),
                    }}
                    onClick={async () => {
                        if (step === 'sendForm') {
                            setLoading(true);
                            await generateFormAndSend();
                            navigate(directory["sendForm"].url);
                        } else if (step === 'sendExtra') {
                            navigate(directory["sendExtra"].url);
                        } else if (step === 'aviso') {
                            navigate(directory["aviso"].url);
                        }
                    }} >
                    {loading ? 'Enviando...' : directory[step].button}
                </LoadingButton>

                {directory[step].buttonNegative && <ButtonInter variant='outlined' onClick={() => {
                    navigate('/portal-del-cliente')
                }}>
                    {directory[step].buttonNegative}
                </ButtonInter>}

            </Grid>
        </PendingPaymentContainer >
    </InterContainer>
    )
}

export default PendingPaymentComponent