import React from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { CustomButton } from '../../components/atoms/Buttons/Buttons';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Generaldatestab from './Generaldatestab'
import { CustomTypography } from "../../components/atoms/Label/Label";
import SafesTab from './SafesTab'
import CustomTab from '../../components/organisms/CustomTabs';


export default function SimpleTabs() {
    const navigate = useNavigate();

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '45px',
            width: "100%"
        }}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    alignSelf: 'stretch',
                }}
                className='back-button'>
                <CustomButton
                    text={"Regresar"}
                    onClick={() => navigate(-1)}
                    size={"medium"}
                    startIcon={<ArrowBack />}
                    variant={"contained"}
                    style={{
                        color: '#039ECC',
                        backgroundColor: "transparent",
                        fontSize: "1rem",
                        textTransform: "none",
                        fontWeight: "600",
                        fontStyle: "normal",
                        minWidth: "64px",
                        border: "none",
                        justifyContent: "flex-start",
                        width: "fit-content",
                    }}
                />
            </div>
            <div style={{padding: "6px 16px"}}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: '16px',
                        alignSelf: 'stretch',
                        paddingBottom: '24px'

                    }}
                >
                    <CustomTab data={[
                            {
                                label: 'Datos Generales',
                                content: <Generaldatestab />
                            },
                            {
                                label: 'Mis Seguros',
                                content: <SafesTab />
                            },
                        ]} 
                    />
                </div>
            </div>
        </div>

    );
}