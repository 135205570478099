import React, { useState } from 'react';
import { useField } from 'formik';
import FormItemGenerate from '../../../atoms/FormItems/FormItemGenerate';
import { styled } from 'styled-components';
import { OutlinedButton, PrimaryButton } from '../../../molecules/CellPhoneRepairButtons';
import { useNavigate } from 'react-router-dom';
import { IStorageMembershipData } from '../../../../utils/localStorageKeys';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { CustomTypography } from '../../../atoms/Label/Label';

type AccidentDetailProps = {
    errors?: any;
    handleChange?: any;
    handleBlur?: any;
    setActiveStep: any;
    activeStep: number;
}

const ContentStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`;

const AccidentDetail: React.FC<AccidentDetailProps> = ({ handleChange, handleBlur, activeStep, setActiveStep }) => {
    const matches = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();
    const [date, dateMeta] = useField("date");
    const [description, descriptionMeta] = useField("description");

    let storage: IStorageMembershipData = {
        ...useSelector((state: any) => state.productsCards.currentValue),
    };

    const userResponse = useSelector((state: any) => state.user.response);

    React.useEffect(() => {
        window.dataLayer.push({
            'event': 'TRUS_reparacion_direccion_v',
            tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium",//premium | gratuito 
            'correo': userResponse?.data?.user?.email,
            'telefono': userResponse?.data?.user?.phone,
            'page_location': window.location.href,
        });
    }, []);

    const currentDate = new Date().toJSON().slice(0, 10)

    const items = [{
        id: 1,
        label: 'Fecha del accidente',
        autofocus: true,
        name: 'date',
        as: 'date',
        type: 'date',
        error: date,
        meta: dateMeta,
        limit: {
            MaxAgeAcept: currentDate,
            MinAgeAcept: '2000-01-01',
        }
    },
    {
        id: 2,
        label: 'Descripción del accidente*',
        autofocus: true,
        name: 'description',
        as: 'text_area',
        type: 'text_area',
        error: description,
        meta: descriptionMeta
    }
    ]

    return (
        <React.Fragment>
            <ContentStyled>
                <FormItemGenerate
                    items={items}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                />

            </ContentStyled>
            {/* <PrimaryButton type='submit' text={"Continuar"} onClick={() => {
                setActiveStep(activeStep + 1);

                window.dataLayer.push({
                    event: "TRUS_reparacion_image",
                    description: description.value,
                    boton_contacto: 'Continuar',
                    tipo_seguro: userResponse?.data ? userResponse?.data?.user?.benefit_id === "C01" ? "De regalo" : "Premium" : storage.type == "free" ? "De regalo" : "Premium",//premium | gratuito 
                    correo: userResponse?.data?.user?.email,
                    telefono: userResponse?.data?.user?.phone,
                });
            }} /> */}
        </React.Fragment>
    )
};

export default AccidentDetail;