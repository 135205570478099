import React, { useEffect, useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Typography, Box } from "@mui/material";
import { useSelector } from 'react-redux';
import { StatusReparacion, StatusUassits } from '../CustomerPortalContent/components/benefitEngine/benefit.values';
import ButtonInter from '../../atoms/Buttons/ButtonInter';
import { useNavigate } from 'react-router-dom';

type StatusReparacionType = keyof typeof StatusReparacion;
type StatusUassitsType = keyof typeof StatusUassits
interface ITimeLine {
    id: number;
    isActive: boolean;
    status: string[];
    info: string;
}
export const timeLineProccess: ITimeLine[] = [
    {
        id: 1,
        isActive: true,
        status: [StatusUassits.esperandoEquipo],
        info: "Hemos enviado la guía de paquetería para tu solicitud"
    },
    {
        id: 2,
        isActive: false,
        status: [StatusUassits.enCamino],
        info: "Tu equipo va en camino a los laboratorios para su revisión"
    },
    {
        id: 3,
        isActive: false,
        status: [StatusUassits.enReparacion, StatusUassits.enDiagnostico],
        info: "Tu equipo ya esta en el laboratorio y estamos trabajando en su reparación."
    },
    {
        id: 4,
        isActive: false,
        status: [StatusUassits.enviado],
        info: "Ya esta lista la reparación de tu celular y lo hemos enviado a tu domicilio."
    },
    {
        id: 5,
        isActive: false,
        status: [StatusUassits.entregado],
        info: "Ayúdanos a confirmar la recepción de tu dispositivo."
    },
]

export const TimeLineTrackingStatus: React.FC = () => {
    const statusReparacion = useSelector((state: any) => state.status.response);
    const [status, setStatus] = useState<string>("none");
    const [timeLine, setTimeLine] = useState<ITimeLine[]>(timeLineProccess);
    const navigate = useNavigate();
    const updateTimeLine = (current: string) => {
        const currentStepIndex = timeLineProccess.findIndex(step => {
            const find = step.status.includes(current);
            return find
        });
        const newTimeLine = timeLineProccess.map(e => {
            e.isActive = false
            return e
        });
        if (currentStepIndex !== -1) {
            for (let i = 0; i <= currentStepIndex; i++) {
                newTimeLine[i].isActive = true;
            }
        }
        setTimeLine(newTimeLine);
    }

    const checkFinish = () => {
        const endStep = timeLine.find(e => e.status.includes(StatusUassits.entregado) && e.isActive === true);
        return endStep ? true : false;
    }

    const checkCurrentStatus = () => {
        if (statusReparacion) {
            const homoEstatus = statusReparacion?.data?.estatus?.toLocaleLowerCase()
            const current = homoEstatus as StatusUassitsType || 'none';
            //const current = StatusUassits.entregado;
            setStatus(current);
        }
    }

    useEffect(() => {
        updateTimeLine(status);
    }, [status]);

    useEffect(() => {
        checkCurrentStatus();
    }, [statusReparacion]);
    return (
        <React.Fragment>
            <Timeline>
                {
                    timeLine.map(({ isActive, info, status }, index) => {

                        return <TimelineItem key={index} sx={{
                            "&::before": {
                                flex: 0,
                                padding: 0
                            }
                        }}>
                            <TimelineSeparator sx={{ marginTop: index === 0 ? 3 : 0 }}>
                                {
                                    index !== 0 &&
                                    <TimelineConnector />
                                }
                                <TimelineDot style={{ margin: '0 0 0 0' }} color={isActive ? "success" : "grey"} />
                                {
                                    timeLineProccess.length - 1 !== index &&
                                    <TimelineConnector />
                                }
                                {
                                    timeLineProccess.length - 1 === index &&
                                    <TimelineConnector sx={{ backgroundColor: 'transparent' }} />
                                }
                            </TimelineSeparator>
                            <TimelineContent sx={{
                                opacity: isActive ? 1 : 0.3,
                                marginTop: index !== 0 ? 1.5 : 0
                            }}>{info}</TimelineContent>
                        </TimelineItem>
                    })
                }

            </Timeline>
            {
                checkFinish() && <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px'
                }}>
                    <ButtonInter variant='contained' onClick={() => {
                        navigate('/portal-del-cliente')
                    }}>
                        Ya lo recibí
                    </ButtonInter>
                    <ButtonInter variant='outlined' onClick={() => {
                        window.open('https://intermx-digital.atlassian.net/servicedesk/customer/portal/8/group/34/create/91')
                    }}>
                        No ha llegado
                    </ButtonInter>
                </Box>
            }

        </React.Fragment>
    );
};

// const timeLineProccessBasic = [
//     {
//         isActive: true,
//         info: "Respalda tu celular, pues borraremos su contenido para tu seguridad."
//     },
//     {
//         isActive: true,
//         info:  "Una vez que recibas la guía de paquetería, elige si prefieres que pasen por tu equipo o tu lo llevas al centro de recolección más cercano."
//     },
//     {
//         isActive: true,
//         info: "Cuando recibamos tu dispositivo en nuestros laboratorios y se haga la reparación, te lo regresaremos en un lapso máximo de 96 horas."
//     },
//     {
//         isActive: true,
//         info: "En tu portal de cliente podrás rastrear el avance de tu reparación para que estes seguro seguro de que estamos trabajando en ella"
//     }
//
// ]
const DESCRIPTION_1 = (description: any) => {

    return <div>
        <Typography
            fontSize={20}
            dangerouslySetInnerHTML={{ __html: description }}
        />
    </div>
}
const timeLineProccessBasic = [
    {
        isActive: true,
        info: DESCRIPTION_1(`<b>Respalda tu celular</b>, esto lo pedimos para proteger tus datos personales, nuestros técnicos jamás harán uso de ellos, pero al repararlo es probable que tus datos sean borrados por seguridad.`)
    },
    {
        isActive: true,
        info: DESCRIPTION_1(`Una vez que recibas la guía de paquetería, <b>elige si prefieres que pasen por tu equipo o tu lo llevas al centro de recolección</b> más cercano.`)
    },
    {
        isActive: true,
        info: DESCRIPTION_1(`Cuando recibamos tu dispositivo en nuestros laboratorios iniciaremos el análisis y repración para,<b> regresártelo en un lapso de 96 horas</b>.`)
    },
    {
        isActive: true,
        info: DESCRIPTION_1(`<b>En tu portal de cliente podrás rastrear el avance de tu reparación</b> para que estes seguro seguro de que estamos trabajando en ella.`)
    }

]

export const TimeLineBasic: React.FC = () => {

    return (
        <React.Fragment>
            <Timeline>
                {
                    timeLineProccessBasic.map(({ isActive, info }) => {
                        return <TimelineItem sx={{
                            "&::before": {
                                flex: 0,
                                padding: 0
                            },
                            marginBottom: '1rem'
                        }}>
                            <TimelineSeparator>
                                <TimelineDot style={{ margin: '14.5px 0 0 0px', padding: 0, backgroundColor: '#000' }} color={!isActive ? "success" : "grey"} />
                            </TimelineSeparator>
                            <TimelineContent style={{
                                opacity: isActive ? 1 : 0.3,
                                fontSize: 20,
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: 'normal',
                                letterSpacing: '0.5px'
                            }}>{info}</TimelineContent>
                        </TimelineItem>
                    })
                }
            </Timeline>
        </React.Fragment>
    );
};
