import React from 'react';
import RemovedAccount from '../RemovedAccounts/RemovedMain';
import CustomContainer from '../../components/organisms/Container/Container';
import { Grid } from "@mui/material";


const RemovedIndex: React.FC = () => {
    return (
        <React.Fragment>
            <CustomContainer childComponent={
                <Grid 
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{ maxWidth: 558, margin: 'auto' }}
                >
                    <RemovedAccount />
                </Grid>
            } />
        </React.Fragment>
    );
};

export default RemovedIndex;