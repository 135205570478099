export const SOCIAL_MEDIA = {
    facebook: {
        url: 'https://www.facebook.com'
    },
    twitter: {
        url: 'https://twitter.com'
    },
    telegram: {
        url: ''
    },
    whatsapp: {
        url: ''
    },
    linkedin: {
        url: ''
    }
}

type SocialMediaKeys = keyof typeof SOCIAL_MEDIA;
interface ISocialConfig {
    url: string
};

export type SocialMediaElements = {
    [key in SocialMediaKeys]?: ISocialConfig
};




