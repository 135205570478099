import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { styled } from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ArrowBack } from '@mui/icons-material';
import { useMediaQuery } from '@material-ui/core';
import FormContainer from '../../../components/organisms/Form/FormContainer';
import { CustomButton } from '../../../components/atoms/Buttons/Buttons';
import DatosDeBeneficiario from '../../../components/organisms/Form/FormContent/DatosDeBeneficiario';
import RenderAlert from '../../../components/organisms/Modal';
import CustomContainer from '../../../components/organisms/Container/Container';
import { fieldIsRequired } from '../../../utils/scrollToTop';
import useFetch from '../../../hooks/useFetch';
import {
  apiError as apiBeneficiaryError,
  apiLoading as apiBeneficiaryLoading,
  apiSuccess as apiBeneficiarySuccess
} from '../../../api/multipleBeneficiarySlice';
import { validateEmail } from '../../../utils/utils';

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 80px;
  gap: 24px;
  align-items: flex-start;
  max-width: 558px;
`;

type Body = {
  benefy_index?: number,
  name: string,
  middle_name: string,
  last_name: string,
  birthdate: string,
  relationship: string,
  email: string,
  phone: number,
  porcentage: number
}

const Beneficiary: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeStep, setActiveStep] = useState<number>(0);
  const multipleBeneficiaries = useSelector((state: any) => state.multipleBeneficiaries) as any | null;
  const beneficiaries = useSelector((state: any) => state.user.response?.data?.beneficiary) as any | null;
  const { fetchData } = useFetch();
  const { response, error } = multipleBeneficiaries

  // const matches = useMediaQuery('(min-width:600px)');

  const membershipTypeModule = {
    datosdeBeneficiarios: {
      formSteps: 0, //starting from 0
      steps: [], // steps title
      titles: [`${location.state?.to ? 'Editar Beneficiario' : 'Tu seguro incluye una cobertura por muerte accidental, ¿Nos ayudas con los datos de tu beneficiario?'}`], // content title
      initialValues: {
        name: '',
        middle_name: '',
        last_name: '',
        email: '',
        phone: '',
        birthdate: '',
        relationship: '',
        porcentage: ''
      }
    }
  }

  const renderStepContent = (step: number, errors: any, handleChange: any, handleBlur: any) => {
    switch (step) {
      case 0:
        return (<DatosDeBeneficiario
          multipleBeneficiaries
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          porcentageLimit={100 - Number(location.state?.porcentage)}
          countBeneficiaries={location.state?.beneficiaries}
        />);
      default:
        return null;
    }
  };

  const _submitForm: any = async (values: any, actions: any) => {
    actions.setSubmitting(true);
    const content: Body = {
      "name": values.name.trim(),
      "middle_name": values.middle_name.trim(),
      "last_name": values.last_name.trim(),
      "birthdate": values.birthdate,
      "relationship": values.relationship,
      "email": values.email.trim(),
      "phone": Number(values.phone),
      "porcentage": values.porcentage
    }

    const event = location?.state?.to ? 'update-benefi' : 'save-benefi';

    if (location?.state?.to) {
      content.benefy_index = location.state.to - 1
    }

    fetchData('users/auth', 'POST', {
      event,
      content: content
    }, {}, apiBeneficiaryLoading, apiBeneficiarySuccess, apiBeneficiaryError);
  }

  useEffect(() => {
    if (!!error) {
      navigate('/muerte-accidental', { state: { showModal: true } })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    if (response?.ok) {
      navigate('/muerte-accidental', { state: { showModal: true } })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const _handleSubmit = async (values: any, actions: any) => {
    _submitForm(values, actions);
  }

  const validationSchemaStep = Yup.object().shape({
    name: Yup.string().required(fieldIsRequired),
    middle_name: Yup.string().required(fieldIsRequired),
    last_name: Yup.string().required(fieldIsRequired),
    birthdate: Yup.date()
      .required(fieldIsRequired),
    phone: Yup.string()
      .phone("MX", "Por favor, ingresa un número de teléfono válido en México")
      .max(10, "maximo 10 caracteres")
      .required(fieldIsRequired),
    email: Yup.string()
      .required(fieldIsRequired)
      .test('email', 'Este correo no es valido',
        function (value) {
          return validateEmail(value);
        }),
    relationship: Yup.string().required(fieldIsRequired),
    porcentage: Yup.number().max(100 - Number(location.state?.porcentage), `tienes un limite de ${100 - location.state?.porcentage}% para asignar`).required(fieldIsRequired)
  })

  const validatorSchemaStepper = () => {
    return validationSchemaStep;
  }

  const handleBack = () => {
    if (beneficiaries?.Beneficiarys?.length <= 0) {
      navigate("/portal-del-cliente")
    } else if (activeStep === 0) {
      navigate('/muerte-accidental')
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
        <CustomContainer
          childComponent={
            <FormWrapper>
              <CustomButton
                text={"Regresar"}
                onClick={handleBack}
                size={"medium"}
                startIcon={<ArrowBack />}
                variant={"contained"}
                style={{
                  color: "#039ECC",
                  backgroundColor: "transparent",
                  fontSize: "1rem",
                  textTransform: "none",
                  fontWeight: "600",
                  fontStyle: "normal",
                  padding: "0px",
                  minWidth: "64px",
                  border: "none",
                  justifyContent: "flex-start",
                  width: "fit-content",
                }}
              />

              <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 40
              }}>
                <FormContainer
                  steps={membershipTypeModule.datosdeBeneficiarios.steps}
                  initialValues={membershipTypeModule.datosdeBeneficiarios.initialValues}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  title={membershipTypeModule.datosdeBeneficiarios.titles}
                  renderStepContent={renderStepContent}
                  _handleSubmit={_handleSubmit}
                  validatorSchemaStepper={validatorSchemaStepper}
                  formObserverSlice={apiBeneficiarySuccess}
                  localData={response}
                />
              </div>
            </FormWrapper>
          }
        />
      </div>
    </>
  );
};

export default Beneficiary;
