import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { TextField } from "@mui/material";
import validation from "../../../../assets/RegisterAssets/signaturegif.gif";
import validationFinsus from "../../../../assets/RegisterAssets/sendfinsusOrange.gif";
import errorGift from "../../../../assets/RegisterAssets/errorgif.gif";
import { CustomTypography } from "../../../atoms/Label/Label";
import DecrementTimer from "../../../atoms/DecrementTimer";
import { useSelector, useDispatch } from "react-redux";
import {
  apiLoading,
  apiSuccess,
  apiError,
  clearSuccess
} from '../../../../api/validateCodeSlice';
import useFetch from "../../../../hooks/useFetch";
import { useFormikContext } from "formik";
import { CustomButton } from "../../../atoms/Buttons/Buttons";
import { apiError as apiLeadsError } from "../../../../api/leadsSlice";
import { IStorageMembershipData } from "../../../../utils/localStorageKeys";
import { myColorPalette } from "../../../../theme/themeGenerated";

const LayOutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
`;

const OTPElement = styled(TextField)`
  display: flex;
  max-width: 48px;
  height: 60px;
  padding: 14px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--black-25, #c7c7c7);
  justify-content: center;
`;

type ValidacionNumeroCelularProps = {
  handleChange: () => void;
  trackingFromAlliance?: string;
};
type CodeNumberProps = { event: string };

const ValidacionNumeroCelular: React.FC<ValidacionNumeroCelularProps> = ({trackingFromAlliance = ""}) => {
  const { fetchData } = useFetch();
  const [num, setNum] = React.useState(["", "", "", "", "", ""]);
  const [error, setError] = useState(false);
  const errorLeads = useSelector((state: any) => state.leads.error);
  const tracking = useSelector((state: any) => state.tracking.response) as any | null;
  const code = useSelector((state: any) => state.validateCode.response) as any | null;
  const formik = useFormikContext();
  const dispatch = useDispatch();
  const [showCode, setShowCode] = useState(false)
  const [tmpCode, setTmpCode] = useState("")

  const _sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const sendCodeNumber = ({ event }: CodeNumberProps) => {
    return fetchData('users/otp', 'POST', {

      "event": event,
      "content": { "tracking_id": trackingFromAlliance || tracking?.data?.tracking_id }

    }, {}, apiLoading, apiSuccess, apiError);
  }

  useEffect(() => {
    _sleep(1500).then(() => {
      sendCodeNumber({ event: "obtain-code" });
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (code?.ok) {
      setShowCode(true);
      setTmpCode(code?.data?.otp);
      dispatch(clearSuccess());
      formik.setFieldValue("otp", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const recibirOTP = (otp: any) => {
    // Dividimos el OTP en dígitos individuales
    // const digitosOTP = otp.split('');
    // Llenamos el arreglo num con los dígitos del OTP
    setNum(otp);
    formik.setFieldValue("otp", otp.join(""))
  };

  const nextFocus = (inputS: string) => {
    const nextInput = document.getElementById(inputS);
    if (nextInput) nextInput?.focus();
  }

  useEffect(() => {
    if(errorLeads){
      setError(true)
    }
  }, [ errorLeads ]);

  let storage: IStorageMembershipData = {
    ...useSelector((state: any) => state.productsCards.currentValue),
  };

  const userData = JSON.parse(sessionStorage.getItem('user-storage')!);

  const userResponse = useSelector((state: any) => state.user.response);

  React.useEffect(() => {
    if(error){
      window.dataLayer.push({ 
        'event': 'TRUS_registro_validacion_error_v',
        tipo_seguro: storage.type == "free" ? "De regalo" : "Premium", 
        'correo': userData?.correo, 
        'telefono': userData?.telefono,
        'page_location': window.location.href,
        seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
      });     
    }
  }, [error])

  return (
    <LayOutContainer>
      <img width={150} src={error ? errorGift : window.location.pathname.includes('finsus') ? validationFinsus : validation} alt="validation" />
      <CustomTypography
        item={
          error
            ? "No hemos podido validar tu número, por favor revisa que tus datos sean correctos"
            : "Ingresa el código que te enviaremos por sms y correo electrónico para validar tu número"
        }
        style={{
          fontSize: 18,
          fontStyle: "normal",
          textAlign: "center",
          fontWeight: 400,
          maxWidth: 328,
          lineHeight: "normal",
        }}
      />
      {error && <div>
        <CustomButton size='small' style={{ color: myColorPalette.getPrimaryColor(), fontSize: 14, fontWeight: '400', letterSpacing: '0.5px', textTransform: 'none' }} color="primary" onClick={() => {
            if (error){
              window.dataLayer.push({
                event: 'TRUS_registro_validacion_error',
                boton_contacto: 'Volver a intentarlo',
                correo: userData?.correo,
                telefono: userData?.telefono,
                tipo_seguro: storage.type == "free" ? "De regalo" : "Premium",
                seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
              })
            }
            dispatch(apiLeadsError(false));
            setError(false);
            formik.setFieldValue("otp", "");
            // for (let i = 1; i <= 6; i++) {
            //   const input: any = document.getElementById('OTP-' + i);
            //   if (input) {
            //     input.value = ''; 
            //   }
            // }
            setNum(["","", "", "", "", ""]);
          }} text='Volver a intentarlo'></CustomButton> 
        </div>}
      {!error && (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              gap: 16,
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 8,
                flexGrow: 1,
                maxWidth: 360,
              }}
            >
              {num.map((digito, index) => (
                <OTPElement
                  required
                  id={`OTP-${index}`}
                  key={index}
                  type="text"
                  value={digito}
                  onChange={(e: any) => {
                    const regex = /^[0-9\b]*$/;
                    if (regex.test(e.target.value)) {
                      const nuevosNumeros = [...num];
                      nuevosNumeros[index] = e.target.value;
                      recibirOTP(nuevosNumeros);
                      if (e.target.value) {
                        nextFocus(`OTP-${index + 1}`)
                      }
                    }
                  }}
                  onKeyDown={(e: any) => {
                    const input = e.target;
                    if (e.key === "Backspace" && input.value === "") {
                      nextFocus(`OTP-${index - 1}`)
                    }
                  }}
                  inputProps={{
                    maxLength: 1,
                    style: { textAlign: "center", fontWeight: "700" },
                  }}
                />
              ))}
            </div>
            <DecrementTimer initialTime={120} sendCodeNumber={sendCodeNumber} setNum={setNum} />
          </div>
        </React.Fragment>
      )}
    </LayOutContainer>
  );
};

export default ValidacionNumeroCelular;
