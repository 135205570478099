import { Button, Modal, Box, Typography } from '@mui/material';
import guy from './assets/guy.png';
import stadistic from './assets/stadistics.png';
import './ReferredComponent.css';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import React, { useState } from 'react';
import {
    FacebookIcon,
    FacebookShareButton,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    TwitterShareButton,
    TwitterIcon,
    TelegramShareButton,
    TelegramIcon,
    WhatsappShareButton,
    WhatsappIcon,
    LinkedinShareButton,
    LinkedinIcon
} from 'react-share'
import { SOCIAL_MEDIA } from './ReffererMapSocialMedia';
import { IStorageMembershipData } from '../../../utils/localStorageKeys';

const ReferredComponent: React.FC = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        p: 2
    };

    const userData = JSON.parse(sessionStorage.getItem('user-storage')!);
    let storage: IStorageMembershipData = {
        ...useSelector((state: any) => state.productsCards.currentValue),
      };

      React.useEffect(() => {
        window.dataLayer.push({ 
            'event': 'TRUS_referidos_v',
            tipo_seguro: storage.type == "free" ? "De regalo" : "Premium",
            'correo': userData?.correo, 
            'telefono': userData?.telefono,
            'page_location': window.location.href,
        });           
    },[])

    return (<>
        <div className="conteinerReferred" style={{ marginTop: '40px' }}>
            <div className="title">
                <p style={{ fontSize: '28px' }}>
                    <strong>
                        Estamos personalizando tu portal de cliente, en un momento te redirigiremos al sitio
                    </strong>
                </p>
            </div>
            <div className="imageBanner">
                <div className="guy">
                    <img src={guy} alt="" width={250} />
                </div>
                <div className="stadistics">
                    <img src={stadistic} alt="" width={250} />
                </div>
            </div>
            <div className="notice">
                <p>
                    Mientras tanto, ¿Te gustaría invitar a alguien más para que pueda formar parte de <strong>#TeRegalamosUnSeguro</strong>?
                </p>
                <div className="buttonArea">

                    <Button className='primary' onClick={() => {
                        handleOpen();
                        window.dataLayer.push({
                            event: 'TRUS_referidos',
                            boton_contacto: 'Compartir',
                            correo: userData?.correo,
                            telefono: userData?.telefono,
                        })
                    }}>
                        COMPARTIR
                    </Button>
                    <br />
                    <Button
                        onClick={() => {
                            window.dataLayer.push({
                                event: 'TRUS_referidos',
                                boton_contacto: 'Tal vez despues',
                                correo: userData?.correo,
                                telefono: userData?.telefono,
                            })
                            navigate('/portal-del-cliente')
                        }}
                    >
                        TALVEZ DESPUÉS
                    </Button>
                </div>
                <div className="">
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <h3 style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>Compartir en:</h3>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'row',
                                gap: '14px',
                                width: '100%'
                            }}>
                                <FacebookShareButton
                                    url={SOCIAL_MEDIA.facebook.url}
                                    quote={"Compartir por facebook"}>
                                    <FacebookIcon size={32} round />
                                </FacebookShareButton>
                                <FacebookMessengerShareButton
                                    url={'https://inter.mx'}
                                    appId={""}
                                >
                                    <FacebookMessengerIcon size={32} round />
                                </FacebookMessengerShareButton>
                                <TwitterShareButton
                                    url={"https://inter.mx"}
                                >
                                    <TwitterIcon size={32} round />
                                </TwitterShareButton>
                                <TelegramShareButton
                                    url={"https://inter.mx"}
                                >
                                    <TelegramIcon size={32} round />
                                </TelegramShareButton>
                                <WhatsappShareButton
                                    url={"https://inter.mx"}
                                >
                                    <WhatsappIcon size={32} round />
                                </WhatsappShareButton>
                                <LinkedinShareButton
                                    url={"https://inter.mx"}
                                >
                                    <LinkedinIcon size={32} round />
                                </LinkedinShareButton>
                            </div>


                        </Box>
                    </Modal>
                </div>
            </div>
        </div>

    </>)
}

export default ReferredComponent