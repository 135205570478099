import React from 'react';
import AllianceFAQsTemplate from '../../components/templates/AllianceFAQs';
import CustomContainer from '../../components/organisms/Container/Container';

const AllianceFAQs: React.FC = () => {
  // Implementa el componente aquí
  return (
    <React.Fragment>
        <CustomContainer childComponent={ <AllianceFAQsTemplate />} />
    </React.Fragment>

  );
};

export default AllianceFAQs;
