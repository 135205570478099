import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove';
import { styled } from "styled-components";

import QuestionsPlus from '../../../../assets/Questions/QuestionsPlus';
import Table from './Table';
import Conditions from './Conditions';
import Exclusions from './Exclusions';
import Medications from './Medications';
import CheckUp from './CheckUp';
import Chubb from './Chubb';


interface IPAccordionContent {
    data: any;
    expanded: string | boolean;
    handleChange: any;
    hideTitle?: boolean
}

export default function AccordionContent({ data, expanded, handleChange, hideTitle = false }: IPAccordionContent) {

    const AcordionCustom: React.CSSProperties = {
        flex: '1 0 0'
    }

    // const BlackTextButton = styled.div`
    //     color: #212121;
    //     font-family: 'Montserrat';
    //     font-size: '16px';
    //     font-style: normal;
    //     font-weight: 700;
    //     line-height: normal;
    // `;

    const BlackText = styled.span`
        color: #212121;
        font-family: 'Montserrat';
        font-size: '16px';
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
        margin-right: 5px;
    `;

    // const TweleMount = styled.div`
    //     color: var(--Black, #212121);
    //     font-feature-settings: 'clig' off, 'liga' off;
    //     font-family: Montserrat;
    //     font-size: 16px;
    //     font-style: normal;
    //     font-weight: 400;
    //     line-height: normal;
    // `;

    //     const Exclusions = styled.div`
    //         color: var(--Black, #212121);
    //         font-feature-settings: 'clig' off, 'liga' off;
    //         font-family: Montserrat;
    //         font-size: 16px;
    //         font-style: normal;
    //         font-weight: 700;
    //         line-height: normal;
    //    `;

    //     const Paragraph = styled.div`
    //         display: flex;
    //         margin-top: 16px;
    //         gap: 8px;
    //    `

    const Paragraph = styled.div`
       margin-top: 16px;
    `

    return (
        <Accordion style={AcordionCustom} expanded={expanded === data?.title} onChange={handleChange(data?.title)} >
            {!hideTitle && <AccordionSummary expandIcon={expanded === data?.title ? < RemoveIcon /> : < QuestionsPlus />}>
                <Typography
                    style={{
                        color: 'var(--black-75, #595959)',
                        fontFamily: 'Montserrat',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: 'normal',
                    }}

                >{data?.title}</Typography>
            </AccordionSummary>}

            <AccordionDetails>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        background: 'blue'
                    }}
                >
                    <Table concepts={data?.table} />
                </div>

                <Paragraph>
                    <BlackText>
                        Vigencia del programa:
                    </BlackText>
                    {data?.duration}
                </Paragraph>

                <Paragraph>
                    <BlackText>
                        Detalle de la cobertura de seguro
                    </BlackText>
                </Paragraph>

                {data?.coverage_details.map((detail: any) => {
                    return (
                        <>
                            <Paragraph>
                                <BlackText>
                                    {detail?.title}
                                </BlackText>
                            </Paragraph>

                            {detail?.description}

                            {detail?.amount &&
                                <Paragraph>
                                    <BlackText>
                                        Suma Asegurada:
                                    </BlackText>
                                    {detail?.amount}
                                </Paragraph>
                            }

                            {detail?.age &&
                                <Paragraph>
                                    <BlackText>
                                        Edad de aceptación:
                                    </BlackText>
                                    {detail?.age}
                                </Paragraph>
                            }

                            <Conditions onlyInsured={detail?.onlyInsured} />

                        </>)
                })}

                <Exclusions />

                <Paragraph>
                    <BlackText>
                        Detalle de las Asistencias
                    </BlackText>
                </Paragraph>

                {data?.assistances.map((assistance: any) => {
                    return (<>
                        <Paragraph>
                            <BlackText>
                                {assistance?.title}
                            </BlackText>
                        </Paragraph>

                        {assistance?.description}

                        {assistance?.limit &&
                            <Paragraph>
                                <BlackText>
                                    Límite de eventos:
                                </BlackText>
                                {assistance?.limit}
                            </Paragraph>
                        }
                        {assistance?.exclusions &&
                            <>
                                <Paragraph>
                                    <BlackText>
                                        Exclusiones:
                                    </BlackText>
                                    {assistance?.exclusions}
                                </Paragraph>
                            </>
                        }

                        <Paragraph>Aplica únicamente para el asegurado</Paragraph>
                    </>)
                })}

                {data?.medications && <Medications />}

                {data?.checkup && <CheckUp />}

                {data?.chubb && <Chubb />}

            </AccordionDetails>
        </Accordion>
    )
}
