import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="121"
            height="55"
            fill="none"
            viewBox="0 0 121 55"
        >
            <g fill="#FF5F54">
                <g>
                    <path d="M43.173 26.57c-1.216 0-2.344-.198-3.391-.6a8.014 8.014 0 01-2.717-1.706 7.92 7.92 0 01-1.792-2.6c-.43-.992-.64-2.084-.64-3.276 0-1.191.215-2.284.64-3.277a7.866 7.866 0 011.803-2.599 8.098 8.098 0 012.723-1.705 9.456 9.456 0 013.392-.602c1.367 0 2.606.24 3.717.713a7.206 7.206 0 012.792 2.09l-2.361 2.174a5.304 5.304 0 00-1.792-1.402 5.025 5.025 0 00-2.176-.473c-.739 0-1.414.122-2.025.373a4.89 4.89 0 00-1.6 1.052c-.447.45-.796.987-1.047 1.606-.25.62-.372 1.302-.372 2.056 0 .753.122 1.443.373 2.056.25.619.593 1.15 1.047 1.606.453.456.983.8 1.6 1.051a5.317 5.317 0 002.024.374 4.97 4.97 0 002.176-.485c.669-.32 1.262-.794 1.792-1.413l2.361 2.173a7.233 7.233 0 01-2.792 2.102c-1.111.485-2.356.724-3.74.724l.005-.011z"></path>
                    <path d="M57.476 26.477c-1.38 0-2.59-.269-3.625-.812-1.035-.543-1.838-1.285-2.408-2.225-.57-.94-.855-2.015-.855-3.225 0-1.209.273-2.283.82-3.235a5.746 5.746 0 012.298-2.226c.983-.537 2.088-.8 3.322-.8 1.233 0 2.257.257 3.21.771a5.79 5.79 0 012.264 2.173c.558.934.832 2.056.832 3.37 0 .134-.006.292-.023.462-.018.175-.03.332-.047.484h-9.843v-2.056h7.993l-1.373.614c.018-.631-.105-1.186-.36-1.653a2.781 2.781 0 00-1.06-1.098c-.447-.263-.977-.398-1.576-.398-.6 0-1.129.135-1.588.398-.46.262-.815.63-1.07 1.11-.257.473-.385 1.04-.385 1.681v.544c0 .677.152 1.267.448 1.764.303.496.716.876 1.24 1.144.523.263 1.157.398 1.89.398.629 0 1.193-.1 1.676-.293.488-.192.936-.49 1.338-.881l1.867 2.032a5.516 5.516 0 01-2.094 1.46c-.838.339-1.81.508-2.903.508l.012-.011z"></path>
                    <path d="M65.668 26.297V9.522h3.514v16.775h-3.514z"></path>
                    <path d="M77.56 26.478c-1.024 0-1.92-.193-2.705-.59-.78-.391-1.39-.993-1.821-1.81-.436-.812-.652-1.84-.652-3.072V14.13h3.514v6.331c0 1.028.215 1.776.64 2.249.43.473 1.03.713 1.815.713.541 0 1.024-.123 1.443-.363.419-.239.756-.601 1-1.086.25-.479.373-1.086.373-1.81v-6.04h3.49v12.166h-3.333v-3.37l.605.993c-.419.847-1.018 1.484-1.803 1.91-.78.432-1.635.643-2.566.643v.011z"></path>
                    <path d="M87.92 26.297V9.522h3.513v16.775H87.92z"></path>
                    <path d="M98.431 26.477c-.93 0-1.733-.164-2.408-.485-.675-.321-1.198-.765-1.565-1.32a3.342 3.342 0 01-.553-1.875c0-.689.175-1.337.53-1.886.355-.55.913-.976 1.675-1.28.768-.303 1.775-.449 3.02-.449h3.199v2.033H99.49c-.826 0-1.39.134-1.698.408-.309.275-.46.614-.46 1.017 0 .45.174.811.53 1.074.354.263.831.398 1.43.398.6 0 1.135-.135 1.612-.41.471-.268.809-.683 1-1.243l.542 1.652a3.208 3.208 0 01-1.397 1.753c-.675.414-1.547.619-2.612.619l-.006-.006zm3.695-.181v-2.371l-.227-.52v-4.252c0-.754-.227-1.344-.687-1.764-.459-.42-1.157-.63-2.106-.63-.645 0-1.28.098-1.902.303a5.446 5.446 0 00-1.611.823l-1.24-2.464c.663-.485 1.455-.847 2.386-1.098a10.83 10.83 0 012.838-.374c1.845 0 3.282.438 4.3 1.314 1.018.876 1.53 2.237 1.53 4.094v6.939h-3.287.006z"></path>
                    <path d="M108.593 26.296V14.13h3.357v3.457l-.471-1.016c.361-.858.937-1.513 1.734-1.956.797-.444 1.762-.666 2.902-.666v3.276a5.039 5.039 0 00-.395-.07 2.697 2.697 0 00-.372-.023c-.989 0-1.781.28-2.362.835-.582.555-.879 1.42-.879 2.576v5.741h-3.514v.012z"></path>
                    <path d="M35.626 46.395V30.567h6.824c1.407 0 2.63.228 3.647.69 1.018.46 1.81 1.12 2.362 1.98.553.858.832 1.874.832 3.054 0 1.18-.28 2.214-.832 3.06-.553.847-1.344 1.513-2.362 1.98-1.024.468-2.24.701-3.647.701h-4.817l1.664-1.652v6.015h-3.671zm3.67-5.607l-1.663-1.74h4.619c1.111 0 1.949-.24 2.525-.724.57-.485.855-1.163.855-2.033 0-.87-.285-1.548-.855-2.027-.57-.473-1.414-.712-2.525-.712h-4.62l1.665-1.74v8.976z"></path>
                    <path d="M51.635 46.395V34.227h3.357v3.458l-.471-1.016c.36-.859.936-1.513 1.733-1.957.797-.444 1.763-.666 2.903-.666v3.277a5.121 5.121 0 00-.395-.07 2.715 2.715 0 00-.373-.023c-.989 0-1.78.28-2.361.835-.582.555-.879 1.419-.879 2.575v5.742h-3.514v.011z"></path>
                    <path d="M66.887 46.576c-1.274 0-2.415-.27-3.415-.812a6.086 6.086 0 01-2.35-2.226c-.57-.94-.856-2.015-.856-3.224s.285-2.3.855-3.247a6.012 6.012 0 012.35-2.214c.996-.531 2.136-.8 3.416-.8 1.28 0 2.437.269 3.444.8a5.966 5.966 0 012.362 2.214c.57.94.855 2.027.855 3.247 0 1.221-.285 2.302-.855 3.236-.57.935-1.362 1.67-2.362 2.214-1.007.543-2.153.812-3.444.812zm0-2.892c.599 0 1.128-.134 1.588-.397.46-.262.826-.654 1.105-1.162.28-.508.42-1.115.42-1.81 0-.696-.14-1.315-.42-1.811a2.928 2.928 0 00-1.105-1.14 3.088 3.088 0 00-1.565-.396c-.588 0-1.111.134-1.577.397a2.915 2.915 0 00-1.117 1.139c-.279.496-.418 1.098-.418 1.81 0 .713.14 1.297.418 1.811.28.514.652.9 1.117 1.162.466.263.984.398 1.554.398z"></path>
                    <path d="M74.448 37.212v-2.716h8.4v2.716h-8.4zm6.393 9.362c-1.425 0-2.536-.362-3.333-1.098-.797-.736-1.193-1.822-1.193-3.265v-10.67h3.537v10.6c0 .514.134.912.396 1.198.261.286.616.432 1.07.432.57 0 1.053-.152 1.443-.45l.925 2.488c-.343.257-.768.45-1.274.579a6.312 6.312 0 01-1.565.192l-.006-.006z"></path>
                    <path d="M91.32 46.576c-1.379 0-2.589-.27-3.624-.812-1.036-.543-1.839-1.285-2.409-2.226-.57-.94-.855-2.015-.855-3.224s.274-2.283.82-3.235a5.746 5.746 0 012.298-2.226c.984-.537 2.089-.8 3.322-.8s2.257.257 3.211.771a5.79 5.79 0 012.263 2.173c.559.934.832 2.056.832 3.37 0 .134-.005.292-.023.461-.017.176-.029.333-.046.485h-9.844v-2.056h7.994l-1.373.614c.017-.631-.105-1.186-.361-1.653a2.78 2.78 0 00-1.059-1.098c-.448-.263-.977-.398-1.576-.398-.6 0-1.129.135-1.589.398-.46.262-.814.63-1.07 1.11-.256.472-.384 1.039-.384 1.681v.544c0 .677.151 1.267.448 1.764.302.496.715.876 1.24 1.144.523.263 1.157.397 1.89.397.628 0 1.192-.099 1.675-.292.489-.192.937-.49 1.338-.882l1.868 2.033a5.516 5.516 0 01-2.095 1.46c-.837.339-1.809.508-2.903.508l.012-.011z"></path>
                    <path d="M105.367 46.576c-1.303 0-2.466-.27-3.479-.8a6.056 6.056 0 01-2.385-2.226c-.576-.952-.867-2.027-.867-3.236s.291-2.3.867-3.235c.576-.935 1.373-1.67 2.385-2.214 1.013-.543 2.176-.812 3.479-.812s2.391.269 3.345.8c.954.538 1.641 1.291 2.06 2.272l-2.705 1.49c-.332-.573-.728-.993-1.193-1.256a3.064 3.064 0 00-1.53-.397c-.588 0-1.117.134-1.6.397a2.908 2.908 0 00-1.146 1.139c-.285.496-.43 1.098-.43 1.81 0 .713.145 1.315.43 1.823.285.502.669.888 1.146 1.15a3.283 3.283 0 001.6.398c.588 0 1.065-.135 1.53-.398.465-.262.861-.689 1.193-1.279l2.705 1.49c-.419.963-1.106 1.717-2.06 2.26-.954.543-2.065.812-3.345.812v.012z"></path>
                    <path d="M111.567 37.212v-2.716h8.401v2.716h-8.401zm6.393 9.362c-1.425 0-2.536-.362-3.333-1.098-.797-.736-1.193-1.822-1.193-3.265v-10.67h3.537v10.6c0 .514.134.912.396 1.198.262.286.617.432 1.071.432.57 0 1.052-.152 1.442-.45l.925 2.488c-.343.257-.768.45-1.274.579a6.312 6.312 0 01-1.565.192l-.006-.006z"></path>
                </g>
                <g>
                    <path d="M9.141 5.078a62.808 62.808 0 015.742-.316c.32 0 .32-.502 0-.496-1.92.017-3.833.122-5.742.315-.314.03-.32.532 0 .497z"></path>
                    <path d="M17.124 5.082c.204.163.46.245.716.152a.711.711 0 00.32-.216c.098-.129.145-.257.192-.415a.41.41 0 00-.285-.508.843.843 0 00-.629.087.573.573 0 00-.244.316c-.064.17.023.356.134.484.145.17.43.152.581 0 .17-.169.146-.414 0-.584.14.158.105.38-.058.503.064-.047-.093.029-.011 0 .029-.012.11-.018-.006 0h.052c-.07-.012-.081-.012-.035 0-.093-.17-.192-.34-.285-.508-.006.029-.07.21-.017.087-.024.041-.018.03.023-.017-.023.029-.081.058.006 0-.04.023-.03.017.029-.012-.082.03.099 0 .012 0-.088 0 .093.023.011 0a.205.205 0 01.053.023c-.047-.029-.03-.017.04.035-.174-.14-.413-.175-.581 0-.146.146-.175.444 0 .584l-.018-.011z"></path>
                    <path d="M2.15 14.778c-.047-3.668-.14-7.354.093-11.016.017-.31-.035-.905.203-1.133.262-.251 1.007-.251 1.344-.304.954-.134 1.92-.21 2.88-.268 2.036-.123 4.078-.135 6.12-.152 2.292-.024 4.578-.053 6.87-.059 1.076 0 2.17 0 3.235.199.727.134 2.007.31 2.333 1.092.163.392.133.859.145 1.268.018.619.03 1.238.047 1.863.029 1.197.052 2.395.075 3.586.047 2.348.087 4.696.122 7.044.134 9.438.157 18.877.076 28.31l-.035 3.586c-.006.578-.012 1.15-.023 1.729-.006.38.023.835-.291 1.11-.593.52-1.873.443-2.6.507a79.226 79.226 0 01-3.538.228c-2.361.1-4.73.1-7.097.059-2.234-.041-4.491-.041-6.72-.205-.814-.058-1.733-.187-2.378-.742-.646-.554-.792-1.495-.838-2.313-.128-2.295-.047-4.614-.058-6.909l-.088-14.082c-.005-1.197-.017-2.389-.023-3.586-.006-1.197-1.815-1.168-1.81 0 .03 5.134.065 10.274.094 15.408.017 2.57.029 5.134.046 7.704.012 2.026.076 4.275 1.955 5.501 1.757 1.145 4.264.894 6.26.953 2.542.075 5.084.134 7.627.105 2.536-.03 5.078-.14 7.603-.41.977-.104 2.025-.221 2.787-.905.762-.683.855-1.705.867-2.698.14-10.32.133-20.647.011-30.973-.064-5.099-.046-10.221-.285-15.32-.046-1.016-.285-1.986-1.117-2.646-.832-.66-1.99-.934-3.025-1.092-2.373-.35-4.828-.175-7.225-.158-2.397.018-4.614.018-6.917.088-2.135.064-4.387.1-6.481.578C1.265.988.637 1.759.504 2.892.392 3.838.374 4.808.345 5.76a150.05 150.05 0 00-.035 6.267l.035 2.745c.012 1.168 1.821 1.168 1.81 0l-.006.006z"></path>
                    <path d="M13.967 50.073c.378 0 .756-.017 1.128-.081.117-.024.239-.258.344-.491.15-.345.133-.9-.047-1.116a.538.538 0 00-.43-.204 14.523 14.523 0 00-1.513.012c-.244.017-.57-.065-.791.327-.134.233-.28.84-.105 1.214.151.316.36.316.53.328.296.011.593.011.884.011z"></path>
                    <path d="M1.3 8.243c4.386.187 8.772.274 13.158.28 2.17 0 4.34-.017 6.51-.058 1.065-.024 2.13-.047 3.194-.082.867-.023 2.1.17 2.78-.479.681-.648-.348-1.67-1.023-1.028-.058.053-.436.012-.547.012-.28.012-.564.017-.843.03-.53.017-1.06.028-1.583.046-.989.023-1.978.047-2.967.064-2.094.035-4.182.047-6.277.041-4.136-.012-8.272-.1-12.403-.275-.93-.04-.93 1.414 0 1.455v-.006z"></path>
                    <path d="M1.356 45.968c3.043-.105 6.074.175 9.11.234 2.968.058 5.934-.059 8.901-.117.931-.017.931-1.472 0-1.454-2.967.058-5.934.175-8.9.117-3.037-.059-6.068-.34-9.11-.234-.931.035-.931 1.483 0 1.454z"></path>
                </g>
            </g>
        </svg>
    );
}

export default Icon;