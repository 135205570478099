import React from 'react';
import ForgotPasswordTemplate from "../../components/templates/ForgotPassword";

type ForgotPasswordProps = {};

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
    return <ForgotPasswordTemplate/>;
};

export default ForgotPassword;
