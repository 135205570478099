import React from "react";

function QuestionsPlus({width= '10', height= '11'}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 10 11"
    >
      <path
        stroke="#595959"
        strokeWidth="0.508"
        d="M5.044.228v10m4.956-5H0"
      ></path>
    </svg>
  );
}

export default QuestionsPlus;