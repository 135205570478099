import React from 'react'
import styled from 'styled-components'

function BlackText({ children }: any) {
    const BlackText = styled.span`
        color: #212121;
        font-family: 'Montserrat';
        font-size: '16px';
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
    `;

    return (
        <BlackText>{children}</BlackText>
    )
}

function List({ elements, numbers }: any) {

    const values = elements.map((element: any) => {
        return (
            <li>{element}</li>
        )
    })

    return (
        numbers ? <ol style={{ paddingLeft: '20px' }}>{values}</ol> : <ul style={{ paddingLeft: '20px' }}>{values}</ul>
    )
}

export default function Exclusions() {
    const Paragraph = styled.div`
        margin-top: 16px;
   `

    return (
        <>
            <Paragraph>
                <BlackText>
                    Exclusiones Generales de las Coberturas de Reembolso de Gastos Médicos por Accidente y Muerte Accidental:
                </BlackText>
            </Paragraph>
            Las coberturas mencionadas para este seguro no amparan la muerte accidental, gastos médicos u hospitalización que deriven de lo siguiente:

            <Paragraph>A. SIDA y VIH: Cualquier fallecimiento, invalidez, pérdida orgánica, enfermedad grave, gastos médicos por accidente u hospitalización que resulte o se relacione, directamente con el Virus de Inmunodeficiencia Humana (VIH) y/o el Síndrome de Inmunodeficiencia Adquirida (SIDA).</Paragraph>

            <Paragraph>B. Salvo pacto en contrario, esta Póliza no ampara muerte accidental, invalidez, pérdida orgánica, gastos médicos por accidente u hospitalización derivada de accidentes que se originen por participación del Asegurado en actividades como:
                <List numbers elements={["Aviación privada, en calidad de piloto o miembro de la tripulación, pasajero o mecánico, fuera de las líneas comerciales autorizadas para la transportación regular de pasajeros.",
                    "Pruebas o contiendas de velocidad, resistencia o seguridad, en vehículos de cualquier tipo.",
                    "Conductor o pasajero de motonetas, cuatrimotos, motocicletas u otros vehículos similares.",
                    "Paracaidismo, buceo, alpinismo, charrería, esquí, tauromaquia o cualquier tipo de deporte aéreo, y en general por la práctica profesional de cualquier deporte."]} />
            </Paragraph>

            <Paragraph>
                C. Alcohol y drogas: Cualquier muerte accidental, invalidez, pérdida orgánica, gastos médicos por accidente u hospitalización por accidente originado por culpa grave del Asegurado cuando se encuentre bajo el efecto del alcohol, drogas, enervantes o alucinógenos, así como de fármacos no prescritos por un médico, o bien, por ingerir somníferos, barbitúricos o estupefacientes. tampoco se cubrirá muerte accidental, invalidez, pérdida orgánica, gastos médicos por accidente u hospitalización por accidente que provenga de algún síndrome de dependencia alcohólica, incluyendo tratamiento de
                condición médica que, en opinión del consultor médico de la Compañía, es considerado causa subyacente o directamente atribuible a un síndrome de dependencia alcohólica.
            </Paragraph>

            <Paragraph>
                D. Maternidad y sus complicaciones.
            </Paragraph>

            <Paragraph>
                E. Tratamientos o intervenciones quirúrgicas estéticas, plásticas o reconstructivas y cualquiera de sus complicaciones.
            </Paragraph>
        </>
  )
}
